import React, { Component } from "react";
import Icons from "../lib/icons";

class ReportsExportButton extends Component {
  render() {
    return (
      <div className="text-right">
        <button type="button" className="btn reports-export-button" disabled>
          Export <span className={Icons.dropdown + " pt-1"} />
        </button>
      </div>
    );
  }
}

export default ReportsExportButton;
