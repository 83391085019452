import React, { Component } from "react";

import LockstepLogo from "./lockstepLogo";

class UnauthorizedHeader extends Component {
  render() {
    return (
      <div className="unauthorized-header-container">
        <LockstepLogo height={57} inverse />
      </div>
    );
  }
}

export default UnauthorizedHeader;
