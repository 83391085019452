import React, { Component } from "react";

class IconPlusCircle extends Component {
  render() {
    let { className, height } = this.props;

    return (
      <svg viewBox="0 0 22 22" height={height} className={`icon ${className ? className : ""}`}>
        <g fill="none" fillRule="evenodd">
          <g transform="translate(1 1)" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
            <circle cx="10" cy="10" r="10" stroke="currentColor" />
            <path stroke="currentColor" d="M10 6v8M6 10h8" />
          </g>
        </g>
      </svg>
    );
  }
}

IconPlusCircle.defaultProps = {
  height: 22
};

export default IconPlusCircle;
