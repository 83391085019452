import React, { Component } from "react";
import Resources from "../lib/resources";
import LockstepLogo from "./library/lockstepLogo";

export default class SiteDown extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="site-down">
          <div className="site-down-connected-by">
            <span className="pr-2">{Resources.ConnectedBy}</span>
            <LockstepLogo height={24}></LockstepLogo>
          </div>
          <img alt="Site is down" src="/img/site-down.svg" className="site-down-image" />
          <h1 className="site-down-heading">{Resources.WereOnIt}</h1>
          <div className="site-down-body">{Resources.OurSiteIsExperiencingSomeIssues}</div>
        </div>
      </React.Fragment>
    );
  }
}
