import React, { Component } from "react";
import { connect } from "react-redux";
import Resources from "../lib/resources";
import { isEmail, isDomain } from "../lib/validation";

import Modal from "react-modal";
import TextInput from "./library/textInput";

import { dispatchToProps as modDP } from "../store/modal-actions";
import { dispatchToProps as conDP } from "../store/connector-actions";
import { dispatchToProps as comDP } from "../store/company-actions";

const dispatchToProps = dispatch => ({
  ...conDP(dispatch),
  ...modDP(dispatch),
  ...comDP(dispatch)
});

class AddEmailIdentifierModal extends Component {
  constructor(props) {
    super(props);
    this.state = { identifiers: "", identifiersIsValid: false };
    this.submit = this.submit.bind(this);
    this.changeInput = this.changeInput.bind(this);
  }

  submit() {
    let { modalStore } = this.props;
    let { modalProps } = modalStore;
    let { sourceCompanyId, identifiedCompanyId } = modalProps;
    let emailConnector = this.props.getConnectorFromName("Email") || {};

    this.props
      .putCompanyIdentifier(emailConnector.connectorId, sourceCompanyId, identifiedCompanyId, this.state.identifiers)
      .then(response => {
        this.props.fetchCompanyIdentifiers(identifiedCompanyId);
        this.props.hideModal();
      })
      .catch(error => {
        return null;
      });
  }

  changeInput(text) {
    let isValid = isDomain(text) || isEmail(text);
    this.setState({ identifiers: text, identifiersIsValid: isValid });
  }

  render() {
    return (
      <Modal
        isOpen={this.props.modalStore.showModal}
        onRequestClose={() => this.props.hideModal()}
        contentLabel="Example Modal"
        className="custom-modal"
        overlayClassName="custom-modal-overlay"
      >
        <div className="custom-modal-header">{Resources.AddNewDomainOrEmail}</div>
        <div className="custom-modal-divider" />
        <div className="custom-modal-content">
          <div className="my-3">{Resources.NewEmailDomainsToAdd}</div>
          <TextInput
            inline
            width="19em"
            textValue={this.state.identifiers}
            isValid={this.state.identifiersIsValid}
            invalidText={Resources.InvalidEmailIdentifier}
            inputOnChange={e => {
              this.changeInput(e.target.value);
            }}
          />
          <div className="d-flex justify-content-end">
            <button className="button-cancel mr-2" onClick={this.props.hideModal}>
              {Resources.Cancel}
            </button>
            <button className="button-green" onClick={this.submit} disabled={!this.state.identifiersIsValid}>
              {Resources.Add}
            </button>
          </div>
        </div>
      </Modal>
    );
  }
}

const storeToProps = store => {
  return {
    modalStore: store.modal,
    connectorStore: store.connector,
    ledgerResources: store.ledger.resources
  };
};

export default connect(
  storeToProps,
  dispatchToProps
)(AddEmailIdentifierModal);
