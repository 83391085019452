import React, { Component } from "react";
import { connect } from "react-redux";
import Resources from "../lib/resources";
import { isEmpty } from "../lib/utils";

import Modal from "react-modal";

import { dispatchToProps } from "../store/modal-actions";

class DKIMConfigurationModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      copiedToClipBoard: {},
      isEmail: false
    };
  }

  render() {
    return (
      <Modal
        isOpen={this.props.modal.showModal}
        onRequestClose={() => this.props.hideModal()}
        contentLabel="Example Modal"
        className="custom-modal-dkim"
        overlayClassName="custom-modal-overlay"
      >
        <div className="custom-modal-content spfdkim-content">
          <div className="input-label">{Resources.SPFDKIMConfigurations}</div>
          <div>
            {Resources.SPFDKIMConfigBlurb} <div className="turquoise-link clickable">{Resources.ClickHere}</div>
          </div>
          {(this.props.SPF_DKIM_ConfigData.Records || []).map((record, i) => {
            const hasName = isEmpty(record.Name) !== true;
            const { copiedToClipBoard, isEmail } = this.state;
            const { index: copiedIndex, key: copiedKey } = copiedToClipBoard;
            return (
              <div key={i + "_SPF_DKIM_KEY"} style={{ overflowX: "auto" }}>
                <p className="font-weight-bold">
                  {Resources.DNSRecord} {(i + 1).toString()}
                </p>
                {hasName && (
                  <div className="input-container">
                    <label>{Resources.Name}</label>
                    <div
                      className={isEmail !== true ? "clickable" : null}
                      onClick={isEmail !== true ? e => this.handleToClipboard(e.target.textContent, i, "Name") : null}
                      data-toggle={isEmail !== true ? "tooltip" : null}
                      data-placement={isEmail !== true ? "top" : null}
                      title={isEmail !== true ? Resources.CopyToClipboard : null}
                    >
                      {record.Name}{" "}
                      {copiedIndex === i && copiedKey === "Name" && <span>{Resources.CopiedToClipboard}</span>}
                    </div>
                  </div>
                )}
                <div className="input-container">
                  <label>{Resources.Type}</label>
                  <div
                    className={isEmail !== true ? "clickable" : null}
                    onClick={isEmail !== true ? e => this.handleToClipboard(e.target.textContent, i, "Type") : null}
                    data-toggle={isEmail !== true ? "tooltip" : null}
                    data-placement={isEmail !== true ? "top" : null}
                    title={isEmail !== true ? Resources.CopyToClipboard : null}
                  >
                    {record.Type}{" "}
                    {copiedIndex === i && copiedKey === "Type" && <span>{Resources.CopiedToClipboard}</span>}
                  </div>
                </div>
                <div className="input-container">
                  <label>{Resources.Value}</label>
                  <div
                    className={isEmail !== true ? "clickable" : null}
                    onClick={isEmail !== true ? e => this.handleToClipboard(e.target.textContent, i, "Value") : null}
                    data-toggle={isEmail !== true ? "tooltip" : null}
                    data-placement={isEmail !== true ? "top" : null}
                    title={isEmail !== true ? Resources.CopyToClipboard : null}
                  >
                    {record.Value}{" "}
                    {copiedIndex === i && copiedKey === "Value" && <span>{Resources.CopiedToClipboard}</span>}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </Modal>
    );
  }
}

const storeToProps = store => {
  return {
    modal: store.modal
  };
};

export default connect(storeToProps, dispatchToProps)(DKIMConfigurationModal);
