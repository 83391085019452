import React, { Component } from "react";
import SignUpBody from "./signUpBody";
import SignUpHeader from "./signUpHeader";
import SignUpFooter from "./signUpFooter";

import "./signup.scss";

export default class SignUp extends Component {
  render() {
    return (
      <div id="signup-container" className="border rounded">
        <SignUpHeader closeModal={this.props.closeModal} headline={this.props.headerHeadlineTxt} />
        <SignUpBody content={this.props.content} />
        <SignUpFooter />
      </div>
    );
  }
}
