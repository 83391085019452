import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Route, Switch } from "react-router-dom";
import ProfileUpdateView from "./views/profileUpdate/profileUpdateView";

class ProfileUpdateController extends Component {
  render() {
    return (
      <Switch>
        <Route path="/v2/profileupdate/:type?/:mode?/:view?/:accessId?">
          <div className="lockstep-view">
            <ProfileUpdateView />
          </div>
        </Route>
      </Switch>
    );
  }
}

export default withRouter(ProfileUpdateController);
