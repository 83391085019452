import React, { Component } from "react";
import { connect } from "react-redux";
import { find } from "../lib/utils";
import Icons from "../lib/icons";
import { withRouter } from "react-router-dom";

import { dispatchToProps as moDP } from "../store/modal-actions";
import { dispatchToProps as comDP } from "../store/company-actions";
import { dispatchToProps as conDP } from "../store/conversations-actions";
import { dispatchToProps as genDP } from "../store/general-actions";
import { dispatchToProps as congDP } from "../store/contextGroups-actions";

import Resources from "../lib/resources";
import Modal from "react-modal";
import AutoCompleteDropdown from "./library/autoCompleteDropdown";

const dispatchToProps = dispatch => ({
  ...moDP(dispatch),
  ...comDP(dispatch),
  ...conDP(dispatch),
  ...genDP(dispatch),
  ...congDP(dispatch)
});

class ModalCompanyRemap extends Component {
  constructor(props) {
    super(props);

    this.state = {
      companySelected: this.props.contextGroupsStore.value[0]
    };
  }

  submit() {
    let companyToId = this.state.companySelected.companyId;
    let companyToName = this.state.companySelected.companyName;
    let companyFromId = this.props.modal.modalProps.companyId;
    let companyFromName = this.props.modal.modalProps.companyName;
    let toContextGroup =
      find(this.props.contextGroupsStore.value, contextGroup => contextGroup.companyId === companyToId) || {};
    let toContextGroupId = toContextGroup.contextGroupId;

    this.props.postCompanyRemap(companyFromId, companyToId).then(() => {
      this.props.showToast({
        timeout: 8000,
        text: Resources.HasBeenRemaped(companyFromName, companyToName),
        type: "green",
        linkHidden: true
      });
      this.props.hideModal();
      this.props.history.push(
        this.props.location.pathname
          .replace(this.props.match.params.selectedContextGroupId, toContextGroupId)
          .replace(this.props.match.params.view, "profile")
      );
      this.props.refreshContextGroups(this.props.match.params.companyId, this.props.match.params.perspectiveId);
    });
  }

  render() {
    return (
      <Modal
        isOpen={this.props.modal.showModal}
        onRequestClose={() => this.props.hideModal()}
        contentLabel="Example Modal"
        className="custom-modal"
        overlayClassName="custom-modal-overlay"
      >
        <div className="custom-modal-header">{Resources.Remap}</div>
        <div className="custom-modal-divider" />
        <div className="custom-modal-content">
          {Resources.RemapAndMergeTheEmailToAnExistingCompany}
          <br />
          <div className="action-modal-company">{this.props.modal.modalProps.companyName}</div>
          <AutoCompleteDropdown
            disabled={false}
            label={"Remap to:"}
            width="300px"
            selected={this.state.companySelected}
            options={this.props.contextGroupsStore.value || []}
            displayKey="displayName"
            placeholder={Resources.LoadingOptions}
            handleSelectOption={option => {
              this.setState({ companySelected: option });
            }}
          />
          <div className="flex-row modal-buttons mt-4">
            <button className="button-cancel float-left mr-1" onClick={() => this.props.hideModal()}>
              {Resources.Cancel}
            </button>
            <button className="btn button-green" onClick={() => this.submit()}>
              {Resources.Save}
            </button>
            <span className="modal-warning-text pl-2">
              <span className={Icons.exclamationCircle + " pr-1"} />
              {Resources.ThisActionCannotBeUndone}
            </span>
          </div>
        </div>
      </Modal>
    );
  }
}

const storeToProps = store => {
  return {
    modal: store.modal,
    companyIdentifiers: store.companies.companyIdentifiers,
    contextGroupsStore: store.contextGroups
  };
};

export default withRouter(
  connect(
    storeToProps,
    dispatchToProps
  )(ModalCompanyRemap)
);
