import React, { Component } from "react";
import { connect } from "react-redux";

import Icons from "../lib/icons";

class CompanyRefresh extends Component {
  render() {
    let loading = this.props.gettingCompanies === true;
    if (loading === false) {
      return null;
    }
    return (
      <div className="ml-3 mb-3 d-flex align-items-end" style={{ minHeight: "40px" }}>
        <span className={`${Icons.spinner}`} />
      </div>
    );
  }
}

const storeToProps = store => {
  return {
    gettingCompanies: store.contextGroups.gettingContextGroups
  };
};

export default connect(storeToProps)(CompanyRefresh);
