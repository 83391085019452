import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import Icons from "../lib/icons";

class MenuItem extends Component {
  render() {
    const { showSpinner, onClick, to, activeCount, label, labelBold, iconClasses, disabled } = this.props;
    const content = (
      <React.Fragment>
        <div className="flex-align-center flex-split">
          <div className="sidebar-text-container">
            <span className="menu-icon">
              <span className={iconClasses} />
            </span>
            <span className={`${labelBold ? "fw-500" : ""}  sidebar-link-text`}>{label}</span>
          </div>
          {!!activeCount && (
            <span
              className={`badge ${this.props.badgeClassName ? this.props.badgeClassName : "badge-coral"} float-right`}
            >
              {activeCount}
            </span>
          )}
        </div>
        {showSpinner && <span className={Icons.spinner} />}
      </React.Fragment>
    );

    if (disabled) {
      return <div className="sidebar-link disabled">{content}</div>;
    } else {
      return (
        <NavLink onClick={onClick} to={to} className={"sidebar-link " + this.props.className}>
          {content}
        </NavLink>
      );
    }
  }
}

MenuItem.defaultProps = {
  activeCount: 0,
  disabled: false
};

export default MenuItem;
