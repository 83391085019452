import React, { Component } from "react";

class IconMove extends Component {
  render() {
    let { className, height } = this.props;

    return (
      <svg
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        viewBox="0 0 24 24"
        height={height}
        className={`icon ${className ? className : ""}`}
      >
        <path d="M5 9l-3 3 3 3M9 5l3-3 3 3M15 19l-3 3-3-3M19 9l3 3-3 3M2 12h20M12 2v20" />
      </svg>
    );
  }
}

IconMove.defaultProps = {
  height: 21
};

export default IconMove;
