import React, { Component } from "react";

class IconInvoice extends Component {
  render() {
    let { className, height } = this.props;
    return (
      <svg height={height} className={`icon ${className ? className : ""}`} viewBox="0 0 22 28">
        <defs />
        <g fill="none" fillRule="evenodd">
          <path fill="#FFF" d="M-380-730h1440V789H-380z" />
          <path fill="#FFF" d="M-56-655h1116V972H-56z" />
          <rect
            width="963"
            height="91"
            x=".5"
            y=".5"
            fill="#FFF"
            stroke="#currentColor"
            rx="2"
            transform="translate(-24 -32)"
          />
          <g stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
            <path d="M13.75 0h-11C1.23121694 0 0 1.2536027 0 2.8v22.4C0 26.7463973 1.23121694 28 2.75 28h16.5c1.5187831 0 2.75-1.2536027 2.75-2.8V8.4L13.75 0z" />
            <path d="M14 1v8h7" />
            <g>
              <path d="M10.71428571 11v13M13.80952385 13.16666667H9.16666668C7.97004971 13.16666667 7 14.13671638 7 15.33333333 7 16.52995029 7.97004972 17.5 9.16666668 17.5h3.09523811c1.19661695 3e-8 2.16666664.97004973 2.16666664 2.16666667 0 1.19661694-.97004969 2.16666663-2.16666664 2.16666663H7" />
            </g>
          </g>
        </g>
      </svg>
    );
  }
}

IconInvoice.defaultProps = {
  height: 26
};

export default IconInvoice;
