import React, { Component } from "react";
import { connect } from "react-redux";

import { dispatchToProps } from "../store/reports-actions";
import Resources from "../lib/resources";

class ReportsFilter extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.reports.rangeType !== nextProps.reports.rangeType) {
      return true;
    }
    return false;
  }

  render() {
    return (
      <div className="nav-filter-reports">
        <h4 className="filter-time-reporting">{Resources.FilterBy}</h4>
        <ul>
          <li
            className={`reports-filter-item ${this.props.reports.rangeType === "lastWeek" ? "active" : ""}`}
            onClick={() => {
              return this.props.changeRangeType("lastWeek");
            }}
          >
            {Resources.LastWeek}
          </li>
          <li
            className={`reports-filter-item ${this.props.reports.rangeType === "thisWeek" ? "active" : ""}`}
            onClick={() => {
              return this.props.changeRangeType("thisWeek");
            }}
          >
            {Resources.ThisWeek}
          </li>
          <li
            className={`reports-filter-item ${this.props.reports.rangeType === "lastMonth" ? "active" : ""}`}
            onClick={() => {
              return this.props.changeRangeType("lastMonth");
            }}
          >
            {Resources.LastMonth}
          </li>
          <li
            className={`reports-filter-item ${this.props.reports.rangeType === "thisMonth" ? "active" : ""}`}
            onClick={() => {
              return this.props.changeRangeType("thisMonth");
            }}
          >
            {Resources.ThisMonth}
          </li>
          <li
            className={`reports-filter-item ${this.props.reports.rangeType === "lastQuarter" ? "active" : ""}`}
            onClick={() => {
              return this.props.changeRangeType("lastQuarter");
            }}
          >
            {Resources.LastQuarter}
          </li>
          <li
            className={`reports-filter-item ${this.props.reports.rangeType === "thisQuarter" ? "active" : ""}`}
            onClick={() => {
              return this.props.changeRangeType("thisQuarter");
            }}
          >
            {Resources.ThisQuarter}
          </li>
        </ul>
      </div>
    );
  }
}

const storeToProps = store => {
  return {
    reports: store.reports
  };
};

export default connect(
  storeToProps,
  dispatchToProps
)(ReportsFilter);
