import React, { Component } from "react";

class IconBell extends Component {
  render() {
    let { className, height } = this.props;

    return (
      <svg
        height={height}
        className={`icon ${className ? className : ""}`}
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        viewBox="0 0 24 24"
      >
        <defs />
        <path d="M18 8A6 6 0 006 8c0 7-3 9-3 9h18s-3-2-3-9M13.73 21a2 2 0 01-3.46 0" />
      </svg>
    );
  }
}

IconBell.defaultProps = {
  height: 24
};

export default IconBell;
