import React, { useState, useEffect, useRef } from "react";
import { usePopper } from "react-popper";
import OutsideClickHandler from "react-outside-click-handler";

import DropdownItem from "./dropdownItem";
import { isEmpty } from "../../lib/utils";

// Deprecated component
// class Dropdown1 extends Component {
//   constructor(props) {
//     super(props);

//     this.state = {
//       isOpen: false
//     };
//     this.timeOutId = null;

//     this.onBlurHandler = this.onBlurHandler.bind(this);
//     this.onFocusHandler = this.onFocusHandler.bind(this);
//   }

//   // We close the popover on the next tick by using setTimeout.
//   // This is necessary because we need to first check if
//   // another child of the element has received focus as
//   // the blur event fires prior to the new focus event.
//   onBlurHandler(e) {
//     let { onBlur = e => null } = this.props;

//     this.timeOutId = setTimeout(() => {
//       this.setState({
//         isOpen: false
//       });
//     });
//     onBlur(e);
//   }

//   // If a child receives focus, do not close the popover.
//   onFocusHandler(e) {
//     const { onFocus = e => null } = this.props;

//     clearTimeout(this.timeOutId);
//     onFocus(e);
//   }

//   render() {
//     const {
//       buttonContent,
//       children,
//       buttonClassName,
//       buttonWidth,
//       menuWidth,
//       disabled = false,
//       menuAlign,
//       closeOnClick,
//       matchButtonMenuWidth
//     } = this.props;
//     const { isOpen } = this.state;

//     const buttonStyle = buttonWidth ? { width: buttonWidth } : {};
//     let menuStyle = {};
//     if (menuWidth) {
//       menuStyle.width = menuWidth;
//     }
//     if (matchButtonMenuWidth) {
//       menuStyle.width = "100%";
//     }

//     return (
//       <div
//         className={"dropdown" + (menuAlign === "right" ? " dropright" : "")}
//         onFocus={this.onFocusHandler}
//         onBlur={this.onBlurHandler}
//         onClick={() => (closeOnClick && isOpen ? this.setState({ isOpen: false }) : null)}
//       >
//         <button
//           className={buttonClassName ? buttonClassName : ""}
//           aria-haspopup="true"
//           aria-expanded="false"
//           disabled={disabled}
//           onClick={() => this.setState({ isOpen: !isOpen })}
//           style={buttonStyle}
//         >
//           {buttonContent}
//         </button>
//         {isOpen && (
//           <div
//             className={"dropdown-menu show" + (menuAlign === "right" ? " dropdown-menu-right" : "")}
//             style={menuStyle}
//           >
//             {children}
//           </div>
//         )}
//       </div>
//     );
//   }

//   Item = DropdownItem;
// }

function Dropdown(props) {
  const {
    buttonContent = "",
    children,
    buttonClassName = "",
    buttonWidth = "",
    menuWidth = "",
    matchButtonMenuWidth = false,
    disabled = false,
    menuAlign = "bottom",
    isForm = false,
    menuClassName = "",
    toggleOnHover = false
  } = props;

  const referenceRef = useRef(null);
  const popperRef = useRef(null);

  const [visible, setVisible] = useState(false);
  const setVisibleClickHandler = value => !toggleOnHover && setVisible(value);

  // When toggleHover is true we want the dropdown to open on mouse enter
  // and the dropdown to close after a delay on mouse exit.
  const [hovered, setHovered] = useState(false);
  const setVisibleHoverHandler = value => {
    if (!toggleOnHover) return null;
    // We want the visibility to be updated immediately if we are opening the dropdown
    if (value) {
      setVisible(value);
    }
    setHovered(value);
  };
  // This will close the dropdown after dropdown is not hovered anymore after a delay
  useInterval(
    () => {
      setVisible(false);
    },
    toggleOnHover && !hovered ? 110 : null
  );

  const { styles, attributes } = usePopper(referenceRef.current, popperRef.current, {
    placement: menuAlign + "-start",
    modifiers: [
      {
        name: "offset",
        enabled: true,
        options: {
          offset: [0, 6]
        }
      }
    ]
  });

  let containerStyle = {
    ...styles.popper
  };
  if (!isEmpty(menuWidth)) {
    containerStyle.width = menuWidth;
  }
  if (matchButtonMenuWidth) {
    containerStyle.width = "100%";
  }

  return (
    <React.Fragment>
      <div
        className="dropdown"
        onMouseEnter={() => setVisibleHoverHandler(true)}
        onMouseLeave={() => setVisibleHoverHandler(false)}
      >
        <OutsideClickHandler onOutsideClick={() => setVisibleClickHandler(false)}>
          <button
            className={"dropdown-trigger " + (buttonClassName ? buttonClassName : "")}
            aria-haspopup="true"
            aria-expanded={visible}
            disabled={disabled}
            ref={referenceRef}
            onClick={e => {
              setVisibleClickHandler(!visible);
            }}
            style={{ width: buttonWidth }}
          >
            {buttonContent}
          </button>
          <span
            ref={popperRef}
            style={containerStyle}
            {...attributes.popper}
            className={`lockstep-dropdown__menu ${
              visible && !isEmpty(children) ? "show" : ""
            } ${menuClassName} breakout`}
            onClick={e =>
              isForm
                ? (e.target.attributes.closedropdownonclick || {}).value
                  ? setVisibleClickHandler(false)
                  : null
                : setVisibleClickHandler(false)
            }
          >
            <OutsideClickHandler
              onOutsideClick={e => {
                e.current && !e.current.contains(referenceRef) && setVisibleClickHandler(false);
              }}
              disabled={isForm || visible === false}
            >
              {children}
            </OutsideClickHandler>
          </span>
        </OutsideClickHandler>
      </div>
    </React.Fragment>
  );
}

Dropdown.Item = DropdownItem;

export default Dropdown;

function useInterval(callback, delay) {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}
