import React, { Component } from "react";
import Resources from "../../lib/resources";
import Icons from "../../lib/icons";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { isEmpty, getQueryParameters, isProduction } from "../../lib/utils";

import LockstepLogo from "../library/lockstepLogo";
import IconApps from "../library/icons/iconApps";

import { dispatchToProps as uaDP } from "../../store/user-actions";
import { dispatchToProps as saDP } from "../../store/search-actions";
import { dispatchToProps as moDP } from "../../store/modal-actions";

import "./main-content-header.scss";
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import IconPlusCircle from "../library/icons/iconPlusCircle";

const dispatchToProps = dispatch => {
  return {
    ...uaDP(dispatch),
    ...saDP(dispatch),
    ...moDP(dispatch)
  };
};

class MainContentHeader extends Component {
  render() {
    const {
      title,
      subtitle,
      links,
      customContent,
      match: { params },
      history,
      hideSearch,
      iconObject,
      isNewCompany,
      logoSrc,
      companySelect
    } = this.props;
    const queryParams = getQueryParameters(this.props.location.search);
    return (
      <div id="content-header-container">
        <div className="d-flex flex-column">
          <div id="content-header-title">
            {this.props.isCustomerPortal && !isEmpty(logoSrc) && (
              <img className="content-header-title-logo" src={logoSrc} alt={Resources.Logo}></img>
            )}
            {isNewCompany && <span className={Icons.activeConversation + " pr-2 new-company-header-icon"} />}
            <div>
              {companySelect ? (
                <div className="content-header">{companySelect}</div>
              ) : (
                <h3 className="content-header">{title}</h3>
              )}
              {!isEmpty(subtitle) && <h4 className="content-header-subtitle">{subtitle}</h4>}
            </div>
            {iconObject}
          </div>

          {!isEmpty(links) && <div id="content-header-nav">{links}</div>}
          {customContent}
        </div>
        <div className="content-header__right-column">
          {this.props.isCustomerPortal ? (
            <a
              className="connected-by-lockstep-container"
              href="https://lockstephq.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="mr-1">{Resources.ConnectedBy}</span> <LockstepLogo height={33} />
            </a>
          ) : (
            <React.Fragment>
              {!isProduction() && (
                <Link to={"/v2"}>
                  <button className="button-action-icon app-switcher-button">
                    <IconApps height={18}></IconApps>
                  </button>
                </Link>
              )}
            </React.Fragment>
          )}
          {this.props.isCustomerPortal && this.props.flags.portalAddVendorsConversion.enabled && (
            <div className="content-header__add-vendors-container">
              <button className="button-primary" onClick={() => this.props.displayModal("portalAddVendorsModal")}>
                <IconPlusCircle className="button-primary-icon" />
                {Resources.AddVendors}
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }
}

MainContentHeader.defaultProps = { company: { isNew: false } };

export default withLDConsumer()(withRouter(connect(null, dispatchToProps)(MainContentHeader)));
