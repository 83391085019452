import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from "react-modal";
import Resources from "../../../lib/resources";
import ContactsTable from "../contactsTable";
import { find } from "../../../lib/utils";
import { dispatchToProps as netDP } from "../../../store/network-actions";
import { dispatchToProps as modDP } from "../../../store/modal-actions";
import { dispatchToProps as persDP } from "../../../store/perspectives-actions";

const dispatchToProps = dispatch => ({
  ...netDP(dispatch),
  ...modDP(dispatch),
  ...persDP(dispatch)
});

class SetNewPrimaryContactDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      primaryContact: ""
    };
    this.setPrimary = this.setPrimary.bind(this);
  }
  setPrimary(key) {
    this.setState({ primaryContact: key });
  }

  componentDidMount() {
    let parentAccount = find(
      this.props.networkStore.accountList.value,
      i => i.vendorName === this.props.account.vendorName
    );
    this.props.fetchAccountContacts(this.props.perspectiveId, parentAccount.entityGlobalKey);
  }

  render() {
    let parentAccount = find(
      this.props.networkStore.accountList.value,
      i => i.vendorName === this.props.account.vendorName
    );
    const selectedAccountContacts = (
      this.props.getAccountContacts(this.props.perspectiveId, parentAccount.entityGlobalKey) || []
    ).filter(i => i.contactName !== this.props.name);
    const { modalStore } = this.props;
    return (
      <Modal
        isOpen={modalStore.showModal}
        onRequestClose={() => this.props.hideModal()}
        className="primary-contact-archive-modal"
        overlayClassName="custom-modal-overlay"
      >
        <p className="header">{Resources.YouAreTryingToArchiveAPrimaryContact}</p>
        <p>{Resources.PrimaryContactArchiveMessage(this.props.name, this.props.companyname)}</p>
        <div className="document-container">
          <ContactsTable
            name="set-primary-contact__contacts-table"
            data={selectedAccountContacts}
            noCompany
            primaryArchive
            loading={this.props.networkStore.gettingAccountContacts[parentAccount.entityGlobalKey]}
            noHover
            setPrimary={this.setPrimary}
            currentPrimary={this.state.primaryContact}
          />
        </div>
        <div className="button-container">
          <button onClick={() => this.props.hideModal()} className="button-secondary-square">
            {Resources.Cancel}
          </button>
          <button
            onClick={() => {
              this.props
                .markContactPrimary(
                  this.props.perspectiveId,
                  this.state.primaryContact,
                  this.props.perspectiveStore.selectedPerspectiveType === "vendors" ? "ap" : "ar"
                )
                .then(response => {
                  this.props
                    .toggleContactArchived(
                      this.props.perspectiveId,
                      this.props.account.entityGlobalKey,
                      true,
                      this.props.perspectiveStore.selectedPerspectiveType === "vendors" ? "ap" : "ar"
                    )
                    .then(response => {
                      this.props.fetchContactList(this.props.perspectiveId);
                      this.props.fetchArchivedContactList(this.props.perspectiveId);
                      this.props.fetchAccountContacts(this.props.perspectiveId, parentAccount.entityGlobalKey);
                      this.props.hideModal();
                    });
                });
            }}
            className="button-primary-square"
          >
            {Resources.Save}
          </button>
        </div>
      </Modal>
    );
  }
}

const storeToProps = store => {
  return {
    modalStore: store.modal,
    networkStore: store.network,
    perspectiveStore: store.perspectives
  };
};

export default connect(storeToProps, dispatchToProps)(SetNewPrimaryContactDialog);
