import { actions } from "./search-actions";
import { clone } from "../lib/utils";

const defaultSearchState = {
  isFetchingSearchResults: false,
  fetchingSearchResultsFailed: null,

  searchHistory: [],
  searchTerm: "",
  searchTermFetching: "",
  searchTermFetched: "",
  displayNoResultsMessage: false,
  isShowingSearchResults: false,

  gettingSearchResults: false,
  gotSearchResults: false,
  failedGetSearchResults: false,

  searchResults: [],
  searchResultsCount: 0,

  searchQuery: {},
  errMsgs: "",

  // These are mapping the label to the specified data for each label returned from API
  counts: {},
  nextLinks: {},
  values: {}
};

const searchReducer = (state = clone(defaultSearchState), action) => {
  switch (action.type) {
    case actions.UPDATE_SEARCH_TERM:
      return { ...state, searchTerm: action.searchTerm };

    case actions.FETCHING_SEARCH_RESULTS:
      return {
        ...state,
        isFetchingSearchResults: true,
        fetchingSearchResultsFailed: null,
        searchResults: [],
        searchQuery: action.request
      };
    case actions.FETCHED_SEARCH_RESULTS:
      if (action.request !== state.searchQuery) {
        return state;
      }
      return {
        ...state,
        isFetchingSearchResults: false,
        fetchingSearchResultsFailed: false,
        searchResults: action.searchResults
      };
    case actions.FETCH_SEARCH_RESULTS_FAILED:
      return {
        ...state,
        isFetchingSearchResults: false,
        fetchingSearchResultsFailed: true
      };

    case actions.GETTING_GLOBAL_SEARCH_RESULTS:
      return {
        ...state,
        gettingSearchResults: true,
        gotSearchResults: false,
        failedGetSearchResults: false,

        searchTermFetching: action.request.request
      };
    case actions.GOT_GLOBAL_SEARCH_RESULTS:
      let counts = {};
      let nextLinks = {};
      let values = {};

      Object.keys(action.searchResults).forEach(label => {
        if (!!action.fetchNextLabel) {
          if (label === action.fetchNextLabel) {
            counts[label] = action.searchResults[label].count;
            nextLinks[label] = action.searchResults[label].nextLink;
          } else {
            counts[label] = state.counts[label];
            nextLinks[label] = state.nextLinks[label];
          }
          values[label] = [...state.values[label], ...action.searchResults[label].value];
        } else {
          counts[label] = action.searchResults[label].count;
          nextLinks[label] = action.searchResults[label].nextLink;
          values[label] = action.searchResults[label].value;
        }
      });

      return {
        ...state,
        gettingSearchResults: false,
        gotSearchResults: true,
        failedGetSearchResults: false,
        searchTermFetching: null,
        searchTermFetched: action.request.request,
        counts,
        nextLinks,
        values
      };
    case actions.FAILED_GET_GLOBAL_SEARCH_RESULTS:
      return {
        ...state,
        gettingSearchResults: false,
        gotSearchResults: false,
        failedGetSearchResults: true,
        searchTermFetching: null
      };

    case actions.PUSH_SEARCH_HISTORY_ITEM:
      return { ...state, searchHistory: [action.searchTerm, ...state.searchHistory] };

    case actions.CLEAR_SEARCH_RESULTS:
      return { ...defaultSearchState, searchHistory: [...state.searchHistory] };

    default:
      return state;
  }
};

export default searchReducer;
