import React, { Component } from "react";
import Card from "../../../library/card";
import Resources from "../../../../lib/resources";
import IconArchive from "../../../library/icons/iconArchive";
import IconPhone from "../../../library/icons/iconPhone";
import IconMapPin from "../../../library/icons/iconMapPin";
import IconLinkedIn from "../../../library/icons/iconLinkedIn";
import IconClipboard from "../../../library/icons/iconClipboard";
import IconMail from "../../../library/icons/iconMail";
import IconBriefcase from "../../../library/icons/iconBriefcase";
import IconAlertCircle from "../../../library/icons/iconAlertCircle";

import { formatDate, isEmpty } from "../../../../lib/utils";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { dispatchToProps as netDP } from "../../../../store/network-actions";
import Avatar from "../../../library/avatar";

const dispatchToProps = dispatch => ({
  ...netDP(dispatch)
});

class ContactDisplayCard extends Component {
  componentDidMount() {
    if (!this.props.signup) {
      let accessId = this.props.match.params.accessId;
      this.props.fetchCustContact(accessId);
    }
  }

  render() {
    let roles = {
      1: "CFO",
      2: "Manager/Controller",
      3: "Disputes",
      4: "Payments",
      5: "Remittance",
      6: "Primary",
      7: "Secondary"
    };

    let selectedContact = this.props.signup ? this.props.selectedContact : this.props.networkStore.custContact;

    return (
      <Card className="account-profile__card">
        <div className="account-profile__card__last-updated">
          {Resources.UpdatedDate(formatDate(selectedContact.updateDate))}
        </div>
        <div className="">
          {isEmpty(selectedContact.contactName) ? null : (
            <div className="account-profile-item">
              {!this.props.noavatar ? (
                <div className="account-profile-item__icon">
                  <Avatar
                    height={"3.6rem"}
                    imgURL={selectedContact.logoURL}
                    // type="user"
                    objectName={selectedContact.companyName}
                    className={"avatar"}
                  ></Avatar>
                </div>
              ) : null}
              <div className="accont-profile-item__content">
                <h2>{selectedContact.contactName}</h2>
              </div>
            </div>
          )}
          {isEmpty(selectedContact.title) ? null : (
            <div className="account-profile-item">
              <div className="account-profile-item__icon">
                <IconBriefcase height={18} />
              </div>
              <div className="accont-profile-item__content">
                {selectedContact.title}, {roles[selectedContact.contactRoleId]}
              </div>
            </div>
          )}
          <div className="account-profile-item">
            <div className="account-profile-item__icon">
              <IconMail height={18} />
            </div>
            <div className="account-profile-item__content">
              {selectedContact.emailAddr === "" || selectedContact.emailAddr === null ? (
                <div onClick={() => this.setState({ editMode: true })} className="missing-email-address-container">
                  <IconAlertCircle height={20} />
                  <span>{Resources.MissingEmailAddress}</span>
                </div>
              ) : (
                selectedContact.emailAddr
              )}
            </div>
          </div>
          {!isEmpty(selectedContact.phone) && (
            <div className="account-profile-item">
              <div className="account-profile-item__icon">
                <IconPhone height={22} />
              </div>
              <div className="account-profile-item__content">{selectedContact.phone}</div>
            </div>
          )}
          {!isEmpty(selectedContact.fax) && (
            <div className="account-profile-item">
              <div className="account-profile-item__icon">
                <IconArchive height={16} />
              </div>
              <div className="account-profile-item__content">{selectedContact.fax}</div>
            </div>
          )}
          {!isEmpty(
            [
              selectedContact.addrLn1,
              selectedContact.city,
              selectedContact.state,
              selectedContact.postalcode,
              selectedContact.country
            ].filter(value => !isEmpty(value))
          ) && (
            <div className="account-profile-item">
              <div className="account-profile-item__icon">
                <IconMapPin height={22} />
              </div>
              <div className="account-profile-item__content">
                <div>{selectedContact.addrLn1}</div>
                <div>
                  {selectedContact.city}, {selectedContact.state} {selectedContact.postalcode}
                </div>
                <div>{selectedContact.country}</div>
              </div>
            </div>
          )}
          {!isEmpty(selectedContact.linkedInProfile) && (
            <div className="account-profile-item">
              <div className="account-profile-item__icon">
                <IconLinkedIn height={19} />
              </div>
              <div className="account-profile-item__content">
                <a rel="norefferer noopener" href={selectedContact.linkedInProfile}>
                  {selectedContact.linkedInProfile}
                </a>
              </div>
            </div>
          )}
          {!isEmpty(selectedContact.notes) && (
            <div className="account-profile-item">
              <div className="account-profile-item__icon">
                <IconClipboard height={20} />
              </div>
              <div className="account-profile-item__content">{selectedContact.notes}</div>
            </div>
          )}
        </div>
      </Card>
    );
  }
}

const storeToProps = store => {
  return {
    networkStore: store.network
  };
};

export default withRouter(connect(storeToProps, dispatchToProps)(ContactDisplayCard));
