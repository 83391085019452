// import moment from "moment";

import { actions, SortBy, SortDirection } from "./contextGroups-actions";
import { compareDates, compareStrings } from "../lib/utils";

export const defaultConversationsState = {
  companyId: null,
  perspectiveId: null,
  gettingContextGroups: false,
  gotContextGroups: false,
  failedGetContextGroups: false,

  movingConversation: false,
  movedConversation: false,
  moveConversationFailed: false,

  bulkMovingConversation: false,
  bulkMovedConversation: false,
  bulkMoveConversationFailed: false,

  value: [],
  contextGroupsMaps: {},
  contextGroupsStats: {},
  contextGroupsSortBy: SortBy.ActiveCount,
  contextGroupsSortDirection: SortDirection.Descending
};

function contextGroupsReducer(state = defaultConversationsState, action) {
  if (window.logToConsole) console.log(action);

  if (action.type === actions.SELECT_COMPANY) {
    if (state.companyId === action.companyId) return state;
    return { ...defaultConversationsState, companyId: action.companyId, contextGroupsStats: state.contextGroupsStats };
  }

  switch (action.type) {
    case actions.GETTING_CONTEXT_GROUPS:
      if (state.companyId !== action.companyId || state.perspectiveId !== action.perspectiveId) {
        return {
          ...defaultConversationsState,
          gettingContextGroups: true,
          gotContextGroups: false,
          failedGetContextGroups: false,
          companyId: action.companyId,
          perspectiveId: action.perspectiveId,
          contextGroupsStats: state.contextGroupsStats
        };
      }
      return { ...state, gettingContextGroups: true, gotContextGroups: false, failedGetContextGroups: false };
    case actions.GET_CONTEXT_GROUPS_FAILED:
      if (state.companyId !== action.companyId || state.perspectiveId !== action.perspectiveId) {
        return state;
      }
      return { ...state, gettingContextGroups: false, gotContextGroups: false, failedGetContextGroups: true };

    case actions.GOT_CONTEXT_GROUPS:
      if (state.companyId !== action.companyId || state.perspectiveId !== action.perspectiveId) {
        return state;
      }

      // let currentcontextGroups = state.value;
      // let currentMostRecent = moment.max(
      //   currentcontextGroups.map(contextGroup => {
      //     return moment(contextGroup.mostRecent);
      //   })
      // );
      // let mostRecent = moment.max(
      //   action.data.map(contextGroup => {
      //     return moment(contextGroup.mostRecent);
      //   })
      // );

      let newState = {
        ...state,
        gettingContextGroups: false,
        gotContextGroups: true,
        failedGetContextGroups: false
      };

      //TODO: Add polling to context groups and do not update store if the times are same
      //if (mostRecent.isSame(currentMostRecent) === false || action.data.length !== currentcontextGroups.length) {
      newState.value = [...action.data];
      newState.contextGroupsMaps = {};

      sortContextGroupsBy(action.data, state.contextGroupsSortBy, state.contextGroupsSortDirection);
      newState.value = action.data;
      action.data.forEach(contextGroup => {
        newState.contextGroupsMaps[contextGroup.contextGroupId] = contextGroup;
      });
      //}
      return newState;

    case actions.GOT_CONTEXT_GROUPS_STATS:
      return { ...state, contextGroupsStats: action.data };
    case actions.UPDATE_PERSPECTIVE_CONTEXT_GROUPS_STATS:
      return {
        ...state,
        contextGroupsStats: { ...state.contextGroupsStats, [action.perspectiveId]: { ...action.stats } }
      };

    case actions.MOVING_CONVERSATION_TO_CONTEXT_GROUP:
      return { ...state, movingConversation: true, movedConversation: false, moveConversationFailed: false };
    case actions.MOVED_CONVERSATION_TO_CONTEXT_GROUP:
      return { ...state, movingConversation: false, movedConversation: true, moveConversationFailed: false };
    case actions.MOVE_CONVERSATION_TO_CONTEXT_GROUP_FAILED:
      return { ...state, movingConversation: false, movedConversation: false, moveConversationFailed: true };

    case actions.BULK_MOVING_CONVERSATIONS_TO_CONTEXT_GROUP:
      return {
        ...state,
        bulkMovingConversation: true,
        bulkMovedConversation: false,
        bulkMoveConversationFailed: false
      };
    case actions.BULK_MOVED_CONVERSATIONS_TO_CONTEXT_GROUP:
      return {
        ...state,
        bulkMovingConversation: false,
        bulkMovedConversation: true,
        bulkMoveConversationFailed: false
      };
    case actions.BULK_MOVE_CONVERSATIONS_TO_CONTEXT_GROUP_FAILED:
      return {
        ...state,
        bulkMovingConversation: false,
        bulkMovedConversation: false,
        bulkMoveConversationFailed: true
      };
    case actions.SET_CONTEXT_GROUPS_SORT_BY:
      if (state.contextGroupsSortBy === action.sortBy) {
        return state;
      }

      let sortByValue = [...state.value];
      sortContextGroupsBy(sortByValue, action.sortBy);

      return { ...state, contextGroupsSortBy: action.sortBy, value: sortByValue };
    case actions.SET_CONTEXT_GROUPS_SORT_DIRECTION:
      if (state.contextGroupsSortDirection === action.sortDirection) {
        return state;
      }

      let sortDirectionValue = [...state.value];
      sortContextGroupsBy(sortDirectionValue, this.state.contextGroupsSortBy, action.sortDirection);

      return { ...state, contextGroupsSortDirection: action.sortDirection, value: sortDirectionValue };
    case actions.SET_CONTEXT_GROUPS_SORT:
      if (state.contextGroupsSortBy === action.sortBy && state.contextGroupsSortDirection === action.sortDirection) {
        return state;
      }

      let sortValue = [...state.value];
      sortContextGroupsBy(
        sortValue,
        action.sortBy || state.contextGroupsSortBy,
        action.sortDirection || state.contextGroupsSortDirection
      );
      return {
        ...state,
        contextGroupsSortBy: action.sortBy,
        contextGroupsSortDirection: action.sortDirection,
        value: sortValue
      };
    case actions.CLEAR_DATA:
      return defaultConversationsState;
    default:
      return state;
  }
}

function compareActiveCounts(a, b) {
  let aCount = a.activeCounts.unassigned + a.activeCounts.assignedToMe + a.activeCounts.assignedToOthers;
  let bCount = b.activeCounts.unassigned + b.activeCounts.assignedToMe + b.activeCounts.assignedToOthers;

  if (aCount === bCount) {
    return 0;
  } else if (aCount < bCount) {
    return -1;
  } else {
    return 1;
  }
}

function sortContextGroupsBy(contextGroups, sortBy, givenSortDirection) {
  switch (sortBy) {
    case SortBy.ActiveCount:
      contextGroups.sort((a, b) => {
        let result;
        if (givenSortDirection === SortDirection.Ascending) {
          result = 0 + compareActiveCounts(a, b);
        } else {
          result = 0 - compareActiveCounts(a, b);
        }
        if (result === 0) {
          result = 0 - compareDates(a.mostRecent, b.mostRecent);
        }
        return result;
      });
      break;
    case SortBy.Name:
      contextGroups.sort((a, b) => {
        if (givenSortDirection === SortDirection.Ascending) {
          return 0 - compareStrings(a.displayName, b.displayName);
        } else {
          return 0 + compareStrings(a.displayName, b.displayName);
        }
      });
      break;
    case SortBy.MostRecent:
      contextGroups.sort((a, b) => {
        if (givenSortDirection === SortDirection.Ascending) {
          return 0 + compareDates(a.mostRecent, b.mostRecent);
        } else {
          return 0 - compareDates(a.mostRecent, b.mostRecent);
        }
      });
      break;
    case SortBy.Hidden:
      contextGroups.sort((a, b) => {
        return 0 + compareStrings(a.companyName, b.companyName);
      });
      break;
    default:
      break;
  }
}

export default contextGroupsReducer;
