import util from "util";
import { handlePromiseError } from "./error-actions";
import auth from "../lib/auth";
import { updateConfig, isEmpty, find, coalesceUserName, compareDates } from "../lib/utils";
import { actions as GeneralActions } from "./general-actions";
import Resources from "../lib/resources";
import data from "../lib/data";

export const actions = {
  ...GeneralActions,
  ...{
    SHOW_LOGIN_MODAL: "SHOW_LOGIN_MODAL",
    HIDE_LOGIN_MODAL: "HIDE_LOGIN_MODAL",

    LOGGED_IN: "LOGGED_IN",
    LOGGING_IN: "LOGGING_IN",
    LOGIN_FAILED: "LOGIN_FAILED",
    LOGIN_FAILED_RESET: "LOGIN_FAILED_RESET",
    TOKEN_EXPIRING: "TOKEN_EXPIRING",

    LOGGED_OUT: "LOGGED_OUT",
    LOGGING_OUT: "LOGGING_OUT",
    LOGOUT_FAILED: "LOGOUT_FAILED",

    GETTING_USERS: "GETTING_USERS",
    GOT_USERS: "GOT_USERS",
    GET_USERS_FAILED: "GET_USERS_FAILED",

    VALIDATING_PASSWORD: "VALIDATING_PASSWORD",
    PASSWORD_VALIDATED: "PASSWORD_VALIDATED",
    PASSWORD_VALIDATION_FAILED: "PASSWORD_VALIDATION_FAILED",

    CLEAR_PASSWORD_ERRORS: "CLEAR_PASSWORD_ERRORS",

    GETTING_USER_CONFIG: "GETTING_USER_CONFIG",
    GOT_USER_CONFIG: "GOT_USER_CONFIG",
    GET_USER_CONFIG_FAILED: "GET_USER_CONFIG_FAILED",

    UPDATING_USER_CONFIG: "UPDATING_USER_CONFIG",
    UPDATED_USER_CONFIG: "UPDATED_USER_CONFIG",
    UPDATE_USER_CONFIG_FAILED: "UPDATE_USER_CONFIG_FAILED",

    USER_ACTIVITY: "USER_ACTIVITY",

    FETCHING_COMPANY_USER_SIGNATURES: "FETCHING_COMPANY_USER_SIGNATURES",
    FETCHED_COMPANY_USER_SIGNATURES: "FETCHED_COMPANY_USER_SIGNATURES",
    FETCH_COMPANY_USER_SIGNATURES_FAILED: "FETCH_COMPANY_USER_SIGNATURES_FAILED",

    UPDATING_COMPANY_USER_SIGNATURE: "UPDATING_COMPANY_USER_SIGNATURE",
    UPDATED_COMPANY_USER_SIGNATURE: "UPDATED_COMPANY_USER_SIGNATURE",
    UPDATE_COMPANY_USER_SIGNATURE_FAILED: "UPDATE_COMPANY_USER_SIGNATURE_FAILED",

    UPDATING_SIGNATURE_ATTACHMENTS: "UPDATING_SIGNATURE_ATTACHMENTS",
    UPDATED_SIGNATURE_ATTACHMENTS: "UPDATED_SIGNATURE_ATTACHMENTS",
    UPDATE_SIGNATURE_ATTACHMENTS_FAILED: "UPDATE_SIGNATURE_ATTACHMENTS_FAILED"
  }
};

const login = (userName, password, refresh) => (dispatch, getState) => {
  let user = getState().user;
  if (refresh !== true && (user.isLoggedIn || user.isLoggingIn)) return;
  dispatch({ type: actions.LOGGING_IN, didLoginFail: false });
  return auth
    .login(userName, password)
    .then(response => {
      dispatch(loggedIn(response.identityToken));
      return response.data;
    })
    .catch(response => {
      dispatch({ type: actions.LOGIN_FAILED, didLoginFail: true });
      handlePromiseError(response, "TODO: Logging in failed.", "authorization", () => {
        return;
      });
    });
};

export const loggedIn = token => (dispatch, getState) => {
  window.localStorage.setItem("logged_in", "true");
  window.localStorage.setItem("id_token", token);
  updateConfig({ AccessToken: token });
  return dispatch({ type: actions.LOGGED_IN, didLoginFail: false, token: token });
};

const samlLogin = (provider, saml) => (dispatch, getState) => {
  let user = getState().user;
  if (user.isLoggedIn || user.isLoggingIn) return;
  dispatch({ type: actions.LOGGING_IN });
  auth
    .samlLogin(provider, saml)
    .then(token => {
      dispatch(loggedIn(token));
    })
    .catch(err => {
      return;
    });
};

export const logout = () => (dispatch, getState) => {
  let user = getState().user;
  if (user.isLoggedOut || user.isLoggingOut) return;

  window.localStorage.removeItem("saml");
  window.localStorage.removeItem("saml-provider");
  window.localStorage.removeItem("id_token");
  window.localStorage.removeItem("logged_in");
  updateConfig({ AccessToken: undefined });
  dispatch({ type: actions.CLEAR_DATA });

  dispatch({ type: actions.LOGGING_OUT });
  auth
    .logout()
    .then(response => {
      dispatch({ type: actions.LOGGED_OUT });
    })
    .catch(error => {
      dispatch({ type: actions.LOGOUT_FAILED });
      handlePromiseError(error, "TODO: Logging out failed", "logout");
    });

  window.location = "/#/login";
};

export const getUsers = (companyId, userIds) => (dispatch, getState) => {
  let user = getState().user;
  if (user.gettingUsers) return;
  dispatch({ type: actions.GETTING_USERS, companyId });
  auth
    .post("v1/user/list", userIds)
    .then(response => {
      if (util.isString(response.data)) dispatch({ type: actions.GET_USERS_FAILED, companyId });
      else dispatch({ type: actions.GOT_USERS, users: response.data, companyId });
    })
    .catch(error => {
      dispatch({ type: actions.GET_USERS_FAILED, companyId });
      handlePromiseError(error, "TODO: Getting the list of users failed", "user list");
    });
};

const getUser = userId => (dispatch, getState) => {
  if (isEmpty(userId)) return { userId: null };
  return getState().user.userLookup[userId] || {};
};

const getUserDisplayName = (userId, isMeDisplayName) => (dispatch, getState) => {
  if (isEmpty(userId)) return Resources.Anyone;
  let userData = getState().user;
  let myUserId = userData.decoded.sub || "";

  let user = userData.userLookup[userId] || {};
  return (user.userId || "").toLowerCase() === (myUserId || "").toLowerCase()
    ? isMeDisplayName || Resources.Me
    : coalesceUserName(user);
};

export const getMyUserIdFromToken = () => (dispatch, getState) => {
  return (getState().user.decoded || {}).sub;
};

const getMyDisplayNameFromToken = () => (dispatch, getState) => {
  let decoded = getState().user.decoded || {};
  return decoded.name || decoded.given_name;
};

const getMyUserNameFromToken = () => (dispatch, getState) => {
  return (getState().user.decoded || {}).preferred_username;
};

const isUserImpersonated = () => (dispatch, getState) => {
  let decoded = getState().user.decoded;
  let azp = (decoded || {}).azp;
  return !isEmpty(azp);
};

let RESET_PASSWORD_PROMISE = null;
const resetPassword = (email, userName) => dispatch => {
  RESET_PASSWORD_PROMISE = auth.post("v1/user/reset", email ? { email } : { userName }).then(response => {
    if (response.data === "OK") {
      return "EMAIL_SENT";
    }
  });

  return RESET_PASSWORD_PROMISE;
};

let CONFIRM_RESET_PASSWORD_PROMISE = null;
const confirmResetPassword = (email, password, token) => dispatch => {
  let payload = {
    email: email,
    password: password,
    token: token
  };

  CONFIRM_RESET_PASSWORD_PROMISE = auth
    .post("v1/user/reset/confirm", payload)
    .then(response => {
      if (response.data === "OK") {
        return "PASSWORD_RESET_SUCCESSFUL";
      }
    })
    .catch(response => {
      return "PASSWORD_RESET_FAILED";
    });
  return CONFIRM_RESET_PASSWORD_PROMISE;
};

let IS_PASSWORD_VALID_PROMISE;
const isPasswordValid = password => dispatch => {
  const MIN_SECURITY_LEVEL = 1;
  dispatch({ type: actions.VALIDATING_PASSWORD });
  IS_PASSWORD_VALID_PROMISE = auth
    .post("v1/user/validate/password", '"' + password + '"', {
      headers: {
        "Content-Type": "application/json; charset=utf-8"
      }
    })
    .then(response => {
      let { score, missingRequiredChars, tooShort } = response.data;
      let isPasswordScoreValid = score >= MIN_SECURITY_LEVEL;
      let isPasswordValid = isPasswordScoreValid && missingRequiredChars === false && tooShort === false;
      if (isPasswordValid) {
        dispatch({
          type: actions.PASSWORD_VALIDATED,
          isPasswordScoreValid,
          isPasswordLengthValid: tooShort === false,
          isPasswordCharsValid: missingRequiredChars === false,
          isPasswordValid
        });
      } else {
        let validatePasswordErrors = [];
        if (tooShort || missingRequiredChars) {
          validatePasswordErrors.push(Resources.MustHaveCorrectPassPatternError);
        } else if (isPasswordScoreValid === false) {
          validatePasswordErrors.push(Resources.PasswordIsWeakError(score));
        }

        dispatch({
          type: actions.PASSWORD_VALIDATION_FAILED,
          validatePasswordErrors
        });
      }
    })
    .catch(e => {
      dispatch({ type: actions.PASSWORD_VALIDATION_FAILED });
    });
  return IS_PASSWORD_VALID_PROMISE;
};

const getUserRole = roleId => {
  switch (roleId) {
    case 0:
      return Resources.User;
    case 1:
      return Resources.Administrator;
    case 2:
      return Resources.Connector;
    default:
      return Resources.Unknown;
  }
};

const getUserConfig = () => (dispatch, getState) => {
  let user = getState().user;
  if (user.gettingUserConfig) return;
  dispatch({ type: actions.GETTING_USER_CONFIG });
  data
    .get("v1/api/user/settings")
    .then(response => {
      dispatch({ type: actions.GOT_USER_CONFIG, config: response.data });
    })
    .catch(error => {
      dispatch({ type: actions.GET_USER_CONFIG_FAILED });
      handlePromiseError(error, "TODO: Getting user settings failed.", "user settings");
    });
};

const updateUserConfig = update => (dispatch, getState) => {
  if (getState().user.isUpdatingUserConfig) {
    return;
  }

  const configUpdate = Object.assign({}, getState().user.userConfig, update);
  dispatch({ type: actions.UPDATING_USER_CONFIG });
  return data
    .post("v1/api/user/settings", configUpdate)
    .then(response => {
      dispatch({ type: actions.UPDATED_USER_CONFIG, configUpdate });
      return true;
    })
    .catch(error => {
      dispatch({ type: actions.UPDATE_USER_CONFIG_FAILED });
      handlePromiseError(error, "TODO: Updating user settings failed.", "user settings");
      throw error;
    });
};

const fetchCompanyUserSignatures = companyId => (dispatch, getState) => {
  if (getState().user.isFetchingCompanyUserSignatures === true) {
    return Promise.reject();
  }
  dispatch({ type: actions.FETCHING_COMPANY_USER_SIGNATURES });

  return data
    .get(`v2/api/company/${companyId}/signatures`)
    .then(response => {
      dispatch({ type: actions.FETCHED_COMPANY_USER_SIGNATURES, userSignatures: response.data });
      return response.data;
    })
    .catch(error => {
      dispatch({ type: actions.FETCH_COMPANY_USER_SIGNATURES_FAILED });
      handlePromiseError(error, "TODO: Fetching user signatures failed.", "signatures");
    });
};

const getCompanyUserSignature = (userId, companyId) => (dispatch, getState) => {
  const store = getState().user;

  if (store.isFetchingCompanyUserSignatures) {
    return null;
  } else if (store.fetchedCompanyUserSignatures === false && store.fetchingCompanyUserSignaturesFailed === false) {
    dispatch(fetchCompanyUserSignatures(companyId));
    return null;
  } else {
    let userSignatures = store.userSignatures.filter(s => s.userId.toLowerCase() === userId.toLowerCase());

    if (userSignatures.length > 1) {
      userSignatures.sort((a, b) => {
        return compareDates(a.createdDate, b.createdDate);
      });
    }

    return userSignatures[0] || {};
  }
};

const updateSignatureAttachments = (companyId, companySignatureId, uploads) => (dispatch, getState) => {
  const formData = new FormData();

  uploads.forEach(u => {
    formData.append("files", u);
  });

  dispatch({ type: actions.UPDATING_SIGNATURE_ATTACHMENTS });

  return data
    .post(`v2/api/company/${companyId}/signatures/${companySignatureId}/attachments`, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
    .then(response => {
      dispatch({ type: actions.UPDATED_SIGNATURE_ATTACHMENTS });
      dispatch(fetchCompanyUserSignatures(companyId));
      return response.data;
    })
    .catch(error => {
      dispatch({ type: actions.UPDATE_SIGNATURE_ATTACHMENTS_FAILED });
      handlePromiseError(error, "TODO: Adding the image failed. Please try again.", "signature image");
      throw error;
    });
};

const updateCompanyUserSignature = (companyId, signatureData) => (dispatch, getState) => {
  if (getState().user.isUpdatingCompanyUserSignature) {
    return;
  }

  dispatch({ type: actions.UPDATING_COMPANY_USER_SIGNATURE });

  return data
    .post(`v2/api/company/${companyId}/signatures`, signatureData)
    .then(response => {
      dispatch({ type: actions.UPDATED_COMPANY_USER_SIGNATURE });
      dispatch(fetchCompanyUserSignatures(companyId));
      return response.data;
    })
    .catch(error => {
      dispatch({ type: actions.UPDATE_COMPANY_USER_SIGNATURE_FAILED });
      handlePromiseError(error, "TODO: Updating your signature failed. Please try again.", "signature");
      throw error;
    });
};

const isCompanyAdmin = () => (dispatch, getState) => {
  const { user, accounts } = getState();
  if (isEmpty(user) || isEmpty(user.decoded)) return null;
  let myUserId = user.decoded.sub;
  let myRole =
    find(accounts.companyRoles, role => {
      return role.userId.toLowerCase() === myUserId.toLowerCase();
    }) || {};
  if (isEmpty(myRole) && accounts.fetchedCompanyRoles === false) {
    return null;
  }
  return myRole.role === 1;
};

export const dispatchToProps = dispatch => ({
  login: (userName, password) => {
    return dispatch(login(userName, password));
  },
  tokenExpiring: () => {
    dispatch({ type: actions.TOKEN_EXPIRING });
  },
  refreshToken: () => {
    dispatch(login(null, null, true));
  },
  samlLogin: (provider, saml) => {
    dispatch(samlLogin(provider, saml));
  },
  logout: () => {
    dispatch(logout());
  },
  showLoginModal: () => {
    dispatch({ type: actions.SHOW_LOGIN_MODAL });
  },
  hideLoginModal: () => {
    dispatch({ type: actions.HIDE_LOGIN_MODAL });
  },
  isCompanyAdmin: () => {
    return dispatch(isCompanyAdmin());
  },
  loggedIn: token => {
    dispatch(loggedIn(token));
  },
  loginFailedReset: () => {
    dispatch({ type: actions.LOGIN_FAILED_RESET });
  },

  getUsers: (companyId, userIds) => {
    dispatch(getUsers(companyId, userIds));
  },
  getUser: userId => {
    return dispatch(getUser(userId));
  },
  getUserDisplayName: (userId, isMeDisplayName) => {
    return dispatch(getUserDisplayName(userId, isMeDisplayName));
  },
  getUserRole: roleId => {
    return getUserRole(roleId);
  },

  getMyUserIdFromToken: () => {
    return dispatch(getMyUserIdFromToken());
  },
  getMyDisplayNameFromToken: () => {
    return dispatch(getMyDisplayNameFromToken());
  },
  getMyUserNameFromToken: () => {
    return dispatch(getMyUserNameFromToken());
  },

  isUserImpersonated: () => {
    return dispatch(isUserImpersonated());
  },
  resetPassword: (email, username) => {
    return dispatch(resetPassword(email, username));
  },
  confirmResetPassword: (email, password, token) => {
    return dispatch(confirmResetPassword(email, password, token));
  },
  isPasswordValid: password => {
    return dispatch(isPasswordValid(password));
  },
  clearPasswordErrors: () => {
    return dispatch({ type: actions.CLEAR_PASSWORD_ERRORS });
  },

  getUserConfig: () => {
    dispatch(getUserConfig());
  },
  updateUserConfig: update => {
    return dispatch(updateUserConfig(update));
  },

  updateCompanyUserSignature: (companyId, signatureData) => {
    return dispatch(updateCompanyUserSignature(companyId, signatureData));
  },

  getCompanyUserSignature: (userId, companyId) => {
    return dispatch(getCompanyUserSignature(userId, companyId));
  },

  updateSignatureAttachments: (companyId, companySignatureId, upload) => {
    return dispatch(updateSignatureAttachments(companyId, companySignatureId, upload));
  },

  userActivity: () => {
    dispatch({ type: actions.USER_ACTIVITY });
  }
});
