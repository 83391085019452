import React, { Component } from "react";
import Resources from "../lib/resources";

class RegGettingVerified extends Component {
  componentDidMount() {
    window.localStorage.removeItem("registration-stage");
    window.localStorage.removeItem("is_registering");
  }

  render() {
    return (
      <div className="">
        <div className="w-40 mx-auto mt-5 p-3">
          <h3>{Resources.RegVerifyEmailBlurb1}</h3>
          <p>
            {Resources.RegVerifyEmailBlurb2} <b>{Resources.RegVerifyEmailBlurb3}</b>
          </p>

          <p>
            {Resources.RegVerifyEmailBlurb4} – {/* TODO: what do we want here for the link? */}
            <a href={window.location.hash}>{Resources.RegVerifyEmailBlurb5}</a>
          </p>

          <p>
            {Resources.RegVerifyEmailBlurb6} {/* TODO: what do we want here for the link? */}
            <a href={window.location.hash}>{Resources.RegVerifyEmailBlurb7}</a>
          </p>
        </div>
      </div>
    );
  }
}

export default RegGettingVerified;
