import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { Route } from "react-router-dom";

import HomeView from "./views/homeView";
import CommunicationsView from "./views/communicationsView";
import NetworkView from "./views/network/networkView";
import SearchView from "./views/searchView";
import ProfileManagementView from "./views/profileManagement/profileManagementView";

class ViewController extends Component {
  render() {
    const {
      match: { params }
    } = this.props;

    let viewContent;

    switch (params.view) {
      case "home":
        viewContent = <HomeView />;
        break;
      case "network":
        viewContent = <NetworkView />;
        break;
      case "search":
        viewContent = <SearchView />;
        break;
      //TODO: This needs rework, only added to drop in activities
      case "activities":
      case "documents":
      case "statements":
      case "profile":
      case "settings":
        viewContent = <CommunicationsView />;
        break;
      case "management":
        viewContent = <ProfileManagementView />;
        break;
      case "sequences":
      default:
        viewContent = <CommunicationsView />;
        break;
    }

    return (
      <Route path="/v2/:companyId/:perspectiveId/:view/:subView?/:selectedItem?">
        <div className="lockstep-view">{viewContent}</div>
      </Route>
    );
  }
}

export default withRouter(ViewController);
