import React, { Component } from "react";
import { connect } from "react-redux";
import Resources from "../../../lib/resources";

import { dispatchToProps as uaDP } from "../../../store/user-actions";
import { dispatchToProps as caDP } from "../../../store/conversations-actions";
import { dispatchToProps as laDP } from "../../../store/ledger-actions";
import { dispatchToProps as gaDP } from "../../../store/general-actions";

import RadioInput from "../../library/radioInput";
import TextAreaInput from "../../library/textAreaInput";

import { isEmpty } from "../../../lib/utils";
import Attachment from "../../attachment";

const dispatchToProps = dispatch => {
  return {
    ...uaDP(dispatch),
    ...caDP(dispatch),
    ...laDP(dispatch),
    ...gaDP(dispatch)
  };
};

class TaskThreadItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      comment: "",
      selectedAction: "approval"
    };
  }

  renderActionForm() {
    const { entry, isApprovalTask, isResponseTask, params } = this.props;

    const conversationStatusName = (
      (this.props.getConversationStatus(this.props.conversation.conversationStatusId) || {}).conversationStatusName ||
      ""
    ).toLowerCase();

    if (
      this.props.conversation.assignedUserId !== this.props.getMyUserIdFromToken() ||
      conversationStatusName === "closed"
    ) {
      return null;
    }

    let actionForm = null;

    const approvalOptions = [
      { label: Resources.Approve, value: "approval" },
      { label: Resources.Reject, value: "rejection" }
    ];

    if (isApprovalTask) {
      actionForm = (
        <div className="conversation-thread-item max-width-600">
          <div>
            <RadioInput
              inline
              inputs={approvalOptions}
              radioName="testing"
              value={this.state.selectedAction}
              onChange={item => {
                this.setState({
                  selectedAction: item.value
                });
              }}
            />
            <TextAreaInput
              textValue={this.state.comment}
              label={Resources.AddComment}
              inputOnChange={e => this.setState({ comment: e.target.value })}
              placeholder={Resources.AddOptionalNote}
              rows={4}
              className="mt-4"
            />
            <div className="flex-end">
              <button
                className="button-green"
                onClick={() => {
                  this.props
                    .createEntry(this.props.companyId, this.state.selectedAction, {
                      htmlBody: this.state.textValue,
                      textBody: this.state.comment,
                      inReplyTo: this.props.conversationId,
                      from: entry.asJson.from,
                      to: entry.asJson.from
                    })
                    .then(res => {
                      const text =
                        this.state.selectedAction === "approval"
                          ? Resources.RequestApproved
                          : Resources.RequestRejected;
                      const previousPath = this.props.location.pathname.slice(
                        0,
                        this.props.location.pathname.indexOf(`/${params.selectedItemId}`)
                      );
                      this.props.refreshConversations(this.props.companyId, this.props.perspective);
                      this.props.history.push(previousPath);
                      this.props.showToast({
                        type: "blue",
                        text,
                        linkText: Resources.View,
                        linkAction: () => this.props.history.push(this.props.location.pathname),
                        linkHidden: false
                      });
                    })
                    .catch(err => null);
                }}
              >
                {Resources.Submit}
              </button>
            </div>
          </div>
        </div>
      );
    } else if (isResponseTask) {
      actionForm = (
        <div className="conversation-thread-item max-width-600">
          <div>
            <TextAreaInput
              textValue={this.state.comment}
              label={Resources.AddResponse}
              inputOnChange={e => this.setState({ comment: e.target.value })}
              rows={4}
              className="mt-4"
            />
            <div className="flex-end">
              <button
                className="button-green float-right"
                disabled={isEmpty(this.state.comment.trim())}
                onClick={() => {
                  this.props
                    .createEntry(this.props.companyId, "response", {
                      htmlBody: this.state.textValue,
                      textBody: this.state.comment,
                      inReplyTo: this.props.conversationId,
                      from: entry.asJson.from,
                      to: entry.asJson.from
                    })
                    .then(res => {
                      const text = Resources.YouResponded;
                      const previousPath = this.props.location.pathname.slice(
                        0,
                        this.props.location.pathname.indexOf(`/${params.selectedItemId}`)
                      );
                      this.props.refreshConversations(this.props.companyId, this.props.perspective);
                      this.props.history.push(previousPath);
                      this.props.showToast({
                        type: "blue",
                        text,
                        linkText: Resources.View,
                        linkAction: () => this.props.history.push(this.props.location.pathname),
                        linkHidden: false
                      });
                    })
                    .catch(err => null);
                }}
              >
                {Resources.Respond}
              </button>
            </div>
          </div>
        </div>
      );
    }

    return actionForm;
  }

  render() {
    const {
      attachments,
      dateText,
      emailBody,
      originalEmailBody,
      posterName,
      assignedTo,
      isApprovalTask,
      isResponseTask
    } = this.props;

    let titleNote = <span>{Resources.TaskCreationMessage(posterName, assignedTo)}</span>;

    if (isApprovalTask) {
      titleNote = Resources.AssignedApprovalTask(posterName, assignedTo);
    } else if (isResponseTask) {
      titleNote = Resources.AssignedResponseTask(posterName, assignedTo);
    }

    return (
      <React.Fragment>
        <div className="conversation-thread-item system-message-item">
          <div>
            {isApprovalTask ? Resources.AssignedApprovalTask(posterName, assignedTo) : titleNote}
            <span className="float-right" style={{ textAlign: "right" }}>
              <div className="thread-item-age">
                {dateText} {Resources.Ago.toLowerCase()}
              </div>
            </span>
          </div>
        </div>

        <div className="conversation-thread-item">
          <div>
            <br />
            <div dangerouslySetInnerHTML={{ __html: emailBody }} />
          </div>
          {isEmpty(attachments) === false && (
            <div>
              <br />
              {attachments.map(att => {
                return (
                  <Attachment
                    attachment={att}
                    key={att.attachmentId}
                    showIcon={true}
                    name={att.fileName}
                    size={att.sizeBytes}
                  />
                );
              })}
            </div>
          )}
        </div>
        {this.renderActionForm()}
        {(isApprovalTask || isResponseTask) && (
          <div className="conversation-thread-item">
            <div>
              <br />
              <div dangerouslySetInnerHTML={{ __html: originalEmailBody }} />
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

const storeToProps = store => {
  return {};
};

export default connect(
  storeToProps,
  dispatchToProps
)(TaskThreadItem);
