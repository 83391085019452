import React, { Component } from "react";

class IconPen extends Component {
  render() {
    let { className, height } = this.props;
    return (
      <svg
        height={height}
        className={`icon ${className ? className : ""}`}
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        viewBox="0 0 24 24"
      >
        <defs />
        <path d="M12 19l7-7 3 3-7 7-3-3z" />
        <path d="M18 13l-1.5-7.5L2 2l3.5 14.5L13 18l5-5zM2 2l7.586 7.586" />
        <circle cx="11" cy="11" r="2" />
      </svg>
    );
  }
}

IconPen.defaultProps = {
  height: 22
};

export default IconPen;
