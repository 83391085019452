import React, { Component } from "react";

class IconExport extends Component {
  render() {
    let { className, height } = this.props;

    return (
      <svg viewBox="0 0 20 20" height={height} className={`icon ${className ? className : ""}`}>
        <path
          d="M16 11v6c0 1.1045695-.8954305 2-2 2H3c-1.1045695 0-2-.8954305-2-2V6c0-1.1045695.8954305-2 2-2h6m4-3h6v6M8 12L19 1"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          stroke="currentColor"
          fill="none"
          fillRule="evenodd"
        />
      </svg>
    );
  }
}

IconExport.defaultProps = {
  height: 20
};

export default IconExport;
