import React, { Component } from "react";
import "./company-select.scss";
import Resources from "../../lib/resources";
import { connect } from "react-redux";

import { dispatchToProps as uaDP } from "../../store/user-actions";
import { dispatchToProps as aaDP } from "../../store/accounts-actions";
import { dispatchToProps as convDP } from "../../store/conversations-actions";
import { dispatchToProps as lgrDP } from "../../store/ledger-actions";
import { dispatchToProps as genDP } from "../../store/general-actions";
import { dispatchToProps as connDP } from "../../store/connector-actions";
import { dispatchToProps as persDP } from "../../store/perspectives-actions";
import { dispatchToProps as contDP } from "../../store/contextGroups-actions";

import { isEmpty, includes } from "../../lib/utils";
import MainContentHeader from "../main_content_header/mainContentHeader";
import MainLoader from "../mainLoader";

const dispatchToProps = dispatch => ({
  ...uaDP(dispatch),
  ...aaDP(dispatch),
  ...lgrDP(dispatch),
  ...convDP(dispatch),
  ...genDP(dispatch),
  ...connDP(dispatch),
  ...persDP(dispatch),
  ...contDP(dispatch)
});

class CompanySelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      noPerspectivesError: false
    };
  }
  selectCompany(companyId) {
    if (isEmpty(companyId)) {
      return;
    }

    this.props.selectCompany(companyId);
    this.props.fetchCompanyUserRoles(companyId);
  }

  componentDidMount() {
    this.props.fetchAllConnectors();
    this.tryUpdate();
  }

  componentDidUpdate(prevProps) {
    this.tryUpdate(prevProps);
  }

  tryUpdate(prevProps = {}) {
    let { user, accounts, connectorStore } = this.props;
    const prevConnectorStore = prevProps.connectorStore || {};
    if (user.isLoggedIn) {
      if (
        prevConnectorStore.allSupportedConnectorsIsFetched !== true &&
        connectorStore.allSupportedConnectorsIsFetched === true
      ) {
        connectorStore.allSupportedConnectors.forEach(connector => {
          if (connector.isInternal !== true) {
            this.props.fetchConnectorVersionData(connector.connectorId);
          }
        });
      }

      if (accounts.hasFetchedAccounts === false) {
        if (accounts.fetchingAccounts === false) {
          this.props.fetchAccounts();
        }
      } else if (
        accounts.accounts.length === 0 &&
        accounts.hasAssumedAccount === false &&
        user.decoded.idp !== "local"
      ) {
        this.props.assumeAccount();
      }

      if (user.gettingUserConfig === false && user.fetchedUserConfig === false) {
        this.props.getUserConfig();
      }

      const subscription = this.props.getSubscription() || {};

      if (user.fetchedUserConfig && accounts.hasFetchedAccounts) {
        let companyId = this.props.getDefaultCompany(this.props.user.userConfig.defaultCompanyId).companyId;
        if (isEmpty(companyId)) {
          return;
        }

        const companyPerspectives = this.props.getCompanyPerspectives(companyId);

        if (isEmpty(companyPerspectives)) {
          if (this.state.noPerspectivesError !== true && Array.isArray(companyPerspectives)) {
            this.setState({ noPerspectivesError: true });
          }
          return;
        }

        let perspectiveId = this.props.user.userConfig.defaultPerspective;

        if (includes(companyPerspectives.map(p => p.perspectiveId), perspectiveId) !== true) {
          perspectiveId = companyPerspectives[0].perspectiveId;
        }

        if (isEmpty(subscription)) {
          return;
        }

        if ((subscription.subscriptionTypeName || "").toLowerCase() === "customer portal") {
          this.props.history.push("/customer-portal");
        } else {
          if (this.props.location.pathname === "/disconnect") {
            this.props.history.push(`/company/${companyId}/manage/packages`);
          } else if (this.props.location.pathname === "/") {
            window.location = "/#/v2";
            //window.location = `/#/company/${companyId}/${perspectiveId}`;
          }
        }
      }
    }
  }

  render() {
    if (this.state.noPerspectivesError === true) {
      const companies = [];
      const defaultCompanyId = this.props.getDefaultCompany(this.props.user.userConfig.defaultCompanyId).companyId;
      let defaultCompany;
      this.props.accounts.accounts.forEach(account => {
        account.companies.forEach(company => {
          if (company.companyId === defaultCompanyId) {
            defaultCompany = company;
          } else {
            companies.push(company);
          }
        });
      });

      return (
        <React.Fragment>
          <MainContentHeader hideSearch={true} />
          <div className="flex-center align-center h-75 mt-5">
            <div className="flex-column flex-center text-center my-5">
              <h1 className="warning-color-font">{Resources.UhOh}</h1>
              <h2 className="mb-5">
                {Resources.NoWorkspaceAccess} {defaultCompany.companyName}
              </h2>
              <h3>{Resources.ContactAnAdministrator}</h3>
            </div>
          </div>
        </React.Fragment>
      );
    }

    return <MainLoader fullScreen={true} />;
  }
}

const storeToProps = store => {
  return {
    accounts: store.accounts,
    user: store.user,
    connectorStore: store.connector,
    perspectivesStore: store.perspectives,
    contextGroupsStore: store.contextGroups
  };
};

export default connect(
  storeToProps,
  dispatchToProps
)(CompanySelect);
