import { Component } from "react";
import React from "react";
import Resources from "../../../lib/resources";
import { connect } from "react-redux";
import { dispatchToProps as netDP } from "../../../store/network-actions";
import { generatePath, withRouter } from "react-router-dom";
import MainLoader from "../../mainLoader";

const dispatchToProps = dispatch => ({
  ...netDP(dispatch)
});

class SuccessPage extends Component {
  componentDidMount() {
    this.props.getSelfServiceStatus();
  }

  renderSuccesPage(lockstepverified) {
    if (lockstepverified) {
      return (
        <div className="profile-update-success-container-verified">
          <h2 className="first-time-title-requests">{Resources.LockstepSucess}</h2>
          {/* <div className="success-page-marketing-video-placeholder">marketingvideo.exe not found</div> */}
          {this.props.networkStore.selfServiceStatus.claim ? (
            <button
              disabled={!this.props.networkStore.selfServiceStatus.claim}
              onClick={() => {
                this.props.fetchCustAccount(this.props.match.params.accessId, this.props.match.params.mode);
                this.props.fetchCustAccountContacts(this.props.match.params.accessId, this.props.match.params.mode);
                this.props.history.push(
                  generatePath("/signup/account/login/:mode/:accessId ", {
                    accessId: this.props.accessId,
                    mode: this.props.mode
                  })
                );
              }}
              className="button-primary-square large"
            >
              {Resources.ClaimYourProfile}
            </button>
          ) : null}
        </div>
      );
    }
  }

  render() {
    if (this.props.networkStore.gettingSelfServiceStatus) {
      return <MainLoader className="activity-loader" fullScreen={true} />;
    } else {
      return (
        <div>
          <div className="profile-update-done-icon-container">
            <svg viewBox="0 0 268 151">
              <defs>
                <filter id="a" width="101%" height="101.1%" x="-.5%" y="-.4%" filterUnits="objectBoundingBox">
                  <feMorphology in="SourceAlpha" operator="dilate" radius=".5" result="shadowSpreadOuter1" />
                  <feOffset dy="1" in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
                  <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="1.5" />
                  <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1" />
                  <feColorMatrix
                    in="shadowBlurOuter1"
                    values="0 0 0 0 0.435294118 0 0 0 0 0.509803922 0 0 0 0 0.556862745 0 0 0 1 0"
                  />
                </filter>
                <rect id="b" width="1147" height="1024" x="0" y="0" rx="2" />
              </defs>
              <g fill="none" fill-rule="evenodd">
                <path fill="#FFF" d="M-733-57H707V967H-733z" />
                <path fill="#F8FBFC" d="M-733 94H707v1300H-733z" />
                <path fill="#FFF" d="M-733-57H707V95H-733z" />
                <path fill="#3E5969" d="M-733 94H707v1H-733z" opacity=".2" />
                <text
                  fill="#0F3044"
                  font-family="WorkSans-SemiBold, Work Sans"
                  font-size="14"
                  font-weight="500"
                  letter-spacing=".25"
                  transform="translate(-733 63)"
                >
                  <tspan x="723" y="13">
                    {" "}
                    CONTACT INFO{" "}
                  </tspan>
                </text>
                <rect
                  width="1027"
                  height="143"
                  x=".5"
                  y=".5"
                  fill="#FFF"
                  stroke="#DBE0E3"
                  rx="2"
                  transform="translate(-527 134)"
                />
                <path fill="#6F828E" d="M-733-57H707V967H-733z" opacity=".5" />
                <g transform="translate(-440 -57)">
                  <use fill="#000" filter="url(#a)" href="#b" />
                  <use fill="#F8FBFC" stroke="#6F828E" href="#b" />
                </g>
                <path
                  fill="#F4F3FF"
                  d="M147.648148 25.2222222c4.796695 0 8.685185 3.8884899 8.685185 8.6851852s-3.88849 8.6851852-8.685185 8.6851852h79.407408c4.796695 0 8.685185 3.8884899 8.685185 8.6851852s-3.88849 8.6851852-8.685185 8.6851852h27.296296c4.796695 0 8.685185 3.8884898 8.685185 8.6851851 0 4.7966954-3.88849 8.6851852-8.685185 8.6851852h-23.574074c-4.796696 0-8.685185 3.8884899-8.685185 8.6851852 0 4.7966955 3.888489 8.6851855 8.685185 8.6851855h7.444444c4.796696 0 8.685185 3.88849 8.685185 8.685185 0 4.796695-3.888489 8.685185-8.685185 8.685185H57.0740741c-4.7966954 0-8.6851852-3.88849-8.6851852-8.685185 0-4.796695 3.8884898-8.685185 8.6851852-8.685185H8.68518519C3.88848986 94.703704 0 90.815214 0 86.0185185c0-4.7966953 3.88848986-8.6851852 8.68518519-8.6851852H58.3148148C63.1115101 77.3333333 67 73.4448435 67 68.6481481c0-4.7966953-3.8884899-8.6851851-8.6851852-8.6851851H27.2962963c-4.7966953 0-8.6851852-3.8884899-8.6851852-8.6851852s3.8884899-8.6851852 8.6851852-8.6851852h49.6296296c-4.7966953 0-8.6851852-3.8884899-8.6851852-8.6851852s3.8884899-8.6851852 8.6851852-8.6851852h70.7222221zM259.314815 94.703704c4.796695 0 8.685185 3.88849 8.685185 8.685185 0 4.796695-3.88849 8.685185-8.685185 8.685185-4.796696 0-8.685185-3.88849-8.685185-8.685185 0-4.796695 3.888489-8.685185 8.685185-8.685185z"
                />
                <path
                  fill="#FFF"
                  stroke="#BEB6FF"
                  stroke-linecap="round"
                  stroke-width="2.5"
                  d="M173.54649283 122.1478049c3.76947476-2.42572731 7.12092926-5.45215366 9.3013129-7.8954673m3.51851243-4.06026485h0c1.80347372-2.20901042 3.42464954-4.51915419 4.86320862-6.9101384 3.1639455-5.25869584 5.44451896-10.90843808 6.83833-16.73331319 1.40964052-5.89103312 1.91226168-11.96120051 1.50435307-17.98714662-.3559387-5.2582176-1.40517607-10.48276444-3.15004216-15.52524112-1.73108935-5.00266118-4.14686498-9.82611643-7.24960459-14.3254534-1.84887436-2.68108611-3.94167458-5.24708484-6.27888253-7.66733453-3.4314273-3.55334698-7.19521552-6.59115054-11.19433732-9.11154697-4.2969251-2.70808402-8.86553773-4.81883222-13.58547867-6.32993382-5.91108815-1.89244962-12.05952236-2.84440308-18.2088914-2.85132-4.78457951-.00538174-9.56972497.56134061-14.24408111 1.70230517-5.26239015 1.28449805-10.38436313 3.29680517-15.2070314 6.0399734-4.2394197 2.4114122-8.24755195 5.38759595-11.91646404 8.93062316-4.6106775 4.45247952-8.34540064 9.47159272-11.20080367 14.84311938-2.79556651 5.25896311-4.74828106 10.85572531-5.85498472 16.589253-1.140196 5.90704268-1.38240117 11.95925506-.72316207 17.93679494.85445246 7.74760557 3.22326412 15.36976696 7.1139536 22.38780375 2.48953938 4.49063826 5.60218865 8.73392694 9.33991763 12.60445861 5.933694 6.14452 12.86121502 10.74746014 20.28085866 13.7991868 9.02295432 3.71117412 18.773696 5.1282967 28.34993563 4.23404059 8.03716826-.75053294 15.95141796-3.12918736 23.20932962-7.14620837"
                />
                <path
                  stroke="#BEB6FF"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2.5"
                  d="M197.8981478 1.648148v7.44444444m0 9.92592596v7.4444444m-12.40740661-12.40740739h7.44444441m9.92592559-4.1e-7h7.44444441M94.9166663 129.444444v4.96296296m0 9.92592594v4.962963m-9.92592586-9.92592575h4.96296296m9.92592604 1.5e-7h4.96296296"
                />
                <path
                  fill="#FFF"
                  stroke="#BEB6FF"
                  stroke-linecap="round"
                  stroke-width="2.5"
                  d="M121.8189258 23.61045056c-2.52960923.94901384-5.0005774 2.10219544-7.38773583 3.46002825-3.70949254 2.10998591-7.21660747 4.71414606-10.4269059 7.81429522-1.3159588 1.27080664-2.5503663 2.59436596-3.70312126 3.96417378m-3.56702122 4.76924487c-.91661582 1.38512195-1.76016503 2.80505745-2.53056118 4.25431111-.5689881 1.0703689-1.0980727 2.15673023-1.58721918 3.25687024"
                />
                <path
                  fill="#F4F3FF"
                  fill-rule="nonzero"
                  stroke="#BEB6FF"
                  stroke-width="2.5"
                  d="M116.605033 71.5868668c-1.806891-1.6276309-4.609712-1.5026651-6.260277.2791188-1.650565 1.781784-1.523839 4.5456608.283052 6.1732917l20.854786 18.7858067c2.529646 2.278683 6.453596 2.103731 8.764388-.390766.096629-.105952.096629-.105952.189875-.214811l29.761334-35.2968495c1.564631-1.855647 1.307519-4.6107058-.574275-6.1535962-1.881794-1.5428903-4.675673-1.289351-6.240304.566296L135.403175 88.520067l-18.798142-16.9332002z"
                />
              </g>
            </svg>
          </div>
          {this.renderSuccesPage(this.props.lockstepverified)}
        </div>
      );
    }
  }
}

const storeToProps = store => {
  return {
    networkStore: store.network
  };
};

export default withRouter(connect(storeToProps, dispatchToProps)(SuccessPage));
