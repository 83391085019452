import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from "react-modal";

import { dispatchToProps } from "../store/modal-actions";

import Resources from "../lib/resources";

class UnmappedPropertyModal extends Component {
  render() {
    return (
      <Modal
        isOpen={this.props.modal.showModal}
        onRequestClose={this.props.hideModal}
        className="custom-modal"
        overlayClassName="custom-modal-overlay"
      >
        <div className="custom-modal-header">{Resources.Warning}</div>
        <div className="custom-modal-divider" />
        <div className="custom-modal-content">
          <h6 className="my-3">{Resources.UnableToPopulateMessage}</h6>
          <ul className="mb-3">
            {this.props.modal.modalProps.unmappedProperties.map((property, i) => {
              return <li key={i}>{property}</li>;
            })}
          </ul>
          <div>{Resources.PleaseFillManually}</div>
        </div>

        <div className="flex-row modal-buttons">
          <button className="button-cancel float-left mr-1" onClick={this.props.hideModal}>
            {Resources.Close}
          </button>
        </div>
      </Modal>
    );
  }
}

const storeToProps = store => {
  return {
    modal: store.modal
  };
};

export default connect(
  storeToProps,
  dispatchToProps
)(UnmappedPropertyModal);
