import React, { Component } from "react";

class IconArrowRight extends Component {
  render() {
    let { className, height, line } = this.props;

    if (line) {
      return (
        <svg viewBox="0 0 16 16" height={height} className={`icon ${className ? className : ""}`}>
          <g
            fill="none"
            fillRule="evenodd"
            stroke="#0F3044"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          >
            <path d="M1 8h14" />
            <path d="M8 1l7 7-7 7" />
          </g>
        </svg>
      );
    }

    return (
      <svg viewBox="0 0 12 18" height={height} className={`icon ${className ? className : ""}`}>
        <path
          d="M1 1l10 8-10 8z"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          fill="none"
          fillRule="evenodd"
        />
      </svg>
    );
  }
}

IconArrowRight.defaultProps = {
  height: 18
};

export default IconArrowRight;
