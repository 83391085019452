import React, { Component } from "react";
import Resources from "../../../../lib/resources";
import { generatePath, withRouter } from "react-router-dom";
import { dispatchToProps as networkDP } from "../../../../store/network-actions";
import { connect } from "react-redux";
import MainLoader from "../../../mainLoader";

const dispatchToProps = dispatch => ({
  ...networkDP(dispatch)
});

class MarketingVideoView extends Component {
  constructor(props) {
    super(props);
    this.goToNext = this.goToNext.bind(this);
  }

  componentDidMount() {
    this.props.fetchCustContact(this.props.match.params.accessId);
  }

  goToNext() {
    this.props.history.push(
      generatePath(this.props.route, {
        accessId: this.props.match.params.accessId,
        view: this.props.view,
        mode: this.props.match.params.mode,
        accountingGroupKey: this.props.match.params.accountingGroupKey,
        companyGlobalKey: this.props.match.params.companyGlobalKey
      })
    );
  }

  render() {
    if (this.props.networkStore.gotCustContact) {
      return (
        <React.Fragment>
          <h2 className="first-time-title-contact">
            {Resources.ProfileUpdateWelcomeFromATC(this.props.networkStore.custContact.contactName)}
          </h2>
          <div className="success-page-marketing-video-placeholder">Marketing_Video.exe has stopped responding</div>
          <h2 className="first-time-title-contact">{Resources.WeAreExcitedToHaveYou}</h2>
          <div onClick={this.goToNext} className="profile-update-button-container">
            <button className="button-primary-square large">{Resources.YesLetsDoIt}!</button>
          </div>
        </React.Fragment>
      );
    } else {
      return <MainLoader className="profile-update-form-mainloader" fullScreen={true} />;
    }
  }
}

const storeToProps = store => {
  return {
    networkStore: store.network
  };
};

export default withRouter(
  connect(
    storeToProps,
    dispatchToProps
  )(MarketingVideoView)
);
