import React, { Component } from "react";
import { withRouter, Switch, Route, generatePath, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import { dispatchToProps as searchDP } from "../../../store/search-actions";
import { isEmpty, formatDate } from "../../../lib/utils";
import Card from "../../library/card";
import Resources from "../../../lib/resources";
import ViewContentHeader from "./viewContentHeader";
import MainLoader from "../../mainLoader";
import Avatar from "../../library/avatar";

const dispatchToProps = dispatch => ({
  ...searchDP(dispatch)
});

class SearchView extends Component {
  constructor(props) {
    super(props);
    this.renderAccountProfilesAll = this.renderAccountProfilesAll.bind(this);
  }

  renderAccountProfileResult(profile) {
    return (
      <React.Fragment>
        <div className="network-activity-card-avatar">
          <Avatar height="44px" type="account" imgURL={profile.logoURL} />
        </div>
        <div>
          <div className="search-result__account__name">
            {profile.displayName || profile.custName || profile.vendorName}
          </div>
          {profile.profileUpdateDate && (
            <div className="search-result__account__update-date">
              {Resources.LastUpdatedDate(formatDate(profile.profileUpdateDate))}
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }

  renderContactProfileResult(profile) {
    return (
      <React.Fragment>
        <div className="network-activity-card-avatar">
          <Avatar height="44px" type="contact" imgURL={profile.profileImageURL} />
        </div>
        <div>
          <div className="search-result__contact__name">{profile.contactName}</div>
          {profile.contactUpdateDate && (
            <div className="search-result__contact__update-date">
              {Resources.LastUpdatedDate(formatDate(profile.contactUpdateDate))}
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }

  renderAccountProfilesAll() {
    const {
      searchResultValues,
      match,
      searchResultCounts,
      searchTerm,
      gettingSearchResults,
      selectedAccountingGroupType
    } = this.props;

    let searchResults;
    let searchResultCountAccounts;
    if (selectedAccountingGroupType === "vendors") {
      searchResults = searchResultValues.vendors || [];
      searchResultCountAccounts = searchResultCounts.vendors;
    } else {
      searchResults = searchResultValues.customers || [];
      searchResultCountAccounts = searchResultCounts.customers;
    }

    return (
      <React.Fragment>
        {searchResults.map((result, i) => {
          return (
            <Card
              className="search-account__item"
              clickable
              onClick={() =>
                this.props.history.push(
                  generatePath("/v2/:companyId/:perspectiveId/network/accounts/:accountId", {
                    ...match.params,
                    accountId: result.custKey || result.vendorKey
                  })
                )
              }
            >
              {this.renderAccountProfileResult(result)}
            </Card>
          );
        })}
        {searchResultCountAccounts > searchResults.length && !gettingSearchResults && (
          <button
            className="button-secondary search__see-more"
            onClick={() =>
              this.props.fetchAccountingGroupSearchResults(
                this.props.match.params.companyId,
                this.props.match.params.companyId,
                this.props.match.params.perspectiveId,
                { request: searchTerm },
                20,
                searchResults.length,
                selectedAccountingGroupType === "vendors" ? "vendors" : "customers"
              )
            }
          >
            {Resources.SeeMore}
          </button>
        )}
        {gettingSearchResults && (
          <div className="search-loader">
            <MainLoader />
          </div>
        )}
      </React.Fragment>
    );
  }

  renderContactProfilesAll() {
    const {
      searchResultValues,
      match,
      searchResultCounts,
      searchTerm,
      gettingSearchResults,
      selectedAccountingGroupType
    } = this.props;

    let searchResults;
    let searchResultCountContacts;
    if (selectedAccountingGroupType === "vendors") {
      searchResults = searchResultValues.vendorContacts || [];
      searchResultCountContacts = searchResultCounts.vendorContacts;
    } else {
      searchResults = searchResultValues.customerContacts || [];
      searchResultCountContacts = searchResultCounts.customerContacts;
    }

    return (
      <React.Fragment>
        {searchResults.map((result, i) => {
          return (
            <Card
              className="search-contact__item"
              clickable
              onClick={() =>
                this.props.history.push(
                  generatePath("/v2/:companyId/:perspectiveId/network/contacts/:contactId", {
                    ...match.params,
                    contactId: result.entityGlobalKey
                  })
                )
              }
            >
              {this.renderContactProfileResult(result)}
            </Card>
          );
        })}
        {searchResultCountContacts > searchResults.length && !gettingSearchResults && (
          <button
            className="button-secondary search__see-more"
            onClick={() =>
              this.props.fetchAccountingGroupSearchResults(
                this.props.match.params.companyId,
                this.props.match.params.companyId,
                this.props.match.params.perspectiveId,
                { request: searchTerm },
                20,
                searchResults.length,
                selectedAccountingGroupType === "vendors" ? "vendorContacts" : "customerContacts"
              )
            }
          >
            {Resources.SeeMore}
          </button>
        )}
        {gettingSearchResults && (
          <div className="search-loader">
            <MainLoader />
          </div>
        )}
      </React.Fragment>
    );
  }

  render() {
    const {
      searchResultValues,
      searchResultCounts,
      match,
      gettingSearchResults,
      selectedAccountingGroupType
    } = this.props;
    const maxPreviewElements = 10;

    let searchResultAccounts;
    let searchResultContacts;
    let searchResultCountAccounts;
    let searchResultCountContacts;

    if (selectedAccountingGroupType === "vendors") {
      searchResultContacts = searchResultValues.vendorContacts || [];
      searchResultAccounts = searchResultValues.vendors || [];
      searchResultCountAccounts = searchResultCounts.vendors;
      searchResultCountContacts = searchResultCounts.vendorContacts;
    } else {
      searchResultContacts = searchResultValues.customerContacts || [];
      searchResultAccounts = searchResultValues.customers || [];
      searchResultCountAccounts = searchResultCounts.customers;
      searchResultCountContacts = searchResultCounts.customerContacts;
    }

    return (
      <React.Fragment>
        <ViewContentHeader
          numberSearchResults={searchResultCountAccounts + searchResultCountContacts}
          numberSearchResultsContacts={searchResultCountContacts}
          numberSearchResultsAccounts={searchResultCountAccounts}
          searchResultsType={match.params.subView}
          gettingSearchResults={gettingSearchResults}
          onSearchView
        />
        {isEmpty(searchResultValues) && !gettingSearchResults && <Redirect to={"/v2/"} />}
        <div className="view-content">
          <Switch>
            <Route exact path="/v2/:companyId/:perspectiveId/search">
              {gettingSearchResults ? (
                <React.Fragment>
                  {match.params.subView !== undefined && (
                    <Redirect to={generatePath("/v2/:companyId/:perspectiveId/search", match.params)} />
                  )}
                  <MainLoader fullScreen className="mt-5" />
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {isEmpty(searchResultContacts) && !isEmpty(searchResultAccounts) && (
                    <Redirect to={generatePath("/v2/:companyId/:perspectiveId/search/accounts", match.params)} />
                  )}
                  {isEmpty(searchResultAccounts) && !isEmpty(searchResultContacts) && (
                    <Redirect to={generatePath("/v2/:companyId/:perspectiveId/search/contacts", match.params)} />
                  )}
                  {!isEmpty(searchResultAccounts) && (
                    <Card className="search-preview search-preview-account-profile">
                      <h2>{Resources.AccountProfilesNum(searchResultCountAccounts || 0)}</h2>
                      <div>
                        {(searchResultAccounts || []).map((result, i) => {
                          if (i === maxPreviewElements) {
                            return (
                              <div className="search-preview__see-all">
                                <button
                                  className="button-secondary search__see-all"
                                  onClick={() =>
                                    this.props.history.push(
                                      generatePath(match.path, { ...match.params, subView: "accounts" })
                                    )
                                  }
                                >
                                  {Resources.SeeAll}
                                </button>
                              </div>
                            );
                          }
                          if (i > maxPreviewElements) {
                            return null;
                          }
                          return (
                            <div
                              className="search-preview__item"
                              onClick={() =>
                                this.props.history.push(
                                  generatePath("/v2/:companyId/:perspectiveId/network/accounts/:accountId", {
                                    ...match.params,
                                    accountId: result.entityGlobalKey
                                  })
                                )
                              }
                            >
                              {this.renderAccountProfileResult(result)}
                            </div>
                          );
                        })}
                      </div>
                    </Card>
                  )}
                  {!isEmpty(searchResultContacts) && (
                    <Card className="search-preview search-preview-contact-profile">
                      <h2>{Resources.ContactProfilesNum(searchResultCountContacts || 0)}</h2>
                      <div>
                        {(searchResultContacts || []).map((result, i) => {
                          if (i === maxPreviewElements) {
                            return (
                              <div className="search-preview__see-all">
                                <button
                                  className="button-secondary search__see-all"
                                  onClick={() =>
                                    this.props.history.push(
                                      generatePath(match.path, { ...match.params, subView: "contacts" })
                                    )
                                  }
                                >
                                  {Resources.SeeAll}
                                </button>
                              </div>
                            );
                          }
                          if (i > maxPreviewElements) {
                            return null;
                          }
                          return (
                            <div
                              className="search-preview__item"
                              onClick={() =>
                                this.props.history.push(
                                  generatePath("/v2/:companyId/:perspectiveId/network/accounts/:contactId", {
                                    ...match.params,
                                    contactId: result.entityGlobalKey
                                  })
                                )
                              }
                            >
                              {this.renderContactProfileResult(result)}
                            </div>
                          );
                        })}
                      </div>
                    </Card>
                  )}
                </React.Fragment>
              )}
            </Route>
            <Route path="/v2/:companyId/:perspectiveId/search/accounts">{this.renderAccountProfilesAll()}</Route>
            <Route path="/v2/:companyId/:perspectiveId/search/contacts">{this.renderContactProfilesAll()}</Route>
          </Switch>
        </div>
      </React.Fragment>
    );
  }
}

const storeToProps = store => {
  return {
    searchTerm: store.search.searchTerm,
    searchResults: store.search.searchResults,
    searchResultsCount: store.search.searchResultsCount,
    gettingSearchResults: store.search.gettingSearchResults,
    searchResultValues: store.search.values,
    searchResultCounts: store.search.counts,
    selectedAccountingGroupType: store.perspectives.selectedPerspectiveType
  };
};

export default withRouter(connect(storeToProps, dispatchToProps)(SearchView));
