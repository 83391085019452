import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from "react-modal";

import { dispatchToProps } from "../store/modal-actions";

import Resources from "../lib/resources";
import { isEmpty } from "../lib/utils";

class ErrorModal extends Component {
  render() {
    const { modalStore } = this.props;
    return (
      <Modal
        isOpen={modalStore.showModal}
        onRequestClose={() => this.props.hideModal()}
        className="custom-modal"
        overlayClassName="custom-modal-overlay"
      >
        <div className="custom-modal-header">{Resources.ErrorDialogTitle}</div>
        <div className="custom-modal-divider" />
        <div className="custom-modal-content">
          <div className="mt-4">{modalStore.modalProps.errorMessage.replace("TODO:", "").trim()}</div>
          {!isEmpty(this.props.customContent) && <React.Fragment>{this.props.customContent}</React.Fragment>}
          <div className="flex-row modal-buttons mt-4">
            <button className="btn button-green" onClick={this.props.hideModal}>
              {Resources.Ok}
            </button>
          </div>
        </div>
      </Modal>
    );
  }
}

const storeToProps = store => {
  return {
    modalStore: store.modal
  };
};

export default connect(
  storeToProps,
  dispatchToProps
)(ErrorModal);
