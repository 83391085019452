import React, { Component } from "react";
import { connect } from "react-redux";
import Resources from "../../lib/resources";

import IconGlobe from "../library/icons/iconGlobe";

import { dispatchToProps as locDP } from "../../store/localization-actions";
import { isEmpty } from "../../lib/utils";

const dispatchToProps = dispatch => ({
  ...locDP(dispatch)
});

class LanguageSelector extends Component {
  render() {
    let { languages, languageId } = this.props;
    if (isEmpty(languages)) {
      return null;
    }
    return (
      <div className="dropdown language-selector">
        <button data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <IconGlobe className="language-selector-icon" height={14} />
          {Resources.Language}
        </button>
        <span className="dropdown-menu">
          {(Object.keys(languages) || []).map((key, i) => {
            let language = languages[key];
            return (
              <div
                key={language.languageId}
                className={`dropdown-item ${language.languageId === languageId ? "active" : ""}`}
                style={{ cursor: "default" }}
                onClick={() => {
                  this.props.setLanguage(language.languageId);
                }}
              >
                {language.languageDescription}
              </div>
            );
          })}
        </span>
      </div>
    );
  }
}

const storeToProps = store => ({
  languages: store.localization.resources,
  languageId: store.localization.languageId
});

export default connect(storeToProps, dispatchToProps)(LanguageSelector);
