import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment";

import { dispatchToProps as moDP } from "../../../store/modal-actions";
import { dispatchToProps as statDP } from "../../../store/statements-actions";

import { isEmpty, find, includes, formatDate } from "../../../lib/utils";
import Resources from "../../../lib/resources";
import ToggleSwitch from "../../library/toggleSwitch";

import Modal from "react-modal";
import TableData from "../../library/tableData";
import Checkbox from "../../library/checkbox";
import MainLoader from "../../mainLoader";

import IconClose from "../../library/icons/iconClose";
import IconAngleDown from "../../library/icons/iconAngleDown";
import IconAngleLeft from "../../library/icons/iconAngleLeft";
import IconStar from "../../library/icons/iconStar";
import IconEdit from "../../library/icons/iconEdit";
import IconTrash from "../../library/icons/iconTrash";
import IconPlusCircle from "../../library/icons/iconPlusCircle";

const dispatchToProps = dispatch => ({
  ...moDP(dispatch),
  ...statDP(dispatch)
});

class ManageAutoPayFlyout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      automaticPaymentEnabled: true,
      emailNotification: true,
      portalMessageNotification: true,
      view: "manageAutoPay",

      selectedPaymentMethod: null,

      showFlyout: true
    };

    this.saveAutoPaySettings = this.saveAutoPaySettings.bind(this);
    this.addNewPaymentMethod = this.addNewPaymentMethod.bind(this);
    this.hideFlyout = this.hideFlyout.bind(this);
  }

  componentDidMount() {
    const { withCompanyId, statementsStore } = this.props;

    let stateUpdate = {};

    const paymentInfo = statementsStore.paymentInfo[withCompanyId];
    const autoPayConfig = paymentInfo.automaticPaymentConfiguration || {};

    let autoPayMethod;
    autoPayMethod = find(
      paymentInfo.paymentMethods,
      pMethod => pMethod.paymentMethodId.toString() === (autoPayConfig.pmtMethodKey || "").toString()
    );
    if (isEmpty(autoPayMethod)) {
      autoPayMethod =
        find(paymentInfo.paymentMethods, pMethod => pMethod.isDefault === true) ||
        paymentInfo.paymentMethods[paymentInfo.paymentMethods.length - 1] ||
        {};
    }

    stateUpdate.selectedPaymentMethod = autoPayMethod;
    stateUpdate.automaticPaymentEnabled = autoPayConfig.automaticPaymentEnabled;
    stateUpdate.emailNotification = autoPayConfig.emailNotification;
    stateUpdate.portalMessageNotification = autoPayConfig.portalMessageNotification;

    this.setState(stateUpdate);
  }

  hideFlyout() {
    this.setState({ showFlyout: false });
  }

  saveAutoPaySettings() {
    let { companyId, withCompanyId } = this.props;

    let config = {
      automaticPaymentEnabled: this.state.automaticPaymentEnabled,
      pmtMethodKey: this.state.selectedPaymentMethod.paymentMethodId,
      emailNotification: this.state.emailNotification,
      portalMessageNotification: this.state.portalMessageNotification
    };

    this.props
      .postAutoPayConfig(companyId, withCompanyId, config)
      .then(response => {
        return this.props.fetchPaymentInfo(companyId, withCompanyId);
      })
      .then(response => {
        this.hideFlyout();
      })
      .catch(() => null);
  }

  addNewPaymentMethod() {
    const { companyId, withCompanyId } = this.props;
    this.setState({ view: "addPaymentMethod" });

    this.props
      .addOrUpdatePaymentMethod(companyId, withCompanyId, { name: "", isDefault: false })
      .then(res => {
        this.setState({ iframeUrl: res.redirectUrl });
      })
      .catch(err => null);
  }

  renderPayFrom() {
    let { withCompanyId, statementsStore } = this.props;
    const paymentInfo = statementsStore.paymentInfo[withCompanyId] || {};

    return (
      <div className="mr-4" style={{ height: "fit-content", marginBottom: "2.66rem" }}>
        <div className="portal-input-label">{Resources.PayFrom}</div>
        <div className="dropdown">
          <button className="payment-method-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            {!isEmpty(this.state.selectedPaymentMethod) ? (
              <span>
                {isEmpty(this.state.selectedPaymentMethod.name)
                  ? `${
                      this.state.selectedPaymentMethod.paymentType === "ACH"
                        ? Resources.BankAccount
                        : Resources.CreditCard
                    } ...${this.state.selectedPaymentMethod.lastFourDigits}`
                  : this.state.selectedPaymentMethod.name}
              </span>
            ) : (
              <span></span>
            )}
            <IconAngleDown height={8} />
          </button>
          <span className="dropdown-menu payment-method-dropdown-menu">
            {(paymentInfo.paymentMethods || []).map((pMethod, i) => (
              <button
                key={i}
                className="dropdown-item"
                style={{ cursor: "default" }}
                onClick={e => {
                  this.setState({ selectedPaymentMethod: pMethod });
                }}
              >
                {isEmpty(pMethod.name)
                  ? `${pMethod.paymentType === "ACH" ? Resources.BankAccount : Resources.CreditCard} ...${
                      pMethod.lastFourDigits
                    }`
                  : pMethod.name}
              </button>
            ))}
            <button
              className="dropdown-item dropdown-call-to-action"
              style={{ cursor: "default" }}
              onClick={this.addNewPaymentMethod}
            >
              {Resources.AddNewPaymentMethod}
            </button>
          </span>
        </div>
      </div>
    );
  }

  renderAddOrEditMethodContent() {
    if (isEmpty(this.state.iframeUrl)) {
      return (
        <div className="flex-center flex-align-center payment-method-iframe">
          <MainLoader fullscreen></MainLoader>
        </div>
      );
    }

    const hostname = window.location.hostname;

    return (
      <iframe
        id="newPaymentIframe"
        src={this.state.iframeUrl}
        title="New Payment method"
        className="payment-method-iframe"
        onLoad={() => {
          const iframe = document.getElementById("newPaymentIframe");
          try {
            if (includes(iframe.contentWindow.location.href, hostname)) {
              this.props.displayNotification("addingPaymentMethodNotification");
              this.setState({ view: "main", iframeUrl: null });
              this.props.fetchPaymentInfo(this.props.companyId, this.props.withCompanyId).then(res => {
                const paymentInfo = this.props.statementsStore.paymentInfo[this.props.withCompanyId].paymentMethods;
                if (paymentInfo.length > 0) {
                  this.setState({
                    selectedPaymentMethod: paymentInfo[paymentInfo.length - 1]
                  });
                }
              });
            }
          } catch {
            return;
          }
        }}
      ></iframe>
    );
  }

  renderManagePaymentMethodsContent() {
    const paymentMethods = (this.props.statementsStore.paymentInfo[this.props.withCompanyId] || {}).paymentMethods;
    const columns = [
      {
        header: Resources.PaymentMethod,
        content: row => (
          <span>
            <span className="fw-500">{`${row.paymentType === "ACH" ? Resources.BankAccount : Resources.CreditCard} ...${
              row.lastFourDigits
            }${row.name ? ` (${row.name})` : ""}`}</span>
            {moment(row.expirationDate, "MM/YY").isBefore() && (
              <span className="expired-label">{Resources.Expired.toLocaleUpperCase()}</span>
            )}
          </span>
        ),
        width: "35%"
      },
      {
        header: Resources.DateAdded,
        content: row => formatDate(new Date(), false, false),
        width: "18%"
      },
      {
        header: Resources.Type,
        content: row => (row.paymentType === "ACH" ? Resources.ACH : Resources.CreditCard),
        width: "18%"
      },
      {
        width: "30%",
        content: row => (
          <div className="flex-end flex-align-center">
            <button
              className="button-action-icon"
              onClick={() =>
                this.props
                  .addOrUpdatePaymentMethod(this.props.companyId, this.props.withCompanyId, {
                    paymentMethodId: row.paymentMethodId,
                    isDefault: true
                  })
                  .then(res => {
                    this.props.fetchPaymentInfo(this.props.companyId, this.props.withCompanyId);
                  })
                  .catch(err => {
                    console.log("Making default payment method error");
                  })
              }
            >
              <IconStar height="21" isFilled={row.isDefault} />
            </button>
            <button
              className="button-action-icon"
              onClick={() => {
                this.setState({ view: "editPaymentMethod" });
                this.props
                  .addOrUpdatePaymentMethod(this.props.companyId, this.props.withCompanyId, {
                    paymentMethodId: row.paymentMethodId
                  })
                  .then(res => {
                    this.setState({ iframeUrl: res.redirectUrl });
                  })
                  .catch(err => null);
              }}
            >
              <IconEdit height="21" />
            </button>
            <button
              className="button-action-icon"
              onClick={() =>
                this.props.deletePaymentMethod(this.props.companyId, this.props.withCompanyId, row.paymentMethodId)
              }
            >
              <IconTrash height="21" />
            </button>
          </div>
        )
      }
    ];

    const tableHeight = paymentMethods.length > 6 ? "28rem" : `${4 * paymentMethods.length + 2}rem`;
    return (
      <div className="flyout-table-container">
        <button className="button-primary mb-5" onClick={this.addNewPaymentMethod}>
          <IconPlusCircle height="20" className="button-primary-icon" />
          {Resources.AddNewPaymentMethod}
        </button>

        {!isEmpty(paymentMethods) && (
          <TableData
            noGrow
            data={paymentMethods}
            columns={columns}
            rowHeight="4rem"
            maxHeight={tableHeight}
            rowClassName="statements-view-row no-hover"
          />
        )}
        <button
          className="portal-button-green"
          style={{ marginTop: "5.3rem" }}
          onClick={() => this.setState({ view: "main" })}
        >
          {Resources.Done}
        </button>
      </div>
    );
  }

  render() {
    let header;
    let content;
    const goBackButton = (
      <span
        style={{ display: "inline-flex", marginRight: "2.15rem" }}
        onClick={() => this.setState({ view: "manageAutoPay" })}
      >
        <IconAngleLeft />
      </span>
    );

    switch (this.state.view) {
      case "managePaymentMethods":
        header = (
          <div className="d-flex align-items-center">
            {goBackButton}
            {Resources.ManagePaymentMethods}
          </div>
        );
        content = this.renderManagePaymentMethodsContent();
        break;
      case "addPaymentMethod":
        header = (
          <div className="d-flex align-items-center">
            {goBackButton}
            {Resources.AddNewPaymentMethod}
          </div>
        );
        content = this.renderAddOrEditMethodContent();
        break;
      case "editPaymentMethod":
        header = (
          <div className="d-flex align-items-center">
            {goBackButton}
            {Resources.EditPaymentMethod}
          </div>
        );
        content = this.renderAddOrEditMethodContent();
        break;
      case "manageAutoPay":
      default:
        header = <div>{Resources.ManageAutomaticPayments}</div>;
        if (this.props.statementsStore.postingAutoPayConfig || this.props.statementsStore.isFetchingPaymentInfo) {
          content = <MainLoader fullScreen />;
        } else {
          content = (
            <React.Fragment>
              <div style={{ marginBottom: "2.66rem" }}>
                <h2>{Resources.AutomaticPayments}</h2>
                <ToggleSwitch
                  checked={this.state.automaticPaymentEnabled}
                  onChange={() => this.setState({ automaticPaymentEnabled: !this.state.automaticPaymentEnabled })}
                />
              </div>
              {this.renderPayFrom()}
              <h2 style={{ marginBottom: "0.66rem" }}>{Resources.WhenPaymentIsMadeHowWouldYouLikeToBeNotified}</h2>
              <Checkbox
                checked={this.state.portalMessageNotification}
                label={Resources.PortalMessage}
                onChange={() => this.setState({ portalMessageNotification: !this.state.portalMessageNotification })}
                style={{ marginBottom: "0.66rem" }}
              />
              <br />
              <Checkbox
                checked={this.state.emailNotification}
                label={Resources.EmailDefaultAddressOnFile}
                onChange={() => this.setState({ emailNotification: !this.state.emailNotification })}
                style={{ marginBottom: "2.66rem" }}
              />
              <p style={{ marginBottom: "5.33rem" }}>
                {Resources.WeWillPayAllOpenInvoicesOnTheirGivenDueDateUsingThePaymentMethodSelectedAbove}
              </p>
              <button className="portal-button-green" onClick={this.saveAutoPaySettings}>
                {Resources.Save}
              </button>
            </React.Fragment>
          );
        }

        break;
    }

    return (
      <Modal
        isOpen={this.state.showFlyout}
        onRequestClose={this.hideFlyout}
        onAfterClose={this.props.hideFlyout}
        contentLabel="Manage automatic payments"
        className="flyout manage-auto-pay-flyout"
        overlayClassName="flyout-overlay"
        closeTimeoutMS={400}
      >
        <div className="flyout-heading">
          {header}
          <div onClick={this.hideFlyout} className="flyout-heading-close">
            <IconClose />
          </div>
        </div>
        <div className="flyout-content" style={{ marginTop: "1.66rem" }}>
          {content}
        </div>
      </Modal>
    );
  }
}

const storeToProps = store => {
  return {
    modalStore: store.modal,
    statementsStore: store.statements
  };
};

export default withRouter(connect(storeToProps, dispatchToProps)(ManageAutoPayFlyout));
