import ReactQuill, { Quill } from "react-quill";

const Inline = Quill.import("blots/inline");

class CustomColor extends Inline {
  constructor(domNode, value) {
    super(domNode, value);

    // Map <font> properties
    domNode.style.color = domNode.color;

    const span = this.replaceWith(new Inline(Inline.create()));

    span.children.forEach(child => {
      if (child.attributes) child.attributes.copy(span);
      if (child.unwrap) child.unwrap();
    });

    this.remove();

    return span;
  }
}

// Prepend links with https protocol

const Link = Quill.import("formats/link");
const builtInFunc = Link.sanitize;

Link.sanitize = function customSanitizeLinkInput(linkValueInput) {
  let val = linkValueInput;

  // do nothing, since this implies user's already using a custom protocol
  if (/^\w+:/.test(val));
  else if (!/^https?:/.test(val)) val = "https:" + val;

  return builtInFunc.call(this, val); // retain the built-in logic
};

CustomColor.blotName = "customColor";
CustomColor.tagName = "FONT";

Quill.register(CustomColor, true);

export default ReactQuill;
