import React, { Component } from "react";
import { connect } from "react-redux";
import { isEmpty } from "../lib/utils";

import { dispatchToProps as moDP } from "../store/modal-actions";
import { dispatchToProps as comDP } from "../store/company-actions";
import { dispatchToProps as conDP } from "../store/conversations-actions";
import { dispatchToProps as genDP } from "../store/general-actions";
import { dispatchToProps as congDP } from "../store/contextGroups-actions";

import TextInput from "./library/textInput";
import Resources from "../lib/resources";
import Modal from "react-modal";

const dispatchToProps = dispatch => ({
  ...moDP(dispatch),
  ...comDP(dispatch),
  ...genDP(dispatch),
  ...conDP(dispatch),
  ...congDP(dispatch)
});

class ModalCompanyRename extends Component {
  constructor(props) {
    super(props);
    this.state = { companyName: "" };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.submit = this.submit.bind(this);
  }

  handleInputChange(e, key) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  submit() {
    this.props.postContextGroup(this.props.modal.modalProps.contextGroupId, this.state.companyName).then(() => {
      this.props.showToast({
        timeout: 8000,
        text: Resources.ChannelNameHasBeenChanged(this.props.modal.modalProps.companyName, this.state.companyName),
        type: "green",
        linkHidden: true
      });
      this.props.hideModal();
      this.props.refreshContextGroups(
        this.props.modal.modalProps.currentCompanyId,
        this.props.modal.modalProps.perspective
      );
    });
  }

  render() {
    return (
      <Modal
        isOpen={this.props.modal.showModal}
        onRequestClose={() => this.props.hideModal()}
        contentLabel="Example Modal"
        className="custom-modal-wide"
        overlayClassName="custom-modal-overlay"
      >
        {/* <div className="custom-modal-overlay"></div>
          <div className="custom-modal"> */}
        <div className="custom-modal-header">{Resources.ChangeDisplayNameForChannel}</div>
        <div className="custom-modal-divider" />
        <div className="custom-modal-content">
          {!isEmpty(this.props.modal.modalProps) && (
            <div className="action-modal-company">{this.props.modal.modalProps.companyName}</div>
          )}
          <TextInput
            width="80%"
            textValue={this.state.companyName}
            label={Resources.EnterNewName}
            id="companyName"
            inputOnChange={e => {
              this.setState({ companyName: e.target.value });
            }}
          />
          <div className="flex-row modal-buttons">
            <button className="button-cancel float-left mr-1" onClick={this.props.hideModal}>
              {Resources.Cancel}
            </button>
            <button className="btn button-green" onClick={this.submit}>
              {Resources.Save}
            </button>
          </div>
        </div>
        {/* </div> */}
      </Modal>
    );
  }
}

const storeToProps = store => {
  return {
    modal: store.modal,
    postedCompanyInfo: store.companies.postedCompanyInfo
  };
};

export default connect(
  storeToProps,
  dispatchToProps
)(ModalCompanyRename);
