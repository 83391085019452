import React, { Component } from "react";

class IconCircle extends Component {
  render() {
    let { className, height } = this.props;
    return (
      <svg height={height} width={height} className={`icon ${className ? className : ""}`}>
        <circle
          cx={height / 2}
          cy={height / 2}
          r={height / 2}
          stroke="currentColor"
          stroke-width="0"
          fill="currentColor"
        />
      </svg>
    );
  }
}

IconCircle.defaultProps = {
  height: 24
};

export default IconCircle;
