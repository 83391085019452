import React, { Component } from "react";

import Resources from "../../../lib/resources";
import Icons from "../../../lib/icons";
import { getPartnerType } from "../../../lib/perspectives";

import { isEmpty, find } from "../../../lib/utils";
import Attachment from "../../attachment";

export default class ForwardThreadItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewFull: false
    };
  }

  render() {
    const {
      posterName,
      attachments,
      isFirstEntry,
      assignedTo,
      statusName,
      dateText,
      perspective,
      perspectiveName,
      from,
      to,
      viewOriginal,
      entry
    } = this.props;

    const relatedConversationId = find(
      entry.conversationIds,
      relatedConversationId => relatedConversationId !== this.props.conversationId
    );

    return this.state.viewFull ? (
      <div className="conversation-thread-item forward-item">
        <div>
          <div>
            <div>
              <b
                dangerouslySetInnerHTML={{
                  __html: Resources.CopyForwardedBy(posterName)
                }}
              />
              {viewOriginal || null}

              <span className="float-right" style={{ textAlign: "right" }}>
                <div className="thread-item-age">
                  {dateText} {Resources.Ago.toLowerCase()}
                </div>
                {isFirstEntry && <div>{`${assignedTo}, ${statusName}`}</div>}
              </span>
            </div>
            <div>{from}</div>
            <div>{to}</div>
            <br />
            <div
              className="forward-color clickable"
              onClick={() => {
                this.props.history.push(
                  this.props.location.pathname.replace(this.props.conversationId, relatedConversationId)
                );
              }}
            >
              {Resources.ViewRelatedConversation}
            </div>
            <div />
            <div className="text-muted">
              <br />
              <span className={`mr-2 ${Icons.visibility}`} />
              {Resources.ForwardingVisibilityMessage(getPartnerType(perspectiveName || perspective).toLowerCase())}
            </div>
          </div>
          {isEmpty(attachments) === false && (
            <div>
              <br />
              {attachments.map(att => {
                return (
                  <Attachment
                    attachment={att}
                    key={att.attachmentId}
                    showIcon={true}
                    name={att.fileName}
                    size={att.sizeBytes}
                  />
                );
              })}
            </div>
          )}
        </div>
      </div>
    ) : null;
  }
}
