import React, { Component } from "react";
import Icons from "../lib/icons";

class SearchInput extends Component {
  render() {
    const { placeholder, handleChange, onKeyPress, onClick, className } = this.props;

    return (
      <div className={`search ${className}`}>
        <input
          placeholder={placeholder}
          onChange={e => {
            handleChange(e);
          }}
          onKeyPress={onKeyPress}
        />
        <span onClick={onClick} className={Icons.search} />
      </div>
    );
  }
}

export default SearchInput;
