import React, { Component } from "react";

class IconUser extends Component {
  render() {
    let { className, height } = this.props;

    return (
      <svg viewBox="0 0 21 23" height={height} className={`icon ${className ? className : ""}`}>
        <g fill="none" fillRule="evenodd">
          <g
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            transform="translate(1 1)"
          >
            <path d="M19 21v-2.3333333C19 16.0893378 16.8733526 14 14.25 14h-9.5C2.12664744 14 0 16.0893378 0 18.6666667V21" />
            <circle cx="9.5" cy="4.5" r="4.5" />
          </g>
        </g>
      </svg>
    );
  }
}

IconUser.defaultProps = {
  height: 23
};

export default IconUser;
