import React, { Component } from "react";
import { connect } from "react-redux";
import { dispatchToProps as aaDP } from "../store/accounts-actions";
import { dispatchToProps as maDP } from "../store/manage-actions";
import { dispatchToProps as persDP } from "../store/perspectives-actions";
import { dispatchToProps as ledgDP } from "../store/ledger-actions";
import { dispatchToProps as conDP } from "../store/conversations-actions";

import { isEmpty } from "../lib/utils";
import Resources from "../lib/resources";

import MainContentHeader from "./main_content_header/mainContentHeader";
import PerspectiveTabs from "./perspectiveTabs";
import TextInput from "./library/textInput";
import MainLoader from "./mainLoader";
import RadioInput from "./library/radioInput";
import TextAreaInput from "./library/textAreaInput";
import CRUDList from "./library/crudList";
import Card from "./library/card";

const dispatchToProps = dispatch => ({
  ...aaDP(dispatch),
  ...maDP(dispatch),
  ...persDP(dispatch),
  ...ledgDP(dispatch),
  ...conDP(dispatch)
});

class ManageWorkflows extends Component {
  defaultState = {
    perspective: null,
    creatingWorkflow: false,
    editingWorkflow: false,
    name: "",
    description: "",
    resourceId: "",
    resourceName: "",
    ledgerHash: ""
  };

  constructor(props) {
    super(props);

    this.state = { ...this.defaultState };

    this.submit = this.submit.bind(this);
    this.delete = this.delete.bind(this);
    this.edit = this.edit.bind(this);
  }

  componentDidMount() {
    const companyId = this.props.match.params.companyId;
    this.props.fetchCompanyWorkflows(companyId);
  }

  componentDidUpdate(prevProps, prevState) {
    const companyId = this.props.match.params.companyId;
    const prevCompanyId = prevProps.match.params.companyId;

    if (isEmpty(this.state.perspective)) {
      const defaultPerspective =
        this.props.getCompanyPerspectiveById(this.props.userDefaultPerspective, companyId) ||
        (this.props.getCompanyPerspectives(companyId) || [])[0];
      if (!isEmpty(defaultPerspective)) {
        this.setState({ perspective: defaultPerspective });
      }
    }

    if (isEmpty(this.state.resourceId)) {
      let reminder = this.props.getResource("resourceName", "Reminder");
      if (!isEmpty(reminder)) {
        this.setState({ resourceId: reminder.resourceId, resourceName: reminder.resourceName });
      }
    }

    if (companyId !== prevCompanyId) {
      this.props.fetchCompanyWorkflows(companyId);
    }

    if (this.state.perspective !== prevState.perspective) {
      this.setState({ ...this.defaultState, perspective: this.state.perspective });
    }
  }

  submit() {
    const companyId = this.props.match.params.companyId;
    const perspectiveId = this.state.perspective.perspectiveId;

    if (this.state.creatingWorkflow) {
      this.props
        .putCompanyWorkflow(
          companyId,
          perspectiveId,
          this.state.name,
          this.state.description,
          this.state.resourceId,
          this.state.resourceName
        )
        .then(() => {
          this.setState({ ...this.defaultState, perspective: this.state.perspective });
          this.props.fetchCompanyWorkflows(companyId);
        });
    } else {
      let newJson = {
        name: this.state.name,
        description: this.state.description,
        resourceId: this.state.resourceId,
        resourceName: this.state.resourceName
      };
      this.props.updateCompanyWorkflow(companyId, this.state.ledgerHash, newJson).then(() => {
        this.setState({ ...this.defaultState, perspective: this.state.perspective });
        this.props.fetchCompanyWorkflows(companyId);
      });
    }
  }

  delete(ledgerHash) {
    const companyId = this.props.match.params.companyId;

    this.props.deleteCompanyWorkflow(companyId, ledgerHash).then(() => {
      this.props.fetchCompanyWorkflows(companyId);
    });
  }

  edit(workflow) {
    this.setState({
      editingWorkflow: true,
      name: workflow.asJson.name,
      description: workflow.asJson.description,
      resourceId: workflow.asJson.resourceId,
      ledgerHash: workflow.ledgerHash
    });
  }

  workflowColumns = [
    {
      header: Resources.Name.toLocaleUpperCase(),
      content: row => {
        return <div>{row.asJson.name}</div>;
      },
      className: "highlight",
      width: "20%"
    },
    {
      header: Resources.Type.toLocaleUpperCase(),
      content: row => {
        return <div>{row.asJson.resourceName}</div>;
      },
      width: "15%"
    },
    {
      header: Resources.Description.toLocaleUpperCase(),
      content: row => {
        return <div>{row.asJson.description}</div>;
      },
      width: "45%"
    },
    {
      header: Resources.Actions.toLocaleUpperCase(),
      type: "actions",
      actions: [
        { type: "update", onClick: row => this.edit(row) },
        { type: "delete", onClick: row => this.delete(row.ledgerHash) }
      ]
    }
  ];

  workflows = () => {
    let reminder = this.props.getResource("resourceName", "Reminder");
    return [
      { label: Resources.Reminder, value: reminder.resourceId, displayName: Resources.Reminder, checked: true }
      // { label: Resources.Approval, disabled: true },
      // { label: Resources.CustomWorkflow, disabled: true }
    ];
  };

  render() {
    const { perspective, creatingWorkflow, editingWorkflow } = this.state;
    if (isEmpty(perspective)) {
      return <MainLoader fullScreen={true} />;
    }

    const perspectiveId = perspective.perspectiveId;

    let pageContent = null;
    if (creatingWorkflow || editingWorkflow) {
      pageContent = (
        <div className="w-500">
          <h4 className="workflow-form-header">
            {editingWorkflow ? Resources.EditingWorkflow : Resources.NewWorkflow}
          </h4>
          <TextInput
            textValue={this.state.name}
            label={Resources.Name}
            inputOnChange={e => this.setState({ name: e.target.value })}
            placeholder={Resources.Name}
          />
          <TextAreaInput
            textValue={this.state.description}
            label={Resources.Description}
            inputOnChange={e => this.setState({ description: e.target.value })}
            placeholder={Resources.Description}
          />
          <h4 className="workflow-form-type">{Resources.WorkflowType}</h4>
          <RadioInput
            disabled={editingWorkflow}
            inputs={this.workflows()}
            radioName="testing"
            value={this.state.resourceId}
            onChange={item => {
              this.setState({ resourceId: item.value, resourceName: item.displayName });
            }}
          />

          {this.props.puttingWorkflow ? (
            <span className="spinner" />
          ) : (
            <div className="workflow-new-bottom-buttons">
              <button
                onClick={() =>
                  this.setState({ editingWorkflow: false, creatingWorkflow: false, name: "", description: "" })
                }
                className="button-cancel mr-4"
              >
                {Resources.Cancel}
              </button>
              <button onClick={this.submit} className="button-green">
                {Resources.Save}
              </button>
            </div>
          )}
        </div>
      );
    } else {
      pageContent = (
        <Card maxWidth={"80em"} className="card-crud-list">
          <CRUDList
            columns={this.workflowColumns}
            data={this.props.workflows[perspectiveId] || []}
            title={Resources.WorkflowList}
            description={Resources.WorkflowListDescription}
            onCreate={() => this.setState({ creatingWorkflow: true })}
            createText={Resources.CreateWorkflow}
            loading={this.props.gettingWorkflows}
          />
        </Card>
      );
    }
    return (
      <React.Fragment>
        <MainContentHeader
          hideSearch={true}
          title={Resources.Workflows}
          customContent={
            <PerspectiveTabs
              handleSelect={perspective => this.setState({ perspective })}
              selectedPerspective={perspective}
            />
          }
          // hideSearch={true}
        />
        <div className="manage-content">{pageContent}</div>
      </React.Fragment>
    );
  }
}

const storeToProps = store => {
  return {
    workflows: store.manage.workflows,
    gettingWorkflows: store.manage.gettingWorkflows,
    puttingWorkflow: store.manage.puttingWorkflow,
    userDefaultPerspective: store.user.userConfig.defaultPerspective
  };
};

export default connect(storeToProps, dispatchToProps)(ManageWorkflows);
