import React, { Component } from "react";
import LockstepLogo from "../../library/lockstepLogo";

class LockstepHeader extends Component {
  render() {
    return (
      <div className="profile-update-view-header">
        <div>
          <LockstepLogo className="profile-update-header-logo" height={60} />
        </div>
      </div>
    );
  }
}

export default LockstepHeader;
