import React, { Component } from "react";
import { connect } from "react-redux";
import { isEmpty, find, isGuid } from "../../lib/utils";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";

import ViewController from "./viewController";

import MainLoader from "../mainLoader";
import LockstepLogo from "../library/lockstepLogo";
import ViewSelector from "./viewSelector";
import PerspectiveSelector from "./perspectiveSelector";
import ProfileUpdateController from "./profileUpdateController";
import ProfileShareView from "./views/profileShareView";

import { dispatchToProps as genDP } from "../../store/general-actions";
import { dispatchToProps as aaDP } from "../../store/accounts-actions";
import { dispatchToProps as paDP } from "../../store/perspectives-actions";
import { dispatchToProps as cgDP } from "../../store/contextGroups-actions";
import { dispatchToProps as convDP } from "../../store/conversations-actions";
import { dispatchToProps as saDP } from "../../store/statements-actions";
import { dispatchToProps as caDP } from "../../store/company-actions";
import { dispatchToProps as uaDP } from "../../store/user-actions";

const dispatchToProps = dispatch => {
  return {
    ...genDP(dispatch),
    ...aaDP(dispatch),
    ...saDP(dispatch),
    ...paDP(dispatch),
    ...cgDP(dispatch),
    ...caDP(dispatch),
    ...uaDP(dispatch),
    ...convDP(dispatch)
  };
};

class Lockstep extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    this.tryUpdate();
  }

  componentDidUpdate() {
    this.tryUpdate();
  }

  componentWillUnmount() {
    window.Intercom("boot");
  }

  tryUpdate() {
    let { contextGroupsStore, perspectivesStore, accountsStore, match } = this.props;

    if (this.props.APILive === false || match.params.companyId === "profileupdate") {
      return null;
    }
    const subscription = this.props.getSubscription();
    if (subscription === null) {
      return;
    }

    const companyId = this.props.getDefaultCompany().companyId;

    if ((isEmpty(contextGroupsStore.companyId) || isEmpty(accountsStore.selectedCompanyId)) && !isEmpty(companyId)) {
      this.props.fetchCompanyInfo(companyId);
      this.props.selectCompany(companyId);
    }

    const perspectiveId = match.params.perspectiveId;
    if (isEmpty(perspectivesStore.selectedPerspectiveId)) {
      const companyPerspectives = this.props.getCompanyPerspectives(companyId);
      if (companyPerspectives === null) {
        return;
      }

      if (!isEmpty(perspectiveId) && isGuid(perspectiveId)) {
        this.props.setSelectedPerspectiveId(perspectiveId);
      } else {
        if (match.params.companyId !== "profileupdate") {
          let defaultPerspective =
            find(
              companyPerspectives,
              cPerspective => cPerspective.parentPerspectiveId === "1" // 1 is assosciated with vendors
            ) || {};
          if (isEmpty(defaultPerspective)) {
            defaultPerspective = companyPerspectives[0];
          }

          this.props.setSelectedPerspectiveId(
            defaultPerspective.accountingGroupKey || defaultPerspective.perspectiveId
          );
        }
      }
    }
  }

  render() {
    const {
      perspectivesStore,
      accountsStore,
      match,
      match: { params }
    } = this.props;

    const companyId = this.props.getDefaultCompany().companyId;
    const perspectiveId = perspectivesStore.selectedPerspectiveId;

    const subscription = this.props.getSubscription();
    // Ensure customer portal users do not accidentally get into Lockstep
    if (!isEmpty(subscription) && (subscription.subscriptionTypeName || "").toLowerCase() === "customer portal") {
      this.props.history.push("/");
    }

    // Only render app when all information has been fetched to display data
    if (
      match.params.companyId !== "profileupdate" &&
      (!accountsStore.hasFetchedAccounts ||
        isEmpty(subscription) ||
        isEmpty(perspectivesStore.companyPerspectives) ||
        isEmpty(perspectivesStore.selectedPerspectiveId))
    ) {
      return <MainLoader fullScreen={true} />;
    }

    const guidRegex = "([0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})";

    return (
      <Switch>
        {
          // TODO: Fix this redirect, should not need to redirect to correct company
        }
        {/* {!isEmpty(companyId) && params.companyId !== companyId && <Redirect to={`/v2/${companyId}`} />} */}
        <Route path={`/v2/:companyId${guidRegex}/:perspectiveId/:view/:subView?`}>
          <div className="lockstep">
            <div className="lockstep-side-bar">
              <div className="lockstep-side-bar-logo">
                <LockstepLogo height={40} inverse></LockstepLogo>
              </div>
              <div className="lockstep-side-bar-nav">
                <PerspectiveSelector></PerspectiveSelector>
                <ViewSelector {...this.props}></ViewSelector>
              </div>
            </div>
            <Switch>
              <Route path="/v2/:companyId/:perspectiveId/:view">
                <ViewController />
              </Route>
            </Switch>
          </div>
        </Route>
        <Route path="/v2/profile/:type/:mode/:accessId">
          <ProfileShareView />
        </Route>
        <Route path="/v2/profileupdate">
          <ProfileUpdateController />
        </Route>
        <Redirect exact from="/v2/:companyId/:perspectiveId" to={`/v2/${companyId}/${perspectiveId}/home`} />
        {!isEmpty(companyId) && isEmpty(params.companyId) && <Redirect exact from="/v2" to={`/v2/${companyId}`} />}
        {!isEmpty(perspectiveId) && <Redirect exact from="/v2/:companyId" to={`/v2/${companyId}/${perspectiveId}`} />}
      </Switch>
    );
  }
}

const storeToProps = store => {
  return {
    accountsStore: store.accounts,
    userStore: store.user,
    conversationsStore: store.conversations,
    perspectivesStore: store.perspectives,
    statementsStore: store.statements,
    contextGroupsStore: store.contextGroups
  };
};

export default withRouter(connect(storeToProps, dispatchToProps)(Lockstep));
