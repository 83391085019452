import data from "../lib/data";
import Resources from "../lib/resources";
import { handlePromiseError } from "./error-actions";
import { actions as GeneralActions } from "./general-actions";
import { getConfig, isEmpty } from "../lib/utils";

export const actions = {
  ...GeneralActions,
  ...{
    GETTING_COMPANY_INFO: "GETTING_COMPANY_INFO",
    GOT_COMPANY_INFO: "GOT_COMPANY_INFO",
    GET_COMPANY_INFO_FAILED: "GET_COMPANY_INFO_FAILED",

    GETTING_COMPANY: "GETTING_COMPANY",
    GOT_COMPANY: "GET_COMPANY",
    GET_COMPANY_FAILED: "GET_COMPANY_FAILED",

    POSTING_COMPANY_INFO: "POSTING_COMPANY_INFO",
    POSTED_COMPANY_INFO: "POSTED_COMPANY_INFO",
    POST_COMPANY_INFO_FAILED: "POST_COMPANY_INFO_FAILED",

    GETTING_COMPANY_INDENTIFIERS: "GETTING_COMPANY_IDENTIFIERS",
    GOT_COMPANY_INDENTIFIERS: "GOT_COMPANY_IDENTIFIERS",
    GET_COMPANY_INDENTIFIERS_FAILED: "GET_COMPANY_IDENTIFIERS_FAILED",

    DELETING_COMPANY_INDENTIFIER: "DELETING_COMPANY_INDENTIFIER",
    DELETED_COMPANY_INDENTIFIER: "DELETED_COMPANY_INDENTIFIER",
    DELETE_COMPANY_INDENTIFIER_FAILED: "DELETE_COMPANY_INDENTIFIER_FAILED",

    PUTTING_COMPANY_INDENTIFIER: "PUTTING_COMPANY_INDENTIFIER",
    PUT_COMPANY_INDENTIFIER: "PUT_COMPANY_INDENTIFIER",
    PUT_COMPANY_INDENTIFIER_FAILED: "PUT_COMPANY_INDENTIFIER_FAILED",

    POSTING_COMPANY_REMAP: "POSTING_COMPANY_REMAP",
    POSTED_COMPANY_REMAP: "POSTED_COMPANY_REMAP",
    POST_COMPANY_REMAP_FAILED: "POST_COMPANY_REMAP_FAILED",

    POSTING_COMPANY_SPAM: "POSTING_COMPANY_SPAM",
    POSTED_COMPANY_SPAM: "POSTED_COMPANY_SPAM",
    POST_COMPANY_SPAM_FAILED: "POST_COMPANY_SPAM_FAILED",

    POSTING_ADDRESS_SPAM: "POSTING_ADDRESS_SPAM",
    POSTED_ADDRESS_SPAM: "POSTED_ADDRESS_SPAM",
    POST_ADDRESS_SPAM_FAILED: "POST_ADDRESS_SPAM_FAILED",

    FETCHING_SPAM_ADDRESS_LIST: "FETCH_SPAM_ADDRESS_LIST",
    FETCHED_SPAM_ADDRESS_LIST: "FETCHED_SPAM_ADDRESS_LIST",
    FETCH_SPAM_ADDRESS_FAILED: "FETCH_SPAM_ADDRESS_FAILED",

    DELETING_ADDRESS_FROM_SPAM_LIST: "DELETING_ADDRESS_FROM_SPAM_LIST",
    DELETED_ADDRESS_FROM_SPAM_LIST: "DELETED_ADDRESS_FROM_SPAM_LIST",
    DELETE_ADDRESS_FROM_SPAM_LIST_FAILED: "DELETE_ADDRESS_FROM_SPAM_LIST_FAILED"
  }
};

let GET_COMPANY_INFO_PROMISE = {};
const fetchCompanyInfo = companyId => (dispatch, getState) => {
  let state = getState().companies;
  if (isEmpty(companyId)) {
    return null;
  }
  if (state.gettingCompanyInfo[companyId]) return GET_COMPANY_INFO_PROMISE[companyId];
  dispatch({ type: actions.GETTING_COMPANY_INFO, companyId: companyId });
  GET_COMPANY_INFO_PROMISE[companyId] = Promise.all([
    data.get(`v1/api/account/company/${companyId}/info`),
    data.get(`v1/api/connector/email/integrations/${companyId}`)
  ])
    .then(arr => {
      delete GET_COMPANY_INFO_PROMISE[companyId];
      dispatch({
        type: actions.GOT_COMPANY_INFO,
        companyId: companyId,
        companyInfo: arr[0].data,
        connectorEmails: arr[1].data
      });
    })
    .catch(response => {
      dispatch({ type: actions.GET_COMPANY_INFO_FAILED, companyId: companyId });
      handlePromiseError(response, Resources.CompanyInfoFetchFailure, "company info");
    });
  return GET_COMPANY_INFO_PROMISE[companyId];
};

const getCompanyInfo = companyId => (dispatch, getState) => {
  let companies = getState().companies;
  if (
    companies.fetchedCompanyInfo[companyId] !== true &&
    companies.gettingCompanyInfo[companyId] !== true &&
    companies.fetchingCompanyInfoFailed !== true
  ) {
    dispatch(fetchCompanyInfo(companyId));
    return {};
  }
  let companyInfo = { ...(companies.companyInfo[companyId] || {}) };
  companyInfo.connectorEmail = {};
  companyInfo.connectorEmail.synthetic = {};
  companyInfo.connectorEmail.remote = {};
  (companyInfo.connectorEmails || []).forEach(e => {
    let synthetic = `${e.localPart}@${getConfig().emailDomain}`;
    companyInfo.connectorEmail.synthetic[e.perspectiveId] = synthetic;
    companyInfo.connectorEmail.remote[e.perspectiveId] = e.companyForwardingAddress || "";
  });

  return companyInfo;
};

const isCompanyUnowned = companyId => (dispatch, getState) => {
  let companies = getState().companies;

  if (companies.gettingCompany[companyId] === true) {
    return false;
  }
  if (companies.fetchedCompany[companyId] === true) {
    return true;
  }
  if (companies.failedCompany[companyId] === true) {
    return false;
  }
  dispatch({ type: actions.GETTING_COMPANY, companyId });
  data
    .get(`v1/api/account/company/${companyId}`)
    .then(e => {
      dispatch({ type: actions.GOT_COMPANY, companyId });
    })
    .catch(rejection => {
      dispatch({ type: actions.GET_COMPANY_FAILED, companyId });
    });
  return false;
};

const postCompanyInfo = (companyInfo, companyId) => (dispatch, getState) => {
  let companyInfoPayload = {
    "Contact Numbers": {
      "AP Phone": companyInfo.apPhone,
      "AR Phone": companyInfo.arPhone
    },
    Address: {
      Line1: companyInfo.address.Line1,
      // Line2: companyInfo.line2,
      // Line3: companyInfo.line3,
      Locality: companyInfo.address.Locality,
      Region: companyInfo.address.Region,
      Country: companyInfo.address.Country,
      PostalCode: companyInfo.address.PostalCode
    },
    URL: companyInfo.url,
    TIN: companyInfo.TIN,
    "W9 File": companyInfo.w9File,
    "W8 BEN File": companyInfo.w8BenFile,
    apEmail: companyInfo.apEmail,
    arEmail: companyInfo.arEmail
  };

  dispatch({ type: actions.POSTING_COMPANY_INFO, companyId });
  data
    .post(`v1/api/account/company/${companyId}`, {
      companyName: companyInfo.companyName
    })
    .then(e => {
      if (!isEmpty(companyInfoPayload)) {
        data
          .post(`v1/api/account/company/${companyId}/info`, companyInfoPayload)
          .then(e => {
            dispatch({ type: actions.POSTED_COMPANY_INFO, companyId });
            dispatch(fetchCompanyInfo(companyId));
          })
          .catch(rejection => {
            dispatch(fetchCompanyInfo(companyId));
            handlePromiseError(
              rejection,
              "TODO: Updating the company info has failed but the name has been changed. Make sure the company emails are filled in.",
              "company info"
            );
            dispatch({ type: actions.POST_COMPANY_INFO_FAILED, companyId });
          });
      }
    })
    .catch(rejection => {
      handlePromiseError(
        rejection,
        "TODO: Updating the company info has failed. Make sure the company name and emails are filled in.",
        "company info"
      );
      dispatch({ type: actions.POST_COMPANY_INFO_FAILED, companyId });
    });
};

const postCompanyName = (companyName, companyId) => (dispatch, getState) => {
  dispatch({ type: actions.POSTING_COMPANY_INFO, companyId });
  return data
    .post(`v1/api/account/company/${companyId}`, { companyName: companyName })
    .then(e => {
      dispatch({ type: actions.POSTED_COMPANY_INFO, companyId });
      dispatch(fetchCompanyInfo(companyId));
    })
    .catch(rejection => {
      handlePromiseError(
        rejection,
        "TODO: Updating the company info has failed. Make sure the company name and emails are filled in.",
        "company name"
      );
      dispatch({ type: actions.POST_COMPANY_INFO_FAILED, companyId });
    });
};

const getCompanyIdentifiers = companyId => (dispatch, getState) => {
  let companies = getState().companies;

  if (
    (companies.gettingCompanyIdentifiers[companyId] && isEmpty(companies.companyIdentifiers[companyId])) ||
    isEmpty(companyId)
  ) {
    return null;
  }
  if (companies.fetchedCompanyIdentifiers[companyId] === true || companies.gettingCompanyIdentifiers[companyId]) {
    return companies.companyIdentifiers[companyId];
  }
  if (companies.failedCompanyIdentifiers[companyId] === true) {
    return null;
  }
  dispatch(fetchCompanyIdentifiers(companyId));
};

const fetchCompanyIdentifiers = companyId => (dispatch, getState) => {
  if (isEmpty(companyId)) {
    return null;
  }
  dispatch({ type: actions.GETTING_COMPANY_INDENTIFIERS, companyId });
  return data
    .get(`v1/api/account/company/${companyId}/identifiers`)
    .then(e => {
      dispatch({
        type: actions.GOT_COMPANY_INDENTIFIERS,
        companyId,
        companyIdentifiers: e.data
      });
      return true;
    })
    .catch(error => {
      dispatch({ type: actions.GET_COMPANY_INDENTIFIERS_FAILED, companyId });
      handlePromiseError(error, "TODO: Getting company identifiers failed.", "company identifiers");
      throw error;
    });
};

const deleteCompanyIdentifier = (emailConnectorId, connectorCompanyPartyIdentifierId) => (dispatch, getState) => {
  dispatch({ type: actions.DELETING_COMPANY_INDENTIFIER, connectorCompanyPartyIdentifierId });
  return data
    .delete(`v1/api/connector/${emailConnectorId}/identifier/${connectorCompanyPartyIdentifierId}`)
    .then(e => {
      dispatch({ type: actions.DELETED_COMPANY_INDENTIFIER, connectorCompanyPartyIdentifierId });
      return true;
    })
    .catch(error => {
      handlePromiseError(error, "TODO: Deleting company identifier has failed.", "company identifier");
      dispatch({ type: actions.DELETE_COMPANY_INDENTIFIER_FAILED, connectorCompanyPartyIdentifierId });
      throw error;
    });
};

const putCompanyIdentifier = (emailConnectorId, sourceCompanyId, identifiedCompanyId, identifier) => (
  dispatch,
  getState
) => {
  if (isEmpty(sourceCompanyId) || isEmpty(emailConnectorId) || isEmpty(identifiedCompanyId) || isEmpty(identifier)) {
    return null;
  }
  dispatch({ type: actions.PUTTING_COMPANY_INDENTIFIER });
  return data
    .put(`v1/api/connector/${emailConnectorId}/identifier`, { sourceCompanyId, identifiedCompanyId, identifier })
    .then(e => {
      dispatch({ type: actions.PUT_COMPANY_INDENTIFIER });
      return true;
    })
    .catch(error => {
      handlePromiseError(error, "TODO: Adding company identifier has failed.", "company identifier");
      dispatch({ type: actions.PUT_COMPANY_INDENTIFIER_FAILED });
      throw error;
    });
};

const postCompanyRemap = (companyIdFrom, companyIdTo) => (dispatch, getState) => {
  dispatch({ type: actions.POSTING_COMPANY_REMAP });
  return data
    .post(`v1/api/account/company/merge/${companyIdFrom}/with/${companyIdTo}`)
    .then(e => {
      dispatch({ type: actions.POSTED_COMPANY_REMAP, companyIdTo });
    })
    .catch(error => {
      handlePromiseError(error, "TODO: Company remap has failed.", "company mapping");
      dispatch({ type: actions.POST_COMPANY_INFO_FAILED });
    });
};

const postCompanyIsSpam = (companyId, companyIdSpam) => (dispatch, getState) => {
  dispatch({ type: actions.POSTING_COMPANY_SPAM });
  return data
    .put(`v1/api/connector/email/config/${companyId}/spam/company`, `"${companyIdSpam}"`, {
      headers: { "Content-Type": "application/json" }
    })
    .then(e => {
      dispatch({ type: actions.POSTED_COMPANY_SPAM });
    })
    .catch(rejection => {
      handlePromiseError(rejection, "TODO: Designating company as spam has failed.", "spam designation");
      dispatch({ type: actions.POST_COMPANY_SPAM_FAILED });
    });
};

const fetchSpamAddressList = companyId => (dispatch, getState) => {
  if (getState().companies.fetchingSpamAddressList) {
    return;
  }
  dispatch({ type: actions.FETCHING_SPAM_ADDRESS_LIST });

  data
    .get(`v1/api/connector/email/config/${companyId}/spam/address`)
    .then(response => {
      dispatch({ type: actions.FETCHED_SPAM_ADDRESS_LIST, spamList: response.data });
    })
    .catch(e => {
      dispatch({ type: actions.FETCH_SPAM_ADDRESS_FAILED });
    });
};

const getSpamAddressList = companyId => (dispatch, getState) => {
  const store = getState().companies;

  if (store.fetchedSpamAddressList === false && store.fetchSpamAddressListFailed === false) {
    dispatch(fetchSpamAddressList(companyId));
    return null;
  } else {
    return store.spamAddressList;
  }
};

const deleteAddressFromSpamList = (companyId, spamAddressId) => (dispatch, getState) => {
  const store = getState().companies;

  if (store.deletingAddressFromSpamList) {
    return;
  }

  dispatch({ type: actions.DELETING_ADDRESS_FROM_SPAM_LIST });

  data
    .delete(`v1/api/connector/email/config/${companyId}/spam/address/${spamAddressId}`)
    .then(response => {
      dispatch({ type: actions.DELETED_ADDRESS_FROM_SPAM_LIST, spamList: response.data });
    })
    .catch(err => {
      dispatch({ type: actions.DELETE_ADDRESS_FROM_SPAM_LIST_FAILED });
    });
};

const postAddressIsSpam = (companyId, address) => (dispatch, getState) => {
  dispatch({ type: actions.POSTING_ADDRESS_SPAM });
  return data
    .put(`v1/api/connector/email/config/${companyId}/spam/address`, `"${address}"`, {
      headers: { "Content-Type": "application/json" }
    })
    .then(response => {
      dispatch({ type: actions.POSTED_ADDRESS_SPAM, spamList: response.data });
      return true;
    })
    .catch(rejection => {
      handlePromiseError(rejection, "TODO: Designating address as spam has failed.", "spam designation");
      dispatch({ type: actions.POST_ADDRESS_SPAM_FAILED });
      throw rejection;
    });
};

export const dispatchToProps = dispatch => ({
  fetchCompanyInfo: companyId => {
    return dispatch(fetchCompanyInfo(companyId));
  },
  getCompanyInfo: companyId => {
    return dispatch(getCompanyInfo(companyId));
  },
  isCompanyUnowned: companyId => {
    return dispatch(isCompanyUnowned(companyId));
  },
  postCompanyInfo: (companyInfo, companyId) => {
    return dispatch(postCompanyInfo(companyInfo, companyId));
  },
  postCompanyName: (companyName, companyId) => {
    return dispatch(postCompanyName(companyName, companyId));
  },
  getCompanyIdentifiers: companyId => {
    return dispatch(getCompanyIdentifiers(companyId));
  },
  fetchCompanyIdentifiers: companyId => {
    return dispatch(fetchCompanyIdentifiers(companyId));
  },
  deleteCompanyIdentifier: (emailConnectorId, connectorCompanyPartyIdentifierId) => {
    return dispatch(deleteCompanyIdentifier(emailConnectorId, connectorCompanyPartyIdentifierId));
  },
  putCompanyIdentifier: (emailConnectorId, sourceCompanyId, identifiedCompanyId, identifier) => {
    return dispatch(putCompanyIdentifier(emailConnectorId, sourceCompanyId, identifiedCompanyId, identifier));
  },
  postCompanyRemap: (companyIdFrom, companyIdTo) => {
    return dispatch(postCompanyRemap(companyIdFrom, companyIdTo));
  },
  postCompanyIsSpam: (companyId, companyIdSpam) => {
    return dispatch(postCompanyIsSpam(companyId, companyIdSpam));
  },
  postAddressIsSpam: (companyId, address) => {
    return dispatch(postAddressIsSpam(companyId, address));
  },
  fetchSpamAddressList: companyId => {
    dispatch(fetchSpamAddressList(companyId));
  },
  getSpamAddressList: companyId => {
    return dispatch(getSpamAddressList(companyId));
  },
  deleteAddressFromSpamList: (companyId, spamAddressId) => {
    return dispatch(deleteAddressFromSpamList(companyId, spamAddressId));
  }
});
