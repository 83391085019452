import React, { Component } from "react";

class IconClose extends Component {
  render() {
    let { className, height } = this.props;

    return (
      <svg
        height={height}
        className={`icon ${className ? className : ""}`}
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        viewBox="0 0 24 24"
      >
        <defs />
        <path d="M18 6L6 18M6 6l12 12" />
      </svg>
    );
  }
}

IconClose.defaultProps = {
  height: 24
};

export default IconClose;
