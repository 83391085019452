import Resources from "./resources";
import { formatCurrency, getInvoiceNum, isEmpty } from "./utils";
import moment from "moment";

export function formatInvoice(ublInvoice) {
  const result = {};
  result.id = (ublInvoice.ID || {}).Value || "";
  result.invoiceNum = `${Resources.Invoice} ${getInvoiceNum(ublInvoice.ID) || ""}`;
  result.issueDate = moment(ublInvoice.IssueDate.ValueString).format("ll");
  const dueDate = isEmpty(ublInvoice.DueDate)
    ? (((ublInvoice.PaymentMeans || [])[0] || {}).PaymentDueDate || {}).ValueString
    : ublInvoice.DueDate.ValueString;
  result.dueDate = isEmpty(dueDate) ? "-" : moment(dueDate).format("ll");
  result.amount = formatCurrency(((ublInvoice.LegalMonetaryTotal || {}).PayableAmount || {}).Value);

  return result;
}
