import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Form, FormGroup, Input } from "reactstrap";
import Icons from "../lib/icons";
import Resources from "../lib/resources";
import { getConfig } from "../lib/utils";

class SetupForwardEmail extends Component {
  perspectiveText() {
    let contextText;
    if (this.props.perspective === "AR") contextText = [Resources.SetupForwardingEmailHeaderAR];
    if (this.props.perspective === "AP") contextText = [Resources.SetupForwardingEmailHeaderAP];

    return contextText;
  }

  toClipboard(text) {
    const el = document.createElement("textarea");
    el.value = text;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
  }

  render() {
    let AP_OR_AR_CONTEXT = this.perspectiveText();
    let {
      registration: { emailForwardingFromAddress, emailForwardingToAddress, isSettingEmailForwardingAddress },
      progressStage
    } = this.props;
    return (
      <div>
        {isSettingEmailForwardingAddress ? (
          <div className={`${Icons.spinner} fa-8x p-5`} />
        ) : (
          <div>
            <h3 className="text-center">{AP_OR_AR_CONTEXT[0]}</h3>
            <hr />
            <Form className=" light-gray-bg w-90 mx-auto p-4" onSubmit={e => e.preventDefault()}>
              <FormGroup className="">
                <p className="">{Resources.SetupForwardingEmailForwardTo(emailForwardingFromAddress)}</p>
                <Input
                  className="w-70 mr-2 d-inline-block"
                  style={{ backgroundColor: "#FFFFFF" }}
                  name="lockstepEmail"
                  id="forwardEmail"
                  value={emailForwardingToAddress}
                  disabled
                />
                <Button
                  onClick={() => this.toClipboard(document.getElementById("forwardEmail").value)}
                  className="w-25"
                >
                  {Resources.Copy}
                </Button>
                <p className="mt-4">
                  <a className="d-block mb-1" href={getConfig().helpLinkUrl} target="_blank" rel="noopener noreferrer">
                    {Resources.SetupForwardingEmailNeedHelpLink}
                  </a>{" "}
                  {Resources.Or}{" "}
                  <a className="d-block mt-1" href={window.location.hash}>
                    {/* TODO: what do we want here for the link ? */}
                    {Resources.SetupForwardingEmailSendPreEmailLink}
                  </a>
                </p>
              </FormGroup>
            </Form>
            <Button onClick={() => progressStage("CONNECTION_VERIFIED")} className="mt-3 mx-auto w-25 d-block">
              {Resources.Verify + " " + Resources.Forwarding}
            </Button>
          </div>
        )}
      </div>
    );
  }
}

const storeToProps = store => {
  return {
    registration: store.registration
  };
};

export default connect(storeToProps)(SetupForwardEmail);
