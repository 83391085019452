import React, { Component } from "react";
import { connect } from "react-redux";
import { dispatchToProps as aaDP } from "../store/accounts-actions";
import { dispatchToProps as maDP } from "../store/manage-actions";
import { dispatchToProps as persDP } from "../store/perspectives-actions";

import { isEmpty } from "../lib/utils";
import Resources from "../lib/resources";

import MainContentHeader from "./main_content_header/mainContentHeader";
import PerspectiveTabs from "./perspectiveTabs";
import EditApproverForm from "./editApproverForm";
import ModalDialog from "./modalDialog";
import MainLoader from "./mainLoader";
import CRUDList from "./library/crudList";
import Card from "./library/card";

const dispatchToProps = dispatch => ({
  ...aaDP(dispatch),
  ...maDP(dispatch),
  ...persDP(dispatch)
});

class ManageApprovers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showApproverForm: false,
      selectedApprover: null,
      perspective: null,
      removeApprover: null
    };

    this.editApprover = this.editApprover.bind(this);
  }

  componentDidUpdate() {
    if (this.state.perspective === null) {
      const defaultPerspective = this.props.getCompanyPerspectiveById(
        this.props.userStore.userConfig.defaultPerspective,
        this.props.match.params.companyId
      );
      if (defaultPerspective !== null) {
        this.setState({ perspective: defaultPerspective });
      }
    }
  }

  deselectApprover() {
    this.setState({ selectedApprover: null });
  }

  editApprover(approver = null) {
    this.setState({ showApproverForm: true, selectedApprover: approver });
  }

  removeApprover = approver => {
    // We don't actually delete approvers, we just remove the working perspective from the approver
    const companyId = this.props.match.params.companyId;
    let perspectiveUpdate = [...approver.perspectives];
    perspectiveUpdate.splice(approver.perspectives.indexOf(this.state.perspective.perspectiveId), 1);
    this.props.updateCompanyContact(
      companyId,
      approver.companyContactId,
      Object.assign({}, approver, { perspectives: perspectiveUpdate })
    );
    this.setState({ removeApprover: null });
  };

  render() {
    const { removeApprover, selectedApprover, perspective } = this.state;
    if (isEmpty(perspective)) {
      return <MainLoader fullScreen={true} />;
    }

    const companyId = this.props.match.params.companyId;
    const columns = [
      {
        header: Resources.Name.toLocaleUpperCase(),
        type: "content",
        content: row => {
          return row.fullName;
        },
        width: "25%"
      },
      {
        header: Resources.Email.toLocaleUpperCase(),
        type: "content",
        content: row => {
          return row.email;
        },
        width: "55%"
      },
      {
        header: Resources.Actions.toLocaleUpperCase(),
        type: "actions",
        actions: [
          { type: "update", onClick: row => this.editApprover(row) },
          { type: "delete", onClick: row => this.removeApprover(row), text: Resources.Remove }
        ],
        width: "20%"
      }
    ];

    const perspectiveId = perspective.perspectiveId;
    let approvers = this.props.getCompanyApprovers(companyId)[perspectiveId] || [];

    return (
      <React.Fragment>
        <EditApproverForm
          approver={selectedApprover}
          perspective={perspective}
          companyId={companyId}
          isOpen={this.state.showApproverForm}
          toggle={() => this.setState({ showApproverForm: false })}
        />
        <MainContentHeader
          hideSearch={true}
          title={Resources.Approvers}
          customContent={
            <PerspectiveTabs
              handleSelect={perspective => this.setState({ perspective }, this.deselectApprover())}
              selectedPerspective={perspective}
            />
          }
        />

        <div className="manage-content">
          {removeApprover && (
            <ModalDialog
              isOpen={true}
              toggle={() => this.setState({ removeApprover: null })}
              title={Resources.Confirm}
              content={
                <div
                  dangerouslySetInnerHTML={{
                    __html: Resources.ConfirmDeleteApprover(removeApprover.fullName || removeApprover.email)
                  }}
                />
              }
              footer={
                <div className="float-right">
                  <button
                    className="cancel-button"
                    onClick={() => {
                      this.setState({ removeApprover: null });
                    }}
                  >
                    {Resources.Cancel}
                  </button>
                  <button
                    className="delete-button"
                    onClick={() => {
                      this.removeApprover(removeApprover);
                    }}
                  >
                    {Resources.Remove}
                  </button>
                </div>
              }
            />
          )}
          <Card maxWidth={"80em"} className="card-crud-list">
            <CRUDList
              columns={columns}
              data={approvers}
              title={Resources.ApproverList}
              description={Resources.ApproverListDescription}
              onCreate={this.editApprover}
            />
          </Card>
        </div>
      </React.Fragment>
    );
  }
}

const storeToProps = store => {
  return {
    accountsStore: store.accounts,
    manageStore: store.manage,
    perspectivesStore: store.perspectives,
    userStore: store.user
  };
};

export default connect(
  storeToProps,
  dispatchToProps
)(ManageApprovers);
