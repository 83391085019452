import { actions } from "./company-actions";

const defaultCompaniesState = {
  gettingCompanyInfo: {},
  fetchedCompanyInfo: {},
  companyInfo: {},
  fetchingCompanyInfoFailed: {},

  gettingCompany: {},
  fetchedCompany: {},
  failedCompany: {},

  postingCompanyInfo: false,
  postedCompanyInfo: false,
  failedPostCompanyInfo: false,

  gettingCompanyIdentifiers: {},
  fetchedCompanyIdentifiers: {},
  failedCompanyIdentifiers: {},

  companyIdentifiers: {},

  deletingCompanyIdentifier: {},
  deletedCompanyIdentifier: {},
  failedDeleteCompanyIdentifier: {},

  puttingCompanyIdentifier: false,
  putCompanyIdentifier: false,
  failedPuCompanyIdentifier: false,

  postingCompanyRemap: false,
  postedCompanyRemap: false,
  failedPostCompanyRemap: false,

  postingCompanySpam: false,
  postedCompanySpam: false,
  failedPostCompanySpam: false,

  postingAddressSpam: false,
  postedAddressSpam: false,
  failedPostAddressSpam: false,

  spamAddressList: [],
  fetchingSpamAddressList: false,
  fetchedSpamAddressList: false,
  fetchSpamAddressListFailed: false,

  deletingAddressFromSpamList: false,
  deletedAddressFromSpamList: false,
  deleteAddressFromSpamListFailed: false
};

function companyReducer(state = defaultCompaniesState, action) {
  switch (action.type) {
    case actions.GETTING_COMPANY_INFO:
      return {
        ...state,
        gettingCompanyInfo: {
          ...state.gettingCompanyInfo,
          [action.companyId]: true
        },
        fetchedCompanyInfo: {
          ...state.fetchedCompanyInfo,
          [action.companyId]: false
        },
        fetchingCompanyInfoFailed: {
          ...state.fetchingCompanyInfoFailed,
          [action.companyId]: null
        }
      };
    case actions.GET_COMPANY_INFO_FAILED:
      return {
        ...state,
        gettingCompanyInfo: {
          ...state.gettingCompanyInfo,
          [action.companyId]: false
        },
        fetchedCompanyInfo: {
          ...state.fetchedCompanyInfo,
          [action.companyId]: false
        },
        fetchingCompanyInfoFailed: {
          ...state.fetchingCompanyInfoFailed,
          [action.companyId]: true
        }
      };
    case actions.GOT_COMPANY_INFO:
      return {
        ...state,
        companyInfo: {
          ...state.companyInfo,
          [action.companyId]: {
            ...action.companyInfo,
            connectorEmails: action.connectorEmails
          }
        },
        gettingCompanyInfo: {
          ...state.gettingCompanyInfo,
          [action.companyId]: false
        },
        fetchedCompanyInfo: {
          ...state.fetchedCompanyInfo,
          [action.companyId]: true
        },
        fetchingCompanyInfoFailed: {
          ...state.fetchingCompanyInfoFailed,
          [action.companyId]: false
        }
      };

    case actions.GETTING_COMPANY:
      return {
        ...state,
        gettingCompany: { ...state.gettingCompany, [action.companyId]: true },
        fetchedCompany: { ...state.fetchedCompany, [action.companyId]: false }
      };
    case actions.GET_COMPANY_FAILED:
      return {
        ...state,
        gettingCompany: { ...state.gettingCompany, [action.companyId]: false },
        fetchedCompany: { ...state.fetchedCompany, [action.companyId]: false },
        failedCompany: { ...state.failedCompany, [action.companyId]: true }
      };
    case actions.GOT_COMPANY:
      return {
        ...state,
        gettingCompany: { ...state.gettingCompany, [action.companyId]: false },
        fetchedCompany: { ...state.fetchedCompany, [action.companyId]: true },
        failedCompany: { ...state.failedCompany, [action.companyId]: false }
      };

    case actions.GETTING_COMPANY_INDENTIFIERS:
      return {
        ...state,
        gettingCompanyIdentifiers: {
          ...state.gettingCompanyIdentifiers,
          [action.companyId]: true
        },
        fetchedCompanyIdentifiers: {
          ...state.fetchedCompanyIdentifiers,
          [action.companyId]: false
        }
      };
    case actions.GET_COMPANY_INDENTIFIERS_FAILED:
      return {
        ...state,
        gettingCompanyIdentifiers: {
          ...state.gettingCompanyIdentifiers,
          [action.companyId]: false
        },
        fetchedCompanyIdentifiers: {
          ...state.fetchedCompanyIdentifiers,
          [action.companyId]: false
        },
        failedCompanyIdentifiers: {
          ...state.failedCompanyIdentifiers,
          [action.companyId]: true
        }
      };
    case actions.GOT_COMPANY_INDENTIFIERS:
      return {
        ...state,
        companyIdentifiers: {
          ...state.companyIdentifiers,
          [action.companyId]: [...action.companyIdentifiers]
        },
        gettingCompanyIdentifiers: {
          ...state.gettingCompanyIdentifiers,
          [action.companyId]: false
        },
        fetchedCompanyIdentifiers: {
          ...state.fetchedCompanyIdentifiers,
          [action.companyId]: true
        },
        failedCompanyIdentifiers: {
          ...state.failedCompanyIdentifiers,
          [action.companyId]: false
        }
      };

    case actions.DELETING_COMPANY_INDENTIFIER:
      return {
        ...state,
        deletingCompanyIdentifier: {
          ...state.deletingCompanyIdentifier,
          [action.connectorCompanyPartyIdentifierId]: true
        },
        deletedCompanyIdentifier: {
          ...state.deletedCompanyIdentifier,
          [action.connectorCompanyPartyIdentifierId]: false
        },
        failedDeleteCompanyIdentifier: {
          ...state.failedDeleteCompanyIdentifier,
          [action.connectorCompanyPartyIdentifierId]: false
        }
      };
    case actions.DELETE_COMPANY_INDENTIFIER_FAILED:
      return {
        ...state,
        deletingCompanyIdentifier: {
          ...state.deletingCompanyIdentifier,
          [action.connectorCompanyPartyIdentifierId]: false
        },
        deletedCompanyIdentifier: {
          ...state.deletedCompanyIdentifier,
          [action.connectorCompanyPartyIdentifierId]: false
        },
        failedDeleteCompanyIdentifier: {
          ...state.failedDeleteCompanyIdentifier,
          [action.connectorCompanyPartyIdentifierId]: true
        }
      };
    case actions.DELETED_COMPANY_INDENTIFIER:
      return {
        ...state,
        deletingCompanyIdentifier: {
          ...state.deletingCompanyIdentifier,
          [action.connectorCompanyPartyIdentifierId]: false
        },
        deletedCompanyIdentifier: {
          ...state.deletedCompanyIdentifier,
          [action.connectorCompanyPartyIdentifierId]: true
        },
        failedDeleteCompanyIdentifier: {
          ...state.failedDeleteCompanyIdentifier,
          [action.connectorCompanyPartyIdentifierId]: false
        }
      };

    case actions.PUTTING_COMPANY_INDENTIFIER:
      return {
        ...state,
        puttingCompanyIdentifier: true,
        putCompanyIdentifier: false,
        failedPuCompanyIdentifier: false
      };
    case actions.PUT_COMPANY_INDENTIFIER:
      return {
        ...state,
        puttingCompanyIdentifier: false,
        putCompanyIdentifier: true,
        failedPuCompanyIdentifier: false
      };
    case actions.PUT_COMPANY_INDENTIFIER_FAILED:
      return {
        ...state,
        puttingCompanyIdentifier: false,
        putCompanyIdentifier: false,
        failedPuCompanyIdentifier: true
      };

    case actions.POSTING_COMPANY_INFO:
      return {
        ...state,
        postingCompanyInfo: true,
        postedCompanyInfo: false,
        failedPostCompanyInfo: false
      };
    case actions.POSTED_COMPANY_INFO:
      return {
        ...state,
        postingCompanyInfo: false,
        postedCompanyInfo: true,
        failedPostCompanyInfo: false
      };
    case actions.POST_COMPANY_INFO_FAILED:
      return {
        ...state,
        postingCompanyInfo: false,
        postedCompanyInfo: false,
        failedPostCompanyInfo: true
      };

    case actions.POSTING_COMPANY_REMAP:
      return {
        ...state,
        postingCompanyRemap: true,
        postedCompanyRemap: false,
        failedPostCompanyRemap: false
      };
    case actions.POSTED_COMPANY_REMAP:
      return {
        ...state,
        companyIdentifiers: {
          ...state.companyIdentifiers,
          [action.companyIdTo]: []
        },
        fetchedCompanyIdentifiers: {
          ...state.fetchedCompanyIdentifiers,
          [action.companyIdTo]: false
        },
        postingCompanyRemap: false,
        postedCompanyRemap: true,
        failedPostCompanyRemap: false
      };
    case actions.POST_COMPANY_REMAP_FAILED:
      return {
        ...state,
        postingCompanyRemap: false,
        postedCompanyRemap: false,
        failedPostCompanyRemap: true
      };

    case actions.POSTING_COMPANY_SPAM:
      return {
        ...state,
        postingCompanySpam: true,
        postedCompanySpam: false,
        failedPostCompanySpam: false
      };
    case actions.POSTED_COMPANY_SPAM:
      return {
        ...state,
        postingCompanySpam: false,
        postedCompanySpam: true,
        failedPostCompanySpam: false
      };
    case actions.POST_COMPANY_SPAM_FAILED:
      return {
        ...state,
        postingCompanySpam: false,
        postedCompanySpam: false,
        failedPostCompanySpam: true
      };

    case actions.POSTING_ADDRESS_SPAM:
      return {
        ...state,
        postingAddressSpam: true,
        postedAddressSpam: false,
        failedPostAddressSpam: false
      };
    case actions.POSTED_ADDRESS_SPAM:
      return {
        ...state,
        spamAddressList: action.spamList,
        postingAddressSpam: false,
        postedAddressSpam: true,
        failedPostAddressSpam: false
      };
    case actions.POST_ADDRESS_SPAM_FAILED:
      return {
        ...state,
        postingAddressSpam: false,
        postedAddressSpam: false,
        failedPostAddressSpam: true
      };

    case actions.FETCHING_SPAM_ADDRESS_LIST:
      return {
        ...state,
        fetchingSpamAddressList: true,
        fetchedSpamAddressList: false,
        fetchSpamAddressListFailed: false
      };

    case actions.FETCHED_SPAM_ADDRESS_LIST:
      return {
        ...state,
        fetchingSpamAddressList: false,
        fetchedSpamAddressList: true,
        fetchSpamAddressListFailed: false,
        spamAddressList: action.spamList
      };

    case actions.FETCH_SPAM_ADDRESS_LIST_FAILED:
      return {
        ...state,
        fetchingSpamAddressList: false,
        fetchedSpamAddressList: false,
        fetchSpamAddressListFailed: true
      };

    case actions.DELETING_ADDRESS_FROM_SPAM_LIST:
      return {
        ...state,
        deletingAddressFromSpamList: true,
        deletedAddressFromSpamList: false,
        deleteAddressFromSpamListFailed: false
      };
    case actions.DELETED_ADDRESS_FROM_SPAM_LIST:
      return {
        ...state,
        spamAddressList: action.spamList,
        deletingAddressFromSpamList: false,
        deletedAddressFromSpamList: true,
        deleteAddressFromSpamListFailed: false
      };
    case actions.DELETE_ADDRESS_FROM_SPAM_LIST_FAILED:
      return {
        ...state,
        deletingAddressFromSpamList: false,
        deletedAddressFromSpamList: false,
        deleteAddressFromSpamListFailed: true
      };

    case actions.CLEAR_DATA:
      return defaultCompaniesState;
    default:
      return state;
  }
}

export default companyReducer;
