import React, { Component } from "react";
import { connect } from "react-redux";
import Resources from "../lib/resources";
import { dispatchToProps } from "../store/connector-actions";
import { isEmpty } from "../lib/utils";
import MainLoader from "./mainLoader";
import MainContentHeader from "./main_content_header/mainContentHeader";
import ERPConfiguration from "./ERPConfiguration";
import ERPRegistration from "./ERPRegistration";

class EditAccountingPackage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      connector: {},
      connectorVersion: {},
      configInfo: {},
      view: null
    };
  }

  tryUpdate(prevProps, prevState = {}) {
    const {
      connectorStore,
      getRegisteredERPConnectors,
      getLatestConnectorVersion,
      match: {
        params: { companyId }
      },
      location
    } = this.props;
    const registeredConnector = getRegisteredERPConnectors(companyId)[0];

    if (registeredConnector !== null) {
      if (isEmpty(registeredConnector)) {
        this.props.history.replace(location.pathname.replace("/edit", ""));
        return;
      }

      const connectorVersion = getLatestConnectorVersion(registeredConnector.connectorId);
      const configInfo = connectorStore.connectorConfigs[connectorVersion.connectorVersionId];

      // TODO: we should help them figure out what's wrong with their registration if its status is not complete
      // if (configInfo.configStatus !== "Complete") {
      // this.props.history.replace(this.props.location.pathname.replace("edit", "registering?invalidRegistration=true"))
      // return
      // }

      if (isEmpty(this.state.connector)) {
        let view = "configuration";

        // if there's an invalid registration default to registration
        if (connectorVersion.configurationType.toLowerCase() === "manual" && false) {
          view = "registration";
        }

        this.setState({
          connector: registeredConnector,
          connectorVersion,
          configInfo,
          view
        });
      }
      // If they change companies, take them back to main connector page
      if (!isEmpty(prevProps) && this.props.companyId !== prevProps.companyId) {
        this.props.history.replace(this.props.location.pathname.replace("/edit", ""));
      }
    }
  }

  componentDidMount() {
    this.tryUpdate();
  }

  componentDidUpdate(prevProps, prevState) {
    this.tryUpdate(prevProps, prevState);
  }

  render() {
    const { connector, connectorVersion, view } = this.state;

    if (isEmpty(connector)) {
      return <MainLoader fullScreen={true} />;
    }

    return (
      <React.Fragment>
        <MainContentHeader
          title={Resources.EditPackageSettings((this.state.connector || {}).displayName || "")}
          hideSearch={true}
        />

        {connectorVersion.configurationType.toLowerCase() === "manual" && (
          <ul className="nav nav-tabs">
            <li className="nav-item">
              <div
                onClick={() => this.setState({ view: "registration" })}
                className={(view === "registration" ? "active" : "") + " nav-link clickable"}
              >
                {Resources.RegistrationSettings}
              </div>
            </li>
            <li className="nav-item">
              <div
                onClick={() => this.setState({ view: "configuration" })}
                className={(view === "configuration" ? "active" : "") + " nav-link clickable"}
              >
                {Resources.DefaultConfiguration}
              </div>
            </li>
          </ul>
        )}
        {view === "configuration" && <ERPConfiguration isEditing={true} {...this.props} />}
        {view === "registration" && <ERPRegistration isEditing={true} {...this.props} />}
      </React.Fragment>
    );
  }
}

const storeToProps = store => {
  return {
    connectorStore: store.connector
  };
};

export default connect(
  storeToProps,
  dispatchToProps
)(EditAccountingPackage);
