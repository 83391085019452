import React, { Component } from "react";

export default class UserSettingsNavContent extends Component {
  getRoute(view) {
    const { params, url } = this.props.match;
    return params.view ? url.replace(params.view || "", view) : `${url}/${view}`;
  }

  render() {
    return <div></div>;
  }
}
