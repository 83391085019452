import React, { Component } from "react";
import { connect } from "react-redux";
import CompanySelectionRow from "./companySelectionRow";
import { getConfig } from "../../lib/utils";
import Icons from "../../lib/icons";
import Resources from "../../lib/resources";
import { dispatchToProps } from "../../store/connector-actions";

class CompanySelectionGrid extends Component {
  componentDidMount() {
    let { companyId, fetchAllConnectors, fetchCompanyGrantedPermissions } = this.props;
    fetchAllConnectors();
    fetchCompanyGrantedPermissions(companyId);
  }

  render() {
    let { companyId } = this.props;
    let companyConnectorConfig = getConfig().companies;

    return false ? (
      <div className={`${Icons.spinner} fa-8x p-5`} />
    ) : (
      <div className="w-75 mx-auto signup-company-grid">
        <div className="m-2">
          <p className="text-center">{Resources.ConnectorCompanySelectionHeader}</p>
        </div>
        <CompanySelectionRow companyId={companyId} companies={companyConnectorConfig.slice(0, 3)} />
        <CompanySelectionRow companyId={companyId} companies={companyConnectorConfig.slice(3, 6)} />
        <CompanySelectionRow companyId={companyId} companies={companyConnectorConfig.slice(6, 9)} />
        <CompanySelectionRow companyId={companyId} companies={companyConnectorConfig.slice(9, 12)} />
        <CompanySelectionRow companyId={companyId} companies={companyConnectorConfig.slice(12, 15)} />
      </div>
    );
  }
}

const storeToProps = store => {
  return {
    user: store.user
  };
};

export default connect(
  storeToProps,
  dispatchToProps
)(CompanySelectionGrid);
