import React, { Component } from "react";
import { connect } from "react-redux";

import { isEmpty, compareStrings, includes } from "../lib/utils";
import Resources from "../lib/resources";
import { withRouter } from "react-router-dom";

import Dropdown from "./library/dropdown";

import { dispatchToProps as moDP } from "../store/modal-actions";
import { dispatchToProps as comDP } from "../store/company-actions";
import { dispatchToProps as genDP } from "../store/general-actions";
import { dispatchToProps as aaDP } from "../store/accounts-actions";
import { dispatchToProps as conDP } from "../store/contextGroups-actions";

const dispatchToProps = dispatch => ({
  ...moDP(dispatch),
  ...comDP(dispatch),
  ...genDP(dispatch),
  ...aaDP(dispatch),
  ...conDP(dispatch)
});

class UserPicker extends Component {
  onUserSelected(e, user) {
    if (isEmpty(this.props.userSelected) !== true) this.props.userSelected(user);
  }

  getUserList() {
    let { user } = this.props;
    let myUserId = this.props.user.decoded.sub || "";
    let me = {};
    let users = [];
    const perspectiveUserIds = this.props.getPerspectiveUserRoles(this.props.perspective).map(r => r.userId);

    user.users.forEach(user => {
      let isMe = (user.userId || "").toLowerCase() === myUserId.toLowerCase();
      user.displayName = isMe ? Resources.Me : user.familyName || user.fullName || user.givenName || "";
      if (isMe === true) {
        me = user;
      } else if (
        this.props.perspectiveUsersOnly === true &&
        !isEmpty(this.props.perspective) &&
        !includes(perspectiveUserIds, user.userId)
      ) {
        return;
      } else {
        users.push(user);
      }
    }, myUserId);
    users.sort((a, b) => {
      return compareStrings(a.displayName, b.displayName);
    });
    users = [{ userId: null, displayName: Resources.Anyone }, me, ...users];
    return users;
  }

  getDropdownItem(item, i) {
    switch (item.type) {
      case "link":
        return (
          <a key={i} className="dropdown-item" href={item.href} target="_blank" rel="noopener noreferrer">
            {item.content}
          </a>
        );
      case "action":
        return (
          <button key={i} className="dropdown-item" onClick={item.onClick}>
            {item.content}
          </button>
        );
      case "break":
        return <div key={i} className="dropdown-divider" />;
      case "header":
        return (
          <span key={i} className="dropdown-header">
            {item.content}
          </span>
        );
      default:
        return null;
    }
  }

  render() {
    const { buttonContent, buttonClassName, disabled } = this.props;
    let users = this.getUserList();
    return (
      <Dropdown buttonContent={buttonContent} buttonClassName={buttonClassName} disabled={disabled}>
        {/* {this.getDropdownItem({ type: "header", content: `${Resources.AssignTo}:` })} */}
        {users.map(user => {
          let userDisabled = user.userId === this.props.disableUserId;
          return isEmpty(user.displayName) === true || userDisabled === true ? null : (
            <Dropdown.Item key={`uid${user.userId}`} onClick={e => this.onUserSelected(e, user)}>
              {user.displayName}
            </Dropdown.Item>
          );
        })}
      </Dropdown>
    );
  }
}

UserPicker.defaultProps = {
  displayUsers: true,
  company: { isNew: false }
};

const storeToProps = store => {
  return {
    user: store.user,
    currentCompanyId: store.conversations.companyId
  };
};

export default withRouter(connect(storeToProps, dispatchToProps)(UserPicker));
