import React, { Component } from "react";
import { connect } from "react-redux";
import Resources from "../../lib/resources";
import { isEmpty, find } from "../../lib/utils";
import { Route, Switch, NavLink, Redirect } from "react-router-dom";
import moment from "moment";

import MainContentHeader from "../main_content_header/mainContentHeader";
import OpenInvoices from "./openInvoices";
import ClosedInvoices from "./closedInvoices";
import Payments from "./payments";
import Documents from "./documents";
import ContactInfo from "./contactInfo";
import Messages from "./messages";
import MainLoader from "../mainLoader";
import PortalSessionExpired from "./portalSessionExpired";
import PortalAccountError from "./portalAccountError";
import LanguageSelector from "./languageSelector";

import { dispatchToProps as genDP } from "../../store/general-actions";
import { dispatchToProps as aaDP } from "../../store/accounts-actions";
import { dispatchToProps as paDP } from "../../store/perspectives-actions";
import { dispatchToProps as cgDP } from "../../store/contextGroups-actions";
import { dispatchToProps as convDP } from "../../store/conversations-actions";
import { dispatchToProps as saDP } from "../../store/statements-actions";
import { dispatchToProps as caDP } from "../../store/company-actions";
import { dispatchToProps as uaDP } from "../../store/user-actions";
import SelectInput from "../library/selectInput";

const dispatchToProps = dispatch => {
  return {
    ...genDP(dispatch),
    ...aaDP(dispatch),
    ...saDP(dispatch),
    ...paDP(dispatch),
    ...cgDP(dispatch),
    ...caDP(dispatch),
    ...uaDP(dispatch),
    ...convDP(dispatch)
  };
};

class CustomerPortal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayAccountError: false,
      changedWithCompanyId: false
    };
  }
  componentDidMount() {
    window.Intercom("shutdown");
    this.tryUpdate();
  }

  componentDidUpdate(prevProps) {
    this.tryUpdate(prevProps);
  }

  componentWillUnmount() {
    window.Intercom("boot");
  }

  tryUpdate(prevProps = {}) {
    let {
      userStore,
      contextGroupsStore,
      perspectivesStore,
      statementsStore,
      conversationsStore,
      accountsStore
    } = this.props;

    if (this.props.APILive === false) {
      return null;
    }
    const subscription = this.props.getSubscription();
    if (subscription === null) {
      return;
    } else if ((subscription.subscriptionTypeName || "").toLowerCase() !== "customer portal") {
      this.props.history.push("/");
    }

    if (userStore.isLoggingIn !== true) {
      let duration = moment.duration(moment(new Date(userStore.decoded.exp * 1000)).diff(moment()));
      let asMinutes = duration.asMinutes();
      if (asMinutes < 5 && !userStore.isTokenExpiring) {
        this.props.tokenExpiring();
      }
    }

    const companyId = accountsStore.selectedCompanyId;

    if (isEmpty(companyId)) {
      this.props.selectCompany(this.props.getDefaultCompany().companyId);
      return null;
    }

    if (isEmpty(contextGroupsStore.companyId) && !isEmpty(companyId)) {
      this.props.fetchCompanyInfo(companyId);
      this.props.selectCompany(companyId);
    }

    const perspectiveId = perspectivesStore.selectedPerspectiveId;

    if (isEmpty(perspectiveId)) {
      const companyPerspectives = this.props.getCompanyPerspectives(companyId);
      if (companyPerspectives === null) {
        return;
      }

      const vendorPerspective = find(
        companyPerspectives,
        cPerspective => cPerspective.parentPerspective.perspectiveName === "vendors"
      );

      this.props.setSelectedPerspectiveId(vendorPerspective.perspectiveId);
    } else if (isEmpty(statementsStore.withCompanyId)) {
      // This should only run once
      const contextGroupsMaps = this.props.getContextGroupsMaps(companyId, perspectiveId);

      if (contextGroupsMaps === null) {
        return;
      }
      if (isEmpty(contextGroupsMaps)) {
        // There is no context groups to show or assosciated companies with this account.
        // This does not make sense as it is a portal account.
        if (this.state.displayAccountError !== true) {
          this.setState({ displayAccountError: true });
        }
        return;
      } else if (this.state.displayAccountError === true) {
        this.setState({ displayAccountError: false });
      }

      const withCompanyId = (contextGroupsMaps[Object.keys(contextGroupsMaps)[0]] || {}).companyId;

      this.props.fetchCompanyInfo(withCompanyId);
      this.props.setWithCompanyId(withCompanyId);
    }

    if (!isEmpty(prevProps) && statementsStore.withCompanyId !== prevProps.statementsStore.withCompanyId) {
      const withCompanyId = this.props.statementsStore.withCompanyId;
      const withContextGroupId = (
        find(this.props.contextGroupsStore.value, contextGroup => contextGroup.companyId === withCompanyId) || {}
      ).contextGroupId;

      if (
        conversationsStore.gettingConversations[perspectiveId] !== true &&
        conversationsStore.fetchedConversations[perspectiveId] !== true
      ) {
        // Get unassigned conversations to populate inbox counter
        this.props.getLabeledWithCompanyConversations(companyId, perspectiveId, withContextGroupId, "unassigned");
      }

      if (statementsStore.fetchingPaymentInfoFailed !== true) {
        this.props.fetchPaymentInfo(companyId, withCompanyId);
      }

      if (statementsStore.fetchingCurrenciesFailed !== true) {
        this.props.fetchCurrencies(companyId, perspectiveId, withCompanyId);
      }

      this.setState({ changedWithCompanyId: true });
    }
  }

  getDefaultRouteFromPage(defaultRoute) {
    if (isEmpty(defaultRoute)) {
      return "";
    }
    switch (defaultRoute) {
      case "Documents":
        return "documents";
      case "CustPayments":
        return "payments";
      case "CustContactInfo":
        return "contacts";
      case "CustInvoices":
        return "closed-invoices";
      case "CustStatement":
      default:
        return "open-invoices";
    }
  }

  render() {
    const {
      contextGroupsStore,
      perspectivesStore,
      statementsStore,
      userStore,
      companyStore,
      accountsStore
    } = this.props;

    if (this.state.displayAccountError) {
      return <PortalAccountError />;
    }

    const selectedCompany = accountsStore.selectedCompany;
    const { companyName, companyId } = selectedCompany;

    const perspectiveId = perspectivesStore.selectedPerspectiveId;
    const withCompanyId = statementsStore.withCompanyId;
    const companyCustId = this.props.getCompanyInfo(companyId).CustID || "";
    let defaultPage = (this.props.getCompanyInfo(withCompanyId).CompanySettings || {}).DefaultPortalPage;
    if (companyStore.fetchedCompanyInfo[withCompanyId] && isEmpty(defaultPage)) {
      defaultPage = "CustStatement";
    }
    const defaultRoute = this.getDefaultRouteFromPage(defaultPage);

    const withContextGroup =
      find(contextGroupsStore.value, contextGroup => contextGroup.companyId === withCompanyId) || {};
    const withContextGroupId = withContextGroup.contextGroupId;
    const withCompanyName = withContextGroup.displayName || withContextGroup.companyName;

    const injectedProps = { companyId, perspectiveId, withCompanyId, withCompanyName, withContextGroupId };
    const unassignedUnreads = this.props.conversationsStore.unassignedUnreads;

    const headerLinks = (
      <React.Fragment>
        <NavLink className="content-header-link" to={"/customer-portal/open-invoices"}>
          {Resources.OpenInvoices.toLocaleUpperCase()}
        </NavLink>
        <NavLink className="content-header-link" to={"/customer-portal/closed-invoices"}>
          {Resources.ClosedInvoices.toLocaleUpperCase()}
        </NavLink>
        <NavLink className="content-header-link" to={"/customer-portal/payments"}>
          {Resources.Payments.toLocaleUpperCase()}
        </NavLink>
        <NavLink className="content-header-link" to={"/customer-portal/documents"}>
          {Resources.Documents.toLocaleUpperCase()}
        </NavLink>
        <NavLink
          className={`content-header-link ${unassignedUnreads ? "with-badge" : ""}`}
          to={"/customer-portal/communications"}
        >
          {Resources.Communications.toLocaleUpperCase()}
          {unassignedUnreads > 0 && (
            <div className="badge-white-bg" style={{ marginLeft: ".4rem" }}>
              {unassignedUnreads}
            </div>
          )}
        </NavLink>
        <NavLink className="content-header-link" to={"/customer-portal/contacts"}>
          {Resources.Contacts.toLocaleUpperCase()}
        </NavLink>
      </React.Fragment>
    );
    const propMerger = Component => {
      return props => {
        const mergedProps = { ...props, ...injectedProps };
        return <Component {...mergedProps} />;
      };
    };

    if (userStore.isTokenExpiring) {
      return <PortalSessionExpired />;
    }

    if (
      isEmpty(withCompanyName) ||
      (isEmpty(statementsStore.currencies) && this.state.changedWithCompanyId === false)
    ) {
      return <MainLoader fullScreen={true} />;
    }

    let logoUrl = this.props.getCompanyInfo(withCompanyId).iconUrl;
    let headerSubtitle =
      companyName === companyCustId
        ? Resources.HelloCompanyNameWelcomeBack(companyName)
        : Resources.HelloCompanyNameIdWelcomeBack(companyName, companyCustId);

    const companySelectOptions = accountsStore.selectedAccount.companies.map(company => {
      return {
        value: company.companyId,
        displayName: company.companyName
      };
    });

    const companySelector =
      accountsStore.selectedAccount.companies.length > 1 ? (
        <SelectInput
          value={companyId}
          displayValue={companyName}
          options={companySelectOptions}
          onSelectOption={option => this.props.selectCompany(option.value)}
          //label={Resources.Template}
          labelClassName="reply-form-label"
          width="25rem"
          className="mt-2"
        />
      ) : null;

    let portalCompanySelector;

    if (accountsStore.selectedAccount.companies.length > 1) {
      portalCompanySelector = companySelector;
      headerSubtitle = null;
    }

    return (
      <div className="customer-portal">
        <MainContentHeader
          logoSrc={logoUrl}
          isCustomerPortal
          title={withCompanyName}
          links={headerLinks}
          subtitle={!isEmpty(companyName) && !isEmpty(companyCustId) && headerSubtitle}
          companySelect={portalCompanySelector}
        />
        <div className="customer-portal-content">
          <Switch>
            {!isEmpty(defaultRoute) && (
              <Redirect exact path="/customer-portal" to={`/customer-portal/${defaultRoute}`} />
            )}
            <Route
              exact
              path="/customer-portal"
              render={() => <MainLoader fullScreen className="portal-page-loader" />}
            />
            <Route path="/customer-portal/open-invoices" render={propMerger(OpenInvoices)} />
            <Route path="/customer-portal/closed-invoices" render={propMerger(ClosedInvoices)} />
            <Route path="/customer-portal/payments" render={propMerger(Payments)} />
            <Route path="/customer-portal/documents" render={propMerger(Documents)} />
            <Redirect exact path="/customer-portal/communications" to="/customer-portal/communications/inbox" />
            <Route path="/customer-portal/communications/:folder" render={propMerger(Messages)} />
            <Route path="/customer-portal/contacts" render={propMerger(ContactInfo)} />
          </Switch>
        </div>
        <div className="customer-portal-language-selector-container">
          <LanguageSelector />
        </div>
      </div>
    );
  }
}

const storeToProps = store => {
  return {
    accountsStore: store.accounts,
    userStore: store.user,
    conversationsStore: store.conversations,
    companyStore: store.companies,
    perspectivesStore: store.perspectives,
    statementsStore: store.statements,
    contextGroupsStore: store.contextGroups,
    APILive: store.general.isAPILive
  };
};

export default connect(storeToProps, dispatchToProps)(CustomerPortal);
