import React, { Component } from "react";
import { Route, Switch, NavLink } from "react-router-dom";
import { connect } from "react-redux";

import { dispatchToProps as eaDP } from "../store/error-actions";
import { dispatchToProps as caDP } from "../store/conversations-actions";
import { dispatchToProps as paDP } from "../store/perspectives-actions";
import { dispatchToProps as uaDP } from "../store/user-actions";

import Resources from "../lib/resources";
import Icons from "../lib/icons";

import CompanySelector from "./companySelector";
import CompanyBrowser from "./companyBrowser";
import UserSettingsNavContent from "./userSettingsNavContent";
import ManageNavContent from "./manageNavContent";
import ReportsNavContent from "./reportsNavContent";
import LockstepLogo from "./library/lockstepLogo";
import CustomerIcon from "./library/customerIcon";
import VendorIcon from "./library/vendorIcon";

import { isEmpty } from "../lib/utils";
import { getPartnerType } from "../lib/perspectives";

class SideNav extends Component {
  renderNavContent() {
    return (
      <Switch>
        <Route path="/company/:companyId/user-settings/:view" component={UserSettingsNavContent} />
        <Route path="/company/:companyId/manage/:view?" component={ManageNavContent} />
        <Route path="/company/:companyId/reports/:view?" component={ReportsNavContent} />
        <Route
          path="/company/:companyId/:perspectiveId/:view?/:selectedContextGroupId?/:selectedItemId?"
          component={CompanyBrowser}
        />
      </Switch>
    );
  }

  render() {
    const {
      match: { params }
    } = this.props;

    if (
      params.section === "customer-portal" ||
      params.view === "account-groups" ||
      params.view === "sequences" ||
      params.section === "v2"
    ) {
      return null;
    }

    if (isEmpty(params.companyId)) {
      return (
        <div className="main-nav">
          <div id="menu-top">
            <a id="menu-logo" href="/">
              <LockstepLogo height={40} inverse />
            </a>
          </div>
        </div>
      );
    }
    const companyPerspectives = this.props.getCompanyPerspectives(params.companyId) || [];

    const viewMap = {
      manage: Resources.Manage,
      reports: Resources.Reports,
      search: Resources.Search
    };

    companyPerspectives.forEach(perspective => {
      viewMap[perspective.perspectiveId] = getPartnerType(perspective.perspectiveName, true);
    });

    const isAdmin = this.props.isCompanyAdmin() === true;

    const renderIcon = className => {
      switch (className) {
        case "vendor-text-icon":
          return <VendorIcon className="v-align-bottom" />;
        case "customer-text-icon":
          return <CustomerIcon className="v-align-bottom" />;
        default:
          return <span className={className} />;
      }
    };

    const workspaceSelector = perspectiveId => {
      if (perspectiveId === "manage" || perspectiveId === "user-settings") {
        return (
          <div
            className="manage-close"
            onClick={event => {
              this.props.history.push(
                `/v2/${params.companyId}/${
                  "" /*this.props.user.userConfig.defaultPerspective ||companyPerspectives[0].perspectiveId}/activities/unassigned*/
                }`
              );
            }}
          >
            <span className={Icons.angleLeft + " pr-2"} />
            <span>{Resources.BackToWorkspace}</span>
          </div>
        );
      } else {
        return (
          <React.Fragment>
            <span role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              {viewMap[params.perspectiveId]}
              <span className={Icons.dropdown} />
            </span>
            <span className="dropdown-menu">
              {companyPerspectives.map((perspective, i) => {
                return (
                  <NavLink
                    key={i}
                    className="dropdown-item"
                    to={`/company/${params.companyId}/${perspective.perspectiveId}`}
                  >
                    {renderIcon(perspective.iconName)}
                    {viewMap[perspective.perspectiveId]}
                  </NavLink>
                );
              })}
              {isAdmin && (
                <NavLink className="dropdown-item" to={`/company/${params.companyId}/reports`}>
                  <span className={Icons.reports} />
                  {Resources.Reports}
                </NavLink>
              )}
            </span>
          </React.Fragment>
        );
      }
    };

    return (
      <div className="main-nav">
        <div id="menu-top">
          <a id="menu-logo" href="/">
            <LockstepLogo height={40} inverse />
          </a>
          <Switch>
            <Route exact path="/disconnect" component={CompanySelector} />
            <Route
              path="/:section/:companyId/:perspectiveId?/:view?/:selectedContextGroupId?"
              component={CompanySelector}
            />
          </Switch>
          <Route
            path="/:section/:companyId?/:perspectiveId?/:view?"
            render={() => {
              return (
                <div className="menu-toggle dropdown">{workspaceSelector(this.props.match.params.perspectiveId)}</div>
              );
            }}
          />
        </div>
        {this.renderNavContent()}
      </div>
    );
  }
}

const storeToProps = store => {
  return {
    user: store.user,
    error: store.error,
    perspecitvesStore: store.perspectives
  };
};

const dispatchToProps = dispatch => ({
  ...eaDP(dispatch),
  ...caDP(dispatch),
  ...paDP(dispatch),
  ...uaDP(dispatch)
});

export default connect(storeToProps, dispatchToProps)(SideNav);
