import React, { Component } from "react";

class IconAngleDown extends Component {
  render() {
    let { className, height, width, strokeWidth, style } = this.props;

    return (
      <svg
        viewBox="0 0 14 8"
        height={height || null}
        width={width || null}
        className={`icon ${className ? className : ""}`}
        style={style}
      >
        <path
          d="M1 1l6 6 6-6"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={strokeWidth || "2"}
          fill="none"
          fillRule="evenodd"
        />
      </svg>
    );
  }
}

export default IconAngleDown;
