import React, { Component } from "react";
import { connect } from "react-redux";
import Resources from "../lib/resources";
import MainContentHeader from "../components/main_content_header/mainContentHeader";
import RadioInput from "./library/radioInput";
import { dispatchToProps as uaDP } from "../store/user-actions";
import { dispatchToProps as gaDP } from "../store/general-actions";
import { isEmpty } from "../lib/utils";
const dispatchToProps = dispatch => {
  return {
    ...uaDP(dispatch),
    ...gaDP(dispatch)
  };
};
class NotificationSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notificationFrequency: "daily",
      readOnly: true
    };
    this.handleEditOrSave = this.handleEditOrSave.bind(this);
  }
  componentDidMount() {
    if (!isEmpty(this.props.userStore.userConfig)) {
      this.setState({ notificationFrequency: this.props.userStore.userConfig.notificationFrequency || "daily" });
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.userStore.userConfig !== prevProps.userStore.userConfig) {
      this.setState({
        notificationFrequency: this.props.userStore.userConfig.notificationFrequency || "daily"
      });
    }
  }
  handleEditOrSave() {
    if (this.state.readOnly) {
      this.setState({ readOnly: false });
      return;
    } else {
      this.setState({ readOnly: true });
      this.props
        .updateUserConfig({ notificationFrequency: this.state.notificationFrequency })
        .then(() => {
          this.props.showToast({
            timeout: 5000,
            text: Resources.NotificationPreferencesSaved,
            linkHidden: true
          });
        })
        .catch(err => {
          return;
        });
    }
  }
  render() {
    const notificationFrequencyRadios = [
      { label: Resources.DailyWeekdays, value: "daily" },
      { label: Resources.WeeklyMondays, value: "weekly" },
      { label: Resources.Never, value: "never" }
    ];
    return (
      <React.Fragment>
        <MainContentHeader hideSearch={true} title={Resources.Notifications} />
        <div className="manage-content">
          <div>
            <label>{Resources.NotificationSettingsSubtext}</label>
            <RadioInput
              inputClassName="w-45"
              disabled={this.state.readOnly}
              radioName="workspaces"
              inputs={notificationFrequencyRadios}
              value={this.state.notificationFrequency}
              onChange={item => this.setState({ notificationFrequency: item.value })}
            />
            <div className="flex-row mt-4">
              <button className="btn button-green" onClick={this.handleEditOrSave}>
                {this.state.readOnly ? Resources.Edit : Resources.Save}
              </button>
              {this.state.readOnly === false && (
                <button
                  className="button-cancel ml-1"
                  onClick={() =>
                    this.setState({
                      notificationFrequency: (this.props.userStore.userConfig || {}).notificationFrequency || "daily",
                      readOnly: true
                    })
                  }
                >
                  {Resources.Cancel}
                </button>
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
const storeToProps = store => {
  return {
    userStore: store.user
  };
};
export default connect(storeToProps, dispatchToProps)(NotificationSettings);
