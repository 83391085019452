import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { dispatchToProps as searchDP } from "../../store/search-actions";

import Resources from "../../lib/resources";
import { isEmpty } from "../../lib/utils";

import AutoCompleteInput from "../library/autoCompleteInput";
import { generatePath } from "react-router-dom";
import Avatar from "../library/avatar";
import IconSearch from "../library/icons/iconSearch";

const dispatchToProps = dispatch => {
  return {
    ...dispatch(searchDP)
  };
};

class SearchBar extends Component {
  triggerSearch(term) {
    const { selectedAccountId, match } = this.props;

    this.props.fetchAccountingGroupSearchResults(
      selectedAccountId,
      match.params.companyId,
      match.params.perspectiveId,
      {
        request: term
      }
    );
    this.props.pushSearchHistoryItem(term);
    return this.props.history.push(generatePath("/v2/:companyId/:perspectiveId/search", match.params));
  }

  render() {
    const {
      searchTerm,
      gotSearchResults,
      gettingSearchResults,
      selectedAccountId,
      match,
      searchHistory,
      searchResultValues,
      hideDropdownMenu,
      disableDebouncedSearch,
      selectedAccountingGroupType
    } = this.props;

    let searchResults;
    if (selectedAccountingGroupType === "vendors") {
      searchResults = [...(searchResultValues.vendors || []), ...(searchResultValues.vendorContacts || [])];
    } else {
      searchResults = [...(searchResultValues.customers || []), ...(searchResultValues.customerContacts || [])];
    }

    const renderSearchHistory = isEmpty(searchTerm) && isEmpty(searchResults);

    return (
      <AutoCompleteInput
        className="search-bar"
        placeholder={Resources.Search}
        onChange={e => {
          const searchTerm = e.target.value;
          this.props.updateSearchTerm(searchTerm);
          !disableDebouncedSearch &&
            this.props.debouncedFetchAccountingGroupSearchResults(
              selectedAccountId,
              match.params.companyId,
              match.params.perspectiveId,
              { request: searchTerm }
            );
        }}
        onSearch={() => {
          this.triggerSearch(searchTerm);
        }}
        isShowingSearchResult={!isEmpty(searchTerm)}
        handleClearResult={() => {
          this.props.clearSearchResults();
        }}
        text={searchTerm}
        noResultsMessage={Resources.NoProfileSearchResultsMessage}
        showNoResultsMessage={gotSearchResults && isEmpty(searchResults)}
        maxOptions={7}
        loading={gettingSearchResults}
        handleSelectOption={option => {
          if (renderSearchHistory) {
            this.props.updateSearchTerm(option);
            return this.triggerSearch(option);
          }

          let isAccount = option.contactKey === undefined;
          if (isAccount) {
            this.props.history.push(
              generatePath("/v2/:companyId/:perspectiveId/network/accounts/:accountId", {
                ...match.params,
                accountId: option.entityGlobalKey
              })
            );
          } else {
            this.props.history.push(
              generatePath("/v2/:companyId/:perspectiveId/network/contacts/:contactId", {
                ...match.params,
                contactId: option.entityGlobalKey
              })
            );
          }

          this.props.pushSearchHistoryItem(searchTerm);
        }}
        callToActionButton={
          renderSearchHistory ? null : (
            <div
              className="dropdown-item-clean dropdown-call-to-action"
              onClick={() => {
                this.props.history.push(
                  generatePath("/v2/:companyId/:perspectiveId/search", {
                    ...match.params
                  })
                );
                this.props.pushSearchHistoryItem(searchTerm);
              }}
            >
              {Resources.SeeAllResultsFor(searchTerm)}
            </div>
          )
        }
        renderOption={option => {
          if (renderSearchHistory) {
            return (
              <div className="search-item search-bar__item--history">
                <span>
                  <IconSearch small height="0.93rem" />
                </span>
                <div>{option}</div>
              </div>
            );
          }
          return (
            <div className="search-item">
              <Avatar
                height={"1.33rem"}
                imgURL={option.profileImageURL || option.logoURL}
                type={option.contactKey === undefined ? "account" : "contact"}
              ></Avatar>
              <div>{option.contactName || option.displayName || option.custName || option.vendorName}</div>
            </div>
          );
        }}
        options={renderSearchHistory ? searchHistory || [] : searchResults || []}
        dropdownHeader={
          renderSearchHistory ? (
            <div className="search-item search-bar__dropdown__history-header">{Resources.RecentSearches}</div>
          ) : null
        }
        width="25rem"
        hideDropdownMenu={hideDropdownMenu}
      />
    );
  }
}

const storeToProps = store => {
  return {
    searchTerm: store.search.searchTerm,
    searchHistory: store.search.searchHistory,
    isShowingSearchResults: store.search.isShowingSearchResults,
    gettingSearchResults: store.search.gettingSearchResults,
    gotSearchResults: store.search.gotSearchResults,
    selectedAccountId: store.accounts.selectedAccountId,
    searchResultValues: store.search.values,
    selectedAccountingGroupType: store.perspectives.selectedPerspectiveType
  };
};

export default withRouter(connect(storeToProps, dispatchToProps)(SearchBar));
