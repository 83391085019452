import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import Dropzone from "react-dropzone";
import ReactQuill from "./library/lockstepQuill";
import MainContentHeader from "../components/main_content_header/mainContentHeader";

import "react-quill/dist/quill.snow.css";
import "../css/quill.scss";
import "react-datepicker/dist/react-datepicker.css";

import Resources from "../lib/resources";
import Icons from "../lib/icons";

import { dispatchToProps } from "../store/user-actions";
import { isEmpty } from "../lib/utils";
import { raiseError } from "../store/error-actions";

class ManageSignature extends Component {
  constructor(props) {
    super(props);
    this.quillRef = null;
    this.reactQuillRef = null;
    this.state = { ...this.defaultState };

    this.handleEditOrSave = this.handleEditOrSave.bind(this);
    // this.postEditorContents = this.postEditorContents.bind(this);
    this.uploadSignatureImages = this.uploadSignatureImages.bind(this);
  }

  defaultState = {
    dropEnabled: false,
    value: "",
    editorSelection: { index: 0 },
    quillConfig: {
      readOnly: true,
      theme: "snow",
      modules: {
        toolbar: [
          [{ header: [1, 2, 3, 4, false] }],
          ["bold", "italic", "underline"],
          ["strike", "blockquote"],
          [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
          ["link"]
        ],
        keyboard: {
          bindings: {
            tab: {
              key: 9,
              handler: () => {
                return true;
              }
            }
          }
        }
      }
    },
    savingAsTemplate: false
  };

  attachQuillRefs = () => {
    // We need to update state to hook into componentDidUpdate
    if (this.state.quillRefAttached !== true) {
      this.setState({ quillRefAttached: true });
    }

    if (typeof (this.reactQuillRef || {}).getEditor !== "function") return;
    this.quillRef = this.reactQuillRef.getEditor();
  };

  componentDidUpdate() {
    const { userStore } = this.props;
    this.attachQuillRefs();

    const shouldTryUpdate =
      userStore.isUpdatingCompanyUserSignature === false &&
      userStore.updatedCompanyUserSignature === false &&
      userStore.updatingCompanyUserSignatureFailed === false;

    if (isEmpty(this.state.companySignatureId)) {
      const signature = this.props.getCompanyUserSignature(
        this.props.getMyUserIdFromToken(),
        this.props.match.params.companyId
      );

      if (signature !== null) {
        if (!isEmpty(signature)) {
          this.setState({
            companySignatureId: signature.companySignatureId,
            existingSignature: signature.signatureHtml
          });
          this.reactQuillRef.setEditorContents(this.quillRef, signature.signatureHtml);
        } else if (shouldTryUpdate) {
          this.props
            .updateCompanyUserSignature(this.props.match.params.companyId, {
              companySignatureId: this.state.companySignatureId || null,
              description: "",
              signatureTitle: "",
              signatureText: "",
              signatureHtml: ""
            })
            .then(companySignature => {
              this.setState({ companySignatureId: companySignature[0].companySignatureId });
            });
        }
      }
    }
  }

  getText() {
    let uEditor = this.reactQuillRef.makeUnprivilegedEditor(this.quillRef);
    return uEditor.getText();
  }

  handleEditOrSave() {
    let nextQuillConfig = { ...this.state.quillConfig };
    if (this.state.quillConfig.readOnly) {
      nextQuillConfig.readOnly = false;
      this.setState({ quillConfig: nextQuillConfig });
      return;
    } else {
      nextQuillConfig.readOnly = true;
      this.setState({ quillConfig: nextQuillConfig });

      return this.props
        .updateCompanyUserSignature(this.props.match.params.companyId, {
          companySignatureId: this.state.companySignatureId || null,
          description: "",
          signatureTitle: this.state.signatureTitle || this.getText(),
          signatureText: this.getText(),
          signatureHtml: this.state.value
        })
        .then(sigantures => {
          this.setState({ existingSignature: sigantures[0].signatureHtml });
          this.props.showToast({
            timeout: 5000,
            text: Resources.NotificationPreferencesSaved,
            linkHidden: true
          });
        })
        .catch(err => {
          return;
        });
    }
  }

  uploadSignatureImages(images) {
    let companySignatureId;
    if (!isEmpty(this.state.companySignatureId)) {
      companySignatureId = Promise.resolve(this.state.companySignatureId);
    } else {
      companySignatureId = this.props
        .updateCompanyUserSignature(this.props.match.params.companyId, {
          companySignatureId: this.state.companySignatureId || null,
          description: "",
          signatureTitle: "",
          signatureText: "",
          signatureHtml: ""
        })
        .then(companySignature => {
          return Promise.resolve(companySignature.companySignatureId);
        });
    }

    companySignatureId
      .then(companySignatureId => {
        return this.props.updateSignatureAttachments(this.props.match.params.companyId, companySignatureId, images);
      })
      .then(attachedImages => {
        const { index } = this.state.editorSelection;

        attachedImages.reverse().forEach(image => {
          this.quillRef.insertEmbed(index, "image", image.attachmentURI);
        });
      })
      .catch(err => console.log(err));

    this.setState({ dropEnabled: false });
  }

  render() {
    return (
      <React.Fragment>
        <MainContentHeader hideSearch={true} title={Resources.Signature} />
        <div className="manage-content">
          <div className="manage-signatures__helper-text">{Resources.SignaturesExplanation}</div>
          <div className="quill-container ml-0">
            <div className={this.state.quillConfig.readOnly ? "read-only-quill" : ""} />
            <div>
              <Dropzone
                accept={["image/png", "image/jpeg", "image/gif"]}
                noClick={true}
                onDragEnter={e => {
                  this.setState({ dropEnabled: true });
                }}
                onDragLeave={e => {
                  this.setState({ dropEnabled: false });
                }}
                onDrop={(acceptedFiles, rejectedFiles) => {
                  this.uploadSignatureImages([...acceptedFiles]);
                  if (!isEmpty(rejectedFiles)) {
                    raiseError(
                      Resources.ImageOnlyUploadWarning,
                      <ul>
                        {rejectedFiles.map(f => {
                          return <li>{f.name}</li>;
                        })}
                      </ul>
                    );
                  }
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()} className="quill-dropzone-container">
                    <ReactQuill
                      ref={el => {
                        this.reactQuillRef = el;
                      }}
                      {...this.state.quillConfig}
                      value={this.state.value}
                      onBlur={range => {
                        this.setState({ editorSelection: { ...range } });
                      }}
                      onChange={value => {
                        this.setState({ value });
                      }}
                    />
                    {this.state.dropEnabled && (
                      <div className="quill-dropzone-overlay">
                        <h4>{Resources.DropImageFilesHere}</h4>
                      </div>
                    )}
                  </div>
                )}
              </Dropzone>
              <div className="ql-toolbar ql-snow p-0" style={{ borderTopStyle: "none" }}>
                <div className="flex-split quill-actions">
                  <div
                    className="editor-action"
                    onClick={() => {
                      if (this.state.quillConfig.readOnly !== true) {
                        window.document.getElementById("uploadFiles").click();
                      }
                    }}
                  >
                    <span className={Icons.attachment} />
                    <span className="editor-action-label">{Resources.InsertImage}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex-row mt-4">
            <button className="btn button-green" onClick={this.handleEditOrSave}>
              {this.state.quillConfig.readOnly ? Resources.Edit : Resources.Save}
            </button>
            {this.state.quillConfig.readOnly === false && (
              <button
                className="button-cancel ml-1"
                onClick={() => {
                  this.reactQuillRef.setEditorContents(this.quillRef, this.state.existingSignature);
                  this.setState({
                    quillConfig: { ...this.state.quillConfig, readOnly: true }
                  });
                }}
              >
                {Resources.Cancel}
              </button>
            )}
          </div>
        </div>
        <input
          className="hidden-input"
          type="file"
          id="uploadFiles"
          multiple
          style={{ visibility: "hidden" }}
          accept=".png,.jpg,.gif"
          onChange={e => {
            const files = [...e.target.files];
            this.uploadSignatureImages(files);
            e.target.value = "";
          }}
        />
      </React.Fragment>
    );
  }
}

const storeToProps = store => {
  return {
    userStore: store.user
  };
};

export default withRouter(connect(storeToProps, dispatchToProps)(ManageSignature));
