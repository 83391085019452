import React, { Component } from "react";
import { connect } from "react-redux";

import { dispatchToProps } from "../../store/modal-actions";
import Resources from "../../lib/resources";

import IconCheck from "../library/icons/iconCheck";
import IconAlertCircle from "../library/icons/iconAlertCircle";
import IconSpinner from "../library/icons/iconSpinner";

class CreatingDisputeNotification extends Component {
  constructor(props) {
    super(props);

    this.state = {
      address: "",
      isValidAddress: false
    };
  }

  componentDidUpdate(prevProps) {
    if (
      (this.props.createdDispute === true && prevProps.createdDispute === false) ||
      (this.props.createDisputeFailed === true && prevProps.createDisputeFailed === false)
    ) {
      setTimeout(this.props.hideNotification, 5000);
    }
  }

  render() {
    let { creatingDispute, createdDispute, createDisputeFailed } = this.props;

    let content;
    if (creatingDispute) {
      content = (
        <React.Fragment>
          <IconSpinner height={20} className="notification-icon-left" />
          <div>{Resources.SubmittingDispute}</div>
        </React.Fragment>
      );
    }
    if (createdDispute) {
      content = (
        <React.Fragment>
          <IconCheck height={20} className="notification-icon-left"></IconCheck>
          <div>{Resources.DisputeSubmitted}</div>
        </React.Fragment>
      );
    }
    if (createDisputeFailed) {
      content = (
        <React.Fragment>
          <IconAlertCircle height={20} className="notification-icon-left"></IconAlertCircle>
          <div>{Resources.FailedSubmittingDispute}</div>
        </React.Fragment>
      );
    }
    return <div className="notification">{content}</div>;
  }
}

const storeToProps = store => {
  return {
    creatingDispute: store.ledger.creatingEntry,
    createdDispute: store.ledger.createdEntry,
    createDisputeFailed: store.ledger.createEntryFailed
  };
};

export default connect(
  storeToProps,
  dispatchToProps
)(CreatingDisputeNotification);
