import React, { Component } from "react";
import { Route } from "react-router-dom";

import ReportsApprovers from "./reportsApprovers";
import ReportsTeam from "./reportsTeam";
import ReportsTasks from "./reportsTasks";
import ReportsApprovalAging from "./reportsApprovalAging";

export default class ReportsPage extends Component {
  render() {
    return (
      <React.Fragment>
        <Route path="/company/:companyId/reports/team" component={ReportsTeam} />
        <Route path="/company/:companyId/reports/tasks" component={ReportsTasks} />
        <Route path="/company/:companyId/reports/approvers" component={ReportsApprovers} />
        <Route path="/company/:companyId/reports/approval-aging" component={ReportsApprovalAging} />
      </React.Fragment>
    );
  }
}
