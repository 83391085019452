import React, { Component } from "react";

class IconHelp extends Component {
  render() {
    let { className, height } = this.props;

    return (
      <svg viewBox="0 0 22 22" height={height} className={`icon ${className ? className : ""}`}>
        <g fill="none" fillRule="evenodd">
          <g transform="translate(1 1)">
            <circle
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              cx="10"
              cy="10"
              r="10"
            />
            <path
              d="M7.09 7c.48543688-1.37995556 1.89538362-2.20859368 3.3371763-1.9612879C11.868969 5.28601788 12.9221794 6.53715293 12.9200034 8c0 2-3.0000034 3-3.0000034 3"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path fill="currentColor" d="M9 13h2v2H9z" />
          </g>
        </g>
      </svg>
    );
  }
}

IconHelp.defaultProps = {
  height: 22
};

export default IconHelp;
