import React, { Component } from "react";
import { isEmpty } from "../../lib/utils";
import Resources from "../../lib/resources";

import TableData from "./tableData";
import Icons from "../../lib/icons";

export default class CRUDList extends Component {
  constructor(props) {
    super(props);

    this.renderAction = this.renderAction.bind(this);
  }

  renderAction(action, row) {
    if (isEmpty(action)) {
      return null;
    }
    let buttonText;
    let buttonClass;

    if (!isEmpty(action.condition) && action.condition(row) === false) {
      return null;
    }

    switch (action.type.toLowerCase()) {
      case "update":
        if (isEmpty(action.onClick)) {
          return null;
        }
        buttonText = action.text || Resources.Edit;
        buttonClass = "crud-list-actions-update";
        break;
      case "delete":
        if (isEmpty(action.onClick)) {
          return null;
        }
        buttonText = action.text || Resources.Delete;
        buttonClass = "crud-list-actions-delete";
        break;
      case "other":
        if (isEmpty(action.text) || isEmpty(action.onClick)) {
          return null;
        }
        buttonText = action.text;
        buttonClass = "crud-list-actions-other";
        break;
      case "conditional":
        if (isEmpty(action.conditional)) {
          return null;
        }
        let conditionalReturn = action.conditional(row);
        return this.renderAction(conditionalReturn, row);
      case "custom":
        return action.content(row);
      default:
        return null;
    }

    return (
      <button className={buttonClass} onClick={() => action.onClick(row)}>
        {buttonText}
      </button>
    );
  }
  render() {
    let { columns, data, emptyRenderText, onCreate, title, description, createText, loading, noHeader } = this.props;

    let newColumns = columns.map(column => {
      let newColumn = { ...column };
      switch (column.type) {
        case "actions":
          newColumn.content = row => {
            return (
              <div className="crud-list-actions">
                {column.actions.map((action, i) => (
                  <span key={i}>{this.renderAction(action, row)}</span>
                ))}
              </div>
            );
          };
          newColumn.header = <div className="crud-list-actions-header">{column.header}</div>;
          return newColumn;
        default:
          return newColumn;
      }
    });
    return (
      <React.Fragment>
        <div className="crud-list-head">
          <div className="crud-list-headings">
            <h2 className="manage-card-title">{title}</h2>
            <h3 className="crud-list-description">{description}</h3>
          </div>
          {!isEmpty(onCreate) ? (
            <button className="crud-list-add-new" onClick={onCreate}>
              <span className={Icons.plus + " pr-2"} />
              <span>{createText || Resources.AddNew}</span>
            </button>
          ) : null}
        </div>
        <TableData
          columns={newColumns}
          data={data}
          emptyRender={
            <div className="flex-center">
              <h4 className="my-5">{emptyRenderText || Resources.NothingToShowHere}</h4>
            </div>
          }
          rowClassName="crud-list-row"
          noGrow
          loading={loading}
          noHeader={noHeader}
        />
      </React.Fragment>
    );
  }
}
