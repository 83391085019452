import React, { Component } from "react";
import { withRouter, Switch, Route, Redirect, generatePath } from "react-router-dom";
import { connect } from "react-redux";

import { formatDate } from "../../../lib/utils";
import Resources from "../../../lib/resources";
import Card from "../../library/card";
import TableData from "../../library/tableData";

import ViewContentHeader from "./viewContentHeader";

import { dispatchToProps as netwDP } from "../../../store/network-actions";
// import IconXCircle from "../../library/icons/iconXCircle";
// import IconHand from "../../library/icons/iconHand";

const dispatchToProps = dispatch => ({
  ...netwDP(dispatch)
});

class OpenRequestsView extends Component {
  componentDidMount() {
    let { companyId, perspectiveId } = this.props.match.params;
    this.props.getOpenOnboardingRequests(companyId, perspectiveId);
    this.props.getOpenProfileUpdateRequests(perspectiveId);
  }

  renderOpenRequestsTable(dataView) {
    const {
      gettingOpenOnboardingRequests,
      openOnboardingRequests = [],
      openOnboardingRequestsCount,
      openProfileUpdateRequests = [],
      openProfileUpdateRequestsCount,
      gettingOpenProfileUpdateRequests
    } = this.props;
    const { perspectiveId } = this.props.match.params;

    let columns = [
      // {
      //   type: "rowSelect",
      //   width: "9%"
      // },
      {
        header: Resources.To.toLocaleUpperCase(),
        content: row => row.recipient,
        width: "25%"
      },
      {
        header: Resources.InitialRequest.toLocaleUpperCase(),
        content: row => formatDate(row.requestDate || {}),
        width: "15%"
      },
      {
        header: Resources.LatestReminder.toLocaleUpperCase(),
        content: row => formatDate(row.latestReminderDate || {}),
        width: "15%"
      },
      {
        header: Resources.NextReminder.toLocaleUpperCase(),
        content: row => formatDate(row.nextReminderDate),
        width: "15%"
      }
      // {
      //   header: Resources.Status.toLocaleUpperCase(),
      //   content: row => {
      //     return (
      //       <div className="status-indicator-container">
      //         <span>{row.status}</span>
      //         <div className={"status-indicator-circle__" + row.status}></div>
      //       </div>
      //     );
      //   },
      //   width: "10%"
      // }
    ];

    let hoverColumns = [
      // {
      //   type: "rowSelect",
      //   width: "9%"
      // },
      {
        header: Resources.To.toLocaleUpperCase(),
        content: row => row.recipient,
        width: "25%"
      },
      {
        header: Resources.InitialRequest.toLocaleUpperCase(),
        content: row => formatDate(row.requestDate || {}),

        width: "15%"
      },
      {
        header: Resources.LatestReminder.toLocaleUpperCase(),
        content: row => formatDate(row.latestReminderDate || {}),

        width: "15%"
      },
      {
        header: Resources.NextReminder.toLocaleUpperCase(),
        content: row => formatDate(row.nextReminderDate),
        width: "15%"
      }
      // {
      //   header: Resources.Status.toLocaleUpperCase(),
      //   content: row => {
      //     return (
      //       <div className="network-table-hover-icon-container__disabled">
      //         <button disabled>
      //           <IconXCircle />
      //         </button>
      //         <button disabled>
      //           <IconHand />
      //         </button>
      //       </div>
      //     );
      //   },
      //   width: "10%"
      // }
    ];

    return (
      <Card className="card-table">
        {/* <div className="table-data-card-header">
          <div className="d-flex align-items-center">
            <div className="table-data-card-header-search">
              <AutoCompleteInput
                disabled
                className="auto-complete-input__table-data-search"
                placeholder={Resources.Search}
                onChange={e => {
                  const searchTerm = e.target.value;
                  return searchTerm;
                }}
                isShowingSearchResult={false}
                handleClearResult={() => {}}
                text={null}
                noResultsMessage={Resources.NoStatementSearchResultsMessage}
                showNoResultsMessage={false}
                maxOptions={7}
                loading={false}
                handleSelectOption={option => {}}
                callToActionButton={
                  <div className="dropdown-item-clean dropdown-call-to-action" onClick={() => {}}>
                    {Resources.SeeAllInvoiceResults("a")}
                  </div>
                }
                renderOption={option => `${Resources.InvoiceNumber} ${option.id}`}
                options={[]}
                width="300px"
              />
            </div>
            <div className="dropdown">
              <button
                className="button-action-icon dropdown-toggle customer-portal-button-filter"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                style={{ marginTop: "2rem" }}
              >
                <IconFilter height="22"></IconFilter>
              </button>
              <div className="dropdown-menu"></div>
            </div>
          </div>
        </div> */}
        <TableData
          name="open-requests__table"
          pagination
          hoverColumns={hoverColumns}
          data={dataView === "onboarding" ? openOnboardingRequests : openProfileUpdateRequests}
          loading={dataView === "onboarding" ? gettingOpenOnboardingRequests : gettingOpenProfileUpdateRequests}
          maxRows={dataView === "onboarding" ? openOnboardingRequestsCount : openProfileUpdateRequestsCount}
          columns={columns}
          onLoadMore={page => {
            if (dataView === "onboarding") {
              this.props.selectOpenOnboardingRequestsPage(perspectiveId, page);
            } else {
              this.props.selectOpenProfileUpdateRequestsPage(perspectiveId, page);
            }
          }}
          style={{ maxHeight: "40vh" }}
          rowHeight="5rem"
          onRowSelectToggle={() => {}}
        />
      </Card>
    );
  }

  render() {
    const { openOnboardingRequestsCount, openProfileUpdateRequestsCount } = this.props;

    let subViews = [
      {
        subView: "onboarding",
        display: Resources.OnboardingCount(openOnboardingRequestsCount || 0).toLocaleUpperCase(),
        path: "/v2/:companyId/:perspectiveId/home/open-requests/onboarding"
      },
      {
        subView: "profile-update",
        display: Resources.ProfileCount(openProfileUpdateRequestsCount || 0).toLocaleUpperCase(),
        path: "/v2/:companyId/:perspectiveId/home/open-requests/profile-update"
      }
    ];
    return (
      <React.Fragment>
        <Switch>
          <Route path="/v2/:companyId/:perspectiveId/home/open-requests/:subView(onboarding)">
            <ViewContentHeader links={subViews} numberOpenRequests={openOnboardingRequestsCount} />
            <div className="view-content">{this.renderOpenRequestsTable("onboarding")}</div>
          </Route>
          <Route path="/v2/:companyId/:perspectiveId/home/open-requests/:subView(profile-update)">
            <ViewContentHeader links={subViews} numberOpenRequests={openProfileUpdateRequestsCount} />
            <div className="view-content">{this.renderOpenRequestsTable("profile-update")}</div>
          </Route>
          <Redirect
            to={generatePath("/v2/:companyId/:perspectiveId/home/open-requests/onboarding", this.props.match.params)}
          />
        </Switch>
      </React.Fragment>
    );
  }
}

const storeToProps = store => {
  return {
    openOnboardingRequests: store.network.openOnboardingRequests.value,
    openOnboardingRequestsCount: store.network.openOnboardingRequests.count,
    gettingOpenOnboardingRequests: store.network.gettingOpenOnboardingRequests,
    openProfileUpdateRequests: store.network.openProfileUpdateRequests.value,
    openProfileUpdateRequestsCount: store.network.openProfileUpdateRequests.count,
    gettingOpenProfileUpdateRequests: store.network.gettingOpenProfileUpdateRequests
  };
};

export default withRouter(connect(storeToProps, dispatchToProps)(OpenRequestsView));
