import React, { Component } from "react";
import moment from "moment";
import { connect } from "react-redux";

import { dispatchToProps as modDP } from "../../store/modal-actions";
import { dispatchToProps as conDP } from "../../store/conversations-actions";

import Resources from "../../lib/resources";
import IconAlert from "../library/icons/iconAlert";
import IconAttachment from "../library/icons/iconAttachment";

import TableData from "../library/tableData";
import TableDataSortableHeader from "../library/tableDataSortableHeader";

import { isEmpty, formatCurrency, find, formatDate, getCurrencySymbol } from "../../lib/utils";

const dispatchToProps = dispatch => {
  return {
    ...dispatch(modDP),
    ...dispatch(conDP)
  };
};
class InvoiceTable extends Component {
  displayRelatedConversation(entry) {
    let { companyId, perspectiveId, withCompanyId, withContextGroupId } = this.props;
    let { ledgerHash } = entry;

    this.props.displayFlyout("messageFlyout", {
      loading: true
    });

    this.props.fetchRelated(ledgerHash).then(() => {
      let relatedConversations = this.props.getRelated(ledgerHash);

      if (isEmpty(relatedConversations)) {
        return;
      }

      let conversation = relatedConversations.value[0];
      let conversationId = conversation.conversationId;

      this.props.displayFlyout("messageFlyout", {
        conversationId,
        companyId,
        perspectiveId,
        withCompanyId,
        withContextGroupId
      });
    });
  }

  getDefaultInvoiceColumns(hover) {
    const { statementsStore } = this.props;

    const invoiceDateCol = {
      header: noSort =>
        noSort ? (
          Resources.InvoiceDate.toLocaleUpperCase()
        ) : (
          <TableDataSortableHeader
            sortBy={statementsStore.openInvoicesSortBy}
            sortDirection={statementsStore.openInvoicesSortDirection}
            updateSort={(sortBy, sortDirection) => {
              this.onSortChange();
              this.props.updateOpenInvoicesSort(sortBy, sortDirection);
            }}
            text={Resources.InvoiceDate.toLocaleUpperCase()}
            sortKey={"IssueDate"}
          />
        ),
      content: row => <span className="fw-500">{formatDate(row.issueDate, true, false)}</span>,
      width: "20%"
    };
    const invoiceNumCol = {
      header: noSort =>
        noSort ? (
          Resources.InvoiceNumber.toLocaleUpperCase()
        ) : (
          <TableDataSortableHeader
            sortBy={statementsStore.openInvoicesSortBy}
            sortDirection={statementsStore.openInvoicesSortDirection}
            updateSort={(sortBy, sortDirection) => {
              this.onSortChange();
              this.props.updateOpenInvoicesSort(sortBy, sortDirection);
            }}
            text={Resources.InvoiceNumber.toLocaleUpperCase()}
          />
        ),
      content: row => {
        let hasAttachment = !isEmpty(row.attachments);

        return (
          <div className={`statements-invoice-id d-flex align-items-center ${hasAttachment ? "link" : ""}`}>
            <div className="flex-even">
              {row.id}
              {hasAttachment && <IconAttachment height={15} className="ml-2" />}
            </div>
            <div className="d-flex flex-column justify-content-start flex-even">
              {row.isDisputed && (
                <div>
                  <button
                    className="button-indicator"
                    onClick={() => this.displayRelatedConversation(row.activeDispute)}
                    style={{ marginBottom: row.hasPromissoryNote ? "0.4rem" : "" }}
                  >
                    {Resources.Disputed}
                  </button>
                </div>
              )}
              {row.hasPromissoryNote && (
                <div>
                  <button
                    className="button-indicator"
                    onClick={() =>
                      this.displayRelatedConversation(
                        find(row.payments, payment => payment.paymentType === "Promise to Pay")
                      )
                    }
                  >
                    {Resources.Promised}
                  </button>
                </div>
              )}
            </div>
          </div>
        );
      },
      width: "25%"
    };

    const amountDueCol = {
      header: noSort =>
        noSort ? (
          Resources.AmountDue.toLocaleUpperCase()
        ) : (
          <TableDataSortableHeader
            sortBy={statementsStore.openInvoicesSortBy}
            sortDirection={statementsStore.openInvoicesSortDirection}
            updateSort={(sortBy, sortDirection) => {
              this.onSortChange();
              this.props.updateOpenInvoicesSort(sortBy, sortDirection);
            }}
            text={Resources.AmountDue.toLocaleUpperCase()}
            sortKey={"Amount"}
          />
        ),
      content: row => (
        <div className="statements-currency-row">
          <span className="mr-2">{getCurrencySymbol(statementsStore.selectedCurrency)}</span>
          <span>{formatCurrency(row.amount, "")}</span>
        </div>
      ),
      width: "9rem"
    };

    const fillerCol = { width: "8%", content: row => null };

    const dueDateCol = {
      header: noSort =>
        noSort ? (
          Resources.Due_Date.toLocaleUpperCase()
        ) : (
          <TableDataSortableHeader
            sortBy={statementsStore.openInvoicesSortBy}
            sortDirection={statementsStore.openInvoicesSortDirection}
            updateSort={(sortBy, sortDirection) => {
              this.onSortChange();
              this.props.updateOpenInvoicesSort(sortBy, sortDirection);
            }}
            text={Resources.Due_Date.toLocaleUpperCase()}
            sortKey={"DueDate"}
          />
        ),
      content: row => <span>{row.transactionType === "CM" ? Resources.NA : formatDate(row.dueDate, true, false)}</span>,
      width: "18%"
    };

    const statusCol = {
      header: Resources.Status.toLocaleUpperCase(),
      content: row => {
        let isPastDue = moment(row.dueDate).isBefore();
        return (
          <React.Fragment>
            <span>{isPastDue ? Resources.PastDue : row.status}</span>
            {isPastDue ? <IconAlert className="pl-2" height={15} /> : ""}
          </React.Fragment>
        );
      }
    };

    const columns = [invoiceDateCol, invoiceNumCol, amountDueCol, fillerCol, dueDateCol, statusCol];

    return columns;
  }

  render() {
    let columns = this.getDefaultInvoiceColumns();
    return (
      <TableData
        noSort
        noGrow
        pagination
        data={this.props.data}
        columns={columns}
        rowHeight="4em"
        maxRows={this.props.data.length}
        loading={isEmpty(this.props.data)}
        rowClassName="statements-view-row no-hover"
        emptyRender={
          <div className="flex-center">
            <h4 className="mt-5">{Resources.EmptyStatements}</h4>
          </div>
        }
      />
    );
  }
}

const storeToProps = store => {
  return {
    statementsStore: store.statements
  };
};

export default connect(storeToProps, dispatchToProps)(InvoiceTable);
