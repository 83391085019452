import React, { Component } from "react";
import { connect } from "react-redux";

import ModalDialog from "./modalDialog";

import Resources from "../lib/resources";
import Icons from "../lib/icons";

import { getPartnerType } from "../lib/perspectives";
import { isEmailValid, isEmpty } from "../lib/utils";

import { dispatchToProps as invDP } from "../store/invite-actions";
import { dispatchToProps as errDP } from "../store/error-actions";
import { dispatchToProps as paDP } from "../store/perspectives-actions";

class InviteCompanyUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fullName: "",
      emailAddress: "",
      customMessage: Resources.InviteToCompanyMessage,
      defaultPerspective: this.props.perspective
    };

    this.onClosed = this.onClosed.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.validateAndInvite = this.validateAndInvite.bind(this);
  }

  onClosing(closing) {
    this.props.resetInviteCompanyUser();
    if (closing) {
      closing();
    }
  }

  onClosed() {
    let onClosed = this.props.onClosed;
    this.onClosing(onClosed);
  }

  onCancel() {
    let onCancel = this.props.onCancel;
    this.onClosing(onCancel);
  }

  componentDidMount() {
    this.props.resetInviteCompanyUser();
  }

  componentDidUpdate() {
    if (this.props.invite.invitedUser === true) {
      this.onClosed();
    }
  }

  validateAndInvite() {
    if (isEmpty(this.state.fullName.trim())) {
      this.props.raiseError(Resources.ErrorFullNameRequired);
    } else if (isEmailValid(this.state.emailAddress) === false) {
      this.props.raiseError(Resources.ErrorEmailFormat);
    } else if (this.props.existingEmails[this.state.emailAddress.toLocaleLowerCase()] === true) {
      this.props.raiseError(Resources.ErrorExistingUserInviteEmail);
    } else {
      this.props.inviteCompanyUser(
        this.props.companyId,
        this.state.fullName,
        this.state.emailAddress,
        this.state.defaultPerspective.perspectiveId,
        this.state.customMessage
      );
    }
  }

  render() {
    let labelClass = "w-25";
    let inputClass = "form-control pr-2 pl-2 plain thin w-75";
    let { invitingUser } = this.props.invite;

    const companyPerspectives = this.props.getCompanyPerspectives(this.props.companyId);

    return (
      <ModalDialog
        isOpen={true}
        toggle={this.props.toggle}
        size="md"
        title={Resources.InviteUser}
        content={
          <div>
            <div>
              <div className="mb-2 flex-align-start">
                <span className={labelClass}>{Resources.Name}</span>
                <input
                  className={inputClass}
                  type="text"
                  value={this.state.fullName}
                  disabled={invitingUser}
                  onChange={e => {
                    this.setState({ fullName: e.target.value });
                  }}
                />
              </div>
              <div className="mb-2 flex-align-start">
                <span className={labelClass}>{Resources.EmailAddress}</span>
                <input
                  className={inputClass}
                  type="text"
                  value={this.state.emailAddress}
                  disabled={invitingUser}
                  onChange={e => {
                    this.setState({ emailAddress: e.target.value });
                  }}
                />
              </div>
              <div className="mb-2 flex-align-start">
                <span className={labelClass}>{Resources.Perspective}</span>
                <span className={`dropdown ${""}`}>
                  <span role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    {getPartnerType(this.state.defaultPerspective.perspectiveName, true)}
                    <span className={Icons.dropdown} />
                  </span>
                  {invitingUser === false && (
                    <span className="dropdown-menu">
                      {companyPerspectives.map((perspective, i) => {
                        return (
                          <div
                            key={i}
                            className="dropdown-item"
                            style={{ cursor: "default" }}
                            onClick={() => {
                              this.setState({
                                defaultPerspective: perspective
                              });
                            }}
                          >
                            {getPartnerType(perspective.perspectiveName, true)}
                          </div>
                        );
                      })}
                    </span>
                  )}
                </span>
              </div>
              <div className="flex-align-start">
                <span className={labelClass}>{Resources.Message}</span>
                <textarea
                  className={`${inputClass}`}
                  style={{ height: "250px" }}
                  disabled={invitingUser}
                  value={this.state.customMessage}
                  onChange={e => {
                    this.setState({ customMessage: e.target.value });
                  }}
                />
              </div>
            </div>
          </div>
        }
        footer={
          <div>
            {invitingUser === true && (
              <span className="col-6">
                <span className="mt-2 float-left">
                  <span>{`Inviting user to company...`}</span>
                  <span className={Icons.spinner} />
                </span>
              </span>
            )}
            <span className="col-6">
              <span className="float-right">
                <button className="cancel-button" disabled={invitingUser} onClick={this.onCancel}>
                  {Resources.Cancel}
                </button>
                <button className="send-button" disabled={invitingUser} onClick={this.validateAndInvite}>
                  {Resources.Invite}
                </button>
              </span>
            </span>
          </div>
        }
      />
    );
  }
}

const storeToProps = store => {
  return {
    invite: store.invite
  };
};

const dispatchToProps = dispatch => ({
  ...invDP(dispatch),
  ...errDP(dispatch),
  ...paDP(dispatch)
});

export default connect(storeToProps, dispatchToProps)(InviteCompanyUser);
