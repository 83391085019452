import React, { Component } from "react";

class IconSpinner extends Component {
  render() {
    let { className, height } = this.props;

    return (
      <div className="icon-spinner">
        <svg
          stroke="red"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          viewBox="0 0 24 24"
          height={height}
          className={`icon ${className ? className : ""}`}
        >
          <path d="M12 2v4" order="1" />
          <path d="M16.24 7.7600002l2.83-2.8300004" order="2" />
          <path d="M18 12h4" order="3" />
          <path d="M16.24 16.24l2.83 2.83" order="4" />
          <path d="M12 18v4" order="5" />
          <path d="M4.9299998 19.07l2.8300004-2.83" order="6" />
          <path d="M2 12h4" order="7" />
          <path d="M4.9299998 4.9299998l2.8300004 2.8300004" order="8" />
        </svg>
      </div>
    );
  }
}

IconSpinner.defaultProps = {
  height: 24
};

export default IconSpinner;
