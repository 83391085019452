import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from "react-modal";

import Icons from "../lib/icons";
import Resources from "../lib/resources";

import { dispatchToProps as modDP } from "../store/modal-actions";
import { dispatchToProps as ledgDP } from "../store/ledger-actions";
import { dispatchToProps as convDP } from "../store/conversations-actions";

import TextAreaInput from "./library/textAreaInput";
import { isEmpty } from "../lib/utils";

const dispatchToProps = dispatch => ({
  ...modDP(dispatch),
  ...ledgDP(dispatch),
  ...convDP(dispatch)
});

class CancelRequestResponseModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      text: ""
    };

    this.send = this.send.bind(this);
  }

  send(to, from, text) {
    let { companyId, ledgerHash } = this.props.modalStore.modalProps;
    this.props
      .putLedgerActionEntry(companyId, ledgerHash, "RequestResponse", "Cancel", { to, from, textBody: text })
      .then(response => {
        this.props.hideModal();
        this.props.refreshConversation();
      })
      .catch(error => {
        return null;
      });
  }

  render() {
    const { modalStore, creatingEntry, subject, requestee, to, from } = this.props;
    return (
      <Modal
        isOpen={modalStore.showModal}
        onRequestClose={() => this.props.hideModal()}
        className="custom-modal"
        overlayClassName="custom-modal-overlay"
      >
        <div className="custom-modal-header">{Resources.NotifyRespondentViaEmail}</div>
        <div className="custom-modal-divider" />
        <div className="custom-modal-content">
          <div>
            <span className="gray-3">{Resources.ResponseRequired}</span> {subject}
          </div>
          <div>
            {Resources.To}: {requestee}
          </div>
          <TextAreaInput
            textValue={this.state.text}
            inputOnChange={e => this.setState({ text: e.target.value })}
            label={Resources.Message}
            rows={4}
            className="mt-2"
          />
          {creatingEntry ? (
            <span className={Icons.spinner} />
          ) : (
            <div className="cancel-request-response-bottom-buttons">
              <button
                className="button-green-invert cancel-request-response-bottom-buttons-no-email"
                onClick={() => this.send(from, from, "")}
              >
                {Resources.CancelRequestWithoutSendingAnEmail}
              </button>
              <button
                className="button-green"
                onClick={() => this.send(to, from, this.state.text)}
                disabled={isEmpty(this.state.text)}
              >
                {Resources.Send}
              </button>
            </div>
          )}
        </div>
      </Modal>
    );
  }
}

const storeToProps = store => {
  return {
    modalStore: store.modal,
    creatingEntry: store.ledger.creatingEntry
  };
};

export default connect(
  storeToProps,
  dispatchToProps
)(CancelRequestResponseModal);
