import React, { Component } from "react";
import { updateSort } from "../../lib/utils";
import IconTableSort from "./icons/iconTableSort";

export default class TableDataSortableHeader extends Component {
  render() {
    return (
      <span
        className="table-data-heading-sort"
        onClick={() =>
          updateSort(this.props.sortKey, this.props.sortBy, this.props.sortDirection, (sortBy, sortDirection) =>
            this.props.updateSort(sortBy, sortDirection)
          )
        }
      >
        <span>{this.props.text}</span>
        <span style={{ marginLeft: "0.3rem" }}>
          <IconTableSort
            height={14}
            topClass={
              this.props.sortKey === this.props.sortBy && this.props.sortDirection === "asc" ? "font-turquoise" : null
            }
            bottomClass={
              this.props.sortKey === this.props.sortBy && this.props.sortDirection === "desc" ? "font-turquoise" : null
            }
          />
        </span>
      </span>
    );
  }
}
