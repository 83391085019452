import React, { Component } from "react";
import { connect } from "react-redux";
import { Route } from "react-router-dom";

import { dispatchToProps } from "../store/general-actions";

import ModalCompanyRemap from "./modalCompanyRemap";
import ModalCompanyRename from "./modalCompanyRename";
import SharedDocumentForm from "./sharedDocumentForm";
import AttachFileModal from "./attachFileModal";
import AttachFromLibraryModal from "./attachFromLibraryModal";
import SetReminderModal from "./setReminderModal";
import UnmappedPropertyModal from "./unmappedPropertyModal";
import WarnBeforeDeleteModal from "./warnBeforeDeleteModal";
import ConfirmModal from "./confirmModal";
import ImportContactsModal from "./importContactsModal";
import UserManagementModal from "./userManagementModal";
import OriginalEmailModal from "./originalEmailModal";
import ImapConfigModal from "./imapConfigModal";
import ChangeIncomingEmailModal from "./changeIncomingEmailModal";
import ErrorModal from "./errorModal";
import CustomDateRangeModal from "./customDateRangeModal";
import SendStatementModal from "./sendStatementModal";
import DisconnectConnectorModal from "./disconnectConnectorModal";
import AddEmailIdentifierModal from "./addEmailIdentifierModal";
import CancelRequestResponseModal from "./cancelRequestResponseModal";
import AddSpamAddressModal from "./addSpamAddressModal";
import CancelApprovalRequestModal from "./cancelApprovalRequestModal";
import DKIMConfigurationModal from "./dkimConfigurationModal";
import PortalAddVendorsModal from "./customer_portal/portalAddVendorsModal";

import Modal from "react-modal";
import SetNewPrimaryContactDialog from "./lockstep/dialogs/setNewPrimaryContactDialog";
import SetNewDocumentTypeDialog from "./lockstep/dialogs/setNewDocumentTypeDialog";
import DeleteDocumentConfirmDialog from "./lockstep/dialogs/deleteDocumentConfirmDialog";
import SendPrivateContactRequestDialog from "./lockstep/dialogs/sendPrivateContactRequestDialog";
import TestEmailConfigModal from "./testEmailConfigModal";

class ModalManager extends Component {
  render() {
    if (this.props.modal.showModal === false) {
      return null;
    }

    Modal.setAppElement(document.getElementById("root"));

    switch (this.props.modal.modalType) {
      case "remap":
        return (
          <Route
            path="/company/:companyId/:perspectiveId/:view?/:selectedContextGroupId?/:selectedItemId?"
            component={ModalCompanyRemap}
          />
        );
      case "rename":
        return <ModalCompanyRename {...this.props.modal.modalProps} />;
      case "setnewPrimaryContactDialog":
        return <SetNewPrimaryContactDialog {...this.props.modal.modalProps} />;
      case "setNewDocumentTypeDialog":
        return <SetNewDocumentTypeDialog {...this.props.modal.modalProps} />;
      case "deleteDocumentConfirmDialog":
        return <DeleteDocumentConfirmDialog {...this.props.modal.modalProps} />;
      case "sendPrivateContactRequestDialog":
        return <SendPrivateContactRequestDialog {...this.props.modal.modalProps} />;
      case "sharedDocumentForm":
        return <SharedDocumentForm {...this.props.modal.modalProps} />;
      case "attachFileModal":
        return <AttachFileModal {...this.props.modal.modalProps} />;
      case "attachFromLibraryModal":
        return <AttachFromLibraryModal {...this.props.modal.modalProps} />;
      case "setReminder":
        return <SetReminderModal {...this.props.modal.modalProps} />;
      case "unmappedPropertyModal":
        return <UnmappedPropertyModal {...this.props.modal.modalPros} />;
      case "warnBeforeDeleteModal":
        return <WarnBeforeDeleteModal {...this.props.modal.modalProps} />;
      case "confirmModal":
        return <ConfirmModal {...this.props.modal.modalProps} />;
      case "importContactsModal":
        return <ImportContactsModal {...this.props.modal.modalProps} />;
      case "userManagementModal":
        return <UserManagementModal {...this.props.modal.modalProps} />;
      case "originalEmailModal":
        return <OriginalEmailModal {...this.props.modal.modalProps} />;
      case "imapConfigModal":
        return <ImapConfigModal {...this.props.modal.modalProps} />;
      case "changeIncomingEmail":
        return <ChangeIncomingEmailModal {...this.props.modal.modalProps} />;
      case "errorModal":
        return <ErrorModal {...this.props.modal.modalProps} />;
      case "customDateRangeModal":
        return <CustomDateRangeModal {...this.props.modal.modalProps} />;
      case "sendStatementModal":
        return <SendStatementModal {...this.props.modal.modalProps} />;
      case "disconnectConnectorModal":
        return <DisconnectConnectorModal {...this.props.modal.modalProps} />;
      case "addEmailIdentifierModal":
        return <AddEmailIdentifierModal {...this.props.modal.modalProps} />;
      case "cancelRequestResponse":
        return <CancelRequestResponseModal {...this.props.modal.modalProps} />;
      case "addSpamAddressModal":
        return <AddSpamAddressModal {...this.props.modal.modalProps} />;
      case "cancelApprovalRequest":
        return <CancelApprovalRequestModal {...this.props.modal.modalProps} />;
      case "dkimConfigurationModal":
        return <DKIMConfigurationModal {...this.props.modal.modalProps} />;
      case "testEmailConfigModal":
        return <TestEmailConfigModal {...this.props.modal.modalProps} />;
      case "portalAddVendorsModal":
        return <PortalAddVendorsModal {...this.props.modal.modalProps} />;
      default:
        return null;
    }
  }
}

const storeToProps = store => {
  return {
    modal: store.modal
  };
};

export default connect(storeToProps, dispatchToProps)(ModalManager);
