import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";

import Modal from "react-modal";

import { dispatchToProps as moDP } from "../store/modal-actions";
import FlexibleScrollContainer from "./flexibleScrollContainer";

import { isEmpty } from "../lib/utils";
import Icons from "../lib/icons";
import Resources from "../lib/resources";

const dispatchToProps = dispatch => {
  return {
    ...moDP(dispatch)
  };
};

class OriginalEmailModal extends Component {
  render() {
    const { modalStore } = this.props;

    let emailBody = modalStore.modalProps.originalHtmlBody;
    if (isEmpty(emailBody)) {
      emailBody = (modalStore.modalProps.originalTextBody || "").split("\n").join("<br />");
    }
    return (
      <Modal
        isOpen={modalStore.showModal}
        onRequestClose={this.props.hideModal}
        contentLabel="Example Modal"
        className="original-email-modal"
        overlayClassName="custom-modal-overlay"
      >
        <div className="flex-split align-baseline">
          <h4 className="fw-500">{this.props.subject}</h4>
          <div onClick={this.props.hideModal} className={`${Icons.close} close-original-email`} />
        </div>
        <div className="custom-modal-divider" />
        <div>
          {Resources.Date}: {moment(this.props.sendDate).calendar()}
        </div>
        <div>{this.props.from}</div>
        <div>{this.props.to}</div>
        <div>{this.props.cc}</div>
        <div>{this.props.bcc}</div>
        <div className="custom-modal-divider" />
        <div className="mb-2">
          <FlexibleScrollContainer offsetHeight="20">
            <div dangerouslySetInnerHTML={{ __html: emailBody }} />
          </FlexibleScrollContainer>
        </div>
        <div className="custom-modal-content" />
      </Modal>
    );
  }
}

const storeToProps = store => {
  return {
    modalStore: store.modal
  };
};

export default connect(
  storeToProps,
  dispatchToProps
)(OriginalEmailModal);
