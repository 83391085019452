import data from "../lib/data";
import auth from "../lib/auth";
import { getConfig, updateConfig, isEmpty, find } from "../lib/utils";
import { actions as GeneralActions } from "./general-actions";
import { loggedIn } from "./user-actions";
import { logout } from "./user-actions";

export const actions = {
  ...GeneralActions,
  ...{
    SET_COMPANY_REGISTRATION_INFO: "SET_COMPANY_REGISTRATION_INFO",

    SET_ACCT_EMAIL_CONFIG_INFO: "SET_ACCT_EMAIL_CONFIG_INFO",

    CREATE_USER_ACCOUNT_FAILED: "CREATE_USER_ACCOUNT_FAILED",
    RESET_REGISTRATION_STATE: "RESET_REGISTRATION_STATE",

    SETTING_EMAIL_FORWARDING_ADDRESS: "SETTING_EMAIL_FORWARDING_ADDRESS",
    EMAIL_FORWARDING_ADDRESS_SET: "EMAIL_FORWARDING_ADDRESS_SET",
    SET_EMAIL_FORWARDING_ADDRESS_FAILED: "SET_EMAIL_FORWARDING_ADDRESS_FAILED",

    GETTING_INVITE_DATA: "GETTING_INVITE_DATA",
    GOT_INVITE_DATA: "GOT_INVITE_DATA",
    GET_INVITE_DATA_FAILED: "GET_INVITE_DATA_FAILED",

    ACCOUNT_IS_BEING_CREATED: "ACCOUNT_IS_BEING_CREATED",
    ACCOUNT_HAS_BEEN_CREATED: "ACCOUNT_HAS_BEEN_CREATED",
    ACCOUNT_ALREADY_EXISTS: "ACCOUNT_ALREADY_EXISTS",
    ACCOUNT_CREATION_FAILED: "ACCOUNT_CREATION_FAILED",

    GETTING_AUTH_TOKEN: "GETTING_AUTH_TOKEN",
    GOT_AUTH_TOKEN: "GOT_AUTH_TOKEN",
    GET_AUTH_TOKEN_FAILED: "GET_AUTH_TOKEN_FAILED",

    TRIGGER_REGISTRATION_ERRORS: "TRIGGER_REGISTRATION_ERRORS",
    CLEAR_REGISTRATION_ERRORS: "CLEAR_REGISTRATION_ERRORS"
  }
};

let CREATE_USER_ACCOUNT_PROMISE = null;
const createUserAccount = companyInfoObj => (dispatch, getState) => {
  let payload = {
    userName: companyInfoObj.personalEmail,
    password: companyInfoObj.password,
    fullName: "",
    givenName: companyInfoObj.personalEmail,
    familyName: "",
    email: companyInfoObj.personalEmail,
    website: companyInfoObj.companyWebsite,
    address: {
      street_address: companyInfoObj.streetAddress,
      locality: companyInfoObj.city,
      region: companyInfoObj.state,
      postal_code: companyInfoObj.zip,
      country: companyInfoObj.country
    }
  };

  CREATE_USER_ACCOUNT_PROMISE = auth
    .createNewUser(payload)
    .then(() => {
      return auth.login(companyInfoObj.personalEmail, companyInfoObj.password).then(response => {
        window.localStorage.setItem("isCurrentlyRegistering", true);
        window.localStorage.setItem("accountingEmail", companyInfoObj.accountingEmail);
        window.localStorage.setItem("personalEmail", companyInfoObj.personalEmail);
        window.localStorage.setItem("companyName", companyInfoObj.companyName);
        return dispatch(loggedIn(response.identityToken));
      });
    })
    .catch(e => {
      window.localStorage.setItem("registration-stage", "COMPANY_INFO");
      let response = e.request.response || "{}";
      let errorResponse = JSON.parse(response);
      let errorMsgKeys = Object.keys(errorResponse);
      let errorMsgs = errorMsgKeys.map(key => {
        return errorResponse[key];
      });
      dispatch({ type: actions.CREATE_USER_ACCOUNT_FAILED });
      dispatch({ type: actions.TRIGGER_REGISTRATION_ERRORS, errorMsgs });
      throw new Error("Create New User Failed");
    });

  return CREATE_USER_ACCOUNT_PROMISE;
};

let CHECK_IF_COMPANY_EXISTS_PROMISE = null;
const checkIfCompanyExist = () => dispatch => {
  let accountingEmail = window.localStorage.getItem("accountingEmail");

  CHECK_IF_COMPANY_EXISTS_PROMISE = data
    .post("v1/api/invite/lookup", { email: accountingEmail })
    .then(lookupResult => {
      let companyId = lookupResult.data.companyId;
      data.post(`v1/api/invite/request/${companyId}`).then(() => {
        window.localStorage.setItem("registration-stage", "USER_COMPANY_EXISTS");
        dispatch(logout());
        window.location = "/#/register";
      });
    })
    .catch(lookupResult => {
      if (lookupResult.response.status === 404) {
        let companyName = window.localStorage.getItem("companyName");
        data.put("v1/api/account", { accountName: companyName }).then(acctResult => {
          let payload = {
            companyName: acctResult.data.accountName,
            companyEmail: accountingEmail,
            accountId: acctResult.data.accountId,
            contactNumber: ""
          };
          data.put("v1/api/account/company", payload).then(companyResult => {
            window.localStorage.setItem("registration-stage", "GETTING_VERIFIED");
            dispatch(logout());
            window.location = "/#/register";
          });
        });
      } else {
        // registration failed
      }
    });
  return CHECK_IF_COMPANY_EXISTS_PROMISE;
};

let SET_FORWARDING_ADDRESS_PROMISE = null;
const setEmailForwardingAddress = (companyId, perspective, email, idTokenHeaders) => dispatch => {
  dispatch({ type: actions.SETTING_EMAIL_FORWARDING_ADDRESS });
  let promises = [
    new Promise((resolve, reject) => {
      data
        .put(
          `v1/api/account/company/${companyId}/emails`,
          '"' + email + '"',
          idTokenHeaders ? idTokenHeaders : { headers: { "Content-Type": "application/json" } }
        )
        .then(resolve)
        .catch(e => {
          let errorResponse = e.response.data || {};
          let errorMsgKeys = Object.keys(errorResponse);
          let errorMsgs = errorMsgKeys.map(key => {
            return errorResponse[key];
          });

          let emailAlreadyExists = false;
          if (errorMsgs.length > 0) {
            emailAlreadyExists = errorMsgs[0].some(item => {
              return item === "This email address is already assigned to an existing company.";
            });
          }
          if (emailAlreadyExists) {
            resolve();
          } else {
            reject(e);
          }
        });
    }),
    data.post(
      `v1/api/connector/email/integrations/${companyId}`,
      { perspectiveId: perspective, companyForwardingAddress: email },
      idTokenHeaders ? idTokenHeaders : { headers: { "Content-Type": "application/json" } }
    )
  ];

  SET_FORWARDING_ADDRESS_PROMISE = Promise.all(promises)
    .then(result => {
      let emailForwardingToAddress = result[1].data.localPart + "@" + getConfig().emailDomain;
      let emailForwardingFromAddress = email;
      dispatch({
        type: actions.EMAIL_FORWARDING_ADDRESS_SET,
        emailForwardingFromAddress,
        emailForwardingToAddress
      });
    })
    .catch(e => {
      // let errorResponse = e.response.data || {};
      // let errorMsgKeys = Object.keys(errorResponse);
      // let errorMsgs = errorMsgKeys.map(key => {
      //   return errorResponse[key];
      // });
      let emailAlreadyExists = false;
      // if (errorMsgs.length > 0) {
      //   emailAlreadyExists = errorMsgs[0].some(item => {
      //     return item === "This email address is already assigned to an existing company.";
      //   });
      // }

      if (emailAlreadyExists) {
        dispatch(setActiveEmailForForwarding(companyId, perspective, email, idTokenHeaders));
      } else {
        dispatch({
          type: actions.TRIGGER_REGISTRATION_ERRORS,
          errorMsgs: "There was a problem registering your email."
        });
        dispatch({ type: actions.SET_EMAIL_FORWARDING_ADDRESS_FAILED });
      }
    });

  return SET_FORWARDING_ADDRESS_PROMISE;
};

const setActiveEmailForForwarding = (companyId, perspective, email, idTokenHeaders) => dispatch => {
  dispatch({ type: actions.SETTING_EMAIL_FORWARDING_ADDRESS });
  data
    .post(
      `v1/api/connector/email/integrations/${companyId}`,
      { perspectiveId: perspective, companyForwardingAddress: email },
      idTokenHeaders ? idTokenHeaders : { headers: { "Content-Type": "application/json" } }
    )
    .then(result => {
      let emailForwardingToAddress = result.data.localPart + "@" + getConfig().emailDomain;
      let emailForwardingFromAddress = email;
      dispatch({
        type: actions.EMAIL_FORWARDING_ADDRESS_SET,
        emailForwardingFromAddress,
        emailForwardingToAddress
      });
    })
    .catch(e => {
      dispatch({ type: actions.SET_EMAIL_FORWARDING_ADDRESS_FAILED });
    });
};

const resendEmailVerification = (companyId, emailId) => (dispatch, getState) => {
  data.post(`v1/api/account/company/${companyId}/emails/${emailId}/resendverification`);
};

const getInviteData = inviteCode => dispatch => {
  dispatch({ type: actions.GETTING_INVITE_DATA });
  return data
    .get(`v1/api/invite/user/${inviteCode}`)
    .then(response => {
      dispatch({ type: actions.GOT_INVITE_DATA, inviteData: response.data });
    })
    .catch(err => {
      if (err.response && err.response.status === 404) {
        throw new Error("Invalid invite");
      }
      dispatch({ type: actions.GET_INVITE_DATA_FAILED });
    });
};

const invitationSignUp = (inviteData, companyInfo) => (dispatch, getState) => {
  dispatch({ type: actions.ACCOUNT_IS_BEING_CREATED });
  let createUserPayload = {
    userName: inviteData.email,
    password: companyInfo.password,
    fullName: inviteData.fullName,
    givenName: inviteData.fullName,
    familyName: "",
    email: inviteData.email,
    website: companyInfo.website,
    inviteId: inviteData.userInviteId,
    address: {
      street_address: companyInfo.address.street_address,
      locality: companyInfo.address.locality,
      region: companyInfo.address.region,
      postal_code: companyInfo.address.postalCode,
      country: companyInfo.address.country
    }
  };
  let accountingEmail = companyInfo.apEmail ? companyInfo.apEmail : companyInfo.arEmail || null;

  let signIn = auth
    .createNewUser(createUserPayload)
    .then(response => {
      if (response.status !== 200) {
        throw response;
      }
      return dispatch(setAuthToken(inviteData.email, companyInfo.password));
    })
    .catch(e => {
      let response = e.request.response || "{}";
      let errorResponse = JSON.parse(response);
      let errorMsgKeys = Object.keys(errorResponse);
      let errorMsgs = errorMsgKeys.map(key => {
        return errorResponse[key];
      });
      if (
        errorMsgs.some(
          item =>
            item[0] === "A user with this email address already exists." ||
            item[0] === "A user with this username already exists."
        )
      ) {
        return dispatch(setAuthToken(inviteData.email, companyInfo.password));
      } else {
        dispatch({ type: actions.ACCOUNT_CREATION_FAILED });
        dispatch({ type: actions.TRIGGER_REGISTRATION_ERRORS, errorMsgs });
        throw e;
      }
    });
  signIn.then(response => {
    let headers = getState().registration.appJsonHeadersWithBToken;
    data
      .post("v1/api/invite/lookup", { email: accountingEmail }, headers)
      .then(lookupResult => {
        let companyId = lookupResult.data.companyId;
        data.post(`v1/api/invite/request/${companyId}`, headers).then(() => {
          dispatch({ type: actions.ACCOUNT_ALREADY_EXISTS });
        });
      })
      .catch(lookupResult => {
        if (lookupResult.response.status === 404 || accountingEmail === null) {
          data
            .put("v1/api/account", { accountName: companyInfo.companyName }, headers)
            .then(acctResult => {
              dispatch(
                createCompany(acctResult.data.accountName, accountingEmail, acctResult.data.accountId, {
                  ...companyInfo,
                  inviteId: inviteData.userInviteId
                })
              );
            })
            .catch(rejection => {
              // create of account failed...search for existing accounts and add to that instead...
              data.get("v1/api/account", headers).then(response => {
                let { accountId } =
                  find(response.data, account => {
                    return account.accountName === companyInfo.companyName;
                  }) ||
                  response.data[0] ||
                  {};
                if (isEmpty(accountId) === false) {
                  dispatch(
                    createCompany(companyInfo.companyName, accountingEmail, accountId, {
                      ...companyInfo,
                      inviteId: inviteData.userInviteId
                    })
                  );
                } else {
                  dispatch({ type: actions.ACCOUNT_CREATION_FAILED });
                }
              });
            });
        } else {
          dispatch({ type: actions.ACCOUNT_CREATION_FAILED });
        }
      });
  });
};

const createCompany = (accountName, accountEmail, accountId, companyInfo) => (dispatch, getState) => {
  dispatch(setAuthToken(companyInfo.email, companyInfo.password)).then(() => {
    let headers = getState().registration.appJsonHeadersWithBToken;
    let idToken = getState().registration.idToken;
    let acctCompanyPayload = {
      companyName: accountName,
      companyEmail: accountEmail,
      accountId: accountId,
      contactNumber: "",
      inviteId: companyInfo.inviteId
    };
    data
      .put("v1/api/account/company", acctCompanyPayload, headers)
      .then(companyResult => {
        let companyId = companyResult.data.companyId;
        let companyInfoPayload = {
          "Contact Numbers": {
            "AP Phone": companyInfo.apPhone,
            "AR Phone": companyInfo.arPhone
          },
          Address: {
            Line1: companyInfo.address.street_address,
            // Line2: companyInfo.line2,
            // Line3: companyInfo.line3,
            City: companyInfo.address.locality,
            Region: companyInfo.address.region,
            Country: companyInfo.address.country,
            PostalCode: companyInfo.address.postal_code
          },
          apEmail: companyInfo.apEmail,
          arEmail: companyInfo.arEmail,
          URL: companyInfo.website,
          TIN: companyInfo.TIN,
          "W9 File": companyInfo.w9File,
          "W8 BEN File": companyInfo.w8BenFile
        };
        let promises = [];
        // if (companyInfo.apEmail)
        //   promises.push(dispatch(setEmailForwardingAddress(companyId, "AP", companyInfo.apEmail, headers)));
        // if (companyInfo.arEmail)
        //   promises.push(dispatch(setEmailForwardingAddress(companyId, "AR", companyInfo.arEmail, headers)));
        promises.push(data.post(`v1/api/account/company/${companyId}/info`, companyInfoPayload, headers));
        Promise.all(promises).then(() => {
          window.localStorage.setItem("logged_in", "true");
          window.localStorage.setItem("id_token", idToken);
          updateConfig({ AccessToken: idToken });
          dispatch({
            type: actions.ACCOUNT_HAS_BEEN_CREATED,
            signUpCustomerId: companyId
          });
        });
      })
      .catch(e => {
        let response = e.request.response || "{}";
        let errorResponse = JSON.parse(response);
        let errorMsgKeys = Object.keys(errorResponse);
        let errorMsgs = errorMsgKeys.map(key => {
          return errorResponse[key];
        });
        dispatch({ type: actions.ACCOUNT_CREATION_FAILED });
        dispatch({ type: actions.TRIGGER_REGISTRATION_ERRORS, errorMsgs });
      });
  });
};

let SET_AUTH_TOKEN = null;
const setAuthToken = (username, password) => dispatch => {
  dispatch({ type: actions.GETTING_AUTH_TOKEN });
  let authorizePayload = {
    username: username,
    password: password,
    rememberLogin: true,
    returnUrl: auth.returnUrl
  };
  SET_AUTH_TOKEN = auth
    .post("v1/user/login/authorize", authorizePayload)
    .then(response => {
      let idToken = response.data.identityToken;
      let appJsonHeadersWithBToken = {
        headers: {
          Authorization: "Bearer " + idToken,
          "Content-Type": "application/json"
        }
      };
      dispatch({
        type: actions.GOT_AUTH_TOKEN,
        idToken,
        appJsonHeadersWithBToken
      });
    })
    .catch(e => {
      let response = e.request.response || "{}";
      let errorResponse = JSON.parse(response);
      let errorMsgKeys = Object.keys(errorResponse);
      let errorMsgs = errorMsgKeys.map(key => {
        return errorResponse[key];
      });
      dispatch({ type: actions.GET_AUTH_TOKEN_FAILED });
      dispatch({ type: actions.TRIGGER_REGISTRATION_ERRORS, errorMsgs });
    });
  return SET_AUTH_TOKEN;
};

export const dispatchToProps = dispatch => ({
  setCompanyRegistrationInfo: companyInfo => {
    dispatch({ type: actions.SET_COMPANY_REGISTRATION_INFO, companyInfo });
  },
  createUserAccount: companyInfoObj => {
    return dispatch(createUserAccount(companyInfoObj));
  },
  checkIfCompanyExist: () => {
    return dispatch(checkIfCompanyExist());
  },
  resetRegistrationState: () => {
    dispatch({ type: actions.RESET_REGISTRATION_STATE });
  },
  setEmailForwardingAddress: (companyId, perspective, email) => {
    return dispatch(setEmailForwardingAddress(companyId, perspective, email));
  },
  setActiveEmailForForwarding: (companyId, perspective, email) => {
    dispatch(setActiveEmailForForwarding(companyId, perspective, email));
  },
  getInviteData: inviteCode => {
    return dispatch(getInviteData(inviteCode));
  },
  invitationSignUp: (inviteData, companyInfo) => {
    dispatch(invitationSignUp(inviteData, companyInfo));
  },
  createCompany: (accountName, accountEmail, accountId, companyInfo) => {
    dispatch(createCompany(accountName, accountEmail, accountId, companyInfo));
  },
  setAuthToken: (username, password) => {
    return dispatch(setAuthToken(username, password));
  },
  resendEmailVerification: (companyId, emailId) => {
    dispatch(resendEmailVerification(companyId, emailId));
  },
  clearRegErrorMessages: () => {
    return dispatch({ type: actions.CLEAR_REGISTRATION_ERRORS });
  }
});
