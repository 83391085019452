import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import Resources from "../../lib/resources";

class NotConnected extends Component {
  getAddress(address = { Country: "", Line1: "", Locality: "", PostalCode: "", Region: "" }) {
    let { /*Country,*/ Line1, Locality, PostalCode, Region } = address;
    return (
      <div>
        {Line1}
        <br />
        {Locality}, {Region} {PostalCode}
      </div>
    );
  }

  render() {
    let { companyId } = this.props.match.params;
    return (
      <React.Fragment>
        <div className="company-profile-disconnected">
          <div className="not-connected-image">
            <img alt={Resources.NotSuccessful} src="/img/no-syncing.png" className="img-fluid" />
          </div>

          <div className="not-connected-text">{Resources.AccountingPackageNotConnectedCompanyProfile}</div>
          <button
            className="not-connected-button button-green"
            onClick={() => this.props.history.push(`/company/${companyId}/manage/packages`)}
          >
            {Resources.ConnectNow}
          </button>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(NotConnected);
