import React, { Component } from "react";
import { connect } from "react-redux";

import { dispatchToProps as seqDP } from "../../store/sequences-actions";
import { dispatchToProps as cgDP } from "../../store/contextGroups-actions";

import Card from "../library/card";
import TableData from "../library/tableData";
import TableDataSortableHeader from "../library/tableDataSortableHeader";

import { formatDate } from "../../lib/utils";

import Resources from "../../lib/resources";

const dispatchToProps = dispatch => {
  return {
    ...dispatch(seqDP),
    ...dispatch(cgDP)
  };
};

class SequencesOverviewTabContent extends Component {
  componentDidMount() {
    this.props.fetchSequenceEvents(this.props.companyId);
  }

  handleLoadMore(pageToLoad) {
    let { companyId, pageRowCount } = this.props;
    let top = pageRowCount;
    let skip = pageToLoad * pageRowCount - pageRowCount;
    this.props.fetchSequenceEvents(companyId, null, null, top, skip);
  }

  render() {
    const { sequencesStore } = this.props;
    const data = this.props.sequencesStore.sequenceEvents;

    const maxRows = sequencesStore.isShowingSearchResult ? data.length : sequencesStore.sequenceEventsCount;

    const columns = [
      {
        header: (
          <TableDataSortableHeader
            sortBy={sequencesStore.sequencesSortBy}
            sortDirection={sequencesStore.sequencesSortDirection}
            updateSort={(sortBy, sortDirection) => {}}
            text={Resources.To.toLocaleUpperCase()}
            sortKey={"sequenceName"}
          />
        ),
        width: "25%",
        content: row => <div>{this.props.getContextGroupNameFromCompanyId(row.companyId)}</div>
      },
      {
        header: Resources.Preview.toLocaleUpperCase(),
        width: "50%",
        content: row => (
          <div>
            {row.taskType}
            <span className="sequences-preview-text"> - {row.previewText}</span>
          </div>
        )
      },
      { header: Resources.Date.toLocaleUpperCase(), content: row => <div>{formatDate(row.date)}</div> }
    ];

    return (
      <div>
        <Card className="sequences-summary">
          <div className="statements-summary-item">
            <div className="statements-summary-item-header flex-column flex-align-center">
              {Resources.ActiveSequences}
            </div>
            <div className="statements-summary-item-data">{sequencesStore.activeSequenceCount || "-"}</div>
          </div>
          <div className="statements-summary-item">
            <div className="statements-summary-item-header flex-column flex-align-center">
              {Resources.AutomatedTasks} <span>({Resources.Today})</span>
            </div>
            <div className="statements-summary-item-data">{sequencesStore.automatedTasksTodayCount || "-"}</div>
          </div>
          <div className="statements-summary-item">
            <div className="statements-summary-item-header flex-column flex-align-center">
              {Resources.ManualTasks} <span>({Resources.Today})</span>
            </div>
            <div className="statements-summary-item-data">{sequencesStore.manualTasksTodayCount || "-"}</div>
          </div>
          <div className="statements-summary-item">
            <div className="statements-summary-item-header flex-column flex-align-center">
              {Resources.AutomatedTasks} <span>({Resources.ThisMonth})</span>
            </div>
            <div className="statements-summary-item-data">{sequencesStore.automatedTasksMonthCount || "-"}</div>
          </div>
          <div className="statements-summary-item">
            <div className="statements-summary-item-header flex-column flex-align-center">
              {Resources.ManualTasks} <span>({Resources.ThisMonth})</span>
            </div>
            <div className="statements-summary-item-data">{sequencesStore.manualTasksMonthCount || "-"}</div>
          </div>
        </Card>
        <Card
          type="company-info-contact-us"
          className="company-info-contact-us"
          title={Resources.RecentlyTriggeredSequenceSteps}
          headAboveCard
        >
          <TableData
            pagination
            data={data}
            columns={columns}
            rowHeight="4em"
            onLoadMore={pageSelected => this.handleLoadMore(pageSelected)}
            maxRows={maxRows}
            loading={this.props.sequencesStore.isFetchingSequenceEvents}
            rowClassName="statements-view-row"
            offsetHeight="40"
            emptyRender={
              <div className="flex-center">
                <h4 className="mt-5">{Resources.EmptyCommunicationTemplates}</h4>
              </div>
            }
          />
        </Card>
      </div>
    );
  }
}
const storeToProps = store => {
  return {
    contextGroupsStore: store.contextGroups,
    sequencesStore: store.sequences,
    pageRowCount: store.general.pageRowCount
  };
};
export default connect(
  storeToProps,
  dispatchToProps
)(SequencesOverviewTabContent);
