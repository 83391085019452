import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import axios from "../../lib/axios";
import { isProduction, removeAt, includes } from "../../lib/utils";
import { dispatchToProps } from "../../store/connector-actions";

class CompanyLogoButton extends Component {
  cleanUrl(dirtyUrl) {
    let cleanUrl = dirtyUrl;
    if (isProduction() && includes(dirtyUrl, "lockstep.network")) {
      let parts = dirtyUrl.split(".");
      if (parts[1] !== "lockstep") {
        cleanUrl = removeAt(parts, 1).join(".");
      }
    }
    return cleanUrl;
  }

  startConnectorRegistration = () => {
    let {
      companyId,
      setConnectorPermissionsForCompany,
      startCustomConnectorRegistration,
      connectorInfo,
      connectorInfo: { redirectUrl, authType, name, connectorName },
      connector: { companyGrantedPermissions, allSupportedConnectors }
    } = this.props;
    let isGrantedPermissions = companyGrantedPermissions.some(permission => {
      return permission.companyName === connectorName;
    });
    let connectorData = allSupportedConnectors.some(connector => {
      return connector.connectorName === name;
    });

    switch (authType) {
      case "oauth2":
        if (isGrantedPermissions === false) {
          setConnectorPermissionsForCompany(companyId, connectorData.companyId).then(() => {
            let cleanUrl = this.cleanUrl(redirectUrl);
            axios.get(cleanUrl + companyId).then(response => {
              window.location = response.data;
            });
          });
        }
        break;
      case "netsuite-custom":
        let connectorEndpoint = this.cleanUrl(connectorInfo.connectorEndpoint);
        startCustomConnectorRegistration({
          ...connectorInfo,
          connectorEndpoint
        });
        break;
      case "oauth1":
      default:
        break;
    }
  };

  render() {
    let {
      connectorInfo: { btnCustomCss, imgSrcPath, name }
    } = this.props;
    return (
      <button
        onClick={this.startConnectorRegistration}
        className={btnCustomCss + " shadow-lg company-logo-button m-0 p-0"}
      >
        <img width="100%" src={imgSrcPath} alt="generic.svg" />
        {name === "Other" ? <div className="centered-text-over-img">Other</div> : null}
      </button>
    );
  }
}

const storeToProps = store => {
  return {
    connector: store.connector
  };
};

export default withRouter(
  connect(
    storeToProps,
    dispatchToProps
  )(CompanyLogoButton)
);
