import React, { Component } from "react";

class IconFile extends Component {
  render() {
    let { className, height } = this.props;

    return (
      <svg
        height={height}
        className={`icon ${className ? className : ""}`}
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        viewBox="0 0 24 24"
      >
        <defs />
        <path d="M14 2H6a2 2 0 00-2 2v16a2 2 0 002 2h12a2 2 0 002-2V8z" />
        <path d="M14 2v6h6M16 13H8M16 17H8M10 9H8" />
      </svg>
    );
  }
}

IconFile.defaultProps = {
  height: 24
};

export default IconFile;
