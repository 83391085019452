import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from "react-modal";

import { dispatchToProps as moDP } from "../store/modal-actions";
import { dispatchToProps as maDP } from "../store/manage-actions";

import Icons from "../lib/icons";
import Resources from "../lib/resources";

import { getFileNameIconClasses } from "../lib/utils";
import Dropzone from "react-dropzone";

const dispatchToProps = dispatch => ({
  ...moDP(dispatch),
  ...maDP(dispatch)
});

class ImportContactsModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      contactsCSV: null,
      dropEnabled: false,
      uploadError: false
    };
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    if (
      prevProps &&
      prevProps.manageStore.isImportingCompanyAddressBook === true &&
      this.props.manageStore.isImportingCompanyAddressBook === false
    ) {
      if (this.props.manageStore.importingCompanyAddressBookFailed === true) {
        this.setState({ contactsCSV: null, uploadError: true });
      } else {
        this.props.hideModal();
      }
    }
  }

  addFiles(files) {
    this.setState({ contactsCSV: files[0], dropEnabled: false, uploadError: false });
  }

  render() {
    return (
      <Modal
        isOpen={this.props.modal.showModal}
        onRequestClose={() => this.props.hideModal()}
        className="custom-modal"
        overlayClassName="custom-modal-overlay"
      >
        <div className="custom-modal-header">{Resources.ImportContactList}</div>
        <div className="custom-modal-divider" />
        {this.state.uploadError && <div className="warning-color-font">{Resources.CSVUploadError}</div>}

        <Dropzone
          ref={el => {
            this.dropzoneRef = el;
          }}
          disabled={this.state.contactsCSV !== null}
          noClick={true}
          onDragEnter={e => {
            this.setState({ dropEnabled: true });
          }}
          onDragLeave={e => {
            this.setState({ dropEnabled: false });
          }}
          onDrop={acceptedFiles => this.addFiles(Array.from(acceptedFiles))}
        >
          {({ getRootProps, getInputProps }) => {
            return (
              <div {...getRootProps({ className: "custom-modal-content" })}>
                <input {...getInputProps()} />
                {this.state.contactsCSV === null ? (
                  <div
                    className={
                      "flex-column align-center my-2 py-5" +
                      (this.state.dropEnabled ? " dropzone-border" : " dropzone-border-clear")
                    }
                  >
                    <h2 className="mb-2 c-gray-font">{Resources.DragFileToUpload}</h2>
                    <div
                      className="green-link-white-bg fw-500"
                      onClick={() => {
                        this.dropzoneRef.open();
                      }}
                    >
                      {Resources.SelectCSVFromComputer}
                    </div>
                  </div>
                ) : (
                  <div className="my-3">
                    <h5 className="mb-1">{Resources.SelectedFile}</h5>
                    <div className="file-upload-item border-0">
                      <span className={getFileNameIconClasses(this.state.contactsCSV.name) + " filetype-icon"} />
                      <span className="attachment-name">{this.state.contactsCSV.name}</span>
                      <span
                        className={Icons.close + " attachment-close"}
                        onClick={() => {
                          this.setState({
                            contactsCSV: null
                          });
                        }}
                      />
                    </div>
                  </div>
                )}
                <div className="flex-row modal-buttons">
                  <button className="button-cancel float-left mr-1" onClick={this.props.hideModal}>
                    {Resources.Cancel}
                  </button>
                  <button
                    disabled={this.state.contactsCSV === null}
                    className="btn button-green"
                    onClick={() => {
                      this.props.importCompanyAddressBook(this.props.companyId, this.state.contactsCSV);
                    }}
                  >
                    {Resources.Import}
                  </button>
                </div>
              </div>
            );
          }}
        </Dropzone>
      </Modal>
    );
  }
}

const storeToProps = store => {
  return {
    modal: store.modal,
    manageStore: store.manage
  };
};

export default connect(
  storeToProps,
  dispatchToProps
)(ImportContactsModal);
