import React, { Component } from "react";
import { connect } from "react-redux";

import Resources from "../../../lib/resources";
import { isEmpty, find } from "../../../lib/utils";
import Attachment from "../../attachment";

import { dispatchToProps as modDP } from "../../../store/modal-actions";

const dispatchToProps = dispatch => ({
  ...modDP(dispatch)
});

class ApprovalRequestThreadItem extends Component {
  render() {
    const {
      posterName,
      emailBody,
      attachments,
      isFirstEntry,
      assignedTo,
      statusName,
      dateText,
      recipient,
      to,
      from,
      cc,
      viewOriginal,
      companyId,
      showCancel,
      resource = {},
      entry,
      params
    } = this.props;

    let { subject } = entry.asJson;
    let { ledgerHash } = entry;

    let action = find(resource.actions, action => action.actionId === entry.actionId) || {};
    if (action.actionName === "Cancel") {
      return (
        <div className="conversation-thread-item system-message-item">
          <span>{Resources.PosterCanceledTheApprovalRequest(posterName)}</span>
          {viewOriginal || null}
          <span className="float-right" style={{ textAlign: "right" }}>
            <div className="thread-item-age">
              {dateText} {Resources.Ago.toLowerCase()}
            </div>
          </span>
        </div>
      );
    }

    const isApprovalRequestTask = !isEmpty(entry.asJson) && !isEmpty(entry.asJson.approvalRequestTasks);
    const titleNote = isApprovalRequestTask
      ? Resources.SentApprovalTask(posterName, recipient)
      : Resources.ApprovalRequestText(posterName, recipient);

    let sentLink = viewOriginal || null;
    if (isApprovalRequestTask) {
      sentLink = (
        <span
          className="clickable forward-color ml-3"
          onClick={() =>
            this.props.history.push(
              this.props.location.pathname.replace(params.selectedItemId, entry.asJson.approvalRequestTasks[0])
            )
          }
        >
          {Resources.ViewTask}
        </span>
      );
    }

    return (
      <div className="conversation-thread-item approval-request-item">
        <div>
          <div>
            <div>
              <b>
                <span
                  dangerouslySetInnerHTML={{
                    __html: titleNote
                  }}
                />
              </b>
              {sentLink}
              {showCancel && (
                <button
                  className="button-cancel"
                  onClick={() => {
                    this.props.displayModal("cancelApprovalRequest", {
                      companyId,
                      ledgerHash,
                      subject,
                      recipient,
                      to: this.props.entry.asJson.to,
                      from: this.props.entry.asJson.from
                    });
                  }}
                >
                  {Resources.Cancel}
                </button>
              )}

              <span className="float-right" style={{ textAlign: "right" }}>
                <div className="thread-item-age">
                  {dateText} {Resources.Ago.toLowerCase()}
                </div>
                {isFirstEntry && <div>{`${assignedTo}, ${statusName}`}</div>}
              </span>
            </div>

            <div>{from}</div>
            <div>{to}</div>
            <div>{cc}</div>
            <div>
              <br />
              <div dangerouslySetInnerHTML={{ __html: emailBody }} />
            </div>
          </div>
          {isEmpty(attachments) === false && (
            <div>
              <br />
              {attachments.map(att => {
                return (
                  <Attachment
                    attachment={att}
                    key={att.attachmentId}
                    showIcon={true}
                    name={att.fileName}
                    size={att.sizeBytes}
                  />
                );
              })}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default connect(null, dispatchToProps)(ApprovalRequestThreadItem);
