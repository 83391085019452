import { actions } from "./sequences-actions";
import { isEmpty } from "../lib/utils";

export const defaultSequencesState = {
  isFetchingSequences: false,
  fetchingSequencesFailed: false,
  fetchedSequences: false,
  sequences: [],
  sequenceMaps: {},
  sequencesSortBy: "modifiedDate",
  sequencesSortDirection: "desc",
  companyId: null,

  isFetchingSequenceEvents: false,
  fetchingSequenceEventsFailed: false,
  fetchedSequenceEvents: false,
  sequenceEvents: [],
  activeSequenceCount: null,
  automatedTasksTodayCount: null,
  automatedTasksMonthCount: null,
  manualTasksTodayCount: null,
  manualTasksMonthCount: null,
  sequenceEventsNextLink: null,
  sequenceEventsCount: null,
  sequenceEventsSortBy: "Date",
  sequenceEventsSortDirection: "desc",

  isFetchingSequenceSamples: false,
  fetchingSequenceSamplesFailed: false,
  fetchedSequenceSamples: false,
  sequenceSamples: [],
  sequenceSampleMaps: {},

  isFetchingTriggers: false,
  fetchingTriggersFailed: false,
  fetchedTriggers: false,
  triggers: [],
  triggerMaps: {},

  isFetchingSequenceActions: false,
  fetchingSequenceActionsFailed: false,
  fetchedSequenceActions: false,
  sequenceActions: [],
  sequenceActionMaps: {}
};

function sequencesReducer(state = defaultSequencesState, action) {
  if (window.logToConsole) console.log(action);
  switch (action.type) {
    case actions.FETCHING_SEQUENCES:
      return {
        ...state,
        isFetchingSequences: true,
        sequences: []
      };
    case actions.FETCHED_SEQUENCES:
      const sequenceMaps = {};
      (action.sequences || []).forEach(sequence => (sequenceMaps[sequence.customerSequenceId] = sequence));
      return {
        ...state,
        isFetchingSequences: false,
        fetchingSequencesFailed: false,
        fetchedSequences: true,
        sequences: action.sequences,
        sequenceMaps
      };
    case actions.FETCH_SEQUENCES_FAILED:
      return { ...state, isFetchingSequences: false, fetchingSequencesFailed: true, fetchedSequences: false };

    case actions.POSTING_SEQUENCE: {
      const nextState = { ...state, isPostingSequence: true };
      if (!isEmpty(action.sequence.customerSequenceId) && !isEmpty(action.sequence.isActive)) {
        const nextSequences = state.sequences.map(sequence => {
          if (sequence.customerSequenceId === action.sequence.customerSequenceId) {
            nextState.sequenceMaps[sequence.customerSequenceId] = { ...sequence, isActive: action.sequence.isActive };
            return { ...sequence, isActive: action.sequence.isActive };
          }
          return sequence;
        });
        nextState.sequences = nextSequences;
      }
      return nextState;
    }
    case actions.POSTED_SEQUENCE:
      return { ...state, isPostingSequence: false };
    case actions.POST_SEQUENCE_FAILED: {
      const nextState = { ...state, isPostingSequence: false };
      if (!isEmpty(action.sequence.customerSequenceId) && !isEmpty(action.sequence.isActive)) {
        const nextSequences = state.sequences.map(sequence => {
          if (sequence.customerSequenceId === action.sequence.customerSequenceId) {
            nextState.sequenceMaps[sequence.customerSequenceId] = { ...sequence, isActive: !action.sequence.isActive };
            return { ...sequence, isActive: !action.sequence.isActive };
          }
          return sequence;
        });
        nextState.sequences = nextSequences;
      }
      return nextState;
    }

    case actions.FETCHING_SEQUENCE_EVENTS: {
      const nextState = {
        ...state,
        isFetchingSequenceEvents: true
      };

      if (action.isNextLink !== true) {
        nextState.sequenceEvents = [];
      }

      return nextState;
    }
    case actions.FETCHED_SEQUENCE_EVENTS: {
      const { history, ...rest } = action.eventData;
      return {
        ...state,
        isFetchingSequenceEvents: false,
        fetchingSequenceEventsFailed: false,
        fetchedSequenceEvents: true,
        sequenceEventsNextLink: history.nextLink,
        sequenceEvents: history.value,
        sequenceEventsCount: history.count,
        ...rest
      };
    }
    case actions.FETCH_SEQUENCE_EVENTS_FAILED:
      return {
        ...state,
        isFetchingSequenceEvents: false,
        fetchingSequenceEventsFailed: true,
        fetchedSequenceEvents: false
      };

    case actions.FETCHING_SEQUENCE_SAMPLES:
      return {
        ...state,
        isFetchingSequenceSamples: true
      };
    case actions.FETCHED_SEQUENCE_SAMPLES:
      const sequenceSampleMaps = {};
      (action.samples || []).forEach(sample => (sequenceSampleMaps[sample.customerSequenceId] = sample));
      return {
        ...state,
        isFetchingSequenceSamples: false,
        fetchingSequenceSamplesFailed: false,
        fetchedSequenceSamples: true,
        sequenceSamples: action.samples,
        sequenceSampleMaps
      };
    case actions.FETCH_SEQUENCE_SAMPLES_FAILED:
      return {
        ...state,
        isFetchingSequenceSamples: false,
        fetchingSequenceSamplesFailed: true,
        fetchedSequenceSamples: false
      };

    case actions.FETCHING_TRIGGERS:
      return {
        ...state,
        isFetchingTriggers: true
      };
    case actions.FETCHED_TRIGGERS:
      const triggerMaps = {};
      action.triggers.forEach(trigger => (triggerMaps[trigger.triggerID] = trigger));

      return {
        ...state,
        isFetchingTriggers: false,
        fetchingTriggersFailed: false,
        fetchedTriggers: true,
        triggers: action.triggers,
        triggerMaps
      };
    case actions.FETCH_TRIGGERS_FAILED:
      return {
        ...state,
        isFetchingTriggers: false,
        fetchingTriggersFailed: true,
        fetchedTriggers: false
      };

    case actions.FETCHING_SEQUENCE_ACTIONS:
      return {
        ...state,
        isFetchingSequenceActions: true
      };
    case actions.FETCHED_SEQUENCE_ACTIONS:
      const sequenceActionMaps = {};
      action.sequenceActions.forEach(
        sequenceAction => (sequenceActionMaps[sequenceAction.activityID] = sequenceAction)
      );

      return {
        ...state,
        isFetchingSequenceActions: false,
        fetchingSequenceActionsFailed: false,
        fetchedSequenceActions: true,
        sequenceActions: action.sequenceActions,
        sequenceActionMaps
      };
    case actions.FETCH_SEQUENCE_ACTIONS_FAILED:
      return {
        ...state,
        isFetchingSequenceActions: false,
        fetchingSequenceActionsFailed: true,
        fetchedSequenceActions: false
      };

    case actions.SELECT_COMPANY:
      if (state.companyId === null) {
        return {
          ...state,
          companyId: action.companyId
        };
      } else if (action.companyId !== state.companyId) {
        return {
          ...defaultSequencesState,
          companyId: action.companyId
        };
      }

      return state;

    default:
      return state;
  }
}

export default sequencesReducer;
