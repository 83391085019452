import React, { useState } from "react";

import { isEmpty } from "../../lib/utils";

import { NavLink, useHistory } from "react-router-dom";
import IconAngleDown from "./icons/iconAngleDown";
import IconAngleRight from "./icons/iconAngleRight";
import { useEffect } from "react";

export default function ExpandableNavLink(props) {
  const {
    to,
    children,
    icon,
    description,
    badgeCount,
    badgeType = "alert",
    isActive = () => false,
    startExpanded
  } = props;

  const [expanded, setExpanded] = useState(startExpanded || null);
  useEffect(() => {
    if (expanded === null) {
      setExpanded(isActive());
    }
  }, [expanded, isActive]);

  let history = useHistory();
  function handleExpandClick() {
    if (!isActive()) {
      if (!expanded) {
        setExpanded(!expanded);
      }
      history.push(to);
    } else {
      setExpanded(!expanded);
    }
  }
  let linkContent = (
    <React.Fragment>
      <span className="view-selector-nav-item__icon">{icon}</span>
      <span className="view-selector-nav-item__content">{description}</span>
      {!isEmpty(badgeCount) && badgeCount !== 0 && (
        <span className={`view-selector-nav-item__badge view-selector-nav-item__badge--${badgeType}`}>
          {badgeCount}
        </span>
      )}
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <div
        className={`expandable-nav-link view-selector-nav-item ${
          props.isActive && props.isActive() && !expanded ? "active" : ""
        }`}
      >
        {expanded ? (
          <div onClick={handleExpandClick} className="expandable-nav-link__link">
            {linkContent}
          </div>
        ) : (
          <NavLink
            className={`expandable-nav-link__link`}
            to={to}
            isActive={() => false}
            key={description}
            onClick={() => handleExpandClick()}
          >
            {linkContent}
          </NavLink>
        )}

        <div className="expandable-nav-link__toggle" onClick={handleExpandClick}>
          {expanded ? <IconAngleDown height={8} /> : <IconAngleRight height={14} />}
        </div>
      </div>
      {expanded && children}
    </React.Fragment>
  );
}
