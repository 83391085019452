import React, { Component } from "react";
import Resources from "../lib/resources";

class SetupSelectInbox extends Component {
  render() {
    return (
      <div>
        <h3>{Resources.SetupInboxToConnect}</h3>
        <hr />
        <button onClick={() => this.props.progressStage("CONNECT_EMAIL", "AP")} className="w-35 mt-2 d-block">
          {Resources.AccountsPayable}
        </button>
        <button onClick={() => this.props.progressStage("CONNECT_EMAIL", "AR")} className="w-35 mt-3 mb-5 d-block">
          {Resources.AccountsReceivable}
        </button>
      </div>
    );
  }
}

export default SetupSelectInbox;
