import React, { Component } from "react";
import { connect } from "react-redux";
import Resources from "../lib/resources";

import Modal from "react-modal";

import { dispatchToProps } from "../store/modal-actions";

class ConfirmModal extends Component {
  render() {
    return (
      <Modal
        isOpen={this.props.modal.showModal}
        onRequestClose={() => this.props.hideModal()}
        contentLabel="Example Modal"
        className="custom-modal"
        overlayClassName="custom-modal-overlay"
      >
        <div className="custom-modal-header">{this.props.headerContent || Resources.Confirm}</div>
        <div className="custom-modal-divider" />
        <div className="custom-modal-content">
          <div className="mt-4">{this.props.confirmMessage}</div>
          <div className="flex-row modal-buttons mt-4">
            <button className="button-cancel float-left mr-1" onClick={this.props.hideModal}>
              {Resources.Cancel}
            </button>
            <button className="btn button-green" onClick={this.props.onConfirm}>
              {this.props.confirmButtonText || Resources.Confirm}
            </button>
          </div>
        </div>
      </Modal>
    );
  }
}

const storeToProps = store => {
  return {
    modal: store.modal
  };
};

export default connect(
  storeToProps,
  dispatchToProps
)(ConfirmModal);
