const icons = {
  activeConversation: "fas fa-circle",
  angleDown: "fal fa-angle-down",
  angleLeft: "fal fa-angle-left",
  angleRight: "fal fa-angle-right",
  angleUp: "fal fa-angle-up",
  approvers: "fa fa-user-check",
  arrowLeft: "fal fa-arrow-left",
  arrowRight: "fal fa-arrow-right",
  arrowUp: "fal fa-arrow-up",
  assigned: "fal fa-users",
  attachment: "fal fa-paperclip",
  bell: "far fa-bell",
  calendar: "fal fa-calendar-alt",
  check: "fal fa-check",
  checkSquare: "fal fa-check-square",
  chevronDoubleRight: "fal fa-chevron-double-right",
  clipboardCheck: "fal fa-clipboard-check",
  clipboardList: "fal fa-clipboard-list",
  clock: "far fa-clock",
  close: "fal fa-times",
  closed: "fal fa-check",
  completed: "fal fa-check-circle",
  compose: "fal fa-envelope",
  contacts: "fal fa-address-book",
  copy: "fal fa-copy",
  customers: "fal fa-money-bill",
  document: "fal fa-folder",
  download: "fas fa-download",
  draft: "fal fa-drafting-compass",
  dropdown: "fas fa-angle-down",
  elipsisH: "far fa-ellipsis-h",
  emailSettings: "fal fa-envelope",
  elipsisV: "far fa-ellipsis-v",
  exclamationCircle: "fas fa-exclamation-circle",
  eye: "far fa-eye",
  fileAlt: "far fa-file-alt",
  fileImage: "far fa-file-image",
  fileInvoiceDollar: "fal fa-file-invoice-dollar",
  filePdf: "far fa-file-pdf",
  fileWord: "far fa-file-word",
  forward: "fal fa-share",
  genericCompany: "fal fa-building",
  google: "fab fa-google",
  hourglass: "fal fa-hourglass",
  list: "fal fa-list",
  myCompany: "fas fa-building",
  manage: "fal fa-toolbox",
  mine: "fal fa-user",
  minus: "far fa-minus",
  note: "fal fa-sticky-note",
  notifications: "fal fa-mail-bulk",
  packages: "fas fa-project-diagram",
  pencil: "fas fa-pencil",
  pendingApproval: "fal fa-comment-alt-check",
  plus: "fas fa-plus-circle",
  profile: "fal fa-id-badge",
  questionCircle: "fal fa-question-circle",
  reply: "fal fa-reply",
  reports: "fal fa-chart-pie",
  save: "fal fa-save",
  savedReplies: "fab fa-replyd",
  search: "fas fa-search",
  setup: "fal fa-cog",
  signature: "fal fa-id-card",
  spam: "fal fa-ban",
  spinner: "fas fa-spinner",
  statusPicker: "fal fa-flag",
  sync: "fal fa-sync",
  template: "fal fa-file-alt",
  newTemplate: "fal fa-file-plus",
  task: "fal fa-list",
  times: "fal fa-times",
  toggleoff: "fal fa-toggle-off",
  toggleon: "fal fa-toggle-on",
  trash: "fal fa-trash-alt",
  unassigned: "fal fa-arrow-to-bottom",
  user: "fal fa-user",
  userFriendsSolid: "fas fa-user-friends",
  userFriends: "far fa-user-friends",
  users: "fal fa-users",
  usersAndPermissions: "fal fa-users-cog",
  vendors: "fal fa-file-invoice-dollar",
  visibility: "fal fa-eye",
  workflows: "fal fa-repeat-alt",
  warningTriangle: "fas fa-exclamation-triangle",
  workspaces: "fas fa-mail-bulk"
};

export default icons;
