import React, { Component } from "react";

import IconAngleDown from "./iconAngleDown";

class IconAngleRight extends Component {
  render() {
    let { className, height, strokeWidth, style } = this.props;
    return (
      <IconAngleDown
        className={className}
        width={height}
        strokeWidth={strokeWidth}
        style={{ ...style, transform: "rotate(-90deg)" }}
      />
    );
  }
}

IconAngleRight.defaultProps = {
  height: 24
};

export default IconAngleRight;
