import data from "../lib/data";
import axios from "../lib/axios";
import _ from "lodash";
import { isEmpty, find, compareDates, includes } from "../lib/utils";
import { handlePromiseError } from "./error-actions";
import { actions as GeneralActions } from "./general-actions";

export const actions = {
  ...GeneralActions,
  ...{
    GETTING_ALL_SUPPORTED_CONNECTORS: "GETTING_ALL_SUPPORTED_CONNECTORS",
    GOT_ALL_SUPPORTED_CONNECTORS: "GOT_ALL_SUPPORTED_CONNECTORS",
    GET_ALL_SUPPORTED_CONNECTORS_FAILED: "GET_ALL_SUPPORTED_CONNECTORS_FAILED",

    GETTING_CONNECTOR_VERSION_DATA: "GETTING_CONNECTOR_VERSION_DATA",
    GOT_CONNECTOR_VERSION_DATA: "GOT_CONNECTOR_VERSION_DATA",
    GET_CONNECTOR_VERSION_DATA_FAILED: "GET_CONNECTOR_VERSION_DATA_FAILED",

    SETTING_DEFAULT_CONNECTOR_PERMISSIONS: "SETTING_DEFAULT_CONNECTOR_PERMISSIONS",
    SET_DEFAULT_CONNECTOR_PERMISSIONS: "SET_DEFAULT_CONNECTOR_PERMISSIONS",
    SET_DEFAULT_CONNECTOR_PERMISSIONS_FAILED: "SET_DEFAULT_CONNECTOR_PERMISSIONS_FAILED",

    GETTING_COMPANY_GRANTED_PERMISSIONS: "GETTING_COMPANY_GRANTED_PERMISSIONS",
    GOT_COMPANY_GRANTED_PERMISSIONS: "GOT_COMPANY_GRANTED_PERMISSIONS",
    GET_COMPANY_GRANTED_PERMISSIONS_FAILED: "GET_COMPANY_GRANTED_PERMISSIONS_FAILED",

    TOGGLE_CONNECTOR_REGISTRATION_MODAL: "TOGGLE_CONNECTOR_REGISTRATION_MODAL",
    START_CUSTOM_CONNECTOR_REGISTRATION: "START_CUSTOM_CONNECTOR_REGISTRATION",
    END_CUSTOM_CONNECTOR_REGISTRATION: "END_CUSTOM_CONNECTOR_REGISTRATION",

    GETTING_CONNECTOR_SPECIFIC_REQUEST: "GETTING_CONNECTOR_SPECIFIC_REQUEST",
    GOT_CONNECTOR_SPECIFIC_REQUEST: "GOT_CONNECTOR_SPECIFIC_REQUEST",
    GET_CONNECTOR_SPECIFIC_REQUEST_FAILED: "GET_CONNECTOR_SPECIFIC_REQUEST_FAILED",

    FETCHING_CONNECTOR_REQUESTS: "FETCHING_CONNECTOR_REQUESTS",
    FETCHED_CONNECTOR_REQUESTS: "FETCHED_CONNECTOR_REQUESTS",
    FETCH_CONNECTOR_REQUESTS_FAILED: "FETCH_CONNECTOR_REQUESTS_FAILED",

    FETCHING_CONNECTOR_RESOURCES: "FETCHING_CONNECTOR_RESOURCES",
    FETCHED_CONNECTOR_RESOURCES: "FETCHED_CONNECTOR_RESOURCES",
    FETCH_CONNECTOR_RESOURCES_FAILED: "FETCH_CONNECTOR_RESOURCES_FAILED",

    CREATING_CONNECTOR_REQUEST: "CREATING_CONNECTOR_REQUEST",
    CREATED_CONNECTOR_REQUEST: "CREATED_CONNECTOR_REQUEST",
    CREATE_CONNECTOR_REQUEST_FAILED: "CREATE_CONNECTOR_REQUEST_FAILED",

    FETCHING_COMPANY_CONNECTORS: "FETCHING_COMPANY_CONNECTORS",
    FETCHED_COMPANY_CONNECTORS: "FETCHED_COMPANY_CONNECTORS",
    FETCH_COMPANY_CONNECTORS_FAILED: "FETCH_COMPANY_CONNECTORS_FAILED",

    FETCHING_CONNECTOR_ACTIONS: "FETCHING_CONNECTOR_ACTIONS",
    FETCHED_CONNECTOR_ACTIONS: "FETCHED_CONNECTOR_ACTIONS",
    FETCH_CONNECTOR_ACTIONS_FAILED: "FETCH_CONNECTOR_ACTIONS_FAILED",

    DISCONNECTING_CONNECTOR: "DISCONNECTING_CONNECTOR",
    DISCONNECTED_CONNECTOR: "DISCONNECTED_CONNECTOR",
    DISCONNECT_CONNECTOR_FAILED: "DISCONNECT_CONNECTOR_FAILED",

    DELETING_COMPANY_PERMISSIONS: "DELETING_COMPANY_PERMISSIONS",
    DELETED_COMPANY_PERMISSIONS: "DELETED_COMPANY_PERMISSIONS",
    DELETE_COMPANY_PERMISSIONS_FAILED: "DELETE_COMPANY_PERMISSIONS_FAILED",

    DELETING_CONNECTOR_CONFIGURATION: "DELETING_CONNECTOR_CONFIGURATION",
    DELETED_CONNECTOR_CONFIGURATION: "DELETED_CONNECTOR_CONFIGURATION",
    DELETE_CONNECTOR_CONFIGURATION_FAILED: "DELETE_CONNECTOR_CONFIGURATION_FAILED",

    FETCHING_CONNECTOR_CONFIGURATION: "FETCHING_CONNECTOR_CONFIGURATION",
    FETCHED_CONNECTOR_CONFIGURATION: "FETCHED_CONNECTOR_CONFIGURATION",
    FETCH_CONNECTOR_CONFIGURATION_FAILED: "FETCH_CONNECTOR_CONFIGURATION_FAILED",

    UPDATING_CONNECTOR_CONFIGURATION: "UPDATING_CONNECTOR_CONFIGURATION",
    UPDATED_CONNECTOR_CONFIGURATION: "UPDATED_CONNECTOR_CONFIGURATION",
    UPDATE_CONNECTOR_CONFIGURATION_FAILED: "UPDATE_CONNECTOR_CONFIGURATION_FAILED",

    PUTTING_CONNECTOR_CONFIGURATION: "PUTTING_CONNECTOR_CONFIGURATION",
    PUT_CONNECTOR_CONFIGURATION: "PUT_CONNECTOR_CONFIGURATION",
    FAILED_PUT_CONNECTOR_CONFIGURATION: "FAILED_PUT_CONNECTOR_CONFIGURATION",

    PUTTING_CONNECTOR_PERMISSIONS: "PUTTING_CONNECTOR_PERMISSIONS",
    PUT_CONNECTOR_PERMISSIONS: "PUT_CONNECTOR_PERMISSIONS",
    FAILED_PUT_CONNECTOR_PERMISSIONS: "FAILED_PUT_CONNECTOR_PERMISSIONS",

    CLEAR_SPECIFIC_CONNECTOR_DATA: "CLEAR_SPECIFIC_CONNECTOR_DATA"
  }
};

const fetchAllConnectors = () => (dispatch, getState) => {
  let state = getState().connector;
  if (state.isGettingAllSupportedConnectors) return;
  dispatch({ type: actions.GETTING_ALL_SUPPORTED_CONNECTORS });
  data
    .get("v1/api/connector")
    .then(response => {
      dispatch({
        type: actions.GOT_ALL_SUPPORTED_CONNECTORS,
        allSupportedConnectors: response.data
      });
    })
    .catch(error => {
      dispatch({ type: actions.GET_ALL_SUPPORTED_CONNECTORS_FAILED });
      handlePromiseError(error, "TODO: Getting connector list failed.", "connector list");
    });
};

const fetchCompanyConnectors = companyId => (dispatch, getState) => {
  if (getState().connector.isFetchingCompanyConnectors) {
    return;
  }
  dispatch({ type: actions.FETCHING_COMPANY_CONNECTORS });
  data
    .get(`v1/api/permission/connectors/for/${companyId}`)
    .then(response => {
      dispatch({
        type: actions.FETCHED_COMPANY_CONNECTORS,
        companyConnectors: response.data,
        companyHasERPConnector: response.data.some(connector => connector.isInternal === false)
      });
    })
    .catch(error => {
      dispatch({ type: actions.FETCH_COMPANY_CONNECTORS_FAILED });
      handlePromiseError(error, "TODO: Getting company connector list failed.", "company connector list");
    });
};

const getPermittedERPConnector = companyId => (dispatch, getState) => {
  const state = getState().connector;
  if (state.fetchedCompanyConnectors === false && state.fetchingCompanyConnectorsFailed === false) {
    dispatch(fetchCompanyConnectors(companyId));
    return null;
  } else {
    return find(state.companyConnectors, c => {
      return c.isHidden !== true;
    });
  }
};

const getConnectorFromName = connectorName => (dispatch, getState) => {
  const state = getState().connector;
  let connector = find(state.allSupportedConnectors, connector => connector.connectorName === connectorName);
  return connector || null;
};

const getConnectorIdFromVersion = connectorVersionId => (dispatch, getState) => {
  const store = getState().connector;
  for (let key in store.connectorVersionData) {
    const cVersion = store.connectorVersionData[key][0];
    if (cVersion.connectorVersionId === connectorVersionId) {
      return cVersion.connectorId;
    }
  }
};

const getRegisteredERPConnectors = companyId => (dispatch, getState) => {
  const store = getState().connector;
  if (store.fetchedCompanyConnectors === false && store.fetchingCompanyConnectorsFailed === false) {
    dispatch(fetchCompanyConnectors(companyId));
    return [];
  } else {
    return store.companyConnectors.filter(connector => connector.isInternal !== true) || [];
  }
};

const hasRegisteredERPConnectors = companyId => (dispatch, getState) => {
  const store = getState().connector;
  if (store.fetchedCompanyConnectors === false && store.fetchingCompanyConnectorsFailed === false) {
    return null;
  } else if (store.fetchedCompanyConnectors === true) {
    return !isEmpty(dispatch(getRegisteredERPConnectors(companyId)));
  } else {
    return false;
  }
};

const fetchConnectorRequests = () => (dispatch, getState) => {
  if (getState().connector.isFetchingConnectorRequests) {
    return;
  }
  dispatch({ type: actions.FETCHING_CONNECTOR_REQUESTS });
  data
    .get("v1/api/connector/requests/all")
    .then(response => {
      dispatch({
        type: actions.FETCHED_CONNECTOR_REQUESTS,
        connectorRequests: response.data
      });
    })
    .catch(rejection => {
      dispatch({ type: actions.FETCH_CONNECTOR_REQUESTS_FAILED });
    });
};

const fetchConnectorResources = (companyId, connectorVersionId) => (dispatch, getState) => {
  if (getState().connector.isFetchingConnectorResources) {
    return;
  }
  dispatch({ type: actions.FETCHING_CONNECTOR_RESOURCES });
  data
    .get(`v1/api/connector/config/${companyId}/${connectorVersionId}/resources`)
    .then(response => {
      dispatch({
        type: actions.FETCHED_CONNECTOR_RESOURCES,
        connectorResources: response.data,
        connectorVersionId
      });
    })
    .catch(rejection => {
      dispatch({ type: actions.FETCH_CONNECTOR_RESOURCES_FAILED });
    });
};

const getConnectorResources = (companyId, connectorVersionId) => (dispatch, getState) => {
  const store = getState().connector;
  if (store.connectorResourcesIsFetched[connectorVersionId] !== true) {
    dispatch(fetchConnectorResources(companyId, connectorVersionId));
    return null;
  } else {
    return store.connectorResources[connectorVersionId];
  }
};

const fetchConnectorActions = connectorId => (dispatch, getState) => {
  if (getState().connector.isFetchingConnectorActions) {
    return;
  }
  dispatch({ type: actions.FETCHING_CONNECTOR_ACTIONS });
  data
    .get(`v1/api/connector/${connectorId}/action`)
    .then(response => {
      dispatch({
        type: actions.FETCHED_CONNECTOR_ACTIONS,
        connectorId,
        connectorActions: response.data
      });
    })
    .catch(rejection => {
      handlePromiseError(
        rejection,
        "TODO: Getting connector actions failed. Please refresh the page and try again.",
        "connector actions"
      );
      dispatch({ type: actions.FETCH_CONNECTOR_ACTIONS_FAILED, connectorId });
    });
};

const getConnectorActionsByResourceIds = (resourceIds, connectorId) => (dispatch, getState) => {
  let connectorActions = getState().connector.connectorActions[connectorId] || [];
  let filtered = connectorActions.filter(action => {
    return resourceIds.indexOf(action.resourceId) > -1;
  });
  return filtered;
};

const createConnectorRequest = payload => (dispatch, getState) => {
  if (getState().connector.isCreatingConnectorRequest) {
    return;
  }
  dispatch({ type: actions.CREATING_CONNECTOR_REQUEST });
  data
    .put("v1/api/connector/request", payload)
    .then(response => {
      dispatch({
        type: actions.CREATED_CONNECTOR_REQUEST,
        connectorName: response.data.connectorName
      });
    })
    .catch(rejection => {
      handlePromiseError(
        rejection,
        "TODO: Voting for the accounting package failed. Please try again.",
        "connector request"
      );
      dispatch({ type: actions.CREATE_CONNECTOR_REQUEST_FAILED });
    });
};

const getSpecificSupportedConnector = connectorName => (dispatch, getState) => {
  let state = getState().connector;
  return (
    find(state.allSupportedConnectors, connectorData => {
      return connectorData.connectorName === connectorName;
    }) || "SUPPORTED_CONNECTOR_NOT_FOUND"
  );
};

const fetchConnectorVersionData = connectorId => (dispatch, getState) => {
  if (getState().connector.isGettingConnectorVersionData[connectorId]) {
    return;
  }
  dispatch({ type: actions.GETTING_CONNECTOR_VERSION_DATA, connectorId });
  return data
    .get(`v1/api/connector/version/${connectorId}`)
    .then(response => {
      dispatch({
        type: actions.GOT_CONNECTOR_VERSION_DATA,
        connectorId,
        connectorVersionData: response.data
      });
    })
    .catch(() => {
      dispatch({
        type: actions.GET_CONNECTOR_VERSION_DATA_FAILED,
        connectorId
      });
    });
};

const getLatestConnectorVersion = connectorId => (dispatch, getState) => {
  const state = getState().connector;
  if (state.connectorVersionDataIsFetched[connectorId] === false) {
    dispatch(fetchConnectorVersionData(connectorId));
    return null;
  }
  const connectorVersions = [...(state.connectorVersionData[connectorId] || [])];
  return (
    connectorVersions.sort((a, b) => {
      return compareDates(a.createDate, b.createDate);
    })[0] || {}
  );
};

const disconnectConnector = (companyId, connectorCompanyId, connectorVersionId) => (dispatch, getState) => {
  if (getState().connector.isDisconnectingConnector) {
    return;
  }

  dispatch({ type: actions.DISCONNECTING_CONNECTOR });

  const promises = [
    dispatch(deleteCompanyPermissions(companyId, connectorCompanyId)),
    dispatch(deleteCompanyPermissions(connectorCompanyId, companyId)),
    dispatch(deleteConnectorConfiguration(companyId, connectorVersionId))
  ];

  return Promise.all(promises)
    .then(response => {
      dispatch({ type: actions.DISCONNECTED_CONNECTOR });
      return true;
    })
    .catch(rejection => {
      dispatch({ type: actions.DISCONNECT_CONNECTOR_FAILED });
      throw rejection;
    });
};

const deleteCompanyPermissions = (onBehalfOfCompanyId, permittedCompanyId) => (dispatch, getState) => {
  dispatch({ type: actions.DELETING_COMPANY_PERMISSIONS });
  return data
    .delete(`v1/api/permission/grants/from/${onBehalfOfCompanyId}/to/${permittedCompanyId}`)
    .then(response => {
      dispatch({ type: actions.DELETED_COMPANY_PERMISSIONS });
    })
    .catch(rejection => {
      dispatch({ type: actions.DELETE_COMPANY_PERMISSIONS_FAILED });
    });
};

const putConnectorConfiguration = (companyId, connectorVersionId, connectorConfig) => (dispatch, getState) => {
  if (getState().connector.isUpdatingConnectorConfig) {
    return;
  }
  dispatch({ type: actions.PUTTING_CONNECTOR_CONFIGURATION });
  return data
    .post(`v1/api/connector/config/${companyId}/${connectorVersionId}`, connectorConfig)
    .then(response => {
      dispatch({
        type: actions.PUT_CONNECTOR_CONFIGURATION,
        connectorVersionId,
        connectorConfig: response.data
      });
      return response;
    })
    .catch(rejection => {
      dispatch({ type: actions.FAILED_PUT_CONNECTOR_CONFIGURATION });
    });
};

const updateConnectorConfiguration = (companyId, connectorVersionId, update) => (dispatch, getState) => {
  if (getState().connector.isUpdatingConnectorConfig) {
    return;
  }
  dispatch({ type: actions.UPDATING_CONNECTOR_CONFIGURATION });
  return data
    .post(`v1/api/connector/config/${companyId}/${connectorVersionId}`, update)
    .then(response => {
      dispatch({
        type: actions.UPDATED_CONNECTOR_CONFIGURATION,
        connectorVersionId,
        connectorConfig: response.data
      });
      return response;
    })
    .catch(rejection => {
      dispatch({ type: actions.UPDATE_CONNECTOR_CONFIGURATION_FAILED });
      throw rejection;
    });
};

const fetchConnectorConfiguration = (companyId, connectorVersionId) => (dispatch, getState) => {
  if (isEmpty(companyId) || isEmpty(connectorVersionId)) {
    return null;
  }
  if (includes(getState().connector.fetchingConnectorConfiguration, connectorVersionId)) {
    return;
  }
  dispatch({ type: actions.FETCHING_CONNECTOR_CONFIGURATION, connectorVersionId: connectorVersionId });
  return data
    .get(`v1/api/connector/config/${companyId}/${connectorVersionId}`)
    .then(response => {
      dispatch({
        type: actions.FETCHED_CONNECTOR_CONFIGURATION,
        connectorVersionId,
        connectorConfig: response.data
      });
    })
    .catch(rejection => {
      dispatch({ type: actions.FETCH_CONNECTOR_CONFIGURATION_FAILED });
    });
};

const deleteConnectorConfiguration = (companyId, connectorVersionId) => (dispatch, getState) => {
  dispatch({ type: actions.DELETING_CONNECTOR_CONFIGURATION });
  return data
    .delete(`v1/api/connector/config/${companyId}/${connectorVersionId}`)
    .then(response => {
      return dispatch({
        type: actions.DELETED_CONNECTOR_CONFIGURATION,
        connectorVersionId
      });
    })
    .catch(rejection => {
      dispatch({ type: actions.DELETE_CONNECTOR_CONFIGURATION_FAILED });
      throw rejection;
    });
};

const setConnectorPermissions = (companyId, permissions) => (dispatch, getState) => {
  dispatch({ type: actions.PUTTING_CONNECTOR_PERMISSIONS });
  return data
    .put(`v1/api/permission/grants/from/${companyId}`, permissions)
    .then(response => {
      dispatch({ type: actions.PUT_CONNECTOR_PERMISSIONS });
      return response;
    })
    .catch(exception => {
      dispatch({ type: actions.FAILED_PUT_CONNECTOR_PERMISSIONS });
      throw exception;
    });
};

const setDefaultConnectorPermissions = (companyId, connectorCompanyId, defaultPermissions) => (dispatch, getState) => {
  if (getState().isSettingDefaultConnectorPermissions) {
    return;
  }

  dispatch({ type: actions.SETTING_DEFAULT_CONNECTOR_PERMISSIONS });

  const companyPermissions = [];
  const connectorPermissions = [];

  defaultPermissions.forEach(a => {
    const action = { resourceId: a.resourceId, actionId: a.actionId };

    if (a.permittedSource === "connector") {
      action.permittedCompanyId = connectorCompanyId;
      connectorPermissions.push(action);
    } else if (a.permittedSource === "company") {
      action.permittedCompanyId = companyId;
      companyPermissions.push(action);
    }
  });

  const promises = [
    data.put(`v1/api/permission/grants/from/${companyId}`, connectorPermissions),
    data.put(`v1/api/permission/grants/from/${connectorCompanyId}`, companyPermissions)
  ];

  return Promise.all(promises)
    .then(response => {
      dispatch({ type: actions.SET_DEFAULT_CONNECTOR_PERMISSIONS });
    })
    .catch(rejection => {
      dispatch({ type: actions.SET_DEFAULT_CONNECTOR_PERMISSIONS_FAILED });
    });
};

const fetchCompanyGrantedPermissions = companyId => (dispatch, getState) => {
  let state = getState().connector;
  if (state.isGettingCompanyGrantedPermissions) return;
  dispatch({ type: actions.GETTING_COMPANY_GRANTED_PERMISSIONS });
  data
    .get(`v1/api/permission/grants/from/${companyId}`)
    .then(response => {
      let permissionKeys = Object.keys(response.data || {});
      let permissionsObj = response.data || {};
      let permissions = permissionKeys.map(key => {
        return permissionsObj[key];
      });

      dispatch({
        type: actions.GOT_COMPANY_GRANTED_PERMISSIONS,
        companyGrantedPermissions: permissions
      });
    })
    .catch(() => {
      dispatch({ type: actions.GET_COMPANY_GRANTED_PERMISSIONS_FAILED });
    });
};

let CONNECTOR_SPECIFIC_REQUEST_PROMISE = null;
const specificConnecterReq = (reqType, endpoint, reqParams, payload, headers) => (dispatch, getState) => {
  let state = getState().connector;

  let currentParams = {
    reqType,
    endpoint
  };

  let isAlreadyRunning = state.currentlyRunningRequests.some(runningParams => {
    return _.isEqual(runningParams, currentParams);
  });
  if (isAlreadyRunning) return CONNECTOR_SPECIFIC_REQUEST_PROMISE;

  dispatch({ type: actions.GETTING_CONNECTOR_SPECIFIC_REQUEST, currentParams });

  if (Array.isArray(reqParams) !== true) reqParams = [reqParams];
  let urlBase = reqParams.join("");

  switch (reqType) {
    case "POST":
      CONNECTOR_SPECIFIC_REQUEST_PROMISE = axios.post(endpoint + urlBase, payload || {}, headers || {});
      break;
    case "GET":
      CONNECTOR_SPECIFIC_REQUEST_PROMISE = axios.get(endpoint + urlBase, headers || {});
      break;
    case "PUT":
      CONNECTOR_SPECIFIC_REQUEST_PROMISE = axios.put(endpoint + urlBase, payload || {}, headers || {});
      break;
    case "DELETE":
      CONNECTOR_SPECIFIC_REQUEST_PROMISE = axios.delete(endpoint + urlBase, headers || {});
      break;
    default:
      break;
  }

  CONNECTOR_SPECIFIC_REQUEST_PROMISE.then(response => {
    dispatch({
      type: actions.GOT_CONNECTOR_SPECIFIC_REQUEST,
      connectorSuccessResponse: response,
      currentParams
    });
    return response;
  }).catch(response => {
    dispatch({
      type: actions.GET_CONNECTOR_SPECIFIC_REQUEST_FAILED,
      connectorFailResponse: response,
      currentParams
    });
    return response;
  });

  return CONNECTOR_SPECIFIC_REQUEST_PROMISE;
};

const getSpecificConnectorReqData = (endpoint, reqType, responseState) => (dispatch, getState) => {
  let state = getState().connector;
  let data;
  if (endpoint && reqType && responseState)
    data = ((state.specificConnectorResponseData[endpoint] || {})[reqType] || {})[responseState] || {};
  if (endpoint && reqType && responseState === undefined)
    data = (state.specificConnectorResponseData[endpoint] || {})[reqType] || {};
  if (endpoint && reqType === undefined && responseState === undefined)
    data = state.specificConnectorResponseData[endpoint] || {};
  if (isEmpty(data)) data = "DATA_NOT_FOUND";
  return data;
};

const clearSpecificConnectorReqData = (endpoint, reqType) => (dispatch, getState) => {
  let state = getState().connector;
  let newSCRD = state.specificConnectorResponseData;

  if (endpoint && reqType) {
    newSCRD[endpoint][reqType] = undefined;
  } else if (reqType === undefined) {
    delete newSCRD[endpoint];
  } else {
    newSCRD = {};
  }

  dispatch({
    type: actions.CLEAR_SPECIFIC_CONNECTOR_DATA,
    specificConnectorResponseData: newSCRD
  });
};

const isSpecificConnectorReqRunning = (endpoint, reqType) => (dispatch, getState) => {
  let params = {
    reqType,
    endpoint
  };

  let state = getState().connector;

  let isAlreadyRunning = state.currentlyRunningRequests.some(runningParams => {
    return _.isEqual(runningParams, params);
  });
  return isAlreadyRunning;
};

export const dispatchToProps = dispatch => ({
  fetchAllConnectors: () => {
    dispatch(fetchAllConnectors());
  },
  fetchCompanyConnectors: companyId => {
    dispatch(fetchCompanyConnectors(companyId));
  },

  getConnectorIdFromVersion: connectorVersionId => {
    return dispatch(getConnectorIdFromVersion(connectorVersionId));
  },
  getConnectorFromName: connectorName => {
    return dispatch(getConnectorFromName(connectorName));
  },

  getPermittedERPConnector: companyId => {
    return dispatch(getPermittedERPConnector(companyId));
  },
  getRegisteredERPConnectors: companyId => {
    return dispatch(getRegisteredERPConnectors(companyId));
  },
  hasRegisteredERPConnectors: companyId => {
    return dispatch(hasRegisteredERPConnectors(companyId));
  },

  fetchConnectorRequests: () => {
    dispatch(fetchConnectorRequests());
  },
  createConnectorRequest: payload => {
    dispatch(createConnectorRequest(payload));
  },

  fetchConnectorResources: (companyId, connectorVersionId) => {
    dispatch(fetchConnectorResources(companyId, connectorVersionId));
  },
  getConnectorResources: (companyId, connectorVersionId) => {
    return dispatch(getConnectorResources(companyId, connectorVersionId));
  },

  fetchConnectorActions: connectorId => {
    dispatch(fetchConnectorActions(connectorId));
  },
  getConnectorActionsByResourceIds: (resourceIds, connectorId) => {
    return dispatch(getConnectorActionsByResourceIds(resourceIds, connectorId));
  },

  setConnectorPermissions: (companyId, permissions) => {
    return dispatch(setConnectorPermissions(companyId, permissions));
  },
  setDefaultConnectorPermissions: (companyId, connectorCompanyId, defaultPermissions) => {
    return dispatch(setDefaultConnectorPermissions(companyId, connectorCompanyId, defaultPermissions));
  },

  deleteCompanyPermissions: (onBehalfOfCompanyId, permittedCompanyId) => {
    return dispatch(deleteCompanyPermissions(onBehalfOfCompanyId, permittedCompanyId));
  },

  fetchConnectorConfiguration: (companyId, connectorVersionId) => {
    return dispatch(fetchConnectorConfiguration(companyId, connectorVersionId));
  },
  putConnectorConfiguration: (companyId, connectorVersionId, connectorConfig) => {
    return dispatch(putConnectorConfiguration(companyId, connectorVersionId, connectorConfig));
  },
  updateConnectorConfiguration: (companyId, connectorVersionId, update) => {
    return dispatch(updateConnectorConfiguration(companyId, connectorVersionId, update));
  },
  deleteConnectorConfiguration: (companyId, connectorVersionId) => {
    return dispatch(deleteConnectorConfiguration(companyId, connectorVersionId));
  },

  getLatestConnectorVersion: connectorId => {
    return dispatch(getLatestConnectorVersion(connectorId));
  },

  disconnectConnector: (companyId, connectorCompanyId, connectorVersionId) => {
    return dispatch(disconnectConnector(companyId, connectorCompanyId, connectorVersionId));
  },

  fetchConnectorVersionData: connectorId => {
    return dispatch(fetchConnectorVersionData(connectorId));
  },
  fetchCompanyGrantedPermissions: companyId => {
    dispatch(fetchCompanyGrantedPermissions(companyId));
  },
  toggleConnectorRegModal: () => {
    dispatch({ type: actions.TOGGLE_CONNECTOR_REGISTRATION_MODAL });
  },
  startCustomConnectorRegistration: connectorInfo => {
    dispatch({
      type: actions.START_CUSTOM_CONNECTOR_REGISTRATION,
      connectorInfo
    });
  },
  endCustomConnectorRegistration: () => {
    dispatch({ type: actions.END_CUSTOM_CONNECTOR_REGISTRATION });
  },
  specificConnecterReq: (reqType, endpoint, reqParams, payload, headers) => {
    return dispatch(specificConnecterReq(reqType, endpoint, reqParams, payload, headers));
  },
  isSpecificConnectorReqRunning: (endpoint, reqType) => {
    return dispatch(isSpecificConnectorReqRunning(endpoint, reqType));
  },
  getSpecificConnectorReqData: (endpoint, reqType, responseState) => {
    return dispatch(getSpecificConnectorReqData(endpoint, reqType, responseState));
  },
  clearSpecificConnectorReqData: (endpoint, reqType) => {
    dispatch(clearSpecificConnectorReqData(endpoint, reqType));
  },
  getSpecificSupportedConnector: connectorName => {
    return dispatch(getSpecificSupportedConnector(connectorName));
  },

  getEmailConnectorId: () => {
    return "83927133-c727-4182-c7a2-178374620192";
  }
});
