import { actions } from "./invite-actions";

const defaultInviteSettings = {
  invitingUser: false,
  invitedUser: false,
  inviteFailed: false,

  resendingInvite: {},
  resentInvite: {},
  resendInviteFailed: {},

  fetchingCompanyUserInvites: false,
  fetchedCompanyUserInvites: false,
  fetchCompanyUserInvitesFailed: false,
  invites: [],

  acceptingCompanyInvite: false,
  acceptedCompanyInvite: false,
  acceptCompanyInviteFailed: false,
  rejectingCompanyInvite: false,
  rejectedCompanyInvite: false,
  rejectCompanyInviteFailed: false
};

function inviteReducer(state = { ...defaultInviteSettings }, action) {
  switch (action.type) {
    case actions.INVITING_COMPANY_USER:
      return {
        ...state,
        invitingUser: true,
        invitedUser: false,
        inviteFailed: false
      };
    case actions.INVITE_COMPANY_USER_FAILED:
      return {
        ...state,
        invitingUser: false,
        invitedUser: false,
        inviteFailed: true
      };
    case actions.INVITED_COMPANY_USER:
      return {
        ...state,
        invitingUser: false,
        invitedUser: true,
        inviteFailed: false
      };

    case actions.RESET_INVITE_COMPANY_USER:
      return {
        ...state,
        invitingUser: false,
        invitedUser: false,
        inviteFailed: false
      };

    case actions.FETCHING_COMPANY_USER_INVITES:
      return {
        ...state,
        companyId: action.companyId,
        fetchingCompanyUserInvites: true,
        fetchedCompanyUserInvites: false,
        fetchCompanyUserInvitesFailed: false
      };
    case actions.FETCH_COMPANY_USER_INVITES_FAILED:
      if (action.companyId !== state.companyId) return state;
      return {
        ...state,
        fetchingCompanyUserInvites: false,
        fetchedCompanyUserInvites: false,
        fetchCompanyUserInvitesFailed: true
      };
    case actions.FETCHED_COMPANY_USER_INVITES:
      if (action.companyId !== state.companyId) return state;
      return {
        ...state,
        fetchingCompanyUserInvites: false,
        fetchedCompanyUserInvites: true,
        fetchCompanyUserInvitesFailed: false,
        invites: action.invites
      };

    case actions.RESEND_COMPANY_USER_INVITE:
      return updateResendStatus(state, action.inviteId, true, false, false);
    case actions.RESEND_COMPANY_USER_INVITE_FAILED:
      return updateResendStatus(state, action.inviteId, false, false, true);
    case actions.RESENT_COMPANY_USER_INVITE:
      return updateResendStatus(state, action.inviteId, false, true, false);

    case actions.ACCEPTING_COMPANY_INVITE:
      return {
        ...state,
        acceptingCompanyInvite: true,
        acceptedCompanyInvite: false,
        acceptCompanyInviteFailed: false
      };
    case actions.ACCEPT_COMPANY_INVITE_FAILED:
      return {
        ...state,
        acceptingCompanyInvite: false,
        acceptedCompanyInvite: false,
        acceptCompanyInviteFailed: true
      };
    case actions.ACCEPTED_COMPANY_INVITE:
      return {
        ...state,
        acceptingCompanyInvite: false,
        acceptedCompanyInvite: true,
        acceptCompanyInviteFailed: false
      };

    case actions.REJECTING_COMPANY_INVITE:
      return {
        ...state,
        rejectingCompanyInvite: true,
        rejectedCompanyInvite: false,
        rejectCompanyInviteFailed: false
      };
    case actions.REJECT_COMPANY_INVITE_FAILED:
      return {
        ...state,
        rejectingCompanyInvite: false,
        rejectedCompanyInvite: false,
        rejectCompanyInviteFailed: true
      };
    case actions.REJECTED_COMPANY_INVITE:
      return {
        ...state,
        rejectingCompanyInvite: false,
        rejectedCompanyInvite: true,
        rejectCompanyInviteFailed: false
      };

    case actions.CLEAR_DATA:
      return { ...defaultInviteSettings };
    case actions.SELECT_COMPANY:
      if (action.companyId === state.companyId) return state;
      return { ...defaultInviteSettings };

    default:
      return state;
  }
}

function updateResendStatus(state, inviteId, resending, resent, failed) {
  let resendingInvite = { ...state.resendingInvite };
  let resentInvite = { ...state.resentInvite };
  let resendInviteFailed = { ...state.resendInviteFailed };

  resendingInvite[inviteId] = resending;
  resentInvite[inviteId] = resent;
  resendInviteFailed[inviteId] = failed;

  return { ...state, resendingInvite, resentInvite, resendInviteFailed };
}

export default inviteReducer;
