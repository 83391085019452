import React, { Component } from "react";
import { connect } from "react-redux";
import Resources from "../lib/resources";

import Modal from "react-modal";

import RadioInput from "./library/radioInput";
import Checkbox from "./library/checkbox";

import { getPartnerType } from "../lib/perspectives";
import { includes } from "../lib/utils";

import { dispatchToProps as moDP } from "../store/modal-actions";
import { dispatchToProps as paDP } from "../store/perspectives-actions";
import { dispatchToProps as aaDP } from "../store/accounts-actions";

const dispatchToProps = dispatch => {
  return {
    ...moDP(dispatch),
    ...paDP(dispatch),
    ...aaDP(dispatch)
  };
};

class UserManagementModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userRole: this.props.user.role,
      permittedPerspectives: this.props.user.perspectiveIds
    };

    this.setUserPermissions = this.setUserPermissions.bind(this);
  }

  setUserPermissions() {
    const { permittedPerspectives, userRole } = this.state;
    const { companyId, user } = this.props;
    if (permittedPerspectives.length === 0 && userRole === 0) {
      return this.setState({ workspaceRequiredError: true });
    }
    this.props.putCompanyUserRole(companyId, user.userId, userRole, permittedPerspectives);
    this.props.hideModal();
  }

  render() {
    const { user, companyId } = this.props;
    const companyPerspectives = this.props.getCompanyPerspectives(companyId) || [];
    const companyPerspectiveIds = companyPerspectives.map(cPerspective => cPerspective.perspectiveId);
    const allChecked =
      companyPerspectives.length === this.state.permittedPerspectives.length || this.state.userRole === 1;
    return (
      <Modal
        isOpen={this.props.modal.showModal}
        onRequestClose={() => this.props.hideModal()}
        contentLabel="Example Modal"
        className="custom-modal"
        overlayClassName="custom-modal-overlay"
      >
        <div className="custom-modal-header">{Resources.UserRoleAndAccess}</div>
        <div className="custom-modal-divider" />
        <div className="custom-modal-content">
          <div className="mt-2 mb-4 fw-500">
            {user.givenName}, <span className="dark-gray-font">{user.email}</span>
          </div>
          <label className="input-label text-left">{Resources.UserRole}</label>
          <RadioInput
            inline
            inputs={[{ label: Resources.User, value: 0 }, { label: Resources.Administrator, value: 1 }]}
            radioName="testing"
            value={this.state.userRole}
            onChange={item => {
              let update = { userRole: item.value, permittedPerspectives: [], workspaceRequiredError: false };
              if (item.value === 1) {
                update.permittedPerspectives = companyPerspectiveIds;
              }
              this.setState(update);
            }}
          />

          <label className="input-label text-left mt-4 mb-2">{Resources.WorkspaceAccess}</label>
          {this.state.workspaceRequiredError && (
            <div className="warning-color-font mb-3">{Resources.UsersMustHaveWorkspaceError}</div>
          )}
          <Checkbox
            disabled={this.state.userRole === 1}
            label={Resources.All}
            checked={allChecked}
            onChange={() => {
              let permittedPerspectives = [];
              if (allChecked !== true) {
                permittedPerspectives = companyPerspectiveIds;
              }
              this.setState({ permittedPerspectives, workspaceRequiredError: false });
            }}
          />
          <div className="flex-wrap mt-1">
            {companyPerspectives.map((cPerspective, i) => {
              const permitted = includes(this.state.permittedPerspectives, cPerspective.perspectiveId) || allChecked;
              return (
                <Checkbox
                  key={i}
                  disabled={this.state.userRole === 1}
                  className="w-45 mb-1"
                  label={getPartnerType(cPerspective.perspectiveName)}
                  checked={permitted}
                  onChange={() => {
                    let permittedPerspectives;
                    if (permitted) {
                      permittedPerspectives = this.state.permittedPerspectives.filter(
                        p => p !== cPerspective.perspectiveId
                      );
                    } else {
                      permittedPerspectives = [...this.state.permittedPerspectives, cPerspective.perspectiveId];
                    }
                    this.setState({ permittedPerspectives, workspaceRequiredError: false });
                  }}
                />
              );
            })}
          </div>
          <div className="flex-row modal-buttons mt-4">
            <button className="button-cancel float-left mr-1" onClick={this.props.hideModal}>
              {Resources.Cancel}
            </button>
            <button className="btn button-green" onClick={this.setUserPermissions}>
              {Resources.Confirm}
            </button>
          </div>
        </div>
      </Modal>
    );
  }
}

const storeToProps = store => {
  return {
    modal: store.modal
  };
};

export default connect(
  storeToProps,
  dispatchToProps
)(UserManagementModal);
