import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from "react-modal";
import moment from "moment";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { dispatchToProps } from "../store/modal-actions";

import Resources from "../lib/resources";

class CustomDateRangeModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fromDate: this.props.fromDate || moment().format("YYYY-MM-DD HH:mm:ss"),
      toDate: this.props.toDate || moment().format("YYYY-MM-DD HH:mm:ss")
    };

    this.applyDates = this.applyDates.bind(this);
  }

  applyDates() {
    this.props.updateDateRange(this.state.fromDate, this.state.toDate);
    this.props.hideModal();
  }
  render() {
    const { modalStore } = this.props;
    return (
      <Modal
        isOpen={modalStore.showModal}
        onRequestClose={() => this.props.hideModal()}
        className="custom-modal custom-date-modal"
        overlayClassName="custom-modal-overlay"
      >
        <div className="custom-modal-header">{this.props.title || Resources.CustomDateRange}</div>
        <div className="custom-modal-divider" />
        <div className="custom-modal-content">
          <div className="date-picker-container">
            <div className="d-inline-block mr-4">
              <div className="font-med">{Resources.From}</div>
              <DatePicker
                startOpen
                className="date-picker-input"
                calendarClassName="date-picker-calendar"
                dayClassName={d => "date-picker-day"}
                dateFormat="YYYY-MM-dd"
                // startDate={new Date(this.state.fromDate)}
                // endDate={new Date(this.state.toDate)}
                maxDate={new Date(this.state.toDate)}
                selected={new Date(this.state.fromDate)}
                // selectsStart
                shouldCloseOnSelect={false}
                onChange={d =>
                  this.setState({
                    fromDate: moment(d)
                      .startOf("day")
                      .format("YYYY-MM-DD HH:mm:ss")
                  })
                }
              />
            </div>
            <div className="d-inline-block">
              <div className="font-med">{Resources.To}</div>
              <DatePicker
                className="date-picker-input"
                calendarClassName="date-picker-calendar"
                dayClassName={d => "date-picker-day"}
                dateFormat="YYYY-MM-dd"
                // startDate={new Date(this.state.fromDate)}
                // endDate={new Date(this.state.toDate)}
                minDate={new Date(this.state.fromDate)}
                maxDate={new Date()}
                selected={new Date(this.state.toDate)}
                // selectsEnd
                shouldCloseOnSelect={false}
                onChange={d =>
                  this.setState({
                    toDate: moment(d)
                      .endOf("day")
                      .format("YYYY-MM-DD HH:mm:ss")
                  })
                }
              />
            </div>
          </div>
          <div className="flex-row modal-buttons mt-4">
            <button className="btn button-green" onClick={this.applyDates}>
              {Resources.Ok}
            </button>
          </div>
        </div>
      </Modal>
    );
  }
}

const storeToProps = store => {
  return {
    modalStore: store.modal
  };
};

export default connect(
  storeToProps,
  dispatchToProps
)(CustomDateRangeModal);
