import React, { Component } from "react";
import { Switch, Redirect, withRouter, Route } from "react-router-dom";
import { connect } from "react-redux";

import SequencesView from "./sequences/sequencesView";
import DepartmentView from "../../departmentView";

import { dispatchToProps as conDP } from "../../../store/conversations-actions";
import { isGuid } from "../../../lib/utils";

const dispatchToProps = dispatch => ({
  ...conDP(dispatch)
});

class CommunicationsView extends Component {
  componentDidMount() {
    this.fetchFolderConversations(this.props.pageRowCount, 0);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.match.params.subView !== this.props.match.params.subView) {
      this.fetchFolderConversations(this.props.pageRowCount, 0);
    }
  }

  getFolderLabel() {
    switch (this.props.match.params.subView) {
      case "closed":
        return "closed";
      case "sent":
        return "waiting-for-response";
      case "open":
      default:
        if (isGuid(this.props.match.params.subView)) {
          return null;
        } else {
          return this.props.match.params.subView;
        }
    }
  }

  fetchFolderConversations(top, skip) {
    const { companyId, perspectiveId } = this.props.match.params;

    const label = this.getFolderLabel();

    this.props.getConversations(companyId, perspectiveId, label, top, skip);
  }

  render() {
    //let data = this.props.conversations.value || [];
    // let messageColumns = [
    //   {
    //     selectable: true,
    //     width: "8%"
    //   },
    //   { header: Resources.From.toLocaleUpperCase(), content: row => row.from, width: "27%" },
    //   { header: Resources.Subject.toLocaleUpperCase(), content: row => row.subject, width: "25%" }
    // ];

    return (
      <React.Fragment>
        <Switch>
          <Redirect
            exact
            from="/v2/:companyId/:perspectiveId/:view(activities|documents)"
            to="/v2/:companyId/:perspectiveId/:view(activities|documents)/mine"
          />
          <Route path="/v2/:companyId/:perspectiveId/:view(activities|documents|statements|profile|settings)/:selectedContextGroupId(mine|unassigned|assigned|waiting-for-response|pending-approval|reminder|closed|spam|snoozed)/:selectedItemId?/:activityType?">
            <DepartmentView></DepartmentView>
            {/* <div className="view-content">
              <button className="button-primary" style={{ marginBottom: "2rem" }}>
                <IconMessage height="20" className="button-primary-icon" />
                {Resources.SendMessage}
              </button>
              <Card className="card-table">
                <TableData
                  data={data}
                  columns={messageColumns}
                  pagination
                  loading={this.props.gettingConversations[this.props.match.params.perspectiveId]}
                />
              </Card>
            </div> */}
          </Route>
          <Route path="/v2/:companyId/:perspectiveId/:subView(sequences)/:sequenceView?">
            <SequencesView />
          </Route>
        </Switch>
      </React.Fragment>
    );
  }
}

const storeToProps = store => {
  return {
    pageRowCount: store.general.pageRowCount,
    gettingConversations: store.conversations.gettingConversations,
    conversations: store.conversations.conversations
  };
};

export default withRouter(connect(storeToProps, dispatchToProps)(CommunicationsView));
