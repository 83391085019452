import React, { useEffect, useState, useRef } from "react";
import IconEdit from "../../../library/icons/iconEdit";
import TextInput from "../../../library/textInput";

const Modes = {
  READ: "read",
  EDIT: "edit"
};

const EditableField = ({ value, onChange }) => {
  const inputEl = useRef(null);
  const [mode, setMode] = useState(Modes.READ);
  const toggleMode = () => setMode(mode === Modes.READ ? Modes.EDIT : Modes.READ);

  useEffect(() => {
    if (inputEl.current) {
      inputEl.current.focus();
    }
  }, [mode]);

  switch (mode) {
    case Modes.READ:
      return (
        <div className="d-flex">
          <span>{value}</span>
          <IconEdit height={18} onClick={toggleMode} className="settings-flyout__edit" />
        </div>
      );
    case Modes.EDIT:
      return (
        <TextInput
          inline
          inputRef={inputEl}
          textValue={value}
          inputOnChange={onChange}
          onBlur={toggleMode}
          onPressEnter={toggleMode}
          className="w-45 settings-flyout__inline-input"
        />
      );
    default:
      throw new Error("Unexpected mode");
  }
};

export default EditableField;
