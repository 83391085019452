import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

import Resources from "../../lib/resources";
import { isEmpty, updateSelectedRows, formatDate, getFileType, find } from "../../lib/utils";

import { dispatchToProps as conDP } from "../../store/conversations-actions";
import { dispatchToProps as ledgDP } from "../../store/ledger-actions";
import { dispatchToProps as modDP } from "../../store/modal-actions";

import TableData from "../library/tableData";
import TableDataSortableHeader from "../library/tableDataSortableHeader";
import Card from "../library/card";
import MainLoader from "../mainLoader";
import IconUpload from "../library/icons/iconUpload";
import IconDownload from "../library/icons/iconDownload";
import IconTrash from "../library/icons/iconTrash";
import IconAttachment from "../library/icons/iconAttachment";
import moment from "moment";

const dispatchToProps = dispatch => {
  return {
    ...dispatch(conDP),
    ...dispatch(ledgDP),
    ...dispatch(modDP),
    ...dispatch(conDP)
  };
};

class Documents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRows: [],
      selectedKeys: []
    };

    this.toggleSelectRow = this.toggleSelectRow.bind(this);
    this.downloadSelectedAttachments = this.downloadSelectedAttachments.bind(this);
    this.deleteSelectedAttachments = this.deleteSelectedAttachments.bind(this);
    this.onSortChange = this.onSortChange.bind(this);
  }

  componentDidMount() {
    this.tryUpdate();
  }

  componentDidUpdate(prevProps) {
    this.tryUpdate(prevProps);
  }

  tryUpdate(prevProps) {
    const { companyId, perspectiveId, withContextGroupId, conversationsStore, pageRowCount } = this.props;

    if (isEmpty(perspectiveId) || isEmpty(withContextGroupId)) {
      return;
    }

    let documentUploaded =
      !isEmpty(prevProps) &&
      this.props.ledgerStore.uploadedDocument === true &&
      prevProps.ledgerStore.uploadedDocument === false;

    let attachmentsDeleted =
      !isEmpty(prevProps) &&
      this.props.ledgerStore.deletedAttachments === true &&
      prevProps.ledgerStore.deletedAttachments === false;

    if (
      conversationsStore.failedGetDocuments === null ||
      documentUploaded ||
      attachmentsDeleted ||
      this.sortHasChanged(prevProps)
    ) {
      this.props.getConversationDocuments(companyId, withContextGroupId, false, perspectiveId, pageRowCount);
    }
    if (attachmentsDeleted) {
      this.setState({ selectedRows: [], selectedKeys: [] });
    }
  }

  sortHasChanged(prevProps) {
    if (isEmpty(prevProps)) {
      return false;
    }
    if (
      this.props.conversationsStore.documents.sortDirection !== prevProps.conversationsStore.documents.sortDirection ||
      this.props.conversationsStore.documents.sortBy !== prevProps.conversationsStore.documents.sortBy
    ) {
      return true;
    }
    return false;
  }

  onSortChange() {}

  downloadSelectedAttachments() {
    if (isEmpty(this.state.selectedRows)) {
      return null;
    }
    this.props.displayNotification("downloadNotification");
    this.props.fetchAttachmentsZip(
      this.state.selectedRows.map(row => row.attachmentId),
      `Documents--${moment().format("Y-MM-DD--HH-mm-ss")}.zip`
    );
  }

  deleteSelectedAttachments() {
    if (isEmpty(this.state.selectedRows)) {
      return null;
    }
    let attachmentsToDelete = [];
    this.state.selectedRows.forEach(row => {
      if (row.canBeDeleted) {
        attachmentsToDelete.push(row.attachmentId);
      }
    });
    this.props.deleteAttachments(attachmentsToDelete);
  }

  toggleSelectRow(key, row) {
    let newSelectedKeys = updateSelectedRows(key, this.state.selectedKeys);
    let newSelectedRows = updateSelectedRows(row, this.state.selectedRows);
    this.setState({ selectedKeys: newSelectedKeys, selectedRows: newSelectedRows });
  }

  handleLoadMore(pageToLoad) {
    let { companyId, perspectiveId, withContextGroupId, pageRowCount } = this.props;
    let top = pageRowCount;
    let skip = pageToLoad * pageRowCount - pageRowCount;
    this.props.getConversationDocuments(companyId, withContextGroupId, false, perspectiveId, top, skip);
  }

  getDocumentsColumns(hover) {
    let { conversationsStore } = this.props;
    let selectCol = {
      type: "rowSelect",
      width: "8%"
    };

    let fileNameCol = {
      header: (
        <TableDataSortableHeader
          sortBy={conversationsStore.documents.sortBy}
          sortDirection={conversationsStore.documents.sortDirection}
          updateSort={(sortBy, sortDirection) => {
            this.onSortChange();
            this.props.setDocumentSort(sortBy, sortDirection);
          }}
          text={Resources.Document.toLocaleUpperCase()}
          sortKey={"FileName"}
        />
      ),
      sortable: noSort => noSort === false,
      content: row => (
        <div className="d-flex align-content-center">
          <div className="documents-document">
            <a
              href={row.signedUrl}
              className="documents-document-name overflow-ellipsis mr-2"
              target="_blank"
              rel="noopener noreferrer"
            >
              {row.fileName.substring(0, row.fileName.lastIndexOf("."))}
            </a>
            <IconAttachment height={15} className="documents-document-icon documents-file-name-icon" />
          </div>
        </div>
      ),
      width: "37%"
    };

    let ownerCol = {
      header: (
        <TableDataSortableHeader
          sortBy={conversationsStore.documents.sortBy}
          sortDirection={conversationsStore.documents.sortDirection}
          updateSort={(sortBy, sortDirection) => {
            this.onSortChange();
            this.props.setDocumentSort(sortBy, sortDirection);
          }}
          text={Resources.Owner.toLocaleUpperCase()}
          sortKey={"CreatedByUser"}
        />
      ),
      sortable: noSort => noSort === false,
      content: row => <span>{row.createdByUser || ""}</span>,
      width: "18%"
    };

    let dateUploadedCol = {
      header: (
        <TableDataSortableHeader
          sortBy={conversationsStore.documents.sortBy}
          sortDirection={conversationsStore.documents.sortDirection}
          updateSort={(sortBy, sortDirection) => {
            this.onSortChange();
            this.props.setDocumentSort(sortBy, sortDirection);
          }}
          text={Resources.DateUploaded.toLocaleUpperCase()}
          sortKey={"CreatedDate"}
        />
      ),
      sortable: noSort => noSort === false,
      content: row => <span>{formatDate(row.createdDate, false, false)}</span>,
      width: "18%"
    };

    let typeCol = {
      header: (
        <TableDataSortableHeader
          sortBy={conversationsStore.documents.sortBy}
          sortDirection={conversationsStore.documents.sortDirection}
          updateSort={(sortBy, sortDirection) => {
            this.onSortChange();
            this.props.setDocumentSort(sortBy, sortDirection);
          }}
          text={Resources.Type.toLocaleUpperCase()}
          sortKey={"extension"}
        />
      ),
      sortable: noSort => noSort === false,
      content: row => <span>{getFileType(row.fileName).toLocaleUpperCase()}</span>,
      width: "8%"
    };

    const fillerCol = {
      content: row => null,
      width: "8%"
    };

    const columns = [selectCol, fileNameCol, ownerCol, dateUploadedCol, typeCol, fillerCol];
    const hoverColumns = [
      selectCol,
      fileNameCol,
      ownerCol,
      dateUploadedCol,
      typeCol,
      {
        content: row =>
          row.canBeDeleted ? (
            <button className="button-action-icon" onClick={() => this.props.deleteAttachments([row.attachmentId])}>
              <IconTrash height="20" />
            </button>
          ) : null,
        width: "8%"
      }
    ];

    if (hover) {
      return hoverColumns;
    }
    return columns;
  }

  renderEmptyState() {
    return (
      <div className="documents-empty-state">
        <svg viewBox="0 0 268 163">
          <g fill="none" fillRule="evenodd">
            <path fill="#FFF" d="M-586-192H854V832H-586z" />
            <path fill="#F8FBFC" d="M-586-40H854v871H-586z" />
            <path
              fill="#F4F3FF"
              d="M256.633663 24.0594059c6.2009 0 11.227723 5.0268228 11.227723 11.2277228 0 6.2009001-5.026823 11.2277228-11.227723 11.2277228h-64.158415c6.2009 0 11.227722 5.0268227 11.227722 11.2277228 0 6.2009-5.026822 11.2277227-11.227722 11.2277227h35.287128c6.2009 0 11.227723 5.0268227 11.227723 11.2277228s-5.026823 11.2277228-11.227723 11.2277228h-16.318553c-7.818527 0-14.156694 5.0268227-14.156694 11.2277224 0 4.133934 3.207921 7.876508 9.623762 11.227723 6.2009 0 11.227723 5.026823 11.227723 11.227723 0 6.2009-5.026823 11.227723-11.227723 11.227723H73.7821782c-6.2009 0-11.2277228-5.026823-11.2277228-11.227723 0-6.2009 5.0268228-11.227723 11.2277228-11.227723H11.2277228C5.02682271 113.881188 0 108.854365 0 102.653465c0-6.2008997 5.02682271-11.2277224 11.2277228-11.2277224h64.1584158c6.2009001 0 11.2277228-5.0268227 11.2277228-11.2277228S81.5870387 68.970297 75.3861386 68.970297H35.2871287c-6.2009 0-11.2277228-5.0268227-11.2277228-11.2277227 0-6.2009001 5.0268228-11.2277228 11.2277228-11.2277228h64.1584159c-6.2009001 0-11.2277228-5.0268227-11.2277228-11.2277228 0-6.2009 5.0268227-11.2277228 11.2277228-11.2277228H256.633663zm0 44.9108911c6.2009 0 11.227723 5.0268227 11.227723 11.2277228s-5.026823 11.2277228-11.227723 11.2277228c-6.2009 0-11.227722-5.0268227-11.227722-11.2277228s5.026822-11.2277228 11.227722-11.2277228z"
            />
            <path
              fill="#FFF"
              stroke="#BEB6FF"
              strokeLinecap="round"
              strokeWidth="3.5"
              d="M171.09746275 22.45544561l14.91985216 108.81705333 1.34044493 10.91704788c.43182842 3.51696024-2.0691646 6.71808531-5.58612484 7.14991373l-93.92827812 11.53294239c-3.51695965.43182835-6.71808492-2.06916464-7.14991334-5.58612488L66.23251038 37.51142872c-.21591419-1.75847992 1.03458241-3.35904247 2.79306224-3.57495665l.03332354-.00409161 7.79264977-.87395535m6.3012086-.70683126l7.35744625-.82531361"
            />
            <path
              fill="#DEDAFF"
              d="M167.03644893 29.30305224l13.5046427 98.61747678 1.21477826 9.89357497c.39134452 3.18724535-1.84459613 6.08450974-4.99411182 6.47122164l-84.11487626 10.32800815c-3.14951618.38671196-6.01995084-1.88356838-6.41129536-5.07081373L73.01608092 41.87828745c-.13461316-1.0963362.64501741-2.09421783 1.74135362-2.22883099l11.60477308-1.42488697"
            />
            <path
              fill="#FFF"
              stroke="#BEB6FF"
              strokeWidth="3.5"
              d="M176.198643 1.75H99.7113243c-.6213204 0-1.1838204.25183983-1.5909903.65900974-.4071699.40716992-.6590097.96966992-.6590097 1.59099026v126.732673c0 .621321.2518398 1.183821.6590097 1.590991.4071699.407169.9696699.659009 1.5909903.659009h99.4653467c.62132 0 1.18382-.25184 1.59099-.659009.40717-.40717.65901-.96967.65901-1.590991V26.9638138c0-.5969784-.237244-1.1694856-.659502-1.5914824L177.789141 2.40851759C177.367224 1.98686182 176.795139 1.75 176.198643 1.75z"
            />
            <path
              stroke="#BEB6FF"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="3.5"
              d="M175.909344 3.85404742V22.4554455c0 2.6575286 2.154353 4.8118812 4.811881 4.8118812h12.72517"
            />
            <path
              stroke="#DEDAFF"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="3.5"
              d="M114.958849 27.2673267h41.70297m-41.70297 19.2475248h68.970297m-68.970297 20.8514851h68.970297m-68.970297 20.8514852h68.970297m-68.970297 20.8514852h41.70297"
            />
          </g>
        </svg>
        <h2>{Resources.NothingToSeeHere}</h2>
        <p>{Resources.PortalDocumentsEmpty}</p>
        {this.getFileUploadButton()}
      </div>
    );
  }

  getFileUploadButton() {
    return (
      <React.Fragment>
        <button
          className="button-primary"
          onClick={() => document.getElementById("customer-portal-file-input").click()}
        >
          <IconUpload height="20" className="button-primary-icon" />
          {Resources.Upload}
        </button>
        <input
          id="customer-portal-file-input"
          type="file"
          name="name"
          accept=".csv, .doc, .docx, .eml, .gif, .jpeg, .pdf, .png, .tif, .tiff, .txt, xls, .xlsx, .zip"
          style={{ display: "none" }}
          onClick={e => (e.target.value = "")}
          onChange={e => {
            this.props.displayNotification("uploadNotification");
            this.props.uploadDocument(this.props.companyId, this.props.withContextGroupId, e.target.files[0]);
          }}
        />
      </React.Fragment>
    );
  }

  render() {
    let { conversationsStore, withCompanyName } = this.props;
    let { selectedRows, selectedKeys } = this.state;

    const data = conversationsStore.documents.value;
    const loading = conversationsStore.gettingDocuments;
    const maxRows = conversationsStore.documents.count;

    const helmet = (
      <Helmet>
        <title>
          {withCompanyName} | {Resources.Documents}
        </title>
      </Helmet>
    );

    if (loading) {
      return (
        <React.Fragment>
          {helmet}
          <MainLoader fullScreen className="portal-page-loader"></MainLoader>
        </React.Fragment>
      );
    }

    if (isEmpty(data)) {
      return (
        <React.Fragment>
          {helmet}
          {this.renderEmptyState()}
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        {helmet}
        <Card type="table" className="portal-table">
          <div className="table-data-card-header">
            <div className="table-data-card-header-buttons">
              {this.getFileUploadButton()}
              <button
                className="button-primary"
                disabled={isEmpty(selectedRows)}
                onClick={this.downloadSelectedAttachments}
              >
                <IconDownload height="20" className="button-primary-icon" />
                {Resources.Download}
              </button>
              <button
                className="button-primary"
                disabled={!selectedRows.some(row => row.canBeDeleted)}
                onClick={this.deleteSelectedAttachments}
              >
                <IconTrash height="20" className="button-primary-icon" />
                {Resources.Delete}
              </button>
            </div>
          </div>
          <TableData
            name="documents__table"
            pagination
            data={data}
            columns={this.getDocumentsColumns(false)}
            hoverColumns={this.getDocumentsColumns(true)}
            rowHeight="4em"
            onLoadMore={pageSelected => this.handleLoadMore(pageSelected)}
            maxRows={maxRows}
            loading={loading}
            selectedKeys={selectedKeys}
            rowKey="attachmentId"
            onRowSelectToggle={key =>
              this.toggleSelectRow(
                key,
                (Array.isArray(key) ? [...key] : [key]).map(k => find(data, row => row.attachmentId === k))
              )
            }
            offsetHeight="40"
            emptyRender={
              <div className="table-data-empty-render">
                <h4 className="mt-5">{Resources.EmptyInboxDocuments}</h4>
              </div>
            }
          />
        </Card>
      </React.Fragment>
    );
  }
}

const storeToProps = store => {
  return {
    contextGroupsStore: store.contextGroups,
    conversationsStore: store.conversations,
    ledgerStore: store.ledger,
    pageRowCount: store.general.pageRowCount
  };
};

export default connect(storeToProps, dispatchToProps)(Documents);
