import React, { Component } from "react";

class IconUpload extends Component {
  render() {
    let { className, height, style } = this.props;

    return (
      <svg viewBox="0 0 20 20" height={height} className={`icon ${className ? className : ""}`} style={style}>
        <g fill="none" fillRule="evenodd">
          <g stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
            <path d="M19 13v4c0 1.1045695-.8954305 2-2 2H3c-1.1045695 0-2-.8954305-2-2v-4M15 6l-5-5-5 5M10 1v12" />
          </g>
        </g>
      </svg>
    );
  }
}

IconUpload.defaultProps = {
  height: 20
};

export default IconUpload;
