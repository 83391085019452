import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { dispatchToProps as moDP } from "../../store/modal-actions";
import { dispatchToProps as manDP } from "../../store/manage-actions";

import Resources from "../../lib/resources";
import { isEmpty, findIndex, removeAt, saveBlobAsDownload } from "../../lib/utils";
import TextInput from "../library/textInput";
import HtmlEditor from "../htmlEditor";
import IconAngleDown from "../library/icons/iconAngleDown";
import Dropdown from "../library/dropdown";
import IconCode from "../library/icons/iconCode";
import IconPlusCircle from "../library/icons/iconPlusCircle";
import IconUpload from "../library/icons/iconUpload";
import IconClose from "../library/icons/iconClose";
import MainLoader from "../mainLoader";

const dispatchToProps = dispatch => ({
  ...moDP(dispatch),
  ...manDP(dispatch)
});

class CreateCommunicationTemplate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      contactAddressBookId: null,

      templateName: "",
      type: "email",
      subject: "",
      htmlBody: "",
      textBody: "",
      emailTemplateKey: null,
      to: "",
      cc: "",
      attachmentNames: null,
      attachmentsToUpload: [],
      attachmentsToDelete: [],

      selectedIndex: null,
      selectedLength: 0,

      focusedField: ""
    };

    this.submitTemplate = this.submitTemplate.bind(this);
    this.deleteTemplate = this.deleteTemplate.bind(this);
  }

  componentDidMount() {
    let { templateEditing } = this.props;
    this.props.getCommunicationTemplateDestinations();
    this.props.getCommunicationTemplateSnippets();
    if (!isEmpty(templateEditing)) {
      let newState = {
        templateName: templateEditing.emailTemplateID,
        emailTemplateKey: templateEditing.emailTemplateKey,
        type: templateEditing.type
      };
      switch (templateEditing.type) {
        case "email":
          newState.htmlBody = templateEditing.emailBody;
          newState.to = templateEditing.toAddress;
          newState.cc = templateEditing.ccAddress;
          newState.attachmentNames = templateEditing.attachmentNames;
          newState.subject = templateEditing.subject;
          break;
        case "text":
          newState.htmlBody = templateEditing.smsBody;
          newState.to = templateEditing.smsTo;
          break;
        case "voice":
          newState.htmlBody = templateEditing.voiceMessageBody;
          newState.to = templateEditing.voiceMessageTo;
          break;
        default:
      }
      this.setState(newState);
    }
  }

  submitTemplate() {
    let { templateName, type, subject, to, cc, htmlBody, textBody, emailTemplateKey } = this.state;

    let template = {
      emailTemplateID: templateName,
      emailTemplateDesc: subject,
      emailTemplateStatus: 0,
      voiceMessageStatus: 0,
      smsStatus: 0
    };
    switch (type) {
      case "email":
        template.emailBody = htmlBody;
        template.toAddress = to;
        template.ccAddress = cc;
        template.subject = subject;
        template.emailTemplateStatus = 1;
        break;
      case "text":
        template.smsBody = textBody;
        template.smsTo = to;
        template.smsStatus = 1;

        break;
      case "voice":
        template.voiceMessageBody = textBody;
        template.voiceMessageTo = to;
        template.voiceMessageStatus = 1;
        break;
      default:
    }
    if (!isEmpty(emailTemplateKey)) {
      template.emailTemplateKey = emailTemplateKey;
    }

    this.props
      .postCommunicationTemplate(
        this.props.companyId,
        template,
        this.state.attachmentsToUpload,
        this.state.attachmentsToDelete
      )
      .then(() => this.props.savedTemplate())
      .catch(() => {});
  }

  deleteTemplate() {
    this.props
      .deleteCommunicationTemplate(this.props.companyId, this.state.emailTemplateKey)
      .then(this.props.savedTemplate)
      .catch(() => {});
  }

  getTypeDisplay(type) {
    switch (type) {
      case "email":
        return Resources.Email;
      case "text":
        return Resources.TextMessage;
      case "voice":
        return Resources.PhoneCall;
      default:
        return null;
    }
  }

  getToCCDropdown(isCC) {
    let destinations = (this.props.manageStore.communicationTemplateDestinations || {})[this.state.type] || [];
    let content = destinations.map(destination => (
      <Dropdown.Item
        onClick={() =>
          isCC
            ? this.setState({ cc: this.state.cc + destination.snippetValue + ", " })
            : this.setState({ to: this.state.to + destination.snippetValue + ", " })
        }
        key={destination.snippetDescription}
      >
        {destination.snippetDescription}
      </Dropdown.Item>
    ));

    return (
      <Dropdown
        disabled={isEmpty(destinations)}
        buttonClassName="button-action-icon"
        buttonContent={<IconPlusCircle height={18} />}
      >
        {content}
      </Dropdown>
    );
  }

  addAttachments(e) {
    const files = [...e.target.files];
    this.setState({ attachmentsToUpload: [...this.state.attachmentsToUpload, ...files] });
    e.target.value = "";
  }

  removeAttachment(attachmentName) {
    let newAttachmentsToDelete = this.state.attachmentsToDelete;
    let newAttachmentsToUpload = this.state.attachmentsToUpload;
    let newAttachmentNames = this.state.attachmentNames;
    let attachmentUploadIndex = findIndex(
      this.state.attachmentsToUpload,
      attachment => attachment.name === attachmentName
    );
    if (attachmentUploadIndex > -1) {
      newAttachmentsToUpload = removeAt(newAttachmentsToUpload, attachmentUploadIndex);
    } else {
      newAttachmentsToDelete.push(attachmentName);
      newAttachmentNames = removeAt(newAttachmentNames, findIndex(newAttachmentNames, name => name === attachmentName));
    }
    this.setState({
      attachmentsToDelete: newAttachmentsToDelete,
      attachmentsToUpload: newAttachmentsToUpload,
      attachmentNames: newAttachmentNames
    });
  }

  getAttachmentNamesToDisplay() {
    return [...(this.state.attachmentNames || []), ...this.state.attachmentsToUpload.map(file => file.name)];
  }

  getAttachmentsRender() {
    if (this.state.type !== "email") {
      return null;
    }
    return (
      <React.Fragment>
        {this.getAttachmentNamesToDisplay().map((attachmentName, i) => (
          <div className="font-turquoise">
            <span
              className="communication-template-attachment-name"
              onClick={() => {
                let attachmentUploadIndex = findIndex(
                  this.state.attachmentsToUpload,
                  attachment => attachment.name === attachmentName
                );
                if (attachmentUploadIndex > -1) {
                  saveBlobAsDownload(this.state.attachmentsToUpload[attachmentUploadIndex], attachmentName);
                } else {
                  this.props.getCommunicationTemplateAttachment(
                    this.props.companyId,
                    this.state.emailTemplateKey,
                    attachmentName
                  );
                }
              }}
            >
              {attachmentName}
            </span>
            <span onClick={() => this.removeAttachment(attachmentName)}>
              <IconClose height={15} />
            </span>
          </div>
        ))}
        <button className="button-secondary mb-5" onClick={() => window.document.getElementById("uploadFiles").click()}>
          <IconUpload height="16" className="ml-0" />
          {Resources.UploadAttachment}
        </button>
        <input
          className="hidden-input"
          type="file"
          id="uploadFiles"
          multiple
          style={{ visibility: "hidden" }}
          onChange={e => this.addAttachments(e)}
        />
      </React.Fragment>
    );
  }

  render() {
    let isDeleting = this.props.manageStore.deletingCommunicationTemplate;
    let isPosting = this.props.manageStore.postingCommunicationTemplate;
    const disableSend = isDeleting || isPosting;

    if (isPosting || isDeleting) {
      return <MainLoader fullScreen />;
    }

    let snippetContent = ((this.props.manageStore.communicationTemplateSnippets || {})[this.state.type] || []).map(
      snippet => {
        return (
          <Dropdown.Item
            onClick={() => {
              let delta = {
                ops: []
              };
              this.state.selectedIndex !== 0 && delta.ops.push({ retain: this.state.selectedIndex });
              this.state.selectedLength !== 0 && delta.ops.push({ delete: this.state.selectedLength });
              delta.ops.push({ insert: snippet.snippetValue || "" });
              this.htmlEditor.updateContents(delta);
            }}
            key={snippet.snippetDescription}
          >
            {snippet.snippetDescription}
          </Dropdown.Item>
        );
      }
    );

    return (
      <React.Fragment>
        <div className="flyout-heading">
          <div>{Resources.CreateNewTemplate}</div>
        </div>
        <div className="flyout-content">
          <TextInput
            label={Resources.TemplateName}
            labelInline={false}
            width="20rem"
            textValue={this.state.templateName}
            inputOnChange={e => {
              this.setState({ templateName: e.target.value });
            }}
            className="communication-template-input"
          />

          <div className={`portal-input-label ${this.state.focusedField === "type" ? " focused" : ""}`}>
            {Resources.Type}
          </div>
          <Dropdown
            buttonContent={
              <React.Fragment>
                <span>{this.getTypeDisplay(this.state.type)}</span>
                <IconAngleDown height={8}></IconAngleDown>
              </React.Fragment>
            }
            buttonClassName="communication-template-input"
            onFocus={() => setTimeout(() => this.setState({ focusedField: "type" }), 90)}
            onBlur={e => setTimeout(() => this.setState({ focusedField: null }), 90)}
          >
            <Dropdown.Item className="dropdown-item" onClick={() => this.setState({ type: "email" })}>
              {Resources.Email}
            </Dropdown.Item>
            <Dropdown.Item className="dropdown-item" onClick={() => this.setState({ type: "voice" })}>
              {Resources.PhoneCall}
            </Dropdown.Item>
            <Dropdown.Item className="dropdown-item" onClick={() => this.setState({ type: "text" })}>
              {Resources.TextMessage}
            </Dropdown.Item>
          </Dropdown>
          <hr className="communication-template-divider" />
          <div className="d-flex align-items-end communication-template-input">
            <TextInput
              label={Resources.To}
              labelInline={false}
              width="20rem"
              textValue={this.state.to}
              className="pr-1 mb-0"
              inputOnChange={e => {
                this.setState({ to: e.target.value });
              }}
            />
            {this.getToCCDropdown(false)}
          </div>
          {this.state.type === "email" && (
            <div className="d-flex align-items-end communication-template-input">
              <TextInput
                label={Resources.CC}
                labelInline={false}
                width="20rem"
                textValue={this.state.cc}
                className="pr-1 mb-0"
                inputOnChange={e => {
                  this.setState({ cc: e.target.value });
                }}
              />
              {this.getToCCDropdown(true)}
            </div>
          )}

          {this.state.type === "email" ? (
            <TextInput
              label={Resources.Subject}
              labelInline={false}
              width="20rem"
              textValue={this.state.subject}
              className="pr-5 communication-template-input"
              inputOnChange={e => {
                this.setState({ subject: e.target.value });
              }}
            />
          ) : null}

          <div className={`portal-input-label ${this.state.focusedField === "message" ? " focused" : ""}`}>
            {Resources.Message}
          </div>
          <HtmlEditor
            onFocus={() => this.setState({ focusedField: "message" })}
            onBlur={e => this.setState({ focusedField: null })}
            hideToolbar={this.state.focusedField !== "message"}
            htmlContent={this.state.htmlBody}
            updateHtmlContent={htmlBody => this.setState({ htmlBody })}
            updateTextContent={textBody => this.setState({ textBody })}
            onChangeSelection={range => {
              range && this.setState({ selectedIndex: range.index, selectedLength: range.length });
            }}
            onRef={ref => (this.htmlEditor = ref)}
            toolbarItems={
              <Dropdown disabled={isEmpty(snippetContent)} buttonContent={<IconCode height={18}></IconCode>}>
                {snippetContent}
              </Dropdown>
            }
          />
          {this.getAttachmentsRender()}

          {!isEmpty(this.state.emailTemplateKey) && (
            <button
              className="button-cancel-square"
              style={{ width: "16.66rem", marginBottom: "1.6rem" }}
              onClick={this.deleteTemplate}
              disabled={disableSend}
            >
              {Resources.Delete}
            </button>
          )}
          <br />
          <button
            className="portal-button-green"
            style={{ width: "16.66rem" }}
            onClick={this.submitTemplate}
            disabled={disableSend}
          >
            {Resources.Save}
          </button>
        </div>
      </React.Fragment>
    );
  }
}

const storeToProps = store => {
  return {
    modalStore: store.modal,
    conversationsStore: store.conversations,
    ledgerStore: store.ledger,
    accountsStore: store.accounts,
    manageStore: store.manage
  };
};

export default withRouter(
  connect(
    storeToProps,
    dispatchToProps
  )(CreateCommunicationTemplate)
);
