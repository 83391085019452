import React, { Component } from "react";
import Card from "../../../library/card";
import TextInput from "../../../library/textInput";
import Resources from "../../../../lib/resources";
import SelectInput from "../../../library/selectInput";
import { find } from "../../../../lib/utils";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { dispatchToProps as netDP } from "../../../../store/network-actions";

const dispatchToProps = dispatch => ({
  ...netDP(dispatch)
});

class LoginInformationForm extends Component {
  componentDidMount() {
    if (this.props.contact && !this.props.profileupdate) {
      let accessId = this.props.match.params.accessId;
      this.props.fetchCustContact(accessId).then(response => {
        this.props.formOnChange("username", this.props.networkStore.custContact.emailAddr);
        this.props.formOnChange("accessId", accessId);
      });
    }
  }

  render() {
    let departments = [
      { value: 1, department: "Accounts Payable" },
      { value: 2, department: "Accounts Recievable" }
    ];

    return (
      <Card className="login-information-card">
        <TextInput
          textValue={this.props.profile.username}
          width="23.2rem"
          labelInline={false}
          label={Resources.UsernameEmail}
          inputOnChange={e => {
            let value = e.target.value;
            this.props.formOnChange("username", value);
          }}
        />
        <TextInput
          textValue={this.props.profile.password}
          width="23.2rem"
          labelInline={false}
          label={Resources.Password}
          isPassword
          inputOnChange={e => {
            let value = e.target.value;
            this.props.formOnChange("password", value);
          }}
        />
        {this.props.displayModePicker ? (
          <SelectInput
            className="field"
            value={find(departments, i => i.value === this.props.profile.departmentId) || {}.value}
            displayValue={(find(departments, i => i.value === this.props.profile.departmentId) || {}).department}
            optionValueKey="value"
            optionDisplayKey="department"
            options={departments}
            labelInline={false}
            label={Resources.Department}
            width={"23.2rem"}
            onSelectOption={option => {
              this.props.formOnChange("departmentId", option.value);
            }}
          />
        ) : null}
      </Card>
    );
  }
}

const storeToProps = store => {
  return {
    networkStore: store.network
  };
};

export default withRouter(connect(storeToProps, dispatchToProps)(LoginInformationForm));
