import React, { Component } from "react";
import { connect } from "react-redux";

import Resources from "../lib/resources";
import { isEmpty } from "../lib/utils";

import MainContentHeader from "./main_content_header/mainContentHeader";
import TextInput from "./library/textInput";
import Card from "./library/card";

import { dispatchToProps as comDP } from "../store/company-actions";
import { dispatchToProps as accDP } from "../store/accounts-actions";

const dispatchToProps = dispatch => ({
  ...comDP(dispatch),
  ...accDP(dispatch)
});

class MyCompanyProfile extends Component {
  constructor(props) {
    super(props);

    this.state = { ...this.defaultState };
    this.saveProfile = this.saveProfile.bind(this);
    this.getAddressContent = this.getAddressContent.bind(this);
    this.edit = this.edit.bind(this);
  }

  defaultState = {
    editing: false,

    companyName: "",
    address: {
      Line1: "",
      Locality: "",
      Region: "",
      Country: "",
      PostalCode: ""
    },
    apEmail: "",
    arEmail: "",
    apPhone: "",
    arPhone: "",
    url: "",
    TIN: ""
  };

  saveProfile() {
    let { companyId } = this.props.match.params;
    this.props
      .postCompanyName(this.state.companyName, companyId)
      .then(response => {
        this.props.postCompanyInfo(this.state, companyId);
      })
      .then(response => {
        this.setState({ editing: false });
        this.props.fetchCompanyInfo(companyId);
        this.props.fetchAccounts();
      })
      .catch(error => {
        return null;
      });
  }

  getAddressContent(givenAddress) {
    let { address, editing } = this.state;

    if (editing) {
      return (
        <div className="my-company-profile-address">
          <TextInput
            textValue={this.state.address.Line1}
            className="my-0"
            inputOnChange={e => {
              this.setState({ address: { ...address, Line1: e.target.value } });
            }}
            placeholder={Resources.StreetAddress}
            width="20em"
          />
          <TextInput
            textValue={this.state.address.Locality}
            className="my-0"
            inputOnChange={e => {
              this.setState({ address: { ...address, Locality: e.target.value } });
            }}
            placeholder={Resources.City}
            width="20em"
          />
          <div className="my-company-profile-address-second">
            <TextInput
              textValue={this.state.address.Region}
              className="my-0"
              inputOnChange={e => {
                this.setState({ address: { ...address, Region: e.target.value } });
              }}
              placeholder={Resources.State}
              width="10em"
            />
            <TextInput
              textValue={this.state.address.PostalCode}
              className="my-0"
              inputOnChange={e => {
                this.setState({ address: { ...address, PostalCode: e.target.value } });
              }}
              placeholder={Resources.PostalCode}
              width="10em"
            />
          </div>
        </div>
      );
    } else {
      return (
        <span className="card-attribute-content-display">
          {!isEmpty(givenAddress.Line1) && givenAddress.Line1}
          {!isEmpty(givenAddress.Locality) && ", " + givenAddress.Locality}
          {!isEmpty(givenAddress.Region) && ", " + givenAddress.Region}
          {!isEmpty(givenAddress.PostalCode) && " " + givenAddress.PostalCode}
        </span>
      );
    }
  }

  edit(info) {
    let { companyName, Address = {}, apEmail, arEmail, apPhone, arPhone, url, TIN } = info;
    let { Line1, Locality, Region, Country, PostalCode } = Address;

    this.setState({
      companyName,
      address: {
        Line1,
        Locality,
        Region,
        Country,
        PostalCode
      },
      apEmail,
      arEmail,
      apPhone,
      arPhone,
      url,
      TIN,
      editing: true
    });
  }

  render() {
    let myCompanyInfo = this.props.getCompanyInfo(this.props.match.params.companyId);
    myCompanyInfo.companyName = this.props.accounts.selectedCompany.companyName;
    myCompanyInfo.apPhone = (myCompanyInfo["Contact Numbers"] || {})["AP Phone"];
    myCompanyInfo.arPhone = (myCompanyInfo["Contact Numbers"] || {})["AR Phone"];

    let { companyName, Address = {}, apEmail, arEmail, apPhone, arPhone, TIN } = myCompanyInfo;

    return (
      <React.Fragment>
        <MainContentHeader title="My Company Profile" hideSearch={true} />
        <div className="manage-content max-100">
          <Card
            maxWidth="50em"
            heading={Resources.Profile}
            onClickEdit={() => this.setState({ editing: true })}
            editing={this.state.editing}
          >
            <div className="my-company-profile-body">
              <div className={this.state.editing ? "card-attribute-editing" : "card-attribute"}>
                <div className="card-attribute-label">{Resources.Name.toLocaleUpperCase()}</div>
                <div className="card-attribute-content">
                  {this.state.editing ? (
                    <TextInput
                      inputOnChange={e => {
                        this.setState({ companyName: e.target.value });
                      }}
                      textValue={this.state.companyName}
                      className="my-0"
                      placeholder={Resources.CompanyName}
                      width="20em"
                    />
                  ) : (
                    <span className="card-attribute-content-display">{companyName}</span>
                  )}
                </div>
              </div>
              <div className={this.state.editing ? "card-attribute-editing" : "card-attribute"}>
                <div className="card-attribute-label">{Resources.TIN.toLocaleUpperCase()}</div>
                <div className="card-attribute-content">
                  {this.state.editing ? (
                    <TextInput
                      inputOnChange={e => {
                        this.setState({ TIN: e.target.value });
                      }}
                      textValue={this.state.TIN}
                      className="my-0"
                      placeholder={Resources.CompanyInfoTIN}
                      width="20em"
                    />
                  ) : (
                    <span className="card-attribute-content-display">{TIN}</span>
                  )}
                </div>
              </div>
              <div className={this.state.editing ? "card-attribute-editing" : "card-attribute"}>
                <div className="card-attribute-label">{Resources.ApEmail.toLocaleUpperCase()}</div>
                <div className="card-attribute-content">
                  {this.state.editing ? (
                    <TextInput
                      inputOnChange={e => {
                        this.setState({ apEmail: e.target.value });
                      }}
                      textValue={this.state.apEmail}
                      className="my-0"
                      width="20em"
                      placeholder={Resources.ApEmail}
                    />
                  ) : (
                    <span className="card-attribute-content-display">{apEmail}</span>
                  )}
                </div>
              </div>
              <div className={this.state.editing ? "card-attribute-editing" : "card-attribute"}>
                <div className="card-attribute-label">{Resources.ArEmail.toLocaleUpperCase()}</div>
                <div className="card-attribute-content">
                  {this.state.editing ? (
                    <TextInput
                      inputOnChange={e => {
                        this.setState({ arEmail: e.target.value });
                      }}
                      textValue={this.state.arEmail}
                      className="my-0"
                      width="20em"
                      placeholder={Resources.ArEmail}
                    />
                  ) : (
                    <span className="card-attribute-content-display">{arEmail}</span>
                  )}
                </div>
              </div>
              <div className={this.state.editing ? "card-attribute-editing" : "card-attribute"}>
                <div className="card-attribute-label">{Resources.ApPhone.toLocaleUpperCase()}</div>
                <div className="card-attribute-content">
                  {this.state.editing ? (
                    <TextInput
                      inputOnChange={e => {
                        this.setState({ apPhone: e.target.value });
                      }}
                      textValue={this.state.apPhone}
                      className="my-0"
                      width="20em"
                      placeholder={Resources.ApPhone}
                    />
                  ) : (
                    <span className="card-attribute-content-display">{apPhone}</span>
                  )}
                </div>
              </div>
              <div className={this.state.editing ? "card-attribute-editing" : "card-attribute"}>
                <div className="card-attribute-label">{Resources.ArPhone.toLocaleUpperCase()}</div>
                <div className="card-attribute-content">
                  {this.state.editing ? (
                    <TextInput
                      inputOnChange={e => {
                        this.setState({ arPhone: e.target.value });
                      }}
                      textValue={this.state.arPhone}
                      className="my-0"
                      width="20em"
                      placeholder={Resources.ArPhone}
                    />
                  ) : (
                    <span className="card-attribute-content-display">{arPhone}</span>
                  )}
                </div>
              </div>
              <div className={this.state.editing ? "card-attribute-editing" : "card-attribute"}>
                <div className="card-attribute-label">{Resources.Address.toLocaleUpperCase()}</div>
                <div className="card-attribute-content">{this.getAddressContent(Address)}</div>
              </div>
            </div>
            {this.state.editing && (
              <div className="my-company-profile-bottom-buttons">
                <button className="button-cancel" onClick={() => this.setState({ editing: false })}>
                  {Resources.Cancel}
                </button>
                <button className="button-green" onClick={this.saveProfile}>
                  {Resources.Save}
                </button>
              </div>
            )}
          </Card>
        </div>
      </React.Fragment>
    );
  }
}

const storeToProps = store => {
  return {
    accounts: store.accounts,
    companyStore: store.companies
  };
};

export default connect(
  storeToProps,
  dispatchToProps
)(MyCompanyProfile);
