import React, { Component } from "react";

export default class ToggleSwitch extends Component {
  render() {
    const { className, text, onChange, checked, label } = this.props;
    return (
      <React.Fragment>
        {this.props.label ? <label className="switch-label">{this.props.label}</label> : null}
        <span className={`switch-container ${className}`} onClick={e => e.stopPropagation()}>
          <label className="switch">
            <input type="checkbox" onChange={e => onChange(e.target.checked)} checked={checked} />
            <span className="slider round" />
          </label>
          {!!text && <span>{text}</span>}
        </span>
      </React.Fragment>
    );
  }
}
