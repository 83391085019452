import React, { Component } from "react";
import { isEmpty } from "../../lib/utils";

export default class RadioInput extends Component {
  render() {
    const { inputs, radioName, label, style, inline } = this.props;
    return (
      <div
        className={`radio-input ${this.props.className ? this.props.className : ""} ${inline ? "d-inline" : ""}`}
        style={style || {}}
      >
        {!isEmpty(label) && <div className="input-label">{label}</div>}
        {inputs.map((input, i) => {
          const isChecked = input.value === this.props.value;
          return (
            <div
              className={`${inline ? "d-inline" : ""} radio-input__input ${this.props.inputClassName || ""}`}
              style={inline ? { marginRight: "2.66rem" } : null}
              key={i}
            >
              <input
                className="hidden"
                type="radio"
                name={radioName}
                id={radioName + i}
                value={"option" + i}
                disabled={input.disabled || this.props.disabled}
                defaultChecked={isChecked}
              />
              <div
                className="radio-display d-inline"
                style={{ marginRight: ".66rem" }}
                onClick={() => (this.props.disabled ? null : this.props.onChange(input))}
              >
                {isChecked ? (
                  <svg viewBox="0 0 20 20" height="20">
                    <defs />
                    <g fill="none" fillRule="evenodd" transform="translate(1 1)">
                      <circle cx="9" cy="9" r="9" fill="#FFF" stroke="#576E7C" />
                      <circle cx="9" cy="9" r="6" fill="#1FB6C1" />
                    </g>
                  </svg>
                ) : (
                  <svg viewBox="0 0 20 20" height="20">
                    <defs />
                    <circle
                      cx="176"
                      cy="306"
                      r="9"
                      fill="#FFF"
                      fillRule="evenodd"
                      stroke="#576E7C"
                      transform="translate(-166 -296)"
                    />
                  </svg>
                )}
              </div>
              <label
                className={`${input.className ? input.className : ""} fw-500`}
                onClick={() => (this.props.disabled ? null : this.props.onChange(input))}
                htmlFor={radioName + i}
              >
                {input.label || input.content}
              </label>
            </div>
          );
        })}
      </div>
    );
  }
}
