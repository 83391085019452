import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";

import Icons from "../../lib/icons";
import Resources from "../../lib/resources";
import {
  formatDate,
  formatCurrency,
  isEmpty,
  stringToSentenceCase,
  updateSelectedRows,
  find,
  openUrlForDownload,
  getCurrencySymbol
} from "../../lib/utils";

import { dispatchToProps as saDP } from "../../store/statements-actions";
import { dispatchToProps as cmpDP } from "../../store/company-actions";
import { dispatchToProps as aaDP } from "../../store/accounts-actions";
import { dispatchToProps as cgDP } from "../../store/contextGroups-actions";
import { dispatchToProps as paDP } from "../../store/perspectives-actions";
import { dispatchToProps as moDP } from "../../store/modal-actions";
import { dispatchToProps as conDP } from "../../store/conversations-actions";
import { dispatchToProps as ledgDP } from "../../store/ledger-actions";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { Helmet } from "react-helmet";

import TableData from "../library/tableData";
import TableDataSortableHeader from "../library/tableDataSortableHeader";
import Card from "../library/card";
import AutoCompleteInput from "../library/autoCompleteInput";
import AutoPayIndicator from "./autoPayIndicator";
import MainLoader from "../mainLoader";
import StatementCurrencySelector from "./statementCurrencySelector";

import IconDollarSign from "../library/icons/iconDollarSign";
import IconMessage from "../library/icons/iconMessage";
import IconHelp from "../library/icons/iconHelp";
import IconStar from "../library/icons/iconStar";
import IconDownload from "../library/icons/iconDownload";
import IconExport from "../library/icons/iconExport";
import IconFilter from "../library/icons/iconFilter";
import IconAlert from "../library/icons/iconAlert";
import IconAlertCircle from "../library/icons/iconAlertCircle";
import IconAttachment from "../library/icons/iconAttachment";
import IconArrowRight from "../library/icons/iconArrowRight";
import IconInfo from "../library/icons/iconInfo";
import IconClose from "../library/icons/iconClose";

const dispatchToProps = dispatch => {
  return {
    ...dispatch(saDP),
    ...dispatch(aaDP),
    ...dispatch(cmpDP),
    ...dispatch(cgDP),
    ...dispatch(paDP),
    ...dispatch(moDP),
    ...dispatch(conDP),
    ...dispatch(ledgDP)
  };
};

class OpenInvoices extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...this.defaultState
    };
    this.toggleSelectRow = this.toggleSelectRow.bind(this);
    this.onSortChange = this.onSortChange.bind(this);
    this.exportRows = this.exportRows.bind(this);
  }

  defaultState = {
    selectedRows: [],
    selectedKeys: [],
    searchTerm: "",
    fromDate: "2017-01-01",
    toDate: moment()
      .startOf("day")
      .format("YYYY-MM-DD"),
    fromDueDate: "2017-01-01",
    toDueDate: moment()
      .startOf("day")
      .format("YYYY-MM-DD")
  };

  componentDidMount() {
    const { companyId, perspectiveId, withCompanyId, pageRowCount } = this.props;

    this.props.clearStatementsSearchResults();
    this.props.fetchOpenInvoices(companyId, perspectiveId, withCompanyId, null, null, pageRowCount);
    this.props.fetchUnappliedPayments(companyId, perspectiveId, withCompanyId, null, null, pageRowCount);
    this.tryUpdate();
  }

  componentDidUpdate(prevProps) {
    this.tryUpdate(prevProps);
  }

  tryUpdate(prevProps) {
    const {
      companyId,
      perspectiveId,
      withCompanyId,
      pageRowCount,
      statementsStore: { openFromDate, openToDate, openFromDueDate, openToDueDate }
    } = this.props;

    if (isEmpty(perspectiveId) || isEmpty(withCompanyId)) {
      return;
    }

    if (this.sortHasChanged(prevProps) || this.dateFilterHasChanged(prevProps) || this.selectedCurrencyHasChanged()) {
      let filter = null;
      if (!isEmpty(openFromDate)) {
        filter = `IssueDate%20>=%20${openFromDate}%20and%20IssueDate%20<=%20${openToDate}`;
      }
      this.props.fetchOpenInvoices(
        companyId,
        perspectiveId,
        withCompanyId,
        openFromDueDate,
        openToDueDate,
        pageRowCount,
        0,
        filter
      );
    }
    if (this.selectedCurrencyHasChanged()) {
      this.props.fetchUnappliedPayments(companyId, perspectiveId, withCompanyId, null, null);
    }
  }

  sortHasChanged(prevProps) {
    if (isEmpty(prevProps)) {
      return false;
    }
    if (
      this.props.statementsStore.openInvoicesSortDirection !== prevProps.statementsStore.openInvoicesSortDirection ||
      this.props.statementsStore.openInvoicesSortBy !== prevProps.statementsStore.openInvoicesSortBy
    ) {
      return true;
    }
    return false;
  }

  dateFilterHasChanged(prevProps) {
    if (isEmpty(prevProps)) {
      return false;
    }

    const {
      statementsStore: { openFromDate, openToDate, openFromDueDate, openToDueDate }
    } = this.props;

    if (
      openFromDate !== prevProps.statementsStore.openFromDate ||
      openToDate !== prevProps.statementsStore.openToDate ||
      openFromDueDate !== prevProps.statementsStore.openFromDueDate ||
      openToDueDate !== prevProps.statementsStore.openToDueDate
    ) {
      return true;
    }
    return false;
  }

  selectedCurrencyHasChanged() {
    if (this.props.statementsStore.selectedCurrency !== this.props.statementsStore.openInvoicesCurrency) {
      return true;
    }
    return false;
  }

  toggleSelectRow(key, row) {
    let newSelectedKeys = updateSelectedRows(key, this.state.selectedKeys);
    let newSelectedRows = updateSelectedRows(row, this.state.selectedRows);
    this.setState({ selectedKeys: newSelectedKeys, selectedRows: newSelectedRows });
  }

  handleLoadMore(pageToLoad) {
    let { companyId, perspectiveId, withCompanyId, pageRowCount } = this.props;
    let top = pageRowCount;
    let skip = pageToLoad * pageRowCount - pageRowCount;
    this.props.fetchOpenInvoices(companyId, perspectiveId, withCompanyId, null, null, top, skip);
  }

  handleLoadMoreUnapplied(pageToLoad) {
    let { companyId, perspectiveId, withCompanyId, pageRowCount } = this.props;
    let top = pageRowCount;
    let skip = pageToLoad * pageRowCount - pageRowCount;
    this.props.fetchUnappliedPayments(companyId, perspectiveId, withCompanyId, null, null, top, skip);
  }

  onSortChange() {
    this.setState({ selectedRows: [] });
  }

  exportRows(rows) {
    if (isEmpty(rows)) {
      return null;
    }
    let { companyId, perspectiveId, withCompanyId, pageRowCount, statementsStore } = this.props;
    let { openFromDate, openToDate, openFromDueDate, openToDueDate } = statementsStore;

    let options = {
      fromDate: openFromDate,
      toDate: openToDate,
      fromDueDate: openFromDueDate,
      toDueDate: openToDueDate
    };

    this.props.displayNotification("exportNotification");
    this.props.fetchTableExport(
      companyId,
      perspectiveId,
      withCompanyId,
      "open",
      pageRowCount,
      statementsStore.openInvoicesSkip,
      rows.map(i => i.id),
      options
    );
  }

  displayRelatedConversation(entry = {}) {
    let { companyId, perspectiveId, withCompanyId, withContextGroupId } = this.props;
    let { ledgerHash } = entry;

    this.props.displayFlyout("messageFlyout", {
      loading: true
    });

    this.props.fetchRelated(ledgerHash).then(() => {
      let relatedConversations = this.props.getRelated(ledgerHash);

      if (isEmpty(relatedConversations)) {
        return;
      }

      let conversation = relatedConversations.value[0];
      let conversationId = conversation.conversationId;
      let invoiceColumns = this.getOpenInvoiceColumns(false).splice(1);

      this.props.displayFlyout("messageFlyout", {
        conversationId,
        companyId,
        perspectiveId,
        withCompanyId,
        withContextGroupId,
        invoiceColumns
      });
    });
  }

  downloadAttachments(attachments, fileName) {
    if (attachments.length === 1) {
      openUrlForDownload(attachments[0].downloadUrl);
    } else if (attachments.length > 1) {
      this.props.displayNotification("downloadNotification");
      this.props.fetchAttachmentsZip(
        attachments.map(attachment => attachment.attachmentId),
        fileName
      );
    }
  }

  getOpenInvoiceColumns(hover) {
    let { statementsStore, withCompanyId } = this.props;

    const allowPayments = (statementsStore.paymentInfo[withCompanyId] || {}).allowPayments;
    const allowDisputes = !isEmpty(
      (this.props.getCompanyInfo(this.props.withCompanyId).CompanySettings || {}).PortalDisputeActivityKey
    );

    const selectCol = {
      type: "rowSelect",
      width: "9%"
    };

    const invoiceDateCol = {
      header: noSort =>
        noSort ? (
          Resources.InvoiceDate.toLocaleUpperCase()
        ) : (
          <TableDataSortableHeader
            sortBy={statementsStore.openInvoicesSortBy}
            sortDirection={statementsStore.openInvoicesSortDirection}
            updateSort={(sortBy, sortDirection) => {
              this.onSortChange();
              this.props.updateOpenInvoicesSort(sortBy, sortDirection);
            }}
            text={Resources.InvoiceDate.toLocaleUpperCase()}
            sortKey={"IssueDate"}
          />
        ),
      sortable: noSort => noSort === false,
      content: row => <span className="fw-500">{formatDate(row.issueDate, true, false)}</span>,
      width: "17%"
    };
    const invoiceNumCol = {
      header: noSort =>
        noSort ? (
          Resources.InvoiceNumber.toLocaleUpperCase()
        ) : (
          <TableDataSortableHeader
            sortBy={statementsStore.openInvoicesSortBy}
            sortDirection={statementsStore.openInvoicesSortDirection}
            updateSort={(sortBy, sortDirection) => {
              this.onSortChange();
              this.props.updateOpenInvoicesSort(sortBy, sortDirection);
            }}
            text={Resources.InvoiceNumber.toLocaleUpperCase()}
            sortKey={"Id"}
          />
        ),
      sortable: noSort => noSort === false,
      content: row => {
        let hasAttachment = !isEmpty(row.attachments);

        return (
          <div className={`statements-invoice-id d-flex align-items-center ${hasAttachment ? "link" : ""}`}>
            <div className="flex-even overflow-ellipsis">
              {hasAttachment ? (
                <React.Fragment>
                  <button
                    className="invoice-num-attachment"
                    onClick={() => {
                      this.downloadAttachments(row.attachments, Resources.InvoiceNAttachments(row.id) + ".zip");
                    }}
                  >
                    <span className="invoice-num-attachment-link overflow-ellipsis" title={row.id}>
                      {row.id}
                    </span>
                    <IconAttachment height={15} style={{ marginLeft: "0.33rem", minWidth: "1rem" }} />
                  </button>
                </React.Fragment>
              ) : (
                <span className="overflow-ellipsis" title={row.id}>
                  {row.id}
                </span>
              )}
            </div>
            {(row.isDisputed || row.hasPromissoryNote) && (
              <div className="d-flex flex-column justify-content-start flex-even">
                {row.isDisputed && (
                  <div>
                    <button
                      className="button-indicator"
                      onClick={() => this.displayRelatedConversation(row.activeDispute)}
                      style={{ marginBottom: row.hasPromissoryNote ? "0.4rem" : "" }}
                    >
                      {Resources.Disputed}
                    </button>
                  </div>
                )}
                {row.hasPromissoryNote && (
                  <div>
                    <button
                      className="button-indicator"
                      onClick={() =>
                        this.displayRelatedConversation(
                          find(row.payments, payment => payment.paymentType === "Promise to Pay")
                        )
                      }
                    >
                      {Resources.Promised}
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        );
      },
      width: "25%"
    };

    const amountDueCol = {
      header: noSort =>
        noSort ? (
          Resources.AmountDue.toLocaleUpperCase()
        ) : (
          <TableDataSortableHeader
            sortBy={statementsStore.openInvoicesSortBy}
            sortDirection={statementsStore.openInvoicesSortDirection}
            updateSort={(sortBy, sortDirection) => {
              this.onSortChange();
              this.props.updateOpenInvoicesSort(sortBy, sortDirection);
            }}
            text={Resources.AmountDue.toLocaleUpperCase()}
            sortKey={"Amount"}
          />
        ),
      sortable: noSort => noSort === false,
      content: row => (
        <div className="statements-currency-row">
          <span className="mr-2">{getCurrencySymbol(row.currencyId)}</span>
          <span>{formatCurrency(row.amount, "")}</span>
        </div>
      ),
      width: "9rem"
    };

    const fillerCol = { width: "8%", content: row => null };

    const dueDateCol = {
      header: noSort =>
        noSort ? (
          Resources.Due_Date.toLocaleUpperCase()
        ) : (
          <TableDataSortableHeader
            sortBy={statementsStore.openInvoicesSortBy}
            sortDirection={statementsStore.openInvoicesSortDirection}
            updateSort={(sortBy, sortDirection) => {
              this.onSortChange();
              this.props.updateOpenInvoicesSort(sortBy, sortDirection);
            }}
            text={Resources.Due_Date.toLocaleUpperCase()}
            sortKey={"DueDate"}
          />
        ),
      sortable: noSort => noSort === false,
      content: row => <span>{row.transactionType === "CM" ? Resources.NA : formatDate(row.dueDate, true, false)}</span>,
      width: "14%"
    };

    const statusCol = {
      header: noSort =>
        noSort ? (
          Resources.Status.toLocaleUpperCase()
        ) : (
          <TableDataSortableHeader
            sortBy={statementsStore.openInvoicesSortBy}
            sortDirection={statementsStore.openInvoicesSortDirection}
            updateSort={(sortBy, sortDirection) => {
              this.onSortChange();
              this.props.updateOpenInvoicesSort(sortBy, sortDirection);
            }}
            text={Resources.Status.toLocaleUpperCase()}
            sortKey={"DueDate"}
          />
        ),
      sortable: noSort => noSort === false,
      content: row => {
        let isPastDue = moment(row.dueDate).isBefore();
        return (
          <React.Fragment>
            <span>{isPastDue ? Resources.PastDue : row.status}</span>
            {isPastDue ? <IconAlert className="pl-2" height={15} /> : ""}
          </React.Fragment>
        );
      },
      width: "15%"
    };

    const columns = [selectCol, invoiceDateCol, invoiceNumCol, amountDueCol, fillerCol, dueDateCol, statusCol];
    const hoverColumns = [
      selectCol,
      invoiceDateCol,
      invoiceNumCol,
      amountDueCol,
      fillerCol,
      {
        colSpan: 2,
        content: (row, i) => (
          <div className="flex-end flex-align-center">
            {allowPayments === true && (
              <button
                className="button-action-icon"
                onClick={() => {
                  this.props.displayFlyout("makePaymentFlyout", {
                    selectedInvoices: [row],
                    columns,
                    withCompanyId: this.props.withCompanyId,
                    companyId: this.props.companyId,
                    perspectiveId: this.props.perspectiveId,
                    clearSelectedRows: () => this.setState({ selectedRows: [], selectedKeys: [] })
                  });
                }}
                disabled={row.amount < 0}
              >
                <IconDollarSign height="24" className="icon-button" />
              </button>
            )}
            <button
              className="button-action-icon"
              onClick={() =>
                this.props.displayFlyout("messageFlyout", {
                  columns,
                  selectedInvoices: [row],
                  companyId: this.props.companyId,
                  perspectiveId: this.props.perspectiveId,
                  withContextGroupId: this.props.withContextGroupId,
                  withCompanyId: this.props.withCompanyId,
                  fetchTemplate: () => {
                    return this.props.fetchOpenInvoicesTemplate(
                      this.props.companyId,
                      this.props.perspectiveId,
                      this.props.withCompanyId,
                      { invoiceIds: [row.id] }
                    );
                  },
                  clearSelectedRows: () => this.setState({ selectedRows: [], selectedKeys: [] })
                })
              }
            >
              <IconMessage height="20" className="icon-button" />
            </button>
            {allowDisputes && (
              <button
                className="button-action-icon"
                onClick={() => {
                  this.props.displayFlyout("disputeFlyout", {
                    selectedInvoices: [row],
                    columns,
                    withCompanyId: this.props.withCompanyId,
                    companyId: this.props.companyId,
                    withContextGroupId: this.props.withContextGroupId,
                    clearSelectedRows: () => this.setState({ selectedRows: [], selectedKeys: [] })
                  });
                }}
              >
                <IconHelp height="20" className="icon-button" />
              </button>
            )}
            <button
              className="button-action-icon"
              onClick={() => {
                this.props.displayFlyout("promiseToPayFlyout", {
                  selectedInvoices: [row],
                  columns,
                  withCompanyId: this.props.withCompanyId,
                  companyId: this.props.companyId,
                  withContextGroupId: this.props.withContextGroupId,
                  clearSelectedRows: () => this.setState({ selectedRows: [], selectedKeys: [] })
                });
              }}
            >
              <IconStar height="20" className="icon-button" />
            </button>
            <button
              className="button-action-icon"
              disabled={isEmpty(row.attachments)}
              onClick={() => {
                this.downloadAttachments(row.attachments, Resources.InvoiceNAttachments(row.id) + ".zip");
              }}
            >
              <IconDownload height="20" className="icon-button" />
            </button>
            <button className="button-action-icon" onClick={() => this.exportRows([row])}>
              <IconExport height="20" className="icon-button" />
            </button>
          </div>
        )
      }
    ];
    if (hover) {
      return hoverColumns;
    } else {
      return columns;
    }
  }

  renderUnappliedPayments() {
    const { statementsStore } = this.props;
    if (statementsStore.unappliedPayments.length === 0 && statementsStore.isFetchingUnappliedPayments === false) {
      return null;
    }

    const transactionDateCol = {
      header: (
        <TableDataSortableHeader
          sortBy={statementsStore.paymentsSortBy}
          sortDirection={statementsStore.paymentsSortDirection}
          updateSort={(sortBy, sortDirection) => {
            this.onSortChange();
            this.props.updatePaymentsSort(sortBy, sortDirection);
          }}
          text={Resources.TransactionDate.toLocaleUpperCase()}
          sortKey={"ProcessedDate"}
        />
      ),
      sortable: noSort => noSort === false,
      content: row => (
        <span className="fw-500">
          {formatDate(row.processedDate, row.paymentName.toLowerCase() !== "pending payment", false)}
        </span>
      ),
      width: "17%"
    };

    const transactionIdCol = {
      header: (
        <TableDataSortableHeader
          sortBy={statementsStore.paymentsSortBy}
          sortDirection={statementsStore.paymentsSortDirection}
          updateSort={(sortBy, sortDirection) => {
            this.onSortChange();
            this.props.updatePaymentsSort(sortBy, sortDirection);
          }}
          text={Resources.TransactionId.toLocaleUpperCase()}
          sortKey={"Id"}
        />
      ),
      sortable: noSort => noSort === false,
      content: row => {
        let hasAttachment = !isEmpty(row.attachments);
        return (
          <div className={`statements-invoice-id ${hasAttachment ? "link" : ""}`}>
            <div className="mr-2">{row.id}</div>
            {hasAttachment && <span className={Icons.filePdf} />}
          </div>
        );
      },
      width: "17%"
    };

    const amountCol = {
      header: (
        <TableDataSortableHeader
          sortBy={statementsStore.paymentsSortBy}
          sortDirection={statementsStore.paymentsSortDirection}
          updateSort={(sortBy, sortDirection) => {
            this.onSortChange();
            this.props.updatePaymentsSort(sortBy, sortDirection);
          }}
          text={Resources.PaymentAmt.toLocaleUpperCase()}
          sortKey={"Amount"}
        />
      ),
      sortable: noSort => noSort === false,
      content: row => (
        <div className="statements-currency-row">
          <span className="mr-2">{getCurrencySymbol(row.currencyId)}</span>
          <span>{formatCurrency(row.amount, "")}</span>
        </div>
      ),
      width: "9rem"
    };

    const unappliedAmountCol = {
      header: (
        <TableDataSortableHeader
          sortBy={statementsStore.paymentsSortBy}
          sortDirection={statementsStore.paymentsSortDirection}
          updateSort={(sortBy, sortDirection) => {
            this.onSortChange();
            this.props.updatePaymentsSort(sortBy, sortDirection);
          }}
          text={Resources.UnappliedAmt.toLocaleUpperCase()}
          sortKey={"UnappliedAmt"}
        />
      ),
      sortable: noSort => noSort === false,
      content: row => (
        <div className="statements-currency-row">
          <span className="mr-2">{getCurrencySymbol(row.currencyId)}</span>
          <span>{formatCurrency(row.unappliedAmount, "")}</span>
        </div>
      ),
      width: "10rem"
    };

    const fillerCol = { width: "2%", content: row => null };

    const transactionTypeCol = {
      header: (
        <TableDataSortableHeader
          sortBy={statementsStore.paymentsSortBy}
          sortDirection={statementsStore.paymentsSortDirection}
          updateSort={(sortBy, sortDirection) => {
            this.onSortChange();
            this.props.updatePaymentsSort(sortBy, sortDirection);
          }}
          text={Resources.Type.toLocaleUpperCase()}
          sortKey={"PaymentType"}
        />
      ),
      sortable: noSort => noSort === false,
      content: row => (
        <span>{row.paymentName.toLowerCase() === "pending payment" ? Resources.PendingPayment : row.paymentType}</span>
      ),
      width: "15%"
    };

    const invoicesCol = {
      header: Resources.Invoices.toLocaleUpperCase(),
      content: row => {
        return (
          <span>{Resources.ZeroInvoices}</span>
          // <button onClick={() => this.props.displayFlyout("applyPaymentFlyout", { payment: row })}>
          //   <span className="statements-invoice-id invoice-num-attachment-link link">{Resources.ApplyToInvoice}</span>
          // </button>
        );
      }
    };

    const columns = [
      transactionDateCol,
      transactionIdCol,
      amountCol,
      fillerCol,
      unappliedAmountCol,
      fillerCol,
      transactionTypeCol,
      invoicesCol
    ];
    const maxRows = statementsStore.isShowingSearchResult
      ? statementsStore.unappliedPayments.length
      : statementsStore.unappliedPaymentsCount;

    return (
      <Card type="table" className="unapplied-card">
        <div className="unapplied-table-header">
          {statementsStore.unappliedPayments.length > 0 ? (
            <React.Fragment>
              <IconAlertCircle className="unapplied-alert-icon" />
              <div className="portal-input-label mb-0">
                {Resources.UnappliedPaymentsAlert(
                  statementsStore.unappliedPaymentsCount,
                  formatCurrency(statementsStore.paymentsTotalUnapplied, statementsStore.selectedCurrency)
                )}
              </div>
            </React.Fragment>
          ) : (
            <div className="portal-input-label mb-0">{Resources.CheckingForUnappliedPayments}</div>
          )}
        </div>
        {statementsStore.unappliedPayments.length > 0 && (
          <TableData
            name="unapplied-payments__table"
            pagination
            data={statementsStore.unappliedPayments}
            columns={columns}
            rowHeight="4em"
            onLoadMore={pageSelected => this.handleLoadMoreUnapplied(pageSelected)}
            maxRows={maxRows}
            loading={statementsStore.isFetchingUnappliedPayments}
            rowClassName="statements-view-row no-hover"
            emptyRender={
              <div className="flex-center">
                <h4 className="mt-5">{Resources.EmptyStatements}</h4>
              </div>
            }
          />
        )}
      </Card>
    );
  }

  renderEmptyState(useCard) {
    const svgInternals = (
      <g fill="none" fillRule="evenodd">
        <path
          fill="#F4F3FF"
          d="M240.479042 44C246.289607 44 251 48.7010101 251 54.5S246.289607 65 240.479042 65h-60.119761c5.810565 0 10.520959 4.7010101 10.520959 10.5S186.169846 86 180.359281 86h33.065869c5.810564 0 10.520958 4.7010101 10.520958 10.5 0 5.79899-4.710394 10.5-10.520958 10.5h-15.291331c-7.326364 0-13.265556 4.70101-13.265556 10.5 0 3.865993 3.005988 7.365993 9.017965 10.5 5.810564 0 10.520958 4.70101 10.520958 10.5s-4.710394 10.5-10.520958 10.5H69.1377246c-5.8105648 0-10.5209581-4.70101-10.5209581-10.5S63.3271598 128 69.1377246 128H10.5209581C4.71039338 128 0 123.29899 0 117.5S4.71039338 107 10.5209581 107h60.1197605c5.8105647 0 10.520958-4.70101 10.520958-10.5 0-5.7989899-4.7103933-10.5-10.520958-10.5H33.0658683c-5.8105647 0-10.5209581-4.7010101-10.5209581-10.5S27.2553036 65 33.0658683 65h60.1197604c-5.8105647 0-10.520958-4.7010101-10.520958-10.5S87.375064 44 93.1856287 44H240.479042zm0 42C246.289607 86 251 90.7010101 251 96.5c0 5.79899-4.710393 10.5-10.520958 10.5s-10.520958-4.70101-10.520958-10.5c0-5.7989899 4.710393-10.5 10.520958-10.5z"
        />
        <path
          fill="#FFF"
          stroke="#BEB6FF"
          strokeWidth="3.5"
          d="M166.969231 64.75c1.182132 0 2.252364.4790605 3.027055 1.2536335.774594.7744765 1.253714 1.8443996 1.253714 3.0262172h0v91.9552243c0 .349269-.141611.66546-.370528.894343-.229014.228979-.5454.370582-.894857.370582h0-67.846153c-.349457 0-.665843-.141603-.894857-.370582-.228917-.228883-.370528-.545074-.370528-.894343h0v-27.45182l-1.8107799.062929c-.2301273.007997-.4611678.012025-.6930663.012025-5.952978 0-11.3333882-2.660451-15.2314583-6.935103C79.1839635 122.337331 76.75 116.344462 76.75 109.731343c0-6.613119 2.4339635-12.6059876 6.3877725-16.9417629 3.8980701-4.2746516 9.2784803-6.9351025 15.2314583-6.9351025.2318985 0 .462939.0040278.6930663.0120252h0l1.8107799.0629288V69.0298507c0-1.1818176.479119-2.2517407 1.253714-3.0262172.774691-.774573 1.844923-1.2536335 3.027055-1.2536335h0zM98.3692308 95.1679104c-3.5223673 0-6.7295251 1.6492273-9.0441163 4.3687243-2.2205915 2.6090533-3.6115248 6.2089613-3.6115248 10.1947083 0 3.985748 1.3909333 7.585655 3.6115248 10.194709 2.3145912 2.719497 5.521749 4.368724 9.0441163 4.368724.3004289 0 .5984641-.012048.8937761-.035737l1.6100701-.129155V95.3328026l-1.6100701-.1291547c-.295312-.023689-.5933472-.0357375-.8937761-.0357375z"
        />
        <path
          stroke="#DEDAFF"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3.5"
          d="M159.5 113.689386v7.196525m0-43.6103183v27.7933733"
        />
        <path
          stroke="#BEB6FF"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3.5"
          d="M48.1924412 153h10.3374733m161.1497065 0H224m-45.128205 0h33.737446m-145.0536854 0h32.2728365"
        />
        <path
          stroke="#DEDAFF"
          strokeLinecap="round"
          strokeWidth="3.5"
          d="M140.016577 2C136.005526 8.29032074 134 13.7642216 134 18.4217026c0 8.2946801 7 12.0093905 7 20.9943828 0 4.5602882-2.333333 9.0882597-7 13.5839146M121.20468 18C119.809948 26.4141139 126 27.9702226 126 35.5138335c0 3.8287222-1.59844 7.6574443-4.79532 11.4861665M150.122888 15c-2.010365 5.1513862-.88094 8.2912986 0 10.1394581C152.005895 29.0898964 155 32.5 155 36.5199571c0 4.6689227-1.625704 9.1622703-4.877112 13.4800429"
        />
      </g>
    );
    if (useCard) {
      return (
        <Card className="open-invoices-empty-state">
          <svg viewBox="0 0 251 164" className="mt-4">
            {svgInternals}
          </svg>
          <h2>{Resources.NiceWorkYoureAllCaughtUp}</h2>
          <p>{Resources.PortalOpenInvoicesEmpty}</p>
        </Card>
      );
    } else {
      return (
        <div className="open-invoices-empty-state">
          <svg viewBox="0 0 251 164">{svgInternals}</svg>
          <h2>{Resources.NiceWorkYoureAllCaughtUp}</h2>
          <p>{Resources.PortalOpenInvoicesEmpty}</p>
        </div>
      );
    }
  }

  render() {
    let { statementsStore, withCompanyId, withCompanyName, companyId } = this.props;
    let { selectedRows, selectedKeys } = this.state;

    const hoverColumns = this.getOpenInvoiceColumns(true);
    const columns = this.getOpenInvoiceColumns(false);

    const data = statementsStore.openInvoices;
    const loading = statementsStore.isFetchingOpenInvoices;
    const maxRows = statementsStore.isShowingSearchResult ? data.length : statementsStore.openInvoicesCount;

    const totalAmount =
      (statementsStore.openInvoicesCurrencyStats[statementsStore.selectedCurrency] || {}).totalBalance ||
      statementsStore.openTotalAmount;
    const pastDueInvoices =
      (statementsStore.openInvoicesCurrencyStats[statementsStore.selectedCurrency] || {}).pastDueCount ||
      statementsStore.openPastDueCount;

    const allowPayments = (statementsStore.paymentInfo[withCompanyId] || {}).allowPayments;
    const allowDisputes = !isEmpty(
      (this.props.getCompanyInfo(this.props.withCompanyId).CompanySettings || {}).PortalDisputeActivityKey
    );

    const autoPayAllowed = (statementsStore.paymentInfo[withCompanyId] || {}).allowAutomaticPayments;
    const autoPayEnabled =
      ((statementsStore.paymentInfo[withCompanyId] || {}).automaticPaymentConfiguration || {})
        .automaticPaymentEnabled || false;

    const hasMultiCurrency = (statementsStore.currencies || []).length > 1;

    const helmet = (
      <Helmet>
        <title>
          {withCompanyName} | {Resources.OpenInvoices}
        </title>
      </Helmet>
    );

    if (
      (loading || statementsStore.isFetchingUnappliedPayments) &&
      (isEmpty(totalAmount) || totalAmount === 0) &&
      (isEmpty(pastDueInvoices) || pastDueInvoices === 0)
    ) {
      return (
        <React.Fragment>
          {helmet}
          <MainLoader fullScreen className="portal-page-loader" />
        </React.Fragment>
      );
    }

    if (
      isEmpty(data) &&
      isEmpty(statementsStore.openFromDate) &&
      isEmpty(statementsStore.openToDate) &&
      isEmpty(statementsStore.openFromDueDate) &&
      isEmpty(statementsStore.openToDueDate) &&
      isEmpty(this.state.searchTerm) &&
      isEmpty(statementsStore.unappliedPayments) &&
      (isEmpty(totalAmount) || totalAmount === 0) &&
      (isEmpty(pastDueInvoices) || pastDueInvoices === 0)
    ) {
      return (
        <React.Fragment>
          {helmet}
          {hasMultiCurrency && <StatementCurrencySelector displayCount />}
          {this.renderEmptyState(hasMultiCurrency)}
        </React.Fragment>
      );
    }

    let currencySelectorDisplayValues = {};
    Object.keys(statementsStore.openInvoicesCurrencyStats).map(
      key => (currencySelectorDisplayValues[key] = statementsStore.openInvoicesCurrencyStats[key].count)
    );

    return (
      <React.Fragment>
        {helmet}
        {hasMultiCurrency && <StatementCurrencySelector displayCount displayCounts={currencySelectorDisplayValues} />}
        <Card className="statements-summary">
          <div className="statements-summary-row">
            <div className="statements-summary-item">
              <div className="statements-summary-item-header">{stringToSentenceCase(Resources.OpenInvoices)}</div>
              <div className="statements-summary-item-data">
                {(statementsStore.openInvoicesCurrencyStats[statementsStore.selectedCurrency] || {}).count ||
                  statementsStore.openInvoicesCount ||
                  "-"}
              </div>
            </div>
            <div className="statements-summary-item">
              <div className="statements-summary-item-header flex-align-center">
                {stringToSentenceCase(Resources.TotalAccountBalance)}
                <div style={{ position: "relative" }}>
                  <button
                    className="button-info"
                    style={{ marginLeft: ".33rem" }}
                    onClick={() => this.setState({ showAccountBalanceHelperText: true })}
                  >
                    <IconInfo height={16} />
                  </button>
                  {this.state.showAccountBalanceHelperText && (
                    <Card className="account-balance-info">
                      <div className="flex-align-start" style={{ width: "23.6rem" }}>
                        <span className="account-balance-helper-text">{Resources.AccountBalanceHelperText}</span>
                        <button
                          onClick={() => this.setState({ showAccountBalanceHelperText: false })}
                          style={{ padding: "0", marginTop: "-.4rem" }}
                        >
                          <IconClose height={14} />
                        </button>
                      </div>
                    </Card>
                  )}
                </div>
              </div>
              <div className="statements-summary-item-data">
                {formatCurrency(totalAmount, statementsStore.selectedCurrency)}
              </div>
            </div>
            <div className="statements-summary-item">
              <div className="statements-summary-item-header">{stringToSentenceCase(Resources.PastDueInvoices)}</div>
              <div className="statements-summary-item-data">{pastDueInvoices}</div>
            </div>
          </div>
          {autoPayAllowed && (
            <AutoPayIndicator
              onClickManage={() => this.props.displayFlyout("manageAutoPayFlyout", { companyId, withCompanyId })}
              autoPayEnabled={autoPayEnabled}
            />
          )}
        </Card>
        {this.renderUnappliedPayments()}
        <Card type="table" className="portal-table">
          <div className="table-data-card-header">
            <div className="table-data-card-header-buttons">
              {allowPayments === true && (
                <button
                  className="button-primary"
                  onClick={() => {
                    const selectedInvoices = [...selectedRows].filter(row => row.transactionType !== "CM");
                    let filteredOutCreditMemo = selectedInvoices.length !== selectedRows.length;
                    this.props.displayFlyout("makePaymentFlyout", {
                      selectedInvoices,
                      columns,
                      withCompanyId: this.props.withCompanyId,
                      companyId: this.props.companyId,
                      perspectiveId: this.props.perspectiveId,
                      filteredOutCreditMemo,
                      clearSelectedRows: () => this.setState({ selectedRows: [], selectedKeys: [] })
                    });
                  }}
                  disabled={
                    selectedRows.length !== 0 && selectedRows.every(inv => (inv || {}).transactionType === "CM")
                  }
                >
                  <IconDollarSign height="24" className="button-primary-icon" />
                  <span>{Resources.Pay}</span>
                </button>
              )}
              <button
                className="button-primary"
                disabled={isEmpty(selectedRows)}
                onClick={() => {
                  const selectedInvoices = selectedRows;
                  this.props.displayFlyout("messageFlyout", {
                    columns,
                    selectedInvoices,
                    companyId: this.props.companyId,
                    perspectiveId: this.props.perspectiveId,
                    withContextGroupId: this.props.withContextGroupId,
                    withCompanyId: this.props.withCompanyId,
                    fetchingTemplate: () => this.props.statementsStore.isFetchingOpenInvoicesTemplate,
                    fetchTemplate: () => {
                      return this.props.fetchOpenInvoicesTemplate(
                        this.props.companyId,
                        this.props.perspectiveId,
                        this.props.withCompanyId,
                        { invoiceIds: selectedInvoices.map(i => i.id) }
                      );
                    },
                    clearSelectedRows: () => this.setState({ selectedRows: [], selectedKeys: [] })
                  });
                }}
              >
                <IconMessage height="20" className="button-primary-icon" />
                {Resources.SendMessage}
              </button>
              {allowDisputes && (
                <button
                  className="button-primary"
                  disabled={isEmpty(selectedRows)}
                  onClick={() => {
                    const selectedInvoices = [...selectedRows];
                    this.props.displayFlyout("disputeFlyout", {
                      selectedInvoices,
                      columns,
                      withCompanyId: this.props.withCompanyId,
                      companyId: this.props.companyId,
                      withContextGroupId: this.props.withContextGroupId,
                      clearSelectedRows: () => this.setState({ selectedRows: [], selectedKeys: [] })
                    });
                  }}
                >
                  <IconHelp height="20" className="button-primary-icon" />
                  {Resources.Dispute}
                </button>
              )}
              <button
                className="button-primary"
                disabled={isEmpty(selectedRows)}
                onClick={() => {
                  const selectedInvoices = [...selectedRows];
                  this.props.displayFlyout("promiseToPayFlyout", {
                    selectedInvoices,
                    columns,
                    withCompanyId: this.props.withCompanyId,
                    companyId: this.props.companyId,
                    withContextGroupId: this.props.withContextGroupId,
                    clearSelectedRows: () => this.setState({ selectedRows: [], selectedKeys: [] })
                  });
                }}
              >
                <IconStar height="20" className="button-primary-icon" />
                {Resources.PromiseToPay}
              </button>
              <button
                className="button-primary"
                disabled={isEmpty(selectedRows) || !selectedRows.some(row => !isEmpty((row || {}).attachments))}
                onClick={() => {
                  let allAttachments = [];
                  selectedRows.forEach(row => {
                    allAttachments = [...allAttachments, ...row.attachments];
                  });
                  this.downloadAttachments(allAttachments, Resources.InvoiceAttachments + ".zip");
                }}
              >
                <IconDownload height="20" className="button-primary-icon" />
                {Resources.DownloadAttachments}
              </button>
              <button
                className="button-primary"
                disabled={isEmpty(selectedRows)}
                onClick={() => this.exportRows(this.state.selectedRows)}
              >
                <IconExport height="20" className="button-primary-icon" />
                {Resources.Export}
              </button>
            </div>
            <div className="d-flex align-items-center">
              <div className="table-data-card-header-search">
                <AutoCompleteInput
                  className="auto-complete-input__table-data-search"
                  placeholder={Resources.Search}
                  onChange={e => {
                    const searchTerm = e.target.value;
                    this.setState({ searchTerm });
                    this.props.fetchStatementSearchResults({
                      companyId: this.props.companyId,
                      perspectiveId: this.props.perspectiveId,
                      withCompanyId: this.props.withCompanyId,
                      searchTerm,
                      type: "open"
                    });
                  }}
                  isShowingSearchResult={statementsStore.isShowingSearchResult}
                  handleClearResult={() => {
                    this.setState({ searchTerm: "" });
                    this.props.clearStatementsSearchResults();
                    this.props.fetchOpenInvoices(
                      this.props.companyId,
                      this.props.perspectiveId,
                      this.props.withCompanyId,
                      null,
                      null,
                      this.props.pageRowCount
                    );
                  }}
                  text={this.state.searchTerm}
                  noResultsMessage={Resources.NoStatementSearchResultsMessage}
                  showNoResultsMessage={statementsStore.fetchedStatementsSearchResults}
                  maxOptions={7}
                  loading={statementsStore.fetchingStatementsSearchResults}
                  handleSelectOption={option => {
                    this.setState({ searchTerm: option.id });
                    this.props.fetchStatementSearchResults({
                      companyId: this.props.companyId,
                      perspectiveId: this.props.perspectiveId,
                      withCompanyId: this.props.withCompanyId,
                      searchTerm: option.id,
                      type: "open"
                    });
                    this.props.setOpenInvoicesToSearchResult([option]);
                  }}
                  callToActionButton={
                    <div
                      className="dropdown-item-clean dropdown-call-to-action"
                      onClick={() => this.props.setOpenInvoicesToSearchResult(statementsStore.searchResults)}
                    >
                      {Resources.SeeAllInvoiceResults(this.state.searchTerm)}
                    </div>
                  }
                  renderOption={option => `${Resources.InvoiceNumber} ${option.id}`}
                  options={statementsStore.searchResults}
                  width="300px"
                />
              </div>
              <div className="dropdown">
                <button
                  className="button-action-icon dropdown-toggle customer-portal-button-filter"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  style={{ marginTop: "1.6rem" }}
                >
                  <IconFilter height="18"></IconFilter>
                </button>
                <div className="dropdown-menu">
                  <form>
                    <div className="mb-2">{Resources.InvoiceDate}</div>
                    <div
                      onClick={e => {
                        e.stopPropagation();
                      }}
                    >
                      <span>
                        <DatePicker
                          startOpen
                          className="date-picker-input"
                          calendarClassName="date-picker-calendar"
                          dayClassName={d => "date-picker-day"}
                          dateFormat="MMM d, yyyy"
                          // startDate={new Date(this.state.fromDate)}
                          // endDate={new Date(this.state.toDate)}
                          maxDate={new Date(this.state.toDate)}
                          selected={new Date(this.state.fromDate)}
                          // selectsStart
                          onChange={d =>
                            this.setState({
                              fromDate: d
                            })
                          }
                        />
                      </span>
                      <IconArrowRight line className="mr-3 ml-3" height={14}></IconArrowRight>
                      <span>
                        <DatePicker
                          startOpen
                          className="date-picker-input"
                          calendarClassName="date-picker-calendar"
                          dayClassName={d => "date-picker-day"}
                          dateFormat="MMM d, yyyy"
                          // startDate={new Date(this.state.fromDate)}
                          // endDate={new Date(this.state.toDate)}
                          minDate={new Date(this.state.fromDate)}
                          selected={new Date(this.state.toDate)}
                          // selectsStart
                          onChange={d =>
                            this.setState({
                              toDate: d
                            })
                          }
                        />
                      </span>
                    </div>

                    <div className="mb-2 mt-4">{Resources.Due_Date}</div>
                    <div
                      onClick={e => {
                        e.stopPropagation();
                      }}
                    >
                      <span>
                        <DatePicker
                          startOpen
                          className="date-picker-input"
                          calendarClassName="date-picker-calendar"
                          dayClassName={d => "date-picker-day"}
                          dateFormat="MMM d, yyyy"
                          // startDate={new Date(this.state.fromDate)}
                          // endDate={new Date(this.state.toDate)}
                          maxDate={new Date(this.state.toDueDate)}
                          selected={new Date(this.state.fromDueDate)}
                          // selectsStart
                          onChange={d =>
                            this.setState({
                              fromDueDate: d
                            })
                          }
                        />
                      </span>
                      <IconArrowRight line className="mr-3 ml-3" height={14}></IconArrowRight>
                      <span>
                        <DatePicker
                          startOpen
                          className="date-picker-input"
                          calendarClassName="date-picker-calendar"
                          dayClassName={d => "date-picker-day"}
                          dateFormat="MMM d, yyyy"
                          // startDate={new Date(this.state.fromDate)}
                          // endDate={new Date(this.state.toDate)}
                          minDate={new Date(this.state.fromDueDate)}
                          selected={new Date(this.state.toDueDate)}
                          // selectsStart
                          onChange={d =>
                            this.setState({
                              toDueDate: d
                            })
                          }
                        />
                      </span>
                    </div>
                  </form>
                  <div className="filter-dropdown-buttons">
                    <button
                      className="filter-dropdown-reset"
                      onClick={() => {
                        this.setState({
                          fromDate: this.defaultState.fromDate,
                          toDate: this.defaultState.toDate,
                          fromDueDate: this.defaultState.fromDueDate,
                          toDueDate: this.defaultState.toDueDate
                        });
                        this.props.updateOpenInvoicesDate(null, null, null, null);
                      }}
                    >
                      {Resources.Reset}
                    </button>
                    <button
                      className="filter-dropdown-save"
                      onClick={() => {
                        this.props.updateOpenInvoicesDate(
                          moment(this.state.fromDate)
                            .utc()
                            .startOf("day")
                            .format("YYYY-MM-DD"),
                          moment(this.state.toDate)
                            .utc()
                            .startOf("day")
                            .format("YYYY-MM-DD"),
                          moment(this.state.fromDueDate)
                            .utc()
                            .startOf("day")
                            .format("YYYY-MM-DD"),
                          moment(this.state.toDueDate)
                            .utc()
                            .startOf("day")
                            .format("YYYY-MM-DD")
                        );
                      }}
                    >
                      {Resources.Save}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <TableData
            name="open-invoices__table"
            pagination
            data={data}
            columns={columns}
            hoverColumns={hoverColumns}
            rowHeight="4em"
            onLoadMore={pageSelected => this.handleLoadMore(pageSelected)}
            maxRows={maxRows}
            loading={loading}
            rowClassName="statements-view-row"
            selectedKeys={selectedKeys}
            rowKey="ledgerHash"
            onRowSelectToggle={key =>
              this.toggleSelectRow(
                key,
                (Array.isArray(key) ? [...key] : [key]).map(k => find(data, row => row.ledgerHash === k))
              )
            }
            emptyRender={
              <div className="table-data-empty-render">
                <h4 className="mt-5">{Resources.EmptyStatements}</h4>
              </div>
            }
          />
        </Card>
      </React.Fragment>
    );
  }
}

const storeToProps = store => {
  return {
    contextGroupsStore: store.contextGroups,
    statementsStore: store.statements,
    perspectivesStore: store.perspectives,
    ledgerStore: store.ledger,
    pageRowCount: store.general.pageRowCount
  };
};

export default connect(storeToProps, dispatchToProps)(OpenInvoices);
