import React, { Component } from "react";

import Resources from "../../lib/resources";
import Icons from "../../lib/icons";

class SyncedWith extends Component {
  render() {
    let { connectorName, text } = this.props;
    return (
      <div className="dark-gray-font font-med">
        <span className={Icons.sync} />
        <span className="ml-1">
          {text
            ? `${text} ${connectorName}`
            : Resources.SyncedWith(connectorName || Resources.RegisteredAccountingPackage.toLowerCase())}
        </span>
      </div>
    );
  }
}

export default SyncedWith;
