import React, { Component } from "react";
import { connect } from "react-redux";
import $ from "jquery";

import { dispatchToProps as maDP } from "../store/manage-actions";
import { dispatchToProps as lgrDP } from "../store/ledger-actions";
import { dispatchToProps as persDP } from "../store/perspectives-actions";

import ReactQuill from "./library/lockstepQuill";
import Dropdown from "./library/dropdown";
import HtmlEditor from "./htmlEditor";
import IconCode from "./library/icons/iconCode";
import SelectInput from "./library/selectInput";

import Resources from "../lib/resources";
import Icons from "../lib/icons";
import { isEmpty, find, spliceString } from "../lib/utils";
import TextInput from "./library/textInput";

//TODO: Refactor this component to use the conversation editor
//      instead and also refactor conversation editor to work
//      with what is needed for this components needs.

const dispatchToProps = dispatch => ({
  ...lgrDP(dispatch),
  ...maDP(dispatch),
  ...persDP(dispatch)
});

class ReplyTemplateForm extends Component {
  state = {
    errorMessage: "",
    mode: null,
    templateName: "",
    templateId: "",
    type: "email",
    subject: "",
    htmlBody: "",
    showCcBcc: false,
    from: "",
    to: "",
    cc: "",
    bcc: "",
    attachments: [],
    defaultResource: "",
    defaultResourceDisplay: "",
    resourceId: "",
    saving: false,
    editorSelection: { index: 0, length: 0 },
    quillConfig: {
      theme: "snow",
      modules: {
        toolbar: [
          [{ header: [1, 2, 3, 4, false] }],
          ["bold", "italic", "underline"],
          ["strike", "blockquote"],
          [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
          ["link"]
        ],
        keyboard: {
          bindings: {
            tab: {
              key: 9,
              handler: () => {
                return true;
              }
            }
          }
        }
      },
      readOnly: false
    },

    selectedIndex: 0,
    selectedLength: 0
  };

  componentDidMount() {
    let mountState = {};
    if (isEmpty(this.props.templateData) !== true) {
      mountState.templateName = this.props.templateData.emailTemplateID || "";
      mountState.templateId = this.props.templateData.emailTemplateKey || "";
      mountState.subject = this.props.templateData.subject || "";
      mountState.htmlBody = this.props.templateData.emailBody || "";
    }

    this.setState(mountState);

    this.tryUpdate();
  }

  componentDidUpdate() {
    this.tryUpdate();
  }

  tryUpdate() {
    // if (this.htmlEditor) {
    //   let toolbar = $(".quill .ql-toolbar");
    //   this.setTabIndex(toolbar[0], -1);
    // }
    if (!isEmpty(this.state.resourceId) && this.state.defaultResource === "") {
      let resource = this.props.getResource("resourceId", this.state.resourceId);
      this.setState({
        defaultResource: resource.resourceName,
        defaultResourceDisplay: this.getResourceDisplayName(resource.resourceName)
      });
    }
  }

  getResourceDisplayName(resourceName) {
    if (resourceName === "Email") {
      return Resources.New;
    }
    return Resources[resourceName];
  }

  setTabIndex(item, tabIndex) {
    item.tabIndex = tabIndex;
    if (item.children) {
      for (let i = 0; i < item.children.length; i++) {
        this.setTabIndex(item.children[i], tabIndex);
      }
    }
  }

  getText() {
    let editor = this.htmlEditor.getEditor();
    let uEditor = this.htmlEditor.makeUnprivilegedEditor(editor);
    return uEditor.getText();
  }

  isValid = () => {
    if (this.state.templateName === "" || this.state.htmlBody === "") {
      return false;
    }

    return true;
  };

  templateNameExists() {
    const { manageStore } = this.props;
    let allTemplates = [];
    Object.keys(manageStore.allCompanyReplyTemplates).forEach(perspectiveId => {
      allTemplates = [...allTemplates, ...manageStore.allCompanyReplyTemplates[perspectiveId]];
    });

    return !isEmpty(
      find(allTemplates, t => {
        return (
          t.perspectiveEmailTemplateName === this.state.templateName.trim() &&
          t.perspectiveEmailTemplateId !== this.state.templateId
        );
      })
    );
  }

  insertVariable(variable) {
    if (isEmpty(variable)) {
      return;
    }

    const editor = this.htmlEditor.getEditor();
    const uEditor = this.htmlEditor.makeUnprivilegedEditor(editor);
    const { index, length } = this.state.editorSelection;

    editor.setText(spliceString(uEditor.getText(), index, length, variable));
  }

  submitTemplate = () => {
    if (this.isValid() === true) {
      if (this.templateNameExists()) {
        this.setState({ errorMessage: Resources.TemplateNameAlreadyExists });
        return;
      }
      const { templateId, templateName, subject, htmlBody, resourceId } = this.state;
      const { perspective, companyId } = this.props;
      const payload = {
        emailTemplateId: templateName,
        emailTemplateKey: templateId,
        emailTemplateDesc: templateName,
        subject: subject,
        emailBody: htmlBody,
        emailTemplateStatus: 1,
        smsStatus: 0,
        voiceMessageStatus: 0
      };

      this.props.createEmailReplyTemplate(companyId, perspective.perspectiveId, payload).then(result => {
        if (result === "TEMPLATE_CREATED") {
          this.props.closeForm();
        }
      });
    } else {
      this.setState({ errorMessage: Resources.AllEntriesEmptyError });
    }
  };

  render() {
    const {
      templateName,
      subject,
      htmlBody,
      quillConfig,
      errorMessage,
      defaultResourceDisplay,
      manageStore
    } = this.state;
    const isUpdate = !isEmpty(this.state.templateId);

    let defaultResourceOptions = [
      {
        value: "",
        displayName: `${Resources.UseAsDefaultTemplateFor}:`
      },
      {
        value: "ApprovalRequest",
        displayName: Resources.ApprovalRequest
      },
      {
        value: "Approval",
        displayName: Resources.Approval
      }
    ];

    const parentPerspectiveId = (this.props.perspective || {}).parentPerspectiveId;
    let perspectiveResources = [];

    if (!isEmpty(parentPerspectiveId)) {
      perspectiveResources = this.props.getResourcesForPerspective(parentPerspectiveId);
    }

    let snippetContent = ((this.props.manageStore.communicationTemplateSnippets || {})[this.state.type] || [])
      .filter(snippet => !isEmpty(snippet.handlebarsSnippetValue))
      .map(snippet => {
        return (
          <Dropdown.Item
            onClick={() => {
              let delta = {
                ops: []
              };
              this.state.selectedIndex !== 0 && delta.ops.push({ retain: this.state.selectedIndex });
              this.state.selectedLength !== 0 && delta.ops.push({ delete: this.state.selectedLength });
              delta.ops.push({ insert: snippet.handlebarsSnippetValue || "" });
              this.htmlEditor.updateContents(delta);
            }}
            key={snippet.snippetDescription}
          >
            <div>{snippet.snippetDescription}</div>
          </Dropdown.Item>
        );
      });

    return (
      <div className="mt-3">
        <div className={!isEmpty(errorMessage) ? "flex mb-3 warning-color-font" : "d-none"}>{errorMessage}</div>

        <div className="">
          <TextInput
            label={Resources.Name}
            labelInline={false}
            textValue={templateName}
            inputOnChange={e => {
              this.setState({ templateName: e.target.value });
            }}
            width="30rem"
          />
        </div>
        <div className="mt-3">
          <TextInput
            label={Resources.Subject}
            labelInline={false}
            textValue={subject}
            inputOnChange={e => {
              this.setState({ subject: e.target.value });
            }}
            width="30rem"
          />
        </div>
        {/* <div className="flex quill-container mt-3">
          <SelectInput
            value={defaultResourceDisplay === "" ? "Use as default template for:" : defaultResourceDisplay}
            options={defaultResourceOptions}
            onSelectOption={option =>
              this.setState({
                defaultResource: option.value,
                defaultResourceDisplay: option.displayName,
                resourceId: (this.props.getResource("resourceName", option.value) || {}).resourceId
              })
            }
            label={Resources.Default}
            labelClassName="reply-form-label"
          />
        </div> */}
        <div style={{ maxWidth: "60rem" }}>
          <HtmlEditor
            onFocus={() => this.setState({ focusedField: "message" })}
            onBlur={range => {
              this.setState({ editorSelection: { ...range } });
            }}
            hideToolbar={this.state.focusedField !== "message"}
            htmlContent={this.state.htmlBody}
            updateHtmlContent={htmlBody => this.setState({ htmlBody })}
            updateTextContent={textBody => this.setState({ textBody })}
            onChangeSelection={range => {
              range && this.setState({ selectedIndex: range.index, selectedLength: range.length });
            }}
            width="50rem"
            onRef={el => {
              this.htmlEditor = el;
            }}
            toolbarItems={
              <Dropdown disabled={isEmpty(snippetContent)} buttonContent={<IconCode height={18}></IconCode>}>
                {snippetContent}
              </Dropdown>
            }
            id="save-replies-quill"
            label={Resources.Body}
          />
          {/* <ReactQuill
            onBlur={range => {
              this.setState({ editorSelection: { ...range } });
            }}
            className="w-100"
            id="save-replies-quill"
            ref={}
            {...quillConfig}
            value={htmlBody}
            onChange={htmlBody => {
              this.setState({ htmlBody });
            }}
          /> */}
        </div>
        <div className="mt-3">
          <button className="button-secondary-square mr-3" onClick={this.props.closeForm}>
            {Resources.Cancel}
          </button>
          <button className="button-primary-square" onClick={this.submitTemplate}>
            {isUpdate ? Resources.Update : Resources.Save}
          </button>
        </div>
      </div>
    );
  }
}

const storeToProps = store => {
  return {
    companies: store.companies,
    manageStore: store.manage
  };
};

export default connect(storeToProps, dispatchToProps)(ReplyTemplateForm);
