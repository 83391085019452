import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Switch, Route, generatePath } from "react-router-dom";

import { dispatchToProps as contDP } from "../../../../store/contextGroups-actions";
import { dispatchToProps as comDP } from "../../../../store/company-actions";
import { dispatchToProps as modDP } from "../../../../store/modal-actions";
import { dispatchToProps as manDP } from "../../../../store/manage-actions";
import { dispatchToProps as saDP } from "../../../../store/sequences-actions";

import SequencesTabContent from "./sequencesTabContent";
import ViewSequenceContent from "./viewSequenceContent";

import Resources from "../../../../lib/resources";

import ViewContentHeader from "../viewContentHeader";

const dispatchToProps = dispatch => {
  return {
    ...dispatch(comDP),
    ...dispatch(contDP),
    ...dispatch(manDP),
    ...dispatch(modDP),
    ...dispatch(saDP)
  };
};

class SequencesView extends Component {
  componentDidMount() {
    const { companyId } = this.props.match.params;
    this.props.fetchSequenceSamples();
    this.props.fetchTriggers(companyId);
    this.props.fetchSequenceActions(companyId);
    this.props.fetchSequences(companyId);
  }

  toggleSequenceActivation(sequence) {
    const { customerSequenceId, isActive } = sequence;
    this.props.postSequence(this.props.match.params.companyId, { customerSequenceId, isActive: !isActive });
  }

  render() {
    let { match, sequencesStore } = this.props;
    const { companyId } = match.params;

    const numberRegex = "\\d+";

    let subViews = [
      {
        subView: "activities",
        path: "/v2/:companyId/:perspectiveId/:view(activities)",
        display: Resources.Activities.toLocaleUpperCase()
      },
      {
        subView: "sequences",
        path: "/v2/:companyId/:perspectiveId/:view(sequences)",
        display: Resources.Sequences.toLocaleUpperCase()
      }
    ];

    // const backButton = (
    //   <button className="sequences-back-button" onClick={() => this.props.history.goBack()}>
    //     <IconAngleLeft height={16} />
    //     <span>{Resources.Back.toLocaleUpperCase()}</span>
    //   </button>
    // );

    return (
      <Switch>
        <Route exact path="/v2/:companyId/:perspectiveId/:subView(sequences)">
          <ViewContentHeader links={subViews} viewParam="view" />
          <div className="view-content">
            <SequencesTabContent
              companyId={companyId}
              createNewSequence={() =>
                this.props.history.push(this.props.location.pathname.replace("list", "templates"))
              }
              editSequence={sequence => {
                this.props.history.push(
                  generatePath(match.path, {
                    ...match.params,
                    sequenceView: sequence.customerSequenceId
                  })
                );
              }}
            />
          </div>
        </Route>
        <Route path={`/v2/:companyId/:perspectiveId/:subView(sequences)/:sequenceView(${numberRegex})`}>
          <ViewContentHeader sequenceViewing={sequencesStore.sequenceMaps[match.params.sequenceView]} />
          <div className="view-content">
            <ViewSequenceContent
              sequence={sequencesStore.sequenceMaps[match.params.sequenceView]}
              toggleSequenceActivation={() =>
                this.toggleSequenceActivation(sequencesStore.sequenceMaps[match.params.sequenceView])
              }
            />
          </div>
        </Route>
      </Switch>
    );
  }
}
const storeToProps = store => {
  return {
    companyStore: store.companies,
    manageStore: store.manage,
    sequencesStore: store.sequences
  };
};
export default withRouter(connect(storeToProps, dispatchToProps)(SequencesView));
