import React, { Component } from "react";
import { isEmpty } from "../lib/utils";
import Icons from "../lib/icons";

export default class EmailAddress extends Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
    this.onRemove = this.onRemove.bind(this);
  }

  onClick(e, email) {
    e.stopPropagation();
    let { onClick } = this.props;
    if (onClick) onClick(email);
  }

  onRemove(e, email) {
    e.stopPropagation();
    let { onRemove } = this.props;
    if (onRemove) onRemove(email);
  }

  render() {
    let { email } = this.props;
    let item = (
      <span className="email-address">
        <span
          onClick={e => {
            this.onClick(e, email);
          }}
        >
          {isEmpty(email.name) || email.name === email.address ? email.address : `${email.name} (${email.address})`}
        </span>
        {this.props.showRemove && (
          <span
            className={`${Icons.times} pl-2`}
            style={{ cursor: "pointer" }}
            onClick={e => {
              return this.onRemove(e, email);
            }}
          />
        )}
      </span>
    );
    if (isEmpty(this.props.inline) || this.props.inline === true) {
      return item;
    }
    return <div>{item}</div>;
  }
}
