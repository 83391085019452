import React, { useEffect } from "react";
import { connect } from "react-redux";

import Modal from "react-modal";
import { ReactTypeformEmbed } from "react-typeform-embed";

import { withLDConsumer } from "launchdarkly-react-client-sdk";
import { dispatchToProps } from "../../store/modal-actions";

const PortalAddVendorsModal = ({ modal: modalStore, flags, hideModal, selectedAccountId }) => {
  useEffect(() => {
    if (flags.portalAddVendorsConversion.enabled === false) {
      hideModal();
    }
  }, [flags]);

  return (
    <Modal
      isOpen={modalStore.showModal}
      onRequestClose={() => hideModal()}
      contentLabel="Example Modal"
      className="custom-modal-wide add-vendors-modal"
      overlayClassName="custom-modal-overlay"
    >
      <ReactTypeformEmbed url={flags.portalAddVendorsConversion.typeformLink + "#accountid=" + selectedAccountId} />
    </Modal>
  );
};

const storeToProps = store => {
  return {
    modal: store.modal,
    selectedAccountId: store.accounts.selectedAccountId
  };
};

export default withLDConsumer()(connect(storeToProps, dispatchToProps)(PortalAddVendorsModal));
