import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import FlexibleScrollContainer from "../flexibleScrollContainer";

import { dispatchToProps as uaDP } from "../../store/user-actions";
import { dispatchToProps as aaDP } from "../../store/accounts-actions";
import { dispatchToProps as convDP } from "../../store/conversations-actions";
import { dispatchToProps as congDP } from "../../store/contextGroups-actions";

const dispatchToProps = dispatch => ({
  ...aaDP(dispatch),
  ...uaDP(dispatch),
  ...convDP(dispatch),
  ...congDP(dispatch)
});

class TabularData extends Component {
  render() {
    let rangedData = this.props.data || [];
    let allSelected = rangedData.length > 0 ? true : false;
    let index = 0;

    while (allSelected && index < rangedData.length) {
      allSelected = allSelected && rangedData[index].selected === true;
      index++;
    }

    const {
      format: { columns, subtexts = [] },
      match: {
        params: { perspectiveId }
      }
    } = this.props;

    return (
      <div className="table-container">
        <div className="table-header">
          {columns.map((column, i) => {
            return (
              <div key={i} style={{ width: column.style.width }} className="column-header">
                {column.header}
              </div>
            );
          })}
        </div>
        <FlexibleScrollContainer
          initialHeight={500}
          onLoadMore={this.props.onLoadMore}
          maxRows={this.props.maxRows}
          loading={this.props.loading}
          maxHeight={this.props.maxHeight}
        >
          {rangedData.map((row, rowIndex) => {
            /* row */
            return (
              <div key={rowIndex} className={row.highlight ? "highlight" : ""}>
                <div
                  onClick={() => this.props.format.callBack(row, rowIndex)}
                  className={row.className || this.props.rowClassName || "conversation-row"}
                >
                  <div className="table-row-main-line">
                    {columns.map((column, i) => {
                      let colStyle;
                      if (column.key === "icon") {
                        if (this.props.isCompanyNew(row.withCompanyId, perspectiveId)) {
                          colStyle = "table-row-main-line-item-icon-new";
                        } else {
                          colStyle = "table-row-main-line-item-icon";
                        }
                      } else {
                        colStyle = "table-row-main-line-item";
                      }

                      return (
                        <div key={i} style={column.style} className={colStyle}>
                          {row[column.key]}
                        </div>
                      );
                    })}
                  </div>
                  <div className={`table-row-subtext ${this.props.obscureSubtext ? "fs-obscure" : ""}`}>
                    {subtexts.map(subtext => {
                      return (
                        <div key={rowIndex} style={subtext.style}>
                          {row[subtext.key]}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            );
          })}
        </FlexibleScrollContainer>
      </div>
    );
  }
}

TabularData.defaultProps = {
  selectEnabled: false
};

const storeToProps = store => {
  return {
    user: store.user,
    ledger: store.ledger,
    conversations: store.conversations
  };
};

export default withRouter(
  connect(
    storeToProps,
    dispatchToProps
  )(TabularData)
);
