import React from "react";
import IconUser from "./icons/iconUser";
import IconCompany from "./icons/iconCompany";

export default function Avatar(props) {
  const {
    height = "2.27rem",
    imgURL = "",
    type = "",
    objectName = "",
    isCurrentUser = false,
    isCurrentAccount = false
  } = props;

  let objectInitials = objectName.match(/\b\w/g) || [];
  objectInitials = ((objectInitials.shift() || "") + (objectInitials.pop() || "")).toUpperCase();
  let emptyText = props.emptyText || objectInitials;
  const emptyTextFontSize = `calc(${height} / ${emptyText.length <= 2 ? 2.4 : emptyText.length * 1.2})`;

  const getEmptyAvatarIcon = () => {
    switch (type) {
      case "account":
        return <IconCompany height={`calc(${height} * 0.45)`} />;
      case "user":
      default:
        return <IconUser height={`calc(${height} * 0.45)`} />;
    }
  };

  return (
    <div className="avatar" style={{ height: height, width: height, maxWidth: height, maxHeight: height }}>
      {imgURL ? (
        <img className="avatar__image" src={imgURL} alt={objectName || ""} />
      ) : (
        <div
          className={`avatar__empty ${type ? "avatar__empty--" + type : null} ${
            isCurrentAccount ? "avatar__empty--current-account" : ""
          } ${isCurrentUser ? "avatar__empty--current-user" : ""}`}
        >
          {emptyText ? (
            <svg
              className="avatar__empty__text"
              viewBox={`0 0 calc(${height}) calc(${height})`}
              style={{ maxHeight: height }}
            >
              <text
                fontSize={emptyTextFontSize}
                //style={{ transform: `translate(calc(${height} * 0.16), calc(50% + 0.315em))` }}
                x="50%"
                y="52%"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                {emptyText}
              </text>
            </svg>
          ) : (
            getEmptyAvatarIcon()
          )}
        </div>
      )}
    </div>
  );
}
