import React, { Component } from "react";
import { connect } from "react-redux";

import "react-quill/dist/quill.snow.css";
import "../css/quill.scss";
import Dropzone from "react-dropzone";
import Modal from "react-modal";

import { isEmpty, removeAt } from "../lib/utils";
import Icons from "../lib/icons";
import Resources from "../lib/resources";

import { dispatchToProps as moDP } from "../store/modal-actions";
import { dispatchToProps as laDP } from "../store/ledger-actions";
import { dispatchToProps as caDP } from "../store/company-actions";
import { dispatchToProps as gaDP } from "../store/general-actions";
import { dispatchToProps as conDP } from "../store/conversations-actions";
import { dispatchToProps as maDP } from "../store/manage-actions";
import { raiseError, handlePromiseError } from "../store/error-actions";

import EmailAddressBar from "./emailAddressBar";
import Attachment from "./attachment";
import ReactQuill from "./library/lockstepQuill";

const dispatchToProps = dispatch => {
  return {
    ...moDP(dispatch),
    ...laDP(dispatch),
    ...caDP(dispatch),
    ...gaDP(dispatch),
    ...conDP(dispatch),
    ...maDP(dispatch)
  };
};

class SendStatementModal extends Component {
  constructor(props) {
    super(props);
    this.quillRef = null;
    this.reactQuillRef = null;
    this.state = { ...this.defaultState };

    this.returnToListView = this.returnToListView.bind(this);
    this.toggleCc = this.toggleCc.bind(this);
    this.toggleBcc = this.toggleBcc.bind(this);
    this.submit = this.submit.bind(this);
    this.save = this.save.bind(this);
    this.updateAttachments = this.updateAttachments.bind(this);
    this.addAttachments = this.addAttachments.bind(this);
    this.removeAttachments = this.removeAttachments.bind(this);
    this.removeAttachment = this.removeAttachment.bind(this);
    this.showAttachFilesModal = this.showAttachFilesModal.bind(this);
    this.showAttachFromLibraryModal = this.showAttachFromLibraryModal.bind(this);
  }

  defaultState = {
    mode: null,
    hasSetDefaultEmail: false,
    value: "",
    showCc: false,
    showBcc: false,
    subject: Resources.StatementFor(this.props.withCompanyName) || "",
    to: [],
    cc: [],
    bcc: [],
    // this is a mixed list of File objects and companyDocuments
    attachments: [],
    dropEnabled: false,
    draftId: null,
    existingAttachments: [],
    detachments: [],
    selectedNewChannel: {},

    saving: false,
    committing: false,
    commitFailed: false,

    quillConfig: {
      theme: "snow",
      modules: {
        toolbar: [
          [{ header: [1, 2, 3, 4, false] }],
          ["bold", "italic", "underline"],
          ["strike", "blockquote"],
          [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
          ["link"]
        ],
        keyboard: {
          bindings: {
            tab: {
              key: 9,
              handler: () => {
                return true;
              }
            }
          }
        }
      },
      readOnly: false
    },
    savingAsTemplate: false
  };

  showAttachFilesModal(e) {
    this.props.displayModal("attachFileModal", {
      companyId: this.props.companyId,
      perspectiveId: this.props.perspectiveId,
      updateAttachments: this.updateAttachments,
      uploads: e.target.files
    });
  }

  showAttachFromLibraryModal() {
    this.props.displayModal("attachFromLibraryModal", {
      companyId: this.props.companyId,
      perspectiveId: this.props.perspectiveId,
      updateAttachments: this.updateAttachments
    });
  }

  componentDidUpdate() {
    const { statementsStore } = this.props;
    this.attachQuillRefs();
    if (
      this.quillRef !== null &&
      this.state.value.trim() === "" &&
      statementsStore.isFetchingClosedInvoicesTemplate === false &&
      statementsStore.isFetchingOpenInvoicesTemplate === false &&
      statementsStore.isFetchingPaymentsTemplate === false
    ) {
      this.props
        .fetchTemplate()
        .then(templateData => {
          const tableIndex = templateData.htmlBody.indexOf("<table");
          const greeting = templateData.htmlBody.slice(0, tableIndex);
          const statementHtml = templateData.htmlBody.slice(tableIndex);
          this.setState({ statementHtml });

          this.reactQuillRef.setEditorContents(this.quillRef, greeting);
        })
        .catch(err => {
          handlePromiseError(err, "Fetching the template data failed.");
        });
    }

    const companyInfo = this.props.getCompanyInfo(this.props.withCompanyId);
    const departmentEmailKey = this.props.parentPerspective.perspectiveName === "customers" ? "arEmail" : "apEmail";
    if (this.state.hasSetDefaultEmail === false && !isEmpty(companyInfo[departmentEmailKey])) {
      this.setState({ hasSetDefaultEmail: true, to: [{ address: companyInfo[departmentEmailKey] }] });
    }
  }

  attachQuillRefs = () => {
    // We need to update state to hook into componentDidUpdate
    if (this.state.quillRefAttached !== true) {
      this.setState({ quillRefAttached: true });
    }

    if (typeof (this.reactQuillRef || {}).getEditor !== "function") return;
    this.quillRef = this.reactQuillRef.getEditor();
  };

  componentDidMount() {
    this.attachQuillRefs();
  }

  toggleCc() {
    this.setState({ showCc: !this.state.showCc });
  }

  toggleBcc() {
    this.setState({ showBcc: !this.state.showBcc });
  }

  splitEmails(emails) {
    if (isEmpty(emails)) return [];
    return emails.split(";").map(email => {
      return { address: email.trim() };
    });
  }

  save(andClose) {
    let result = {};

    result.from = [];
    if (this.state.companyEmail) {
      result.from.push(this.state.companyEmail);
    }

    const companyInfo = this.props.getCompanyInfo(this.props.companyId);
    let cAddress = companyInfo.connectorEmail.synthetic[this.props.perspectiveId];
    let companyEmail = isEmpty(cAddress) ? undefined : { name: companyInfo.companyName, address: cAddress };

    result.from = [companyEmail];

    result.to = this.state.to;
    result.cc = this.state.cc;
    result.bcc = this.state.bcc;

    let error = "";
    let resourceName = "email";

    if (andClose) {
      const closedStatusId = this.props.getConversationStatusByName("closed").conversationStatusId;
      result.conversationStatusId = closedStatusId;
    }

    result = {
      ...result,
      subject: this.state.subject,
      textBody: this.getText(),
      htmlBody: this.state.value + this.state.statementHtml
    };

    this.setState({
      saving: true,
      commitFailed: false,
      quillConfig: { ...this.state.quillConfig, readOnly: true }
    });

    let ok = isEmpty(error) ? Promise.resolve() : Promise.reject(error);
    if (isEmpty(this.state.draftId)) {
      ok = ok.then(() => {
        return this.props.createDraft(this.props.companyId, resourceName, result);
      });
      ok = ok.then(response => {
        return this.addAttachments(response.data);
      });
    } else {
      ok = ok.then(() => {
        return this.props.saveDraft(this.state.draftId, result);
      });
      ok = ok.then(response => {
        return this.addAttachments(response.data);
      });
      ok = ok.then(draftId => {
        return this.removeAttachments().then(response => {
          return Promise.resolve(draftId);
        });
      });
    }
    ok = ok
      .then(draftId => {
        this.setState({
          saving: false,
          quillConfig: {
            ...this.state.quillConfig,
            readOnly: this.state.committing
          }
        });

        return Promise.resolve(draftId);
      }, this)
      .catch(rejection => {
        this.setState({
          saving: false,
          quillConfig: {
            ...this.state.quillConfig,
            readOnly: this.state.committing
          }
        });
        if (typeof rejection === "string") {
          raiseError(rejection);
        }
        if (this.state.committing) {
          throw new Error("save of draft failed");
        }
      }, this);

    return ok;
  }

  addAttachments(draftId) {
    let resolveDraftId = Promise.resolve(draftId);
    if (this.state.attachments.length > 0) {
      return Promise.all([
        ...this.state.attachments.map(a => {
          if (!isEmpty(a.companyDocumentId)) {
            return this.props.addDocumentAttachment(draftId, a.companyDocumentId);
          }
          return this.props.addAttachment(draftId, a);
        })
      ]).then(response => {
        return resolveDraftId;
      });
    } else return resolveDraftId;
  }

  removeAttachments() {
    if (this.state.detachments.length > 0)
      return Promise.all(
        this.state.detachments.map(a => {
          return this.props.deleteAttachment(a.attachmentId);
        })
      );
    else return Promise.resolve();
  }

  submit(e, submitAndClose = false) {
    this.setState(
      {
        saving: true,
        committing: true,
        commitFailed: false,
        quillConfig: { ...this.state.quillConfig, readOnly: true }
      },
      () => {
        let ok = this.save(submitAndClose);
        ok = ok.then(draftId => {
          return this.props.commitDraft(draftId);
        }, this);
        ok.then(response => {
          const toastInfo = {};

          toastInfo.text = Resources.YouSentAStatement;
          toastInfo.type = "blue";
          this.props.showToast(toastInfo);
          this.props.hideModal();
        }, this).catch(rejection => {
          this.setState({
            saving: false,
            committing: false,
            commitFailed: true,
            quillConfig: { ...this.state.quillConfig, readOnly: false }
          });
          handlePromiseError(
            rejection,
            "TODO: Sending of the response to the conversation has failed.  Please try again."
          );
        }, this);
      }
    );
  }

  updateAttachments(attachments) {
    this.setState({ attachments: [...this.state.attachments, ...attachments], dropEnabled: false });
  }

  removeAttachment(index) {
    this.setState({ attachments: removeAt(this.state.attachments, index) });
  }

  removeExistingAttachment(index) {
    let detachments = [...this.state.detachments, this.state.existingAttachments[index]];
    this.setState({
      existingAttachments: removeAt(this.state.existingAttachments, index),
      detachments
    });
  }

  returnToListView(toastInfo) {
    const {
      match: { params },
      location: { pathname }
    } = this.props;
    this.props.showToast(toastInfo);
    this.props.setConversationIndex(null);
    let previousPath = pathname.slice(0, pathname.indexOf(`/${params.selectedItemId}`));
    this.props.history.push(previousPath);
  }

  getText() {
    let uEditor = this.reactQuillRef.makeUnprivilegedEditor(this.quillRef);
    return uEditor.getText();
  }

  renderEditor() {
    let { showCc, showBcc } = this.state;

    let disabled = this.state.saving === true || this.state.committing === true;

    const contacts = this.props.getCompanyAddressBook(this.props.companyId) || [];

    let showAddAttachment = false;
    const emailsExpander = (
      <div className="float-right">
        <span className="mr-2 clickable" onClick={this.toggleCc}>
          {Resources.EmailCC}
        </span>
        <span className="clickable" onClick={this.toggleBcc}>
          {Resources.EmailBCC}
        </span>
      </div>
    );

    return (
      <React.Fragment>
        <div className="quill-container">
          <div>
            <div className="emails-container">
              {emailsExpander}
              <span className="email-header-label">{Resources.EmailTo}:</span>
              <EmailAddressBar
                perspectiveId={this.props.perspectiveId}
                type="to"
                participants={this.state.to}
                startAdding
                contacts={contacts}
                onChanged={to => {
                  this.setState({ to });
                }}
              />
            </div>
            {showCc && (
              <div className="mt-2">
                <span className="email-header-label">{Resources.EmailCC}:</span>
                <EmailAddressBar
                  perspectiveId={this.props.perspectiveId}
                  type="cc"
                  participants={this.state.cc}
                  contacts={contacts}
                  onChanged={cc => {
                    this.setState({ cc, ccChanged: true });
                  }}
                />
              </div>
            )}
            {showBcc && (
              <div className="mt-2">
                <span className="email-header-label">{Resources.EmailBCC}:</span>
                <EmailAddressBar
                  perspectiveId={this.props.perspectiveId}
                  type="bcc"
                  participants={this.state.bcc}
                  contacts={contacts}
                  onChanged={bcc => {
                    this.setState({ bcc });
                  }}
                />
              </div>
            )}
            <div className="mt-2 flex-align-center">
              <span className="email-header-label">{Resources.Subject}:</span>
              <input
                className="subject-input"
                type="text"
                value={this.state.subject}
                onChange={e => {
                  this.setState({ subject: e.target.value });
                }}
              />
            </div>
          </div>
          <div>
            {/* we are letting quill do it's thing and own all the html editing things.  Our custom actions will live in the foot section below*/}
            <Dropzone
              noClick={true}
              onDragEnter={e => {
                this.setState({ dropEnabled: true });
              }}
              onDragLeave={e => {
                this.setState({ dropEnabled: false });
              }}
              onDrop={acceptedFiles => this.updateAttachments(Array.from(acceptedFiles))}
            >
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()} className="quill-dropzone-container">
                  <ReactQuill
                    ref={el => {
                      this.reactQuillRef = el;
                    }}
                    {...this.state.quillConfig}
                    value={this.state.value}
                    onChange={value => {
                      this.setState({ value });
                    }}
                  />
                  {this.state.dropEnabled && (
                    <div className="quill-dropzone-overlay">
                      <h4>{Resources.DropFilesHere}</h4>
                    </div>
                  )}
                  <input {...getInputProps()} />
                </div>
              )}
            </Dropzone>
            {/* this is the footer toolbar where all of our custom actions will live */}
            <div className="ql-toolbar ql-snow p-0" style={{ borderTopStyle: "none" }}>
              <div className="flex-split quill-actions">
                <div>
                  {showAddAttachment && (
                    <div className="editor-action">
                      <span
                        className="dropdown"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <span className={Icons.attachment} disabled={disabled} />
                        <span className="editor-action-label">{Resources.AttachFile}</span>
                        <span className={Icons.dropdown} style={{ fontSize: ".8em" }} />
                      </span>
                      <span className="dropdown-menu">
                        <span className="dropdown-item" onClick={this.showAttachFromLibraryModal}>
                          {Resources.SharedDocuments}
                        </span>
                        <span
                          className="dropdown-item"
                          onClick={() => {
                            window.document.getElementById("uploadFiles").click();
                          }}
                        >
                          {Resources.UploadFromComputer}
                        </span>
                      </span>
                    </div>
                  )}
                </div>

                <div>
                  <button className="cancel-button" onClick={this.props.hideModal} style={{ marginLeft: "0px" }}>
                    {Resources.Discard}
                  </button>
                  <button
                    className="button-edit editor-button"
                    disabled={disabled}
                    onKeyPress={e => {
                      if (e.key === "Enter" || e.key === " ") {
                        e.stopPropagation();
                        this.submit(e, true);
                      }
                    }}
                    onClick={this.submit}
                  >
                    {disabled === true ? <span className={Icons.spinner} /> : Resources.SendForResponse}
                  </button>
                  <button
                    className="send-button"
                    style={{ padding: "0px 10px !important", width: "auto" }}
                    tabIndex="0"
                    disabled={disabled}
                    onKeyPress={e => {
                      if (e.key === "Enter" || e.key === " ") {
                        e.stopPropagation();
                        this.submit(e, true);
                      }
                    }}
                    onClick={e => this.submit(e, true)}
                  >
                    {disabled === true ? <span className={Icons.spinner} /> : Resources.SendAndClose}
                  </button>
                </div>
              </div>
              <div className="p-1">
                {this.state.attachments.map((a, index) => {
                  return (
                    <Attachment
                      attachment={a}
                      key={index}
                      name={a.name || a.companyDocumentName}
                      size={a.size || a.fileSize}
                      showDelete={true}
                      showIcon
                      onDelete={() => {
                        this.removeAttachment(index);
                      }}
                    />
                  );
                })}
                {this.state.existingAttachments.map((a, index) => {
                  return (
                    <Attachment
                      attachment={a}
                      key={a.attachmentId}
                      name={a.fileName}
                      size={a.sizeBytes}
                      showDelete={true}
                      showIcon
                      onDelete={() => {
                        this.removeExistingAttachment(index);
                      }}
                    />
                  );
                })}
              </div>
            </div>
          </div>
          <input
            className="hidden-input"
            type="file"
            id="uploadFiles"
            multiple
            style={{ visibility: "hidden" }}
            onChange={this.showAttachFilesModal}
          />
        </div>
      </React.Fragment>
    );
  }

  render() {
    const { modalStore } = this.props;

    let emailBody = modalStore.modalProps.originalHtmlBody;
    if (isEmpty(emailBody)) {
      emailBody = (modalStore.modalProps.originalTextBody || "").split("\n").join("<br />");
    }
    return (
      <Modal
        isOpen={modalStore.showModal}
        onRequestClose={this.props.hideModal}
        contentLabel="Example Modal"
        className="send-statement-modal"
        overlayClassName="custom-modal-overlay"
      >
        <div className="align-baseline flex-split">
          <h4 className="fw-500">{Resources.StatementFor(this.props.withCompanyName)}</h4>
          <div onClick={this.props.hideModal} className={`${Icons.close} close-original-email`} />
        </div>
        <div className="custom-modal-divider" />
        <div className="custom-modal-content">{this.renderEditor()}</div>
        <div className="statement-included-message">{Resources.StatementWillBeIncluded}</div>
      </Modal>
    );
  }
}

const storeToProps = store => {
  return {
    modalStore: store.modal,
    companyInfo: store.companies.companyInfo,
    addressBook: store.manage.companyAddressBook,
    statementsStore: store.statements
  };
};

export default connect(
  storeToProps,
  dispatchToProps
)(SendStatementModal);
