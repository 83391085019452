import React, { Component } from "react";
import { connect } from "react-redux";
import Resources from "../lib/resources";
import { dispatchToProps as caDP } from "../store/connector-actions";
import { dispatchToProps as gaDP } from "../store/general-actions";
import { isEmpty } from "../lib/utils";
import MainContentHeader from "./main_content_header/mainContentHeader";
import AutoCompleteDropdown from "./library/autoCompleteDropdown";
import MainLoader from "./mainLoader";
import Icons from "../lib/icons";
import FlexibleScrollContainer from "./flexibleScrollContainer";
import LegacyNotification from "./library/legacyNotification";
import TextInput from "./library/textInput";

const dispatchToProps = dispatch => {
  return {
    ...caDP(dispatch),
    ...gaDP(dispatch)
  };
};

class ERPConfiguration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      connector: null,
      schema: []
    };

    this.saveConfiguration = this.saveConfiguration.bind(this);
    this.refreshData = this.refreshData.bind(this);
  }

  componentDidMount() {
    window.addEventListener("refreshData", this.refreshData);
    this.tryUpdate();
  }

  componentWillUnmount() {
    window.removeEventListener("refreshData", this.refreshData);
  }

  refreshData() {
    const { companyId } = this.props.match.params;
    const registeredConnector = this.props.getRegisteredERPConnectors(companyId)[0];
    const connectorVersion = this.props.getLatestConnectorVersion(registeredConnector.connectorId);
    this.props.fetchConnectorConfiguration(companyId, connectorVersion.connectorVersionId);
  }

  componentDidUpdate(prevProps, prevState) {
    this.tryUpdate(prevProps, prevState);
  }

  tryUpdate(prevProps, prevState = {}) {
    const {
      getRegisteredERPConnectors,
      match: {
        params: { companyId }
      },
      location
    } = this.props;
    const registeredConnector = getRegisteredERPConnectors(companyId)[0];

    let goBack = false;
    if (registeredConnector !== null) {
      if (isEmpty(registeredConnector)) {
        this.props.history.replace(location.pathname.replace("/configure", ""));
        return;
      }

      if (registeredConnector.configStatus.toLowerCase() === "registration") {
        // Reroute to registration with error message
        this.props.history.replace(
          this.props.location.pathname.replace("configure", "registering?invalidRegistration=true")
        );
        return;
      }

      if (isEmpty(this.state.connector)) {
        let registrationConfig = registeredConnector.connectorVersion.configurationSchema.registrationConfig || [];
        const schema = [...registrationConfig];
        if (isEmpty(schema)) {
          goBack = true;
        } else {
          this.setState({ schema, connector: registeredConnector });
        }
      }
    }

    // If they change companies, take them back to main connector page
    if (goBack || (!isEmpty(prevProps) && this.props.companyId !== prevProps.companyId)) {
      let urlParts = this.props.location.pathname.split("/");
      urlParts.pop();
      let newUrl = urlParts.join("/");
      this.props.history.replace(newUrl);
    }
  }

  saveConfiguration() {
    let {
      match: {
        params: { companyId }
      },
      connectorStore,
      getLatestConnectorVersion,
      updateConnectorConfiguration,
      history,
      location
    } = this.props;
    const connectorVersion = getLatestConnectorVersion(this.state.connector.connectorId);

    const update = this.state.schema.reduce((accum, field) => {
      accum[field.key] = (field.selected || {}).id;
      return accum;
    }, {});

    const config = connectorStore.connectorConfigs[connectorVersion.connectorVersionId].connectorConfiguration;
    const payload = Object.assign({}, config, update);

    updateConnectorConfiguration(companyId, connectorVersion.connectorVersionId, payload).then(response => {
      if (!isEmpty(response)) {
        this.props.selectCompany(companyId);
        if (this.props.isEditing) {
          history.goBack();
        } else {
          history.push(location.pathname.replace("/configure", "?success=true"));
        }
      }
    });
  }

  render() {
    const { connector } = this.state;
    const { isEditing, connectorStore } = this.props;

    if (connector === null) {
      return <MainLoader fullScreen={true} />;
    }

    const isSyncing = connector.configStatus.toLowerCase() === "syncing";
    const isSubmitting = connectorStore.isUpdatingConnectorConfiguration;

    return (
      <React.Fragment>
        {!isEditing && (
          <MainContentHeader
            title={Resources.Configure}
            customContent={<LegacyNotification type="alert" message={Resources.RegisterStepSucceeded} />}
            hideSearch={true}
          />
        )}
        <div className="manage-content">
          <FlexibleScrollContainer>
            <div className="connector-container">
              {
                <h3 className="mb-3 fw-500">
                  {Resources.ConfigureConnectorSettings((this.state.connector || {}).displayName || "")}
                </h3>
              }
              {!isSyncing && <div className="select-accounting-subtext">{Resources.ConfigureMessage}</div>}

              {isSyncing && (
                <div className="flex-align-center mb-5">
                  <div style={{ fontSize: "26px", color: "#666" }} className={Icons.spinner} />
                  <div className="ml-3 italic">{Resources.SyncingMessage}</div>
                </div>
              )}

              {this.state.schema.map((field, i) => {
                if (field.type === "string") {
                  return (
                    <TextInput
                      key={i}
                      width="300px"
                      textValue={(field.selected || {}).id}
                      label={field.displayName || field.description}
                      id={field.key}
                      inputOnChange={e => {
                        const schema = [...this.state.schema];
                        const update = Object.assign({}, field, {
                          selected: { id: e.target.value }
                        });
                        schema[i] = update;
                        this.setState({ schema });
                      }}
                    />
                  );
                }
                return (
                  <div className="mb-3" key={i}>
                    <AutoCompleteDropdown
                      disabled={isSyncing}
                      label={field.displayName}
                      id={field.id}
                      width="300px"
                      selected={field.selected}
                      options={field.options || []}
                      displayKey="displayName"
                      placeholder={Resources.LoadingOptions}
                      handleSelectOption={option => {
                        const schema = [...this.state.schema];
                        schema[i] = Object.assign({}, field, {
                          selected: option
                        });
                        this.setState({ schema });
                      }}
                    />
                  </div>
                );
              })}

              <div className="connector-buttons">
                {isEditing && (
                  <button className="cancel-button" onClick={() => this.props.history.goBack()}>
                    {Resources.Cancel}
                  </button>
                )}
                <button onClick={this.saveConfiguration} disabled={isSubmitting || isSyncing} className="send-button">
                  {isSubmitting ? Resources.Saving : Resources.Save}
                </button>
              </div>
            </div>
          </FlexibleScrollContainer>
        </div>
      </React.Fragment>
    );
  }
}

const storeToProps = store => {
  return {
    connectorStore: store.connector
  };
};

export default connect(
  storeToProps,
  dispatchToProps
)(ERPConfiguration);
