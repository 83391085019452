import React, { Component } from "react";

class IconArrowUp extends Component {
  render() {
    let { className, height } = this.props;

    return (
      <svg
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        className={`icon ${className ? className : ""}`}
        height={height}
        viewBox="0 0 24 24"
      >
        <path d="M12 19V5M5 12l7-7 7 7" />
      </svg>
    );
  }
}

IconArrowUp.defaultProps = {
  height: 18
};

export default IconArrowUp;
