import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink, Redirect, withRouter } from "react-router-dom";

import { dispatchToProps as contDP } from "../../store/contextGroups-actions";
import { dispatchToProps as comDP } from "../../store/company-actions";
import { dispatchToProps as modDP } from "../../store/modal-actions";
import { dispatchToProps as manDP } from "../../store/manage-actions";
import { dispatchToProps as saDP } from "../../store/sequences-actions";

import FlexibleScrollContainer from "../flexibleScrollContainer";
import IconAngleLeft from "../library/icons/iconAngleLeft";

import SequencesTabContent from "./sequencesTabContent";
import SequencesOverviewTabContent from "./sequencesOverviewTabContent";
import PickSequenceTemplateContent from "./pickSequenceTemplateContent";
import CreateOrEditSequenceContent from "./createOrEditSequenceContent";

import Resources from "../../lib/resources";

const dispatchToProps = dispatch => {
  return {
    ...dispatch(comDP),
    ...dispatch(contDP),
    ...dispatch(manDP),
    ...dispatch(modDP),
    ...dispatch(saDP)
  };
};

class ManageSequences extends Component {
  componentDidMount() {
    const { companyId } = this.props.match.params;
    this.props.fetchSequenceSamples();
    this.props.fetchTriggers(companyId);
    this.props.fetchSequenceActions(companyId);
    this.props.fetchSequences(companyId);
  }

  render() {
    const { companyId, sequenceView } = this.props.match.params;

    let content;
    let header = (
      <React.Fragment>
        <Redirect
          from={`/company/:companyId/manage/sequences/`}
          to={`/company/${companyId}/manage/sequences/overview`}
        />
        <NavLink className="content-header-link" to={`/company/${companyId}/manage/sequences/overview`}>
          {Resources.Overview}
        </NavLink>
        <NavLink className="content-header-link" to={`/company/${companyId}/manage/sequences/list`}>
          {Resources.Sequences}
        </NavLink>
      </React.Fragment>
    );

    const backButton = (
      <button className="sequences-back-button" onClick={() => this.props.history.goBack()}>
        <IconAngleLeft height={16} />
        <span>{Resources.Back.toLocaleUpperCase()}</span>
      </button>
    );

    switch (sequenceView) {
      case "templates":
        content = (
          <PickSequenceTemplateContent
            onSelectSequenceTemplate={template => {
              this.props.history.push(
                this.props.location.pathname.replace("templates", `new/${template.customerSequenceTemplateId}`)
              );
            }}
          />
        );
        header = backButton;
        break;
      case "new":
      case "edit":
        content = <CreateOrEditSequenceContent companyId={companyId} {...this.props} />;
        header = backButton;
        break;
      case "list":
        content = (
          <SequencesTabContent
            companyId={companyId}
            createNewSequence={() => this.props.history.push(this.props.location.pathname.replace("list", "templates"))}
            editSequence={sequence => {
              this.props.history.push(
                this.props.location.pathname.replace(
                  this.props.match.params.sequenceView,
                  `edit/${sequence.customerSequenceId}`
                )
              );
            }}
          />
        );
        break;
      case "overview":
      default:
        content = <SequencesOverviewTabContent companyId={companyId} />;
        break;
    }

    return (
      <FlexibleScrollContainer>
        <div className="sequences-create-header" id="content-header-nav" style={{ marginTop: "1rem" }}>
          {header}
        </div>
        <hr style={{ marginBottom: "2.67rem", marginTop: "0" }} />
        <div className="manage-content">{content}</div>
      </FlexibleScrollContainer>
    );
  }
}
const storeToProps = store => {
  return {
    companyStore: store.companies,
    manageStore: store.manage,
    sequencesStore: store.sequences
  };
};
export default withRouter(
  connect(
    storeToProps,
    dispatchToProps
  )(ManageSequences)
);
