import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from "react-modal";
import Resources from "../../../lib/resources";
import ContactsTable from "../contactsTable";
import { find } from "../../../lib/utils";
import { dispatchToProps as netDP } from "../../../store/network-actions";
import { dispatchToProps as modDP } from "../../../store/modal-actions";
import { dispatchToProps as persDP } from "../../../store/perspectives-actions";
import RadioInput from "../../library/radioInput";
const dispatchToProps = dispatch => ({
  ...netDP(dispatch),
  ...modDP(dispatch),
  ...persDP(dispatch)
});
class SendPrivateContactRequestDialog extends Component {
  render() {
    const { modalStore } = this.props;
    return (
      <Modal
        isOpen={modalStore.showModal}
        onRequestClose={() => this.props.hideModal()}
        className="primary-contact-archive-modal"
        overlayClassName="custom-modal-overlay"
      >
        <p className="header">{Resources.AreYouSure}</p>
        <div className="">
          <p>{Resources.ThisProfileIsMarkedPrivateMessage}</p>
        </div>
        <div className="button-container">
          <button onClick={() => this.props.hideModal()} className="button-secondary-square">
            {Resources.Cancel}
          </button>
          <button
            onClick={() => {
              this.props
                .updateContactPrivacy(
                  this.props.perspectiveId,
                  this.props.contactId,
                  !this.props.selectedContact.isPrivate,
                  this.props.selectedPerspectiveType === "vendors" ? "ap" : "ar"
                )
                .then(response => {
                  this.props.fetchContactProfile(this.props.perspectiveId, this.props.contactId);
                  this.props.fetchContactList(this.props.perspectiveId);
                });
              this.props
                .sendContactProfileUpdateRequest([this.props.selectedContact.entityGlobalKey])
                .then(response => {
                  this.props.fetchOpenProfileUpdateRequests(this.props.perspectiveId);
                  this.props.fetchOpenOnboardingRequests(this.props.perspectiveId);
                  this.props.fetchActivityList(this.props.perspectiveId, {
                    top: 100,
                    skip: 0
                  });
                });
              this.props.hideModal();
              this.props.displayNotification("sendProfileUpdateRequestNotifiation");
            }}
            className="button-primary-square"
          >
            {Resources.YesIAmSure}
          </button>
        </div>
      </Modal>
    );
  }
}
const storeToProps = store => {
  return {
    modalStore: store.modal,
    networkStore: store.network,
    perspectiveStore: store.perspectives
  };
};
export default connect(storeToProps, dispatchToProps)(SendPrivateContactRequestDialog);
