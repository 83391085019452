import React, { Component } from "react";
import { connect } from "react-redux";

import { dispatchToProps } from "../store/reports-actions";

import ReportsFilter from "./reportsFilter";
import MainContentHeader from "./main_content_header/mainContentHeader";
import MainLoader from "./mainLoader";
import {
  isEmpty,
  clone,
  ticksToReadableTime,
  getReportsTicks,
  millisecondsToReadableTimeHardCutoff
} from "../lib/utils";
import Resources from "../lib/resources";
import ReportContentBlock from "./reportsContentBlock";
import TabularData from "./library/tabularData";
import { BarChart, YAxis, XAxis, Bar, Tooltip, ResponsiveContainer, CartesianGrid } from "recharts";
import ReportsExportButton from "./reportsExportButton";
import ReportsNoData from "./reportsNoData";

class ReportsTeam extends Component {
  constructor(props) {
    super(props);
    this.state = {
      numberOfEntries: 10
    };

    this.loadMore = this.loadMore.bind(this);
  }

  componentDidMount() {
    this.tryUpdate();
  }

  componentDidUpdate(prevProps) {
    this.tryUpdate(prevProps);
  }

  tryUpdate(prevProps) {
    if (
      isEmpty(prevProps) ||
      this.props.reports.companyId !== prevProps.reports.companyId ||
      this.props.reports.rangeType !== prevProps.reports.rangeType
    ) {
      this.props.getReportsData("team");
    }
    if (
      isEmpty(prevProps) === false &&
      this.props.reports.fetchedReport === true &&
      prevProps.reports.fetchedReport === false
    ) {
      let userIds =
        this.props.reports.reportData.members.map(approver => {
          return approver.userId;
        }) || [];
      this.props.getReportUsers(userIds);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.props.reports.companyId !== nextProps.reports.companyId ||
      this.props.reports.rangeType !== nextProps.reports.rangeType ||
      this.props.reports.reportType !== nextProps.reports.reportType ||
      this.props.reports.reportData !== nextProps.reports.reportData ||
      this.state.numberOfEntries !== nextState.numberOfEntries ||
      this.props.reports.reportUserIds !== nextProps.reports.reportUserIds
    ) {
      return true;
    }
    return false;
  }

  loadMore() {
    if (this.state.numberOfEntries < this.props.reports.reportData.members.length) {
      this.setState({ numberOfEntries: this.state.numberOfEntries + 10 });
    }
  }

  teamColumns(isAdmin) {
    let teamColumns = [
      {
        header: "",
        key: "",
        style: {
          width: "2%"
        }
      },
      {
        header: Resources.TeamMember,
        key: "displayName",
        style: {
          width: "48%"
        }
      },
      {
        header: Resources.Tasks,
        key: "tasks",
        style: {
          width: "16%"
        }
      },
      {
        header: Resources.PercentResponses,
        key: "responsePercentage",
        style: {
          width: "16%"
        }
      },
      {
        header: Resources.PercentResolutions,
        key: "resolutionPercentage",
        style: {
          width: "16%"
        }
      },
      {
        header: "",
        key: "",
        style: {
          width: "2%"
        }
      }
    ];

    return teamColumns;
  }

  prettyTeam(team) {
    if (!isEmpty(team)) {
      return team.map(member => {
        let m = clone(member);
        m.responsePercentage = Math.round(member.responsePercentage * 10) / 10 + "%";
        m.resolutionPercentage = Math.round(member.resolutionPercentage * 10) / 10 + "%";
        m.averageReviewTime = ticksToReadableTime(member.averageReviewTime);
        m.displayName = this.props.getReportUserDisplayName(member.userId);
        return m;
      });
    }
    return team;
  }

  prettyGraph(graphArray) {
    if (!isEmpty(graphArray)) {
      return graphArray.map(entry => {
        let e = clone(entry);
        e.displayName = this.props.getReportUserDisplayName(entry.userId);
        return e;
      });
    }
    return graphArray;
  }

  YAxisTickFormatter(tick, data) {
    if (data.length > 6 && tick.length > 7) {
      return tick.substr(0, tick.indexOf(" ")) + "...";
    }
    if (tick.length > 12) {
      let min;
      min = tick.indexOf(" ") > 10 ? 10 : tick.indexOf(" ");
      return tick.substr(0, min) + "...";
    }
    return tick;
  }

  render() {
    let { fetchingReport, reportData } = this.props.reports;
    let team = this.prettyTeam(reportData.members || []);
    let sortedTeam = team.sort(function(a, b) {
      return a.averageResolutionTime - b.averageResolutionTime;
    });
    let longestResolutionTimeMember = !isEmpty(sortedTeam[sortedTeam.length - 1])
      ? this.props.getReportUserDisplayName(sortedTeam[sortedTeam.length - 1].userId)
      : null;

    let xAxisTicks = !isEmpty(reportData.members)
      ? getReportsTicks(reportData.members.map(member => member.averageResolutionTime))
      : [0];

    return (
      <React.Fragment>
        <MainContentHeader title={Resources.TeamReport} hideSearch={true} />
        {fetchingReport ? (
          <MainLoader fullScreen={true} />
        ) : (
          <div className="reports">
            <ReportsFilter />
            <div className="reports-content-blocks">
              <div className="row">
                <ReportContentBlock
                  heading={Resources.TotalTasks}
                  data={reportData.totalTasks}
                  backgroundColor="#03A08F"
                />
                <ReportContentBlock
                  heading={Resources.Responses}
                  data={reportData.totalResponses}
                  backgroundColor="#0E92E3"
                />
                <ReportContentBlock
                  heading={Resources.SlowestResolver}
                  data={longestResolutionTimeMember}
                  backgroundColor="#097ABF"
                />
                <ReportContentBlock
                  heading={Resources.Resolutions}
                  data={reportData.totalResolutions}
                  backgroundColor="#676C89"
                />
                <ReportContentBlock
                  heading={Resources.AvgResolutionTime}
                  data={ticksToReadableTime(reportData.averageResolutionTime)}
                  backgroundColor="#4B4E61"
                />
                <ReportContentBlock
                  heading={Resources.AvgResponseTime}
                  data={ticksToReadableTime(reportData.averageResponseTime)}
                  backgroundColor="#31333F"
                />
              </div>
            </div>
            <div className="reports-chart-area">
              <div className="reports-charts-title">{Resources.AverageResolutionTimeByTeamMember}</div>
              <ResponsiveContainer>
                <BarChart
                  data={sortedTeam}
                  layout="vertical"
                  isAnimationActive={true}
                  margin={{ top: 10, right: 20, bottom: 10, left: 40 }}
                >
                  <CartesianGrid horizontal={false} />
                  <XAxis
                    type="number"
                    axisLine={false}
                    tickLine={false}
                    tickFormatter={tick =>
                      this.state.graphShown !== "numberOfRequests" && tick !== 0
                        ? millisecondsToReadableTimeHardCutoff(tick / 10000, true)
                        : tick
                    }
                    ticks={xAxisTicks}
                  />
                  <YAxis
                    dataKey="displayName"
                    type="category"
                    tickFormatter={tick => this.YAxisTickFormatter(tick, sortedTeam.length)}
                  />
                  <Bar name="Average Resolution Time" dataKey="averageResolutionTime" fill="#80CEFF" />
                  <Tooltip
                    formatter={(value, name, props) =>
                      this.state.graphShown !== "numberOfRequests" ? ticksToReadableTime(value, true) : value
                    }
                  />
                </BarChart>
              </ResponsiveContainer>
            </div>
            <ReportsExportButton />
            <div className="table-tasks-report">
              {isEmpty(team) ? (
                <ReportsNoData height="12vw" reportType="team" />
              ) : (
                <TabularData
                  selectable={false}
                  onLoadMore={this.loadMore}
                  maxRows={team.length}
                  rowClassName="testing-reports-table"
                  rowStyle={{ cursor: "default" }}
                  data={team.slice(0, this.state.numberOfEntries)}
                  format={{
                    callBack: row => {},
                    selectAll: (e, rows) => {},
                    selected: (e, row) => {},
                    columns: this.teamColumns()
                  }}
                  //maxHeight="40vh"
                />
              )}
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

const storeToProps = store => {
  return {
    reports: store.reports
  };
};

export default connect(
  storeToProps,
  dispatchToProps
)(ReportsTeam);
