import React, { Component } from "react";

class IconUnread extends Component {
  render() {
    let { className, height } = this.props;

    return (
      <svg viewBox="0 0 22 18" height={height} className={`icon ${className ? className : ""}`}>
        <g
          stroke="currentColor"
          strokeWidth="2"
          fill="none"
          fillRule="evenodd"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M3 1h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H3c-1.1 0-2-.9-2-2V3c0-1.1.9-2 2-2z" />
          <path d="M21 3l-10 7L1 3" />
        </g>
      </svg>
    );
  }
}

IconUnread.defaultProps = {
  height: 18
};

export default IconUnread;
