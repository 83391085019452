import React, { Component } from "react";
import { connect } from "react-redux";

import { dispatchToProps } from "../store/general-actions";

import MessageFlyout from "./customer_portal/messageFlyout";
import MakePaymentFlyout from "./customer_portal/makePaymentFlyout";
import DisputeFlyout from "./customer_portal/flyouts/disputeFlyout";
import PromiseToPayFlyout from "./customer_portal/flyouts/promiseToPayFlyout";
import ContactFlyout from "./customer_portal/flyouts/contactFlyout";
import ApplyPaymentFlyout from "./customer_portal/flyouts/applyPaymentFlyout";
import ManageAutoPayFlyout from "./customer_portal/flyouts/manageAutoPayFlyout";
import PaymentsFlyout from "./customer_portal/flyouts/paymentsFlyout";
import InvoicesFlyout from "./customer_portal/flyouts/invoicesFlyout";
import ProfileUpdateRequestFlyout from "./lockstep/flyouts/profileUpdateRequestFlyout";

import Modal from "react-modal";
import ShareFlyout from "./lockstep/flyouts/shareFlyout";
import NewActivityFlyout from "./lockstep/flyouts/newActivityFlyout";
import MyProfileView from "./lockstep/views/profileManagement/myProfileView";
import CompanyProfileView from "./lockstep/views/profileManagement/companyProfileView";
import AddContactFlyout from "./lockstep/flyouts/addContactFlyout";
import SettingsFlyout from "./lockstep/views/settings/settingsControllerFlyout";

class FlyoutManager extends Component {
  render() {
    if (this.props.modal.showFlyout === false) {
      return null;
    }

    Modal.setAppElement(document.getElementById("root"));

    switch (this.props.modal.flyoutType) {
      case "messageFlyout":
        return <MessageFlyout {...this.props.modal.flyoutProps} />;
      case "makePaymentFlyout":
        return <MakePaymentFlyout {...this.props.modal.flyoutProps} />;
      case "disputeFlyout":
        return <DisputeFlyout {...this.props.modal.flyoutProps} />;
      case "promiseToPayFlyout":
        return <PromiseToPayFlyout {...this.props.modal.flyoutProps} />;
      case "addContactFlyout":
        return <AddContactFlyout {...this.props.modal.flyoutProps} />;
      case "contactFlyout":
        return <ContactFlyout {...this.props.modal.flyoutProps} />;
      case "applyPaymentFlyout":
        return <ApplyPaymentFlyout {...this.props.modal.flyoutProps} />;
      case "manageAutoPayFlyout":
        return <ManageAutoPayFlyout {...this.props.modal.flyoutProps} />;
      case "profileUpdateRequestFlyout":
        return <ProfileUpdateRequestFlyout {...this.props.modal.flyoutProps} />;
      case "shareFlyout":
        return <ShareFlyout {...this.props.modal.flyoutProps} />;
      case "myProfileView":
        return <MyProfileView {...this.props.modal.flyoutProps} />;
      case "companyProfileView":
        return <CompanyProfileView {...this.props.modal.flyoutProps} />;
      case "paymentsFlyout":
        return <PaymentsFlyout {...this.props.modal.flyoutProps} />;
      case "invoicesFlyout":
        return <InvoicesFlyout {...this.props.modal.flyoutProps} />;
      case "newActivityFlyout":
        return <NewActivityFlyout {...this.props.modal.flyoutProps} />;
      case "settingsFlyout":
        return <SettingsFlyout {...this.props.modal.flyoutProps} />;
      default:
        return null;
    }
  }
}

const storeToProps = store => {
  return {
    modal: store.modal
  };
};

export default connect(storeToProps, dispatchToProps)(FlyoutManager);
