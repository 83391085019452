import React, { Component } from "react";
import { connect } from "react-redux";

import { dispatchToProps } from "../../store/modal-actions";
import Resources from "../../lib/resources";

import IconCheck from "../library/icons/iconCheck";
import IconAlertCircle from "../library/icons/iconAlertCircle";
import IconSpinner from "../library/icons/iconSpinner";

class ContactSubmittingNotification extends Component {
  constructor(props) {
    super(props);

    this.state = {
      address: "",
      isValidAddress: false
    };
  }

  componentDidUpdate(prevProps) {
    if (
      (this.props.submitted === true && prevProps.submitted === false) ||
      (this.props.failed === true && prevProps.failed === false)
    ) {
      setTimeout(this.props.hideNotification, 5000);
    }
  }

  render() {
    let { submitting, submitted, failed } = this.props;

    let content;
    if (submitting) {
      content = (
        <React.Fragment>
          <IconSpinner height={20} className="notification-icon-left" />
          <div>{Resources.ContactSubmitting}</div>
        </React.Fragment>
      );
    }
    if (submitted) {
      content = (
        <React.Fragment>
          <IconCheck height={18} className="notification-icon-left"></IconCheck>
          <div>{Resources.ContactSubmitted}</div>
        </React.Fragment>
      );
    }
    if (failed) {
      content = (
        <React.Fragment>
          <IconAlertCircle height={18} className="notification-icon-left"></IconAlertCircle>
          <div>{Resources.ContactSubmitFailed}</div>
        </React.Fragment>
      );
    }
    return <div className="notification">{content}</div>;
  }
}

const storeToProps = (store, ownProps) => {
  const { isNew } = ownProps;
  return {
    submitting: isNew ? store.manage.puttingAddressBookContact : store.manage.postingAddressBookContact,
    submitted: isNew ? store.manage.putAddressBookContact : store.manage.postedAddressBookContact,
    failed: isNew ? store.manage.failedPutAddressBookContact : store.manage.failedPostAddressBookContact
  };
};

export default connect(
  storeToProps,
  dispatchToProps
)(ContactSubmittingNotification);
