import React, { Component } from "react";
import { Card } from "reactstrap";
import Avatar from "./avatar";
import IconMail from "./icons/iconMail";
import IconPhone from "./icons/iconPhone";
import IconArchive from "./icons/iconArchive";
import IconMapPin from "./icons/iconMapPin";
import IconLinkedIn from "./icons/iconLinkedIn";
import IconClipboard from "./icons/iconClipboard";
import IconBriefcase from "./icons/iconBriefcase";

import Resources from "../../lib/resources";

import { isEmpty, formatDate } from "../../lib/utils";

class CompanyInfoCard extends Component {
  render() {
    let classifications = [
      { value: 1, classification: "Corporation" },
      { value: 2, classification: "Partnership" },
      { value: 3, classification: "Sole-proprietor" }
    ];
    let accountData = this.props.accountData;
    let accountingGroupDetails = this.props.accountingGroupDetails;

    return (
      <div className="profile-update-card-container">
        <Card className="account-profile__card">
          <div className="account-profile__card__last-updated">
            {Resources.UpdatedDate(formatDate(accountData.profileUpdateDate))}
          </div>
          <div className="profile-update-card-avatar-container">
            <Avatar
              height={"3.6rem"}
              imgURL={accountData.logoURL}
              // type="user"
              objectName={accountData.companyName}
              className={"avatar"}
            ></Avatar>
            <h2>{accountData.companyName}</h2>
          </div>
          <div className="">
            <div className="account-profile-item">
              <div className="account-profile-item__icon">
                <IconMail height={18} />
              </div>
              <div className="account-profile-item__content">
                {accountData.emailAddr || accountingGroupDetails.emailAddr}
              </div>
            </div>
            {!isEmpty(accountData.phone) && (
              <div className="account-profile-item">
                <div className="account-profile-item__icon">
                  <IconPhone height={22} />
                </div>
                <div className="account-profile-item__content">{accountData.phone}</div>
              </div>
            )}
            {!isEmpty(accountData.fax) && (
              <div className="account-profile-item">
                <div className="account-profile-item__icon">
                  <IconArchive height={16} />
                </div>
                <div className="account-profile-item__content">{accountData.fax}</div>
              </div>
            )}
            {!isEmpty(
              [
                accountData.mailToAddrLn1,
                accountData.mailToCity,
                accountData.mailToState,
                accountData.mailToPostalcode,
                accountData.mailToCountry
              ].filter(value => !isEmpty(value))
            ) && (
              <div className="account-profile-item">
                <div className="account-profile-item__icon">
                  <IconMapPin height={22} />
                </div>
                <div className="account-profile-item__content">
                  <div>{accountData.mailToAddrLn1}</div>
                  <div>
                    {accountData.mailToCity}, {accountData.mailToState} {accountData.mailToPostalcode}
                  </div>
                  <div>{accountData.mailToCountry}</div>
                </div>
              </div>
            )}
            {!isEmpty(
              [
                accountData.billToAddrLn1,
                accountData.billToCity,
                accountData.billToState,
                accountData.billToPostalcode,
                accountData.billToCountry
              ].filter(value => !isEmpty(value))
            ) && (
              <div className="account-profile-item">
                <div className="account-profile-item__icon">
                  <IconMapPin height={22} />
                </div>
                <div className="account-profile-item__content">
                  <div className="account-profile-item__header">{Resources.BillTo}</div>
                  <div>{accountData.billToAddrLn1}</div>
                  <div>
                    {accountData.billToCity}, {accountData.billToState} {accountData.billToPostalcode}
                  </div>
                  <div>{accountData.billToCountry}</div>
                </div>
              </div>
            )}
            {!isEmpty(
              [
                accountData.shipToAddrLn1,
                accountData.shipToCity,
                accountData.shipToState,
                accountData.shipToPostalcode,
                accountData.shipToCountry
              ].filter(value => !isEmpty(value))
            ) && (
              <div className="account-profile-item">
                <div className="account-profile-item__icon"></div>
                <div className="account-profile-item__content">
                  <div className="account-profile-item__header">{Resources.ShipTo}</div>
                  <div>{accountData.shipToAddrLn1}</div>
                  <div>
                    {accountData.shipToCity}, {accountData.shipToState} {accountData.shipToPostalcode}
                  </div>
                  <div>{accountData.shipToCountry}</div>
                </div>
              </div>
            )}
            {!isEmpty([accountData.taxId, accountData.entityClassification].filter(value => !isEmpty(value))) && (
              <div className="account-profile-item">
                <div className="account-profile-item__icon">
                  <IconBriefcase height={19} />
                </div>
                <div className="account-profile-item__content">
                  <div>
                    {Resources.ein}: {accountData.taxId}
                  </div>
                  <div>
                    {Resources.EntityClassification}:{" "}
                    {
                      (classifications.filter(i => i.value === accountData.entityClassificationId)[0] || {})
                        .classification
                    }
                  </div>
                </div>
              </div>
            )}
            {!isEmpty(accountData.linkedInProfile) && (
              <div className="account-profile-item">
                <div className="account-profile-item__icon">
                  <IconLinkedIn height={19} />
                </div>
                <div className="account-profile-item__content">
                  <a rel="norefferer noopener" href={accountData.linkedInProfile}>
                    {accountData.linkedInProfile}
                  </a>
                </div>
              </div>
            )}
            {!isEmpty(accountData.notes) && (
              <div className="account-profile-item">
                <div className="account-profile-item__icon">
                  <IconClipboard height={20} />
                </div>
                <div className="account-profile-item__content">{accountData.notes}</div>
              </div>
            )}
          </div>
        </Card>
      </div>
    );
  }
}

export default CompanyInfoCard;
