import React, { Component } from "react";
import MyProfileView from "./myProfileView";
import CompanyProfileView from "./companyProfileView";
import { Switch, Route, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { dispatchToProps as networkDP } from "../../../../store/network-actions";

const dispatchToProps = dispatch => ({
  ...networkDP(dispatch)
});

class ProfileManagmentView extends Component {
  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route path="/v2/:companyId/:perspectiveId/:view/my-profile">
            <MyProfileView />
          </Route>
          <Route path="/v2/:companyId/:perspectiveId/:view/company-profile/:subView">
            <CompanyProfileView />
          </Route>
        </Switch>
      </React.Fragment>
    );
  }
}

const storeToProps = store => {
  return {};
};

export default withRouter(
  connect(
    storeToProps,
    dispatchToProps
  )(ProfileManagmentView)
);
