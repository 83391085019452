import React, { Component } from "react";
import { connect } from "react-redux";
import Resources from "../../../lib/resources";

import { dispatchToProps } from "../../../store/connector-actions";

import { isEmpty, find } from "../../../lib/utils";
import { formatInvoice } from "../../../lib/transform-ubl";
import Attachment from "../../attachment";

class InvoiceThreadItem extends Component {
  render() {
    const { attachments, dateText, entry, connectorStore } = this.props;
    const connectorVersionId = JSON.parse(entry.metadata).ConnectorVersionId;
    if (isEmpty(entry) || isEmpty(entry.asJson)) {
      return null;
    }

    const { invoiceNum, issueDate, dueDate, amount, id: invoiceId } = formatInvoice(entry.asJson);
    let titleNote = "";

    if (entry.fromCompanyId === entry.toCompanyId) {
      let connectorId = this.props.getConnectorIdFromVersion(connectorVersionId);
      let erp = (
        find(connectorStore.allSupportedConnectors, c => {
          return c.connectorId === connectorId;
        }) || {}
      ).displayName;

      titleNote = <span>{Resources.AutoEntryMessage(Resources.Invoice, invoiceId, erp)}</span>;
    } else {
      titleNote = <span>{Resources.ResourceCreated(Resources.Invoice)}</span>;
    }

    return (
      <React.Fragment>
        <div className="conversation-thread-item system-message-item">
          <div>
            {titleNote}
            <span className="float-right" style={{ textAlign: "right" }}>
              <div className="thread-item-age">
                {dateText} {Resources.Ago.toLowerCase()}
              </div>
            </span>
          </div>
        </div>
        <div className="invoice-thread-item">
          <div>
            <div className="invoice-title">{invoiceNum}</div>
            <div className="flex">
              <div className="invoice-detail-container">
                <div className="invoice-detail-label">{Resources.Issued}:</div>
                <div>{issueDate}</div>
              </div>
              <div className="invoice-detail-container">
                <div className="invoice-detail-label">{Resources.Due}:</div>
                <div>{dueDate}</div>
              </div>
              <div className="invoice-detail-container">
                <div className="invoice-detail-label">{Resources.Amount}</div>
                <div className="notification-color-font fw-700">{amount}</div>
              </div>
            </div>
            {isEmpty(attachments) === false && (
              <div>
                <br />
                {attachments.map(att => {
                  return (
                    <Attachment
                      attachment={att}
                      key={att.attachmentId}
                      showIcon={true}
                      name={att.fileName}
                      size={att.sizeBytes}
                    />
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const storeToProps = store => {
  return {
    connectorStore: store.connector
  };
};

export default connect(
  storeToProps,
  dispatchToProps
)(InvoiceThreadItem);
