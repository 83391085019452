import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";

import Resources from "../lib/resources";
import MainContentHeader from "./main_content_header/mainContentHeader";
import PerspectiveTabs from "./perspectiveTabs";
import ModalDialog from "./modalDialog";
import MainLoader from "./mainLoader";
import CRUDList from "./library/crudList";
import Card from "./library/card";

import { dispatchToProps as maDP } from "../store/manage-actions";
import { dispatchToProps as modDP } from "../store/modal-actions";
import { dispatchToProps as paDP } from "../store/perspectives-actions";
import { dispatchToProps as uaDP } from "../store/user-actions";
import { isEmpty, getFileNameIconClasses } from "../lib/utils";

const dispatchToProps = dispatch => {
  return {
    ...modDP(dispatch),
    ...maDP(dispatch),
    ...paDP(dispatch),
    ...uaDP(dispatch)
  };
};

class SharedDocuments extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedDocument: null,
      perspective: null,
      deleteDocument: null,
      upload: null
    };

    this.editDocument = this.editDocument.bind(this);
    this.deleteDocument = this.deleteDocument.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
  }

  componentDidUpdate() {
    if (this.state.perspective === null) {
      const defaultPerspective = this.props.getCompanyPerspectiveById(
        this.props.userStore.userConfig.defaultPerspective,
        this.props.match.params.companyId
      );
      if (defaultPerspective !== null) {
        this.setState({ perspective: defaultPerspective });
      }
    }
  }

  editDocument(document = null) {
    this.setState({ selectedDocument: document }, () =>
      this.props.displayModal("sharedDocumentForm", this.getModalProps())
    );
  }

  deleteDocument(document) {
    const {
      match: { params }
    } = this.props;
    const companyId = params.companyId;
    const perspectiveId = this.state.perspective.perspectiveId;
    this.props.deleteCompanyDocument(companyId, perspectiveId, document.companyDocumentId);
    this.setState({ deleteDocument: null });
  }

  uploadFile() {
    window.document.getElementById("uploadFile").click();
  }

  getModalProps() {
    const { perspective, selectedDocument, upload } = this.state;
    const perspectiveId = perspective.perspectiveId;
    return {
      companyDocument: selectedDocument,
      upload,
      perspectiveId,
      companyId: this.props.match.params.companyId,
      clearSelectedDocument: () => {
        document.getElementById("uploadFile").value = null;
        this.setState({ selectedDocument: null, upload: null });
      },
      uploadFile: this.uploadFile
    };
  }

  columns() {
    return [
      {
        header: Resources.Name.toLocaleUpperCase(),
        content: row => (
          <div className="file-upload-item p-0">
            <span className={getFileNameIconClasses(row.companyDocumentName) + " filetype-icon"} />
            <div className="flex-column">
              <span>{row.companyDocumentName}</span>
              <span>{row.description}</span>
            </div>
          </div>
        ),
        width: "44%"
      },
      {
        header: Resources.AddedBy.toLocaleUpperCase(),
        content: row => this.props.getUserDisplayName(row.createdByUserId, Resources.You),
        width: "15%"
      },
      {
        header: Resources.DateAdded.toLocaleUpperCase(),
        content: row => moment(row.createdDate).format("LL"),
        width: "20%"
      },
      {
        header: Resources.Actions.toLocaleUpperCase(),
        type: "actions",
        actions: [
          { type: "update", onClick: row => this.editDocument(row) },
          { type: "delete", onClick: row => this.setState({ deleteDocument: row }) }
        ]
      }
    ];
  }

  render() {
    const { perspective, deleteDocument } = this.state;
    const {
      match: {
        params: { companyId }
      }
    } = this.props;

    if (isEmpty(perspective)) {
      return <MainLoader fullScreen={true} />;
    }

    const perspectiveId = perspective.perspectiveId;

    const documents = this.props.getCompanyDocuments(companyId, perspectiveId) || [];

    return (
      <React.Fragment>
        <MainContentHeader
          title={Resources.SharedDocuments}
          customContent={
            <PerspectiveTabs
              handleSelect={perspective => this.setState({ perspective })}
              selectedPerspective={perspective}
            />
          }
          hideSearch={true}
        />

        <div className="manage-content">
          {deleteDocument && (
            <ModalDialog
              isOpen={true}
              toggle={() => this.setState({ deleteDocument: null })}
              title={Resources.Confirm}
              content={
                <div
                  dangerouslySetInnerHTML={{
                    __html: Resources.ConfirmDeleteDocument(deleteDocument.companyDocumentName)
                  }}
                />
              }
              footer={
                <div className="float-right">
                  <button
                    className="cancel-button"
                    onClick={() => {
                      this.setState({ deleteDocument: null });
                    }}
                  >
                    {Resources.Cancel}
                  </button>
                  <button
                    className="delete-button"
                    onClick={() => {
                      this.deleteDocument(deleteDocument);
                    }}
                  >
                    {Resources.Remove}
                  </button>
                </div>
              }
            />
          )}
          <Card maxWidth={"80em"} className="card-crud-list">
            <CRUDList
              columns={this.columns()}
              data={documents}
              title={Resources.SharedDocumentList}
              description={Resources.SharedDocumentListDescription}
              onCreate={() => this.uploadFile()}
              createText={Resources.AddNewFile}
              loading={this.props.manageStore.isFetchingCompanyDocuments}
            />
          </Card>
          <input
            className="hidden-input"
            type="file"
            id="uploadFile"
            style={{ display: "none" }}
            onChange={e => {
              this.setState({ upload: e.target.files[0] }, () => {
                this.props.displayModal("sharedDocumentForm", this.getModalProps());
              });
            }}
          />
        </div>
      </React.Fragment>
    );
  }
}

const storeToProps = store => {
  return {
    manageStore: store.manage,
    userStore: store.user
  };
};

export default connect(
  storeToProps,
  dispatchToProps
)(SharedDocuments);
