import React, { Component } from "react";
import { uniqueId, isEmpty } from "../../lib/utils";

export default class Checkbox extends Component {
  render() {
    const id = "checkbox_" + uniqueId();
    const onClick = this.props.preventBubbling ? e => e.stopPropagation() : null;
    return (
      <div className={"checkbox " + (this.props.className || "")} onClick={onClick} style={this.props.style || {}}>
        <input
          id={id}
          checked={this.props.checked}
          type="checkbox"
          className="hidden"
          onChange={this.props.onChange}
          disabled={this.props.disabled}
        />
        <div className="checkbox-display" onClick={this.props.onChange}>
          <svg viewBox="0 0 18 18" height="16">
            <path className="checkbox-display-border" d="M.5.5h17v17H.5z" />
            <path className={`checkbox-display-fill ${this.props.checked ? "checked" : ""}`} d="M3 3h12v12H3z" />
          </svg>
        </div>
        <label htmlFor={id} className={isEmpty(this.props.label) ? "no-show" : ""}>
          {this.props.label || ""}
        </label>
      </div>
    );
  }
}
