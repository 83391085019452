import { isEmpty } from "./utils";
import Resources from "./resources";

export const Perspectives = {
  AccountsPayable: "vendors",
  AccountsReceivable: "customers"
};

export const getPerspective = type => {
  let lcType = (isEmpty(type) ? "" : type).toLocaleLowerCase();
  switch (lcType) {
    case "ar":
      return Perspectives.AccountsReceivable;
    case "ap":
    default:
      return Perspectives.AccountsPayable;
  }
};

export const getDisplayPerspective = perspective => {
  switch (perspective) {
    case Perspectives.AccountsPayable:
    case "ap":
      return Resources.AccountsPayable;
    case Perspectives.AccountsReceivable:
    case "ar":
      return Resources.AccountsReceivable;
    default:
      return perspective;
  }
};

export const getPartnerType = (perspective, plural) => {
  switch (perspective) {
    case Perspectives.AccountsPayable:
    case "ap":
      if (plural) {
        return Resources.Vendors;
      }
      return Resources.Vendor;
    case Perspectives.AccountsReceivable:
    case "ar":
      if (plural) {
        return Resources.Customers;
      }
      return Resources.Customer;
    default:
      return perspective;
  }
};
