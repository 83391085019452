import React, { Component } from "react";
import { connect } from "react-redux";
import Resources from "../lib/resources";
import { dispatchToProps } from "../store/conversations-actions";

import UnauthorizedHeader from "./library/unauthorizedHeader";
import RadioInput from "./library/radioInput";
import TextAreaInput from "./library/textAreaInput";
import { isEmpty } from "../lib/utils";
import Icons from "../lib/icons";

class ApprovalRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.defaultState
    };

    this.fetchApprovalRequest = this.fetchApprovalRequest.bind(this);
    this.content = this.content.bind(this);
    this.bottomButtons = this.bottomButtons.bind(this);
    this.poweredByLockstep = this.poweredByLockstep.bind(this);
    this.inputRef = React.createRef();
  }

  defaultState = {
    selectedAction: "reject",
    comment: "",
    submittedAction: null,

    showActionSummary: false
  };

  componentDidMount() {
    window.Intercom("shutdown");
    window.Intercom("boot");
    this.fetchApprovalRequest();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.match.params.conversationId !== this.props.match.params.conversationId ||
      prevProps.match.params.signature !== this.props.match.params.signature
    ) {
      this.setState({ ...this.defaultState });
      this.fetchApprovalRequest();
    }
  }

  fetchApprovalRequest() {
    this.props.getApprovalRequest(this.props.match.params.conversationId, this.props.match.params.signature);
  }

  poweredByLockstep() {
    return (
      <div
        className="approval-request-powered-by"
        dangerouslySetInnerHTML={{
          __html: Resources.PoweredByLockstep
        }}
      />
    );
  }

  approvalOptions() {
    return [{ label: Resources.Approve, value: "approve" }, { label: Resources.Reject, value: "reject" }];
  }

  content() {
    if (this.state.showActionSummary) {
      return (
        <React.Fragment>
          <div className="approval-request-subject">
            <h2>{Resources.ThankYouForYourDecision}</h2>
          </div>
          <hr className="divider-light max-width-600" />
          <div className="approval-request-body text-center">
            <p>{Resources.YouHaveActionThis(this.state.submittedAction.action)}</p>
            <p className="pt-4">{Resources.YourComment + ":"}</p>
            <div>{this.state.submittedAction.comment}</div>
            <p className="pt-4">
              {Resources.WeWillNotifyRequesterOfYourDecision(
                (this.props.conversationsStore.approvalRequest.asJson || {}).requesterDisplayName
              )}
              <br />
              {Resources.HaveAWonderfulDayClosePage}
            </p>
            {this.bottomButtons()}
            {this.poweredByLockstep()}
          </div>
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <div className="approval-request-subject">
          <h2>{(this.props.conversationsStore.approvalRequest.asJson || {}).subject}</h2>
        </div>
        <hr className="divider-light max-width-600" />
        <div className="approval-request-body">
          <RadioInput
            inline
            inputs={this.approvalOptions()}
            radioName="testing"
            value={this.state.selectedAction}
            onChange={item => {
              this.setState({
                selectedAction: item.value
              });
            }}
          />
          <TextAreaInput
            textValue={this.state.comment}
            label={Resources.AddComment}
            inputOnChange={e => this.setState({ comment: e.target.value })}
            placeholder={Resources.CommentRequired}
            rows={4}
            className="mt-4"
          />
          <div className="d-flex flex-row-reverse">{this.bottomButtons()}</div>
          {this.poweredByLockstep()}
        </div>
      </React.Fragment>
    );
  }

  bottomButtons() {
    if (this.state.showActionSummary) {
      return null;
    }
    if (this.props.conversationsStore.puttingApprovalRequestAction) {
      return <span className={Icons.spinner} />;
    }
    return (
      <div className="approval-request-bottom-buttons align-content-center">
        {this.props.conversationsStore.failedPutApprovalRequestAction && (
          <div className="warning-color-font pr-3">{Resources.FailedToSendApprovalDecision}</div>
        )}
        <button
          className="button-green"
          disabled={isEmpty(this.state.comment)}
          onClick={() =>
            this.props
              .putApprovalRequestAction(
                this.props.match.params.conversationId,
                this.props.match.params.signature,
                this.state.selectedAction,
                this.state.comment
              )
              .then(response => {
                this.setState({
                  submittedAction: {
                    action:
                      this.state.selectedAction === "approve"
                        ? Resources.Approved.toLocaleLowerCase()
                        : Resources.Rejected.toLocaleLowerCase(),
                    comment: this.state.comment
                  },
                  showActionSummary: true
                });
              })
              .catch(error => {
                return;
              })
          }
        >
          {Resources.Confirm}
        </button>
      </div>
    );
  }

  render() {
    return (
      <React.Fragment>
        <UnauthorizedHeader />
        <div className="approval-request">{this.content()}</div>
      </React.Fragment>
    );
  }
}

const storeToProps = store => {
  return {
    conversationsStore: store.conversations
  };
};

export default connect(
  storeToProps,
  dispatchToProps
)(ApprovalRequest);
