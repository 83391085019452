import React, { Component } from "react";

class IconCheck extends Component {
  render() {
    let { className, height } = this.props;

    return (
      <svg viewBox="0 0 18 13" height={height} className={`icon ${className ? className : ""}`}>
        <path
          d="M17 1L6 12 1 7"
          stroke="currentColor"
          strokeWidth="2"
          fill="none"
          fillRule="evenodd"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
}

IconCheck.defaultProps = {
  height: 13
};

export default IconCheck;
