import React, { Component } from "react";
import moment from "moment";
import { connect } from "react-redux";

import { dispatchToProps as seqDP } from "../../store/sequences-actions";

import Card from "../library/card";
import TableData from "../library/tableData";
import TableDataSortableHeader from "../library/tableDataSortableHeader";
import ToggleSwitch from "../library/toggleSwitch";

import Resources from "../../lib/resources";

const dispatchToProps = dispatch => {
  return {
    ...dispatch(seqDP)
  };
};

class SequencesTabContent extends Component {
  toggleSequenceActivation(row) {
    const { customerSequenceId, isActive } = row;
    this.props.postSequence(this.props.companyId, { customerSequenceId, isActive: !isActive });
  }

  render() {
    const data = this.props.sequencesStore.sequences;
    const columns = [
      {
        header: (
          <TableDataSortableHeader
            sortBy={this.props.sequencesStore.sequencesSortBy}
            sortDirection={this.props.sequencesStore.sequencesSortDirection}
            updateSort={(sortBy, sortDirection) => {}}
            text={Resources.SequenceName.toLocaleUpperCase()}
            sortKey={"customerSequenceName"}
          />
        ),
        content: row => <span>{row.customerSequenceName}</span>,
        width: "16%"
      },
      { width: "4%", content: row => null },
      {
        header: (
          <TableDataSortableHeader
            sortBy={this.props.sequencesStore.sequencesSortBy}
            sortDirection={this.props.sequencesStore.sequencesSortDirection}
            updateSort={(sortBy, sortDirection) => {}}
            text={Resources.Description.toLocaleUpperCase()}
            sortKey={"description"}
          />
        ),
        content: row => <span className="overflow-elipsis">{row.description}</span>,
        width: "40%"
      },
      { width: "4%", content: row => null },
      {
        header: (
          <TableDataSortableHeader
            sortBy={this.props.sequencesStore.sequencesSortBy}
            sortDirection={this.props.sequencesStore.sequencesSortDirection}
            updateSort={(sortBy, sortDirection) => {}}
            text={Resources.Customers.toLocaleUpperCase()}
            sortKey={"customerCount"}
          />
        ),
        content: row => <span>{row.customerCount}</span>,
        width: "11%"
      },
      { width: "4%", content: row => null },
      {
        header: (
          <TableDataSortableHeader
            sortBy={this.props.sequencesStore.sequencesSortBy}
            sortDirection={this.props.sequencesStore.sequencesSortDirection}
            updateSort={(sortBy, sortDirection) => {}}
            text={Resources.LastEdited.toLocaleUpperCase()}
            sortKey={"modifiedDate"}
          />
        ),
        content: row => <span>{moment(row.modifiedDate).format("MMM d")}</span>,
        width: "12%"
      },
      {
        header: "",
        content: row => <ToggleSwitch checked={row.isActive} onChange={() => this.toggleSequenceActivation(row)} />
      }
    ];

    return (
      <div>
        <button
          className="button-purple"
          style={{ width: "20rem", marginBottom: "2.67rem" }}
          onClick={this.props.createNewSequence}
        >
          {Resources.CreateANewSequence}
        </button>
        <h2 className="card-title" style={{ marginBottom: "1.1rem" }}>
          {Resources.Sequences}
        </h2>
        <Card className="p-0">
          <TableData
            pagination
            data={data}
            columns={columns}
            rowHeight="4em"
            loading={this.props.sequencesStore.isFetchingSequences}
            rowClassName="statements-view-row"
            offsetHeight="40"
            onRowClick={row => this.props.editSequence(row)}
            emptyRender={
              <div className="flex-center">
                <h4 className="mt-5">{Resources.EmptySequences}</h4>
              </div>
            }
          />
        </Card>
      </div>
    );
  }
}
const storeToProps = store => {
  return {
    sequencesStore: store.sequences
  };
};
export default connect(
  storeToProps,
  dispatchToProps
)(SequencesTabContent);
