import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from "react-modal";

import { dispatchToProps as moDP } from "../store/modal-actions";
import { dispatchToProps as conDP } from "../store/connector-actions";

import Resources from "../lib/resources";
import { find } from "../lib/utils";

import TextInput from "./library/textInput";
import RadioInput from "./library/radioInput";
import Checkbox from "./library/checkbox";
import icons from "../lib/icons";

const dispatchToProps = dispatch => ({
  ...moDP(dispatch),
  ...conDP(dispatch)
});

class ImapConfigModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      host: "",
      port: 993,
      username: "",
      password: "",
      pastDaysToImport: 1,
      importInputValue: "day"
    };

    this.submit = this.submit.bind(this);
    this.putConnectorConfig = this.putConnectorConfig.bind(this);
  }

  componentDidMount() {
    let { host, password, port, username, daysPrior } = this.props.modalStore.modalProps;
    let importInputValue;
    switch (daysPrior) {
      case 1:
        importInputValue = "day";
        break;
      case 7:
        importInputValue = "week";
        break;
      case 30:
        importInputValue = "month";
        break;
      default:
        importInputValue = "custom";
        break;
    }
    this.setState({
      host: host,
      password: password,
      username: username,
      port: port,
      pastDaysToImport: daysPrior,
      importInputValue: importInputValue
    });
  }

  componentDidUpdate(prevProps) {}

  submit() {
    let emailConnector = find(this.props.allSupportedConnectors, connector => connector.connectorName === "Email");

    if (this.props.connectorStore.connectorVersionDataIsFetched[emailConnector.connectorId]) {
      this.putConnectorConfig()
        .then(response => this.props.hideModal())
        .catch(error => null);
    } else {
      this.props
        .fetchConnectorVersionData(emailConnector.connectorId)
        .then(reponse => this.putConnectorConfig())
        .then(response => this.props.hideModal())
        .catch(error => null);
    }
  }

  getConnectorConfigIMAP() {
    return {
      host: this.state.host,
      port: this.state.port,
      username: this.state.username,
      password: this.state.password,
      deleteAfterImport: this.state.deleteAfterImport,
      daysPrior: this.state.pastDaysToImport,
      perspectiveId: this.props.perspective.perspectiveId
    };
  }

  putConnectorConfig() {
    let companyId = this.props.modalStore.modalProps.companyId;
    let perspectiveId = (this.props.modalStore.modalProps.perspective || {}).perspectiveId;

    let emailConnector = find(this.props.allSupportedConnectors, connector => connector.connectorName === "Email");

    let emailConnectorVersion = this.props.connectorStore.connectorVersionData[emailConnector.connectorId];
    let emailConnectorVersionId = emailConnectorVersion[0].connectorVersionId;
    let emailConnectorConfigs = (this.props.connectorStore.connectorConfigs[emailConnectorVersionId] || {})
      .connectorConfiguration;

    emailConnectorConfigs = (emailConnectorConfigs || []).filter(config => config.perspectiveId !== perspectiveId);

    return this.props
      .putConnectorConfiguration(
        companyId,
        this.props.connectorStore.connectorVersionData[emailConnector.connectorId][0].connectorVersionId,
        [...emailConnectorConfigs, this.getConnectorConfigIMAP()]
      )
      .then(response => {
        this.props.fetchConnectorConfiguration(companyId, emailConnectorVersionId);
        this.setState({ editingImap: false, putConfig: true });
      })
      .catch(error => null);
  }

  dayInputs() {
    return [
      { label: Resources.Day, value: "day" },
      { label: Resources.Week, value: "week" },
      { label: Resources.Month, value: "month" },
      {
        content: <div>{Resources.Custom}</div>,
        value: "custom"
      }
    ];
  }

  mapInputValue(value) {
    const map = { day: 1, week: 7, month: 30, custom: "" };
    return map[value];
  }

  render() {
    let { puttingConnectorConfig } = this.props.connectorStore;
    let bottomButtons;
    if (puttingConnectorConfig) {
      bottomButtons = (
        <button className="button-green">
          <span className={icons.spinner} />
          {Resources.Syncing}
        </button>
      );
    } else {
      bottomButtons = (
        <React.Fragment>
          <button onClick={this.submit} className="btn button-green">
            {Resources.SignIn}
          </button>
          <button className="btn button-cancel ml-3" onClick={() => this.props.hideModal()}>
            {Resources.Cancel}
          </button>
        </React.Fragment>
      );
    }
    return (
      <Modal
        isOpen={this.props.modalStore.showModal}
        onRequestClose={() => this.props.hideModal()}
        className="custom-modal"
        overlayClassName="custom-modal-overlay"
      >
        <div className="custom-modal-header">{Resources.ImapSettings}</div>
        <div className="custom-modal-divider" />
        <div className="custom-modal-content">
          <div className="mt-2">
            <TextInput
              className="mb-1"
              disabled={puttingConnectorConfig}
              width="19em"
              inline
              textValue={this.state.username}
              label={Resources.EmailAddress}
              placeholder={Resources.Username}
              id="perspective-imap-username"
              inputOnChange={e => {
                this.setState({ username: e.target.value });
              }}
            />{" "}
            <br />
            <TextInput
              fsObscure
              isPassword={true}
              className="mt-3"
              disabled={puttingConnectorConfig}
              width="19em"
              textValue={this.state.password}
              label={Resources.Password}
              placeholder={Resources.Password}
              id="perspective-imap-password"
              inputOnChange={e => {
                this.setState({ password: e.target.value });
              }}
            />
            <TextInput
              fsObscure
              inline
              disabled={puttingConnectorConfig}
              width="19em"
              textValue={this.state.host}
              label={Resources.HostAddress}
              placeholder={Resources.HostAddressExample}
              id="perspective-imap-host"
              inputOnChange={e => {
                this.setState({ host: e.target.value });
              }}
            />
            <TextInput
              fsObscure
              className="pl-3"
              inline
              disabled={puttingConnectorConfig}
              textValue={this.state.port}
              label={Resources.Port}
              placeholder={Resources.Port}
              id="perspective-imap-port"
              width="8em"
              inputOnChange={e => {
                this.setState({ port: parseInt(e.target.value, 10) || 0 });
              }}
            />
            <br />
            <div>{Resources.ImportAllMailFromPast}</div>
            <RadioInput
              inline
              disabled={puttingConnectorConfig}
              inputs={this.dayInputs()}
              radioName="testing"
              value={this.state.importInputValue}
              onChange={item => {
                const stateUpdate = { importInputValue: item.value };
                if (item.value !== "custom") {
                  stateUpdate.pastDaysToImport = this.mapInputValue(item.value);
                }
                this.setState(stateUpdate);
              }}
            />
            <TextInput
              inline
              className="margin-bottom-zero pl-3"
              disabled={!(puttingConnectorConfig || this.state.importInputValue === "custom")}
              textValue={this.state.importInputValue === "custom" ? this.state.pastDaysToImport : " "}
              id="perspective-imap-days"
              width="4em"
              inputOnChange={e => {
                this.setState({ pastDaysToImport: parseInt(e.target.value, 10) || 0 });
              }}
            />{" "}
            <span className="pl-1">{Resources.Days}</span>
            <Checkbox
              className="pt-3"
              disabled={puttingConnectorConfig}
              label={Resources.DeleteEmailFromServerAfterImport}
              checked={this.state.deleteAfterImport || false}
              onChange={() => this.setState({ deleteAfterImport: !this.state.deleteAfterImport })}
            />
            <br />
            {bottomButtons}
          </div>
        </div>
      </Modal>
    );
  }
}

const storeToProps = store => {
  return {
    modalStore: store.modal,
    connectorStore: store.connector,
    allSupportedConnectors: store.connector.allSupportedConnectors
  };
};

export default connect(
  storeToProps,
  dispatchToProps
)(ImapConfigModal);
