import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Resources from "../../lib/resources";
import { formatString } from "../../lib/strings";
import { isEmpty, getQueryParameters } from "../../lib/utils";
import SidebarInvoice from "../sidebarInvoice";
import FlexibleScrollContainer from "../flexibleScrollContainer";
import CompanyProfileUI from "./companyProfileUI";
import NotConnected from "./notConnected";
import MainLoader from "../mainLoader";

import { dispatchToProps as coDP } from "../../store/company-actions";
import { dispatchToProps as uaDP } from "../../store/user-actions";
import { dispatchToProps as caDP } from "../../store/conversations-actions";
import { dispatchToProps as paDP } from "../../store/perspectives-actions";
import { dispatchToProps as aaDP } from "../../store/accounts-actions";
import { dispatchToProps as congDP } from "../../store/contextGroups-actions";
import { dispatchToProps as connDP } from "../../store/connector-actions";
import { dispatchToProps as modDP } from "../../store/modal-actions";
import { dispatchToProps as genDP } from "../../store/general-actions";

const dispatchToProps = dispatch => ({
  ...uaDP(dispatch),
  ...coDP(dispatch),
  ...caDP(dispatch),
  ...paDP(dispatch),
  ...aaDP(dispatch),
  ...congDP(dispatch),
  ...connDP(dispatch),
  ...modDP(dispatch),
  ...genDP(dispatch)
});

class CompanyProfile extends Component {
  constructor(props) {
    super(props);
    this.state = this.defaultState();
  }

  defaultState() {
    return {
      companyName: "",
      address: {
        street: "",
        city: "",
        region: "",
        country: "",
        postal_code: ""
      },
      apPhone: "",
      arPhone: "",
      url: "",
      TIN: "",
      apEmail: "",
      arEmail: "",
      w9File: "",
      w8BenFile: "",
      editing: false,
      vendorLink: ""
    };
  }

  componentDidMount() {
    const { companyId, fetchCompanyInfo } = this.props;
    if (!isEmpty(companyId)) {
      fetchCompanyInfo(companyId);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      companyId,
      fetchCompanyInfo,
      match: { params },
      contextGroupsStore
    } = this.props;
    if (
      !isEmpty(prevProps) &&
      prevProps.companies.postingCompanyInfo === true &&
      this.props.companies.postingCompanyInfo === false
    ) {
      if (this.props.companyId !== this.props.match.params.companyId) {
        this.props.refreshContextGroups(params.companyId, params.perspectiveId);
      } else {
        this.props.fetchAccounts();
      }
      this.setState({ editing: false });
    }

    if (
      this.props.isSidePanel !== true &&
      this.props.companyName !== this.state.companyName &&
      this.state.editing === false &&
      this.props.accountsStore.fetchingAccounts !== true
    ) {
      this.setState({ companyName: this.props.companyName });
    }

    if (this.props.companies.fetchingCompanyInfoFailed[companyId] === true) {
      return;
    }

    const contextGroupsFetched =
      prevProps.contextGroupsStore.gotContextGroups !== true && contextGroupsStore.gotContextGroups === true;
    if (this.hasFetched() !== true) {
      fetchCompanyInfo(companyId);
    } else if (
      this.hasFetched(prevProps) !== true ||
      companyId !== prevProps.companyId ||
      contextGroupsFetched ||
      prevProps.invoiceLink !== this.props.invoiceLink
    ) {
      this.syncStoreToState();
    }
  }

  checkEditibility() {
    return isEmpty(this.props.companyId) ? false : this.props.isCompanyUnowned(this.props.companyId) === true;
  }

  hasFetched(props = this.props) {
    return props.companies.fetchedCompanyInfo[props.companyId] === true;
  }

  syncStoreToState() {
    if (this.state.editing) {
      return null;
    }
    const { companyId, getCompanyInfo, contextGroupsStore, conversationsStore } = this.props;
    const companyInfo = getCompanyInfo(companyId);
    const defaultState = this.defaultState();
    let contactNumbers = companyInfo["Contact Numbers"] || null;
    let vendorLink = {};

    if (!isEmpty(contextGroupsStore.value)) {
      const contextGroupId = ((conversationsStore.conversation || {}).contextGroupIds || [])[0];
      let selectedContextGroup = contextGroupsStore.contextGroupsMaps[contextGroupId] || {};
      vendorLink = { link: selectedContextGroup.link, linkText: selectedContextGroup.linkText };
    }

    this.setState({
      companyName: this.props.companyName || "",
      apEmail: companyInfo.apEmail || defaultState.apEmail,
      arEmail: companyInfo.arEmail || defaultState.arEmail,
      cn: companyInfo["Contact Numbers"] || defaultState.cn,
      apPhone: contactNumbers ? contactNumbers["AP Phone"] : defaultState.apPhone,
      arPhone: contactNumbers ? contactNumbers["AR Phone"] : defaultState.arPhone,
      address: { ...companyInfo.Address } || { ...defaultState.address },
      url: companyInfo.URL || defaultState.url,
      TIN: companyInfo.TIN || defaultState.TIN,
      w9File: companyInfo["W9 File"] || defaultState.w9,
      w8BenFile: companyInfo["W8 BEN File"] || defaultState.w8,
      vendorLink,
      editing: false
    });
  }

  render() {
    let { isSidePanel, actions, invoice, erpInvoiceEntry } = this.props;
    let { vendorLink, apPhone, arPhone } = this.state;
    const id = getQueryParameters((vendorLink || {}).link || "").id || "";

    actions = actions
      .filter(a => {
        return isEmpty(a.actionLinkTemplate) === false;
      })
      .map(a => {
        return {
          link: formatString(a.actionLinkTemplate, { id }),
          linkText: a.actionTitle
        };
      });

    if (isSidePanel) {
      return (
        <div>
          {/* {this.getPropertyItem(Resources.CompanyInfoContactNumbers, "cn")} */}
          <div>{Resources.CompanyInfoContactNumbers.toLocaleUpperCase()}</div>
          <div className="phone-numbers">
            <div className="flex">
              <div className="phone-label">{Resources.AccountsPayableAbbr}</div>
              <div>{apPhone}</div>
            </div>
            <div className="flex">
              <div className="phone-label">{Resources.AccountsReceivableAbbr}</div>
              <div>{arPhone}</div>
            </div>
          </div>
          <SidebarInvoice invoice={invoice} erpInvoiceEntry={erpInvoiceEntry} />
          <span className="divider" />
          {(actions.length > 0 || !isEmpty(vendorLink)) && (
            <React.Fragment>
              <div className="actions-container">
                <div>{Resources.Actions.toLocaleUpperCase()}</div>
                {vendorLink && (
                  <a href={vendorLink.link} target="_blank" rel="noopener noreferrer">
                    {vendorLink.linkText}
                  </a>
                )}
                {actions.map((a, i) => {
                  return (
                    <a key={i} href={a.link} target="_blank" rel="noopener noreferrer">
                      {a.linkText}
                    </a>
                  );
                })}
              </div>
              <span className="divider" />
            </React.Fragment>
          )}
        </div>
      );
    }

    let registeredERPConnector = this.props.getRegisteredERPConnectors(this.props.companyId)[0] || {};

    if (this.props.hasRegisteredERPConnectors(this.props.companyId) === null) {
      return <MainLoader fullScreen={true} />;
    }
    if (!this.props.hasRegisteredERPConnectors(this.props.companyId)) {
      return <NotConnected />;
    }
    if (isEmpty(vendorLink.linkText) && !this.props.isMyCompany) {
      return (
        <div className="company-profile-email-company">
          <div className="company-profile-email-company-header">
            {Resources.ChannelIsNotLinkedToAConnectorCompany(registeredERPConnector.displayName)}
          </div>
          <div className="company-profile-email-company-actions">
            <div className="company-profile-email-company-actions-label">{Resources.LockstepActions}:</div>
            <div className="company-profile-email-company-actions-buttons">
              <div className="my-2">
                <button
                  className="button-green company-profile-email-company-action-button"
                  onClick={() => {
                    this.props.displayModal("remap", {
                      companyId: this.props.companyId,
                      perspective: this.props.match.params.perspectiveId,
                      companyName: this.state.companyName
                    });
                  }}
                >
                  {Resources.RemapEmailToExistingCompany}
                </button>
              </div>
              <div className="my-2">
                <button
                  className="button-green company-profile-email-company-action-button"
                  onClick={() => {
                    this.props
                      .postCompanyIsSpam(this.props.match.params.companyId, this.props.companyId)
                      .then(() => {
                        this.props.showToast({
                          timeout: 8000,
                          text: Resources.CompanyMarkedAsSpam(this.state.companyName),
                          type: "green",
                          linkHidden: true
                        });
                        this.props.history.push(
                          this.props.location.pathname
                            .replace(this.props.match.params.selectedContextGroupId, "spam")
                            .replace(this.props.match.params.view, "activities")
                        );
                      })
                      .catch(error => {
                        return null;
                      });
                  }}
                >
                  {Resources.MarkSenderAsSpam}
                </button>
              </div>
            </div>
            <div className="company-profile-email-company-helper-text">
              {Resources.CreateVendorCustomerProfileHelperText(registeredERPConnector.displayName)}
            </div>
          </div>
        </div>
      );
    }
    return (
      <FlexibleScrollContainer>
        <CompanyProfileUI
          {...this.state}
          connectorName={registeredERPConnector.displayName}
          isMyCompany={this.props.isMyCompany}
        />
      </FlexibleScrollContainer>
    );
  }
}

const storeToProps = store => {
  return {
    companies: store.companies,
    conversationsStore: store.conversations,
    accountsStore: store.accounts,
    contextGroupsStore: store.contextGroups
  };
};

CompanyProfile.defaultProps = {
  readOnly: true,
  showConnectorLink: true,
  isSidePanel: false,
  actions: []
};

export default withRouter(
  connect(
    storeToProps,
    dispatchToProps
  )(CompanyProfile)
);
