import React, { Component } from "react";
import { connect } from "react-redux";

import { dispatchToProps as moDP } from "../store/modal-actions";
import { dispatchToProps as maDP } from "../store/manage-actions";

import Resources from "../lib/resources";
import Modal from "react-modal";
import FlexibleScrollContainer from "./flexibleScrollContainer";
import Checkbox from "./library/checkbox";
import { removeAt, getFileNameIconClasses } from "../lib/utils";

const dispatchToProps = dispatch => ({
  ...moDP(dispatch),
  ...maDP(dispatch)
});

class AttachFromLibraryModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      attachments: []
    };
  }

  render() {
    const { companyId, perspectiveId } = this.props;
    const companyDocuments = this.props.getCompanyDocuments(companyId, perspectiveId) || [];

    return (
      <Modal
        isOpen={this.props.modal.showModal}
        onRequestClose={() => this.props.hideModal()}
        className="custom-modal"
        overlayClassName="custom-modal-overlay"
      >
        <div className="custom-modal-header">{Resources.AttachFile}</div>
        <div className="custom-modal-divider" />
        <div className="custom-modal-content">
          <h5 className="mb-0">{Resources.SharedDocuments}</h5>
          {companyDocuments.length > 0 ? (
            <React.Fragment>
              <div className="font-med mb-2">{Resources.ChooseLibraryFiles}</div>
              <FlexibleScrollContainer maxHeight={`${Math.min(64 * companyDocuments.length, 280) + 20}px`}>
                {companyDocuments.map((doc, i) => {
                  let onChange;
                  const attachmentIndex = this.state.attachments.indexOf(doc);
                  const checked = attachmentIndex > -1;
                  if (checked) {
                    onChange = () =>
                      this.setState({
                        attachments: removeAt(this.state.attachments, attachmentIndex)
                      });
                  } else {
                    onChange = () =>
                      this.setState({
                        attachments: [...this.state.attachments, doc]
                      });
                  }
                  const rowContent = (
                    <div>
                      <span className={getFileNameIconClasses(doc.companyDocumentName) + " filetype-icon"} />
                      <div className="flex-column flex-center">
                        <span className="attachment-name">{doc.companyDocumentName}</span>
                        {doc.description && <span className="font-med">{doc.description}</span>}
                      </div>
                    </div>
                  );
                  return (
                    <div className="file-upload-item shared-document-selector" key={i}>
                      <Checkbox label={rowContent} checked={checked} onChange={onChange} />
                    </div>
                  );
                })}
              </FlexibleScrollContainer>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div className="mb-4 font-grey">{Resources.NoCompanyDocuments}</div>
              <h5 className="mb-2">{Resources.AttachFromComputer}</h5>
              <button
                onClick={() => {
                  window.document.getElementById("uploadFiles").click();
                }}
                className="button-green-secondary mb-5"
              >
                {Resources.SelectUploadFile}
              </button>
            </React.Fragment>
          )}
          <div className="flex-row modal-buttons">
            <button className="button-cancel float-left mr-1" onClick={this.props.hideModal}>
              {Resources.Cancel}
            </button>
            <button
              className="btn button-green"
              disabled={companyDocuments.length === 0}
              onClick={() => {
                this.props.updateAttachments(this.state.attachments);
                this.props.hideModal();
              }}
            >
              {Resources.Save}
            </button>
          </div>
        </div>
      </Modal>
    );
  }
}

const storeToProps = store => {
  return {
    modal: store.modal
  };
};

export default connect(
  storeToProps,
  dispatchToProps
)(AttachFromLibraryModal);
