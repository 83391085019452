import util from "util";
import data from "../lib/data";
import { handlePromiseError } from "./error-actions";
import moment from "moment";
import auth from "../lib/auth";

import { actions as GeneralActions } from "./general-actions";
import { isEmpty, coalesceUserName } from "../lib/utils";

export const actions = {
  ...GeneralActions,
  ...{
    FETCHING_REPORT_DATA: "FETCHING_REPORT_DATA",
    FETCHED_REPORT_DATA: "FETCHED_REPORT_DATA",
    FETCH_REPORT_DATA_FAILED: "FETCH_REPORT_DATA_FAILED",

    CHANGE_RANGE_TYPE: "CHANGE_RANGE_TYPE",

    FETCHING_REPORT_USERS: "FETCHING_REPORT_USERS",
    FETCHED_REPORT_USERS: "FETCHED_REPORT_USERS",
    FETCH_REPORT_USERS_FAILED: "FETCH_REPORT_USERS_FAILED"
  }
};

const fetchReportsData = (companyId, reportType, rangeType, fromDate, toDate) => (dispatch, getState) => {
  dispatch({ type: actions.FETCHING_REPORT_DATA, reportType });
  data
    .get(`v1/api/stats/${companyId}/${reportType}/from/${fromDate}/to/${toDate}`)
    .then(response => {
      dispatch({
        type: actions.FETCHED_REPORT_DATA,
        companyId,
        reportType,
        rangeType,
        reportData: response.data
      });
    })
    .catch(rejection => {
      dispatch({
        type: actions.FETCH_REPORT_DATA_FAILED,
        companyId,
        reportType,
        fromDate,
        toDate
      });
      let state = getState().reports;
      if (reportType === state.reportType && companyId === state.companyId) {
        handlePromiseError(
          rejection,
          "TODO: The request getting the report data has failed.  Please try again.",
          "report data"
        );
      }
    });
};

const getReportsData = reportType => (dispatch, getState) => {
  let state = getState().reports;
  let companyId = state.companyId;
  let reportTypeEndpoint = dispatch(getEndPointForReportType(reportType));
  let rangeType = state.rangeType;

  let fromAndToDate = dispatch(getTimesFromRangeType(rangeType));

  if (isEmpty(companyId)) {
    return;
  }
  dispatch(fetchReportsData(state.companyId, reportTypeEndpoint, rangeType, fromAndToDate[0], fromAndToDate[1]));
};

const refreshReportData = () => (dispatch, getState) => {
  dispatch(getReportsData(this.getState.reports.reportType));
};

const getEndPointForReportType = reportType => (dispatch, getState) => {
  switch (reportType) {
    case "team":
      return "team";
    case "approval-aging":
      return "aging";
    case "tasks":
      return "tasks";
    case "approvers":
      return "approvers";
    default:
      return "tasks";
  }
};

const getTimesFromRangeType = rangeType => (dispatch, getstate) => {
  let dateStart;
  let dateEnd;
  switch (rangeType) {
    case "lastWeek":
      dateStart = moment()
        .subtract(1, "week")
        .startOf("week");
      dateEnd = moment().startOf("week");
      break;
    case "thisWeek":
      dateStart = moment().startOf("week");
      dateEnd = moment()
        .add(1, "week")
        .startOf("week");
      break;
    case "lastMonth":
      dateStart = moment()
        .subtract(1, "month")
        .startOf("month");
      dateEnd = moment().startOf("month");
      break;
    case "thisMonth":
      dateStart = moment().startOf("month");
      dateEnd = moment()
        .add(1, "month")
        .startOf("month");
      break;
    case "lastQuarter":
      dateStart = moment()
        .subtract(1, "quarter")
        .startOf("quarter");
      dateEnd = moment().startOf("quarter");
      break;
    case "thisQuarter":
      dateStart = moment().startOf("quarter");
      dateEnd = moment()
        .add(1, "quarter")
        .startOf("quarter");
      break;
    default:
      dateStart = moment().startOf("week");
      dateEnd = moment()
        .add(1, "week")
        .startOf("week");
      break;
  }
  return [dateStart.format(), dateEnd.format()];
};

const changeRangeType = rangeType => (dispatch, getState) => {
  dispatch({ type: actions.CHANGE_RANGE_TYPE, rangeType });
};

const getReportUsers = guids => (dispatch, getState) => {
  dispatch({ type: actions.FETCHING_REPORT_USERS });
  if (isEmpty(guids)) {
    dispatch({
      type: actions.FETCHED_REPORT_USERS,
      reportUsers: {},
      reportUserIds: {}
    });
    return;
  }
  auth
    .post("v1/user/list", guids)
    .then(response => {
      if (util.isString(response.data)) dispatch({ type: actions.FETCH_REPORT_USERS_FAILED });
      else {
        let userIdMap = {};
        response.data.forEach(user => {
          userIdMap[user.userId] = user;
        });
        dispatch({
          type: actions.FETCHED_REPORT_USERS,
          reportUsers: response.data,
          reportUserIds: userIdMap
        });
      }
    })
    .catch(response => {
      dispatch({ type: actions.FETCH_REPORT_USERS_FAILED });
    });
};

const getReportUserDisplayName = userId => (dispatch, getState) => {
  if (getState().reports.fetchedReportUserIds === false || isEmpty(getState().reports.reportUsers)) {
    return "";
  }
  let userData = getState().reports.reportUserIds;

  return coalesceUserName(userData[userId]) || "SAML User";
};

export const dispatchToProps = dispatch => ({
  getReportsData: reportType => {
    return dispatch(getReportsData(reportType));
  },
  changeRangeType: rangeType => {
    return dispatch(changeRangeType(rangeType));
  },
  getReportUsers: guids => {
    return dispatch(getReportUsers(guids));
  },
  getReportUserDisplayName: userId => {
    return dispatch(getReportUserDisplayName(userId));
  },
  refreshReportData: () => {
    return dispatch(refreshReportData());
  }
});
