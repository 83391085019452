import React, { Component } from "react";
import { connect } from "react-redux";

import { dispatchToProps as uaDP } from "../store/user-actions";
import { dispatchToProps as aaDP } from "../store/accounts-actions";

import Resources from "../lib/resources";
import MenuItem from "./menuItem";
import Icons from "../lib/icons";

const dispatchToProps = dispatch => {
  return {
    ...aaDP(dispatch),
    ...uaDP(dispatch)
  };
};

class ManageNavContent extends Component {
  getRoute(view) {
    const { params, url } = this.props.match;
    return params.view ? url.replace(params.view || "", view) : `${url}/${view}`;
  }

  render() {
    const { view } = this.props.match.params;
    const isAdmin = this.props.isCompanyAdmin() !== false;

    return (
      <div>
        {/* <MenuItem
          to={this.getRoute("profile")}
          iconClasses={Icons.genericCompany}
          label={Resources.CompanyProfile}
          active={view === "profile"}
        /> */}
        <MenuItem
          to={this.getRoute("user-profile")}
          iconClasses={Icons.profile}
          label={Resources.MyProfile}
          active={view === "userProfile"}
        />
        <MenuItem
          to={this.getRoute("notifications")}
          iconClasses={Icons.notifications}
          label={Resources.Notifications}
          active={view === "notifications"}
        />
        <MenuItem
          to={this.getRoute("signature")}
          iconClasses={Icons.signature}
          label={Resources.Signature}
          active={view === "signature"}
        />
        {isAdmin && (
          <MenuItem
            to={this.getRoute("workspaces")}
            iconClasses={Icons.workspaces}
            label={Resources.CompanyWorkspaceSettings}
            active={view === "workspaces"}
          />
        )}
        {false && isAdmin && (
          <MenuItem
            to={this.getRoute("packages")}
            iconClasses={Icons.packages}
            label={Resources.CompanyAccountingPackages}
            active={view === "packages"}
          />
        )}
        <MenuItem
          to={this.getRoute("users")}
          iconClasses={Icons.usersAndPermissions}
          label={Resources.CompanyUsersAndPermissions}
          active={view === "users"}
        />
        <MenuItem
          to={this.getRoute("approvers")}
          iconClasses={Icons.approvers}
          label={Resources.Approvers}
          active={view === "approvers"}
        />
        {/* <MenuItem
          to={this.getRoute("workflows")}
          iconClasses={Icons.workflows}
          label={Resources.Workflows}
          active={view === "workflows"}
        /> */}
        <MenuItem
          to={this.getRoute("templates")}
          iconClasses={Icons.template}
          label={Resources.Templates}
          active={view === "templates"}
        />
        {/* <MenuItem
          to={this.getRoute("communication-templates")}
          iconClasses={Icons.template}
          label={Resources.CommunicationTemplates}
          active={view === "communication-templates"}
        /> */}
        <MenuItem
          to={this.getRoute("shared-documents")}
          iconClasses={Icons.document}
          label={Resources.SharedDocuments}
          active={view === "shared-documents"}
        />
        {/* <MenuItem
          to={this.getRoute("contacts")}
          iconClasses={Icons.contacts}
          label={Resources.Contacts}
          active={view === "shared-documents"}
        /> */}
      </div>
    );
  }
}

const storeToProps = store => {
  return {
    accountsStore: store.accounts
  };
};

export default connect(storeToProps, dispatchToProps)(ManageNavContent);
