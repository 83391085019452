import { Component } from "react";
import { connect } from "react-redux";

class FeatureGate extends Component {
  render() {
    const { selectedAccount, gatedFeature, children } = this.props;

    const featureMap = selectedAccount.features || {};

    if (featureMap[gatedFeature] === true || featureMap[gatedFeature] === 1) {
      return children;
    }

    return null;
  }
}

const storeToProps = store => {
  return {
    selectedAccount: store.accounts.selectedAccount
  };
};

export default connect(storeToProps, null)(FeatureGate);
