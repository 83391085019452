import React, { Component } from "react";
import { connect } from "react-redux";

import ReportsFilter from "./reportsFilter";
import { dispatchToProps as raDP } from "../store/reports-actions";
import { dispatchToProps as aaDP } from "../store/accounts-actions";
import MainContentHeader from "./main_content_header/mainContentHeader";
import MainLoader from "./mainLoader";
import {
  isEmpty,
  clone,
  ticksToReadableTime,
  coalesceUserName,
  getReportsTicks,
  millisecondsToReadableTimeHardCutoff
} from "../lib/utils";
import ReportContentBlock from "./reportsContentBlock";
import TabularData from "./library/tabularData";
import Resources from "../lib/resources";
import { BarChart, YAxis, XAxis, Bar, Tooltip, ResponsiveContainer, CartesianGrid } from "recharts";
import Icons from "../lib/icons";
import ReportsExportButton from "./reportsExportButton";
import ReportsNoData from "./reportsNoData";

const dispatchToProps = dispatch => ({
  ...aaDP(dispatch),
  ...raDP(dispatch)
});

class ReportApprovers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      numberOfEntries: 30,
      graphShown: "averageApprovalTime"
    };

    this.loadMore = this.loadMore.bind(this);
  }

  componentDidMount() {
    this.tryUpdate();
  }

  componentDidUpdate(prevProps) {
    this.tryUpdate(prevProps);
  }

  tryUpdate(prevProps) {
    if (
      isEmpty(prevProps) ||
      this.props.reports.companyId !== prevProps.reports.companyId ||
      this.props.reports.rangeType !== prevProps.reports.rangeType
    ) {
      this.props.getReportsData("approvers");
    }
    if (
      isEmpty(prevProps) === false &&
      this.props.reports.fetchedReport === true &&
      prevProps.reports.fetchedReport === false
    ) {
      let guidsArray =
        this.props.reports.reportData.approvers.map(approver => {
          return approver.approverId;
        }) || [];
      this.props.getReportUsers(guidsArray);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.props.reports.companyId !== nextProps.reports.companyId ||
      this.props.reports.rangeType !== nextProps.reports.rangeType ||
      this.props.reports.reportType !== nextProps.reports.reportType ||
      this.props.reports.reportData !== nextProps.reports.reportData ||
      this.state.graphShown !== nextState.graphShown ||
      this.props.reports.reportUserIds !== nextProps.reports.reportUserIds
    ) {
      return true;
    }
    return false;
  }

  loadMore() {
    if (this.state.numberOfEntries < this.props.reports.reportData.approvers.length) {
      this.setState({ numberOfEntries: this.state.numberOfEntries + 10 });
    }
  }

  approvalColumns(isAdmin) {
    let approvalColumns = [
      {
        header: "",
        key: "",
        style: {
          width: "2%"
        }
      },
      {
        header: Resources.Approver,
        key: "displayName",
        style: {
          width: "13%"
        }
      },
      {
        header: Resources.Requests,
        key: "requests",
        style: {
          width: "10%"
        }
      },
      {
        header: Resources.Approved,
        key: "approved",
        style: {
          width: "10%"
        }
      },
      {
        header: Resources.Rejected,
        key: "rejected",
        style: {
          width: "10%"
        }
      },
      {
        header: Resources.Outstanding,
        key: "outstanding",
        style: {
          width: "10%"
        }
      },
      {
        header: Resources.AvgApprovalTime,
        key: "averageApprovalTimeDisplay",
        style: {
          width: "13%"
        }
      },
      {
        header: Resources.MaxApprovalTime,
        key: "maxApprovalTimeDisplay",
        style: {
          width: "15%"
        }
      },
      {
        header: Resources.MaxOutstandingTime,
        key: "maxOutstandingTimeDisplay",
        style: {
          width: "15%"
        }
      },
      {
        header: "",
        key: "",
        style: {
          width: "2%"
        }
      }
    ];

    return approvalColumns;
  }

  prettyApprovers(approvers) {
    let approversList = this.props.getCompanyApprovers(this.props.reports.companyId) || [];
    let approversFlattenedMap = {};
    let perspectives = Object.keys(approversList);

    perspectives.forEach(key => {
      approversList[key].forEach(approver => {
        approversFlattenedMap[approver.companyContactId] = approver;
      });
    });

    if (!isEmpty(approvers)) {
      return approvers.map(approver => {
        let a = clone(approver);
        a.averageApprovalTimeDisplay = ticksToReadableTime(approver.averageApprovalTime);
        a.maxApprovalTimeDisplay = ticksToReadableTime(approver.maxApprovalTime);
        a.maxOutstandingTimeDisplay = ticksToReadableTime(approver.maxOutstandingTime);
        a.displayName = coalesceUserName(approversFlattenedMap[approver.approverId]);
        return a;
      });
    }
    return approvers;
  }

  YAxisTickFormatter(tick, data) {
    if (data.length > 6 && tick.length > 7) {
      return tick.substr(0, tick.indexOf(" ")) + "...";
    }
    if (tick.length > 12) {
      let min;
      min = tick.indexOf(" ") > 10 || tick.indexOf(" ") === -1 ? 10 : tick.indexOf(" ");
      return tick.substr(0, min) + "...";
    }
    return tick;
  }
  render() {
    let { fetchingReport, reportData } = this.props.reports;
    let graphTitle;
    let graphSelector;
    let graphTooltipName;

    switch (this.state.graphShown) {
      case "averageApprovalTime":
        graphTitle = Resources.AverageApprovalTimeByApprover;
        graphSelector = "averageApprovalTime";
        graphTooltipName = "Average Approval Time";
        break;
      case "maxApprovalTime":
        graphTitle = Resources.MaxApprovalTimeByApprover;
        graphSelector = "maxApprovalTime";
        graphTooltipName = "Max Approval Time";
        break;
      case "numberOfRequests":
        graphTitle = Resources.NumberOfRequestsByApprover;
        graphSelector = "requests";
        graphTooltipName = "Number of Requests";
        break;
      default:
        graphTitle = Resources.AverageApprovalTimeByApprover;
        graphSelector = "averageApprovalTime";
        graphTooltipName = "Average Approval Time";
        break;
    }

    let approvers = this.prettyApprovers(reportData.approvers || []);
    let approvalTimeByApproversSorted = JSON.parse(JSON.stringify(approvers));
    approvalTimeByApproversSorted = approvalTimeByApproversSorted.sort(function(a, b) {
      return a[graphSelector] - b[graphSelector];
    });

    let xAxisTicks = !isEmpty(approvalTimeByApproversSorted)
      ? getReportsTicks(approvalTimeByApproversSorted.map(member => member[graphSelector]))
      : [0];

    return (
      <React.Fragment>
        <MainContentHeader title={Resources.ApproversReport} hideSearch={true} />
        {fetchingReport ? (
          <MainLoader fullScreen={true} />
        ) : (
          <div className="reports">
            <ReportsFilter />
            <div className="reports-content-blocks">
              <div className="row">
                <ReportContentBlock
                  heading={Resources.ApprovalRequests}
                  data={reportData.approvalRequests}
                  backgroundColor="#F7831B"
                />
                <ReportContentBlock heading={Resources.Approved} data={reportData.approved} backgroundColor="#E76D01" />
                <ReportContentBlock heading={Resources.Rejected} data={reportData.rejected} backgroundColor="#EB5757" />
                <ReportContentBlock
                  heading={Resources.Outstanding}
                  data={reportData.outstanding}
                  backgroundColor="#676C89"
                />
                <ReportContentBlock
                  heading={Resources.AvgApprovalTime}
                  data={ticksToReadableTime(reportData.averageApprovalTime)}
                  backgroundColor="#4B4E61"
                />
                <ReportContentBlock
                  heading={Resources.MaxApprovalTime}
                  data={ticksToReadableTime(reportData.maxApprovalTime)}
                  backgroundColor="#31333F"
                />
              </div>
            </div>
            <div className="reports-content-blocks" />
            <div className="reports-chart-area">
              <div id="approvers-dropdown-selector" className="menu-toggle dropdown">
                <span
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  className="reports-charts-title"
                >
                  {graphTitle}
                  <span className={Icons.dropdown + " pt-1 pl-3"} />
                </span>
                <span id="approvers-dropdown-menu" className="dropdown-menu">
                  <span
                    className="dropdown-item reports-charts-title-selector"
                    onClick={() => this.setState({ graphShown: "numberOfRequests" })}
                  >
                    {Resources.NumberOfRequestsByApprover}
                  </span>
                  <span
                    className="dropdown-item reports-charts-title-selector"
                    onClick={() => this.setState({ graphShown: "averageApprovalTime" })}
                  >
                    {Resources.AverageApprovalTimeByApprover}
                  </span>
                  <span
                    className="dropdown-item reports-charts-title-selector bottom"
                    onClick={() => this.setState({ graphShown: "maxApprovalTime" })}
                  >
                    {Resources.MaxApprovalTimeByApprover}
                  </span>
                </span>
              </div>

              <ResponsiveContainer>
                <BarChart
                  data={approvalTimeByApproversSorted}
                  layout="vertical"
                  isAnimationActive={true}
                  AnimationDuration={100000}
                  margin={{ top: 10, right: 20, bottom: 10, left: 40 }}
                >
                  <CartesianGrid horizontal={false} />
                  {graphSelector !== "requests" ? (
                    <XAxis
                      type="number"
                      axisLine={false}
                      tickLine={false}
                      tickFormatter={tick =>
                        this.state.graphShown !== "numberOfRequests" && tick !== 0
                          ? millisecondsToReadableTimeHardCutoff(tick / 10000, true)
                          : tick
                      }
                      ticks={xAxisTicks}
                    />
                  ) : (
                    <XAxis
                      type="number"
                      axisLine={false}
                      tickLine={false}
                      tickFormatter={tick =>
                        this.state.graphShown !== "numberOfRequests" && tick !== 0
                          ? millisecondsToReadableTimeHardCutoff(tick / 10000, true)
                          : tick
                      }
                    />
                  )}

                  <YAxis
                    dataKey="displayName"
                    type="category"
                    tickLine={false}
                    tickFormatter={tick => this.YAxisTickFormatter(tick, approvalTimeByApproversSorted.length)}
                  />
                  <Bar name={graphTooltipName} dataKey={graphSelector} label="true" fill="#FFC780" />
                  <Tooltip
                    formatter={(value, name, props) =>
                      this.state.graphShown !== "numberOfRequests" ? ticksToReadableTime(value) : value
                    }
                  />
                </BarChart>
              </ResponsiveContainer>
            </div>
            <ReportsExportButton />
            <div className="table-tasks-report">
              {isEmpty(approvers) ? (
                <ReportsNoData height="12vw" reportType="approvers" />
              ) : (
                <TabularData
                  selectable={false}
                  onLoadMore={this.loadMore}
                  maxRows={approvers.length}
                  rowClassName="testing-reports-table"
                  rowStyle={{ cursor: "default" }}
                  data={approvers.slice(0, this.state.numberOfEntries)}
                  format={{
                    callBack: row => {},
                    selectAll: (e, rows) => {},
                    selected: (e, row) => {},
                    columns: this.approvalColumns()
                  }}
                  //maxHeight="40vh"
                />
              )}
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

const storeToProps = store => {
  return {
    reports: store.reports
  };
};

export default connect(
  storeToProps,
  dispatchToProps
)(ReportApprovers);
