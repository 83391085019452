import React, { Component } from "react";
import { connect } from "react-redux";
import Resources from "../lib/resources";
import { isEmpty, find } from "../lib/utils";
import Icons from "../lib/icons";
import SelectInput from "./library/selectInput";

import { dispatchToProps as compDP } from "../store/company-actions";
import { dispatchToProps as mangDP } from "../store/manage-actions";
import { dispatchToProps as regDP } from "../store/registration-actions";
import { dispatchToProps as uaDP } from "../store/user-actions";
import { dispatchToProps as paDP } from "../store/perspectives-actions";
import { dispatchToProps as conDP } from "../store/connector-actions";
import { dispatchToProps as modDP } from "../store/modal-actions";

import TextInput from "./library/textInput";
import ForwardingEmailSettings from "./forwardingEmailSettings";
import AutoCompleteDropdown from "./library/autoCompleteDropdown";

const dispatchToProps = dispatch => ({
  ...compDP(dispatch),
  ...mangDP(dispatch),
  ...regDP(dispatch),
  ...uaDP(dispatch),
  ...paDP(dispatch),
  ...conDP(dispatch),
  ...modDP(dispatch)
});

class IncomingSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editingImap: false,
      hasImapConnector: false,
      imapConfigStatus: null,
      host: "",
      port: 993,
      username: "",
      password: "",
      confirmPassword: "",
      putConfig: false,
      userDomain: "",
      inboxFolder: "",
      sentItemsFolder: "",
      connectionSecurity: "",
      authenticationMethod: "",
      connectionTimeout: null,
      retrievalTimeout: null,
      sslProtocol: "",

      selectedIncomingOption: null
    };
    this.putConnectorConfig = this.putConnectorConfig.bind(this);
    this.inputRef = React.createRef();

    this.syncImapStoreToState = this.syncImapStoreToState.bind(this);
    this.getImapContent = this.getImapContent.bind(this);
    this.getSyntheticContent = this.getSyntheticContent.bind(this);
    this.getImapCompanyPermissions = this.getImapCompanyPermissions.bind(this);
    this.deactivate = this.deactivate.bind(this);
  }

  defaultState = {
    editingImap: false,
    hasImapConnector: false,
    hasGmailConnector: false,
    imapConfigStatus: null,
    gmailConfigStatus: null,
    host: "",
    port: 993,
    username: "",
    password: "",
    confirmPassword: "",
    daysPrior: 0,
    deleteAfterImport: false,
    putConfig: false,
    userDomain: "",
    inboxFolder: "",
    sentItemsFolder: "",
    connectionSecurity: "",
    authenticationMethod: "",
    connectionTimeout: null,
    retrievalTimeout: null,
    sslProtocol: "",

    selectedIncomingOption: null
  };

  componentDidMount() {
    let companyId = this.props.company.companyId;

    let emailConnector =
      find(this.props.allSupportedConnectors, connector => connector.connectorName === "Email") || {};

    let emailConnectorVersion = this.props.connector.connectorVersionData[emailConnector.connectorId] || [];
    let emailConnectorVersionId = (emailConnectorVersion[0] || {}).connectorVersionId;

    this.props.fetchConnectorConfiguration(companyId, emailConnectorVersionId);
    this.syncImapStoreToState();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.isCompanyAdmin() !== true) {
      return;
    }
    let {
      connector: { isFetchingConnectorConfiguration }
    } = this.props;

    if (prevProps.perspective !== this.props.perspective) {
      this.setState({ selectedIncomingOption: null });
      this.syncImapStoreToState();
    }

    if (
      (isFetchingConnectorConfiguration === false && prevProps.connector.isFetchingConnectorConfiguration === true) ||
      prevProps.connector.connectorConfigs !== this.props.connector.connectorConfigs
    ) {
      this.syncImapStoreToState();
    }
  }

  getConnectorConfigIMAP() {
    return {
      host: this.state.host || "",
      port: this.state.port || 993,
      username: this.state.username || "",
      password: this.state.password || "",
      perspectiveId: this.props.perspective.perspectiveId,
      domain: this.state.userDomain,
      inbox: this.state.inboxFolder,
      sentMailBox: this.state.sentItemsFolder,
      useSSL: this.state.connectionSecurity === "ssl",
      useStartTLS: this.state.connectionSecurity === "starttls",
      authenticationMethod: this.state.authenticationMethod,
      connectionTimeout: this.state.connectionTimeout,
      retrievalTimeout: this.state.retrievalTimeout,
      sslMethod: this.state.sslProtocol
    };
  }

  putConnectorConfig() {
    let companyId = this.props.company.companyId;
    let perspectiveId = (this.props.perspective || {}).perspectiveId;

    let emailConnector = find(this.props.allSupportedConnectors, connector => connector.connectorName === "Email");

    let emailConnectorVersion = this.props.connector.connectorVersionData[emailConnector.connectorId];
    let emailConnectorVersionId = emailConnectorVersion[0].connectorVersionId;
    let emailConnectorConfigs = (this.props.connector.connectorConfigs[emailConnectorVersionId] || {})
      .connectorConfiguration;

    emailConnectorConfigs = (emailConnectorConfigs || [])
      .filter(config => config.perspectiveId !== perspectiveId)
      .map(config => {
        return {
          ...config,
          port: config.port || 993,
          password: config.password || "",
          host: config.host || "",
          username: config.username || ""
        };
      });

    return this.props
      .putConnectorConfiguration(
        companyId,
        this.props.connector.connectorVersionData[emailConnector.connectorId][0].connectorVersionId,
        [...emailConnectorConfigs, this.getConnectorConfigIMAP()]
      )
      .then(response => {
        this.props.fetchConnectorConfiguration(companyId, emailConnectorVersionId);
        this.setState({ editingImap: false, putConfig: true });
      })
      .catch(error => null);
  }

  getImapCompanyPermissions() {
    let emailConnector = find(this.props.allSupportedConnectors, connector => connector.connectorName === "Email");
    let emailResource = find(this.props.ledgerResources, resource => resource.resourceName === "Email");
    let connectorConfigResource = find(
      this.props.ledgerResources,
      resource => resource.resourceName === "ConnectorConfigs"
    );

    return [
      {
        resourceId: emailResource.resourceId,
        actionId: find(emailResource.actions, action => action.actionName === "Create").actionId,
        permittedCompanyId: emailConnector.companyId
      },
      {
        resourceId: emailResource.resourceId,
        actionId: find(emailResource.actions, action => action.actionName === "Read").actionId,
        permittedCompanyId: emailConnector.companyId
      },
      {
        resourceId: connectorConfigResource.resourceId,
        actionId: find(connectorConfigResource.actions, action => action.actionName === "Create").actionId,
        permittedCompanyId: emailConnector.companyId
      },
      {
        resourceId: connectorConfigResource.resourceId,
        actionId: find(connectorConfigResource.actions, action => action.actionName === "Read").actionId,
        permittedCompanyId: emailConnector.companyId
      },
      {
        resourceId: connectorConfigResource.resourceId,
        actionId: find(connectorConfigResource.actions, action => action.actionName === "Update").actionId,
        permittedCompanyId: emailConnector.companyId
      },
      {
        resourceId: connectorConfigResource.resourceId,
        actionId: find(connectorConfigResource.actions, action => action.actionName === "Delete").actionId,
        permittedCompanyId: emailConnector.companyId
      }
    ];
  }

  syncImapStoreToState() {
    if (isEmpty(this.props.perspective) || isEmpty(this.props.connector.connectorConfigs)) {
      return null;
    }

    let imapConnector =
      find(this.props.connector.allSupportedConnectors, connector => connector.connectorName === "Email") || {};

    let perspectiveId = this.props.perspective.perspectiveId;
    let companyId = this.props.company.companyId;
    let imapConnectorVersion = this.props.connector.connectorVersionData[imapConnector.connectorId];

    if (isEmpty(imapConnectorVersion)) {
      return null;
    }

    let imapConnectorVersionId = imapConnectorVersion[0].connectorVersionId;
    let imapConnectorConfigStatus = (this.props.connector.connectorConfigs[imapConnectorVersionId] || {}).configStatus;

    let imapConnectorConfigs =
      (this.props.connector.connectorConfigs[imapConnectorVersionId] || {}).connectorConfiguration || [];

    let imapConfigHasError = imapConnectorConfigs.some(config => !isEmpty(config.errors));

    let imapPerspectiveConnectorConfig =
      find(imapConnectorConfigs, connectorConfig => connectorConfig.perspectiveId === perspectiveId) || {};

    if (imapConnectorConfigStatus === "Syncing") {
      clearInterval(window.pollImapConfig);
      window.pollImapConfig = window.setInterval(
        () => this.props.fetchConnectorConfiguration(companyId, imapConnectorVersionId),
        5000
      );
    } else {
      clearInterval(window.pollImapConfig);
    }

    if (!isEmpty(imapPerspectiveConnectorConfig)) {
      if (!isEmpty(imapPerspectiveConnectorConfig)) {
        this.setState({
          hasImapConnector: true,
          editingImap: false,
          imapConfigStatus: imapConnectorConfigStatus,
          host: imapPerspectiveConnectorConfig.host,
          username: imapPerspectiveConnectorConfig.username,
          password: imapPerspectiveConnectorConfig.password,
          confirmPassword: imapPerspectiveConnectorConfig.password,
          port: imapPerspectiveConnectorConfig.port,
          connectionSecurity: imapPerspectiveConnectorConfig.useSSL
            ? "ssl"
            : imapPerspectiveConnectorConfig.useStartTLS
            ? "starttls"
            : "",
          sslProtocol: imapPerspectiveConnectorConfig.sslMethod,
          sentItemsFolder: imapPerspectiveConnectorConfig.sentMailBox,
          inboxFolder: imapPerspectiveConnectorConfig.inbox,
          userDomain: imapPerspectiveConnectorConfig.domain,
          authenticationMethod: imapPerspectiveConnectorConfig.authenticationMethod,
          connectionTimeout: imapPerspectiveConnectorConfig.connectionTimeout,
          retrievalTimeout: imapPerspectiveConnectorConfig.retrievalTimeout
        });
      } else {
        this.setState({
          hasImapConnector: false,
          editingImap: false,
          imapConfigStatus: null,
          host: null,
          username: null,
          password: null,
          port: null,
          deleteAfterImport: null,
          daysPrior: null,
          imapConfigHasError: null,
          imapErrors: null
        });
      }
    } else {
      this.setState({
        ...this.defaultState
      });
    }
  }

  deactivate(connectorName) {
    let companyId = this.props.company.companyId;
    let perspectiveId = (this.props.perspective || {}).perspectiveId;
    let connector = find(this.props.allSupportedConnectors, connector => connector.connectorName === connectorName);

    this.setState({ editingImap: false });
    let connectorVersionId = this.props.connector.connectorVersionData[(connector || {}).connectorId][0]
      .connectorVersionId;
    this.props
      .updateConnectorConfiguration(
        companyId,
        connectorVersionId,
        this.props.connector.connectorConfigs[connectorVersionId].connectorConfiguration.filter(
          connectorConfig => connectorConfig.perspectiveId !== perspectiveId
        )
      )
      .then(response => this.props.fetchCompanyConnectors(companyId));
  }

  getImapContent() {
    let imapConfigStatus;

    switch (this.state.imapConfigStatus) {
      case "Complete":
        imapConfigStatus = <span className="connector-badge badge-active">{Resources.Active}</span>;
        break;
      case "Registration":
        if (this.state.imapConfigHasError) {
          if (!isEmpty(this.state.imapErrors)) {
            imapConfigStatus = (
              <React.Fragment>
                <div className="connector-badge badge-error d-inline-block text-center">
                  {Resources.Error}:{" "}
                  {this.state.imapErrors.map((error, i) => {
                    if (i + 1 === this.state.imapErrors.length) {
                      return error;
                    } else {
                      return error + ", ";
                    }
                  })}
                </div>
              </React.Fragment>
            );
          } else {
            imapConfigStatus = <span className="connector-badge badge-active">{Resources.Active}</span>;
          }
        } else {
          imapConfigStatus = <span className="pl-2 unverified-color-font">{Resources.Registering}</span>;
        }
        break;
      case "Syncing":
        imapConfigStatus = <span className="connector-badge badge-syncing">{Resources.Syncing}</span>;
        break;
      default:
        imapConfigStatus = (
          <div className="connector-badge badge-error d-inline-block text-center">{Resources.Inactive}</div>
        );
        break;
    }

    const connectionSecurity = [
      { value: "ssl", test: "SSL" },
      { value: "starttls", test: "StartTLS" },
      { value: "none", test: "None (not recommended)" }
    ];

    const authenticationMethods = [
      { value: "NONE" },
      { value: "CRAM-MDS" },
      { value: "NTLM" },
      { value: "PLAIN" },
      { value: "LOGIN" }
    ];

    const sslProtocols = [{ value: "NONE" }, { value: "TLS 1.0" }, { value: "SSL 3.0" }, { value: "PCT 1.0" }];

    return (
      <React.Fragment>
        <div>
          <div className="mb-3">{Resources.IMAPMethod}</div>
          <br />
          <div className="mb-3 input-label">{Resources.RequiredFields}</div>
          <br />
          <TextInput
            fsObscure
            className="mb-4"
            labelInline={false}
            inline
            /*disabled={puttingConnectorConfig}*/
            width="19em"
            textValue={this.state.host}
            label={Resources.ServerName}
            placeholder={Resources.HostAddressExample}
            id="perspective-imap-host"
            inputOnChange={e => {
              this.setState({ host: e.target.value });
            }}
          />{" "}
          <br />
          <TextInput
            fsObscure
            labelInline={false}
            className="mb-4"
            inline
            /*disabled={puttingConnectorConfig}*/
            textValue={this.state.port}
            label={Resources.Port}
            placeholder={Resources.Port}
            id="perspective-imap-port"
            width="19em"
            inputOnChange={e => {
              this.setState({ port: parseInt(e.target.value, 10) || 0 });
            }}
            isNumber
          />{" "}
          <br />
          <TextInput
            className="mb-4"
            /*disabled={puttingConnectorConfig}*/
            width="19em"
            inline
            labelInline={false}
            textValue={this.state.username}
            label={Resources.Username}
            autocomplete={false}
            placeholder={Resources.Username}
            id="perspective-imap-username"
            inputOnChange={e => {
              this.setState({ username: e.target.value });
            }}
          />{" "}
          <br />
          <TextInput
            fsObscure
            inline
            labelInline={false}
            isPassword={true}
            className="mb-4"
            /*disabled={puttingConnectorConfig}*/
            width="19em"
            autocomplete={false}
            textValue={this.state.password}
            label={Resources.Password}
            placeholder={Resources.Password}
            id="perspective-imap-password"
            inputOnChange={e => {
              this.setState({ password: e.target.value });
            }}
            style={{ marginRight: "2.5rem" }}
          />
          <TextInput
            fsObscure
            inline
            labelInline={false}
            isPassword={true}
            className="mb-4"
            /*disabled={puttingConnectorConfig}*/
            width="19em"
            textValue={this.state.confirmPassword}
            label={Resources.ConfirmPassword}
            placeholder={Resources.ConfirmPassword}
            id="perspective-imap-confirmPassword"
            inputOnChange={e => {
              this.setState({ confirmPassword: e.target.value });
            }}
          />
          <br />
          <div className="d-flex">
            <SelectInput
              className="mb-4"
              value={this.state.connectionSecurity}
              displayValue={
                (find(connectionSecurity, option => option.value === this.state.connectionSecurity) || {}).test
              }
              optionValueKey="value"
              optionDisplayKey="test"
              options={connectionSecurity}
              labelInline={false}
              label={Resources.ConnectionSecurity}
              width={"19rem"}
              onSelectOption={option => {
                this.setState({ connectionSecurity: option.value });
              }}
              style={{ marginRight: "2.5rem", display: "inline-block" }}
            />
            {this.state.connectionSecurity === "ssl" && (
              <SelectInput
                className="mb-4"
                value={this.state.sslProtocol}
                displayValue={
                  (find(sslProtocols, option => option.value === this.state.connectionSecurity) || {}).value
                }
                optionValueKey="value"
                optionDisplayKey="test"
                options={sslProtocols}
                labelInline={false}
                label={Resources.SSLProtocol}
                id="perspective-imap-sslprotocol"
                width={"19rem"}
                onSelectOption={option => {
                  this.setState({ sslProtocol: option.value });
                }}
                style={{ display: "inline-block" }}
              />
            )}
          </div>
          <div className="nav-tabs mb-4"></div>
          <div className="input-label mb-4">{Resources.OptionalFields}</div>
          <TextInput
            className="mb-4"
            labelInline={false}
            inline
            /*disabled={puttingConnectorConfig}*/
            width="19em"
            textValue={this.state.userDomain}
            label={Resources.UserDomain}
            placeholder={Resources.UserDomain}
            id="perspective-imap-userDomain"
            inputOnChange={e => {
              this.setState({ userDomain: e.target.value });
            }}
          />{" "}
          <br />
          <TextInput
            labelInline={false}
            className="mb-4"
            inline
            /*disabled={puttingConnectorConfig}*/
            textValue={this.state.inboxFolder}
            label={Resources.InboxFolder}
            placeholder={Resources.InboxFolder}
            id="perspective-imap-inboxFolder"
            width="19em"
            inputOnChange={e => {
              this.setState({ inboxFolder: e.target.value });
            }}
            style={{ marginRight: "2.5rem" }}
          />
          <TextInput
            className="mb-4"
            /*disabled={puttingConnectorConfig}*/
            width="19em"
            inline
            labelInline={false}
            textValue={this.state.sentItemsFolder}
            label={Resources.SentItemsFolder}
            placeholder={Resources.SentItemsFolder}
            id="perspective-imap-sentItemsFolder"
            inputOnChange={e => {
              this.setState({ sentItemsFolder: e.target.value });
            }}
          />{" "}
          <br />
          <SelectInput
            className="mb-4"
            displayValue={
              (find(authenticationMethods, option => option.value === this.state.authenticationMethod) || {}).value
            }
            optionValueKey="value"
            options={authenticationMethods}
            labelInline={false}
            label={Resources.AuthenticationMethod}
            width={"19.2rem"}
            onSelectOption={option => {
              this.setState({ authenticationMethod: option.value });
            }}
          />
          <br />
          <TextInput
            fsObscure
            inline
            labelInline={false}
            className="mb-4"
            /*disabled={puttingConnectorConfig}*/
            width="19em"
            textValue={this.state.connectionTimeout}
            label={Resources.ConnectionTimeout}
            placeholder={Resources.ConnectionTimeout}
            id="perspective-imap-connectionTimeout"
            inputOnChange={e => {
              this.setState({ connectionTimeout: e.target.value });
            }}
            style={{ marginRight: "2.5rem" }}
            isNumber
          />
          <TextInput
            fsObscure
            inline
            labelInline={false}
            className="mb-4"
            /*disabled={puttingConnectorConfig}*/
            width="19em"
            textValue={this.state.retrievalTimeout}
            label={Resources.RetrievalTimeout}
            placeholder={Resources.RetrievalTimeout}
            id="perspective-imap-retrievalTimeout"
            inputOnChange={e => {
              this.setState({ retrievalTimeout: e.target.value });
            }}
            isNumber
          />
          <div className="mt-2">
            <button
              className="button-secondary-square"
              style={{ minWidth: "14rem", marginRight: "1.3rem" }}
              onClick={this.props.onCancel}
            >
              {Resources.Cancel}
            </button>
            <button
              className="button-primary-square"
              style={{ minWidth: "14rem" }}
              disabled={
                isEmpty(this.state.host) ||
                isEmpty(this.state.port) ||
                isEmpty(this.state.username) ||
                isEmpty(this.state.password) ||
                isEmpty(this.state.connectionSecurity) ||
                this.state.password !== this.state.confirmPassword
              }
              onClick={e => {
                this.putConnectorConfig();
                this.props.onSave();
              }}
            >
              {Resources.Save}
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }

  getSyntheticContent() {
    let perspective = this.props.perspective;
    let companyId = this.props.company.companyId;
    let companyInfo = this.props.getCompanyInfo(companyId);

    return (
      <ForwardingEmailSettings
        perspective={perspective}
        companyInfo={companyInfo}
        companyId={companyId}
        showOnlySynthetic
      />
    );
  }

  getSelectedMailOptionRender(optionType) {
    switch (optionType) {
      case "imap":
        return this.getImapContent();
      case "gmail":
        return this.getGmailContent();
      default:
        return this.getSyntheticContent();
    }
  }

  getIncomingButton() {
    if (((this.state.selectedIncomingOption || {}).type || this.props.incomingEmail.type) === "imap") {
      return (
        <button
          className="button-primary-square"
          style={{ float: "right" }}
          onClick={() =>
            this.props.displayModal("testEmailConfigModal", {
              companyId: this.props.company.companyId,
              accountingGroupId: this.props.perspective.perspectiveId,
              accountingGroupName: this.props.perspective.perspectiveName
            })
          }
        >
          {Resources.TestIncomingEmail}
        </button>
      );
    }
  }

  render() {
    let incomingOptions = this.props.incomingOptions;

    return (
      <div>
        <div className="card-attribute-column border-none">
          <div className="input-label">{Resources.DeliveryPreference}</div>
          <div className="card-attribute-content mb-4">
            <AutoCompleteDropdown
              width="350px"
              selected={this.state.selectedIncomingOption || this.props.incomingEmail}
              options={incomingOptions}
              displayKey="displayName"
              placeholder={Resources.LoadingOptions}
              handleSelectOption={option => {
                this.setState({
                  selectedIncomingOption: option
                });
              }}
              icon={Icons.angleDown + " solid"}
              readOnly
            />
            {this.getIncomingButton()}
          </div>
        </div>
        <div className="nav-tabs mb-4"></div>
        <div className="px-3 email-settings-row row">
          {this.getSelectedMailOptionRender(
            (this.state.selectedIncomingOption || {}).type || this.props.incomingEmail.type
          )}
        </div>
      </div>
    );
  }
}

const storeToProps = store => {
  return {
    ledgerResources: store.ledger.resources,
    connector: store.connector,
    allSupportedConnectors: store.connector.allSupportedConnectors
  };
};

export default connect(storeToProps, dispatchToProps)(IncomingSettings);
