import React, { Component } from "react";

class IconFilter extends Component {
  render() {
    let { className, height } = this.props;

    return (
      <svg viewBox="0 0 22 20" height={height} className={`icon ${className ? className : ""}`}>
        <g fill="none" fillRule="evenodd">
          <path
            d="M21 1H1l8 9.46V17l4 2v-8.54z"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
        </g>
      </svg>
    );
  }
}

IconFilter.defaultProps = {
  height: 20
};

export default IconFilter;
