import React, { Component } from "react";
import { connect } from "react-redux";

import { dispatchToProps as aaDP } from "../store/accounts-actions";
import { dispatchToProps as paDP } from "../store/perspectives-actions";
import { dispatchToProps as eaDP } from "../store/error-actions";

import Resources from "../lib/resources";
import { isEmpty, isEmailValid, find } from "../lib/utils";
import ModalDialog from "./modalDialog";
import Icons from "../lib/icons";

const dispatchToProps = dispatch => {
  return {
    ...aaDP(dispatch),
    ...paDP(dispatch),
    ...eaDP(dispatch)
  };
};

class EditApproverForm extends Component {
  constructor(props) {
    super(props);

    this.state = this.defaultState();

    this.submitApprover = this.submitApprover.bind(this);
  }

  defaultState() {
    return {
      email: "",
      fullName: "",
      companyContactId: null,
      errorMessage: ""
    };
  }

  componentDidUpdate(prevProps) {
    // On open
    if (prevProps.isOpen !== true && this.props.isOpen === true) {
      const { approver } = this.props;
      // Edit mode
      if (isEmpty(approver) !== true) {
        this.setState({
          email: approver.email,
          fullName: approver.fullName,
          companyContactId: approver.companyContactId
        });
      } else {
        this.setState(this.defaultState());
      }
    }
  }

  submitApprover() {
    let { email, fullName, companyContactId } = this.state;
    const { perspective, companyId, getDefaultCompany, approver } = this.props;
    if (isEmpty(email)) {
      return this.setState({ errorMessage: Resources.ErrorEmailRequired });
    } else if (isEmailValid(email) !== true) {
      return this.setState({ errorMessage: Resources.ErrorEmailInvalid });
    } else {
      const perspectiveId = perspective.perspectiveId;
      const isCreate = isEmpty(companyContactId);
      const newEmailInOtherPerspective = find((getDefaultCompany(companyId) || {}).companyContacts || [], c => {
        return c.email.toLowerCase() === email.toLowerCase() && c.companyContactId !== companyContactId;
      });

      const payload = {
        email,
        fullName,
        isApprover: true,
        perspectives: [perspectiveId]
      };

      if (isCreate) {
        if (isEmpty(newEmailInOtherPerspective)) {
          this.props.createCompanyContact(companyId, payload);
        } else {
          payload.perspectives = payload.perspectives.concat(newEmailInOtherPerspective.perspectives);
          this.props.updateCompanyContact(companyId, newEmailInOtherPerspective.companyContactId, payload);
        }
      } else {
        // Updating contact
        if (!isEmpty(newEmailInOtherPerspective)) {
          this.props.raiseError(Resources.ContactAlreadyExistsError);
        } else {
          payload.perspectives = approver.perspectives;
          this.props.updateCompanyContact(companyId, companyContactId, payload);
        }
      }
      this.props.toggle();
    }
  }

  render() {
    const { email, fullName, companyContactId, errorMessage } = this.state;
    const { creatingCompanyContact } = this.props.accountsStore;
    let labelClass = "w-15";
    let inputClass = "form-control pr-2 pl-2 plain thin w-75";

    return (
      <ModalDialog
        title={isEmpty(this.props.approver) ? Resources.AddApprover : Resources.EditApprover}
        size="md"
        isOpen={this.props.isOpen}
        toggle={this.props.toggle}
        content={
          <div>
            {!isEmpty(errorMessage) && <div className="flex mb-3 warning-color-font">{errorMessage}</div>}
            <div className="mb-2 flex-align-start">
              <span className={labelClass}>{Resources.Email}</span>
              <input
                className={inputClass}
                type="text"
                value={email}
                onChange={e => {
                  this.setState({ email: e.target.value });
                }}
              />
            </div>
            <div className="mb-2 flex-align-start">
              <span className={labelClass}>{Resources.Name}</span>
              <input
                className={inputClass}
                type="text"
                value={fullName}
                onChange={e => {
                  this.setState({ fullName: e.target.value });
                }}
              />
            </div>
          </div>
        }
        footer={
          <div>
            {creatingCompanyContact === true && (
              <span className="col-6">
                <span className="mt-2 float-left">
                  <span>{Resources.AddingApprover}</span>
                  <span className={Icons.spinner} />
                </span>
              </span>
            )}
            <span className="col-6">
              <span className="float-right">
                <button className="cancel-button" disabled={creatingCompanyContact} onClick={this.props.toggle}>
                  {Resources.Cancel}
                </button>
                <button className="send-button" disabled={creatingCompanyContact} onClick={this.submitApprover}>
                  {isEmpty(companyContactId) ? Resources.Submit : Resources.Update}
                </button>
              </span>
            </span>
          </div>
        }
      />
    );
  }
}

const storeToProps = store => {
  return {
    accountsStore: store.accounts
  };
};

export default connect(
  storeToProps,
  dispatchToProps
)(EditApproverForm);
