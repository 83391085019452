import React, { Component } from "react";

import Resources from "../../../lib/resources";
import { isEmpty } from "../../../lib/utils";
import Attachment from "../../attachment";

export default class DraftThreadItem extends Component {
  render() {
    const { emailBody, attachments, isFirstEntry, assignedTo, statusName, dateText, to, cc } = this.props;

    return (
      <div className="conversation-thread-item draft-item">
        <div>
          <div>
            <div>
              <b dangerouslySetInnerHTML={{ __html: Resources.PrivateDraftText }} />
              <span className="ml-2 lockstep-button" onClick={this.props.handleEdit}>
                {Resources.Edit}
              </span>
              <span className="ml-2 lockstep-button" onClick={this.props.handleDelete}>
                {Resources.Delete}
              </span>

              <span className="float-right" style={{ textAlign: "right" }}>
                <div className="thread-item-age">
                  {dateText} {Resources.Ago.toLowerCase()}
                </div>
                {isFirstEntry && <div>{`${assignedTo}, ${statusName}`}</div>}
              </span>
            </div>
            <div>{to}</div>
            <div>{cc}</div>
            <div>
              <br />
              <div dangerouslySetInnerHTML={{ __html: emailBody }} />
            </div>
          </div>
          {isEmpty(attachments) === false && (
            <div>
              <br />
              {attachments.map(att => {
                return (
                  <Attachment
                    attachment={att}
                    key={att.attachmentId}
                    showIcon={true}
                    name={att.fileName}
                    size={att.sizeBytes}
                  />
                );
              })}
            </div>
          )}
        </div>
      </div>
    );
  }
}
