import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import moment from "moment";

import Icons from "../lib/icons";
import { isEmpty, compareStrings, isGuid, createNewEvent } from "../lib/utils";

import { dispatchToProps as uaDP } from "../store/user-actions";
import { dispatchToProps as aaDP } from "../store/accounts-actions";
import { dispatchToProps as caDP } from "../store/company-actions";
import { dispatchToProps as convDP } from "../store/conversations-actions";
import { dispatchToProps as persDP } from "../store/perspectives-actions";
import { dispatchToProps as genDP } from "../store/general-actions";
import { dispatchToProps as conDP } from "../store/connector-actions";
import { dispatchToProps as laDP } from "../store/ledger-actions";
import { dispatchToProps as congDP } from "../store/contextGroups-actions";
import { dispatchToProps as gaDP } from "../store/general-actions";

const dispatchToProps = dispatch => ({
  ...uaDP(dispatch),
  ...aaDP(dispatch),
  ...convDP(dispatch),
  ...genDP(dispatch),
  ...persDP(dispatch),
  ...conDP(dispatch),
  ...laDP(dispatch),
  ...congDP(dispatch),
  ...gaDP(dispatch),
  ...caDP(dispatch)
});

class CompanySelector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      autoRefresh: true,
      autoRefreshInterval: 15 * 1000
    };

    this.loadData = this.loadData.bind(this);
  }

  componentDidMount() {
    this.props.fetchPerspectives();
    this.tryUpdate();
    this.startInterval();
  }

  componentWillUnmount() {
    this.stopInterval();
  }

  componentDidUpdate(prevProps) {
    this.tryUpdate(prevProps);
  }

  startInterval() {
    if (this.state.autoRefresh === true && isEmpty(this.interval) === true) {
      this.interval = setInterval(this.loadData, this.state.autoRefreshInterval);
    }
  }

  stopInterval() {
    if (this.state.autoRefresh === true) {
      clearInterval(this.interval);
      this.interval = undefined;
    }
  }

  selectCompany(companyId) {
    if (isEmpty(companyId)) {
      return;
    }

    this.props.selectCompany(companyId);
    this.props.fetchCompanyInfo(companyId);
    this.props.fetchCompanyUserRoles(companyId);
  }

  loadData() {
    let { companyId } = this.props.match.params;
    const { accountsStore, userStore, generalStore } = this.props;

    if (navigator.onLine === false) {
      this.props.networkError();
      return;
    } else if (generalStore.hasNetworkError === true) {
      this.props.clearNetworkError();
    }

    if (companyId && accountsStore.fetchingAccounts !== true) {
      this.props.fetchAccounts();

      if (
        userStore.shouldRefreshToken === true &&
        moment.duration(moment().diff(moment(userStore.lastTokenRefresh))).asMinutes() >= 5
      ) {
        this.props.refreshToken();
      }
    }
  }

  tryUpdate(prevProps = { match: { params: {} } }) {
    const { params } = this.props.match;
    const { connectorStore, ledgerStore, contextGroupsStore } = this.props;

    if (
      isEmpty(this.props.perspectivesStore.perspectives) &&
      this.props.perspectivesStore.fetchingPerspectives === false
    ) {
      this.props.fetchPerspectives();
    }

    if (isEmpty(params.companyId)) {
      return;
    }

    // const companyPerspectives = this.props.getCompanyPerspectives(params.companyId) || [];
    // companyPerspectives.forEach(perspective => {
    //   if (contextGroupsStore.fetchedCompanies[perspective.perspectiveId] !== true) {
    //     this.props.refreshContextGroups(params.companyId, perspective.perspectiveId);
    //   }
    // });

    if (
      isGuid(params.perspectiveId) &&
      contextGroupsStore.gotContextGroups === false &&
      contextGroupsStore.failedGetContextGroups === false
    ) {
      this.props.fetchContextGroups(params.companyId, params.perspectiveId);
    }
    if (this.props.userStore.fetchedUserConfig !== true) {
      this.props.getUserConfig();
    }

    if (ledgerStore.fetchingResourcesFailed === null) {
      this.props.fetchResources();
    }

    if (connectorStore.allSupportedConnectorsIsFetched !== true) {
      this.props.fetchAllConnectors();
    }

    // TODO: Remove this, it is not needed now. This data is used anywhere where a connector is interacted with
    if (
      (prevProps.connectorStore || {}).allSupportedConnectorsIsFetched !== true &&
      connectorStore.allSupportedConnectorsIsFetched === true
    ) {
      connectorStore.allSupportedConnectors.forEach(connector => {
        if (connectorStore.connectorVersionDataIsFetched[connector.connectorId] !== true) {
          this.props.fetchConnectorVersionData(connector.connectorId);
        }
      });
    }

    const registeredConnector = this.props.getRegisteredERPConnectors(params.companyId)[0];
    if (!isEmpty(registeredConnector) && registeredConnector !== null) {
      if (connectorStore.fetchedConnectorActions[registeredConnector.connectorId] !== true) {
        this.props.fetchConnectorActions(registeredConnector.connectorId);
      }
    }

    if (prevProps.location && prevProps.location.pathname !== this.props.location.pathname) {
      this.props.userActivity();
    }

    let { accountsStore, userStore } = this.props;
    const prevParams = prevProps.match.params;
    const changedDepartment = params.perspectiveId !== prevParams.perspectiveId;

    if (accountsStore.hasFetchedAccounts === false) {
      this.loadData();
    } else if (accountsStore.selectedCompanyId !== params.companyId) {
      let defaultCompanyId = this.props.getDefaultCompany(params.companyId).companyId;
      let defaultDepartment = userStore.userConfig.defaultPerspective;

      if (isEmpty(defaultCompanyId)) {
        // this should be the only one in the whole of the product that takes you here...
        window.location = "/#/";
      } else if (params.companyId !== defaultCompanyId) {
        this.props.history.replace(`/company/${defaultCompanyId}/${defaultDepartment}`);
      } else {
        this.selectCompany(params.companyId);
      }
    } else if (changedDepartment) {
      this.loadData();
    } else {
      let prevAccountsStore = prevProps.accountsStore || {};
      if (accountsStore.fetchingAccounts !== true && prevAccountsStore.fetchingAccounts === true) {
        if (userStore.isLoggingIn !== true) {
          let duration = moment.duration(moment(new Date(userStore.decoded.exp * 1000)).diff(moment()));
          let asMinutes = duration.asMinutes();
          if (asMinutes < 5) {
            this.props.tokenExpiring();
          } else if (asMinutes < 0) {
            this.props.logout();
          }
        }
        this.props.fetchCompanyUserRoles(params.companyId);
        window.dispatchEvent(createNewEvent("refreshData"));
      }
    }
  }

  render() {
    let modalDialog =
      this.props.userStore.isTokenExpiring !== true
        ? null
        : // <ModalDialog
          //   isOpen={true}
          //   title={Resources.SessionExpiringTitle}
          //   content={
          //     <div
          //       dangerouslySetInnerHTML={{
          //         __html: `${Resources.SessionExpiring}`
          //       }}
          //     />
          //   }
          //   footer={
          //     <div className="float-right">
          //       <span
          //         className="btn btn-default mr-3"
          //         onClick={() => {
          //           this.props.refreshToken();
          //         }}
          //       >
          //         {Resources.Ok}
          //       </span>
          //     </div>
          //   }
          // />
          null;

    const { accountsStore } = this.props;
    let {
      match: { params }
    } = this.props;
    // In the future we will add the ability to select companies from the side nav

    let companyName = (accountsStore.selectedCompany || {}).companyName || "";
    let companies = [];
    if (accountsStore.accounts.length > 0 && isEmpty(accountsStore.selectedCompanyId) !== true) {
      accountsStore.accounts.forEach(account => {
        account.companies.forEach(company => {
          companies.push(company);
        });
      });

      companies.sort((a, b) => {
        return compareStrings(a.companyName, b.companyName);
      });
    }

    let body = null;
    if (companies.length > 1) {
      let path = this.props.match.path;
      path = path.replace(":section", params.section || "");
      path = path.replace(":perspectiveId?", params.perspectiveId || "");
      path = path.replace(":view?", params.view || "");
      path = path.replace(
        ":selectedCompanyId?",
        isEmpty(params.selectedCompanyId)
          ? ""
          : isGuid(params.selectedCompanyId)
          ? "unassigned"
          : params.selectedCompanyId
      );
      path =
        "/" +
        path
          .split("/")
          .filter(part => {
            return isEmpty(part) === false;
          })
          .join("/");
      body = (
        <div className="menu-toggle dropdown">
          <span role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            {accountsStore.selectedCompany.companyName}
            <span className={Icons.dropdown} />
          </span>
          <span className="dropdown-menu">
            {companies.map(company => {
              return (
                <NavLink
                  key={company.companyId}
                  className={`dropdown-item ${company.companyId === accountsStore.selectedCompanyId ? "active" : ""}`}
                  onClick={() => {
                    this.selectCompany(company.companyId);
                  }}
                  to={path.replace(":companyId", company.companyId)}
                >
                  <span className={Icons.myCompany} />
                  {company.companyName}
                </NavLink>
              );
            })}
          </span>
        </div>
      );
    } else {
      body = <h5>{companyName}</h5>;
    }

    return (
      <React.Fragment>
        {modalDialog}
        {body}
      </React.Fragment>
    );
  }
}

const storeToProps = store => {
  return {
    userStore: store.user,
    accountsStore: store.accounts,
    perspectivesStore: store.perspectives,
    connectorStore: store.connector,
    ledgerStore: store.ledger,
    contextGroupsStore: store.contextGroups,
    generalStore: store.general
  };
};

export default connect(storeToProps, dispatchToProps)(CompanySelector);
