import React, { Component } from "react";
import { connect } from "react-redux";
import Resources from "../lib/resources";

import MainContentHeader from "./main_content_header/mainContentHeader";
import { withRouter } from "react-router-dom";
import { isEmpty, find } from "../lib/utils";
import RegisteredConnectors from "./registeredConnectors";
import FeatureGate from "./featureGate";
import FlexibleScrollContainer from "./flexibleScrollContainer";

import { dispatchToProps as caDP } from "../store/connector-actions";
import { dispatchToProps as uaDP } from "../store/user-actions";
import { dispatchToProps as gaDP } from "../store/general-actions";
import { dispatchToProps as laDP } from "../store/ledger-actions";

class ManageConnectors extends Component {
  constructor(props) {
    super(props);

    this.state = { ...this.defaultState };

    this.renderConnector = this.renderConnector.bind(this);
    this.selectConnector = this.selectConnector.bind(this);
    this.registerConnector = this.registerConnector.bind(this);
    this.permitAndRedirect = this.permitAndRedirect.bind(this);
  }

  defaultState = {
    selectedConnector: null,
    showConfirmRegisterModal: false,
    showConfirmUnregisterModal: false,
    connectorRequestName: ""
  };

  componentDidMount() {
    this.tryUpdate();
  }

  componentDidUpdate(prevProps) {
    this.tryUpdate(prevProps);
  }

  tryUpdate(prevProps = {}) {
    const {
      companyId,
      connectorStore,
      connectorStore: { createdConnectorRequests },
      getRegisteredERPConnectors,
      fetchAllConnectors,
      fetchConnectorVersionData,
      fetchConnectorRequests,
      fetchConnectorActions
    } = this.props;
    const prevConnectorStore = prevProps.connectorStore || {};

    if (!isEmpty(prevProps) && prevProps.companyId !== companyId) {
      this.setState(this.defaultState);
    }

    if (connectorStore.allSupportedConnectorsIsFetched === false) {
      fetchAllConnectors();
    }

    if (connectorStore.fetchingConnectorRequestsFailed === null) {
      fetchConnectorRequests();
    }

    if (
      prevConnectorStore.allSupportedConnectorsIsFetched !== true &&
      connectorStore.allSupportedConnectorsIsFetched === true
    ) {
      connectorStore.allSupportedConnectors.forEach(connector => {
        if (
          (connector.isInternal !== true || this.props.isUserImpersonated()) &&
          connectorStore.connectorVersionDataIsFetched[connector.connectorId] !== true
        ) {
          fetchConnectorVersionData(connector.connectorId);
        }
      });
    }
    const registeredConnector = getRegisteredERPConnectors(companyId)[0];

    if (registeredConnector !== null) {
      if (!isEmpty(registeredConnector)) {
        const requestsAreFetched = connectorStore.fetchingConnectorRequestsFailed !== null;

        if (requestsAreFetched && this.state.selectedConnector === null) {
          fetchConnectorActions(registeredConnector.connectorId);

          // If there is a registered connector, we should set the default selected to be a connector request
          const firstRequest = connectorStore.connectorRequests.filter(cRequest => {
            return !isEmpty((cRequest || {}).imageUrl);
          })[0];
          this.setState({ selectedConnector: firstRequest });
        }
      } else if (connectorStore.allSupportedConnectorsIsFetched === true && this.state.selectedConnector === null) {
        this.setState({
          selectedConnector: find(connectorStore.allSupportedConnectors, c => {
            return c.isInternal !== true || this.props.isUserImpersonated();
          })
        });
      }
    }

    const newConnectorRequest =
      createdConnectorRequests.length > 0 &&
      (prevConnectorStore.createdConnectorRequests || []).length !== createdConnectorRequests.length;
    if (newConnectorRequest) {
      this.setState({ showVoteFeedback: true });
    }
  }

  permitAndRedirect(connector) {
    const { companyId, getLatestConnectorVersion } = this.props;

    const connectorVersion = getLatestConnectorVersion(connector.connectorId);

    this.props
      .setDefaultConnectorPermissions(companyId, connector.companyId, connectorVersion.defaultPermissions)
      .then(data => {
        if (connectorVersion.configurationType.toLowerCase() === "manual") {
          this.props.fetchCompanyConnectors(companyId);
        } else if (connectorVersion.configurationType.toLowerCase() === "automatic") {
          window.location.href = connectorVersion.integrationEndpointUrl + companyId;
        }
      });
  }

  registerConnector(connector) {
    const { companyId, getPermittedERPConnector, getRegisteredERPConnectors, deleteCompanyPermissions } = this.props;
    const permittedConnector = getPermittedERPConnector(companyId);
    const registeredConnector = getRegisteredERPConnectors(companyId)[0] || {};
    if (permittedConnector !== null && isEmpty(permittedConnector)) {
      this.permitAndRedirect(connector);
    } else if (registeredConnector !== null && isEmpty(registeredConnector)) {
      deleteCompanyPermissions(companyId, permittedConnector.companyId).then(data => {
        this.permitAndRedirect(connector);
      });
    } else if (registeredConnector.connectorId === connector.connectorId) {
      console.error("This connector is already registered");
    } else {
      this.setState({ showConfirmRegisterModal: true });
    }
  }

  selectConnector(connector) {
    this.setState({ selectedConnector: connector, showVoteFeedback: false });
  }

  renderConnector(connector, i) {
    const { selectedConnector } = this.state;
    const selectedClass = selectedConnector === connector ? "selected-connector" : "";

    if (isEmpty(connector.connectorRequestId)) {
      const connectorMarkup = (
        <div key={i} className="connector-item">
          <div>
            <div className="connector-name">{connector.displayName}</div>
            <div className="connector-description">
              <span>{Resources.Synchronizes}: </span>
              {((connector.connectorVersions[0] || {}).resources || []).map((resource, i) => (
                <span key={i}>
                  {resource}
                  {connector.connectorVersions[0].resources.length - 1 === i ? null : ", "}
                </span>
              ))}
            </div>
          </div>
          {isEmpty(connector.connectButtonUrl) ? (
            <div>
              <button
                className="button-blue-secondary connector-button"
                onClick={() => this.registerConnector(connector)}
              >
                {Resources.ConnectToConnector(connector.displayName)}
              </button>
            </div>
          ) : (
            <img
              src={connector.connectButtonUrl}
              alt={Resources.ConnectToConnector(connector.displayName)}
              className="connector-connect-button img-fluid"
              onClick={() => this.registerConnector(connector)}
            />
          )}
        </div>
      );

      return connector.isHidden ? (
        <FeatureGate gatedFeature="showHiddenConnectors" key={i}>
          {connectorMarkup}
        </FeatureGate>
      ) : (
        connectorMarkup
      );
    } else {
      // actually a connector request
      return (
        <div key={i} className={selectedClass + " connector-icon"}>
          <div onClick={() => this.selectConnector(connector)} className={`connector-request`}>
            <img src={connector.imageUrl} alt={Resources.VoteFor(connector.displayName)} />
          </div>
        </div>
      );
    }
  }

  render() {
    const { connectorStore, companyId } = this.props;

    let registeredConnectors = this.props.getRegisteredERPConnectors(companyId);
    const connectors = [
      ...connectorStore.allSupportedConnectors.filter(c => {
        return c.connectorId !== (registeredConnectors[0] || {}).connectorId;
      })
    ];

    return (
      <React.Fragment>
        <MainContentHeader title={Resources.CompanyAccountingPackages} hideSearch={true} />
        <div className="manage-content">
          <FlexibleScrollContainer>
            <div className="connector-container">
              {!isEmpty(registeredConnectors) ? (
                <RegisteredConnectors registeredConnectors={registeredConnectors} />
              ) : (
                <React.Fragment>
                  <div className="select-accounting">{Resources.SelectAccountingSoftware}</div>
                  <div className="flex-wrap">
                    <div className="connector-list">{connectors.map(this.renderConnector)}</div>
                  </div>
                </React.Fragment>
              )}
            </div>
          </FlexibleScrollContainer>
        </div>
      </React.Fragment>
    );
  }
}

const storeToProps = store => {
  return {
    connectorStore: store.connector,
    userStore: store.user,
    ledgerStore: store.ledger
  };
};

const dispatchToProps = dispatch => {
  return {
    ...uaDP(dispatch),
    ...caDP(dispatch),
    ...gaDP(dispatch),
    ...laDP(dispatch)
  };
};

export default withRouter(
  connect(
    storeToProps,
    dispatchToProps
  )(ManageConnectors)
);
