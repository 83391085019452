import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import Icons from "../../../lib/icons";
import Resources from "../../../lib/resources";
import IconClose from "../../library/icons/iconClose";
import TableData from "../../library/tableData";

import { formatDate, formatCurrency, isEmpty, getCurrencySymbol } from "../../../lib/utils";

import Modal from "react-modal";

import { dispatchToProps as moDP } from "../../../store/modal-actions";

const dispatchToProps = dispatch => ({
  ...moDP(dispatch)
});

class PaymentsFlyout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showFlyout: true
    };

    this.hideFlyout = this.hideFlyout.bind(this);
  }

  hideFlyout() {
    this.setState({ showFlyout: false });
  }

  render() {
    const transactionDateCol = {
      header: Resources.TransactionDate.toLocaleUpperCase(),
      content: row => (
        <span className="fw-500">
          {formatDate(row.processedDate, row.paymentName.toLowerCase() !== "pending payment", false)}
        </span>
      )
    };

    const transactionIdCol = {
      header: Resources.TransactionId.toLocaleUpperCase(),
      content: row => {
        let hasAttachment = !isEmpty(row.attachments);
        return (
          <div className={`statements-invoice-id ${hasAttachment ? "link" : ""}`}>
            <div className="mr-2">{row.id}</div>
            {hasAttachment && <span className={Icons.filePdf} />}
          </div>
        );
      }
    };

    const amountCol = {
      header: Resources.PaymentAmt.toLocaleUpperCase(),
      content: row => (
        <div className="statements-currency-row">
          <span className="mr-2">{getCurrencySymbol(row.currencyId)}</span>
          <span>{formatCurrency(row.amount, "")}</span>
        </div>
      )
    };

    const transactionTypeCol = {
      header: Resources.TransactionType.toLocaleUpperCase(),
      content: row => (
        <span>{row.paymentName.toLowerCase() === "pending payment" ? Resources.PendingPayment : row.paymentType}</span>
      )
    };
    const invoicesCol = {
      header: Resources.Invoices.toLocaleUpperCase(),
      content: row => {
        let invoicesApplicationContent;
        if (row.invoiceIds.length === 1) {
          invoicesApplicationContent = Resources.OneApplication;
        } else {
          invoicesApplicationContent = Resources.NApplications(row.invoiceIds.length);
        }
        return <span>{invoicesApplicationContent}</span>;
      }
    };

    const unappliedAmountCol = {
      header: Resources.UnappliedAmt.toLocaleUpperCase(),
      content: row => (
        <div className="statements-currency-row">
          <span className="mr-2">{getCurrencySymbol(row.currencyId)}</span>
          <span>{formatCurrency(row.unappliedAmount, "")}</span>
        </div>
      )
    };

    const columns = [
      transactionDateCol,
      transactionIdCol,
      amountCol,
      unappliedAmountCol,
      invoicesCol,
      transactionTypeCol
    ];

    const { invoice = {} } = this.props;

    return (
      <Modal
        isOpen={this.state.showFlyout}
        onRequestClose={this.hideFlyout}
        onAfterClose={this.props.hideFlyout}
        contentLabel="Example Modal"
        className="flyout dispute-flyout"
        overlayClassName="flyout-overlay"
        closeTimeoutMS={400}
      >
        <div className="flyout-heading">
          <div>{Resources.TransactionsAppliedToInvoice(invoice.id)}</div>
          <div onClick={this.hideFlyout} className="flyout-heading-close">
            <IconClose />
          </div>
        </div>
        <div className="flyout-content">
          <div className="flyout-table-container">
            <TableData
              noGrow
              noSort
              pagination
              rowHeight="4rem"
              data={invoice.payments}
              columns={columns}
              maxHeight={`${4 * invoice.payments.length + 2}rem`}
              rowClassName="statements-view-row no-hover"
            />
          </div>
        </div>
      </Modal>
    );
  }
}

const storeToProps = store => {
  return {
    modalStore: store.modal
  };
};

export default withRouter(connect(storeToProps, dispatchToProps)(PaymentsFlyout));
