import React, { Component } from "react";

export default class SignUpFooter extends Component {
  render() {
    return (
      <div id="signup-footer">
        <hr className="signup-footer-hr" />
      </div>
    );
  }
}
