import React, { Component } from "react";
import Resources from "../lib/resources";

class RegUserAccountExists extends Component {
  componentDidMount() {
    window.localStorage.removeItem("registration-stage");
    window.localStorage.removeItem("is_registering");
  }

  render() {
    return (
      <div className="">
        <div className="w-60 mx-auto mt-5 p-3 text-center">
          <h3>{Resources.RegUserExistsBlurb1}</h3>
          <button onClick={() => window.location.reload(true)} className="w-15 mt-5">
            {Resources.Login}
          </button>
          <p className="pt-5">{Resources.RegUserExistsBlurb2}</p>
        </div>
      </div>
    );
  }
}

export default RegUserAccountExists;
