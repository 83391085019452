import React, { Component } from "react";
import { connect } from "react-redux";
import Resources from "../lib/resources";
import Icons from "../lib/icons";
import { Button, Form, FormGroup, Input } from "reactstrap";
import _ from "lodash";
import { dispatchToProps } from "../store/registration-actions";

class RegCompanyInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: window.localStorage.getItem("isCurrentlyRegistering") ? true : false,
      isFormComplete: true,
      companyInfo: {
        companyName: "",
        address: "",
        city: "",
        country: "",
        state: "",
        zip: "",
        companyWebsite: "",
        accountingEmail: "",
        personalEmail: "",
        password: "",
        confirmPassword: ""
      }
    };
  }

  onInput = e => {
    let companyInfo = {
      ...this.state.companyInfo,
      [e.target.name]: e.target.value
    };
    this.setState({
      companyInfo: companyInfo
    });
  };

  isFromComplete = () => {
    let companyInfo = Object.keys(this.state.companyInfo).map(item => {
      return this.state.companyInfo[item];
    });
    let isComplete = companyInfo.every(item => {
      return !_.isEmpty(item);
    });
    this.setState({ isFormComplete: isComplete });
    return isComplete;
  };

  isValid = () => {
    return this.state.companyInfo.password === this.state.companyInfo.confirmPassword && this.isFromComplete();
  };

  submitForm = () => {
    if (this.isValid()) {
      this.setState({ ...this.state, isLoading: true }, () => {
        let companyInfo = this.state.companyInfo;
        this.props.setCompanyRegistrationInfo(companyInfo);
        this.props.createUserAccount(companyInfo).catch(() => {
          this.setState({ ...this.state, isLoading: false });
        });
      });
    }
  };

  render() {
    return this.state.isLoading ? (
      <div className={`w-100 mt-5 text-center fa-10x ${Icons.spinner}`} />
    ) : (
      <div className="w-40 mx-auto">
        <Form className="col">
          {this.props.registration.errorMsgs.map((item, i) => {
            return (
              <span key={i} className=" warning-color-font">
                {item}
              </span>
            );
          })}
          <span className={(this.state.isFormComplete ? "d-none" : "") + " warning-color-font"}>
            {Resources.AllEntriesEmptyError}
          </span>
          <FormGroup className="row">
            <Input
              type="text"
              name="companyName"
              id="reg-company-name"
              placeholder={Resources.Company + " " + Resources.Name}
              value={this.state.companyName}
              onChange={this.onInput}
            />
          </FormGroup>

          <FormGroup className="row">
            <Input
              type="text"
              name="address"
              id="reg-address"
              placeholder={Resources.Address}
              value={this.state.address}
              onChange={this.onInput}
            />
          </FormGroup>

          <FormGroup className="row">
            <Input
              className="col mr-2"
              type="text"
              name="city"
              id="reg-city"
              placeholder={Resources.City}
              value={this.state.city}
              onChange={this.onInput}
            />
            <Input
              className="col input-defualt"
              type="text"
              name="state"
              id="reg-state"
              placeholder={Resources.State}
              value={this.state.state}
              onChange={this.onInput}
            />
          </FormGroup>

          <FormGroup className="row">
            <Input
              className="col mr-2"
              type="text"
              name="zip"
              id="reg-zip"
              placeholder={Resources.Zip}
              value={this.state.zip}
              onChange={this.onInput}
            />
            <Input
              className="col input-defualt"
              type="text"
              name="country"
              id="reg-country"
              placeholder={Resources.Country}
              value={this.state.country}
              onChange={this.onInput}
            />
          </FormGroup>

          <FormGroup className="row">
            <Input
              type="text"
              name="companyWebsite"
              id="reg-company-website"
              placeholder={Resources.Website}
              value={this.state.companyWebsite}
              onChange={this.onInput}
            />
          </FormGroup>

          <FormGroup className="row">
            <Input
              type="text"
              name="accountingEmail"
              id="reg-acct-email"
              placeholder={Resources.Accounting + " " + Resources.Email}
              value={this.state.accountingEmail}
              onChange={this.onInput}
            />
          </FormGroup>

          <FormGroup className="row">
            <Input
              type="text"
              name="personalEmail"
              id="reg-personal-email"
              placeholder={Resources.Personal + " " + Resources.Email}
              value={this.state.personalEmail}
              onChange={this.onInput}
            />
          </FormGroup>

          <FormGroup className="row">
            <Input
              type="password"
              name="password"
              id="reg-password"
              placeholder={Resources.Password}
              value={this.state.password}
              onChange={this.onInput}
            />
          </FormGroup>

          <FormGroup className="row">
            <Input
              type="password"
              name="confirmPassword"
              id="reg-confirm-password"
              placeholder={Resources.Confirm + " " + Resources.Password}
              value={this.state.confirmPassword}
              onChange={this.onInput}
            />
            <span
              className={
                (!this.state.companyInfo.password.startsWith(this.state.companyInfo.confirmPassword) || "d-none") +
                " warning-color-font"
              }
            >
              {Resources.PasswordsNeedToMatchError}
            </span>
          </FormGroup>
          <div className="row">
            <Button onClick={this.submitForm} className="ml-auto w-35">
              {Resources.Next}
            </Button>
          </div>
        </Form>
      </div>
    );
  }
}

const storeToProps = store => {
  return {
    registration: store.registration
  };
};

export default connect(
  storeToProps,
  dispatchToProps
)(RegCompanyInfo);
