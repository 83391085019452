import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from "react-modal";

import { dispatchToProps } from "../store/modal-actions";

import Resources from "../lib/resources";
import { isEmail, isDomain } from "../lib/validation";

import TextInput from "./library/textInput";

class AddSpamAddressModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      address: "",
      isValidAddress: false
    };
    this.addSpamAddress = this.addSpamAddress.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  addSpamAddress() {
    this.props
      .addSpamAddress(this.props.companyId, this.state.address)
      .then(res => this.props.hideModal())
      .catch(err => null);
  }

  handleInputChange(e) {
    const address = e.target.value;
    const isValidAddress = isDomain(address) || isEmail(address);
    this.setState({ address, isValidAddress });
  }

  render() {
    return (
      <Modal
        isOpen={this.props.modal.showModal}
        onRequestClose={() => this.props.hideModal()}
        className="custom-modal"
        overlayClassName="custom-modal-overlay"
      >
        <div className="custom-modal-header">{Resources.MarkAsSpam}</div>
        <div className="custom-modal-divider" />
        <div className="custom-modal-content">
          <div className="mb-3">{Resources.AddSpamDescription}</div>
          <TextInput
            className="w-100"
            inline
            placeholder={Resources.AddSpamPlaceholder}
            textValue={this.state.address}
            isValid={this.state.isValidAddress}
            invalidText={Resources.InvalidEmailIdentifier}
            label={Resources.DomainOrAddress}
            id="spam-address"
            inputOnChange={this.handleInputChange}
            onPressEnter={this.addSpamAddress}
          />
          <div className="flex-row modal-buttons mt-3">
            <button className="button-cancel float-left mr-1" onClick={this.props.hideModal}>
              {Resources.Cancel}
            </button>

            <button onClick={this.addSpamAddress} className="button-green" disabled={!this.state.isValidAddress}>
              {Resources.Add}
            </button>
          </div>
        </div>
      </Modal>
    );
  }
}

const storeToProps = store => {
  return {
    modal: store.modal
  };
};

export default connect(
  storeToProps,
  dispatchToProps
)(AddSpamAddressModal);
