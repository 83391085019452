import React, { Component } from "react";
import { connect } from "react-redux";
import { dispatchToProps as maDP } from "../store/manage-actions";
import { dispatchToProps as moDP } from "../store/modal-actions";

import Resources from "../lib/resources";

import MainContentHeader from "./main_content_header/mainContentHeader";
import CRUDList from "./library/crudList";
import Card from "./library/card";

const dispatchToProps = dispatch => ({
  ...maDP(dispatch),
  ...moDP(dispatch)
});

class ManageContacts extends Component {
  contactColumns = [
    {
      header: Resources.Name.toLocaleUpperCase(),
      content: row => {
        return <div className="overflow-ellipsis">{row.fullName}</div>;
      },
      className: "highlight",
      width: "20%"
    },
    {
      header: Resources.Email.toLocaleUpperCase(),
      content: row => {
        return <div className="overflow-ellipsis">{row.email}</div>;
      },
      width: "30%"
    },
    {
      header: Resources.Organization.toLocaleUpperCase(),
      content: row => {
        return <div className="overflow-ellipsis">{row.groupName || ""}</div>;
      },
      width: "30%"
    },
    {
      header: Resources.Actions.toLocaleUpperCase(),
      type: "actions",
      actions: [
        {
          type: "delete",
          onClick: row =>
            this.props.displayModal("warnBeforeDeleteModal", {
              warningMessage: (
                <div
                  dangerouslySetInnerHTML={{
                    __html: Resources.ConfirmDeleteContact(row.fullName || row.email)
                  }}
                />
              ),
              delete: () => {
                this.props.deleteCompanyAddressBookContact(this.props.match.params.companyId, row.addressBookContactId);
                this.props.hideModal();
              }
            })
        }
      ]
    }
  ];

  render() {
    const contacts = this.props.getCompanyAddressBook(this.props.match.params.companyId);
    return (
      <React.Fragment>
        <MainContentHeader title={Resources.Contacts} hideSearch={true} />
        <div className="manage-content">
          <Card maxWidth={"80em"} className="card-crud-list">
            <CRUDList
              columns={this.contactColumns}
              data={contacts || []}
              title={Resources.ContactList}
              description={Resources.ContactListDescription}
              onCreate={() =>
                this.props.displayModal("importContactsModal", { companyId: this.props.match.params.companyId })
              }
              createText={Resources.ImportCSV}
              loading={contacts === null}
            />
          </Card>
        </div>
      </React.Fragment>
    );
  }
}

const storeToProps = store => {
  return {
    manageStore: store.manage
  };
};

export default connect(
  storeToProps,
  dispatchToProps
)(ManageContacts);
