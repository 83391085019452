import React, { Component } from "react";
import { isEmpty } from "../../lib/utils";
import Icons from "../../lib/icons";
import Resources from "../../lib/resources";

// import { DragSource, DropTarget } from "react-dnd";

class Card extends Component {
  constructor(props) {
    super(props);

    this.cardRef = React.createRef();
  }

  render() {
    let {
      children,
      maxWidth,
      className,
      title,
      headAboveCard,
      onClickEdit,
      editing,
      onCancel,
      onSave,
      description,
      type,
      clickable,
      onMouseEnter,
      onMouseLeave,
      noSave
    } = this.props;

    //connectDropTarget(connectDragSource(this.cardRef));

    let isEditable = !isEmpty(onClickEdit) && (noSave || !isEmpty(onSave));
    let cardHeader = () =>
      !isEmpty(title) && (
        <React.Fragment>
          <div className="card-head">
            <div className="card-headings">
              <h2 className="card-title">{title}</h2>
            </div>
            {isEditable &&
              (editing ? null : (
                <button className="crud-list-add-new" onClick={() => onClickEdit()}>
                  <span className={Icons.pencil + " pr-2"} />
                  <span>{Resources.Edit}</span>
                </button>
              ))}
          </div>
          <h3 className="card-description">{description}</h3>
        </React.Fragment>
      );
    return (
      <React.Fragment>
        {headAboveCard && cardHeader()}

        <div
          ref={this.cardRef}
          className={`card ${className ? className : ""} ${type ? "card-" + type : ""} ${clickable ? "clickable" : ""}`}
          style={{ maxWidth: maxWidth, opacity: this.props.isDragging ? 0 : 1 }}
          onClick={!isEmpty(this.props.onClick) ? this.props.onClick : undefined}
          onMouseEnter={!isEmpty(onMouseEnter) ? onMouseEnter : undefined}
          onMouseLeave={!isEmpty(onMouseLeave) ? onMouseLeave : undefined}
          draggable={false}
        >
          {!headAboveCard && cardHeader()}
          {children}
          {this.props.isDragging ? "dragging" : null}
          {isEditable && editing && (
            <div className="card-bottom-buttons">
              {!isEmpty(onCancel) && (
                <button
                  className="button-secondary-square"
                  style={{ minWidth: "14rem", marginRight: "1.3rem" }}
                  onClick={onCancel}
                >
                  {Resources.Cancel}
                </button>
              )}
              {!isEmpty(onSave) && (
                <button className="button-primary-square" style={{ minWidth: "14rem" }} onClick={onSave}>
                  {Resources.Save}
                </button>
              )}
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default Card;

// export default DropTarget(
//   "card",
//   {
//     hover(props, monitor, component) {
//       if (!component) {
//         return null;
//       }
//       const node = component.cardRef.current;
//       if (!node) {
//         return null;
//       }
//       const dragIndex = monitor.getItem().index;
//       const hoverIndex = props.index;

//       if (dragIndex === hoverIndex) {
//         return;
//       }
//       const hoverBoundingRect = node.getBoundingClientRect();
//       const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
//       const clientOffset = monitor.getClientOffset();
//       const hoverClientY = clientOffset.y - hoverBoundingRect.top;

//       if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
//         return;
//       }
//       if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
//         return;
//       }

//       props.moveCard(dragIndex, hoverIndex);
//       monitor.getItem().index = hoverIndex;
//     }
//   },
//   connect => ({
//     connectDropTarget: connect.dropTarget()
//   })
// )(
//   DragSource(
//     "card",
//     {
//       canDrag: props => {
//         return props.isDraggable === true;
//       },
//       beginDrag: props => ({
//         index: props.index
//       })
//     },
//     (connect, monitor) => ({
//       connectDragSource: connect.dragSource(),
//       isDragging: monitor.isDragging()
//     })
//   )(Card)
// );
