import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { withRouter } from "react-router-dom";

import Resources from "../lib/resources";
import {
  isEmpty,
  getConversationSubject,
  compareDates,
  find,
  formatCurrency,
  getInvoiceNum,
  formatTaskLabel,
  updateSelectedRows,
  formatDate,
  htmlToText
} from "../lib/utils";
import { getPerspective } from "../lib/perspectives";

import { dispatchToProps as uaDP } from "../store/user-actions";
import { dispatchToProps as convDP } from "../store/conversations-actions";
import { dispatchToProps as connDP } from "../store/connector-actions";
import { dispatchToProps as congDP } from "../store/contextGroups-actions";
import { dispatchToProps as netDP } from "../store/network-actions";

import Icons from "../lib/icons";
import TableData from "./library/tableData";
import Card from "./library/card";
import AutoCompleteInput from "./library/autoCompleteInput";
import ConversationActionButtons from "./lockstep/conversationActionButtons";

import IconAttachment from "./library/icons/iconAttachment";
import IconMessage from "./library/icons/iconMessage";
import { withLDConsumer } from "launchdarkly-react-client-sdk";

const dispatchToProps = dispatch => ({
  ...uaDP(dispatch),
  ...convDP(dispatch),
  ...connDP(dispatch),
  ...congDP(dispatch),
  ...netDP(dispatch)
});

class ConversationTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      perspective: getPerspective(props.match.params.perspectiveId),
      displayedNewCompanyToast: false,
      selectedRows: [],
      selectedKeys: [],
      conversationsLoadedPage: 1,

      conversationsSearchTerm: "",
      displayConversationsSearchResults: false,

      remapIdentifiersOnMove: false
    };

    this.loadMore = this.loadMore.bind(this);
    this.getContextGroupId = this.getContextGroupId.bind(this);
    this.toggleSelectRow = this.toggleSelectRow.bind(this);
  }

  clearSelectedRows() {
    this.setState({ selectedRows: [], selectedKeys: [] });
  }

  getContextGroupId() {
    const { selectedContextGroupId, perspectiveId, subView } = this.props.match.params;

    let contextGroupKey = selectedContextGroupId;
    if (subView === "accounts") {
      const accountProfile = this.props.getAccountProfile(perspectiveId, selectedContextGroupId) || {};
      contextGroupKey = accountProfile.custKey || accountProfile.vendorKey;
    }
    if (subView === "contacts") {
      const contactProfile = this.props.getContactProfile(perspectiveId, selectedContextGroupId);
      contextGroupKey = contactProfile.contactKey;
    }
    return contextGroupKey;
  }

  refreshConversations() {
    const { companyId, perspectiveId } = this.props.match.params;
    let { conversations } = this.props;

    this.props.refreshContextGroups(this.props.match.params.companyId, perspectiveId);

    if (conversations.gettingConversations[perspectiveId] !== true) {
      this.props.refreshConversations(companyId, perspectiveId, this.getContextGroupId());
    }
  }

  loadMore(pageToLoad) {
    let {
      conversations,
      match: {
        params: { companyId, perspectiveId, selectedContextGroupId }
      }
    } = this.props;
    this.setState({ conversationsLoadedPage: pageToLoad });
    const { displayConversationsSearchResults, conversationsSearchTerm } = this.state;
    if (displayConversationsSearchResults) {
      this.props.fetchConversationsSearchResults(companyId, perspectiveId, selectedContextGroupId, {
        searchTerm: conversationsSearchTerm,
        skip: (pageToLoad - 1) * this.props.pageRowCount
      });
    } else {
      if (conversations.gettingConversations[perspectiveId] !== true) {
        this.props.getConversations(
          companyId,
          perspectiveId,
          this.getContextGroupId(),
          false,
          false,
          this.props.pageRowCount,
          this.props.pageRowCount * (pageToLoad - 1)
        );
      }
    }
  }

  componentDidMount() {
    let {
      match: {
        params: { companyId, perspectiveId }
      }
    } = this.props;
    this.props.getConversations(companyId, perspectiveId, this.getContextGroupId());
    this.props.fetchResources();
    this.props.getCompanyUserRoles();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.match.params.view !== "activities" ||
      this.props.match.params.perspectiveId !== prevProps.match.params.perspectiveId ||
      this.props.match.params.selectedContextGroupId !== prevProps.match.params.selectedContextGroupId
    ) {
      this.clearSelectedRows();
    }

    let {
      match: {
        params: { companyId, perspectiveId }
      }
    } = this.props;

    if (
      !isEmpty(prevProps) &&
      (this.props.conversations.sortBy !== prevProps.conversations.sortBy ||
        this.props.conversations.sortDirection !== prevProps.conversations.sortDirection)
    ) {
      this.refreshConversations();
      this.clearSelectedRows();
    }

    if (this.getContextGroupId() !== this.props.conversations.withCompanyId && !isEmpty(this.getContextGroupId())) {
      this.setState({ conversationsSearchTerm: "", displayConversationsSearchResults: false });
      this.props.clearConversationsSearchResults();
      this.props.getConversations(companyId, perspectiveId, this.getContextGroupId());
    }
  }

  isText(str) {
    return (
      [
        "text",
        "forward",
        "note",
        "email",
        "statuschange",
        "assignmentchange",
        "approvalrequest",
        "approval",
        "rejection",
        "task"
      ].indexOf(str) > -1
    );
  }

  isInvoice(str) {
    return ["bill", "invoice"].indexOf(str) > -1;
  }

  toggleSelectRow(key, row) {
    let newSelectedKeys = updateSelectedRows(key, this.state.selectedKeys);
    let newSelectedRows = updateSelectedRows(row, this.state.selectedRows);
    this.setState({ selectedKeys: newSelectedKeys, selectedRows: newSelectedRows });
  }

  renderEmptyState() {
    return (
      <div className="messages-empty-state">
        <svg viewBox="0 0 308 164">
          <g fill="none" fill-rule="evenodd">
            <path fill="#FFF" d="M-566-274H874V750H-566z" />
            <path fill="#F8FBFC" d="M-566-122H874v872H-566z" />
            <rect
              width="1027"
              height="379"
              x=".5"
              y=".5"
              fill="#FFF"
              stroke="#DBE0E3"
              rx="2"
              transform="translate(-360 -38)"
            />
            <path
              fill="#F4F3FF"
              d="M294.545455 20.4090909c7.116942 0 12.886363 5.7694215 12.886363 12.8863636 0 7.1169422-5.769421 12.8863637-12.886363 12.8863637h-73.636364c7.116942 0 12.886364 5.7694215 12.886364 12.8863636 0 7.1169421-5.769422 12.8863637-12.886364 12.8863637h40.5c7.116942 0 12.886364 5.7694215 12.886364 12.8863636 0 7.1169421-5.769422 12.8863636-12.886364 12.8863636h-18.729249c-8.973536 0-16.248024 5.7694213-16.248024 12.8863633 0 4.744628 3.681818 9.040083 11.045455 12.886364 7.116942 0 12.886363 5.769422 12.886363 12.886364 0 7.116942-5.769421 12.886363-12.886363 12.886363H84.6818182c-7.1169421 0-12.8863637-5.769421-12.8863637-12.886363 0-7.116942 5.7694216-12.886364 12.8863637-12.886364H12.8863636C5.76942152 123.5 0 117.730578 0 110.613636c0-7.116942 5.76942152-12.8863633 12.8863636-12.8863633h73.6363637c7.1169421 0 12.8863636-5.7694215 12.8863636-12.8863636 0-7.1169421-5.7694215-12.8863636-12.8863636-12.8863636H40.5c-7.1169421 0-12.8863636-5.7694216-12.8863636-12.8863637S33.3830579 46.1818182 40.5 46.1818182h73.636364c-7.116942 0-12.886364-5.7694215-12.886364-12.8863637 0-7.1169421 5.769422-12.8863636 12.886364-12.8863636h180.409091zm0 51.5454546c7.116942 0 12.886363 5.7694215 12.886363 12.8863636 0 7.1169421-5.769421 12.8863636-12.886363 12.8863636-7.116943 0-12.886364-5.7694215-12.886364-12.8863636 0-7.1169421 5.769421-12.8863636 12.886364-12.8863636z"
            />
            <path
              fill="#FFF"
              d="M130.922787 109.337201c-.143846 1.018442-.218242 2.059205-.218242 3.117344 0 12.200473 9.890437 22.09091 22.09091 22.09091 12.200472 0 22.090909-9.890437 22.090909-22.09091 0-1.058139-.074396-2.098902-.218242-3.117344h53.604605v49.140072c0 3.050118-2.472609 5.522727-5.522727 5.522727H82.8409091c-3.0501181 0-5.5227273-2.472609-5.5227273-5.522727v-49.140072h53.6046052z"
            />
            <path
              fill="#FFF"
              d="M176.727273 108.772727c0 13.217178-10.71464 23.931818-23.931818 23.931818-13.217179 0-23.931819-10.71464-23.931819-23.931818 0-.428198.011246-.853769.033458-1.276435H77.3181818l17.5999005-52.0384031c.7587435-2.2434104 2.8633707-3.7533434 5.2316147-3.7533434h105.291515c2.368244 0 4.472871 1.509933 5.231615 3.7533434l17.5999 52.0384031h-51.578912c.022212.422666.033458.848237.033458 1.276435z"
            />
            <path
              fill="#DEDAFF"
              d="M173.225055 110.529959c0 10.213274-9.146643 20.333677-20.4296 20.333677-11.282957 0-20.429601-10.120403-20.429601-20.333677 0-.330881.0096-2.50064.028561-2.827246H93.8863636l15.0243054-36.5296749c.647708-1.7335444 2.444341-2.9003108 4.466013-2.9003108h78.837545c2.021672 0 3.818305 1.1667664 4.466013 2.9003108l15.024305 36.5296749h-38.508051c.018961.326606.028561 2.496365.028561 2.827246z"
            />
            <path
              stroke="#BEB6FF"
              stroke-width="3.5"
              d="M207.251104 53.4545455H98.3398048c-.2680112 0-.5211058.0854386-.7286641.2343481-.2075583.1489096-.3695803.3612901-.4554463.615174L79.0681818 107.784215v51.374876c0 .345178.139911.657678.3661165.883883.2262055.226206.5387055.366117.8838835.366117H225.272727c.345178 0 .657678-.139911.883884-.366117.226205-.226205.366116-.538705.366116-.883883v-51.374876l-18.087512-53.4801474c-.085866-.2538839-.247888-.4662644-.455447-.615174-.207558-.1489095-.460652-.2343481-.728664-.2343481z"
            />
            <path
              stroke="#BEB6FF"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="3.5"
              d="M89.419822 106.931818h4.4665416-4.4665416zm13.671087 0h23.462223c3.454061 0 3.454061 2.427378 3.454061 3.681818 0 12.200473 10.117987 22.090909 22.599156 22.090909 12.48117 0 22.599157-9.890436 22.599157-22.090909 0-1.25444 0-3.681818 3.454061-3.681818h45.931342M151.137917 2v30.8460138M103.090909 9.92094283l20.433372 22.92507097m75.477273-22.92507097L178.568182 32.8460138"
            />
          </g>
        </svg>
        <h2>{Resources.ThisInboxIsEmpty}</h2>
      </div>
    );
  }

  getConversationColumns(contextGroup) {
    const { params = {} } = this.props.match;
    const { perspectiveId } = params;

    const isWaitingForResponseView = params.selectedContextGroupId === "waiting-for-response";

    // const showAssignedTo =
    //   params.selectedContextGroupId === "assigned" || params.selectedContextGroupId === "pending-approval";
    // const showApprover = params.selectedContextGroupId === "pending-approval";

    let columns;
    let selectCol = {
      type: "rowSelect",
      width: "3%"
    };

    let subjectMessageCol = {
      header: Resources.Activity.toLocaleUpperCase(),
      content: row => {
        let dueOn;
        const hasActiveReminder = !isEmpty(row.activeReminderHash);
        const isTask = row.rootResourceName === "task";
        if (hasActiveReminder) {
          let reminder = find(
            this.props.workflows[perspectiveId] || [],
            reminder => reminder.ledgerHash === row.activeReminderHash
          );
          let dueDate = row.activeReminderDate;
          if (!isEmpty(dueDate)) {
            dueOn = (
              <span className="conversation-table-row-reminder-due">
                {Resources.ReminderNameDueTime(
                  ((reminder || {}).asJson || {}).name || Resources.Reminder,
                  moment
                    .utc(dueDate)
                    .local()
                    .fromNow()
                )}
              </span>
            );
          } else {
            dueOn = (
              <span className="conversation-table-row-reminder-due">
                {Resources.ReminderNameDueTimeAgo(
                  ((reminder || {}).asJson || {}).name || Resources.Reminder,
                  moment(row.modifiedDate).fromNow(true)
                )}
              </span>
            );
          }
        } else if (isTask) {
          const isCompleted =
            (this.props.getConversationStatus(row.conversationStatusId).conversationStatusName || "").toLowerCase() ===
            "completed";
          const { labelClassName, labelText } = formatTaskLabel(row.rootEntry.asJson.dueDate, isCompleted);
          dueOn = <span className={labelClassName}>{labelText}</span>;
        }

        return (
          <div>
            <div className={`customer-portal-messages-table-subject ${row.hasUnreads ? "highlight" : ""}`}>
              <div className="customer-portal-messages-table-subject-text">{row.displaySubject}</div>
              {row.hasAttachments && (
                <div className="customer-portal-messages-table-attachment-icon">
                  <IconAttachment height="16" />
                </div>
              )}
              {!isEmpty(row.message) && (
                <div className={`overflow-ellipsis customer-portal-messages-table-subject-body`}>
                  {!isEmpty(row.displaySubject) && <span className="mr-2 ml-2">-</span>}
                  <span>{htmlToText(row.message)}</span>
                </div>
              )}
            </div>
          </div>
        );
      },
      width: "72%"
    };
    let channelCol = {
      header: isWaitingForResponseView ? Resources.To.toLocaleUpperCase() : Resources.From.toLocaleUpperCase(),
      content: row => (
        <div className={`conversation-table-row-partner overflow-ellipsis ${row.hasUnreads ? "highlight" : ""}`}>
          {row.contextGroupIds.map(id => this.props.getContextGroupName(id)).join()}
        </div>
      ),
      width: "12%"
    };

    let assignedToCol = {
      header: Resources.Assigned.toLocaleUpperCase(),
      content: row => <div className="overflow-ellipsis">{row.assignedTo}</div>,
      width: "10%"
    };

    let approverCol = {
      content: row => <div className="overflow-ellipsis">{row.approver}</div>,
      width: "11%"
    };

    let dateCol = {
      header: isWaitingForResponseView ? Resources.Sent.toLocaleUpperCase() : Resources.Received.toLocaleUpperCase(),
      content: row => (
        <div>
          <div className="conversation-table-row-original-date">
            <div className="original-date-line">{formatDate(row.createdDate)}</div>
          </div>
        </div>
      ),
      width: "18%"
    };

    const iconClassName = row => {
      return row.rootResourceName === "task" ? "" : "conversation-table-row-icon";
    };
    switch (contextGroup) {
      case "pending-approval":
        columns = [
          selectCol,
          {
            header: "",
            content: row => <div className={iconClassName(row)}>{row.icon}</div>,
            width: "2%"
          },
          channelCol,
          {
            header: "",
            content: row => (row.hasAttachments === true ? <span className={Icons.attachment} /> : " "),
            width: "2%"
          },
          { ...subjectMessageCol, width: "40%" },
          {
            header: "",
            content: () => null,
            width: "1%"
          },
          assignedToCol,
          approverCol,
          dateCol
        ];
        break;
      case "unassigned":
      case "spam":
      case "mine":
        columns = [
          selectCol,
          {
            header: "",
            content: row => <div className={iconClassName(row)}>{row.icon}</div>,
            width: "2%"
          },
          channelCol,
          {
            header: "",
            content: row => (row.hasAttachments === true ? <span className={Icons.attachment} /> : " "),
            width: "2%"
          },
          subjectMessageCol,
          dateCol
        ];
        break;
      case "assigned":
      case "closed":
      default:
        columns = [
          selectCol,
          {
            header: "",
            content: row => <div className={iconClassName(row)}>{row.icon}</div>,
            width: "2%"
          },
          channelCol,
          {
            header: "",
            content: row => (row.hasAttachments === true ? <span className={Icons.attachment} /> : " "),
            width: "2%"
          },
          { ...subjectMessageCol, width: "61%" },
          assignedToCol,
          dateCol
        ];
        break;
    }
    return columns;
  }

  render() {
    let { user, conversations, contextGroupsStore, match } = this.props;
    if (user.isLoggedIn !== true) {
      return <div />;
    }

    let params = this.props.match.params;
    let { perspectiveId: perspective } = params;

    const { selectedRows, selectedKeys, conversationsSearchTerm, displayConversationsSearchResults } = this.state;
    const conversationsStore = conversations;

    let selectedUserIds = {};
    let selectedStatusIds = {};

    let disableComposeButton;
    if (params.subView === "accounts" || params.subView === "contacts") {
      let sendToEmail;
      if (params.subView === "accounts") {
        sendToEmail = this.props.getAccountProfile(params.perspectiveId, params.selectedContextGroupId).emailAddr;
      } else {
        sendToEmail = this.props.getContactProfile(params.perspectiveId, params.selectedContextGroupId).emailAddr;
      }
      disableComposeButton = isEmpty(sendToEmail);
    }

    let perspectiveContent = conversations.conversations[perspective] || {};
    let data =
      (this.state.displayConversationsSearchResults
        ? conversationsStore.conversationsSearchResults.value
        : perspectiveContent.value) || [];
    let fetchingEntries = false;

    if (data.length > 0) {
      // this.props.setMaxEntriesLength(data.length * 2);
      data.forEach(d => {
        let addresses = [...d.conversationAddresses];
        addresses.sort((a, b) => {
          return 0 - compareDates(a.associationDate, b.associationDate);
        });

        const latestResourceName = (
          (
            find(this.props.ledgerStore.resources, r => {
              return r.resourceId === (d.latestEntry || {}).resourceId;
            }) || {}
          ).resourceName || ""
        ).toLowerCase();
        const rootResourceName = (
          (
            find(this.props.ledgerStore.resources, r => {
              return r.resourceId === (d.rootEntry || {}).resourceId;
            }) || {}
          ).resourceName || ""
        ).toLowerCase();

        d.rootResourceName = rootResourceName;

        if (this.isText(latestResourceName)) {
          let content = JSON.parse(d.latestEntry.content.split("\n").join(""));
          let textBody = content.textBody;
          if (textBody === "Text body not available.") {
            textBody = null;
          }
          if (isEmpty(textBody)) {
            textBody = htmlToText(content.htmlBody);
          }
          d.message = textBody;
        } else if (this.isInvoice(latestResourceName)) {
          if (d.latestEntry.toCompanyId === d.latestEntry.fromCompanyId) {
            const connectorVersionId = JSON.parse(d.latestEntry.metadata).ConnectorVersionId;
            const connectorId = this.props.getConnectorIdFromVersion(connectorVersionId);
            const erp = (
              find(this.props.connectorStore.allSupportedConnectors, c => {
                return c.connectorId === connectorId;
              }) || {}
            ).displayName;
            const resource = Resources.Invoice;
            const id = (d.rootEntry.asJson.ID || {}).Value || "";
            d.message = Resources.AutoEntryMessage(resource, id, erp);
          } else {
            d.message = Resources.InvoiceArrived;
          }
        } else {
          d.message = Resources.DeliveredByLockstep;
        }

        d.displaySubject = getConversationSubject(d.subject);
        if (
          this.isInvoice(rootResourceName) &&
          isEmpty(d.rootEntry.asJson) === false &&
          isEmpty(JSON.parse(d.rootEntry.metadata).ConnectorVersionId)
        ) {
          let invoice = d.rootEntry.asJson;
          let invoiceNum = `${Resources.Invoice} ${getInvoiceNum(invoice.ID) || ""}`;
          let issueDate = Resources.IssueDate(moment(invoice.IssueDate.ValueString || invoice.IssuedDate).format("ll"));
          let dueDate = isEmpty(invoice.DueDate)
            ? (((invoice.PaymentMeans || [])[0] || {}).PaymentDueDate || {}).ValueString
            : invoice.DueDate.ValueString;
          dueDate = isEmpty(dueDate) ? "-" : moment(dueDate).format("ll");

          dueDate = Resources.DueDate(dueDate);
          let amount = formatCurrency(((invoice.LegalMonetaryTotal || {}).PayableAmount || {}).Value);
          d.displaySubject = [invoiceNum, issueDate, dueDate, amount].join("  |  ");
        }

        d.highlight =
          (this.props.getConversationStatus(d.conversationStatusId || "65d7d5d6-3615-4710-bce7-7920fd12d0d0") || {})
            .displayStyle === "highlight";
        d.lowlight =
          (this.props.getConversationStatus(d.conversationStatusId || "65d7d5d6-3615-4710-bce7-7920fd12d0d0") || {})
            .displayStyle === "lowlight";

        if ((contextGroupsStore.contextGroupsMaps[d.contextGroupIds[0]] || {}).isNew) {
          d.icon = <span className={`${Icons.activeConversation} table-row-main-line-item-icon-new`} />;
        }

        if (rootResourceName === "task") {
          d.icon = <span className={`${Icons.task} conversation-table-row-icon-task`} />;
        }

        if (!isEmpty(d.activeReminderHash)) {
          d.icon = <span className={`${Icons.bell} conversation-table-row-icon-bell`} />;
        }

        if (d.selected) {
          selectedUserIds[d.assignedUserId] = true;
          selectedStatusIds[d.conversationStatusId] = true;
        }

        d.assignedTo = this.props.getUserDisplayName(d.assignedUserId, Resources.You);

        const approver = d.primaryApprover || {};
        d.approver = (approver.friendlyName || "").trim() || (approver.address || "").trim();
      });
    }

    let selectedUserId = Object.keys(selectedUserIds).length === 1 ? Object.keys(selectedUserIds)[0] : undefined;
    if (selectedUserId === "null") selectedUserId = null;

    let selectedStatusId = Object.keys(selectedStatusIds).length === 1 ? Object.keys(selectedStatusIds)[0] : undefined;
    if (selectedStatusId === "null") selectedStatusId = null;

    const loadingConversations = this.state.displayConversationsSearchResults
      ? conversationsStore.gettingConversationsSearchResults
      : conversations.gettingConversations[perspective] || fetchingEntries;

    return (
      <React.Fragment>
        <div className="action-buttons">
          <button
            className="button-primary bg-white"
            onClick={() => {
              const sendToAccountId = params.subView === "accounts" ? params.selectedContextGroupId : null;
              const sendToContactId = params.subView === "contacts" ? params.selectedContextGroupId : null;
              this.props.displayFlyout("newActivityFlyout", {
                companyId: params.companyId,
                perspectiveId: params.perspectiveId,
                sendToAccountId,
                sendToContactId
              });
              //this.props.history.push(this.props.match.url + "/new/conversation");
            }}
            disabled={disableComposeButton}
          >
            <IconMessage height="20" className="button-primary-icon" />
            <span>{Resources.Compose}</span>
          </button>
        </div>
        <Card type="table">
          {isEmpty(data) && !this.state.displayConversationsSearchResults && !loadingConversations ? (
            this.renderEmptyState()
          ) : (
            <React.Fragment>
              <div className="table-data-card-header">
                {!isEmpty(selectedRows) ? (
                  <div className="table-data-card-header-buttons">
                    <ConversationActionButtons
                      actionComplete={() => {
                        this.setState({ selectedRows: [], selectedKeys: [] });
                        this.refreshConversations();
                        this.props.refreshContextGroups(params.companyId, params.perspectiveId);
                      }}
                      noForward
                      selectedRows={selectedRows}
                      selectedKeys={selectedKeys}
                      perspectiveId={perspective}
                    />
                  </div>
                ) : (
                  <div className="table-data-card-header-search">
                    <AutoCompleteInput
                      loading={conversationsStore.gettingConversationsSearchResults}
                      className="auto-complete-input__table-data-search"
                      placeholder={Resources.Search}
                      onChange={e => {
                        const newConversationsSearchTerm = e.target.value;
                        this.setState({ conversationsSearchTerm: newConversationsSearchTerm });
                        this.props.debouncedFetchConversationsSearchResults(
                          match.params.companyId,
                          match.params.perspectiveId,
                          match.params.selectedContextGroupId,
                          {
                            searchTerm: newConversationsSearchTerm
                          }
                        );
                      }}
                      callToActionButton={
                        <div
                          className="dropdown-item-clean dropdown-call-to-action"
                          onClick={() => this.setState({ displayConversationsSearchResults: true })}
                        >
                          {Resources.SeeAllResultsFor(conversationsStore.conversationsSearchTerm)}
                        </div>
                      }
                      isShowingSearchResult={displayConversationsSearchResults}
                      handleClearResult={() => {
                        this.setState({
                          conversationsSearchTerm: "",
                          displayConversationsSearchResults: false,
                          conversationsLoadedPage: 1
                        });
                        this.props.clearConversationsSearchResults();
                        this.refreshConversations();
                      }}
                      text={conversationsSearchTerm || conversationsStore.conversationsSearchTerm}
                      noResultsMessage={Resources.NoResultsFound}
                      showNoResultsMessage={conversationsStore.gotConversationsSearchResults}
                      maxOptions={7}
                      handleSelectOption={option => {
                        this.setState({
                          conversationsSearchTerm: option.subject,
                          displayConversationsSearchResults: true,
                          conversationsLoadedPage: 1
                        });
                        this.props.fetchConversationsSearchResults(match.params.perspectiveId, {
                          searchTerm: option.subject
                        });
                      }}
                      renderOption={option => option.subject}
                      options={conversationsStore.conversationsSearchResults.value}
                      width="300px"
                    />
                  </div>
                )}
              </div>
              <TableData
                name="conversation-table"
                columns={this.getConversationColumns(params.selectedContextGroupId)}
                data={data}
                onLoadMore={this.loadMore}
                loadedPage={this.state.conversationsLoadedPage}
                pagination
                headerClassName="conversation-table__header"
                loading={loadingConversations}
                maxRows={
                  this.state.displayConversationsSearchResults
                    ? conversationsStore.conversationsSearchResults.count
                    : perspectiveContent.count || 0
                }
                onRowClick={(row, rowIndex) => {
                  this.props.updateConversationReadStatuses([row.conversationId], true);
                  this.props.setConversationIndex(rowIndex);
                  this.props.history.push(`${window.location.hash.substr(1)}/${row.conversationId}`);
                }}
                rowKey="conversationId"
                selectedKeys={selectedKeys}
                onRowSelectToggle={key =>
                  this.toggleSelectRow(
                    key,
                    (Array.isArray(key) ? [...key] : [key]).map(k => find(data, row => row.conversationId === k))
                  )
                }
                rowClassName="conversation-table-row"
                rowHeight="4rem"
                emptyRender={
                  <div className="flex-center">
                    <h4 className="mt-5">{Resources.EmptyInboxActivities}</h4>
                  </div>
                }
              />
            </React.Fragment>
          )}
        </Card>
      </React.Fragment>
    );
  }
}

const storeToProps = store => {
  return {
    user: store.user,
    conversations: store.conversations,
    connectorStore: store.connector,
    ledgerStore: store.ledger,
    workflows: store.manage.workflows,
    contextGroupsStore: store.contextGroups,
    pageRowCount: store.general.pageRowCount
  };
};

export default withLDConsumer()(withRouter(connect(storeToProps, dispatchToProps)(ConversationTable)));
