import React, { Component } from "react";
import Resources from "../lib/resources";
import { connect } from "react-redux";
import { isEmpty, find, parseDomain, enableBSTooltips, disableBSTooltips } from "../lib/utils";

import { dispatchToProps as manDP } from "../store/manage-actions";
import { dispatchToProps as modDP } from "../store/modal-actions";
import ModalDialog from "./modalDialog";
import FlexibleScrollContainer from "./flexibleScrollContainer";
import TextInput from "./library/textInput";

const dispatchToProps = dispatch => ({
  ...manDP(dispatch),
  ...modDP(dispatch)
});

class SPFDKIMEmailSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      domainName: "",
      isEmailModalVisible: false,
      isEditingDomainName: false,
      SPF_DKIM_ConfigId: "",
      SPF_DKIM_ConfigData: {},
      isEnabled: false,
      domainNameError: "",
      copiedToClipBoard: {}
    };

    this.enterEditMode = this.enterEditMode.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
  }

  componentDidMount() {
    if (this.props.isVisible === true) {
      this.setPage();
    }
  }

  componentWillUnmount() {
    disableBSTooltips();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isVisible !== true && this.props.isVisible === true) {
      this.setPage();
    }
  }

  enterEditMode() {
    let {
      configData: { configurationData = "{}" },
      companyInfo
    } = this.props;
    configurationData = JSON.parse(configurationData);
    const domainName = parseDomain(
      configurationData.DomainName ||
        companyInfo.connectorEmail.remote.vendors ||
        companyInfo.connectorEmail.remote.customers ||
        ""
    );

    const stateUpdate = { isEditingDomainName: true };
    if (this.state.isEditingDomainName !== true && isEmpty(this.state.domainName) && isEmpty(domainName) !== true) {
      stateUpdate.domainName = domainName;
    }
    this.setState(stateUpdate);
  }

  setPage = () => {
    let {
      configData,
      companyInfo: { connectorEmail }
    } = this.props;

    if (isEmpty(configData) !== true) {
      let configurationData = (configData || {}).configurationData || "{}";
      let SPF_DKIM_ConfigData = JSON.parse(configurationData);

      const domainName = SPF_DKIM_ConfigData.DomainName;

      this.setState({
        SPF_DKIM_ConfigData,
        SPF_DKIM_ConfigId: (configData || {}).emailConfigurationTypeId,
        domainName,
        isEnabled: true
      });
    } else if (isEmpty(connectorEmail.remote) !== true) {
      const domainName = parseDomain(connectorEmail.remote.vendors || connectorEmail.remote.customers || "");

      if (isEmpty(domainName) !== true) {
        this.setState({ domainName }, this.setDomainName);
      }
    }
  };

  setDomainName = () => {
    let { domainName } = this.state;
    let { getAllEmailConfigTypes, setCompanyEmailConfig, perspectiveId, companyId } = this.props;

    if (domainName) {
      let allEmailConfigTypes = getAllEmailConfigTypes();
      let DKIMSPF_Type =
        find(allEmailConfigTypes, item => {
          return item.emailConfigurationTypeName === "DKIM/SPF";
        }) || {};
      let payload = {
        emailConfigurationTypeId: DKIMSPF_Type.emailConfigurationTypeId,
        perspectiveId,
        configurationData: JSON.stringify({ domainName: domainName })
      };
      return setCompanyEmailConfig(companyId, payload);
    } else {
      this.setState({
        domainNameError: Resources.AllEntriesEmptyError,
        isEditingDomainName: false
      });
    }
  };

  disableSFP_DKIMSettings = () => {
    let {
      setCompanyEmailConfig,
      manage: { allEmailConfigTypes },
      perspectiveId,
      companyId
    } = this.props;

    let lockstepType =
      find(allEmailConfigTypes, item => {
        return item.emailConfigurationTypeName === "Lockstep";
      }) || {};
    let lockstepEmailConfig = {
      emailConfigurationTypeId: lockstepType.emailConfigurationTypeId,
      perspectiveId,
      configurationData: null
    };

    setCompanyEmailConfig(companyId, lockstepEmailConfig);
  };

  toggleModal() {
    this.setState({ isEmailModalVisible: !this.state.isEmailModalVisible });
  }

  toClipboard = text => {
    const el = document.createElement("textarea");
    el.value = text;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
  };

  handleToClipboard(text, index, key) {
    this.toClipboard(text.trim());
    this.setState({ copiedToClipBoard: { index, key } });
  }

  formattedCopyText = () => {
    let {
      company: { companyName }
    } = this.props;
    let { SPF_DKIM_ConfigData } = this.state;
    let email =
      Resources.FormattedTextCopyEmailForSPFDKIM(companyName) +
      (SPF_DKIM_ConfigData.Records || []).map(item => {
        let DKIM = item.substring(0, item.indexOf("CNAME"));
        let DKIM_CNAME = item.substring(item.indexOf("CNAME"), item.length);
        return DKIM + "\n\n" + DKIM_CNAME + "\n\n";
      }) +
      "\n\nSPF: " +
      SPF_DKIM_ConfigData.spf +
      " \n\nTXT: " +
      SPF_DKIM_ConfigData.txt;
    this.toClipboard(email);
  };

  copyableDNSRecords() {
    return (this.state.SPF_DKIM_ConfigData.Records || []).reduce((accum, val, index) => {
      return `${accum} ${Resources.DNSRecord} ${index + 1} \n ${Resources.Name}: ${val.Name} \n ${Resources.Type}: ${
        val.Type
      } \n ${Resources.Value}: ${val.Value} \n\n`;
    }, "");
  }

  renderDNSRecords(isEmail = false) {
    if (isEmail !== true) {
      enableBSTooltips();
    }
    const { copiedToClipBoard, SPF_DKIM_ConfigData } = this.state;
    const { index: copiedIndex, key: copiedKey } = copiedToClipBoard;
    return (
      <React.Fragment>
        {(SPF_DKIM_ConfigData.Records || []).map((record, i) => {
          const hasName = isEmpty(record.Name) !== true;

          return (
            <div key={i + "_SPF_DKIM_KEY"} style={{ overflowX: "auto" }}>
              <p className="font-weight-bold m-0">
                {Resources.DNSRecord} {(i + 1).toString()}
              </p>
              {hasName && (
                <div className="input-container">
                  <label>{Resources.Name}</label>
                  <div
                    className={isEmail !== true ? "clickable" : null}
                    onClick={isEmail !== true ? e => this.handleToClipboard(e.target.textContent, i, "Name") : null}
                    data-toggle={isEmail !== true ? "tooltip" : null}
                    data-placement={isEmail !== true ? "top" : null}
                    title={isEmail !== true ? Resources.CopyToClipboard : null}
                  >
                    {record.Name}{" "}
                    {copiedIndex === i && copiedKey === "Name" && <span>{Resources.CopiedToClipboard}</span>}
                  </div>
                </div>
              )}
              <div className="input-container">
                <label>{Resources.Type}</label>
                <div
                  className={isEmail !== true ? "clickable" : null}
                  onClick={isEmail !== true ? e => this.handleToClipboard(e.target.textContent, i, "Type") : null}
                  data-toggle={isEmail !== true ? "tooltip" : null}
                  data-placement={isEmail !== true ? "top" : null}
                  title={isEmail !== true ? Resources.CopyToClipboard : null}
                >
                  {record.Type}{" "}
                  {copiedIndex === i && copiedKey === "Type" && <span>{Resources.CopiedToClipboard}</span>}
                </div>
              </div>
              <div className="input-container">
                <label>{Resources.Value}</label>
                <div
                  className={isEmail !== true ? "clickable" : null}
                  onClick={isEmail !== true ? e => this.handleToClipboard(e.target.textContent, i, "Value") : null}
                  data-toggle={isEmail !== true ? "tooltip" : null}
                  data-placement={isEmail !== true ? "top" : null}
                  title={isEmail !== true ? Resources.CopyToClipboard : null}
                >
                  {record.Value}{" "}
                  {copiedIndex === i && copiedKey === "Value" && <span>{Resources.CopiedToClipboard}</span>}
                </div>
              </div>
            </div>
          );
        })}
      </React.Fragment>
    );
  }

  render() {
    let companyName = this.props.company.companyName;
    let { isVisible, isVerified } = this.props;
    let { domainName, domainNameError, isEditingDomainName, isEmailModalVisible, copiedToClipBoard } = this.state;
    if (isVisible !== true) {
      return null;
    }
    let HelpEmailModalContent = (
      <div>
        <h3 className="text-center mt-1">{Resources.SendSPFDKIMHelpEmail}</h3>
        <div className="p-3 border" style={{ backgroundColor: "#F8F8F8" }}>
          <p>{Resources.SPFDKIMBlurb1}</p>
          <FlexibleScrollContainer maxHeight="75vh">
            <div className="p-2 mb-2 border shadow-sm" style={{ backgroundColor: "#FCFCFC" }}>
              <p className="font-weight-bold text-muted m-0">{Resources.Subject}</p>
              <p className="m-0">{Resources.AddingSPFDKIMRecordsForLockstep}</p>
              <button
                onClick={() => this.handleToClipboard(Resources.AddingSPFDKIMRecordsForLockstep, null, "emailSubject")}
              >
                {Resources.Copy}
              </button>
              {copiedToClipBoard.key === "emailSubject" && <span>{Resources.CopiedToClipboard}</span>}
            </div>
            <div className="p-2 mb-2 border shadow-sm" style={{ backgroundColor: "#FCFCFC" }}>
              <p className="font-weight-bold text-muted m-0">{Resources.Body}</p>
              <p className="">{Resources.Hello}</p>
              <p>{Resources.SPFDKIMBlurb2(companyName)}</p>
              <p>{Resources.SPFDKIMBlurb3}</p>
              {this.renderDNSRecords(true)}
              <button
                onClick={() =>
                  this.handleToClipboard(
                    `${Resources.Hello}\n\n${Resources.SPFDKIMBlurb2(companyName)}\n\n${
                      Resources.SPFDKIMBlurb3
                    }\n\n${this.copyableDNSRecords()}`,
                    null,
                    "emailBody"
                  )
                }
              >
                {Resources.Copy}
              </button>
              {copiedToClipBoard.key === "emailBody" && <span>{Resources.CopiedToClipboard}</span>}
            </div>
            <div className="right-aligned">
              <button onClick={() => this.setState({ isEmailModalVisible: false })} className=" red-bg m-2">
                {Resources.Close}
              </button>
            </div>
          </FlexibleScrollContainer>
        </div>
      </div>
    );

    return (
      <div>
        <div className="">
          {/*<h5 className="d-inline-block mr-2">{Resources.ImproveDeliveryRatesSPFDKIM}</h5>
          <h5 className={`d-inline-block ${isVerified ? "verified-color-font" : "unverified-color-font"}`}>
            {isVerified ? Resources.Verified : Resources.Unverified}
          </h5>*/}
          <p className="mb-0">{Resources.SPFDKIMBlurb4}</p>
          <div
            onClick={() => this.setState({ isEmailModalVisible: true })}
            className="d-inline-block turquoise-link clickable mb-4"
          >
            {Resources.CopyInstructionsToEmailYourITAdmin}
          </div>
        </div>
        <div>
          <div className="card-attribute-content mb-4">
            <TextInput
              fsObscure
              className="mb-4"
              labelInline={false}
              inline
              /*disabled={puttingConnectorConfig}*/
              width="19em"
              textValue={this.state.domainName}
              label={Resources.Domain}
              placeholder={Resources.domainName}
              inputOnChange={e => {
                this.setState({ domainName: e.target.value });
              }}
            />
            <button
              className="button-primary-square"
              style={{ float: "right", marginTop: "35px" }}
              onClick={() => {
                isEmpty(JSON.parse(this.props.configData.configurationData || '"{}"').Records) ||
                JSON.parse(this.props.configData.configurationData || '"{}"').DomainName !== this.state.domainName
                  ? this.setDomainName().then(() => {
                      this.props.displayModal("dkimConfigurationModal", {
                        SPF_DKIM_ConfigData: this.state.SPF_DKIM_ConfigData
                      });
                    })
                  : this.props.displayModal("dkimConfigurationModal", {
                      SPF_DKIM_ConfigData: this.state.SPF_DKIM_ConfigData
                    });
              }}
            >
              {isEmpty(JSON.parse(this.props.configData.configurationData || '"{}"').Records) ||
              JSON.parse(this.props.configData.configurationData || '"{}"').DomainName !== this.state.domainName
                ? Resources.Save
                : Resources.GetConfigurations}
            </button>
          </div>

          {/* TODO: What do we want here for Learn more link?
          <p>
            {Resources.SPFDKIMBlurb5}{" "}
            <a href={window.location.hash} className="blue-font-white-bg">
              {Resources.LearnMore}
            </a>
          </p> */}

          <div className="">
            {/*<div className="p-3 mb-3 border" style={{ backgroundColor: "#F8F8F8" }}>
              <h4>{Resources.SPFDKIMConfigurations}</h4>
              <div className="p-2 mb-1 border" style={{ backgroundColor: "#FCFCFC" }}>
                {this.renderDNSRecords()}
              </div>
        </div>*/}
            <div className="">
              {/* <button className="d-inline-block mr-2">{Resources.SetDNSSettings}</button> */}
              {/* {(isEnabled) 
                ? <button onClick={this.disableSFP_DKIMSettings} className="d-inline-block warning-color-bg">{Resources.DisableDNSSettings}</button> 
                : ''} */}
            </div>
          </div>
          <ModalDialog
            footer={null}
            title={null}
            size="xlg"
            content={HelpEmailModalContent}
            isOpen={isEmailModalVisible}
            toggle={this.toggleModal}
            bodyClassName="m-0 p-0"
          />
        </div>
      </div>
    );
  }
}

const storeToProps = store => {
  return {
    manage: store.manage
  };
};

export default connect(storeToProps, dispatchToProps)(SPFDKIMEmailSettings);
