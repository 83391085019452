import React, { Component } from "react";

class IconTrash extends Component {
  render() {
    let { className, height } = this.props;

    return (
      <svg viewBox="0 0 20 22" height={height} className={`icon ${className ? className : ""}`}>
        <g
          stroke="currentColor"
          strokeWidth="2"
          fill="none"
          fillRule="evenodd"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M1 5h18M17 5v14c0 1.1045695-.8954305 2-2 2H5c-1.1045695 0-2-.8954305-2-2V5m3 0V3c0-1.1045695.8954305-2 2-2h4c1.1045695 0 2 .8954305 2 2v2M8 10v6M12 10v6" />
        </g>
      </svg>
    );
  }
}

IconTrash.defaultProps = {
  height: 22
};

export default IconTrash;
