import React, { Component } from "react";
import { connect } from "react-redux";
import $ from "jquery";

import EmailAddress from "./emailAddress";
import { removeAt, isEmpty, isEmailValid, deepCompare, find, includes, compareStrings } from "../lib/utils";
import Icons from "../lib/icons";

import { dispatchToProps as eaDP } from "../store/error-actions";
import { dispatchToProps as aaDP } from "../store/accounts-actions";

import Resources from "../lib/resources";

const dispatchToProps = dispatch => {
  return {
    ...eaDP(dispatch),
    ...aaDP(dispatch)
  };
};

class EmailAddressBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      adding: false,
      participants: [],
      contacts: [],
      filter: ""
    };

    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.validateAndAdd = this.validateAndAdd.bind(this);
    this.getFilteredContacts = this.getFilteredContacts.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
  }

  componentDidMount() {
    this.tryUpdate();
  }

  componentDidUpdate(prevProps, prevState) {
    this.tryUpdate(prevProps, prevState);

    if (this.state.invalidFormat && this.props.errorStore.hasError !== true && this.props.modalOpen !== true) {
      $(`#${this.props.type}EmailInputSpan`).popover("show");
    } else {
      this.removePopover();
    }
  }

  componentWillUnmount() {
    this.removePopover();
  }

  removePopover() {
    $(`#${this.props.type}EmailInputSpan`).popover("hide");
    $(".popover").remove();
  }

  tryUpdate(prevProps, prevState) {
    let changed = isEmpty(prevProps);

    let state = {};
    if (
      this.props.startAdding === true &&
      (isEmpty(prevProps) ||
        prevProps.startAdding !== true ||
        (this.props.participants.length === 0 && this.state.adding === false))
    ) {
      state.adding = true;
      changed = true;
    }

    let { participants, contacts } = this.props;
    if (
      changed ||
      deepCompare(participants, prevProps.participants) !== 0 ||
      deepCompare(participants, this.state.participants) !== 0
    ) {
      state = { ...state, participants };
      changed = true;
    }

    if (changed || deepCompare(contacts, prevProps.contacts) !== 0) {
      state = { ...state, contacts };
      changed = true;
    }

    if (changed) {
      this.setState(state);
    }

    if (this.state.adding === true && prevState.adding !== true && this.newemail) {
      this.newemail.focus();
    }
  }

  onEmailSelect(selected) {
    let participants = [...this.state.participants];
    let found = find(this.state.participants, participant => {
      return participant.address === selected.email || participant.address === selected.address;
    });
    if (!found) {
      // map property names over for contacts and approvers
      if (!isEmpty(selected.email)) {
        participants.push({
          address: selected.email,
          name: selected.fullName
        });
      } else {
        participants.push(selected);
      }
    }
    this.setState({
      participants,
      filter: "",
      adding: false,
      invalidFormat: false
    });
    this.onChanged(participants);
  }

  removeParticipant(index) {
    let participants = removeAt(this.state.participants, index);
    this.setState({ participants });
    this.onChanged(participants);
  }

  onChanged(participants) {
    let { onChanged } = this.props;
    if (onChanged) onChanged(participants);
  }

  onFilterChanged(e) {
    this.setState({ filter: e.target.value.trim().toLocaleLowerCase() });
  }

  handleKeyPress(e) {
    switch (e.key) {
      case "Enter":
        this.validateAndAdd(e);
        break;
      default:
        this.setState({ invalidFormat: false });
        break;
    }
  }

  validateAndAdd(e) {
    let address = e.target.value.trim();
    let newEmail = {
      name: "",
      address: address,
      isValid: isEmailValid(address),
      isEmpty: isEmpty(address)
    };

    let hasRequiredEmail =
      this.props.startAdding !== true || (this.props.startAdding === true && this.state.participants.length > 0);

    if (this.getFilteredContacts().length === 0) {
      if (newEmail.isEmpty) {
        if (hasRequiredEmail) {
          this.stopAdding();
        }
      } else {
        if (newEmail.isValid) {
          this.onEmailSelect(newEmail);
        } else {
          this.setState({ invalidFormat: true });
        }
      }
    } else {
      if (isEmpty(this.interval)) {
        this.interval = setInterval(() => {
          clearInterval(this.interval);
          this.interval = undefined;
          if (this.state.filtering === true) {
            this.setState({ filtering: false });
          }
        }, 250);
      }
      if (newEmail.isValid !== true && newEmail.isEmpty !== true) {
        this.setState({ invalidFormat: true });
      }
    }
  }

  stopAdding() {
    this.removePopover();
    this.setState({
      adding: false,
      filter: "",
      filtering: false,
      invalidFormat: false
    });
  }

  handleKeyDown(e) {
    switch (e.key) {
      case "Escape":
        this.stopAdding();
        break;
      default:
        break;
    }
  }

  getFilteredContacts() {
    let filtered = [];
    let contacts = [...(this.state.contacts || [])];
    if (this.state.adding && this.state.filter.trim() !== "") {
      filtered = contacts.filter(contact => {
        return (
          includes((contact.fullName || "").toLocaleLowerCase(), this.state.filter) ||
          includes((contact.email || "").toLocaleLowerCase(), this.state.filter)
        );
      });

      const perspectiveUserIds = this.props.getPerspectiveUserRoles(this.props.perspectiveId).map(r => r.userId);
      const perspectiveCompanyUsers = this.props.userStore.users.filter(u => includes(perspectiveUserIds, u.userId));

      filtered = filtered.map(contact => {
        const teamMember = find(perspectiveCompanyUsers, user => {
          return user.emailAddress === contact.email;
        });

        if (!isEmpty(teamMember)) {
          contact.isTeamMember = true;
          contact.fullName = teamMember.fullName;
        }

        return contact;
      });

      filtered.sort((a, b) => {
        let aStart = (a.fullName || "").toLocaleLowerCase().startsWith(this.state.filter);
        let bStart = (b.fullName || "").toLocaleLowerCase().startsWith(this.state.filter);
        if (aStart && !bStart) {
          return -1;
        }
        if (!aStart && bStart) {
          return 1;
        }
        return compareStrings(a.fullName || "", b.fullName || "");
      });

      filtered = filtered.slice(0, 4);
    }
    return filtered;
  }

  handleFocus() {
    this.setState({ filtering: true, invalidFormat: false });
  }

  render() {
    let include =
      this.state.filtering !== true
        ? []
        : this.getFilteredContacts().map((c, index) => {
            return (
              <div
                key={`contact_${index}`}
                className="dropdown-item"
                onClick={e => {
                  this.onEmailSelect(c);
                }}
                style={{ cursor: "default" }}
              >
                {c.isTeamMember && (
                  <img
                    alt="Lockstep Icon"
                    className="lockstep-icon"
                    src={process.env.PUBLIC_URL + "/lockstep-favicon.png"}
                  />
                )}
                {isEmpty(c.fullName) || c.fullName === c.email ? c.email : `${c.fullName} (${c.email})`}
              </div>
            );
          });

    return (
      <span>
        {this.state.participants.map((email, index) => {
          return (
            <EmailAddress
              key={index}
              email={email}
              showRemove={true}
              onRemove={() => {
                this.removeParticipant(index);
              }}
            />
          );
        })}
        {this.state.adding ? (
          <span
            id={`${this.props.type}EmailInputSpan`}
            className="dropdown"
            data-toggle="popover"
            data-content={Resources.InvalidEmailFormat}
            data-placement="right"
            data-trigger="manual"
          >
            <input
              ref={newemail => (this.newemail = newemail)}
              className="dropdown-toggle email-input"
              onChange={e => {
                this.onFilterChanged(e);
              }}
              onKeyPress={this.handleKeyPress}
              onKeyDown={this.handleKeyDown}
              onBlur={this.validateAndAdd}
              onFocus={this.handleFocus}
              role="button"
              data-toggle="collapse"
              aria-haspopup="true"
              aria-expanded="true"
            />
            {include.length === 0 ? null : (
              <React.Fragment>
                <span className="dropdown-menu show" style={{ display: "inline-table" }}>
                  {include}
                </span>
              </React.Fragment>
            )}
          </span>
        ) : (
          <span
            className={`plus-email ${Icons.plus}`}
            onClick={() => {
              this.setState({ adding: true });
            }}
          />
        )}
      </span>
    );
  }
}

const storeToProps = store => {
  return {
    errorStore: store.error,
    userStore: store.user
  };
};

export default connect(
  storeToProps,
  dispatchToProps
)(EmailAddressBar);
