import React, { Component } from "react";
import { connect } from "react-redux";
import Resources from "../lib/resources";

import { withRouter } from "react-router-dom";
import { isEmpty } from "../lib/utils";

import { dispatchToProps as caDP } from "../store/connector-actions";
import { dispatchToProps as uaDP } from "../store/user-actions";
import { dispatchToProps as gaDP } from "../store/general-actions";
import { dispatchToProps as laDP } from "../store/ledger-actions";
import { dispatchToProps as modDP } from "../store/modal-actions";

const dispatchToProps = dispatch => {
  return {
    ...uaDP(dispatch),
    ...caDP(dispatch),
    ...gaDP(dispatch),
    ...laDP(dispatch),
    ...modDP(dispatch)
  };
};

class RegisteredConnector extends Component {
  constructor(props) {
    super(props);

    this.state = { ...this.defaultState };

    this.getActionButtonsFromConnector = this.getActionButtonsFromConnector.bind(this);
    this.registerConnector = this.registerConnector.bind(this);
    this.disconnectButton = this.disconnectButton.bind(this);
    this.reconnectButton = this.reconnectButton.bind(this);
    this.configureButton = this.configureButton.bind(this);
  }

  defaultState = {
    selectedConnector: null,
    showConfirmRegisterModal: false,
    showConfirmUnregisterModal: false,
    connectorRequestName: ""
  };

  getDisplayStatusFromConnectorStatus(configStatus, configProgress, configurationType) {
    switch (configStatus) {
      case "Registration":
        if (configurationType === "Manual") {
          return <div className="connector-status-syncing">{Resources.ConfigurationRequired}</div>;
        }
        return <div className="connector-status-registration">{Resources.Disconnected}</div>;
      case "Syncing":
        return <div className="connector-status-syncing">{Resources.SynchronizingData(configProgress)}</div>;
      case "Defaults":
        return <div className="connector-status connector-status-defaults">{Resources.ConfigurationRequired}</div>;
      case "Complete":
        return <div className="connector-status-complete">{Resources.Connected}</div>;
      default:
        return null;
    }
  }

  registerConnector(connector) {
    const { location, history, getLatestConnectorVersion } = this.props;
    let companyId = this.props.match.params.companyId;

    const connectorVersion = getLatestConnectorVersion(connector.connectorId);

    this.props
      .setDefaultConnectorPermissions(companyId, connector.companyId, connectorVersion.defaultPermissions)
      .then(data => {
        if (connectorVersion.configurationType.toLowerCase() === "manual") {
          history.push(`${location.pathname}/registering`);
        } else if (connectorVersion.configurationType.toLowerCase() === "automatic") {
          window.location.href = connectorVersion.integrationEndpointUrl + companyId;
        }
      });
  }

  reconnectButton(registeredConnector) {
    return (
      <button onClick={() => this.registerConnector(registeredConnector)} className="button-warning-secondary">
        {Resources.Reconnect}
      </button>
    );
  }

  disconnectButton(registeredConnector) {
    return (
      <button
        onClick={() =>
          this.props.displayModal("disconnectConnectorModal", {
            connectorDisplayName: registeredConnector.displayName,
            connectorVersionId: registeredConnector.connectorVersion.connectorVersionId,
            companyId: this.props.match.params.companyId,
            connectorCompanyId: registeredConnector.companyId
          })
        }
        className="button-warning-secondary"
      >
        {Resources.DisconnectFromConnector(registeredConnector.displayName)}
      </button>
    );
  }

  configureButton(registeredConnector) {
    let registrationConfig = registeredConnector.connectorVersion.configurationSchema.registrationConfig || [];
    const schema = [...registrationConfig];
    let connectorHasDefaults = isEmpty(schema) === false;
    let additionToPath = registeredConnector.connectorVersion.configurationType === "Manual" ? "registering" : "edit";

    return connectorHasDefaults ? (
      <button
        onClick={() => this.props.history.push(`${this.props.location.pathname}/${additionToPath}`)}
        className="button-blue-secondary"
      >
        {Resources.Configure}
      </button>
    ) : null;
  }

  getActionButtonsFromConnector(registeredConnector) {
    switch (registeredConnector.configStatus) {
      case "Registration":
        if (registeredConnector.connectorVersion.configurationType === "Manual") {
          return (
            <React.Fragment>
              <span className="mr-2">{this.configureButton(registeredConnector)}</span>
              <span>{this.disconnectButton(registeredConnector)}</span>
            </React.Fragment>
          );
        }
        return (
          <React.Fragment>
            <span className="mr-2">{this.reconnectButton(registeredConnector)}</span>
            <span>{this.disconnectButton(registeredConnector)}</span>
          </React.Fragment>
        );
      case "Syncing":
        return this.disconnectButton(registeredConnector);

      case "Defaults":
        return (
          <React.Fragment>
            <span className="mr-2">{this.disconnectButton(registeredConnector)}</span>
            <span>{this.configureButton(registeredConnector)}</span>
          </React.Fragment>
        );
      case "Complete":
        return (
          <React.Fragment>
            <span className="mr-2">{this.disconnectButton(registeredConnector)}</span>
            <span>{this.configureButton(registeredConnector)}</span>
          </React.Fragment>
        );
      default:
        return null;
    }
  }

  render() {
    const { registeredConnectors } = this.props;

    if (isEmpty(registeredConnectors)) {
      return null;
    }

    return (
      <React.Fragment>
        <div className="row pb-3 m-0">
          <div className="col-3 d-flex justify-content-center registered-connector-header">{Resources.Connection}</div>
          <div className="col-4 d-flex justify-content-center registered-connector-header">{Resources.Status}</div>
          <div className="col-5 d-flex justify-content-center registered-connector-header">{Resources.Action}</div>
        </div>
        {registeredConnectors.map((registeredConnector, i) => {
          return (
            <div className="row m-0" key={i}>
              <div className="col-3 d-flex justify-content-center">
                <div className="img-fluid">
                  <div className="registered-connector-icon">
                    <img src={registeredConnector.iconUrl} alt={`${registeredConnector.displayName} logo`} />
                  </div>
                </div>
              </div>
              <div className="col-4 d-flex justify-content-center align-items-center">
                {this.getDisplayStatusFromConnectorStatus(
                  registeredConnector.configStatus,
                  registeredConnector.configProgress,
                  registeredConnector.connectorVersion.configurationType
                )}
              </div>
              <div className="col-5 d-flex justify-content-center align-items-center">
                {this.getActionButtonsFromConnector(registeredConnector)}
                <div />
              </div>
            </div>
          );
        })}
      </React.Fragment>
    );
  }
}

const storeToProps = store => {
  return {
    connectorStore: store.connector,
    userStore: store.user
  };
};

export default withRouter(
  connect(
    storeToProps,
    dispatchToProps
  )(RegisteredConnector)
);
