import React, { Component } from "react";

class IconSearch extends Component {
  render() {
    let { className, height, small } = this.props;

    if (small) {
      return (
        <svg
          viewBox="0 0 16 16"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          fillRule="evenodd"
          fill="none"
          className={`icon ${className ? className : ""}`}
          height={height}
        >
          <g transform="translate(1 1)">
            <circle cx={6} cy={6} r={6} />
            <path d="M14 14l-3-3" />
          </g>
        </svg>
      );
    }

    return (
      <svg
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        className={`icon ${className ? className : ""}`}
        height={height}
        viewBox="0 0 24 24"
      >
        <defs />
        <circle cx="11" cy="11" r="8" />
        <path d="M21 21l-4.35-4.35" />
      </svg>
    );
  }
}

IconSearch.defaultProps = {
  height: 24
};

export default IconSearch;
