import data from "../lib/data";
import { isEmpty } from "../lib/utils";
import { actions as GeneralActions } from "./general-actions";
import { handlePromiseError } from "./error-actions";

export const actions = {
  ...GeneralActions,

  FETCHING_SEQUENCES: "FETCHING_SEQUENCES",
  FETCHED_SEQUENCES: "FETCHED_SEQUENCES",
  FETCH_SEQUENCES_FAILED: "FETCH_SEQUENCES_FAILED",

  FETCHING_SEQUENCE_EVENTS: "FETCHING_SEQUENCE_EVENTS",
  FETCHED_SEQUENCE_EVENTS: "FETCHED_SEQUENCE_EVENTS",
  FETCH_SEQUENCE_EVENTS_FAILED: "FETCH_SEQUENCE_EVENTS_FAILED",

  FETCHING_SEQUENCE_SAMPLES: "FETCHING_SEQUENCE_SAMPLES",
  FETCHED_SEQUENCE_SAMPLES: "FETCHED_SEQUENCE_SAMPLES",
  FETCH_SEQUENCE_SAMPLES_FAILED: "FETCH_SEQUENCE_SAMPLES_FAILED",

  FETCHING_TRIGGERS: "FETCHING_TRIGGERS",
  FETCHED_TRIGGERS: "FETCHED_TRIGGERS",
  FETCH_TRIGGERS_FAILED: "FETCH_TRIGGERS_FAILED",

  FETCHING_SEQUENCE_ACTIONS: "FETCHING_SEQUENCE_ACTIONS",
  FETCHED_SEQUENCE_ACTIONS: "FETCHED_SEQUENCE_ACTIONS",
  FETCH_SEQUENCE_ACTIONS_FAILED: "FETCH_SEQUENCE_ACTIONS_FAILED",

  POSTING_SEQUENCE: "POSTING_SEQUENCE",
  POSTED_SEQUENCE: "POSTED_SEQUENCE",
  POST_SEQUENCE_FAILED: "POST_SEQUENCE_FAILED"
};

const fetchSequences = companyId => (dispatch, getState) => {
  const state = getState().sequences;
  if (state.isFetchingSequences) {
    return;
  }
  dispatch({ type: actions.FETCHING_SEQUENCES });

  data
    .get(`v2/api/sequence/${companyId}`)
    .then(res => {
      dispatch({ type: actions.FETCHED_SEQUENCES, sequences: res.data });
    })
    .catch(err => {
      handlePromiseError(err, "Fetching sequences summary failed.", "sequences");
      dispatch({ type: actions.FETCH_SEQUENCES_FAILED });
    });
};

const postSequence = (companyId, payload) => (dispatch, getState) => {
  dispatch({ type: actions.POSTING_SEQUENCE, sequence: payload });

  data
    .post(`v2/api/sequence/${companyId}`, payload)
    .then(res => {
      dispatch({ type: actions.POSTED_SEQUENCE, sequences: res.data });
    })
    .catch(err => {
      handlePromiseError(err, "Posting sequence failed.", "sequence");
      dispatch({ type: actions.POST_SEQUENCE_FAILED, sequence: payload });
    });
};

const fetchSequenceEvents = (companyId, fromDate, toDate = null, top, skip, filter) => (dispatch, getState) => {
  const state = getState().sequences;
  if (state.isFetchingSequenceEvents) {
    return;
  }
  dispatch({ type: actions.FETCHING_SEQUENCE_EVENTS });

  let endpoint = `v2/api/sequence/${companyId}/events`;

  if (!isEmpty(fromDate)) {
    endpoint = `${endpoint}/from/${fromDate}`;
    if (!isEmpty(toDate)) {
      endpoint = `${endpoint}/to/${toDate}`;
    }
  }

  endpoint = `${endpoint}?$orderBy=${state.sequenceEventsSortBy.trim()}%20${
    state.sequenceEventsSortDirection
  },%20Id%20desc`;

  if (!isEmpty(top)) {
    endpoint = `${endpoint}&$top=${top}`;
  }
  if (!isEmpty(skip)) {
    endpoint = `${endpoint}&$skip=${skip}`;
  }

  if (!isEmpty(filter)) {
    endpoint = `${endpoint}&$filter=${filter}`;
  }

  data
    .get(endpoint)
    .then(res => {
      dispatch({ type: actions.FETCHED_SEQUENCE_EVENTS, eventData: res.data });
    })
    .catch(err => {
      handlePromiseError(err, "Fetching sequences history failed.", "sequences history");
      dispatch({ type: actions.FETCH_SEQUENCE_EVENTS_FAILED });
    });
};

const fetchSequenceSamples = () => (dispatch, getState) => {
  const state = getState().sequences;
  if (state.isFetchingSequenceSamples) {
    return;
  }
  dispatch({ type: actions.FETCHING_SEQUENCE_SAMPLES });

  data
    .get("v2/api/sequence/samples")
    .then(res => {
      dispatch({ type: actions.FETCHED_SEQUENCE_SAMPLES, samples: res.data });
    })
    .catch(err => {
      handlePromiseError(err, "Fetching sequence templates failed.", "sequence templates");
      dispatch({ type: actions.FETCH_SEQUENCE_SAMPLES_FAILED });
    });
};

const fetchTriggers = companyId => (dispatch, getState) => {
  const state = getState().sequences;
  if (state.isFetchingTriggers) {
    return;
  }
  dispatch({ type: actions.FETCHING_TRIGGERS });

  data
    .get(`v2/api/sequence/triggers/${companyId}`)
    .then(res => {
      dispatch({ type: actions.FETCHED_TRIGGERS, triggers: res.data });
    })
    .catch(err => {
      handlePromiseError(err, "Fetching sequence triggers failed.", "sequence triggers");
      dispatch({ type: actions.FETCH_TRIGGERS_FAILED });
    });
};

const fetchSequenceActions = companyId => (dispatch, getState) => {
  const state = getState().sequences;
  if (state.isFetchingSequenceActions) {
    return;
  }
  dispatch({ type: actions.FETCHING_SEQUENCE_ACTIONS });

  data
    .get(`v2/api/sequence/activities/${companyId}`)
    .then(res => {
      dispatch({ type: actions.FETCHED_SEQUENCE_ACTIONS, sequenceActions: res.data });
    })
    .catch(err => {
      handlePromiseError(err, "Fetching sequence actions failed.", "sequence actions");
      dispatch({ type: actions.FETCH_SEQUENCE_ACTIONS_FAILED });
    });
};

const postSequenceStepDescription = (companyId, reqBody) => (dispatch, getState) => {
  return data
    .post(`v2/api/sequence/${companyId}/description`, reqBody)
    .then(res => res.data)
    .catch(err => {
      handlePromiseError(err, "Generating the sequence step description failed", "sequence step description");
      throw err;
    });
};

export const dispatchToProps = dispatch => ({
  fetchSequences: companyId => {
    dispatch(fetchSequences(companyId));
  },
  postSequence: (companyId, payload) => {
    dispatch(postSequence(companyId, payload));
  },
  fetchSequenceEvents: (companyId, fromDate, toDate = null, top, skip, filter) => {
    dispatch(fetchSequenceEvents(companyId, fromDate, (toDate = null), top, skip, filter));
  },
  fetchSequenceSamples: () => {
    dispatch(fetchSequenceSamples());
  },
  fetchTriggers: companyId => {
    dispatch(fetchTriggers(companyId));
  },
  fetchSequenceActions: companyId => {
    dispatch(fetchSequenceActions(companyId));
  },
  postSequenceStepDescription: (companyId, reqBody) => {
    return dispatch(postSequenceStepDescription(companyId, reqBody));
  }
});
