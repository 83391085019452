import React, { Component } from "react";
import Icons from "../../lib/icons";

class LegacyNotification extends Component {
  state = {
    dismissed: false
  };

  componentDidMount() {
    if (this.props.autoDismissTimeout) {
      this.timeout = setTimeout(() => {
        this.setState({ dismissed: true });
      }, this.props.autoDismissTimeout);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  render() {
    if (this.state.dismissed) {
      return null;
    }

    let className = "legacy-notification";

    switch (this.props.type) {
      case "alert":
        className = className + " legacy-notification-alert";
        break;
      case "error":
        className = className + " legacy-notification-error";
        break;
      case "warning":
        className = className + " legacy-notification-warning";
        break;
      case "info":
        className = className + " legacy-notification-info";
        break;
      case "success":
      default:
        className = className + " legacy-notification-success";
        break;
    }

    return (
      <div className={className}>
        {this.props.message}
        {this.props.dismissable && (
          <span onClick={() => this.setState({ dismissed: true })} className={`notification-close ${Icons.close}`} />
        )}
      </div>
    );
  }
}

LegacyNotification.defaultProps = {
  dismissable: true
};

export default LegacyNotification;
