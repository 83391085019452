import { actions } from "./reports-actions";

const defaultReportsSettings = {
  fetchingReport: false,
  fetchedReport: false,
  failedToFetchReport: false,

  fetchingReportUsers: false,
  fetchedReportUsers: false,
  failedToFetchReportUsers: false,

  reportType: "",
  rangeType: "thisWeek",

  reportData: {},
  reportUsers: {},
  reportUserIds: {}
};

function reportsReducer(state = { ...defaultReportsSettings }, action) {
  switch (action.type) {
    case actions.FETCHING_REPORT_DATA:
      return {
        ...state,
        fetchingReport: true,
        fetchedReport: false,
        failedToFetchReport: false,
        reportType: action.reportType,
        reportData: {}
      };
    case actions.FETCHED_REPORT_DATA:
      if (
        action.reportType !== state.reportType ||
        action.companyId !== state.companyId ||
        action.rangeType !== state.rangeType
      )
        return state;
      return {
        ...state,
        fetchingReport: false,
        fetchedReport: true,
        failedToFetchReport: false,
        reportData: action.reportData
      };
    case actions.FETCH_REPORT_DATA_FAILED:
      if (
        action.reportType !== state.reportType ||
        action.companyId !== state.companyId ||
        action.rangeType !== state.rangeType
      )
        return state;
      return {
        ...state,
        fetchingReport: false,
        fetchedReport: false,
        failedToFetchReport: true,
        reportData: {}
      };

    case actions.CHANGE_RANGE_TYPE:
      return { ...state, rangeType: action.rangeType };

    case actions.FETCHING_REPORT_USERS:
      if (state.fetchingReport) return state;
      return {
        ...state,
        fetchingReportUsers: true,
        fetchedReportUsers: false,
        failedToFetchReportUsers: false,
        reportUsers: {},
        reportUserIds: {}
      };
    case actions.FETCHED_REPORT_USERS:
      return {
        ...state,
        fetchingReportUsers: false,
        fetchedReportUsers: true,
        failedToFetchReportUsers: false,
        reportUsers: action.reportUsers,
        reportUserIds: action.reportUserIds
      };
    case actions.FETCH_REPORT_USERS_FAILED:
      return {
        ...state,
        fetchingReportUsers: false,
        fetchedReportUsers: false,
        failedToFetchReportUsers: true
      };

    case actions.CLEAR_DATA:
      return { ...defaultReportsSettings };
    case actions.SELECT_COMPANY:
      if (action.companyId === state.companyId) return state;
      return { ...defaultReportsSettings, companyId: action.companyId };

    default:
      return state;
  }
}

export default reportsReducer;
