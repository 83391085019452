import React, { Component } from "react";
import { Modal, ModalFooter, ModalHeader, ModalBody } from "reactstrap";
import { isEmpty } from "../lib/utils";

export default class ModalDialog extends Component {
  render() {
    const { headerClassName, bodyClassName, footerClassName, title, content, footer, ...rest } = this.props;

    return (
      <Modal {...rest}>
        {isEmpty(title) !== true ? <ModalHeader className={headerClassName}>{title}</ModalHeader> : null}
        <ModalBody className={bodyClassName}>{content}</ModalBody>
        {isEmpty(footer) !== true ? <ModalFooter className={footerClassName}>{footer}</ModalFooter> : null}
      </Modal>
    );
  }
}
