import React, { Component } from "react";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import Resources from "../../lib/resources";

class companyConnectedBody extends Component {
  render() {
    return (
      <div className={this.className}>
        <div className="mt-5 mt mb-4">
          <img alt="" className="mx-auto my-auto d-block" width="25%" src={this.props.companyLogoIconPath} />
        </div>
        <div className="mx-auto w-50">
          <p className="text-center p-2">{Resources.SignUp_BodyTextStep4}</p>
        </div>
        <div className="mx-auto d-flex justify-content-center align-items-center">
          <Button className="mr-2 inactive-button disabled">{Resources.Previous}</Button>
          <Button onClick={this.props.click} className="active-button">
            {Resources.Next + " " + Resources.Step}
          </Button>
        </div>
      </div>
    );
  }
}

const storeToProps = store => {
  return {
    user: store.user
  };
};

export default connect(storeToProps)(companyConnectedBody);
