import React, { Component } from "react";

class IconMessage extends Component {
  render() {
    let { className, height } = this.props;

    return (
      <svg viewBox="0 0 20 20" height={height} className={`icon ${className ? className : ""}`}>
        <g fill="none" fillRule="evenodd">
          <path
            d="M19 13c0 1.1045695-.8954305 2-2 2H5l-4 4V3c0-1.1045695.8954305-2 2-2h14c1.1045695 0 2 .8954305 2 2v10z"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
        </g>
      </svg>
    );
  }
}

IconMessage.defaultProps = {
  height: 20
};

export default IconMessage;
