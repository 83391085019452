import React, { Component } from "react";

class ProfileAttribute extends Component {
  render() {
    let { label, content } = this.props;
    return (
      <div className="profile-attribute">
        <div className="profile-attribute-label">{label}</div>
        <div className="profile-attribute-content">{content}</div>
      </div>
    );
  }
}

ProfileAttribute.defaultProps = {
  label: "",
  content: ""
};

export default ProfileAttribute;
