import React from "react";
import ReactDOM from "react-dom";
import jwt_decode from "jwt-decode";
import { Provider, useSelector } from "react-redux";
import { Router, HashRouter, Route } from "react-router-dom";
import { Helmet } from "react-helmet";
import store from "./store/store";
import { dispatchToProps as UserDispatch } from "./store/user-actions";
import { dispatchToProps as RegistrationDispatch } from "./store/registration-actions";
import {
  getConfig,
  getQueryParameters,
  isLocalHost,
  isProduction,
  isEmpty,
  isStaging,
  isQA,
  isLeading,
  isTrailing
} from "./lib/utils";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import { useLDClient } from "launchdarkly-react-client-sdk";

import FullStory from "react-fullstory";

import Intercom from "react-intercom";

import { DndProvider } from "react-dnd";
import Backend from "react-dnd-html5-backend";

import { createHashHistory } from "history";

import "moment/min/locales.min";

import "bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import "@fortawesome/fontawesome-pro/css/all.css";

//import 'bootstrap/dist/css/bootstrap.css'
import "./css/index.scss";

//import 'babel-polyfill'

import Layout from "./components/layout";
import ErrorBoundary from "./components/errorBoundary";

let history = createHashHistory();
let userDispatch;

if (window.location.hash && window.location.hash !== "") {
  let res = getQueryParameters(window.location.hash.replace(/^#\/?/, ""));
  userDispatch = new UserDispatch(store.dispatch);
  let registrationDispatch = new RegistrationDispatch(store.dispatch);

  if (res.id_token) {
    window.localStorage.setItem("id_token", res.id_token);
    userDispatch.loggedIn(res.id_token);
    window.location = "#/";
  }

  if (res.saml) {
    window.localStorage.clear();
    window.localStorage.setItem("saml", res.saml);
    window.localStorage.setItem("saml-provider", res.provider);
  }

  try {
    if (window.localStorage.getItem("isCurrentlyRegistering")) {
      registrationDispatch.checkIfCompanyExist().then(result => {
        window.localStorage.removeItem("isCurrentlyRegistering");
      });
    }
  } catch {} // DOMExceptions are thrown in cross-origin contexts under newer versions of Chrome, so we'll use the default for now
}

try {
  let idToken = window.localStorage.getItem("id_token");
  let dateNow = new Date();
  if (idToken) {
    let jwt = jwt_decode(idToken);
    if (jwt.exp >= dateNow.getTime() / 1000) {
      userDispatch.loggedIn(idToken);
    } else {
      userDispatch.logout();
    }
  }

  let samlSession = window.localStorage.getItem("saml");
  let providerSession = window.localStorage.getItem("saml-provider");
  if (samlSession && providerSession) {
    userDispatch.samlLogin(providerSession, samlSession);
  }
} catch {} // DOMExceptions are thrown in cross-origin contexts under newer versions of Chrome, so we'll use the default for now

if (isLocalHost() !== true && isProduction()) {
  ReactDOM.render(<FullStory org={getConfig().fullstoryId} />, document.getElementById("fullstory-node"));
}

//Launch Darkly Initialization
(async () => {
  let clientSideID = "5fadac306caa1f0a1a58ef95";
  if (isStaging()) {
    clientSideID = "5fadac55bb17700a04240e69";
  }
  if (isQA()) {
    clientSideID = "5fadac11c1f7360a5eb20d68";
  }
  if (isLeading()) {
    clientSideID = "5fbd944e943b4e09a2297be4";
  }
  if (isTrailing()) {
    clientSideID = "5fbd9467b051d70981ba5d4e";
  }
  if (isProduction()) {
    clientSideID = "5fadac11c1f7360a5eb20d69";
  }
  const LDProvider = await asyncWithLDProvider({
    clientSideID: clientSideID
  });
  ReactDOM.render(
    <LDProvider>
      <Provider store={store}>
        <Router history={history}>
          <HashRouter>
            <ErrorBoundary>
              <LaunchDarklyHandler />
              <Helmet>
                <title>Lockstep</title>
              </Helmet>
              <DndProvider backend={Backend}>
                <Route path="/" component={Layout} />
              </DndProvider>
            </ErrorBoundary>
          </HashRouter>
        </Router>
      </Provider>
    </LDProvider>,
    document.getElementById("root")
  );
})();

ReactDOM.render(<Intercom appID={getConfig().intercomId} />, document.getElementById("intercom-node"));

const LaunchDarklyHandler = () => {
  const ldClient = useLDClient();
  const accountStore = useSelector(state => state.accounts);
  if (!isEmpty(accountStore.selectedAccount) && !isEmpty(accountStore.subscription))
    ldClient.identify({
      key: accountStore.selectedAccount.accountId,
      name: accountStore.selectedAccount.accountName,
      custom: {
        subscriptionTypeId: accountStore.selectedAccount.subscriptionTypeId,
        subscriptionTypeName: accountStore.subscription.subscriptionTypeName
      }
    });
  return null;
};
