import React, { Component } from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import ContactUpdateForm from "./contactUpdateForm";
import SuccessPage from "../successPage";
import ProfileUpdateForm from "./profileUpdateForm";
import AddContactsForm from "./addContactsForm";
import DocumentUploadForm from "../documentUploadForm";
import LockstepHeader from "../lockstepHeader";
import ProfileUpdateViewHeader from "./profileUpdateViewHeader";
import MarketingVideoView from "./marketingVideoView";
import ContactTableForm from "./contactTableForm";

class ProfileUpdateView extends Component {
  componentDidMount() {
    window.Intercom("shutdown");
  }

  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route path="/v2/profileupdate/firsttimesetup/:view(welcome|update)/:accessId">
            <LockstepHeader />
            <Switch>
              <Route path="/v2/profileupdate/firsttimesetup/:view(welcome)/:accessId">
                <MarketingVideoView
                  route={"/v2/profileupdate/firsttimesetup/:view(update)/:accessId"}
                  view={"update"}
                />
              </Route>
              <Route path="/v2/profileupdate/firsttimesetup/:view(update)/:accessId">
                <ContactUpdateForm route={"/v2"} view={""} />
              </Route>
            </Switch>
          </Route>
          <Route path="/v2/profileupdate/firsttimeadmin/:accountingGroupKey/:companyGlobalKey/:mode(ar|ap)/:view(welcome|contactupdate|accountupdate|accountcontacts|accountdocuments)/:accessId">
            <LockstepHeader />
            <Switch>
              <Route path="/v2/profileupdate/firsttimeadmin/:accountingGroupKey/:companyGlobalKey/:mode(ar|ap)/:view(welcome)/:accessId">
                <MarketingVideoView
                  route={
                    "/v2/profileupdate/firsttimeadmin/:accountingGroupKey/:companyGlobalKey/:mode(ar|ap)/:view(contactupdate)/:accessId"
                  }
                  view={"contactupdate"}
                />
              </Route>
              <Route path="/v2/profileupdate/firsttimeadmin/:accountingGroupKey/:companyGlobalKey/:mode(ar|ap)/:view(contactupdate)/:accessId">
                <ContactUpdateForm
                  route={
                    "/v2/profileupdate/firsttimeadmin/:accountingGroupKey/:companyGlobalKey/:mode(ar|ap)/:view(accountupdate)/:accessId"
                  }
                  view={"accountupdate"}
                />
              </Route>
              <Route path="/v2/profileupdate/firsttimeadmin/:accountingGroupKey/:companyGlobalKey/:mode(ar|ap)/:view(accountupdate)/:accessId">
                <ProfileUpdateForm
                  route={
                    "/v2/profileupdate/firsttimeadmin/:accountingGroupKey/:companyGlobalKey/:mode(ar|ap)/:view(accountcontacts)/:accessId"
                  }
                  view={"accountcontacts"}
                  admin={true}
                />
              </Route>
              <Route path="/v2/profileupdate/firsttimeadmin/:accountingGroupKey/:companyGlobalKey/:mode(ar|ap)/:view(accountcontacts)/:accessId">
                <ContactTableForm
                  route={
                    "/v2/profileupdate/firsttimeadmin/:accountingGroupKey/:companyGlobalKey/:mode(ar|ap)/:view(accountdocuments)/:accessId"
                  }
                  view={"accountdocuments"}
                />
              </Route>
              <Route path="/v2/profileupdate/firsttimeadmin/:accountingGroupKey/:companyGlobalKey/:mode(ar|ap)/:view(accountdocuments)/:accessId">
                <DocumentUploadForm route={"/v2"} admin={true} />
              </Route>
            </Switch>
          </Route>
          <Route path="/v2/profileupdate/:type(contact|account|success)/:mode(ar|ap)/:view/:accessId">
            <ProfileUpdateViewHeader />
            <Switch>
              <Route path="/v2/profileupdate/:type(account)/:mode(ar|ap)/update/:accessId">
                <ProfileUpdateForm route={"/v2/profileupdate/:type(account)/addcontact/:accessId"} />
              </Route>
              <Route path="/v2/profileupdate/:type(account)/:mode(ar|ap)/addcontact/:accessId">
                <AddContactsForm route={"/v2/profileupdate/:type(account)/documents/:accessId"} />
              </Route>
              <Route path="/v2/profileupdate/:type(account)/:mode(ar|ap)/documents/:accessId">
                <DocumentUploadForm
                  profileUpdate
                  route={"/v2/profileupdate/:type(success)/:mode(ar|ap)/success/:accessId"}
                />
              </Route>
              <Route path="/v2/profileupdate/:type(contact)/:mode(ar|ap)/update/:accessId">
                <ContactUpdateForm route={"/v2/profileupdate/:type(success)/:mode(ar|ap)/success/:accessId"} />
              </Route>
              <Route path="/v2/profileupdate/success/:mode(ar|ap)/success/:accessId">
                <SuccessPage
                  lockstepverified={true}
                  accessId={this.props.match.params.accessId}
                  mode={this.props.match.params.mode}
                  getCompanyInfo={this.getCompanyInfo}
                />
              </Route>
            </Switch>
          </Route>
        </Switch>
      </React.Fragment>
    );
  }
}

export default withRouter(ProfileUpdateView);
