import React, { Component } from "react";
import SignUp from "./connector_registration/signUp";
import CompanyConnectedBody from "./connector_registration/companyConnectedBody";
import CompanySingUpCompleteBody from "./connector_registration/companySignupCompleteBody";
import ModalDialog from "./modalDialog";

export default class SignUpContinued extends Component {
  state = {
    showStep4: true,
    showStep5: false
  };

  stepTwo = () => {
    this.setState({
      showStep4: false,
      showStep5: true
    });
  };

  stepThree = () => {
    window.location = "/#/home";
  };

  render() {
    return (
      <div>
        {this.state.showStep4 ? (
          <ModalDialog
            isOpen={true}
            headerClassName="signUpModal"
            bodyClassName="signUpModal"
            footerClassName="signUpModal"
            content={
              <SignUp
                content={<CompanyConnectedBody companyLogoIconPath="/img/quickbooks.svg" click={this.stepTwo} />}
                headerHeadlineTxt="You're Connected to QuickBooks!"
                currentStepText="Step 4."
              />
            }
          />
        ) : null}
        {this.state.showStep5 ? (
          <ModalDialog
            isOpen={true}
            headerClassName="signUpModal"
            bodyClassName="signUpModal"
            footerClassName="signUpModal"
            content={
              <SignUp
                content={<CompanySingUpCompleteBody companyLogoIconPath="/img/mountain.svg" click={this.stepThree} />}
                headerHeadlineTxt="You're All Set!"
                currentStepText="Step 5."
              />
            }
          />
        ) : null}
      </div>
    );
  }
}
