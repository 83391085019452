import React, { Component } from "react";

class IconRead extends Component {
  render() {
    let { className, height } = this.props;

    return (
      <svg viewBox="0 0 22 23" height={height} className={`icon ${className ? className : ""}`}>
        <g
          stroke="currentColor"
          strokeWidth="2"
          fill="none"
          fillRule="evenodd"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M21 8v12c0 1.1-.9 2-2 2H3c-1.1 0-2-.9-2-2V8" />
          <path fill="currentColor" d="M1 8l10-7 10 7-10 7L1 8" />
        </g>
      </svg>
    );
  }
}

IconRead.defaultProps = {
  height: 23
};

export default IconRead;
