import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import qs from "qs";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Resources from "../lib/resources";
import Icons from "../lib/icons";
import { isEmpty, getQueryParameters, find, includes } from "../lib/utils";
import { getPartnerType } from "../lib/perspectives";

import PartnerAdder from "./library/partnerAdder";
import TabularData from "./library/tabularData";

import { dispatchToProps as srchD } from "../store/search-actions";
import { dispatchToProps as paDP } from "../store/perspectives-actions";

const dispatchToProps = dispatch => ({
  ...srchD(dispatch),
  ...paDP(dispatch)
});

const columns = [
  {
    header: "",
    key: "icon",
    style: {
      width: "3%",
      fontSize: "8px",
      textAlign: "center",
      color: "#695dfa"
    }
  },
  {
    header: Resources.Organization.toLocaleUpperCase(),
    key: "partner",
    style: {
      width: "15%",
      fontSize: "15px",
      textAlign: "left"
    }
  },
  {
    header: "",
    key: "attachment",
    style: { width: "2%" }
  },
  {
    header: Resources.Subject.toLocaleUpperCase(),
    key: "displayText",
    style: { width: "46%" }
  },
  {
    header: "",
    key: "",
    style: { width: "1%" }
  },
  {
    header: Resources.OriginalDate.toLocaleUpperCase(),
    key: "originalDate",
    style: {
      width: "18%",
      fontSize: "12px",
      fontWeight: "400",
      marginLeft: "0.15em"
    }
  },
  {
    header: Resources.WaitingFor.toLocaleUpperCase(),
    key: "age",
    style: {
      width: "14%",
      fontSize: "12px",
      fontWeight: "400"
    }
  }
];

class SearchViewModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      queryParams: {
        query: "",
        partnerIds: [],
        fromDate: "",
        toDate: ""
      },
      partners: [],
      inputHasFocus: false,
      isCustomDateRange: false,
      syncPartners: true
    };

    this.filtersChanged = this.filtersChanged.bind(this);
    this.executeSearch = this.executeSearch.bind(this);
    this.onKeyPress = this.onKeyPress.bind(this);
    this.updateQuery = this.updateQuery.bind(this);
    this.updateDateRange = this.updateDateRange.bind(this);
    this.clearFilters = this.clearFilters.bind(this);
  }

  componentDidMount() {
    this.tryUpdate();
  }

  componentDidUpdate(prevProps, prevState) {
    this.tryUpdate(prevProps, prevState);
  }

  tryUpdate(prevProps, prevState) {
    const queryParams = getQueryParameters(this.props.location.search);

    if (!prevProps || prevProps.location !== this.props.location) {
      if (isEmpty(getQueryParameters(this.props.location.search)) && this.state.inputHasFocus !== true) {
        this.searchInput.focus();
      }

      const update = {
        syncPartners: true,
        queryParams: { ...this.state.queryParams, ...queryParams }
      };
      if (moment(queryParams.fromDate || "").isValid() || moment(queryParams.toDate || "").isValid()) {
        update.isCustomDateRange = true;
      }

      this.setState(update);
      this.executeSearch();
    }

    if (prevProps && prevProps.contextGroupsStore.value !== this.props.contextGroupsStore.value) {
      const perspectiveCompanies = this.props.contextGroupsStore.value || [];

      let update = { syncPartners: false };

      if (isEmpty(queryParams.partnerIds)) {
        this.setState(update);
      } else if (!isEmpty(perspectiveCompanies)) {
        let partners = [];

        // When arrays with one value are parsed by qs they are returned as a string
        if (typeof queryParams.partnerIds === "string") {
          partners = [
            find(perspectiveCompanies, p => {
              return p.companyId === queryParams.partnerIds;
            })
          ].filter(p => {
            return !isEmpty(p);
          });
        } else {
          partners = perspectiveCompanies.filter(p => {
            return includes(queryParams.partnerIds, p.companyId);
          });
        }

        // This prevents the vendor list from being reordered if it is the same items
        if (_.isEqual(_.sortBy(partners, "companyName"), _.sortBy(this.state.partners, "companyName")) !== true) {
          update.partners = partners;
        }
        this.setState(update);
      }
    }
  }

  filtersChanged() {
    return "?" + qs.stringify(this.state.queryParams) !== this.props.location.search;
  }

  onKeyPress(e) {
    if (e.key === "Enter") {
      this.updateQuery();
    }
  }

  executeSearch() {
    const {
      match: { params },
      location
    } = this.props;
    const queryParams = getQueryParameters(location.search);

    if (isEmpty(queryParams) || isEmpty(queryParams.query.trim())) {
      return;
    }

    const perspectiveId = this.props.match.params.perspectiveId;

    let fromDate = null;
    switch (queryParams.fromDate) {
      case "last24":
        fromDate = moment()
          .subtract(1, "d")
          .format("YYYY-MM-DD HH:mm");
        break;
      case "lastWeek":
        fromDate = moment()
          .subtract(1, "w")
          .format("YYYY-MM-DD HH:mm");
        break;
      case "lastMonth":
        fromDate = moment()
          .subtract(1, "M")
          .format("YYYY-MM-DD HH:mm");
        break;
      case "":
        break;
      default:
        break;
    }
    let withCompanyIds = [];

    if (!isEmpty(queryParams.partnerIds)) {
      withCompanyIds = Array.isArray(queryParams.partnerIds) ? queryParams.partnerIds : [queryParams.partnerIds];
    }

    const request = {
      request: queryParams.query,
      perspectiveId,
      resourceIds: [],
      fromDate: fromDate || queryParams.fromDate,
      toDate: queryParams.toDate,
      withCompanyIds
    };

    this.props.fetchSearchResults(params.companyId, request);
  }

  clearFilters() {
    this.setState({
      partners: [],
      isCustomDateRange: false,
      queryParams: {
        ...this.state.queryParams,
        toDate: "",
        fromDate: "",
        partnerIds: []
      }
    });
  }

  updateQuery() {
    if (this.state.queryParams.query.trim() === "") {
      return;
    }
    this.searchInput.blur();
    this.props.history.push({ search: qs.stringify(this.state.queryParams) });
  }

  updateDateRange(fromDate = "", toDate = "", isCustomDateRange = false) {
    const nextQueryParams = Object.assign({}, this.state.queryParams, {
      fromDate,
      toDate
    });
    this.setState({ isCustomDateRange, queryParams: nextQueryParams });
  }

  updatePartners(partners) {
    this.setState({
      partners,
      queryParams: Object.assign({}, this.state.queryParams, {
        partnerIds: partners.map(p => p.companyId)
      })
    });
  }

  render() {
    const {
      searchStore,
      contextGroupsStore,
      match: { params }
    } = this.props;
    const perspectiveCompanies = contextGroupsStore.value || [];
    const partners = {};

    const perspective = this.props.getCompanyPerspectiveById(params.perspectiveId);

    perspectiveCompanies.forEach(c => {
      partners[c.companyId] = c;
    });

    const data = searchStore.searchResults.map(d => {
      d.partner = (partners[d.withCompanyId] || {}).companyName;
      d.highlight = true;
      d.attachment = d.hasAttachments === true ? <span className={Icons.attachment} /> : " ";
      d.age = moment(d.modifiedDate).fromNow(true);
      d.originalDate = <div className="original-date-line">{moment(d.createdDate).format("llll")}</div>;
      d.className = "search-table-row";

      return d;
    });

    const queryParams = getQueryParameters(this.props.location.search);
    let searchTitle = Resources.Search;

    if (searchStore.isFetchingSearchResults) {
      searchTitle = Resources.Searching + "...";
    } else if (!isEmpty(queryParams)) {
      searchTitle = Resources.SearchResults + ` (${searchStore.searchResults.length})`;
    }

    return (
      <React.Fragment>
        <div className="search-modal-overlay" />
        <div className="search-modal">
          <div className="search-modal-header">
            <div className="flex">
              <h3 className="fw-500">{searchTitle}</h3>
              <div>
                <div className={`main-search-bar ${this.state.inputHasFocus ? "focused" : ""}`}>
                  <input
                    ref={input => {
                      this.searchInput = input;
                    }}
                    onFocus={() => this.setState({ inputHasFocus: true })}
                    onBlur={() => this.setState({ inputHasFocus: false })}
                    onChange={e => {
                      this.setState({
                        queryParams: {
                          ...this.state.queryParams,
                          query: e.target.value
                        }
                      });
                    }}
                    value={this.state.queryParams.query}
                    onKeyPress={this.onKeyPress}
                  />
                  <span onClick={this.updateQuery} className={Icons.search} />
                </div>
              </div>
            </div>
            <Link
              to={this.props.location.pathname.replace("/search", "")}
              className={`${Icons.close} close-search-modal`}
            />
          </div>
          <div className="flex">
            <div className="search-modal-sidebar">
              <h5 className="fw-500 mb-3">{Resources.FilterBy}</h5>
              <div className="font-med">
                <div className="search-filter-title">{Resources.TimeRange.toUpperCase()}</div>
                <div
                  className={`green-link-white-bg pb-2 ${
                    this.state.queryParams.fromDate === "" && this.state.isCustomDateRange !== true ? "active" : ""
                  }`}
                  onClick={() => this.updateDateRange("")}
                >
                  {Resources.AnyTime}
                </div>
                <div
                  className={`green-link-white-bg pb-2 ${this.state.queryParams.fromDate === "last24" ? "active" : ""}`}
                  onClick={() => this.updateDateRange("last24")}
                >
                  {Resources.PastTwentyFourHours}
                </div>
                <div
                  className={`green-link-white-bg pb-2 ${
                    this.state.queryParams.fromDate === "lastWeek" ? "active" : ""
                  }`}
                  onClick={() => this.updateDateRange("lastWeek")}
                >
                  {Resources.PastWeek}
                </div>
                <div
                  className={`green-link-white-bg pb-2 ${
                    this.state.queryParams.fromDate === "lastMonth" ? "active" : ""
                  }`}
                  onClick={() => this.updateDateRange("lastMonth")}
                >
                  {Resources.PastMonth}
                </div>
                <div
                  className={`green-link-white-bg pb-2 ${this.state.isCustomDateRange ? "active" : ""}`}
                  onClick={() => {
                    this.updateDateRange(
                      moment()
                        .startOf("day")
                        .format("YYYY-MM-DD HH:mm:ss"),
                      moment()
                        .endOf("day")
                        .format("YYYY-MM-DD HH:mm:ss"),
                      true
                    );
                  }}
                >
                  {Resources.CustomRange}
                </div>
                {this.state.isCustomDateRange && (
                  <div className="mb-4">
                    <div className="d-inline-block mr-4">
                      <div className="font-small">{Resources.From}</div>
                      <DatePicker
                        className="date-picker-input"
                        calendarClassName="date-picker-calendar"
                        dayClassName={d => "date-picker-day"}
                        dateFormat="YYYY-MM-dd"
                        maxDate={new Date(this.state.queryParams.toDate)}
                        selected={
                          this.state.queryParams.fromDate
                            ? moment(this.state.queryParams.fromDate).toDate()
                            : new Date()
                        }
                        onChange={d =>
                          this.updateDateRange(
                            moment(d)
                              .startOf("day")
                              .format("YYYY-MM-DD HH:mm:ss"),
                            this.state.queryParams.toDate,
                            true
                          )
                        }
                      />
                      {/* <span className={Icons.calendar}></span> */}
                    </div>
                    <div className="d-inline-block">
                      <div className="font-small">{Resources.To}</div>
                      <DatePicker
                        className="date-picker-input"
                        calendarClassName="date-picker-calendar"
                        dayClassName={d => "date-picker-day"}
                        dateFormat="YYYY-MM-dd"
                        minDate={new Date(this.state.queryParams.fromDate)}
                        maxDate={new Date()}
                        selected={
                          this.state.queryParams.toDate ? moment(this.state.queryParams.toDate).toDate() : new Date()
                        }
                        onChange={d =>
                          this.updateDateRange(
                            this.state.queryParams.fromDate,
                            moment(d)
                              .endOf("day")
                              .format("YYYY-MM-DD HH:mm:ss"),
                            true
                          )
                        }
                      />
                      {/* <span className={Icons.calendar}></span> */}
                    </div>
                  </div>
                )}
                <div className="search-filter-title mt-3">
                  {(getPartnerType((perspective || {}).perspectiveName, true) || "").toUpperCase()} (
                  {perspectiveCompanies.length})
                </div>
                <PartnerAdder
                  partnerList={perspectiveCompanies
                    .filter(c => includes(this.state.queryParams.partnerIds, c.companyId) !== true)
                    .map(c => {
                      c.name = c.companyName;
                      return c;
                    })}
                  addedPartners={this.state.partners}
                  onChanged={p => this.updatePartners(p)}
                />

                <button
                  onClick={this.updateQuery}
                  disabled={this.filtersChanged() !== true || this.state.queryParams.query.trim() === ""}
                  className="button-green btn d-block fw-500 mt-3"
                >
                  {Resources.UpdateResults}
                </button>
                <button onClick={this.clearFilters} className="onboarding-cancel-button mt-1">
                  {Resources.ClearFilters}
                </button>
              </div>
            </div>
            <div className="search-modal-content">
              <TabularData
                selectEnabled={true}
                data={data}
                loading={searchStore.isFetchingSearchResults}
                selectable={true}
                format={{
                  callBack: (row, rowIndex) => {
                    const currentPath = this.props.location.pathname;

                    let pathname = currentPath.substring(0, currentPath.indexOf(params.rest));
                    pathname = `${pathname}activities/${row.contextGroupIds[0]}/${row.conversationId}`;
                    this.props.history.push({
                      pathname,
                      search: "?fromSearch=true"
                    });
                  },
                  columns
                }}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const storeToProps = store => {
  return {
    searchStore: store.search,
    contextGroupsStore: store.contextGroups
  };
};

export default connect(
  storeToProps,
  dispatchToProps
)(SearchViewModal);
