import React, { Component } from "react";
import { connect } from "react-redux";
import CompanyLogoButton from "./companyLogoButton";

class CompanySelectionRow extends Component {
  render() {
    let { companyId, companies } = this.props;
    return (
      <div id="company-selection-row" className="mt-3 mx-auto d-flex justify-content-between">
        <CompanyLogoButton companyId={companyId} connectorInfo={companies[0]} />
        <CompanyLogoButton companyId={companyId} connectorInfo={companies[1]} />
        <CompanyLogoButton companyId={companyId} connectorInfo={companies[2]} />
      </div>
    );
  }
}

const storeToProps = store => {
  return {
    user: store.user
  };
};

export default connect(storeToProps)(CompanySelectionRow);
