import React, { Component } from "react";

class IconHouse extends Component {
  render() {
    let { className, height } = this.props;

    return (
      <svg
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        viewBox="0 0 24 24"
        height={height}
        className={`icon ${className ? className : ""}`}
      >
        <path d="M3 9l9-7 9 7v11a2 2 0 01-2 2H5a2 2 0 01-2-2z" />
        <path d="M9 22V12h6v10" />
      </svg>
    );
  }
}

IconHouse.defaultProps = {
  height: 22
};

export default IconHouse;
