import React, { Component } from "react";

import Resources from "../../../lib/resources";
import { isEmpty } from "../../../lib/utils";
import Attachment from "../../attachment";

export default class SimpleForwardThreadItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewFull: false
    };
  }

  render() {
    const {
      posterName,
      emailBody,
      attachments,
      isFirstEntry,
      assignedTo,
      statusName,
      dateText,
      to,
      from,
      cc,
      viewOriginal,
      item = {}
    } = this.props;

    let recipient = ((item.data || {}).to || []).map(recipient => (recipient || {}).address).join(", ");
    let titleNote = Resources.ForwardedByTo(posterName, recipient);

    return (
      <React.Fragment>
        <div className="conversation-thread-item">
          <div className="conversation-thread-item__body">
            <div className="conversation-thread-item__body__header">
              <div className="conversation-thread-forward-badge-container">
                <span className="conversation-thread-item__body__badge conversation-thread-item__body__badge--forward">
                  {titleNote}
                </span>
                <span className="view-link" onClick={() => this.setState({ viewFull: !this.state.viewFull })}>
                  {this.state.viewFull ? Resources.Hide : Resources.Show}
                </span>
              </div>
              <span className="thread-item-age">{dateText}</span>
            </div>
            {this.state.viewFull ? (
              <div className="conversation-thread-full-message">
                <div>{from}</div>
                <div>{to}</div>
                <div>{cc}</div>
                <div>
                  <br />
                  <div dangerouslySetInnerHTML={{ __html: emailBody }} />
                </div>
                {isEmpty(attachments) === false && (
                  <div>
                    <br />
                    {attachments.map(att => {
                      return (
                        <Attachment
                          attachment={att}
                          key={att.attachmentId}
                          showIcon={true}
                          name={att.fileName}
                          size={att.sizeBytes}
                        />
                      );
                    })}
                  </div>
                )}
              </div>
            ) : null}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
