import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Resources from "../lib/resources";
import { isEmpty, find, createKey } from "../lib/utils";
import { getPartnerType } from "../lib/perspectives";

import UserPicker from "./userPicker";
import MainLoader from "./mainLoader";
import SyncedWith from "./library/syncedWith";
import CRUDList from "./library/crudList";
import Card from "./library/card";

import { dispatchToProps as laDP } from "../store/ledger-actions";
import { dispatchToProps as paDP } from "../store/perspectives-actions";
import { dispatchToProps as aaDP } from "../store/accounts-actions";
import { dispatchToProps as comDP } from "../store/company-actions";
import { dispatchToProps as connDP } from "../store/connector-actions";
import { dispatchToProps as modDP } from "../store/modal-actions";

const dispatchToProps = dispatch => ({
  ...laDP(dispatch),
  ...aaDP(dispatch),
  ...paDP(dispatch),
  ...comDP(dispatch),
  ...connDP(dispatch),
  ...modDP(dispatch)
});

class CompanySettings extends Component {
  constructor(props) {
    super(props);

    this.state = { ...this.defaultState };
    this.saveSettings = this.saveSettings.bind(this);
  }

  defaultState = {
    defaultAssignee: {},
    defaultApprover: {},
    partnerEntry: {},
    settingsSaved: false
  };

  componentDidMount() {
    this.props.fetchCompanyIdentifiers(this.props.companyId);
    this.tryUpdate();
  }

  componentDidUpdate(prevProps, prevState) {
    this.tryUpdate(prevProps, prevState);
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  tryUpdate(prevProps, prevState) {
    const {
      match: { params },
      userStore,
      companyId
    } = this.props;
    if (prevProps && prevProps.companyId !== companyId) {
      this.setState({ ...this.defaultState });
      return;
    }

    const perspective = this.props.getCompanyPerspectiveById(params.perspectiveId);

    if (!isEmpty(perspective) && !isEmpty(companyId)) {
      const parentPerspective = perspective.parentPerspective;
      const partnerType = parentPerspective.perspectiveName === "customers" ? "customer" : "vendor";

      const partnerEntry = (this.props.getCompanyResources(params.companyId, companyId, partnerType) || [])[0];
      if (isEmpty(partnerEntry) === false && isEmpty(this.state.partnerEntry)) {
        this.setState({ partnerEntry });
      }
    }

    if (!isEmpty(this.state.partnerEntry)) {
      const existingSettings = this.props.getEntryConfig(this.state.partnerEntry.ledgerHash, params.perspectiveId);

      if (!isEmpty(existingSettings) && !isEmpty(userStore.users)) {
        let update = {};

        if (isEmpty(this.state.defaultAssignee) && existingSettings.defaultAssignee) {
          const myUserId = userStore.decoded.sub;
          const defaultAssignee = find(userStore.users, user => {
            return user.userId === existingSettings.defaultAssignee;
          });
          const isMe = defaultAssignee.userId === myUserId;
          defaultAssignee.displayName = isMe
            ? Resources.Me
            : defaultAssignee.familyName || defaultAssignee.fullName || defaultAssignee.givenName || "";
          update.defaultAssignee = defaultAssignee;
        }

        if (isEmpty(this.state.defaultApprover) && existingSettings.defaultApprover) {
          const approvers = this.props.getCompanyApprovers(params.companyId)[params.perspectiveId] || [];
          const defaultApprover = find(approvers, approver => {
            return approver.companyContactId === existingSettings.defaultApprover;
          });
          update.defaultApprover = defaultApprover;
        }

        if (!isEmpty(update)) {
          this.setState(update);
        }
      }
    }

    if (
      prevState &&
      (prevState.defaultApprover !== this.state.defaultApprover ||
        prevState.defaultAssignee !== this.state.defaultAssignee)
    ) {
      clearTimeout(this.timeout);
      this.setState({ settingsSaved: false });
    }
  }

  saveSettings() {
    this.props
      .updateEntryConfig(this.state.partnerEntry.ledgerHash, this.props.match.params.perspectiveId, {
        defaultAssignee: this.state.defaultAssignee.userId,
        defaultApprover: this.state.defaultApprover.companyContactId || null
      })
      .then(response => {
        if (response) {
          // Hide settings saved feedback after timeout
          const hideSavedCallback = () => {
            this.timeout = setTimeout(() => this.setState({ settingsSaved: false }), 10000);
          };
          this.setState({ settingsSaved: true }, hideSavedCallback);
        }
      });
  }

  companyIdentifiersColumns = [
    {
      content: row => {
        return <div>{row.identifier}</div>;
      }
    },
    {
      type: "actions",
      actions: [
        {
          type: "delete",
          condition: row => row.isUserManaged,
          onClick: row => {
            let emailConnectorId = this.props.getConnectorFromName("Email").connectorId;

            this.props
              .deleteCompanyIdentifier(emailConnectorId, row.connectorCompanyPartyIdentifierId)
              .then(response => {
                this.props.fetchCompanyIdentifiers(row.identifiedCompanyId);
              })
              .catch(error => {
                return null;
              });
          }
        },
        {
          type: "custom",
          condition: row => !row.isUserManaged,
          content: row => {
            let connectedConnector = this.props.getRegisteredERPConnectors(this.props.companyId)[0] || {};
            return <SyncedWith connectorName={connectedConnector.description} />;
          }
        }
      ]
    }
  ];

  render() {
    const {
      match: { params },
      ledgerStore,
      companyId
    } = this.props;

    const approvers = [
      { fullName: Resources.NoOne, companyContactId: null },
      ...(this.props.getCompanyApprovers(companyId)[params.perspectiveId] || [])
    ];
    const perspective = this.props.getCompanyPerspectiveById(params.perspectiveId) || {};
    const parentPerspective = perspective.parentPerspective || {};
    const partnerType = parentPerspective.perspectiveName === "customers" ? "customer" : "vendor";
    let companyIdentifiers = this.props.getCompanyIdentifiers(companyId) || [];
    let identifiedCompanyId =
      (this.props.contextGroupsStore.contextGroupsMaps[this.props.match.params.selectedContextGroupId] || {})
        .companyId || null;

    if (
      ledgerStore.isFetchingCompanyResources[createKey(companyId, partnerType)] ||
      ledgerStore.isFetchingEntryConfig[this.state.partnerEntry.ledgerHash]
    ) {
      return <MainLoader fullScreen={true} />;
    }

    return (
      <div className="context-group-settings">
        <div className="mb-5">
          <h4 className="fw-500 mb-1">{Resources.Assignee}</h4>
          <div className="mb-3">
            {Resources.AutoAssignMessage((getPartnerType(perspective.perspectiveName) || "").toLowerCase())}
          </div>
          <div className="fw-500 mb-1">{Resources.AssignMessagesTo}</div>
          <UserPicker
            perspective={perspective}
            customTrigger={
              <div className="auto-complete-dropdown" style={{ width: "280px" }}>
                <div className="select-text" style={{ width: "250px" }}>
                  {this.state.defaultAssignee.displayName || Resources.Anyone}
                </div>
                <span className="fas fa-angle-down" />
              </div>
            }
            dropdownMenuStyle={{ width: "280px" }}
            userSelected={user => {
              this.setState({ defaultAssignee: user });
            }}
          />
        </div>

        <div className="mb-3">
          <h4 className="fw-500 mb-1">{Resources.Approver}</h4>
          <div className="mb-3">{Resources.ChooseDefaultApprover}</div>
          <div className="fw-500 mb-1">{Resources.SendApprovalRequestsTo}</div>
          <span>
            <div
              className="auto-complete-dropdown"
              style={{ width: "280px" }}
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span className="select-text" style={{ width: "250px" }}>
                {this.state.defaultApprover.fullName || this.state.defaultApprover.email || Resources.NoOne}
              </span>
              <span className="fas fa-angle-down" />
            </div>
            <span className="dropdown-menu" style={{ width: "280px" }}>
              {approvers.map(approver => {
                return (
                  <div
                    key={approver.companyContactId}
                    className="dropdown-item"
                    style={{ cursor: "default", textAlign: "left" }}
                    onClick={e => {
                      this.setState({ defaultApprover: approver });
                    }}
                  >
                    {approver.fullName || approver.email}
                  </div>
                );
              })}
            </span>
          </span>
        </div>

        <button className="send-button my-3" disabled={ledgerStore.isUpdatingEntryConfig} onClick={this.saveSettings}>
          {Resources.Save}
        </button>
        {this.state.settingsSaved && <span className="saved-message">{Resources.Saved}</span>}

        <Card maxWidth={"50em"} className="card-crud-list">
          <CRUDList
            columns={this.companyIdentifiersColumns}
            data={companyIdentifiers}
            title={Resources.EmailMapping}
            description={Resources.EmailMappingExplanation}
            onCreate={() => {
              this.props.displayModal("addEmailIdentifierModal", {
                sourceCompanyId: this.props.match.params.companyId,
                identifiedCompanyId
              });
            }}
            createText={Resources.AddNew}
            loading={this.props.companyStore.gettingCompanyIdentifiers[identifiedCompanyId]}
            noHeader
          />
        </Card>
      </div>
    );
  }
}

const storeToProps = store => {
  return {
    ledgerStore: store.ledger,
    userStore: store.user,
    companyStore: store.companies,
    contextGroupsStore: store.contextGroups
  };
};

export default withRouter(connect(storeToProps, dispatchToProps)(CompanySettings));
