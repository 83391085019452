import React, { Component } from "react";
import { connect } from "react-redux";

import { dispatchToProps as caDP } from "../store/company-actions";
import { dispatchToProps as moDP } from "../store/modal-actions";

import Resources from "../lib/resources";

import Card from "./library/card";
import CRUDList from "./library/crudList";

const dispatchToProps = dispatch => {
  return {
    ...caDP(dispatch),
    ...moDP(dispatch)
  };
};

class SpamSettings extends Component {
  render() {
    const columns = [
      {
        header: Resources.DomainOrAddress.toLocaleUpperCase(),
        content: row => {
          return <div className="overflow-ellipsis">{row.address}</div>;
        },
        className: "highlight",
        width: "50%"
      },
      {
        header: Resources.Actions.toLocaleUpperCase(),
        type: "actions",
        actions: [
          {
            type: "delete",
            text: Resources.Remove,
            onClick: row =>
              this.props.displayModal("warnBeforeDeleteModal", {
                warningMessage: (
                  <div dangerouslySetInnerHTML={{ __html: Resources.ConfirmDeleteSpamAddress(row.address) }} />
                ),
                delete: () => {
                  this.props.deleteAddressFromSpamList(this.props.companyId, row.spamAddressId);
                  this.props.hideModal();
                }
              })
          }
        ],
        width: "25%"
      }
    ];

    const spamAddressList = this.props.getSpamAddressList(this.props.companyId);

    return (
      <div className="manage-content">
        <Card maxWidth={"80em"} className="card-crud-list">
          <CRUDList
            columns={columns}
            data={spamAddressList || []}
            title={Resources.SpamMapping}
            description={Resources.SpamSettingsDescription}
            onCreate={() => {
              this.props.displayModal("addSpamAddressModal", {
                addSpamAddress: this.props.postAddressIsSpam,
                companyId: this.props.companyId
              });
            }}
          />
        </Card>
      </div>
    );
  }
}

const storeToProps = store => {
  return {
    companyStore: store.companies
  };
};

export default connect(
  storeToProps,
  dispatchToProps
)(SpamSettings);
