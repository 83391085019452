import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { dispatchToProps as networkDP } from "../../../../store/network-actions";
import { dispatchToProps as modDP } from "../../../../store/modal-actions";
import Modal from "react-modal";
import SettingsNavigator from "./settingsNavigator";
import WorkspacePreferences from "./workspacePreferences";
import SummaryEmailPreferences from "./summaryEmailPreferences";
import { set } from "numeral";

export const SettingsViews = {
  SETTINGS: "settings",
  WORKSPACE_PREFERENCES: "workspace_preferences",
  SUMMARY_EMAIL_PREFERENCES: "summary_email_preferences"
};

const dispatchToProps = dispatch => ({
  ...networkDP(dispatch),
  ...modDP(dispatch)
});

const Settings = ({ hideFlyout, flags }) => {
  const [showFlyout, setShowFlyout] = useState(true);
  const [currentView, setCurrentView] = useState("settings");

  const requestClose = () => setShowFlyout(false);

  const renderCurrentView = view => {
    switch (view) {
      case SettingsViews.SETTINGS:
        return <SettingsNavigator setCurrentView={setCurrentView} hideFlyout={hideFlyout} />;
      case SettingsViews.WORKSPACE_PREFERENCES:
        return <WorkspacePreferences setCurrentView={setCurrentView} hideFlyout={hideFlyout} />;
      case SettingsViews.SUMMARY_EMAIL_PREFERENCES:
        return <SummaryEmailPreferences setCurrentView={setCurrentView} hideFlyout={hideFlyout} />;
      default:
        break;
    }
  };

  return (
    <Modal
      isOpen={showFlyout}
      onRequestClose={requestClose}
      onAfterClose={!showFlyout ? hideFlyout : null}
      closeTimeoutMS={400}
      contentLabel="Settings flyout"
      className="flyout settings-flyout"
      overlayClassName="flyout-overlay"
    >
      <React.Fragment>{renderCurrentView(currentView)}</React.Fragment>
    </Modal>
  );
};

const storeToProps = store => {
  return {
    networkStore: store.network,
    modalStore: store.modal
  };
};

export default withRouter(connect(storeToProps, dispatchToProps)(Settings));
