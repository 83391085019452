import { actions } from "./manage-actions";
import { sortObjects } from "../lib/utils";

const defaultManageState = {
  companyId: null,

  isGettingAllEmailReplyTemplates: false,
  allCompanyReplyTemplates: {},
  allCompanyReplyTemplatesIsFetched: {},

  isCreatingEmailReplyTemplate: false,

  isCreatingCompanyDocument: false,
  isFetchingCompanyDocuments: false,
  fetchingCompanyDocumentsFailed: {},
  companyDocuments: {},

  isGettingAllEmailConfigTypes: false,
  allEmailConfigTypes: [],

  isImportingCompanyAddressBook: false,
  importingCompanyAddressBookFailed: null,

  puttingAddressBookContact: false,
  putAddressBookContact: false,
  failedPutAddressBookContact: false,

  postingAddressBookContact: false,
  postedAddressBookContact: false,
  failedPostAddressBookContact: false,

  companyAddressBook: [],
  isFetchingCompanyAddressBook: false,
  fetchingCompanyAddressBookFailed: null,

  deletingCompanyAddressBookContact: false,
  deletedCompanyAddressBookContact: false,
  failedDeletingCompanyAddressBookContact: false,

  isSettingCompanyEmailConfigData: false,
  newCompanyEmailConfigDataIsSet: false,

  isGettingCompanyConfigData: false,
  companiesEmailConfigData: {},

  isGettingCompanyEmailConfigType: false,
  companiesEmailConfigType: {},

  isCurrentEmailConfigVerified: null,
  isGettingCurrentEmailConfigVerification: false,

  gettingWorkflows: false,
  gotWorkflows: false,
  failedToGetWorkflows: false,

  puttingWorkflow: false,
  putWorkflow: false,
  failedToPutWorkflow: false,

  deletingWorkflow: false,
  deletedWorkflow: false,
  failedToDeleteWorkflow: false,

  updatingWorkflow: false,
  updatedWorkflow: false,
  failedToUpdateWorkflow: false,

  workflows: {},

  gettingCommunicationTemplates: false,
  gotCommunicationTemplates: false,
  failedGetCommunicationTemplates: false,
  communicationTemplates: [],
  communicationTemplateTypeToTemplate: {
    email: [],
    voice: [],
    text: []
  },

  communicationTemplatesSortDirection: "desc",
  communicationTemplatesSortBy: "emailTemplateID",

  postingCommunicationTemplate: false,
  postedCommunicationTemplate: false,
  failedPostCommunicationTemplate: false,

  deletingCommunicationTemplate: false,
  deletedCommunicationTemplate: false,
  failedDeleteCommunicationTemplate: false,

  gettingCustomerGroups: false,
  gotCustomerGroups: false,
  failedGetCustomerGroups: false,
  customerGroups: [],
  customerGroupsSortBy: "customerGroupId",
  customerGroupsSortDirection: "asc",

  postingCustomerGroup: false,
  postedCustomerGroup: false,
  failedPostCustomerGroup: false,

  deletingCustomerGroup: false,
  deletedCustomerGroup: false,
  failedDeleteCustomerGroup: false,

  gettingCustomerGroupsFilters: false,
  gotCustomerGroupsFilters: false,
  failedGetCustomerGroupsFilters: false,
  customerGroupsFilters: [],

  postingCustomerGroupsPreview: false,
  postedCustomerGroupsPreview: false,
  failedPostCustomerGroupsPreview: false,
  customerGroupPreview: {},

  gettingCustomers: false,
  gotCustomers: false,
  failedGetCustomers: false,
  customers: {
    value: [],
    count: 0
  },

  customersSearchTerm: "",
  customersSearchResults: [],
  displayCustomersSearchResults: false,
  fetchingCustomersSearchResults: false,
  fetchedCustomersSearchResults: false,
  failedFetchCustomersSearchResults: false,

  gettingCommunicationTemplateDestinations: false,
  gotCommunicationTemplateDestinations: false,
  failedGetCommunicationTemplateDestinations: false,
  communicationTemplateDestinations: {
    email: [],
    voice: [],
    text: []
  },

  gettingCommunicationTemplateSnippets: false,
  gotCommunicationTemplateSnippets: false,
  failedGetCommunicationTemplateSnippets: false,
  communicationTemplateSnippets: {
    email: [],
    voice: [],
    text: []
  },

  gettingCommunicationTemplateAttachment: false,
  gotCommunicationTemplateAttachment: false,
  failedGetCommunicationTemplateAttachment: false,

  accountingGroupEmailConfigTestValue: {},
  //status: 'idle' | 'fetching' | 'succeeded' | 'failed',
  accountingGroupEmailConfigTestStatus: {}
};

const manageReducer = (state = defaultManageState, action) => {
  switch (action.type) {
    case actions.CREATING_EMAIL_REPLY_TEMPLATE:
      return { ...state, isCreatingEmailReplyTemplate: true };
    case actions.CREATED_EMAIL_REPLY_TEMPLATE:
      return { ...state, isCreatingEmailReplyTemplate: false };
    case actions.CREATE_EMAIL_REPLY_TEMPLATE_FAILED:
      return { ...state, isCreatingEmailReplyTemplate: false };

    case actions.GETTING_ALL_EMAIL_REPLY_TEMPLATES:
      return { ...state, isGettingAllEmailReplyTemplates: true };
    case actions.GOT_ALL_EMAIL_REPLY_TEMPLATES:
      return {
        ...state,
        isGettingAllEmailReplyTemplates: false,
        allCompanyReplyTemplatesIsFetched: {
          ...state.allCompanyReplyTemplatesIsFetched,
          [action.perspective]: true
        },
        allCompanyReplyTemplates: {
          ...state.allCompanyReplyTemplates,
          [action.perspective]: action.companyReplyTemplates
        }
      };
    case actions.GET_ALL_COMPANY_EMAIL_REPLY_TEMPLATES_FAILED:
      return {
        ...state,
        isGettingAllEmailReplyTemplates: false,
        allCompanyReplyTemplatesIsFetched: {
          ...state.allCompanyReplyTemplatesIsFetched,
          [action.perspective]: true
        }
      };
    case actions.CLEAR_ALL_REPLY_TEMPLATES:
      return {
        ...state,
        allCompanyReplyTemplates: defaultManageState.allCompanyReplyTemplates,
        allCompanyReplyTemplatesIsFetched: defaultManageState.allCompanyReplyTemplatesIsFetched
      };
    case actions.GETTING_ALL_EMAIL_CONFIG_TYPES:
      return { ...state, isGettingAllEmailConfigTypes: true };
    case actions.GOT_ALL_EMAIL_CONFIG_TYPES:
      return {
        ...state,
        isGettingAllEmailConfigTypes: false,
        allEmailConfigTypes: action.allEmailConfigTypes
      };

    case actions.IMPORTING_COMPANY_ADDRESS_BOOK:
      return { ...state, isImportingCompanyAddressBook: true };

    case actions.IMPORTED_COMPANY_ADDRESS_BOOK:
      return { ...state, importingCompanyAddressBookFailed: false, isImportingCompanyAddressBook: false };
    case actions.IMPORT_COMPANY_ADDRESS_BOOK_FAILED:
      return { ...state, importingCompanyAddressBookFailed: true, isImportingCompanyAddressBook: false };

    case actions.PUTTING_COMPANY_ADDRESS_BOOK:
      return {
        ...state,
        puttingAddressBookContact: true,
        putAddressBookContact: false,
        failedPutAddressBookContact: false
      };
    case actions.PUT_COMPANY_ADDRESS_BOOK:
      return {
        ...state,
        puttingAddressBookContact: false,
        putAddressBookContact: true,
        failedPutAddressBookContact: false
      };
    case actions.FAILED_PUT_COMPANY_ADDRESS_BOOK:
      return {
        ...state,
        puttingAddressBookContact: false,
        putAddressBookContact: false,
        failedPutAddressBookContact: true
      };

    case actions.POSTING_COMPANY_ADDRESS_BOOK:
      return {
        ...state,
        postingAddressBookContact: true,
        postedAddressBookContact: false,
        failedPostAddressBookContact: false
      };

    case actions.POST_COMPANY_ADDRESS_BOOK:
      return {
        ...state,
        postingAddressBookContact: false,
        postedAddressBookContact: true,
        failedPostAddressBookContact: false
      };

    case actions.FAILED_POST_COMPANY_ADDRESS_BOOK:
      return {
        ...state,
        postingAddressBookContact: false,
        postedAddressBookContact: false,
        failedPostAddressBookContact: true
      };

    case actions.FETCHING_COMPANY_ADDRESS_BOOK:
      return { ...state, isFetchingCompanyAddressBook: true };
    case actions.FETCHED_COMPANY_ADDRESS_BOOK:
      return {
        ...state,
        isFetchingCompanyAddressBook: false,
        fetchingCompanyAddressBookFailed: false,
        companyAddressBook: action.addresses
      };
    case actions.FETCH_COMPANY_ADDRESS_BOOK_FAILED:
      return {
        ...state,
        isFetchingCompanyAddressBook: false,
        fetchingCompanyAddressBookFailed: true
      };

    case actions.DELETING_COMPANY_ADDRESS_BOOK_CONTACT:
      return {
        ...state,
        deletingCompanyAddressBookContact: true,
        deletedCompanyAddressBookContact: false,
        failedDeletingCompanyAddressBookContact: false
      };
    case actions.DELETED_COMPANY_ADDRESS_BOOK_CONTACT:
      return {
        ...state,
        deletingCompanyAddressBookContact: false,
        deletedCompanyAddressBookContact: true,
        failedDeletingCompanyAddressBookContact: false,
        companyAddressBook: action.addresses
      };
    case actions.DELETE_COMPANY_ADDRESS_BOOK_CONTACT_FAILED:
      return {
        ...state,
        deletingCompanyAddressBookContact: false,
        deletedCompanyAddressBookContact: false,
        failedDeletingCompanyAddressBookContact: true
      };

    case actions.FETCHING_COMPANY_DOCUMENTS:
      return { ...state, isFetchingCompanyDocuments: true };
    case actions.FETCHED_COMPANY_DOCUMENTS:
      return {
        ...state,
        isFetchingCompanyDocuments: false,
        fetchingCompanyDocumentsFailed: {
          ...state.fetchingCompanyDocumentsFailed,
          [action.perspectiveId]: false
        },
        companyDocuments: {
          ...state.companyDocuments,
          [action.perspectiveId]: action.companyDocuments
        }
      };
    case actions.FETCH_COMPANY_DOCUMENTS_FAILED:
      return {
        ...state,
        isFetchingCompanyDocuments: false,
        fetchingCompanyDocumentsFailed: {
          ...state.fetchingCompanyDocumentsFailed,
          [action.perspectiveId]: true
        },
        companyDocuments: {
          ...state.companyDocuments,
          [action.perspectiveId]: action.companyDocuments
        }
      };

    case actions.CREATING_COMPANY_DOCUMENT:
      return { ...state, isCreatingCompanyDocument: true };
    case actions.CREATED_COMPANY_DOCUMENT:
      return { ...state, isCreatingCompanyDocument: false };
    case actions.CREATE_COMPANY_DOCUMENT_FAILED:
      return { ...state, isCreatingCompanyDocument: false };

    case actions.GET_ALL_EMAIL_CONFIG_TYPES_FAILED:
      return { ...state, isGettingAllEmailConfigTypes: false };
    case actions.SETTING_COMPANY_EMAIL_CONFIG:
      return { ...state, isSettingCompanyEmailConfigData: true };
    case actions.SET_COMPANY_EMAIL_CONFIG:
      return {
        ...state,
        isSettingCompanyEmailConfigData: false,
        newCompanyEmailConfigDataIsSet: true
      };
    case actions.SET_COMPANY_EMAIL_CONFIG_FAILED:
      return { ...state, isSettingCompanyEmailConfigData: false };

    case actions.GETTING_COMPANY_EMAIL_CONFIG:
      return { ...state, isGettingCompanyConfigData: true };
    case actions.GOT_COMPANY_EMAIL_CONFIG:
      return {
        ...state,
        companiesEmailConfigData: {
          ...state.companiesEmailConfigData,
          ...action.companyCurrentConfig
        },
        isGettingCompanyConfigData: false
      };
    case actions.GET_COMPANY_EMAIL_CONFIG_FAILED:
      return { ...state, isGettingCompanyConfigData: false };

    case actions.GETTING_COMPANY_EMAIL_CONFIG_TYPE:
      return { ...state, isGettingCompanyEmailConfigType: true };
    case actions.GOT_COMPANY_EMAIL_CONFIG_TYPE:
      return {
        ...state,
        isGettingCompanyEmailConfigType: false,
        companiesEmailConfigType: {
          ...state.companiesEmailConfigType,
          ...action.companyEmailConfigType
        }
      };
    case actions.GET_COMPANY_EMAIL_CONFIG_TYPE_FAILED:
      return { ...state, isGettingCompanyEmailConfigType: false };

    case actions.GETTING_COMPANY_EMAIL_CONFIG_VERIFICATION:
      return { ...state, isGettingCurrentEmailConfigVerification: true };
    case actions.GOT_COMPANY_EMAIL_CONFIG_VERIFICATION:
      return {
        ...state,
        isGettingCurrentEmailConfigVerification: false,
        isCurrentEmailConfigVerified: action.isCurrentEmailConfigVerified
      };
    case actions.GET_COMPANY_EMAIL_CONFIG_VERIFICATION_FAILED:
      return { ...state, isGettingCurrentEmailConfigVerification: false };

    case actions.GETTING_COMPANY_WORKFLOWS:
      return {
        ...state,
        gettingWorkflows: true,
        failedToGetWorkflows: false,
        gotWorkflows: false,
        companyId: action.companyId,
        workflows: {}
      };
    case actions.FAILED_GET_COMPANY_WORKFLOWS:
      return { ...state, gettingWorkflows: false, failedToGetWorkflows: true, gotWorkflows: false };
    case actions.GOT_COMPANY_WORKFLOWS:
      if (state.companyId !== action.companyId) {
        return state;
      }
      let workflows = { ...state.workflows };
      action.workflows.forEach(workflow => {
        let asJson = workflow.asJson;
        workflows[asJson.perspectiveId] = (workflows[asJson.perspectiveId] || []).concat(workflow);
      });
      return {
        ...state,
        gettingWorkflows: false,
        failedToGetWorkflows: false,
        gotWorkflows: true,
        workflows
      };

    case actions.PUTTING_COMPANY_WORKFLOW:
      return { ...state, puttingWorkflow: true, putWorkflow: false, failedToPutWorkflow: false };
    case actions.PUT_COMPANY_WORKFLOW:
      return { ...state, puttingWorkflow: false, putWorkflow: true, failedToPutWorkflow: false };
    case actions.FAILED_PUT_COMPANY_WORKFLOW:
      return { ...state, puttingWorkflow: false, putWorkflow: false, failedToPutWorkflow: true };

    case actions.DELETING_COMPANY_WORKFLOW:
      return { ...state, deletingWorkflow: true, deletedWorkflow: false, failedToDeleteWorkflow: false };
    case actions.DELETED_COMPANY_WORKFLOW:
      return { ...state, deletingWorkflow: false, deletedWorkflow: true, failedToDeleteWorkflow: false };
    case actions.FAILED_DELETE_COMPANY_WORKFLOW:
      return { ...state, deletingWorkflow: false, deletedWorkflow: false, failedToDeleteWorkflow: true };

    case actions.UPDATING_COMPANY_WORKFLOW:
      return { ...state, updatingWorkflow: true, updatedWorkflow: false, failedToUpdateWorkflow: false };
    case actions.UPDATED_COMPANY_WORKFLOW:
      return { ...state, updatingWorkflow: false, updatedWorkflow: true, failedToUpdateWorkflow: false };
    case actions.FAILED_UPDATE_COMPANY_WORKFLOW:
      return { ...state, updatingWorkflow: false, updatedWorkflow: false, failedToUpdateWorkflow: true };

    case actions.GETTING_COMMUNICATION_TEMPLATES:
      return {
        ...state,
        gettingCommunicationTemplates: true,
        gotCommunicationTemplates: false,
        failedGetCommunicationTemplates: false
      };
    case actions.GOT_COMMUNICATION_TEMPLATES:
      let emailTemplates = [];
      let phoneTemplates = [];
      let textTemplates = [];

      action.communicationTemplates.forEach(template => {
        if (template.emailTemplateStatus) {
          emailTemplates.push(template);
        }
        if (template.voiceMessageStatus) {
          phoneTemplates.push(template);
        }
        if (template.smsStatus) {
          textTemplates.push(template);
        }
      });

      return {
        ...state,
        gettingCommunicationTemplates: false,
        gotCommunicationTemplates: true,
        failedGetCommunicationTemplates: false,
        communicationTemplates: sortObjects(
          action.communicationTemplates,
          state.communicationTemplatesSortBy,
          state.communicationTemplatesSortDirection
        ),
        communicationTemplateTypeToTemplate: {
          email: emailTemplates,
          voice: phoneTemplates,
          text: textTemplates
        }
      };
    case actions.FAILED_GET_COMMUNICATION_TEMPLATES:
      return {
        ...state,
        gettingCommunicationTemplates: false,
        gotCommunicationTemplates: false,
        failedGetCommunicationTemplates: true
      };
    case actions.POSTING_COMMUNICATION_TEMPLATE:
      return {
        ...state,
        postingCommunicationTemplate: true,
        postedCommunicationTemplate: false,
        failedPostCommunicationTemplate: false
      };
    case actions.POSTED_COMMUNICATION_TEMPLATE:
      return {
        ...state,
        postingCommunicationTemplate: false,
        postedCommunicationTemplate: true,
        failedPostCommunicationTemplate: false,
        communicationTemplates: []
      };
    case actions.FAILED_POST_COMMUNICATION_TEMPLATE:
      return {
        ...state,
        postingCommunicationTemplate: false,
        postedCommunicationTemplate: false,
        failedPostCommunicationTemplate: true,
        communicationTemplates: []
      };
    case actions.DELETING_COMMUNICATION_TEMPLATE:
      return {
        ...state,
        deletingCommunicationTemplate: true,
        deletedCommunicationTemplate: false,
        failedDeleteCommunicationTemplate: false
      };
    case actions.DELETED_COMMUNICATION_TEMPLATE:
      return {
        ...state,
        deletingCommunicationTemplate: false,
        deletedCommunicationTemplate: true,
        failedDeleteCommunicationTemplate: false
      };
    case actions.FAILED_DELETE_COMMUNICATION_TEMPLATE:
      return {
        ...state,
        deletingCommunicationTemplate: false,
        deletedCommunicationTemplate: false,
        failedDeleteCommunicationTemplate: true
      };

    case actions.GETTING_CUSTOMER_GROUPS:
      return {
        ...state,
        gettingCustomerGroups: true,
        gotCustomerGroups: false,
        failedGetCustomerGroups: false,
        customerGroups: []
      };
    case actions.GOT_CUSTOMER_GROUPS:
      return {
        ...state,
        gettingCustomerGroups: false,
        gotCustomerGroups: true,
        failedGetCustomerGroups: false,
        customerGroups: [...action.customerGroups]
      };
    case actions.FAILED_GET_CUSTOMER_GROUPS:
      return {
        ...state,
        gettingCustomerGroups: false,
        gotCustomerGroups: false,
        failedGetCustomerGroups: true
      };

    case actions.POSTING_CUSTOMER_GROUP:
      return { ...state, postingCustomerGroup: true, postedCustomerGroup: false, failedPostCustomerGroup: false };
    case actions.POSTED_CUSTOMER_GROUP:
      return { ...state, postingCustomerGroup: false, postedCustomerGroup: true, failedPostCustomerGroup: false };
    case actions.FAILED_POST_CUSTOMER_GROUP:
      return { ...state, postingCustomerGroup: false, postedCustomerGroup: false, failedPostCustomerGroup: true };

    case actions.DELETING_CUSTOMER_GROUP:
      return { ...state, deletingCustomerGroup: true, deletedCustomerGroup: false, failedDeleteCustomerGroup: false };
    case actions.DELETED_CUSTOMER_GROUP:
      return { ...state, deletingCustomerGroup: false, deletedCustomerGroup: true, failedDeleteCustomerGroup: false };
    case actions.FAILED_DELETE_CUSTOMER_GROUP:
      return { ...state, deletingCustomerGroup: false, deletedCustomerGroup: false, failedDeleteCustomerGroup: true };

    case actions.GETTING_CUSTOMER_GROUPS_FILTERS:
      return {
        ...state,
        gettingCustomerGroupsFilters: true,
        gotCustomerGroupsFilters: false,
        failedGetCustomerGroupsFilters: false
      };
    case actions.GOT_CUSTOMER_GROUPS_FILTERS:
      return {
        ...state,
        gettingCustomerGroupsFilters: false,
        gotCustomerGroupsFilters: true,
        failedGetCustomerGroupsFilters: false,
        customerGroupsFilters: [...action.filters]
      };
    case actions.FAILED_GET_CUSTOMER_GROUPS_FILTERS:
      return {
        ...state,
        gettingCustomerGroupsFilters: false,
        gotCustomerGroupsFilters: false,
        failedGetCustomerGroupsFilters: true,
        customerGroupsFilters: []
      };

    case actions.POSTING_CUSTOMER_GROUPS_PREVIEW:
      return {
        ...state,
        postingCustomerGroupsPreview: true,
        postedCustomerGroupsPreview: false,
        failedPostCustomerGroupsPreview: false,
        customerGroupPreview: {}
      };
    case actions.POSTED_CUSTOMER_GROUPS_PREVIEW:
      return {
        ...state,
        postingCustomerGroupsPreview: false,
        postedCustomerGroupsPreview: true,
        failedPostCustomerGroupsPreview: false,
        customerGroupPreview: action.customerGroupPreview
      };
    case actions.FAILED_POST_CUSTOMER_GROUPS_PREVIEW:
      return {
        ...state,
        postingCustomerGroupsPreview: false,
        postedCustomerGroupsPreview: false,
        failedPostCustomerGroupsPreview: true
      };

    case actions.GETTING_CUSTOMERS:
      return {
        ...state,
        gettingCustomers: true,
        gotCustomers: false,
        failedGetCustomers: false,
        customers: { value: [], count: 0 }
      };
    case actions.GOT_CUSTOMERS:
      return {
        ...state,
        gettingCustomers: false,
        gotCustomers: true,
        failedGetCustomers: false,
        customers: { value: [...action.customers], count: action.count }
      };
    case actions.FAILED_GET_CUSTOMERS:
      return {
        ...state,
        gettingCustomers: false,
        gotCustomers: false,
        failedGetCustomers: true
      };

    case actions.UPDATE_CUSTOMER_GROUPS_SORT:
      return {
        ...state,
        customerGroups: [...action.customerGroups],
        customerGroupsSortBy: action.sortBy,
        customerGroupsSortDirection: action.sortDirection
      };

    case actions.CLEAR_CUSTOMERS_SEARCH_RESULTS:
      return {
        ...state,
        customersSearchTerm: "",
        customersSearchResults: [],
        displayCustomersSearchResults: false,
        fetchingCustomersSearchResults: false,
        fetchedCustomersSearchResults: false,
        failedFetchCustomersSearchResults: false
      };
    case actions.DISPLAY_CUSTOMERS_SEARCH_RESULTS:
      return { ...state, displayCustomersSearchResults: true };
    case actions.FETCHING_CUSTOMERS_SEARCH_RESULTS:
      return {
        ...state,
        customersSearchTerm: action.searchTerm,
        fetchingCustomersSearchResults: true,
        fetchedCustomersSearchResults: false,
        failedFetchCustomersSearchResults: false
      };
    case actions.FETCHED_CUSTOMERS_SEARCH_RESULTS:
      if (state.customersSearchTerm !== action.searchTerm) {
        return { ...state };
      }
      return {
        ...state,
        customersSearchTerm: action.searchTerm,
        customersSearchResults: action.searchResults,
        fetchingCustomersSearchResults: false,
        fetchedCustomersSearchResults: true,
        failedFetchCustomersSearchResults: false
      };
    case actions.FETCH_CUSTOMERS_SEARCH_RESULTS_FAILED:
      return {
        ...state,
        fetchingCustomersSearchResults: false,
        fetchedCustomersSearchResults: false,
        failedFetchCustomersSearchResults: true
      };

    case actions.GETTING_COMMUNICATION_TEMPLATE_DESTINATIONS:
      return {
        ...state,
        gettingCommunicationTemplateDestinations: true,
        gotCommunicationTemplateDestinations: false,
        failedGetCommunicationTemplateDestinations: false
      };
    case actions.GOT_COMMUNICATION_TEMPLATE_DESTINATIONS:
      let newDestinations = {};
      action.destinations.forEach(destination => {
        newDestinations[destination.templateType] !== undefined
          ? newDestinations[destination.templateType].push(destination)
          : (newDestinations[destination.templateType] = [destination]);
      });
      return {
        ...state,
        gettingCommunicationTemplateDestinations: false,
        gotCommunicationTemplateDestinations: true,
        failedGetCommunicationTemplateDestinations: false,
        communicationTemplateDestinations: newDestinations
      };
    case actions.FAILED_GET_COMMUNICATION_TEMPLATE_DESTINATIONS:
      return {
        ...state,
        gettingCommunicationTemplateDestinations: false,
        gotCommunicationTemplateDestinations: false,
        failedGetCommunicationTemplateDestinations: true
      };

    case actions.GETTING_COMMUNICATION_TEMPLATE_SNIPPETS:
      return {
        ...state,
        gettingCommunicationTemplateSnippets: true,
        gotCommunicationTemplates: false,
        failedGetCommunicationTemplates: false
      };
    case actions.GOT_COMMUNICATION_TEMPLATE_SNIPPETS:
      let newSnippets = {};
      action.snippets.forEach(snippet => {
        newSnippets[snippet.templateType] !== undefined
          ? newSnippets[snippet.templateType].push(snippet)
          : (newSnippets[snippet.templateType] = [snippet]);
      });
      return {
        ...state,
        gettingCommunicationTemplateSnippets: false,
        gotCommunicationTemplates: true,
        failedGetCommunicationTemplates: false,
        communicationTemplateSnippets: newSnippets
      };
    case actions.FAILED_GET_COMMUNICATION_TEMPLATE_SNIPPETS:
      return {
        ...state,
        gettingCommunicationTemplateSnippets: false,
        gotCommunicationTemplateSnippets: false,
        failedGetCommunicationTemplateSnippets: true
      };
    case actions.UPDATE_COMMUNICATION_TEMPLATES_SORT:
      return {
        ...state,
        communicationTemplates: sortObjects(state.communicationTemplates, action.sortBy, action.sortDirection),
        communicationTemplatesSortBy: action.sortBy,
        communicationTemplatesSortDirection: action.sortDirection,
        sortByType: action.sortByType
      };

    case actions.GETTING_COMMUNICATION_TEMPLATE_ATTACHMENT:
      return {
        ...state,
        gettingCommunicationTemplateAttachment: true,
        gotCommunicationTemplateAttachment: false,
        failedGetCommunicationTemplateAttachment: false
      };
    case actions.GOT_COMMUNICATION_TEMPLATE_ATTACHMENT:
      return {
        ...state,
        gettingCommunicationTemplateAttachment: false,
        gotCommunicationTemplateAttachment: true,
        failedGetCommunicationTemplateAttachment: false
      };
    case actions.FAILED_GET_COMMUNICATION_TEMPLATE_ATTACHMENT:
      return {
        ...state,
        gettingCommunicationTemplateAttachment: false,
        gotCommunicationTemplateAttachment: false,
        failedGetCommunicationTemplateAttachment: true
      };
    case actions.GETTING_EMAIL_CONFIG_TEST:
      return {
        ...state,
        accountingGroupEmailConfigTestStatus: {
          ...state.accountingGroupEmailConfigTestStatus,
          [action.accountingGroupId]: "loading"
        }
      };
    case actions.GOT_EMAIL_CONFIG_TEST:
      return {
        ...state,
        accountingGroupEmailConfigTestStatus: {
          ...state.accountingGroupEmailConfigTestStatus,
          [action.accountingGroupId]: "succeeded"
        },
        accountingGroupEmailConfigTestValue: {
          ...state.accountingGroupEmailConfigTestValue,
          [action.accountingGroupId]: action.emailConfigTest
        }
      };
    case actions.FAILED_GET_EMAIL_CONFIG_TEST:
      return {
        ...state,
        accountingGroupEmailConfigTestStatus: {
          ...state.accountingGroupEmailConfigTestStatus,
          [action.accountingGroupId]: "failed"
        }
      };

    case actions.CLEAR_MANAGE_IS_SET_DATA:
      return { ...state, [action.nameOfStateVar]: false };

    case actions.SELECT_COMPANY:
      return { ...defaultManageState, companyId: action.companyId };

    default:
      return state;
  }
};

export default manageReducer;
