import React, { Component } from "react";
import { connect } from "react-redux";

import Resources from "../lib/resources";
import MenuItem from "./menuItem";
import SearchInput from "./searchInput";
import FlexibleScrollContainer from "./flexibleScrollContainer";
import CompanyRefresh from "./companyRefresh";

import { isEmpty, includes, isGuid } from "../lib/utils";
import Icons from "../lib/icons";

import { dispatchToProps as caDP } from "../store/conversations-actions";
import { dispatchToProps as aaDP } from "../store/accounts-actions";
import { dispatchToProps as manDP } from "../store/manage-actions";
import { dispatchToProps as ledgDP } from "../store/ledger-actions";
import { dispatchToProps as congDP, SortDirection, SortBy } from "../store/contextGroups-actions";

const dispatchToProps = dispatch => {
  return {
    ...caDP(dispatch),
    ...aaDP(dispatch),
    ...manDP(dispatch),
    ...ledgDP(dispatch),
    ...congDP(dispatch)
  };
};

class CompanyBrowser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: "",
      contextGroupsToDisplay: 30,
      loadMoreContextGroupsIncrement: 20
    };

    this.applyFilter = this.applyFilter.bind(this);
    this.loadMore = this.loadMore.bind(this);
  }

  componentDidMount() {
    const companyId = this.props.match.params.companyId;
    const perspectiveId = this.props.match.params.perspectiveId;
    this.props.fetchCompanyWorkflows(companyId);
    if (isGuid(perspectiveId)) {
      this.props.getReminders(companyId, perspectiveId);
      this.props.fetchContextGroups(companyId, perspectiveId);
    }
  }

  componentDidUpdate(prevProps) {
    const companyId = this.props.match.params.companyId;
    const prevCompanyId = prevProps.match.params.companyId;
    const perspectiveId = this.props.match.params.perspectiveId;

    if (companyId !== prevCompanyId) {
      this.props.fetchCompanyWorkflows(companyId);
      this.props.getReminders(companyId, perspectiveId);
      this.props.fetchContextGroups(companyId, perspectiveId);
    }
  }

  getRoute(view, selectedContextGroupId) {
    let {
      match,
      match: { params }
    } = this.props;

    let parts = match.url.split("/");
    if (isEmpty(params.selectedItemId) === false) {
      parts.pop();
    }
    if (isEmpty(params.selectedContextGroupId) === false) {
      parts.pop();
    }
    if (isEmpty(params.view) === false) {
      parts.pop();
    }
    parts.push(view);
    if (isEmpty(selectedContextGroupId) === false) {
      parts.push(selectedContextGroupId);
    }
    return parts.join("/");
  }

  loadMore(maxValue) {
    let newValue = this.state.contextGroupsToDisplay + this.state.loadMoreContextGroupsIncrement;
    if (newValue > maxValue && maxValue !== 0) {
      newValue = maxValue;
    }
    this.setState({
      contextGroupsToDisplay: newValue
    });
  }

  render() {
    const {
      contextGroupsStore,
      location,
      match: { params }
    } = this.props;

    const contextGroups = contextGroupsStore.value;

    let unassignedActive = 0;
    let mineActive = 0;
    let assignedActive = 0;
    let pendingApproval = 0;
    let waitingResponse = 0;
    let reminders = this.props.conversations.reminders.count;

    let unassignedHasReminder = false;
    let mineHasReminder = false;
    let assignedHasReminder = false;
    let pendingApprovalHasReminder = false;
    let waitingResponseHasReminder = false;

    let filteredContextGroups = [];

    for (let i = 0; i < contextGroups.length; i++) {
      let contextGroup = contextGroups[i];
      if (contextGroup.isSpam) {
        continue;
      }
      if (
        (this.props.contextGroupsStore.contextGroupsSortBy === SortBy.Hidden) === contextGroup.isHidden &&
        includes(contextGroup.companyName.toLowerCase(), this.state.filter.toLowerCase())
      ) {
        filteredContextGroups.push(contextGroup);
      }
      if (contextGroup.isHidden) {
        continue;
      }
      const ac = contextGroup.activeCounts || {};
      unassignedActive += ac.unassigned || 0;
      mineActive += ac.assignedToMe || 0;
      assignedActive += ac.assignedToOthers || 0;
      pendingApproval += ac.pendingApproval || 0;
      waitingResponse += ac.waitingForResponse || 0;

      unassignedHasReminder += ac.hasUnassignedReminders;
      mineHasReminder += ac.hasAssignedToMeReminders;
      assignedHasReminder += ac.hasAssignedToOthersReminders;
      pendingApprovalHasReminder += ac.hasPendingApprovalReminders;
      waitingResponseHasReminder += ac.hasWaitingForResponseReminders;
    }

    const rootRoute = location.pathname.slice(0, location.pathname.lastIndexOf(`/${params.selectedContextGroupId}`));
    return (
      <div>
        <FlexibleScrollContainer
          offsetHeight={this.props.contextGroupsStore.gettingContextGroups === true ? 55 : 0}
          onLoadMore={e => this.loadMore(filteredContextGroups.length)}
          maxRows={filteredContextGroups.length}
          loaded={this.state.contextGroupsToDisplay}
          hideLoadMore
          noFooter
        >
          <div className="inbox-container">
            <MenuItem
              to={`${rootRoute}/unassigned`}
              iconClasses={Icons.unassigned}
              label={Resources.Unassigned}
              labelBold={true}
              activeCount={unassignedActive}
              active={params.selectedContextGroupId === "unassigned"}
              badgeClassName={unassignedHasReminder ? "badge-reminder" : ""}
            />
            <MenuItem
              to={`${rootRoute}/mine`}
              iconClasses={Icons.mine}
              label={Resources.Mine}
              labelBold={true}
              activeCount={mineActive}
              active={params.selectedContextGroupId === "mine"}
              badgeClassName={mineHasReminder ? "badge-reminder" : ""}
            />
            <MenuItem
              to={`${rootRoute}/assigned`}
              iconClasses={Icons.assigned}
              label={Resources.Assigned}
              labelBold={true}
              activeCount={assignedActive}
              active={params.selectedContextGroupId === "assigned"}
              badgeClassName={assignedHasReminder ? "badge-reminder" : ""}
            />
            <MenuItem
              to={`${rootRoute}/waiting-for-response`}
              iconClasses={Icons.hourglass}
              label={Resources.WaitingResponse}
              labelBold={true}
              activeCount={waitingResponse}
              active={params.selectedContextGroupId === "assigned"}
              badgeClassName={waitingResponseHasReminder ? "badge-reminder" : "badge-transparent"}
            />
            <MenuItem
              to={`${rootRoute}/pending-approval`}
              iconClasses={Icons.pendingApproval}
              label={Resources.OutForApproval}
              labelBold={true}
              activeCount={pendingApproval}
              active={params.selectedContextGroupId === "pending-approval"}
              badgeClassName={pendingApprovalHasReminder ? "badge-reminder" : ""}
            />
            <MenuItem
              to={`${rootRoute}/reminder`}
              iconClasses={Icons.bell}
              label={Resources.Reminders}
              labelBold={true}
              activeCount={reminders}
              active={params.selectedContextGroupId === "reminder"}
              badgeClassName="badge-transparent"
            />
            <MenuItem
              to={`${rootRoute}/closed`}
              iconClasses={Icons.closed}
              label={Resources.Closed}
              labelBold={true}
              active={params.selectedContextGroupId === "closed"}
            />
            <MenuItem
              to={`${rootRoute}/spam`}
              iconClasses={Icons.spam}
              label={Resources.Spam}
              labelBold={true}
              active={params.selectedContextGroupId === "spam"}
            />

            <span>
              <button className="compose-button dropdown" data-toggle="dropdown" aria-haspopup aria-expanded="false">
                {Resources.CreateNew}
              </button>
              <span className="dropdown-menu dropdown-menu-right">
                <span
                  className="dropdown-item"
                  onClick={e => {
                    const path = `${rootRoute.replace(params.view, "activities")}/${
                      params.selectedContextGroupId
                    }/new/conversation`;
                    this.props.history.push(path);
                  }}
                >
                  {Resources.Conversation}
                </span>
                <span
                  className="dropdown-item"
                  onClick={e => {
                    const path = `${rootRoute.replace(params.view, "activities")}/${
                      params.selectedContextGroupId
                    }/new/task`;
                    this.props.history.push(path);
                  }}
                >
                  {Resources.Task}
                </span>
              </span>
            </span>
          </div>

          <div id="vendor-list">
            <div className="dropdown" id="sortby-dropdown">
              <span
                role="button"
                className="d-inline-block w-100"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {Resources.SortBy}{" "}
                <span className="font-weight-bold">{this.props.getContextGroupsSortByDisplayName()}</span>
                <span className={Icons.dropdown} />
              </span>
              <span className="dropdown-menu">
                <div
                  className="dropdown-item"
                  onClick={e => {
                    this.setSort(e, SortBy.Name, SortDirection.Descending);
                  }}
                >
                  {Resources.NameDescending}
                </div>
                <div
                  className="dropdown-item"
                  onClick={e => {
                    this.setSort(e, SortBy.Name, SortDirection.Ascending);
                  }}
                >
                  {Resources.NameAscending}
                </div>
                <div
                  className="dropdown-item"
                  onClick={e => {
                    this.setSort(e, SortBy.ActiveCount, SortDirection.Descending);
                  }}
                >
                  {Resources.LargestActiveCount}
                </div>
                <div
                  className="dropdown-item"
                  onClick={e => {
                    this.setSort(e, SortBy.ActiveCount, SortDirection.Ascending);
                  }}
                >
                  {Resources.SmallestActiveCount}
                </div>
                <div
                  className="dropdown-item"
                  onClick={e => {
                    this.setSort(e, SortBy.MostRecent, SortDirection.Descending);
                  }}
                >
                  {Resources.NewestToOldest}
                </div>
                <div
                  className="dropdown-item"
                  onClick={e => {
                    this.setSort(e, SortBy.MostRecent, SortDirection.Ascending);
                  }}
                >
                  {Resources.OldestToNewest}
                </div>
                <div
                  className="dropdown-item"
                  onClick={e => {
                    this.setSort(e, SortBy.Hidden, SortDirection.Ascending);
                  }}
                >
                  {Resources.Hidden}
                </div>
              </span>
            </div>

            <SearchInput placeholder={Resources.Filter} handleChange={this.applyFilter} />

            {filteredContextGroups.length > 0 &&
              params.perspectiveId === this.props.contextGroupsStore.perspectiveId &&
              filteredContextGroups.map((contextGroup, i) => {
                let contextGroupActiveCount =
                  contextGroup.activeCounts.unassigned +
                  contextGroup.activeCounts.assignedToMe +
                  contextGroup.activeCounts.assignedToOthers;
                if (i >= this.state.contextGroupsToDisplay) {
                  return null;
                }
                return (
                  <div key={contextGroup.companyId}>
                    <MenuItem
                      // iconClasses={Icons.genericCompany}
                      active={contextGroup.contextGroupId === params.selectedContextGroupId}
                      to={`${rootRoute}/${contextGroup.contextGroupId}`}
                      activeCount={contextGroupActiveCount}
                      label={contextGroup.displayName || Resources.Unknown}
                      iconClasses={
                        contextGroup.isNew
                          ? Icons.activeConversation + " new-company-icon"
                          : Icons.activeConversation + " icon-hidden"
                      }
                    />
                  </div>
                );
              })}
          </div>
        </FlexibleScrollContainer>
        <CompanyRefresh perspectiveId={params.perspectiveId} />
      </div>
    );
  }

  setSort(e, sortBy, sortDirection) {
    e.stopPropagation();
    this.props.setContextGroupsSort(sortBy, sortDirection);
  }

  applyFilter(e) {
    e.stopPropagation();
    if (isEmpty(this.interval) === false) {
      clearInterval(this.interval);
    }
    this.interval = setInterval(
      value => {
        clearInterval(this.interval);
        this.interval = undefined;
        this.setState({
          filter: value
        });
      },
      250,
      e.target.value
    );
  }
}

const storeToProps = store => {
  return {
    user: store.user,
    accounts: store.accounts,
    conversations: store.conversations,
    workflows: store.manage.workflows,
    contextGroupsStore: store.contextGroups
  };
};

export default connect(
  storeToProps,
  dispatchToProps
)(CompanyBrowser);
