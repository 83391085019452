import React, { Component } from "react";

class ReportsNoData extends Component {
  render() {
    const { height, reportType } = this.props;

    return (
      <div
        className="text-right"
        style={{
          height: height,
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <h3 className="fw-500 reports-noData">No {reportType} report data for this time range.</h3>
      </div>
    );
  }
}

export default ReportsNoData;
