import { actions } from "./registration-actions";

const defaultRegistrationState = {
  companyInfo: {},
  errorMsgs: [],
  emailForwardingToAddress: "",
  emailForwardingFromAddress: "",

  inviteData: {},
  isGettingInviteData: false,

  isSettingEmailForwardingAddress: false,

  isSettingUserAccount: false,
  doesAccountExists: false,
  isSignUpSuccessfulAndComplete: false,
  signUpCustomerId: "",

  isGettingAuthToken: false,
  appJsonHeadersWithBToken: "",
  idToken: ""
};

const registrationReducer = (state = defaultRegistrationState, action) => {
  switch (action.type) {
    case actions.SET_COMPANY_REGISTRATION_INFO:
      return { ...state, companyInfo: action.companyInfo };
    case actions.CREATE_USER_ACCOUNT_FAILED:
      return { ...state };
    case actions.SETTING_EMAIL_FORWARDING_ADDRESS:
      return { ...state, isSettingEmailForwardingAddress: true };
    case actions.EMAIL_FORWARDING_ADDRESS_SET:
      return {
        ...state,
        isSettingEmailForwardingAddress: false,
        emailForwardingToAddress: action.emailForwardingToAddress,
        emailForwardingFromAddress: action.emailForwardingFromAddress
      };
    case actions.SET_EMAIL_FORWARDING_ADDRESS_FAILED:
      return { ...state, isSettingEmailForwardingAddress: false };
    case actions.GETTING_INVITE_DATA:
      return { ...state, isGettingInviteData: true };
    case actions.GOT_INVITE_DATA:
      return {
        ...state,
        isGettingInviteData: false,
        inviteData: action.inviteData
      };
    case actions.GET_INVITE_DATA_FAILED:
      return { ...state, isGettingInviteData: false };
    case actions.ACCOUNT_IS_BEING_CREATED:
      return {
        ...state,
        isSettingUserAccount: true,
        doesAccountExists: false,
        isSignUpSuccessfulAndComplete: false
      };
    case actions.ACCOUNT_HAS_BEEN_CREATED:
      return {
        ...state,
        isSettingUserAccount: false,
        doesAccountExists: false,
        isSignUpSuccessfulAndComplete: true,
        signUpCustomerId: action.signUpCustomerId
      };
    case actions.ACCOUNT_ALREADY_EXISTS:
      return {
        ...state,
        isSettingUserAccount: false,
        doesAccountExists: true,
        isSignUpSuccessfulAndComplete: false
      };
    case actions.ACCOUNT_CREATION_FAILED:
      return {
        ...state,
        isSettingUserAccount: false,
        doesAccountExists: false,
        isSignUpSuccessfulAndComplete: false
      };
    case actions.GETTING_AUTH_TOKEN:
      return { ...state, isGettingAuthToken: true };
    case actions.GOT_AUTH_TOKEN:
      return {
        ...state,
        isGettingAuthToken: false,
        idToken: action.idToken,
        appJsonHeadersWithBToken: action.appJsonHeadersWithBToken
      };
    case actions.GET_AUTH_TOKEN_FAILED:
      return { ...state, isGettingAuthToken: false };
    case actions.TRIGGER_REGISTRATION_ERRORS:
      return { ...state, errorMsgs: action.errorMsgs };
    case actions.CLEAR_REGISTRATION_ERRORS:
      return { ...state, errorMsgs: [] };
    case actions.RESET_REGISTRATION_STATE:
      return { ...defaultRegistrationState };
    default:
      return state;
  }
};

export default registrationReducer;
