import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink, generatePath, withRouter } from "react-router-dom";

import Resources from "../../lib/resources";
import { isGuid, includes, find } from "../../lib/utils";

import ExpandableNavLink from "../library/expandableNavLink";

import IconHouse from "../library/icons/iconHouse";
import IconList from "../library/icons/iconList";
import IconGlobe from "../library/icons/iconGlobe";

import { dispatchToProps as congDP } from "../../store/contextGroups-actions";
import { dispatchToProps as paDP } from "../../store/perspectives-actions";
import { dispatchToProps as manDP } from "../../store/manage-actions";
import { isEmpty } from "lodash";

const dispatchToProps = dispatch => ({
  ...congDP(dispatch),
  ...manDP(dispatch),
  ...paDP(dispatch)
});

class ViewSelector extends Component {
  componentDidMount() {
    const companyId = this.props.match.params.companyId;
    const perspectiveId = this.props.match.params.perspectiveId;
    this.props.fetchCompanyWorkflows(companyId);
    if (isGuid(perspectiveId)) {
      this.props.getReminders(companyId, perspectiveId);
      this.props.fetchContextGroups(companyId, perspectiveId);
    }
  }

  componentDidUpdate(prevProps) {
    const companyId = this.props.match.params.companyId;
    const prevCompanyId = prevProps.match.params.companyId;
    const perspectiveId = this.props.match.params.perspectiveId;

    if (companyId !== prevCompanyId) {
      this.props.fetchCompanyWorkflows(companyId);
      this.props.getReminders(companyId, perspectiveId);
      this.props.fetchContextGroups(companyId, perspectiveId);
    }
  }

  views = () => [
    {
      viewRoute: "home",
      display:
        this.props.perspectivesStore.selectedPerspectiveType === "vendors" ||
        this.props.perspectivesStore.selectedPerspectiveType === "customers",
      itemDescription: Resources.Home,
      itemIcon: <IconHouse />
    },
    {
      viewRoute: "activities",
      itemDescription: Resources.Activities,
      itemIcon: <IconList />,
      expandedDefault: true,
      subViews: [
        { viewRoute: "mine", itemDescription: Resources.Mine },
        { viewRoute: "unassigned", itemDescription: Resources.Unassigned },
        { viewRoute: "assigned", itemDescription: Resources.Assigned },
        { viewRoute: "waiting-for-response", itemDescription: Resources.WaitingResponse },
        //{ viewRoute: "pending-approval", itemDescription: Resources.OutForApproval },
        { viewRoute: "snoozed", itemDescription: Resources.Snoozed },
        { viewRoute: "closed", itemDescription: Resources.Closed },
        { viewRoute: "spam", itemDescription: Resources.Spam }
      ]
    },
    {
      viewRoute: "network",
      display:
        this.props.perspectivesStore.selectedPerspectiveType === "vendors" ||
        this.props.perspectivesStore.selectedPerspectiveType === "customers",
      itemDescription: Resources.MyNetwork,
      itemIcon: <IconGlobe />,
      subViews: [
        {
          itemDescription: Resources.AllAccountsAndContacts,
          generatePath: (path, params) => generatePath(path, { ...params, view: "network", subView: "accounts" }),
          exact: true
        },
        ...this.props.contextGroups
          .slice(0, 50)
          .filter(
            contextGroup =>
              contextGroup.companyLocalKey && contextGroup.companyLocalKey !== "00000000-0000-0000-0000-000000000000"
          )
          .map((contextGroup, i) => {
            return {
              itemDescription: contextGroup.companyName,
              tooltip: true,
              companyId: contextGroup.displayName,
              viewRoute: contextGroup.companyLocalKey,
              generatePath: (path, params) =>
                generatePath(path + "/:accountId", {
                  ...params,
                  view: "network",
                  subView: "accounts",
                  accountId: contextGroup.companyLocalKey
                })
            };
          })
          .filter((item, i, self) => {
            if (!item) {
              return false;
            }
            return (
              self.findIndex(item2 => {
                if (!item2) {
                  return false;
                }
                return (
                  item2.generatePath(this.props.match.path, this.props.match.params) ===
                  item.generatePath(this.props.match.path, this.props.match.params)
                );
              }) === i
            );
          })
      ]
    }
  ];

  getBadgeType(contextGroupName) {
    const {
      contextGroupsStats,
      perspectivesStore: { selectedPerspectiveId }
    } = this.props;

    return ((contextGroupsStats[selectedPerspectiveId] || {}).badgeTypes || {})[contextGroupName] || "info";
  }

  getBadgeCount(contextGroupName) {
    const {
      contextGroupsStats,
      perspectivesStore: { selectedPerspectiveId }
    } = this.props;

    return ((contextGroupsStats[selectedPerspectiveId] || {}).summaryCounts || {})[contextGroupName] || 0;
  }

  render() {
    let path = this.props.match.path;
    const companyName = this.props.getDefaultCompany().companyName;
    const perspectiveType = this.props.perspectivesStore.selectedPerspectiveType || "";
    const selectedPerspective = this.props.perspectivesStore.selectedPerspective || {};
    const perspectives = [
      { value: "vendors", perspective: Resources.AccountsPayable.toLocaleUpperCase() },
      { value: "customers", perspective: Resources.AccountsReceivable.toLocaleUpperCase() }
    ];
    return (
      <div className="view-selector">
        <div className="view-selector-company-name">{companyName}</div>
        <div className="view-selector-perspective-name">
          {(find(perspectives, perspective => perspective.value === perspectiveType) || {}).perspective ||
            (selectedPerspective.perspectiveName || "").toLocaleUpperCase()}
        </div>
        {this.views().map(view => {
          if (view.display === false) {
            return null;
          }
          let toUrl = generatePath(path, { ...this.props.match.params, view: view.viewRoute, subView: null });
          let link = (
            <NavLink
              className={`view-selector-nav-item`}
              to={toUrl}
              activeClassName="active"
              key={view.itemDescription}
              isActive={() => {
                if (!this.props.match) {
                  return false;
                }

                return this.props.match.params.view === view.viewRoute;
              }}
            >
              <span className="view-selector-nav-item__icon">{view.itemIcon}</span>
              <span className="view-selector-nav-item__content">{view.itemDescription}</span>
            </NavLink>
          );
          if (view.subViews && !isEmpty(view.subViews)) {
            link = (
              <ExpandableNavLink
                to={toUrl}
                icon={view.itemIcon}
                description={view.itemDescription}
                badgeCount={this.getBadgeCount(view.viewRoute)}
                badgeType={this.getBadgeType(view.viewRoute)}
                isActive={() => includes(this.props.match.url, toUrl)}
                startExpanded={view.expandedDefault}
                key={view.itemDescription}
              >
                {view.subViews.map(subView => {
                  let subViewToUrl = subView.generatePath
                    ? subView.generatePath(path, this.props.match.params)
                    : generatePath(path, {
                        ...this.props.match.params,
                        view: view.viewRoute,
                        subView: subView.viewRoute
                      });
                  return (
                    <div className={subView.tooltip ? "tooltip-container" : ""}>
                      <NavLink
                        className={`view-selector-nav-item small`}
                        to={subViewToUrl}
                        activeClassName="active"
                        key={subView.itemDescription + this.getBadgeCount(subView.viewRoute)}
                        isActive={() => {
                          if (!this.props.match) {
                            return false;
                          }

                          return (
                            this.props.match.params.view === view.viewRoute &&
                            (this.props.match.params.subView === subView.viewRoute ||
                              (subView.generatePath &&
                                (subView.exact
                                  ? this.props.location.pathname ===
                                    subView.generatePath(this.props.match.path, this.props.match.params)
                                  : includes(
                                      this.props.location.pathname,
                                      subView.generatePath(this.props.match.path, this.props.match.params)
                                    ))))
                          );
                        }}
                      >
                        <span className="view-selector-nav-item__icon">{subView.itemIcon}</span>
                        <span
                          className={
                            subView.itemDescription === "All accounts and contacts"
                              ? "view-selector-nav-item__content-no-truncate"
                              : "view-selector-nav-item__content overflow-ellipsis"
                          }
                        >
                          {subView.itemDescription}
                        </span>
                        {this.getBadgeCount(subView.viewRoute) !== 0 && (
                          <div
                            className={
                              "view-selector-nav-item__badge" +
                              " view-selector-nav-item__badge--" +
                              this.getBadgeType(subView.viewRoute)
                            }
                          >
                            {this.getBadgeCount(subView.viewRoute)}
                          </div>
                        )}
                        <span className="view-selector-nav-item__badge__spacer"></span>
                      </NavLink>
                      {subView.tooltip ? <div className="navbar-tooltip">{subView.companyId}</div> : null}
                    </div>
                  );
                })}
              </ExpandableNavLink>
            );
          }
          return link;
        })}
      </div>
    );
  }
}

const storeToProps = store => {
  return {
    contextGroups: store.contextGroups.value,
    reminders: store.conversations.reminders,
    perspectivesStore: store.perspectives,
    contextGroupsStats: store.contextGroups.contextGroupsStats
  };
};

export default withRouter(connect(storeToProps, dispatchToProps)(ViewSelector));
