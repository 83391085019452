import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Form, FormGroup, Label, Input } from "reactstrap";
import { getConfig } from "../lib/utils";
import Resources from "../lib/resources";
import Icons from "../lib/icons";

import { dispatchToProps as uaDP } from "../store/user-actions";
import { dispatchToProps as aaDP } from "../store/accounts-actions";

const dispatchToProps = dispatch => ({
  ...uaDP(dispatch),
  ...aaDP(dispatch)
});

class LoginForm extends Component {
  constructor(props) {
    super(props);
    let config = getConfig();
    this.state = {
      usernameInput: config.userName || "",
      passwordInput: config.password || ""
    };
  }

  componentDidMount() {
    let savedUsername = window.localStorage.getItem("personalEmail");
    if (savedUsername) {
      this.setState({ usernameInput: savedUsername });
    }
  }

  onInput = e =>
    this.setState({
      [e.target.id]: e.target.value
    });

  submitOnEnter = e => {
    if (e.key === "Enter") {
      this.submitLogin(e);
    }
  };

  submitLogin = e => {
    e.preventDefault();
    window.localStorage.setItem("personalEmail", this.state.usernameInput);
    this.props.login(this.state.usernameInput, this.state.passwordInput);
  };

  componentDidUpdate(prevProps) {
    let { user, loginSuccessPath } = this.props;
    if (user.isLoggedIn) {
      // Let companySelect component at root path handle rerouting
      this.props.history.push(loginSuccessPath);
    }
  }

  render() {
    let {
      user: { isLoggingIn, gettingUserConfig },
      accounts: { fetchingAccounts }
    } = this.props;
    return isLoggingIn || fetchingAccounts || gettingUserConfig ? (
      <div className="flex-center align-center">
        <div className={`${Icons.spinner} mt-5 fa-5x p-5`} />
      </div>
    ) : (
      <Form className="" onSubmit={this.submitLogin} onKeyPress={this.submitOnEnter}>
        {this.props.user.didLoginFail && (
          <span className="warning-color-font">The username or password you entered is incorrect.</span>
        )}
        <FormGroup>
          <div className="text-center">
            {this.props.user.isLoginErrorVisable ? (
              <span className="text-danger">{Resources.UserLoginFailure}</span>
            ) : null}
          </div>
          <Label for="username">{Resources.Username}</Label>
          <Input
            type="username"
            name="username"
            value={this.state.usernameInput}
            onChange={this.onInput}
            id="usernameInput"
            placeholder={Resources.Username}
          />
        </FormGroup>
        <FormGroup>
          <Label for="password">{Resources.Password}</Label>
          <Input
            style={{ backgroundColor: "#FFFFFF" }}
            type="password"
            name="password"
            value={this.state.passwordInput}
            onChange={this.onInput}
            id="passwordInput"
            placeholder={Resources.password}
          />
        </FormGroup>
        <FormGroup className="text-center">
          {this.props.loginFooter === null ? (
            <div>
              <Button
                className="mr-5 w-25"
                onClick={() => this.props.login(this.state.usernameInput, this.state.passwordInput)}
              >
                {Resources.SignIn}
              </Button>
              <Button className="ml-5 w-25" onClick={this.props.hideLoginModal}>
                {Resources.Cancel}
              </Button>
            </div>
          ) : (
            this.props.loginFooter(this.submitLogin)
          )}
        </FormGroup>
      </Form>
    );
  }
}

LoginForm.defaultProps = {
  loginSuccessPath: "/"
};

const storeToProps = store => {
  return {
    user: store.user,
    accounts: store.accounts
  };
};

export default connect(
  storeToProps,
  dispatchToProps
)(LoginForm);
