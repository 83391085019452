import React, { Component } from "react";
import { Button, Form } from "reactstrap";
import { connect } from "react-redux";
import Resources from "../lib/resources";
import FlexibleScrollContainer from "./flexibleScrollContainer";

import { dispatchToProps as aaDP } from "../store/accounts-actions";
import { dispatchToProps as usrDP } from "../store/user-actions";
import { dispatchToProps as regDP } from "../store/registration-actions";
import { dispatchToProps as genDP } from "../store/general-actions";
import LockstepHeader from "./lockstep/views/lockstepHeader";
import MainLoader from "./mainLoader";

const dispatchToProps = dispatch => ({
  ...aaDP(dispatch),
  ...usrDP(dispatch),
  ...regDP(dispatch),
  ...genDP(dispatch)
});

class InvitedAcceptProfile extends Component {
  state = {
    companyInfo: {
      email: "",
      companyName: "",
      apEmail: "",
      arEmail: "",
      apPhone: "",
      arPhone: "",
      website: "",
      textAreaAddress: "",
      entityType: "",
      TIN: "",
      password: "",
      address: {
        street_address: "",
        locality: "",
        region: "",
        postal_code: "",
        country: ""
      }
    },
    isSignUpSuccessfulAndComplete: false,
    doesAccountExists: false,
    showEditAddress: false
  };

  componentDidMount() {
    let inviteData = this.props.registration.inviteData.companyInvite;
    let email = this.props.registration.inviteData.email;
    if (inviteData) {
      this.setState(
        {
          companyInfo: {
            email: email,
            companyName: inviteData.companyName,
            apEmail: inviteData.apEmail,
            arEmail: inviteData.arEmail,
            apPhone: inviteData.apPhone,
            arPhone: inviteData.arPhone,
            website: inviteData.website,
            textAreaAddress: `${inviteData.line1}<br />${inviteData.city}, ${inviteData.regionId} ${inviteData.postalCode}<br />${inviteData.countryId}`,
            entityType: inviteData.entityType,
            TIN: inviteData.tin,
            password: this.props.passwordData.password,
            address: {
              street_address: inviteData.line1,
              locality: inviteData.city,
              region: inviteData.regionId,
              postal_code: inviteData.postalCode,
              country: inviteData.countryId
            }
          }
        },
        () => {
          if (inviteData.isPreconfirmed) {
            this.AcceptProfileData();
          }
        }
      );
    }
  }

  componentDidUpdate() {
    if (this.props.registration.isSignUpSuccessfulAndComplete) {
      let companyId = this.props.registration.signUpCustomerId;
      this.props.replace(`/company/${companyId}/manage/workspaces`);
      this.props.resetRegistrationState();
    }

    if (this.props.registration.doesAccountExists) {
      this.props.replace("/login");
      window.localStorage.setItem("personalEmail", this.state.companyInfo.email);
    }
  }

  setField = e => {
    let name = e.target.name;
    let companyInfo = this.state.companyInfo;
    if (
      name === "street_address" ||
      name === "postal_code" ||
      name === "country" ||
      name === "region" ||
      name === "locality"
    ) {
      this.setState({
        ...this.state,
        companyInfo: {
          ...companyInfo,
          address: { ...companyInfo.address, [e.target.name]: e.target.value }
        }
      });
    } else {
      this.setState({
        ...this.state,
        companyInfo: { ...companyInfo, [e.target.name]: e.target.value }
      });
    }
  };

  AcceptProfileData = () => {
    let inviteData = this.props.registration.inviteData;
    let companyInfo = this.state.companyInfo;
    this.props.invitationSignUp(inviteData, companyInfo);
  };

  render() {
    return (
      <React.Fragment>
        <LockstepHeader />
        <div className="confirm-password-page-container" style={{ height: "100vh" }}>
          {this.props.registration.isSettingUserAccount ? (
            <MainLoader fullScreen={true} />
          ) : (
            <FlexibleScrollContainer>
              <Form onSubmit={e => e.preventDefault()} className="full-company-profile white-color-bg p-4 mt-0">
                <div>
                  <h1>{this.state.companyInfo.companyName}</h1>
                </div>

                <hr />

                {this.props.registration.errorMsgs.map((error, i) => {
                  if (
                    error.some(
                      item =>
                        item === "A user with this email address already exists." ||
                        item === "A user with this username already exists."
                    )
                  )
                    return (
                      <div key={i} className="warning-color-font">
                        A user with the email, {this.state.companyInfo.email} already exists.'
                      </div>
                    );
                  return (
                    <div key={i} className="warning-color-font">
                      {error}
                    </div>
                  );
                })}

                <h5>{Resources.EmailAddresses}</h5>
                <div className="input-container">
                  <label htmlFor="ap-email">{Resources.ApEmail}:</label>
                  <input
                    type="email"
                    name="apEmail"
                    id="ap-email"
                    value={this.state.companyInfo.apEmail}
                    onChange={this.setField}
                  />
                </div>

                <div className="input-container">
                  <label htmlFor="ar-email">{Resources.ArEmail}:</label>
                  <input
                    type="email"
                    name="arEmail"
                    id="ar-Email"
                    value={this.state.companyInfo.arEmail}
                    onChange={this.setField}
                  />
                </div>

                <h5>{Resources.ContactInformation}</h5>
                <div className="input-container">
                  <label htmlFor="ap-phone">{Resources.ApPhone}:</label>
                  <input
                    type="phone"
                    name="apPhone"
                    id="ap-phone"
                    value={this.state.companyInfo.apPhone}
                    onChange={this.setField}
                  />
                </div>
                <div className="input-container">
                  <label htmlFor="ar-phone">{Resources.ArPhone}:</label>
                  <input
                    type="phone"
                    name="arPhone"
                    id="ar-phone"
                    value={this.state.companyInfo.arPhone}
                    onChange={this.setField}
                  />
                </div>
                <div className="input-container">
                  <label htmlFor="website">{Resources.URL}:</label>
                  <input
                    className="w-100 d-inline-block rounded-0"
                    type="text"
                    name="website"
                    id="website"
                    value={this.state.companyInfo.website}
                    onChange={this.setField}
                  />
                </div>

                <h5>{Resources.AddressInformation}</h5>
                <div
                  onFocus={() => this.setState({ showEditAddress: true })}
                  onClick={() => this.setState({ showEditAddress: true })}
                  className={this.state.showEditAddress ? "d-none" : "input-container"}
                >
                  <label className="w-100 d-inline-block align-top" htmlFor="address">
                    {Resources.Address}:
                  </label>
                  <div
                    className="w-100 d-inline-block rounded-0"
                    name="textAreaAddress"
                    id="address"
                    style={{ height: "100px" }}
                    dangerouslySetInnerHTML={{
                      __html: this.state.companyInfo.textAreaAddress
                    }}
                  />
                </div>

                {this.state.showEditAddress && (
                  <React.Fragment>
                    <div className="input-container">
                      <label htmlFor="street_address">{Resources.StreetAddress}:</label>
                      <input
                        type="text"
                        name="street_address"
                        id="street_address"
                        value={this.state.companyInfo.address.street_address}
                        onChange={this.setField}
                      />
                    </div>
                    <div className="input-container">
                      <label htmlFor="locality">{Resources.City}:</label>
                      <input
                        type="text"
                        name="locality"
                        id="locality"
                        value={this.state.companyInfo.address.locality}
                        onChange={this.setField}
                      />
                    </div>

                    <div className="input-container">
                      <label htmlFor="region">{Resources.Region}:</label>
                      <input
                        className="w-100 d-inline-block rounded-0"
                        type="text"
                        name="region"
                        id="region"
                        value={this.state.companyInfo.address.region}
                        onChange={this.setField}
                      />
                    </div>
                    <div className="input-container">
                      <label htmlFor="country">{Resources.Country}:</label>
                      <input
                        className="w-100 d-inline-block rounded-0"
                        type="text"
                        name="country"
                        id="country"
                        value={this.state.companyInfo.address.country}
                        onChange={this.setField}
                      />
                    </div>
                    <div className="input-container">
                      <label htmlFor="postalCode">{Resources.PostalCode}:</label>
                      <input
                        className="w-100 d-inline-block rounded-0"
                        type="text"
                        name="postal_code"
                        id="postalCode"
                        value={this.state.companyInfo.address.postal_code}
                        onChange={this.setField}
                      />
                    </div>
                  </React.Fragment>
                )}

                <hr />

                {/* TODO: these will come back some day */}
                {/* <div className="input-container">
              <label className="w-100 d-inline-block text-left" htmlFor="entity-type">{Resources.EntityType}:</label>
              <input type="text" name="entityType" id="entity-type" value={this.state.companyInfo.entityType} onChange={this.setField} />
            </div> */}
                <div className="input-container">
                  <label className="w-100 d-inline-block text-left" htmlFor="tin">
                    {Resources.TIN}:
                  </label>
                  <input type="text" name="TIN" id="tin" value={this.state.companyInfo.TIN} onChange={this.setField} />
                </div>
                {/* TODO: these will come back some day */}
                {/* <div className="input-container">
              <label>{Resources.W9File}: </label>
              <span className="mx-2 far fa-file" />
            </div>
            <div className="input-container">
              <label>{Resources.W8BenFile}: </label>
              <span className="mx-2 far fa-file" />
            </div> */}
                <div className="button-container">
                  <Button onClick={this.AcceptProfileData} className="button-primary-square">
                    {Resources.AcceptProfileData}
                  </Button>
                </div>
              </Form>
            </FlexibleScrollContainer>
          )}
        </div>
      </React.Fragment>
    );
  }
}

const storeToProps = store => {
  return {
    registration: store.registration
  };
};

export default connect(storeToProps, dispatchToProps)(InvitedAcceptProfile);
