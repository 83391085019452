import React, { Component } from "react";
import { connect } from "react-redux";

import { dispatchToProps as modDP } from "../../../store/modal-actions";
import { dispatchToProps as manDP } from "../../../store/manage-actions";

import Resources from "../../../lib/resources";

import Card from "../../library/card";
import TableData from "../../library/tableData";
import AutoCompleteInput from "../../library/autoCompleteInput";
import TableDataSortableHeader from "../../library/tableDataSortableHeader";
import CreateCustomerGroup from "./createCustomerGroup";

const dispatchToProps = dispatch => {
  return {
    ...dispatch(manDP),
    ...dispatch(modDP)
  };
};

class ManageCustomerGroups extends Component {
  constructor(props) {
    super(props);

    this.state = {
      displaySearchResults: false,
      searchTerm: "",
      searchResults: [],
      creatingCustomerGroup: false,
      editingCustomerGroup: {}
    };

    this.updateSearchTerm = this.updateSearchTerm.bind(this);
  }

  communicationTemplatesColumns = () => [
    {
      header: (
        <TableDataSortableHeader
          sortBy={this.props.manageStore.customerGroupsSortBy}
          sortDirection={this.props.manageStore.customerGroupsSortDirection}
          updateSort={(sortBy, sortDirection) => {
            this.props.updateCustomerGroupsSort(sortBy, sortDirection);
          }}
          text={Resources.GroupName.toLocaleUpperCase()}
          sortKey={"customerGroupId"}
        />
      ),
      width: "25%",
      content: row => {
        return row.customerGroupId;
      }
    },
    {
      header: (
        <TableDataSortableHeader
          sortBy={this.props.manageStore.customerGroupsSortBy}
          sortDirection={this.props.manageStore.customerGroupsSortDirection}
          updateSort={(sortBy, sortDirection) => {
            this.props.updateCustomerGroupsSort(sortBy, sortDirection);
          }}
          text={Resources.Description.toLocaleUpperCase()}
          sortKey={"description"}
        />
      ),
      width: "45%",
      content: row => row.description
    },
    {
      header: (
        <TableDataSortableHeader
          sortBy={this.props.manageStore.customerGroupsSortBy}
          sortDirection={this.props.manageStore.customerGroupsSortDirection}
          updateSort={(sortBy, sortDirection) => {
            this.props.updateCustomerGroupsSort(sortBy, sortDirection);
          }}
          text={Resources.Customers.toLocaleUpperCase()}
          sortKey={"companyCount"}
        />
      ),
      width: "7.33rem",
      content: row => {
        return <span className="float-right pr-2">{(row.companies || {}).count}</span>;
      }
    },
    { header: Resources.LastEdited.toLocaleUpperCase(), content: () => null }
  ];

  componentDidMount() {
    this.props.getCustomerGroups(this.props.match.params.companyId);
  }

  updateSearchTerm(searchTerm) {
    let searchResults = this.searchData(searchTerm);
    this.setState({ searchTerm, searchResults });
  }

  searchData(searchTerm) {
    let data = this.props.manageStore.customerGroups;
    return data.filter(group => group.customerGroupId.toLowerCase().includes(searchTerm.toLowerCase()));
  }

  displaySearchResults(searchTerm) {
    this.updateSearchTerm(searchTerm);
    this.setState({ displaySearchResults: true });
  }

  render() {
    let data = this.state.displaySearchResults ? this.state.searchResults : this.props.manageStore.customerGroups;

    if (this.state.creatingCustomerGroup) {
      return (
        <CreateCustomerGroup
          companyId={this.props.match.params.companyId}
          customerGroup={this.state.editingCustomerGroup}
          onCloseCustomerGroup={() => this.setState({ creatingCustomerGroup: false, editingCustomerGroup: {} })}
        />
      );
    }
    return (
      <div className="manage-content">
        <button
          className="button-purple"
          onClick={() => this.setState({ creatingCustomerGroup: true })}
          style={{ width: "20rem", marginBottom: "2.66rem", marginTop: "2.66rem" }}
        >
          {Resources.CreateNewGroup}
        </button>
        <Card
          type="company-info-contact-us"
          className="company-info-contact-us"
          title={Resources.AccountGroups}
          headAboveCard
        >
          <div className="table-data-card-header">
            <div className="d-flex align-items-center">
              <div className="table-data-card-header-search">
                <AutoCompleteInput
                  className="auto-complete-input__table-data-search"
                  placeholder={Resources.Search}
                  onChange={e => {
                    this.updateSearchTerm(e.target.value);
                  }}
                  isShowingSearchResult={this.state.displaySearchResults}
                  handleClearResult={() => {
                    this.setState({ searchTerm: "", searchResults: [], displaySearchResults: false });
                  }}
                  text={this.state.searchTerm}
                  noResultsMessage={Resources.NoCustomerGroupsSearchResultsMessage}
                  showNoResultsMessage={this.state.searchTerm.length !== 0 && this.props.manageStore.gotCustomerGroups}
                  maxOptions={7}
                  loading={this.props.manageStore.gettingCustomerGroups}
                  handleSelectOption={option => {
                    this.displaySearchResults(option.customerGroupId);
                  }}
                  callToActionButton={
                    <div
                      className="dropdown-item-clean dropdown-call-to-action"
                      onClick={() => this.displaySearchResults(this.state.searchTerm)}
                    >
                      {Resources.SeeAllResultsFor(this.state.searchTerm)}
                    </div>
                  }
                  renderOption={option => `${option.customerGroupId}`}
                  options={this.state.searchResults}
                  width="300px"
                />
              </div>
            </div>
          </div>
          <TableData
            pagination
            data={data}
            columns={this.communicationTemplatesColumns()}
            rowHeight="4em"
            maxRows={data.length}
            loading={this.props.manageStore.gettingCustomerGroups}
            onRowClick={row => {
              this.setState({ creatingCustomerGroup: true, editingCustomerGroup: row });
            }}
            offsetHeight="40"
            emptyRender={
              <div className="flex-center">
                <h4 className="mt-5">{Resources.EmptyCustomerGroups}</h4>
              </div>
            }
          />
        </Card>
      </div>
    );
  }
}

const storeToProps = store => {
  return {
    pageRowCount: store.general.pageRowCount,
    companyStore: store.companies,
    manageStore: store.manage
  };
};

export default connect(
  storeToProps,
  dispatchToProps
)(ManageCustomerGroups);
