import React, { Component } from "react";
import { findDOMNode } from "react-dom";

import "../css/scrollbox.scss";
import Resources from "../lib/resources";
import Icons from "../lib/icons";
import { isEmpty } from "../lib/utils";

export default class FlexibleScrollContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      contentHeight: 0,
      height: this.props.initialHeight,
      maxHeight: this.props.maxHeight || this.props.initialHeight,
      scrollBound: false
    };

    this.contentScroll = React.createRef();
    this.body = React.createRef();

    this.handleScroll = this.handleScroll.bind(this);
    this.onLoadMore = this.onLoadMore.bind(this);
    this.updateHeight = this.updateHeight.bind(this);
  }

  updateHeight() {
    // this can happen when we are in the middle of a call to render and the DOM isn't realized...but the resize event on the window gets fired
    if (isEmpty(this.body)) {
      return;
    }

    let contentHeight = this.contentScroll.clientHeight;
    if (contentHeight !== this.state.contentHeight) {
      this.setState({ contentHeight });
    }

    let footerHeight = 60;
    let maxed = this.props.maxRows === this.props.children.length || this.props.maxRows === this.props.loaded;
    if (isEmpty(this.props.maxRows) || maxed) {
      footerHeight = 20;
    }
    if (this.props.loading === true || this.props.forceFooter === true) {
      footerHeight = 60;
    }
    if (this.props.noFooter) {
      footerHeight = 0;
    }

    const offsetHeight = this.props.offsetHeight || 0;
    const height = window.innerHeight - this.body.getBoundingClientRect().top - footerHeight - offsetHeight;
    let maxHeight = this.state.maxHeight;

    if (this.props.maxHeight) {
      maxHeight = `calc(${this.props.maxHeight} - ${footerHeight}px - ${offsetHeight}px)`;
    } else {
      maxHeight = height;
    }

    if (contentHeight < height && this.props.noGrow) {
      if (this.state.height !== contentHeight || this.state.maxHeight !== contentHeight)
        this.setState({ height: contentHeight, maxHeight: contentHeight });
    } else {
      if (this.state.height !== height || this.state.maxHeight !== maxHeight) this.setState({ height, maxHeight });
    }
    if (!isEmpty(this.props.scrollbarPresent)) {
      this.props.scrollbarPresent(
        this.state.contentHeight > this.state.height,
        this.body.offsetWidth - this.contentScroll.offsetWidth
      );
    }
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateHeight);
    this.updateHeight();
    findDOMNode(this.refs["scrollbox"]).addEventListener("scroll", this.handleScroll, false);
  }

  componentDidUpdate() {
    this.updateHeight();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateHeight);
    findDOMNode(this.refs["scrollbox"]).removeEventListener("scroll", this.handleScroll, false);
  }

  handleScroll() {
    let box = this.refs["scrollbox"];
    let position = box.scrollHeight - box.scrollTop;
    // position was computing with decimals...so they were never equal
    // this lets us get a little before bottoming out
    let atBottom = position - box.offsetHeight < 20;
    if (atBottom) {
      this.onLoadMore();
    }
  }

  onLoadMore() {
    let onLoadMore = this.props.onLoadMore;
    if (onLoadMore) onLoadMore();
  }

  render() {
    let {
      children,
      children: { length },
      maxRows,
      loaded
    } = this.props;
    length = loaded || length || 0;
    maxRows = maxRows || length;
    let maxed = maxRows === length;

    return (
      <div>
        <div
          ref={body => (this.body = body)}
          style={{
            height: `${this.state.height}px`,
            maxHeight: this.state.maxHeight
          }}
        >
          <div
            ref={"scrollbox"}
            id={this.props.id || null}
            className="scroll-box"
            style={{
              height: `${this.state.height}px`,
              maxHeight: this.state.maxHeight
            }}
          >
            <div ref={element => (this.contentScroll = element)}>{children}</div>
          </div>
        </div>
        {(maxed !== true || this.props.loading) && this.props.hideLoadMore !== true && (
          <div className="ml-3 mt-3">
            {maxed !== true && <span>{`Showing ${length} of ${maxRows} rows...`}</span>}
            <span disabled={this.props.loading} onClick={this.onLoadMore} style={{ cursor: "pointer" }}>
              {this.props.loading ? <span className={Icons.spinner} /> : Resources.LoadMore}
            </span>
          </div>
        )}
      </div>
    );
  }
}
