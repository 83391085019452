import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { dispatchToProps as moDP } from "../../../store/modal-actions";
import { dispatchToProps as ledgDP } from "../../../store/ledger-actions";
import { dispatchToProps as statDP } from "../../../store/statements-actions";
import { dispatchToProps as manDP } from "../../../store/manage-actions";
import { dispatchToProps as networkDP } from "../../../store/network-actions";
import { dispatchToProps as caDP } from "../../../store/company-actions";
import { dispatchToProps as perDP } from "../../../store/perspectives-actions";

import Resources from "../../../lib/resources";
import "react-datepicker/dist/react-datepicker.css";
import IconClose from "../../library/icons/iconClose";

import Modal from "react-modal";
import ContactUpdateForm from "../views/profileUpdate/contactUpdateForm";

const dispatchToProps = dispatch => ({
  ...moDP(dispatch),
  ...ledgDP(dispatch),
  ...statDP(dispatch),
  ...caDP(dispatch),
  ...manDP(dispatch),
  ...networkDP(dispatch),
  ...perDP(dispatch)
});

class AddContactFlyout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showFlyout: true,
      contactInfo: {
        companyKey: this.props.accountId
      }
    };

    this.hideFlyout = this.hideFlyout.bind(this);
    this.formOnChange = this.formOnChange.bind(this);
    this.addContactFunction = this.addContactFunction.bind(this);
  }

  formOnChange(field, value) {
    this.setState(prevState => {
      return { contactInfo: { ...prevState.contactInfo, [field]: value } };
    });
  }

  hideFlyout() {
    this.setState({ showFlyout: false });
  }

  addContactFunction(sendUpdate) {
    let mode = this.props.perspectiveStore.selectedPerspectiveType === "vendors" ? "ap" : "ar";
    let postRequest = {
      ...this.state.contactInfo,
      isLockstepVerified: false
    };
    this.props
      .addContact(this.props.perspectiveId, this.state.contactInfo.companyKey, mode, postRequest)
      .then(response => {
        if (sendUpdate) {
          this.props.sendContactProfileUpdateRequest([response.entityGlobalKey]);
        }
        this.props
          .updateCustContact(this.props.perspectiveId, response.entityGlobalKey, mode, postRequest)
          .then(response => {
            this.props.fetchContactList(this.props.perspectiveId);
            if (this.props.accountId) {
              this.props.fetchAccountContacts(this.props.perspectiveId, this.props.accountId);
            }
            this.hideFlyout();
          });
      });
    sendUpdate
      ? this.props.displayNotification("addingContactSendingUpdateRequestNotification")
      : this.props.displayNotification("addingContactNotification");
  }

  render() {
    return (
      <Modal
        isOpen={this.state.showFlyout}
        onRequestClose={this.hideFlyout}
        onAfterClose={!this.state.showFlyout ? this.props.hideFlyout : null}
        closeTimeoutMS={400}
        contentLabel="Example Modal"
        className="flyout"
        overlayClassName="flyout-overlay"
      >
        <div className="add-contacts-flyout">
          <div onClick={() => this.hideFlyout()} className="flyout-heading-close">
            <IconClose />
          </div>
          <h2>{Resources.AddANewContact}</h2>
          <ContactUpdateForm
            edit
            small
            empty
            add
            selectedCompany={
              !!this.props.accountId ? this.props.networkStore.accountsMap[this.props.accountId].companyName : ""
            }
            mode={this.props.perspectiveStore.selectedPerspectiveType === "vendors" ? "ap" : "ar"}
            perspectiveId={this.props.perspectiveId}
            addContactFunction={this.addContactFunction}
            contactFormOnChange={this.formOnChange}
          />
        </div>
      </Modal>
    );
  }
}

const storeToProps = store => {
  return {
    modalStore: store.modal,
    manageStore: store.manage,
    contactList: store.network.contactList,
    accountList: store.network.accountList,
    pageRowCount: store.general.pageRowCount,
    networkStore: store.network,
    perspectiveStore: store.perspectives,
    searchResults: store.network.searchResults,
    isShowingSearchResult: store.gotNetworkAccountSearchResults,
    gettingNetworkAccountList: store.network.gettingNetworkAccountList,
    gettingNetworkContactList: store.network.gettingNetworkContactList
  };
};

export default withRouter(connect(storeToProps, dispatchToProps)(AddContactFlyout));
