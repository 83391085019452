import React, { Component } from "react";
import { connect } from "react-redux";

import { dispatchToProps as seqDP } from "../../store/sequences-actions";

import IconPlusCircle from "../library/icons/iconPlusCircle";
import IconAngleRight from "../library/icons/iconAngleRight";
import IconAngleDown from "../library/icons/iconAngleDown";
import IconTrash from "../library/icons/iconTrash";
import IconInfo from "../library/icons/iconInfo";
import IconClose from "../library/icons/iconClose";

import ToggleSwitch from "../library/toggleSwitch";
import Card from "../library/card";
import TextInput from "../library/textInput";
import TextAreaInput from "../library/textAreaInput";
import Checkbox from "../library/checkbox";
import SelectInput from "../library/selectInput";
import MainLoader from "../mainLoader";

import Resources from "../../lib/resources";
import { onBlurCheckFocusable, removeAt, replaceAt, isEmpty, find, includes } from "../../lib/utils";

const dispatchToProps = dispatch => {
  return {
    ...dispatch(seqDP)
  };
};

class CreateOrEditSequenceContent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      focusedField: null,
      sequenceState: {},
      editingSequenceStepIndex: null,
      editingSequenceStep: null,
      isAddingNewStep: false,
      selectedTriggerType: null,
      showNotificationHelperText: false,

      dueDateSign: 1
    };

    this.completeEditSequenceStep = this.completeEditSequenceStep.bind(this);
    this.cancelEditSequenceStep = this.cancelEditSequenceStep.bind(this);
    this.reorderSequenceSteps = this.reorderSequenceSteps.bind(this);
  }

  componentDidMount() {
    this.tryUpdate();
  }

  componentDidUpdate() {
    this.tryUpdate();
  }

  tryUpdate() {
    const {
      sequencesStore,
      match: { params },
      companyId,
      manageStore
    } = this.props;
    if (isEmpty(this.state.sequenceState)) {
      // set initial state to template state for new sequence or, existing state for editing sequence
      let sequenceState = {};
      if (params.sequenceView === "new") {
        if (sequencesStore.fetchedSequenceSamples !== true) {
          return;
        }
        sequenceState = { ...this.props.sequencesStore.sequenceSampleMaps[this.props.match.params.sequenceId] };
      } else {
        if (sequencesStore.fetchedSequences !== true) {
          return;
        }
        sequenceState = { ...this.props.sequencesStore.sequenceMaps[this.props.match.params.sequenceId] };
      }
      sequenceState.sequenceSteps = sequenceState.sequenceSteps.map(step => {
        let newStep = { ...step };
        switch (newStep.actionType) {
          case "EmailSend":
            newStep.activityType = 1;
            break;
          case "Phone":
            newStep.activityType = 3;
            break;
          default:
            break;
        }
        return newStep;
      });

      // if we can't find the sequence or sample in the store, go back to sequence list view
      if (isEmpty(sequenceState)) {
        this.props.history.push(this.props.location.replace(`${params.sequenceView}/${params.sequenceId}`, "list"));
        return;
      }

      ((sequenceState || {}).sequenceSteps || []).sort((a, b) => (a.sequenceNumber > b.sequenceNumber ? 1 : -1));

      this.setState({ sequenceState });
    }
    if (!isEmpty(companyId) && !manageStore.gotCustomerGroups) {
      this.props.getCustomerGroups(companyId);
    }
    if (!isEmpty(companyId) && !manageStore.gotCommunicationTemplates) {
      this.props.getCommunicationTemplates(companyId);
    }
  }

  reorderSequenceSteps(dragIndex, hoverIndex) {
    let nextSequenceSteps = [...this.state.sequenceState.sequenceSteps];
    nextSequenceSteps.forEach((step, i) => (step.sequenceNumber = i + 1));
    this.setState({ sequenceState: { ...this.state.sequenceState, sequenceSteps: nextSequenceSteps } });
  }

  updateSequenceStep(update) {
    this.setState({ editingSequenceStep: { ...this.state.editingSequenceStep, ...update } });
  }

  completeEditSequenceStep() {
    const editingStep = { ...this.state.editingSequenceStep };

    this.props
      .postSequenceStepDescription(this.props.companyId, editingStep)
      .then(description => {
        editingStep.description = description;
        const newSteps = replaceAt(
          this.state.sequenceState.sequenceSteps,
          this.state.editingSequenceStepIndex,
          editingStep
        );

        this.setState({
          isAddingNewStep: false,
          editingSequenceStep: null,
          editingSequenceStepIndex: null,
          sequenceState: {
            ...this.state.sequenceState,
            sequenceSteps: newSteps
          }
        });
      })
      .catch(err => null);
  }

  cancelEditSequenceStep() {
    const nextState = { editingSequenceStepIndex: null, editingSequenceStep: null, isAddingNewStep: false };
    if (this.state.isAddingNewStep) {
      const nextSequenceSteps = removeAt(this.state.sequenceState.sequenceSteps, this.state.editingSequenceStepIndex);
      nextState.sequenceState = { ...this.state.sequenceState, sequenceSteps: nextSequenceSteps };
    }

    this.setState(nextState);
  }

  renderSequenceStepTrigger(sequenceStep) {
    const { sequenceState, editingSequenceStep } = this.state;

    const triggerBasis =
      find(sequenceState.availableTriggers, trigger => trigger.triggerID === sequenceStep.triggerType) || {};

    let result = null;

    if ((sequenceState.availableTriggers || []).length === 1) {
      if (triggerBasis.triggerValueType === "static") {
        result = (
          <label className="sequence-step-input-label">
            {Resources.Trigger}: {triggerBasis.description}
          </label>
        );
      } else {
        switch (triggerBasis.triggerID) {
          case "Due Date":
            result = (
              <div className="flex">
                <div className="flex-column" style={{ height: "fit-content", marginRight: "3.67rem" }}>
                  <label className="sequence-step-input-label">{Resources.Trigger}</label>
                  <div className="dropdown">
                    <button
                      className="sequence-dropdown"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <span>
                        {Math.abs(Number.parseInt((sequenceStep.triggerValue || {}).value)) ===
                        Number.parseInt((sequenceStep.triggerValue || {}).value)
                          ? Resources.InvoicePastDue
                          : Resources.InvoiceComingDue}
                      </span>
                      <IconAngleDown height={8} />
                    </button>
                    <span className="dropdown-menu sequence-dropdown-menu">
                      <button
                        key={"coming"}
                        className="dropdown-item"
                        style={{ cursor: "default" }}
                        onClick={e => {
                          this.setState({ dueDateSign: -1 });
                          this.updateSequenceStep({
                            triggerValue: {
                              ...sequenceStep.triggerValue,
                              value: Math.abs(Number.parseInt((sequenceStep.triggerValue || {}).value)) * -1
                            }
                          });
                        }}
                      >
                        {Resources.InvoiceComingDue}
                      </button>
                      <button
                        key={"past"}
                        className="dropdown-item"
                        style={{ cursor: "default" }}
                        onClick={e => {
                          this.setState({ dueDateSign: 1 });
                          this.updateSequenceStep({
                            triggerValue: {
                              ...sequenceStep.triggerValue,
                              value: Math.abs(Number.parseInt(sequenceStep.triggerValue.value))
                            }
                          });
                        }}
                      >
                        {Resources.InvoicePastDue}
                      </button>
                    </span>
                  </div>
                </div>
                <div style={{ marginLeft: "8.8rem" }} className="flex-column">
                  <div className="flex" style={{ position: "relative" }}>
                    <label
                      className={`edit-sequence-input-label ${
                        this.state.focusedField === "triggerValue" ? " focused" : ""
                      }`}
                    >
                      {this.state.editingSequenceStepIndex === 0 ? Resources.DaysComingDue : Resources.DaysPastDue}
                    </label>
                  </div>
                  <div className="flex-align-center">
                    <TextInput
                      isNumber
                      centerText
                      className="mb-0"
                      width="4rem"
                      textValue={Math.abs((sequenceStep.triggerValue || {}).value || 7).toString()}
                      inputOnChange={e =>
                        this.updateSequenceStep({
                          triggerValue: {
                            criteria: "Equal To",
                            value: Number.parseInt(e.target.value) * this.state.dueDateSign
                          }
                        })
                      }
                      onFocus={() => this.setState({ focusedField: "triggerValue" })}
                      onBlur={() => onBlurCheckFocusable(() => this.setState({ focusedField: null }))}
                    />
                  </div>
                </div>
              </div>
            );
            break;
          case "Percent Credit Used":
            result = (
              <div className="flex">
                <div style={{ marginRight: "3.67rem" }}>
                  <label className="sequence-step-input-label">{Resources.Trigger}</label>
                  <div style={{ lineHeight: "2.13rem" }}>{triggerBasis.description}</div>
                </div>
                <div>
                  <label className="sequence-step-input-label">
                    {triggerBasis.triggerID === "Percent Credit Used" ? Resources.Percentage : null}
                  </label>
                  <div className="flex-align-center">
                    {Resources.GreaterThanOrEqualTo}
                    <TextInput
                      isNumber
                      centerText
                      className="mb-0"
                      width="4rem"
                      style={{ marginLeft: ".67rem", marginRight: ".67rem" }}
                      textValue={editingSequenceStep.triggerValue[0].value}
                      inputOnChange={e =>
                        this.setState({
                          editingSequenceStep: {
                            ...editingSequenceStep,
                            triggerValue: [
                              { ...editingSequenceStep.triggerValue[0], value: e.target.value },
                              editingSequenceStep.triggerValue[1]
                            ]
                          }
                        })
                      }
                      onFocus={() => this.setState({ focusedField: null })}
                    />
                    {Resources.AndLessThan}
                    <TextInput
                      isNumber
                      centerText
                      className="mb-0"
                      width="4rem"
                      style={{ marginLeft: ".67rem", marginRight: ".67rem" }}
                      textValue={editingSequenceStep.triggerValue[1].value}
                      inputOnChange={e =>
                        this.setState({
                          editingSequenceStep: {
                            ...editingSequenceStep,
                            triggerValue: [
                              editingSequenceStep.triggerValue[0],
                              { ...editingSequenceStep.triggerValue[1], value: e.target.value }
                            ]
                          }
                        })
                      }
                      onFocus={() => this.setState({ focusedField: null })}
                    />
                    %
                  </div>
                </div>
              </div>
            );
            break;
          default:
            break;
        }
      }

      if (triggerBasis.frequencyOptions.length > 1) {
        result = <React.Fragment>{result}</React.Fragment>;
      }
    }

    return result;
  }

  renderSequenceStep(step, i) {
    const { sequenceState } = this.state;
    const { sequencesStore, manageStore } = this.props;
    const isEditing = step === sequenceState.sequenceSteps[this.state.editingSequenceStepIndex];
    const sequenceStep = isEditing ? this.state.editingSequenceStep : step;

    const triggerBasis = find(
      sequenceState.availableTriggers,
      trigger => trigger.triggerID === sequenceStep.triggerType
    );

    const displayRepeat =
      triggerBasis.frequencyOptions.length > 1 &&
      (step.frequencyType !== "Once" || sequenceState.sequenceSteps.length - 1 === i);
    const displayDisputeAndPromise = sequenceStep.triggerCategory === "invoice";

    let templateOptions;
    switch (sequenceStep.activityType) {
      case 1:
        templateOptions = (manageStore.communicationTemplateTypeToTemplate.email || []).map(template => {
          return { description: template.emailTemplateID, value: template.emailTemplateKey };
        });
        break;
      case 3:
        templateOptions = (manageStore.communicationTemplateTypeToTemplate.voice || []).map(template => {
          return { description: template.emailTemplateID, value: template.emailTemplateKey };
        });
        break;
      default:
        templateOptions = [];
        break;
    }

    return (
      <div key={sequenceStep.description || "new"} className="flex-align-center" style={{ marginBottom: "1.6rem" }}>
        <div className="sequence-step-order-number" style={{ marginRight: "1.12rem" }}>
          {i + 1}
        </div>
        {isEditing ? (
          <Card className="sequence-step-card editing-sequence-step-card">
            <div className="flex">
              <div style={{ height: "fit-content", marginBottom: "1.33rem" }}>
                {this.renderSequenceStepTrigger(sequenceStep)}
              </div>
            </div>
            <div className="flex">
              <div className="flex-column" style={{ height: "fit-content", marginRight: "3.67rem" }}>
                <label className="sequence-step-input-label">{Resources.Action}</label>
                <SelectInput
                  className="sequence-dropdown"
                  value={(sequencesStore.sequenceActionMaps[sequenceStep.actionType] || {}).description}
                  displayValue={(sequencesStore.sequenceActionMaps[sequenceStep.actionType] || {}).description}
                  optionValueKey="activityID"
                  optionDisplayKey="description"
                  options={sequencesStore.sequenceActions}
                  onSelectOption={action => {
                    this.updateSequenceStep({ actionType: action.activityID, activityType: action.activityType });
                  }}
                />
              </div>
              <div className="flex-column" style={{ height: "fit-content", marginRight: "3.67rem" }}>
                <label className="sequence-step-input-label">{Resources.Template}</label>
                <SelectInput
                  className="sequence-dropdown"
                  value={sequenceStep.actionValue}
                  displayValue={sequenceStep.templateDescription}
                  optionValueKey="value"
                  optionDisplayKey="description"
                  options={templateOptions}
                  onSelectOption={template => {
                    this.updateSequenceStep({ actionValue: template.value, templateDescription: template.description });
                  }}
                />
              </div>
            </div>
            {displayDisputeAndPromise && (
              <React.Fragment>
                <div className="sequence-step-divider"></div>
                <div className="flex" style={{ marginBottom: "1.33rem" }}>
                  <ToggleSwitch
                    className="sequence-activation-toggle"
                    checked={sequenceStep.attachPromiseToPay || false}
                    onChange={() => {
                      this.updateSequenceStep({
                        attachPromiseToPay: !sequenceStep.attachPromiseToPay
                      });
                    }}
                  />
                  <span className="toggle-switch-label">{Resources.AttachPromiseToPayInvoices}</span>
                </div>
                <div className="flex">
                  <ToggleSwitch
                    className="sequence-activation-toggle"
                    checked={sequenceStep.attachDisputed || false}
                    onChange={() => {
                      this.updateSequenceStep({
                        attachDisputed: !sequenceStep.attachDisputed
                      });
                    }}
                  />
                  <span className="toggle-switch-label">{Resources.AttachDisputedInvoices}</span>
                </div>
              </React.Fragment>
            )}
            {displayRepeat && (
              <React.Fragment>
                <div className="sequence-step-divider"></div>
                <div className="flex">
                  <ToggleSwitch
                    className="sequence-activation-toggle"
                    checked={sequenceStep.frequencyType !== "Once"}
                    onChange={() => {
                      if (sequenceStep.frequencyType === "Once") {
                        this.updateSequenceStep({
                          frequencyType: find(triggerBasis.frequencyOptions, o => o !== "Once"),
                          frequencyValue: 1
                        });
                      } else {
                        this.updateSequenceStep({
                          frequencyType: "Once",
                          frequencyValue: null
                        });
                      }
                    }}
                  />
                  <span className="toggle-switch-label">{Resources.Repeat}</span>
                </div>
                {sequenceStep.frequencyType !== "Once" && (
                  <div className="flex-align-center" style={{ marginTop: "2.1rem" }}>
                    <div className="flex-column" style={{ height: "fit-content", marginRight: "3.67rem" }}>
                      <label className="sequence-step-input-label">{Resources.Frequency}</label>
                      <div className="dropdown">
                        <button
                          className="sequence-dropdown"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <span>
                            {sequenceStep.frequencyType === "Days" ? Resources.NumberOfDays : Resources.DayOfTheMonth}
                          </span>
                          <IconAngleDown height={8} />
                        </button>
                        <span className="dropdown-menu sequence-dropdown-menu">
                          {includes(triggerBasis.frequencyOptions, "Days") && (
                            <button
                              key={"num"}
                              className="dropdown-item"
                              style={{ cursor: "default" }}
                              onClick={() => this.updateSequenceStep({ frequencyType: "Day of Month" })}
                            >
                              {Resources.NumberOfDays}
                            </button>
                          )}
                          {includes(triggerBasis.frequencyOptions, "Day of Month") && (
                            <button
                              key={"days"}
                              className="dropdown-item"
                              style={{ cursor: "default" }}
                              onClick={() => this.updateSequenceStep({ frequencyType: "Day of Month" })}
                            >
                              {Resources.DayOfTheMonth}
                            </button>
                          )}
                        </span>
                      </div>
                    </div>
                    <div style={{ marginLeft: "8.8rem" }} className="flex-column">
                      <div className="flex" style={{ position: "relative" }}>
                        <label
                          className={`edit-sequence-input-label ${
                            this.state.focusedField === "repeatFrequency" ? " focused" : ""
                          }`}
                        >
                          {Resources.Days}
                        </label>
                      </div>
                      <TextInput
                        isNumber
                        centerText
                        className="mb-0"
                        width="4rem"
                        textValue={(sequenceStep.frequencyValue || 30).toString()}
                        inputOnChange={e =>
                          this.updateSequenceStep({ frequencyValue: Number.parseInt(e.target.value) })
                        }
                        onFocus={() => this.setState({ focusedField: "repeatFrequency" })}
                        onBlur={() => onBlurCheckFocusable(() => this.setState({ focusedField: null }))}
                      />
                    </div>
                  </div>
                )}
              </React.Fragment>
            )}
            <div className="flex" style={{ marginTop: "2.66rem" }}>
              <button
                className="button-primary-square"
                style={{ marginRight: "2rem" }}
                onClick={this.completeEditSequenceStep}
              >
                {Resources.Done}
              </button>
              <button className="button-secondary-square" onClick={this.cancelEditSequenceStep}>
                {Resources.Cancel}
              </button>
            </div>
          </Card>
        ) : (
          <Card
            isDraggable={this.state.isAddingNewStep !== true && isEmpty(this.state.editingSequenceStep)}
            moveCard={this.reorderSequenceSteps}
            index={i}
            onClick={() => {
              if (isEmpty(this.state.editingSequenceStep)) {
                this.setState({ editingSequenceStepIndex: i, editingSequenceStep: { ...sequenceStep } });
              }
            }}
            className="sequence-step-card"
          >
            {sequenceStep.description}
          </Card>
        )}
        <button
          className="button-action-icon"
          style={{ marginLeft: ".3rem" }}
          onClick={() =>
            this.setState({
              ...this.state,
              isAddingNewStep: false,
              editingSequenceStep: null,
              editingSequenceStepIndex: null,
              sequenceState: {
                ...this.state.sequenceState,
                sequenceSteps: removeAt(this.state.sequenceState.sequenceSteps, i)
              }
            })
          }
        >
          <IconTrash height={20} />
        </button>
      </div>
    );
  }

  render() {
    const { sequenceState, isAddingNewStep } = this.state;

    const isNewSequence = this.props.match.params.sequenceView === "new";

    return (
      <div>
        <h2 className="card-title" style={{ marginBottom: "1.06rem" }}>
          {isNewSequence ? Resources.CreateANewSequence : Resources.ViewAndEditSequence}
        </h2>

        {isEmpty(sequenceState) ? (
          <div style={{ position: "absolute", left: "calc(50% - 14px)", top: "35%" }}>
            <MainLoader fullScreen />
          </div>
        ) : (
          <React.Fragment>
            <div>
              <div className="flex" style={{ marginBottom: "2.6rem" }}>
                <div style={{ marginRight: "8.66rem" }}>
                  <label
                    className={`edit-sequence-input-label ${
                      this.state.focusedField === "sequenceName" ? " focused" : ""
                    }`}
                  >
                    {Resources.Name}
                  </label>
                  <TextInput
                    className="mb-0"
                    width="23rem"
                    textValue={sequenceState.customerSequenceName}
                    inputOnChange={e =>
                      this.setState({
                        sequenceState: { ...sequenceState, customerSequenceName: e.target.value }
                      })
                    }
                    onFocus={() => this.setState({ focusedField: "sequenceName" })}
                    onBlur={() => onBlurCheckFocusable(() => this.setState({ focusedField: null }))}
                  />
                </div>
                <div className="flex-column">
                  <label className="edit-sequence-input-label">{Resources.Status}</label>
                  <div className="flex-align-center" style={{ height: "2.34rem" }}>
                    <ToggleSwitch
                      className="sequence-activation-toggle"
                      checked={sequenceState.isActive}
                      onChange={() =>
                        this.setState({ sequenceState: { ...sequenceState, isActive: !sequenceState.isActive } })
                      }
                    />
                    <span className="toggle-switch-label">
                      {sequenceState.isActive ? Resources.Active : Resources.Disabled}
                    </span>
                  </div>
                </div>
                {sequenceState.customerContactFrequency !== null && (
                  <div style={{ marginLeft: "8.8rem" }} className="flex-column">
                    <div className="flex" style={{ position: "relative" }}>
                      <label
                        className={`edit-sequence-input-label ${
                          this.state.focusedField === "contactFrequency" ? " focused" : ""
                        }`}
                      >
                        {Resources.CustomerNotificationFrequency}
                        <button
                          style={{ marginLeft: ".33rem" }}
                          className="button-info"
                          onClick={() => this.setState({ showNotificationHelperText: true })}
                        >
                          <IconInfo height={12} />
                        </button>
                      </label>
                      {this.state.showNotificationHelperText && (
                        <Card type="info">
                          <div className="flex-align-start" style={{ width: "23.6rem" }}>
                            <span className="notification-frequency-helper-text">
                              {Resources.NotificationFrequencyHelperText}
                            </span>
                            <button
                              onClick={() => this.setState({ showNotificationHelperText: false })}
                              style={{ padding: "0", marginTop: "-.4rem" }}
                            >
                              <IconClose height={14} />
                            </button>
                          </div>
                        </Card>
                      )}
                    </div>
                    <div className="flex-align-center">
                      <TextInput
                        isNumber
                        centerText
                        className="mb-0"
                        width="4rem"
                        textValue={sequenceState.customerContactFrequency}
                        inputOnChange={e =>
                          this.setState({
                            sequenceState: {
                              ...sequenceState,
                              customerContactFrequency: Number.parseInt(e.target.value)
                            }
                          })
                        }
                        onFocus={() => this.setState({ focusedField: "contactFrequency" })}
                        onBlur={() => onBlurCheckFocusable(() => this.setState({ focusedField: null }))}
                      />
                      <span style={{ marginLeft: ".6rem", width: "4.5rem", fontWeight: "500" }}>{Resources.Days}</span>
                    </div>
                  </div>
                )}
              </div>

              <label
                className={`edit-sequence-input-label ${this.state.focusedField === "description" ? " focused" : ""}`}
              >
                {Resources.Description}
              </label>
              <TextAreaInput
                className="sequence-description-input"
                textValue={sequenceState.description}
                inputOnChange={e => this.setState({ sequenceState: { ...sequenceState, description: e.target.value } })}
                rows={2}
                onFocus={() => this.setState({ focusedField: "description" })}
                onBlur={() => onBlurCheckFocusable(() => this.setState({ focusedField: null }))}
              />

              <div className="create-sequence-divider"></div>

              <div className="sequences-secondary-header" style={{ marginBottom: "1.1rem" }}>
                {Resources.Steps}
              </div>
              {(sequenceState.sequenceSteps || []).map((step, i) => this.renderSequenceStep(step, i))}
              {!isAddingNewStep && (
                <button
                  className="button-secondary"
                  onClick={() => {
                    let newStep = {};
                    if (sequenceState.availableTriggers.length === 1) {
                      const triggerBasis = sequenceState.availableTriggers[0];
                      newStep = {
                        sequenceNumber: sequenceState.sequenceSteps.length + 1,
                        isActive: true,
                        triggerType: triggerBasis.triggerID,
                        fequencyType: find(triggerBasis.frequencyOptions, o => o !== "Once")
                      };
                    }

                    this.setState({
                      isAddingNewStep: true,
                      editingSequenceStepIndex: sequenceState.sequenceSteps.length,
                      editingSequenceStep: newStep,
                      sequenceState: {
                        ...this.state.sequenceState,
                        sequenceSteps: [...this.state.sequenceState.sequenceSteps, newStep]
                      }
                    });
                  }}
                >
                  <span style={{ marginRight: ".66rem" }}>
                    <IconPlusCircle height="16" />
                  </span>
                  <span>{Resources.AddAStep}</span>
                </button>
              )}
            </div>

            <div className="create-sequence-divider"></div>

            <div className="sequences-secondary-header">{Resources.CustomerGroupsAssigned}</div>
            <button className="button-secondary" onClick={null} style={{ padding: "0.27rem", marginBottom: "1.6rem" }}>
              {Resources.ManageCustomerGroups}
              <IconAngleRight height={12} strokeWidth="3" className="ml-0" />
            </button>
            <div style={{ paddingBottom: "4.33rem" }}>
              <Checkbox
                label={Resources.All}
                //   checked={this.state.selectedCustomerGroups.length === this.props.sequencesStore.}
                className="sequence-edit-account-group-checkbox"
              />
              {(this.props.manageStore.customerGroups || []).map(customerGroup => {
                return (
                  <div key={customerGroup.customerGroupId}>
                    <Checkbox label={customerGroup.customerGroupId} className="sequence-edit-account-group-checkbox" />
                  </div>
                );
              })}
            </div>
            <div className="flex-column">
              {isNewSequence ? (
                undefined
              ) : (
                <button className="button-cancel-square" style={{ width: "20rem", marginBottom: "1.6rem" }}>
                  {Resources.Delete}
                </button>
              )}
              <button
                onClick={() => this.props.postSequence(this.props.companyId, sequenceState)}
                className="button-primary-square"
                disabled={!isEmpty(this.state.editingSequenceStep)}
                style={{ width: "20rem" }}
              >
                {Resources.Save}
              </button>
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }
}
const storeToProps = store => {
  return {
    sequencesStore: store.sequences,
    manageStore: store.manage
  };
};
export default connect(
  storeToProps,
  dispatchToProps
)(CreateOrEditSequenceContent);
