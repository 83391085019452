import React, { Component } from "react";
import { connect } from "react-redux";

import { dispatchToProps } from "../../store/modal-actions";
import Resources from "../../lib/resources";

import IconCheck from "../library/icons/iconCheck";
import IconAlertCircle from "../library/icons/iconAlertCircle";
import IconSpinner from "../library/icons/iconSpinner";

class ExportNotification extends Component {
  constructor(props) {
    super(props);

    this.state = {
      address: "",
      isValidAddress: false
    };
  }

  componentDidUpdate(prevProps) {
    if (
      (this.props.exportedTable === true && prevProps.exportedTable === false) ||
      (this.props.exportTableFailed === true && prevProps.exportTableFailed === false)
    ) {
      setTimeout(this.props.hideNotification, 5000);
    }
  }

  render() {
    let { exportingTable, exportedTable, exportTableFailed } = this.props;

    let content;
    if (exportingTable) {
      content = (
        <React.Fragment>
          <IconSpinner height={20} className="notification-icon-left" />
          <div>{Resources.ExportingTable}</div>
        </React.Fragment>
      );
    }
    if (exportedTable) {
      content = (
        <React.Fragment>
          <IconCheck height={18} className="notification-icon-left"></IconCheck>
          <div>{Resources.ExportComplete}</div>
        </React.Fragment>
      );
    }
    if (exportTableFailed) {
      content = (
        <React.Fragment>
          <IconAlertCircle height={18} className="notification-icon-left"></IconAlertCircle>
          <div>{Resources.ExportTableFailed}</div>
        </React.Fragment>
      );
    }
    return <div className="notification">{content}</div>;
  }
}

const storeToProps = store => {
  return {
    exportingTable: store.statements.fetchingExportTable,
    exportedTable: store.statements.fetchedExportTable,
    exportTableFailed: store.statements.failedFetchExportTable
  };
};

export default connect(
  storeToProps,
  dispatchToProps
)(ExportNotification);
