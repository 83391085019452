import React, { Component } from "react";

class IconAlert extends Component {
  render() {
    let { className, height } = this.props;

    return (
      <svg viewBox="0 0 23 21" height={height} className={`icon ${className ? className : ""}`}>
        <g fill="none" fillRule="evenodd">
          <path
            stroke="currentColor"
            d="M9.738 1.963l-8.47 14.14a2 2 0 001.71 3h16.94a2 2 0 001.71-3l-8.47-14.14a2 2 0 00-3.42 0zM11.448 7.103v4M11.448 15.103h0"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path stroke="currentColor" d="M10.448 14.103h2v2h-2z" />
        </g>
      </svg>
    );
  }
}

IconAlert.defaultProps = {
  height: 21
};

export default IconAlert;
