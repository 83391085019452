import React, { Component } from "react";

class IconInbox extends Component {
  render() {
    let { className, height } = this.props;

    return (
      <svg
        viewBox="0 0 24 24"
        height={height}
        className={`icon ${className ? className : ""}`}
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      >
        <defs />
        <path d="M22 12h-6l-2 3h-4l-2-3H2" />
        <path d="M5.45 5.11L2 12v6a2 2 0 002 2h16a2 2 0 002-2v-6l-3.45-6.89A2 2 0 0016.76 4H7.24a2 2 0 00-1.79 1.11z" />
      </svg>
    );
  }
}

IconInbox.defaultProps = {
  height: 24
};

export default IconInbox;
