import React, { Component } from "react";

class IconStar extends Component {
  render() {
    let { className, height, isFilled } = this.props;

    return (
      <svg viewBox="0 0 23 22" height={height} className={`icon ${className ? className : ""}`}>
        <g fill={isFilled ? "currentColor" : "none"} fillRule="evenodd">
          <path
            d="M11.5152471 1l3.2492114 6.58254469 7.2660357 1.06203996L16.7728707 13.76551 18.0136698 21l-6.4984227-3.4174553L5.0168244 21l1.24079915-7.23449L1 8.64458465l7.26603575-1.06203996z"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            stroke="currentColor"
          />
        </g>
      </svg>
    );
  }
}

IconStar.defaultProps = {
  height: 22
};

export default IconStar;
