import React, { Component } from "react";

class IconShare extends Component {
  render() {
    let { className, height } = this.props;

    return (
      <svg
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        viewBox="0 0 24 24"
        height={height}
        className={`icon ${className ? className : ""}`}
      >
        <circle cx="18" cy="5" r="3" />
        <circle cx="6" cy="12" r="3" />
        <circle cx="18" cy="19" r="3" />
        <path d="M8.59 13.51l6.83 3.98M15.41 6.51l-6.82 3.98" />
      </svg>
    );
  }
}

IconShare.defaultProps = {
  height: 22
};

export default IconShare;
