import React, { Component } from "react";

class IconApps extends Component {
  render() {
    let { className, height } = this.props;

    return (
      <svg viewBox="0 0 22 22" height={height} className={`icon ${className ? className : ""}`}>
        <g
          fill="currentColor"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
          transform="translate(2 2)"
        >
          <circle cx="1" cy="9" r="1" />
          <circle cx="1" cy="1" r="1" />
          <circle cx="1" cy="17" r="1" />
          <circle cx="9" cy="9" r="1" />
          <circle cx="9" cy="1" r="1" />
          <circle cx="9" cy="17" r="1" />
          <circle cx="17" cy="9" r="1" />
          <circle cx="17" cy="1" r="1" />
          <circle cx="17" cy="17" r="1" />
        </g>
      </svg>
    );
  }
}

IconApps.defaultProps = {
  height: 21
};

export default IconApps;
