import React, { Component } from "react";
import { connect } from "react-redux";

import { dispatchToProps as seqDP } from "../../../../store/sequences-actions";

import Card from "../../../library/card";
import ToggleSwitch from "../../../library/toggleSwitch";

import Resources from "../../../../lib/resources";
import { isEmpty, formatDimensionalDate } from "../../../../lib/utils";
import MainLoader from "../../../mainLoader";

const dispatchToProps = dispatch => {
  return {
    ...dispatch(seqDP)
  };
};

class SequencesTabContent extends Component {
  toggleSequenceActivation(row) {
    const { customerSequenceId, isActive } = row;
    this.props.postSequence(this.props.companyId, { customerSequenceId, isActive: !isActive });
  }

  render() {
    const sequences = this.props.sequencesStore.sequences;

    if (isEmpty(sequences) && this.props.sequencesStore.isFetchingSequences) {
      return <MainLoader className="mt-5" fullScreen />;
    }

    return (
      <div className="sequence-cards-container">
        {sequences.map(sequence => {
          return (
            <Card
              className="sequence-card"
              clickable
              onClick={() => {
                this.props.editSequence(sequence);
              }}
              key={sequence.customerSequenceId}
            >
              <div className="sequence-card__row">
                <h2>{sequence.customerSequenceName}</h2>
                <div className="sequence-card__last-generated">
                  {Resources.LastGeneratedDate(formatDimensionalDate(sequence.modifiedDate))}
                </div>
              </div>
              <div className="sequence-card__row">
                <div className="sequence-card__description">{sequence.description}</div>
                <ToggleSwitch
                  checked={sequence.isActive}
                  onChange={() => this.toggleSequenceActivation(sequence)}
                  text={sequence.isActive ? Resources.Active : Resources.Inactive}
                />
              </div>
            </Card>
          );
        })}
      </div>
    );
  }
}
const storeToProps = store => {
  return {
    sequencesStore: store.sequences
  };
};
export default connect(
  storeToProps,
  dispatchToProps
)(SequencesTabContent);
