import React, { Component } from "react";
import Resources from "../lib/resources";
import Icons from "../lib/icons";
import ModalDialog from "./modalDialog";
import SetUpSelectInbox from "./setupSelectInbox";
import SetUpConnectEmail from "./setupConnectEmail";
import SetUpForwardEmail from "./setupForwardEmail";
import SetUpConnectionVerified from "./setupConnectionVerified";
import SignUp from "./connector_registration/signUp";
import CompanyConnectionGrid from "./connector_registration/companySelectionGrid";
import MainContentHeader from "./main_content_header/mainContentHeader";

class SetUpLandingPage extends Component {
  state = {
    setupProgressStage: "",
    isModalHidden: true,
    isVideoHidden: true,
    perspective: "",
    configureEmail: {
      isComplete: false
    }
  };

  showVideo = () => {
    this.setState({ isVideoHidden: !this.state.isVideoHidden });
  };

  configureEmail = () => {
    this.setState({
      setupProgressStage: "SELECT_INBOX",
      isModalHidden: false,
      configureEmail: {
        ...this.state.configureEmail
      }
    });
  };

  setProgressStage = (setupProgressStage, perspective) => {
    if (!this.state.perspective) {
      this.setState({ perspective, setupProgressStage });
    } else {
      this.setState({ setupProgressStage });
    }
  };

  setCompleteOnEmailConfig = () => {
    this.setState({
      configureEmail: { ...this.state.configureEmail, isComplete: true },
      isModalHidden: true
    });
  };

  render() {
    let videoContent = (
      <div className="embed-responsive embed-responsive-16by9 mb-3">
        <iframe
          title="Instruction Video"
          className="embed-responsive-item"
          src="https://www.youtube.com/embed/zpOULjyy-n8?rel=0"
          allowfullscreen
        />
      </div>
    );

    let currentModalComponent;

    switch (this.state.setupProgressStage) {
      case "SELECT_INBOX":
        currentModalComponent = <SetUpSelectInbox progressStage={this.setProgressStage} />;
        break;
      case "CONNECT_EMAIL":
        currentModalComponent = (
          <SetUpConnectEmail
            companyId={this.props.companyId}
            perspective={this.state.perspective}
            progressStage={this.setProgressStage}
          />
        );
        break;
      case "FORWARD_EMAIL":
        currentModalComponent = (
          <SetUpForwardEmail perspective={this.state.perspective} progressStage={this.setProgressStage} />
        );
        break;
      case "CONNECTION_VERIFIED":
        currentModalComponent = (
          <SetUpConnectionVerified perspective={this.state.perspective} completeSetup={this.setCompleteOnEmailConfig} />
        );
        break;
      case "CONNECT_ERP_SYSTEM":
        currentModalComponent = (
          <SignUp
            content={
              <CompanyConnectionGrid
                companyId={this.props.match.params.companyId}
                companyLogoIconPath="/img/quickbooks.svg"
                click={this.stepOne}
              />
            }
            headerHeadlineTxt="Connect Your Accounting Software"
          />
        );
        break;
      default:
        break;
    }

    return (
      <React.Fragment>
        <MainContentHeader title={Resources.Setup} /* hideSearch={true} */ />
        <div className="manage-content">
          <div className="w-75 mx-auto text-center">
            <h2>{Resources.SetupHeader}</h2>
            <h5 className="dark-gray-font">{Resources.SetupSubHeader}</h5>
            <div className="progress w-75 mx-auto">
              <div
                className="progress-bar w-10"
                role="progressbar"
                aria-valuenow="75"
                aria-valuemin="0"
                aria-valuemax="100"
              />
            </div>
          </div>
          <div className="mt-3">
            <div className="border p-2 row">
              <div className="col-4 p-0">
                <span
                  className="badge badge-light d-inline-block float-left mr-2"
                  style={{
                    fontSize: "1.5em",
                    height: "35px",
                    width: "35px",
                    borderRadius: "50%",
                    lineHeight: "1.2em"
                  }}
                >
                  1
                </span>
                <div className="d-inline-block text-center" style={{ width: "calc(100% - 45px)" }}>
                  <h3>{Resources.Introduction}</h3>
                  <div className="fab fa-leanpub fa-4x" />
                </div>
              </div>
              <p className="col-5 mt-3">{Resources.SetupBlurb1}</p>
              <div className="col-3">
                <button onClick={this.showVideo} className="w-100 mt-4">
                  {this.state.isVideoHidden
                    ? Resources.Watch + " " + Resources.Video
                    : Resources.Hide + " " + Resources.Video}
                </button>
              </div>
            </div>
            <div className="text-center">
              <span className={`${Icons.dropdown} fa-5x`} />
            </div>
            {this.state.isVideoHidden || videoContent}
            <div className="border p-3">
              <div>
                <span
                  className="badge badge-light d-inline-block float-left mr-5"
                  style={{
                    fontSize: "1.5em",
                    height: "35px",
                    width: "35px",
                    borderRadius: "50%",
                    lineHeight: "1.2em"
                  }}
                >
                  2
                </span>
                <h3 className="mb-4 pt-1">{Resources.SetupBlurb2}</h3>
                <hr />
              </div>
              <div className="row">
                <div className="col-9 dark-gray-font">
                  <h3>{Resources.SetupBlurb3}</h3>
                  <p>{Resources.SetupBlurb4}</p>
                </div>
                <div className="col-3">
                  <button
                    onClick={this.configureEmail}
                    className={(this.state.configureEmail.isComplete && "d-none") + " w-100 mt-4"}
                  >
                    {Resources.Configure + " " + Resources.Email}
                  </button>

                  <div className={this.state.configureEmail.isComplete || "d-none"}>
                    <h4 style={{ color: "#53f442" }}>
                      <b>Complete</b>
                    </h4>
                    <div onClick={this.configureEmail}>{Resources.ConnectAnother}</div>
                  </div>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-9 dark-gray-font">
                  <h3>{Resources.ConnectYourERPToLockstep}</h3>
                  <p>{Resources.SetupBlurb6}</p>
                </div>
                <div className="col-3">
                  <button
                    onClick={() =>
                      this.setState({
                        setupProgressStage: "CONNECT_ERP_SYSTEM",
                        isModalHidden: false
                      })
                    }
                    className="w-100 mt-3"
                  >
                    {Resources.ConnectAcctSystem}
                  </button>

                  <div className="d-none">
                    <h4 style={{ color: "#53f442" }}>
                      <b>{Resources.Complete}</b>
                    </h4>
                    <div onClick={this.configureEmail}>{Resources.ConnectAnother}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ModalDialog
            isOpen={this.state.isModalHidden ? false : true}
            footer={null}
            title={null}
            content={currentModalComponent}
            bodyClassName={(this.state.setupProgressStage === "CONNECT_ERP_SYSTEM" ? "signUpModal" : "") + " rounded-0"}
            className={this.state.setupProgressStage === "CONNECT_ERP_SYSTEM" ? "" : "v-center-modal"}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default SetUpLandingPage;
