import { actions } from "./localization-actions";
import { isEmpty } from "../lib/utils";

const defaultLanguageSettings = {
  languageId: "en-US",
  resources: {},

  gettingLanguages: false,
  gotLanguages: false,
  failedGetLanguages: false,

  gettingLanguage: false,
  gotLanguage: false,
  failedGetLanguage: false
};

function localizationReducer(state = defaultLanguageSettings, action) {
  switch (action.type) {
    case actions.SET_LANGUAGE:
      window.strings = generateStringsMap(state.resources[action.languageId]);
      return { ...state, ...{ languageId: action.languageId } };

    case actions.GETTING_LANGUAGES:
      return { ...state, gettingLanguages: true, gotLanguages: false, failedGetLanguages: false };
    case actions.GOT_LANGUAGES:
      if (!isEmpty(action.languages[state.languageId])) {
        window.strings = generateStringsMap(action.languages[state.languageId]);
      }
      return {
        ...state,
        gettingLanguages: false,
        gotLanguages: true,
        failedGetLanguages: false,
        resources: { ...state.resources, ...action.languages }
      };
    case actions.FAILED_GET_LANGUAGES:
      return { ...state, gettingLanguages: false, gotLanguages: false, failedGetLanguages: true };

    case actions.GETTING_LANGUAGE:
      return { ...state, gettingLanguage: true, gotLanguage: false, failedGetLanguage: false };
    case actions.GOT_LANGUAGE:
      if (state.languageId === action.language.languageId) {
        window.strings = generateStringsMap(action.language);
      }
      return {
        ...state,
        gettingLanguage: false,
        gotLanguage: true,
        failedGetLanguage: false,
        resources: { ...state.resources, [action.language.languageId]: action.language }
      };
    case actions.FAILED_GET_LANGUAGE:
      return { ...state, gettingLanguage: false, gotLanguage: false, failedGetLanguage: true };
    default:
      return state;
  }
}

function generateStringsMap(language) {
  if (isEmpty(language)) {
    return {};
  }
  let stringsMap = {};
  language.value.forEach(string => (stringsMap[string.name.toLocaleUpperCase()] = string.value));
  return stringsMap;
}

export default localizationReducer;
