import React, { Component } from "react";
import { connect } from "react-redux";
import { isEmpty } from "../lib/utils";

import { dispatchToProps as moDP } from "../store/modal-actions";
import { dispatchToProps as manDP } from "../store/manage-actions";

import Resources from "../lib/resources";
import Modal from "react-modal";

import Mainloader from "./mainLoader";

const dispatchToProps = dispatch => ({
  ...moDP(dispatch),
  ...manDP(dispatch)
});

class testEmailConfigModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showImapConfigObject: false,
      showSmtpConfigObject: false
    };
  }

  componentDidMount() {
    const { companyId, accountingGroupId } = this.props;

    this.props.testEmailConfig(companyId, accountingGroupId);
  }

  render() {
    const { companyId, accountingGroupId } = this.props;
    const configTestStatus = this.props.getEmailConfigTestStatus(companyId, accountingGroupId);
    const configTest = this.props.getEmailConfigTest(companyId, accountingGroupId);

    return (
      <Modal
        isOpen={this.props.modal.showModal}
        onRequestClose={() => this.props.hideModal()}
        contentLabel="Example Modal"
        className="custom-modal-wide"
        overlayClassName="custom-modal-overlay"
      >
        {/* <div className="custom-modal-overlay"></div>
          <div className="custom-modal"> */}
        <div className="custom-modal-header">{Resources.TestEmailConfiguration}</div>
        <div className="custom-modal-divider" />
        <div className="custom-modal-content">
          <div className="test-email-config-modal__status">
            {configTestStatus === "loading"
              ? Resources.TestingEmailConfigurationForName(this.props.accountingGroupName)
              : Resources.CompletedTestingEmailConfigurationForName(this.props.accountingGroupName)}
          </div>
          {configTestStatus === "loading" ? (
            <Mainloader fullScreen className="test-email-config-modal__loader" />
          ) : (
            <div className="test-email-config-modal__content">
              {!isEmpty(configTest.imapConfig) && (
                <div className="test-email-config-modal__content__config">
                  <div>
                    <h2 className="test-email-config-modal__content__config__header">
                      {Resources.ImapConfigTestResults}
                    </h2>
                    <div className="test-email-config-modal__content__config__item">
                      <span className="test-email-config-modal__content__config__label">
                        {Resources.ImapConfigUsed}:{" "}
                      </span>
                      <button
                        className="button-secondary d-inline p-0"
                        onClick={() => this.setState({ showImapConfigObject: !this.state.showImapConfigObject })}
                      >
                        {this.state.showImapConfigObject ? Resources.HideConfig : Resources.ShowConfig}
                      </button>
                      {this.state.showImapConfigObject && (
                        <pre>
                          <code>{JSON.stringify(configTest.imapConfig, null, "\t")}</code>
                        </pre>
                      )}
                    </div>
                    <div className="test-email-config-modal__content__config__item">
                      <span className="test-email-config-modal__content__config__label">
                        {Resources.ImapConnectionStatus}:{" "}
                      </span>
                      {configTest.imapConfigStatus.canConnect ? (
                        <span className="badge badge-active">{Resources.SuccessfullyConnected}</span>
                      ) : (
                        <span className="badge badge-rejected">{Resources.FailedToConnect}</span>
                      )}
                    </div>
                    <div className="test-email-config-modal__content__config__item">
                      <span className="test-email-config-modal__content__config__label">
                        {Resources.ImapAuthentication}:{" "}
                      </span>
                      {configTest.imapConfigStatus.canAuthorize ? (
                        <span className="badge badge-active">{Resources.SuccessfullyAuthorized}</span>
                      ) : (
                        <span className="badge badge-rejected">{Resources.FailedToAuthenticate}</span>
                      )}
                    </div>
                    {!isEmpty(configTest.imapConfigStatus.errorString) && (
                      <div className="test-email-config-modal__content__config__item">
                        <span className="test-email-config-modal__content__config__label">
                          {Resources.ErrorMessage}:{" "}
                        </span>
                        <div>{configTest.imapConfigStatus.errorString}</div>
                      </div>
                    )}
                  </div>
                </div>
              )}
              {!isEmpty(configTest.smtpConfig) && (
                <div className="test-email-config-modal__content__config">
                  <div>
                    <h2 className="test-email-config-modal__content__config__header">
                      {Resources.SmtpConfigTestResults}
                    </h2>
                    <div className="test-email-config-modal__content__config__item">
                      <span className="test-email-config-modal__content__config__label">
                        {Resources.SmtpConfigUsed}:{" "}
                      </span>
                      <button
                        className="button-secondary d-inline p-0"
                        onClick={() => this.setState({ showSmtpConfigObject: !this.state.showSmtpConfigObject })}
                      >
                        {this.state.showSmtpConfigObject ? Resources.HideConfig : Resources.ShowConfig}
                      </button>
                      {this.state.showSmtpConfigObject && (
                        <pre>
                          <code>{JSON.stringify(configTest.smtpConfig, null, "\t")}</code>
                        </pre>
                      )}
                    </div>
                    <div className="test-email-config-modal__content__config__item">
                      <span className="test-email-config-modal__content__config__label">
                        {Resources.SmtpConnectionStatus}:{" "}
                      </span>
                      {configTest.smtpConfigStatus.canConnect ? (
                        <span className="badge badge-active">{Resources.SuccessfullyConnected}</span>
                      ) : (
                        <span className="badge badge-rejected">{Resources.FailedToConnect}</span>
                      )}
                    </div>
                    <div className="test-email-config-modal__content__config__item">
                      <span className="test-email-config-modal__content__config__label">
                        {Resources.SmtpAuthentication}:{" "}
                      </span>
                      {configTest.smtpConfigStatus.canAuthorize ? (
                        <span className="badge badge-active">{Resources.SuccessfullyAuthorized}</span>
                      ) : (
                        <span className="badge badge-rejected">{Resources.FailedToAuthenticate}</span>
                      )}
                    </div>
                    {!isEmpty(configTest.smtpConfigStatus.errorString) && (
                      <div className="test-email-config-modal__content__config__item">
                        <span className="test-email-config-modal__content__config__label">
                          {Resources.ErrorMessage}:{" "}
                        </span>
                        <div>{configTest.smtpConfigStatus.errorString}</div>
                      </div>
                    )}
                  </div>
                </div>
              )}

              {!isEmpty(configTest.dkimConfig) && (
                <div className="test-email-config-modal__content__config">
                  <div>
                    <h2 className="test-email-config-modal__content__config__header">
                      {Resources.DkimConfigTestResults}
                    </h2>
                    <div className="test-email-config-modal__content__config__item">
                      <span className="test-email-config-modal__content__config__label">{Resources.DkimDomain}: </span>
                      <span>{configTest.dkimConfig.dkimDomain}</span>
                    </div>
                    <div className="test-email-config-modal__content__config__item">
                      <span className="test-email-config-modal__content__config__label">
                        {Resources.DkimRecordsStatus}:{" "}
                      </span>
                      {configTest.dkimConfigStatus ? (
                        <span className="badge badge-active">{Resources.SuccessfullyFoundRecords}</span>
                      ) : (
                        <span className="badge badge-rejected">{Resources.FailedToFindRecords}</span>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
          <div></div>

          <div className="flex-row modal-buttons">
            <button className="button-cancel float-left mr-1" onClick={this.props.hideModal}>
              {Resources.Close}
            </button>
          </div>
        </div>
        {/* </div> */}
      </Modal>
    );
  }
}

const storeToProps = store => {
  return {
    modal: store.modal,
    manage: store.manage
  };
};

export default connect(storeToProps, dispatchToProps)(testEmailConfigModal);
