import React, { Component } from "react";
import { connect } from "react-redux";

import { dispatchToProps as seqDP } from "../../store/sequences-actions";

import Card from "../library/card";
import Resources from "../../lib/resources";
import IconSmile from "../library/icons/iconSmile";
import IconPen from "../library/icons/iconPen";
import IconPercent from "../library/icons/iconPercent";
import IconSlash from "../library/icons/iconSlash";
import IconMissing from "../library/icons/iconMissing";
import IconGift from "../library/icons/iconGift";
import IconAlertCircle from "../library/icons/iconAlertCircle";
import IconInvoice from "../library/icons/iconInvoice";
import IconBell from "../library/icons/iconBell";
import IconFile from "../library/icons/iconFile";

const dispatchToProps = dispatch => {
  return {
    ...dispatch(seqDP)
  };
};

class SequencesTabContent extends Component {
  renderIcon(iconClass) {
    switch (iconClass) {
      case "onboarding":
        return <IconSmile />;
      case "custom":
        return <IconPen />;
      case "percent":
        return <IconPercent />;
      case "hold":
        return <IconSlash />;
      case "missing":
        return <IconMissing height={28} />;
      case "anniversary":
        return <IconGift />;
      case "escalation":
        return <IconAlertCircle />;
      case "invoice":
        return <IconInvoice />;
      case "reminder":
        return <IconBell />;
      case "monthly":
        return <IconFile />;
      default:
        return null;
    }
  }
  render() {
    const templates = this.props.sequencesStore.sequenceSamples;

    return (
      <div>
        <h2 className="card-title" style={{ marginBottom: "1.06rem" }}>
          {Resources.CreateASequence}
        </h2>
        {templates.map(template => (
          <Card
            className="sequence-template-card"
            key={template.customerSequenceId}
            onClick={() => this.props.onSelectSequenceTemplate(template)}
          >
            <div className="flex">
              <div className="flex-align-center" style={{ marginRight: "1.6rem" }}>
                {this.renderIcon(template.customerSequenceIconClass)}
              </div>
              <div className="flex-column">
                <div className="sequence-template-title">{template.customerSequenceName}</div>
                <div className="sequence-template-description">{template.description}</div>
              </div>
            </div>
          </Card>
        ))}
        {/* <Card className="sequence-template-card" onClick={() => this.props.onSelectSequenceTemplate()}>
          <div className="flex">
            <div className="flex-align-center" style={{ marginRight: "1.6rem" }}>
              <IconPen />
            </div>
            <div className="flex-column">
              <div className="sequence-template-title">{Resources.CreateMyOwn}</div>
              <div className="sequence-template-description">{Resources.CustomSequenceMessage}</div>
            </div>
          </div>
        </Card> */}
      </div>
    );
  }
}
const storeToProps = store => {
  return {
    sequencesStore: store.sequences
  };
};
export default connect(
  storeToProps,
  dispatchToProps
)(SequencesTabContent);
