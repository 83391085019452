import React, { Component } from "react";
import { connect } from "react-redux";
import Icon from "../../lib/icons";
import ProgressBar from "./progressBar";
import { dispatchToProps } from "../../store/connector-actions";

class SignUpHeader extends Component {
  render() {
    let { toggleConnectorRegModal } = this.props;
    return (
      <div id="signup-header">
        <div className="pt-2">
          <div onClick={toggleConnectorRegModal} className={Icon.close + " mr-2 clickable float-right"} />
          <h4 className="text-center mt-2">{this.props.headline}</h4>
        </div>
        <ProgressBar currentStep={this.currentStep} />
      </div>
    );
  }
}

export default connect(
  null,
  dispatchToProps
)(SignUpHeader);
