import React, { Component } from "react";

export default class ProgressBar extends Component {
  render() {
    return (
      <div className="progress-bar-container">
        <div className={"side-progress-line-bar "} />
        <img alt="" width="7.5%" src="/img/pencil.svg" className={"svg-icon "} />
        <div className={"middle-progress-line-bar "} />
        <img alt="" width="7.5%" src="/img/artboard.svg" className={"svg-icon "} />
        <div className={"middle-progress-line-bar "} />
        <img alt="" width="7.5%" src="/img/clipboard.svg" className={"svg-icon "} />
        <div className={"middle-progress-line-bar "} />
        <img alt="" width="7.5%" src="/img/folder-3.svg" className={"svg-icon "} />
        <div className={"middle-progress-line-bar "} />
        <img alt="" width="7.5%" src="/img/stamp.svg" className={"svg-icon "} />
        <div className={"side-progress-line-bar"} />
      </div>
    );
  }
}
