import React, { Component } from "react";

class IconDollarSign extends Component {
  render() {
    let { className, height } = this.props;

    return (
      <svg viewBox="0 0 14 24" height={height} className={`icon ${className ? className : ""}`}>
        <g fill="none" fillRule="evenodd">
          <g stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
            <path d="M7 1v22M12 5H4.5C2.56700338 5 1 6.56700338 1 8.5S2.56700338 12 4.5 12h5c1.9329966 0 3.5 1.5670034 3.5 3.5S11.4329966 19 9.5 19H1" />
          </g>
        </g>
      </svg>
    );
  }
}

IconDollarSign.defaultProps = {
  height: 24
};

export default IconDollarSign;
