import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment";

import { dispatchToProps as moDP } from "../../store/modal-actions";
import { dispatchToProps as laDP } from "../../store/ledger-actions";
import { dispatchToProps as caDP } from "../../store/company-actions";
import { dispatchToProps as convDP } from "../../store/conversations-actions";
import { dispatchToProps as gaDP } from "../../store/general-actions";
import { dispatchToProps as userDP } from "../../store/user-actions";
import { dispatchToProps as statDP } from "../../store/statements-actions";

import Resources from "../../lib/resources";
import IconClose from "../library/icons/iconClose";
import TextInput from "../library/textInput";
import HtmlEditor from "../htmlEditor";
import IconUpload from "../library/icons/iconUpload";
import UserAvatar from "../library/userAvatar";
import TableData from "../library/tableData";

import Modal from "react-modal";
import { onBlurCheckFocusable, isEmpty, find, removeAt, compareDates, updateSelectedRows } from "../../lib/utils";
import MainLoader from "../mainLoader";
import InvoiceTable from "./invoiceTable";

const dispatchToProps = dispatch => ({
  ...moDP(dispatch),
  ...laDP(dispatch),
  ...caDP(dispatch),
  ...convDP(dispatch),
  ...gaDP(dispatch),
  ...userDP(dispatch),
  ...statDP(dispatch)
});

class MessageFlyout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      subject: "",
      htmlBody: "",
      textBody: "",
      statementHtml: "",
      selectedRows: [],
      attachments: [],
      invoiceLedgerHashMap: {},

      showFlyout: true
    };

    this.toggleSelectRow = this.toggleSelectRow.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
    this.hideFlyout = this.hideFlyout.bind(this);
  }

  componentDidMount() {
    if (this.props.selectedInvoices.length > 0) {
      const selectedRows = [];
      for (let i = 0; i < this.props.selectedInvoices.length; i++) {
        selectedRows.push(i);
      }
      this.setState({ selectedRows });
    }
    this.tryUpdate();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loading === true && (this.props.loading === false || isEmpty(this.props.loading))) {
      this.tryUpdate();
    }
  }

  tryUpdate() {
    const { conversationId } = this.props;

    if (
      !isEmpty(conversationId) &&
      (isEmpty(this.props.conversationsStore.conversation) ||
        this.props.conversationsStore.conversation.conversationId !== conversationId)
    ) {
      this.props.updateConversationReadStatuses([conversationId], true);
      this.props.getConversation(conversationId);
    }
  }

  componentWillUnmount() {
    this.props.clearConversation();
  }

  hideFlyout() {
    this.setState({ showFlyout: false });
  }

  toggleSelectRow(i) {
    let newSelectedRows = updateSelectedRows(i, this.state.selectedRows, this.props.selectedInvoices.length);

    this.setState({ selectedRows: newSelectedRows });
  }

  clearSelectedRows() {
    if (!isEmpty(this.props.clearSelectedRows)) {
      this.props.clearSelectedRows();
    }
  }

  sendMessage() {
    const { htmlBody, textBody, subject, attachments, statementHtml } = this.state;
    const { companyId, perspectiveId, withCompanyId } = this.props.modalStore.flyoutProps;

    const companyInfo = this.props.getCompanyInfo(companyId);
    const cAddress = (((companyInfo || {}).connectorEmail || {}).synthetic || {})[perspectiveId];
    const companyEmail = isEmpty(cAddress) ? undefined : { name: companyInfo.companyName, address: cAddress };

    const withCompanyInfo = this.props.getCompanyInfo(withCompanyId);
    const wPerspectiveId = (find(withCompanyInfo.connectorEmails, cEmail => cEmail.perspective === "customers") || {})
      .perspectiveId;
    const wcAddress = (((withCompanyInfo || {}).connectorEmail || {}).synthetic || {})[wPerspectiveId];
    const withCompanyEmail = isEmpty(wcAddress) ? undefined : { name: withCompanyInfo.companyName, address: wcAddress };

    this.setState({ sending: true });
    this.props.displayNotification("sendingMessageNotification");

    const reqBody = {
      htmlBody: htmlBody + statementHtml,
      textBody,
      subject,
      from: [companyEmail],
      to: [withCompanyEmail]
    };

    if (!isEmpty(this.props.conversationId)) {
      reqBody.inReplyTo = this.props.conversationId;
    }

    if (!isEmpty(this.state.selectedRows)) {
      reqBody.invoiceIds = this.state.selectedRows.map(rowIndex => this.props.selectedInvoices[rowIndex].ledgerHash);
    }

    this.props
      .createDraft(companyId, "email", reqBody)
      .then(response => {
        const draftId = response.data;
        if (!isEmpty(attachments)) {
          return Promise.all([
            ...attachments.map(a => {
              if (!isEmpty(a.companyDocumentId)) {
                return this.props.addDocumentAttachment(draftId, a.companyDocumentId);
              }
              return this.props.addAttachment(draftId, a);
            })
          ]).then(res => draftId);
        } else {
          return draftId;
        }
      })
      .then(draftId => this.props.commitDraft(draftId))
      .then(res => {
        this.setState({ sending: false });
        this.props.refetchConversations();
        this.clearSelectedRows();
        this.hideFlyout();
      })
      .catch(err => {
        this.setState({ sending: false });
        this.clearSelectedRows();
        this.hideFlyout();
      });
  }

  ensureInvoicesFetched(invoiceLedgerHashes) {
    const { companyId, perspectiveId, withCompanyId } = this.props.modalStore.flyoutProps;

    let missingIds = invoiceLedgerHashes.map(ledgerHash =>
      isEmpty(this.state.invoiceLedgerHashMap[ledgerHash]) ? ledgerHash : null
    );
    missingIds = missingIds.filter(value => !isEmpty(value));

    if (isEmpty(missingIds)) {
      return true;
    }

    !this.props.statementsStore.fetchingAllInvoices &&
      this.props
        .fetchAllInvoices(companyId, perspectiveId, withCompanyId, {
          top: 100000000,
          filter: `LedgerHash%20in%20${missingIds.join()}`
        })
        .then(response => {
          let newInvoiceLedgerHashMap = { ...this.state.invoiceLedgerHashMap };
          response.value.forEach(invoice => {
            newInvoiceLedgerHashMap[invoice.ledgerHash] = { ...invoice };
          });

          this.setState({ invoiceLedgerHashMap: newInvoiceLedgerHashMap });
        });
    return false;
  }

  renderThread() {
    const { conversation } = this.props.conversationsStore;

    const threadItems = conversation.conversationAddresses.map((addr, i) => {
      const entry = this.props.getEntry(addr.address);
      if (isEmpty(entry)) {
        this.props.fetchEntry(addr.address, addr.isDraft);
        return null;
      }

      if (
        i + 1 < conversation.conversationAddresses.length &&
        ["applicationresponse", "debitnote"].includes(conversation.conversationAddresses[i + 1].type)
      ) {
        return null;
      }

      let attachments = [];

      if (addr.hasAttachments) {
        attachments = this.props.getAttachments(entry.ledgerHash);
        if (isEmpty(attachments)) {
          this.props.fetchAttachments(entry.ledgerHash);
        }
      }

      let result = {
        type: addr.type,
        entry,
        data: entry.asJson,
        addr,
        attachments
      };

      result.date = moment(result.data.date || entry.dateCreated, "YYYY-MM-DDTHH:mm:ssZ");
      return result;
    });

    threadItems.sort((a, b) => {
      if (a === null) {
        return 1;
      }
      if (b === null) {
        return -1;
      }
      if ((a.addr || {}).isDraft !== (b.addr || {}).isDraft)
        return a.addr.isDraft === true && b.addr.isDraft !== true ? -1 : 1;
      return compareDates(a.date, b.date);
    });

    return threadItems.map((item, i) => {
      if (isEmpty(item)) {
        return null;
      }

      let data = [];

      let isSenderMe = item.entry.postingUserId === this.props.getMyUserIdFromToken();
      const senderName = isSenderMe
        ? (this.props.accountsStore.selectedCompany || {}).companyName || ""
        : this.props.withCompanyName;
      const avatarClassName = isSenderMe ? "user-avatar-me mr-2" : "user-avatar-other mr-2";

      let threadItemContent;

      switch (item.type) {
        case "applicationresponse":
          let invoiceLedgerHashes = [];
          let documentResponse = item.entry.asJson.ApplicationResponse.DocumentResponse;
          let documentReference =
            documentResponse.DocumentReference instanceof Array
              ? documentResponse.DocumentReference
              : [documentResponse.DocumentReference];

          invoiceLedgerHashes = documentReference.map(item => {
            return item.ID["#text"];
          });

          if (this.ensureInvoicesFetched(invoiceLedgerHashes)) {
            data = invoiceLedgerHashes.map(ledgerHash => {
              return this.state.invoiceLedgerHashMap[ledgerHash];
            });
            data = data.filter(value => !isEmpty(value));
          }

          threadItemContent = (
            <React.Fragment>
              <div className="portal-conversation-thread-item-body flyout-table-container">
                <div
                  dangerouslySetInnerHTML={{ __html: item.entry.asJson.ApplicationResponse.Note["#cdata-section"] }}
                />
                <div className="mb-4">
                  {Resources.ReasonForDispute}:{" "}
                  {item.entry.asJson.ApplicationResponse.DocumentResponse.Response.ResponseCode["#text"]}
                </div>
                <InvoiceTable data={data} />
              </div>
            </React.Fragment>
          );
          break;
        case "debitnote":
          let debitNoteInvoiceLedgerHashes = [];
          let billingReferences = item.entry.asJson.DebitNote.BillingReference;
          billingReferences = billingReferences instanceof Array ? billingReferences : [billingReferences];

          debitNoteInvoiceLedgerHashes = billingReferences.map(
            billingReference => billingReference.InvoiceDocumentReference.ID["#text"]
          );

          if (this.ensureInvoicesFetched(debitNoteInvoiceLedgerHashes)) {
            data = debitNoteInvoiceLedgerHashes.map(ledgerHash => {
              return this.state.invoiceLedgerHashMap[ledgerHash];
            });
            data = data.filter(value => !isEmpty(value));
          }

          threadItemContent = (
            <React.Fragment>
              <div className="portal-conversation-thread-item-body  flyout-table-container">
                <div dangerouslySetInnerHTML={{ __html: item.entry.asJson.DebitNote.Note["#cdata-section"] }} />
                <InvoiceTable data={data} />
              </div>
            </React.Fragment>
          );
          break;
        case "email":
          threadItemContent = (
            <div
              className="portal-conversation-thread-item-body"
              dangerouslySetInnerHTML={{ __html: item.data.htmlBody }}
            />
          );
          break;
        default:
          threadItemContent = null;
          break;
      }

      return (
        <div key={`thread-item${i}`} className="portal-conversation-thread-item">
          <div className="flex-split flex-align-center">
            <div className="flex-align-center">
              <UserAvatar className={avatarClassName} height={15} />
              <span className="portal-thread-item-sender-name">{senderName}</span>
            </div>
            <div style={{ textAlign: "right" }}>
              <div className="thread-item-age">
                {moment(item.date).fromNow(true)} {Resources.Ago.toLowerCase()}
              </div>
            </div>
          </div>
          {threadItemContent}
        </div>
      );
    });
  }

  render() {
    const { conversationId, conversationSubject, loading } = this.props;

    const { conversation } = this.props.conversationsStore;
    const isFetchingEntries = !isEmpty(this.props.ledgerStore.fetchingEntries);

    let isLoading = loading || isFetchingEntries;

    const isNew = !loading && isEmpty(conversationId);
    const showEditor = isNew || this.state.reply === true;

    const disableSend = isEmpty(this.state.htmlBody) || (isNew && isEmpty(this.state.subject));

    if (isLoading || conversation.conversationId !== this.props.conversationId) {
      return (
        <Modal
          isOpen={this.state.showFlyout}
          onRequestClose={this.hideFlyout}
          onAfterClose={this.props.hideFlyout}
          contentLabel="Example Modal"
          className="flyout message-flyout"
          overlayClassName="flyout-overlay"
          closeTimeoutMS={400}
        >
          <MainLoader fullScreen />
        </Modal>
      );
    }

    return (
      <Modal
        isOpen={this.state.showFlyout}
        onRequestClose={this.hideFlyout}
        onAfterClose={this.props.hideFlyout}
        contentLabel="Example Modal"
        className="flyout message-flyout"
        overlayClassName="flyout-overlay"
        closeTimeoutMS={400}
      >
        <div className="flyout-heading">
          <div>{isNew ? Resources.SendAMessage : conversationSubject || conversation.subject}</div>
          <div onClick={this.hideFlyout} className="flyout-heading-close">
            <IconClose />
          </div>
        </div>
        <div className="flyout-content">
          {isNew ? (
            <React.Fragment>
              <div className={`portal-input-label ${this.state.focusedField === "subject" ? " focused" : ""}`}>
                {Resources.Subject}
              </div>
              <TextInput
                inputOnChange={e => this.setState({ subject: e.target.value })}
                onFocus={() => this.setState({ focusedField: "subject" })}
                onBlur={e => onBlurCheckFocusable(() => this.setState({ focusedField: null }))}
                textValue={this.state.subject}
              />
              <div className={`portal-input-label mt-5 ${this.state.focusedField === "message" ? " focused" : ""}`}>
                {Resources.Message}
              </div>
            </React.Fragment>
          ) : (
            this.renderThread()
          )}
          {showEditor ? (
            <React.Fragment>
              <HtmlEditor
                onFocus={() => this.setState({ focusedField: "message" })}
                onBlur={e => onBlurCheckFocusable(() => this.setState({ focusedField: null }))}
                hideToolbar={this.state.focusedField !== "message"}
                htmlContent={this.state.htmlBody}
                updateHtmlContent={htmlBody => this.setState({ htmlBody })}
                updateTextContent={textBody => this.setState({ textBody })}
              />
              {this.state.attachments.map((file, i) => (
                <div className="font-turquoise">
                  <span>{file.name}</span>
                  <span onClick={() => this.setState({ attachments: removeAt(this.state.attachments, i) })}>
                    <IconClose height={15} />
                  </span>
                </div>
              ))}
              <button
                className="button-secondary mb-5"
                onClick={() => window.document.getElementById("uploadFiles").click()}
              >
                <IconUpload height="16" style={{ marginRight: "0.65rem" }} />
                {Resources.UploadAttachment}
              </button>
              {!isEmpty(this.props.selectedInvoices) && (
                <div style={{ marginBottom: "1.6rem" }}>
                  <div className="portal-input-label">{Resources.InvoicesSelectedForMessage}</div>
                  <TableData
                    noSort
                    noGrow
                    pagination
                    data={this.props.selectedInvoices}
                    columns={this.props.columns}
                    rowHeight="4rem"
                    maxHeight={`${4 * this.props.selectedInvoices.length + 2}rem`}
                    rowClassName="statements-view-row no-hover"
                    selectedRows={this.state.selectedRows}
                    onRowSelectToggle={i => this.toggleSelectRow(i)}
                  />
                </div>
              )}
              <button
                disabled={disableSend}
                className="portal-button-green"
                onClick={this.sendMessage}
                style={{ marginTop: "5.4rem" }}
              >
                {Resources.Send}
              </button>
            </React.Fragment>
          ) : (
            !isLoading && (
              <button className="portal-button-green" onClick={() => this.setState({ reply: true })}>
                {Resources.Reply}
              </button>
            )
          )}

          <input
            className="hidden-input"
            type="file"
            id="uploadFiles"
            multiple
            style={{ visibility: "hidden" }}
            onChange={e => {
              const files = [...e.target.files];
              this.setState({ attachments: [...this.state.attachments, ...files] });
              e.target.value = "";
            }}
          />
        </div>
      </Modal>
    );
  }
}

const storeToProps = store => {
  return {
    modalStore: store.modal,
    conversationsStore: store.conversations,
    ledgerStore: store.ledger,
    accountsStore: store.accounts,
    statementsStore: store.statements
  };
};

MessageFlyout.defaultProps = {
  selectedInvoices: [],
  fetchingTemplate: () => false
};

export default withRouter(connect(storeToProps, dispatchToProps)(MessageFlyout));
