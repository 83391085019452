import React, { Component } from "react";
import { connect } from "react-redux";
import Icons from "../lib/icons";

import { dispatchToProps as moDP } from "../store/modal-actions";
import { dispatchToProps as connDP } from "../store/connector-actions";

import Resources from "../lib/resources";
import Modal from "react-modal";

const dispatchToProps = dispatch => ({
  ...moDP(dispatch),
  ...connDP(dispatch)
});

class DisconnectConnectorModal extends Component {
  constructor(props) {
    super(props);
    this.disconnectConnector = this.disconnectConnector.bind(this);
  }

  disconnectConnector(companyId, connectorVersionId, connectorCompanyId) {
    return this.props
      .disconnectConnector(companyId, connectorCompanyId, connectorVersionId)
      .then(response => {
        return this.props.fetchCompanyConnectors(companyId);
      })
      .catch(err => {
        return false;
      });
  }

  render() {
    let modalProps = this.props.modalStore.modalProps || {};
    let { companyId, connectorVersionId, connectorDisplayName, connectorCompanyId } = modalProps;

    const isSubmitting = this.props.connectorStore.isDeletingCompanyPermssions;

    return (
      <Modal
        isOpen={this.props.modalStore.showModal}
        onRequestClose={() => this.props.hideModal()}
        contentLabel="Example Modal"
        className="custom-modal"
        overlayClassName="custom-modal-overlay"
      >
        <div className="custom-modal-header">
          <div>{Resources.ConnectorDisconnectWarning(connectorDisplayName)}</div>
          {isSubmitting && <span className={Icons.spinner} />}
        </div>
        {/* <div className="custom-modal-divider" /> */}
        <div className="custom-modal-content">
          <div className="d-flex justify-content-center mt-3">
            <button
              disabled={isSubmitting}
              className="onboarding-cancel-button"
              onClick={() => {
                this.props.hideModal();
              }}
            >
              {Resources.NoCancel}
            </button>
            <button
              disabled={isSubmitting}
              className="onboarding-button"
              onClick={() =>
                this.disconnectConnector(companyId, connectorVersionId, connectorCompanyId).then(() =>
                  this.props.hideModal()
                )
              }
            >
              {Resources.YesDisconnect}
            </button>
          </div>
        </div>
      </Modal>
    );
  }
}

const storeToProps = store => {
  return {
    modalStore: store.modal,
    connectorStore: store.connector
  };
};

export default connect(
  storeToProps,
  dispatchToProps
)(DisconnectConnectorModal);
