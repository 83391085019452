import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from "react-modal";
import { getRRule } from "../lib/utils";
import { withRouter } from "react-router-dom";

import DatePicker from "react-datepicker";
import moment from "moment";

import { dispatchToProps as ledgDP } from "../store/modal-actions";
import { dispatchToProps as conDP } from "../store/conversations-actions";
import { dispatchToProps as modDP } from "../store/ledger-actions";
import { dispatchToProps as congDP } from "../store/contextGroups-actions";

import Resources from "../lib/resources";
import IconClose from "./library/icons/iconClose";

const dispatchToProps = dispatch => ({
  ...ledgDP(dispatch),
  ...conDP(dispatch),
  ...modDP(dispatch),
  ...congDP(dispatch)
});

class SetReminderModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      remindDate: moment()
        .add(1, "day")
        .startOf("day")
        .set("hour", 8)
        .format("YYYY-MM-DD HH:mm:ss"),
      rrule: "",
      inputHasFocus: false
    };

    this.submit = this.submit.bind(this);
  }

  submit() {
    let rrule = getRRule(this.state.remindDate);
    const defaultReminderType = ((this.props.manageStore || {}).workflows[this.props.perspectiveId] || [])[0] || {};
    this.props.displayNotification("snoozingActivity");
    this.props
      .putReminder(
        this.props.companyId,
        defaultReminderType.ledgerHash,
        this.props.conversationId,
        rrule,
        this.state.remindDate
      )
      .then(() => {
        this.props.hideModal();
        this.props.actionComplete();
        this.props.getReminders(this.props.companyId, this.props.perspectiveId);
      });
  }

  render() {
    let modalContent;
    // const todaySelected = moment().isSame(moment(this.state.remindDate || moment()), "day");

    modalContent = (
      <React.Fragment>
        <DatePicker
          className="date-picker-input"
          calendarClassName="date-picker-calendar"
          dayClassName={d => "date-picker-day"}
          dateFormat="MMM d, yyyy"
          // startDate={new Date(this.state.fromDate)}
          // endDate={new Date(this.state.toDate)}
          minDate={
            new Date(
              moment()
                .add(1, "day")
                .startOf("day")
                .format()
            )
          }
          selected={new Date(this.state.remindDate)}
          // selectsStart
          onChange={d =>
            this.setState({
              remindDate: d
            })
          }
          disabled={this.props.creatingLedgerEntry}
        />
      </React.Fragment>
    );

    return (
      <Modal
        isOpen={this.props.modal.showModal}
        onRequestClose={this.props.hideModal}
        className="custom-modal custom-modal-wide"
        overlayClassName="custom-modal-overlay"
      >
        <div className="custom-modal-header custom-modal-header--small">
          <div>{Resources.ChooseACustomDateToSnoozeThisActivityUntil}</div>
          <div
            className="custom-modal-header__close"
            onClick={this.props.hideModal}
            disabled={this.props.creatingLedgerEntry}
          >
            <IconClose height={24} />
          </div>
        </div>
        <div className="set-reminder-modal-content">
          {modalContent}
          <div className="set-reminder-modal__save">
            <button className="button-primary-square" onClick={this.submit} disabled={this.props.creatingLedgerEntry}>
              {Resources.Save}
            </button>
          </div>
        </div>
      </Modal>
    );
  }
}

const storeToProps = store => {
  return {
    modal: store.modal,
    manageStore: store.manage,
    creatingLedgerEntry: store.ledger.creatingEntry
  };
};

export default withRouter(connect(storeToProps, dispatchToProps)(SetReminderModal));
