import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Form, FormGroup, Input } from "reactstrap";
import Resources from "../lib/resources";
import Icons from "../lib/icons";

class SetUpConnectionVerified extends Component {
  state = {
    email: "",
    description: ""
  };

  perspectiveText() {
    let contextText;
    if (this.props.perspective === "AR") contextText = [Resources.SetupConnectionVerifiedHeaderAR];
    if (this.props.perspective === "AP") contextText = [Resources.SetupConnectionVerifiedHeaderAP];

    return contextText;
  }

  render() {
    let AP_OR_AR_CONTEXT = this.perspectiveText();
    return (
      <div>
        <h3 className="text-center">{AP_OR_AR_CONTEXT[0]}</h3>
        <hr />
        <Form className=" light-gray-bg w-90 mx-auto p-4" onSubmit={e => e.preventDefault()}>
          <FormGroup className="">
            <p>
              {Resources.SetupConnectionVerifiedSentToForwardTo(this.props.registration.emailForwardingFromAddress)}
            </p>
            <Input
              className="w-80 mr-2 d-inline-block mb-3"
              style={{ backgroundColor: "#FFFFFF" }}
              name="lockstepEmail"
              onChange={this.onInput}
              id="passwordInput"
              value={this.props.registration.emailForwardingToAddress}
            />
          </FormGroup>
        </Form>
        <div className="text-center mt-3">
          <span className="">
            <span className={`${Icons.checkSquare} fa-2x align-middle`} style={{ color: "#53f442" }} />{" "}
            <span className="align-middle mr-5">
              <b>{Resources.SetupConnectionVerified}</b>
            </span>
          </span>
          <Button onClick={this.props.completeSetup} className="d-inline ml-2">
            {Resources.SetupConnectionVerifiedContinueWithSetup}
          </Button>
        </div>
      </div>
    );
  }
}

const storeToProps = store => {
  return {
    registration: store.registration
  };
};

export default connect(storeToProps)(SetUpConnectionVerified);
