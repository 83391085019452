import React, { Component } from "react";
import { connect } from "react-redux";

import ReportsFilter from "./reportsFilter";
import { dispatchToProps as raDP } from "../store/reports-actions";
import { dispatchToProps as aaDP } from "../store/accounts-actions";

import MainContentHeader from "./main_content_header/mainContentHeader";
import MainLoader from "./mainLoader";
import { isEmpty, clone, ticksToReadableTime, find } from "../lib/utils";
import ReportContentBlock from "./reportsContentBlock";
import TabularData from "./library/tabularData";
import Resources from "../lib/resources";
import moment from "moment";
import { BarChart, YAxis, XAxis, Bar, Tooltip, ResponsiveContainer, CartesianGrid } from "recharts";
import Icons from "../lib/icons";
import ReportsExportButton from "./reportsExportButton";
import ReportsNoData from "./reportsNoData";

const dispatchToProps = dispatch => ({
  ...aaDP(dispatch),
  ...raDP(dispatch)
});

class ReportsTasks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      numberOfEntries: 10,
      graphShown: "responses"
    };

    this.loadMore = this.loadMore.bind(this);
  }

  componentDidMount() {
    this.tryUpdate();
  }

  componentDidUpdate(prevProps) {
    this.tryUpdate(prevProps);
  }

  tryUpdate(prevProps) {
    if (
      isEmpty(prevProps) ||
      this.props.reports.companyId !== prevProps.reports.companyId ||
      this.props.reports.rangeType !== prevProps.reports.rangeType
    ) {
      this.props.getReportsData("tasks");
    }
    if (
      isEmpty(prevProps) === false &&
      this.props.reports.fetchedReport === true &&
      prevProps.reports.fetchedReport === false
    ) {
      let guidsArray =
        this.props.reports.reportData.tasks.map(task => {
          return task.assigneeId;
        }) || [];
      let filtered = guidsArray.filter(function(el) {
        return el != null;
      });
      this.props.getReportUsers(filtered);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.props.reports.companyId !== nextProps.reports.companyId ||
      this.props.reports.rangeType !== nextProps.reports.rangeType ||
      this.props.reports.reportType !== nextProps.reports.reportType ||
      this.props.reports.reportData !== nextProps.reports.reportData ||
      this.state.numberOfEntries !== nextState.numberOfEntries ||
      this.props.reports.reportUserIds !== nextProps.reports.reportUserIds ||
      this.state.graphShown !== nextState.graphSshown ||
      this.props.contextGroupsStore.value !== nextProps.contextGroupsStore.value
    ) {
      return true;
    }
    return false;
  }

  loadMore() {
    if (this.state.numberOfEntries < this.props.reports.reportData.tasks.length) {
      this.setState({ numberOfEntries: this.state.numberOfEntries + 10 });
    }
  }

  getTasksColumns(isAdmin) {
    let tasksColumns = [
      {
        header: "",
        key: "",
        style: {
          width: "1%"
        }
      },
      {
        header: Resources.TaskTitle,
        key: "title",
        style: {
          width: "48%"
        }
      },
      {
        header: Resources.Date,
        key: "date",
        style: {
          width: "10%"
        }
      },
      {
        header: Resources.Organization,
        key: "companyName",
        style: {
          width: "12%"
        }
      },
      {
        header: Resources.TeamMember,
        key: "displayName",
        style: {
          width: "12%"
        }
      },
      {
        header: Resources.ResolutionTime,
        key: "resolutionTimeDisplay",
        style: {
          width: "8%"
        }
      },
      {
        header: Resources.FirstResponse,
        key: "firstResponseTimeDisplay",
        style: {
          width: "8%"
        }
      },
      {
        header: "",
        key: "",
        style: {
          width: "1%"
        }
      }
    ];

    return tasksColumns;
  }

  prettyTasks(tasks) {
    let mergedCompanies = this.props.contextGroupsStore.value;

    // TODO: This needs to be updated, issue is that we need to be able to gather all contextGroups or companies
    // In order to map correctly the company name for each task
    /* Object.keys(this.props.conversations.companies).forEach(perspectiveId => {
      mergedCompanies = mergedCompanies.concat(clone(this.props.conversations.companies[perspectiveId]));
    }); */

    if (!isEmpty(tasks)) {
      return tasks.map(task => {
        let t = clone(task);
        t.date = moment(task.date).format("ddd M/D hh:mm A");
        t.resolutionTimeDisplay = ticksToReadableTime(task.resolutionTime);
        t.companyName = !isEmpty(mergedCompanies)
          ? (find(mergedCompanies, x => x.companyId === t.companyId) || {}).companyName
          : "---";
        let displayName = this.props.getReportUserDisplayName(task.assigneeId);
        t.displayName = displayName === "SAML User" ? "---" : displayName;
        t.firstResponseTimeDisplay = ticksToReadableTime(task.firstResponseTime);
        return t;
      });
    }
    return tasks;
  }

  prettyGraph(graphArray) {
    let dateFormat;
    switch (this.props.reports.rangeType) {
      case "P28D":
        dateFormat = "M/D";
        break;
      case "P10D":
        dateFormat = "M/D/YY";
        break;
      case "P12H":
        dateFormat = "M/D h:mm A";
        break;
      default:
        dateFormat = "M/D";
        break;
    }

    if (!isEmpty(graphArray)) {
      return graphArray.map(entry => {
        let e = clone(entry);
        e.date = moment(entry.date).format(dateFormat);
        return e;
      });
    }
    return graphArray;
  }

  render() {
    let { fetchingReport, reportData } = this.props.reports;
    let graphTitle;
    let arraySelector;
    let graphTooltipName;

    switch (this.state.graphShown) {
      case "responses":
        graphTitle = Resources.Responses;
        arraySelector = "responses";
        graphTooltipName = "Responses";
        break;
      case "totalTasks":
        graphTitle = Resources.TotalTasks;
        arraySelector = "taskCounts";
        graphTooltipName = "Number of Tasks Created";
        break;
      case "resolutions":
        graphTitle = Resources.Resolutions;
        arraySelector = "resolutions";
        graphTooltipName = "Resolutions";
        break;
      default:
        graphTitle = Resources.TotalTasks;
        arraySelector = "taskCounts";
        graphTooltipName = "Number of Tasks Created";
        break;
    }

    let tasks = this.prettyTasks(reportData.tasks || []);
    let responses = this.prettyGraph(reportData[arraySelector] || []);
    let tasksColumns = this.getTasksColumns(true);

    return (
      <React.Fragment>
        <MainContentHeader title={Resources.TasksReport} hideSearch={true} />
        {fetchingReport ? (
          <MainLoader fullScreen={true} />
        ) : (
          <div className="reports">
            <ReportsFilter />
            <div className="reports-content-blocks">
              <div className="row">
                <ReportContentBlock
                  heading={Resources.TotalTasks}
                  data={reportData.totalTasks}
                  backgroundColor="#03A08F"
                />
                <ReportContentBlock
                  heading={Resources.Responses}
                  data={reportData.totalResponses}
                  backgroundColor="#2D9CDB"
                />
                <ReportContentBlock
                  heading={Resources.AvgFirstResponse}
                  data={ticksToReadableTime(reportData.averageFirstResponseTime)}
                  backgroundColor="#097ABF"
                />
                <ReportContentBlock
                  heading={Resources.Resolutions}
                  data={reportData.totalResolutions}
                  backgroundColor="#676C89"
                />
                <ReportContentBlock
                  heading={Resources.AvgResolutionTime}
                  data={ticksToReadableTime(reportData.averageResolutionTime)}
                  backgroundColor="#4B4E61"
                />
                <ReportContentBlock
                  heading={Resources.AvgResponseTime}
                  data={ticksToReadableTime(reportData.averageResponseTime)}
                  backgroundColor="#31333F"
                />
              </div>
            </div>
            <div className="reports-chart-area">
              <div id="approvers-dropdown-selector" className="menu-toggle dropdown">
                <span
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  className="reports-charts-title"
                >
                  {graphTitle}
                  <span className={Icons.dropdown + " pt-1 pl-3"} />
                </span>
                <span id="approvers-dropdown-menu" className="dropdown-menu">
                  <span
                    className="dropdown-item reports-charts-title-selector"
                    onClick={() => this.setState({ graphShown: "responses" })}
                  >
                    {Resources.Responses}
                  </span>
                  <span
                    className="dropdown-item reports-charts-title-selector"
                    onClick={() => this.setState({ graphShown: "totalTasks" })}
                  >
                    {Resources.TotalTasks}
                  </span>
                  <span
                    className="dropdown-item reports-charts-title-selector bottom"
                    onClick={() => this.setState({ graphShown: "resolutions" })}
                  >
                    {Resources.Resolutions}
                  </span>
                </span>
              </div>
              <ResponsiveContainer>
                <BarChart data={responses} margin={{ top: 20, bottom: 10 }}>
                  <CartesianGrid vertical={false} />
                  <XAxis type="category" dataKey="date" tickLine={false} angle={0} />
                  <YAxis type="number" tickLine={false} />
                  <Bar name={graphTooltipName} dataKey="count" fill="#80D7CF" />
                  <Tooltip />
                </BarChart>
              </ResponsiveContainer>
            </div>
            <ReportsExportButton />
            <div className="table-tasks-report">
              {isEmpty(tasks) ? (
                <ReportsNoData height="12vw" reportType="tasks" />
              ) : (
                <TabularData
                  selectable={false}
                  onLoadMore={this.loadMore}
                  maxRows={tasks.length}
                  rowClassName="testing-reports-table"
                  rowStyle={{ cursor: "default" }}
                  data={tasks.slice(0, this.state.numberOfEntries)}
                  format={{
                    callBack: row => {},
                    selectAll: (e, rows) => {},
                    selected: (e, row) => {},
                    columns: tasksColumns
                  }}
                  //maxHeight="40vh"
                />
              )}
            </div>
            {/* <pre id="reports-pre">{JSON.stringify(reportData, null, 2)}</pre> */}
          </div>
        )}
      </React.Fragment>
    );
  }
}

const storeToProps = store => {
  return {
    reports: store.reports,
    contextGroupsStore: store.contextGroups
  };
};

export default connect(
  storeToProps,
  dispatchToProps
)(ReportsTasks);
