import React, { Component } from "react";
import { connect } from "react-redux";
import Resources from "../lib/resources";
import { dispatchToProps } from "../store/connector-actions";
import { isEmpty, getQueryParameters } from "../lib/utils";
import TextInput from "./library/textInput";
import MainContentHeader from "./main_content_header/mainContentHeader";
import LegacyNotification from "./library/legacyNotification";

class ERPRegistration extends Component {
  state = {
    errorMessage: "",
    schema: null
  };

  componentDidMount() {
    this.tryUpdate();
  }

  componentDidUpdate(prevProps, prevState) {
    this.tryUpdate(prevProps, prevState);
  }

  tryUpdate(prevProps, prevState = {}) {
    const {
      connectorStore,
      getPermittedERPConnector,
      getRegisteredERPConnectors,
      getLatestConnectorVersion,
      match: {
        params: { companyId }
      },
      location,
      history
    } = this.props;
    const registeredConnector = getRegisteredERPConnectors(companyId)[0];

    if (registeredConnector !== null && isEmpty(this.state.connector)) {
      const permittedConnector = getPermittedERPConnector(companyId);

      if (isEmpty(permittedConnector)) {
        this.props.history.replace(location.pathname.replace("/registering", ""));
        return;
      }

      const connectorVersion = getLatestConnectorVersion(permittedConnector.connectorId);
      const existingConfig = !isEmpty(registeredConnector)
        ? connectorStore.connectorConfigs[connectorVersion.connectorVersionId]
        : null;

      const schema = connectorVersion.configurationSchema.registrationConfig.map(field => {
        const update = {};
        if (field.type === "string") {
          if (!isEmpty(existingConfig)) {
            update.value = existingConfig.connectorConfiguration[field.key];
          } else {
            update.value = "";
          }
        }
        return Object.assign({}, field, update);
      });
      this.setState({ schema, connector: permittedConnector });
    }

    // If they change companies, take them back to main connector page
    if (!isEmpty(prevProps) && companyId !== prevProps.match.params.companyId) {
      history.replace(location.pathname.replace("/registering", ""));
    }
  }

  register = () => {
    let {
      match: {
        params: { companyId }
      },
      history,
      location
    } = this.props;
    const permittedConnector = this.props.getPermittedERPConnector(companyId);
    const connectorVersion = this.props.getLatestConnectorVersion(permittedConnector.connectorId);

    const payload = this.state.schema.reduce((accum, field) => {
      accum[field.key] = field.value;
      return accum;
    }, {});

    this.props.updateConnectorConfiguration(companyId, connectorVersion.connectorVersionId, payload).then(response => {
      if (!isEmpty(response)) {
        if (this.props.isEditing) {
          this.props.history.goBack();
        } else {
          history.push(location.pathname.replace("registering", "configure"));
        }
      }
    });
  };

  render() {
    const { connectorStore, isEditing } = this.props;
    const errorNotification = getQueryParameters(this.props.location.search).error ? (
      <LegacyNotification type="error" message={Resources.ConnectorRegistrationError} />
    ) : null;
    const isSubmitting = connectorStore.isUpdatingConnectorConfiguration;
    const buttonText = isEditing
      ? isSubmitting
        ? Resources.Updating
        : Resources.Update
      : isSubmitting
      ? Resources.Registering
      : Resources.Register;

    return (
      <React.Fragment>
        {!this.props.isEditing && (
          <MainContentHeader title={Resources.Setup} customContent={errorNotification} hideSearch={true} />
        )}
        <div className="manage-content">
          {isEditing && <h3 className="mb-4 fw-500">{Resources.UpdateRegistration}</h3>}

          {!isEditing && <div className="input-label">{(this.state.connector || {}).displayName}</div>}
          {!isEditing && <div className="mb-4">{Resources.EnterPackageDetails}</div>}

          <div className="fs-obscure">
            {(this.state.schema || []).map((field, i) => {
              return (
                <TextInput
                  key={i}
                  width="560px"
                  textValue={field.value}
                  label={field.displayName || field.description}
                  id={field.key}
                  inputOnChange={e => {
                    const schema = [...this.state.schema];
                    const update = Object.assign({}, field, {
                      value: e.target.value
                    });
                    schema[i] = update;
                    this.setState({ schema });
                  }}
                />
              );
            })}
          </div>
          <div className="connector-buttons">
            {isEditing && (
              <button className="cancel-button" onClick={() => this.props.history.goBack()}>
                {Resources.Cancel}
              </button>
            )}
            <button onClick={this.register} disabled={isSubmitting} className="send-button">
              {buttonText}
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const storeToProps = store => {
  return {
    connectorStore: store.connector
  };
};

export default connect(
  storeToProps,
  dispatchToProps
)(ERPRegistration);
