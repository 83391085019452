import React, { Component } from "react";
import { connect } from "react-redux";

import { dispatchToProps } from "../../store/modal-actions";
import Resources from "../../lib/resources";

import IconCheck from "../library/icons/iconCheck";
import IconAlertCircle from "../library/icons/iconAlertCircle";
import IconSpinner from "../library/icons/iconSpinner";

class CreatingPromiseToPayNotification extends Component {
  constructor(props) {
    super(props);

    this.state = {
      address: "",
      isValidAddress: false
    };
  }

  componentDidUpdate(prevProps) {
    if (
      (this.props.createdPromiseToPay === true && prevProps.createdPromiseToPay === false) ||
      (this.props.createPromiseToPayFailed === true && prevProps.createPromiseToPayFailed === false)
    ) {
      setTimeout(this.props.hideNotification, 5000);
    }
  }

  render() {
    let { creatingPromiseToPay, createdPromiseToPay, createPromiseToPayFailed } = this.props;

    let content;
    if (creatingPromiseToPay) {
      content = (
        <React.Fragment>
          <IconSpinner height={20} className="notification-icon-left" />
          <div>{Resources.SubmittingPromiseToPay}</div>
        </React.Fragment>
      );
    }
    if (createdPromiseToPay) {
      content = (
        <React.Fragment>
          <IconCheck height={18} className="notification-icon-left"></IconCheck>
          <div>{Resources.PromiseToPaySubmitted}</div>
        </React.Fragment>
      );
    }
    if (createPromiseToPayFailed) {
      content = (
        <React.Fragment>
          <IconAlertCircle height={18} className="notification-icon-left"></IconAlertCircle>
          <div>{Resources.FailedSubmittingPromiseToPay}</div>
        </React.Fragment>
      );
    }
    return <div className="notification">{content}</div>;
  }
}

const storeToProps = store => {
  return {
    creatingPromiseToPay: store.ledger.creatingEntry,
    createdPromiseToPay: store.ledger.createdEntry,
    createPromiseToPayFailed: store.ledger.createEntryFailed
  };
};

export default connect(
  storeToProps,
  dispatchToProps
)(CreatingPromiseToPayNotification);
