import { actions } from "./perspectives-actions";
import { find } from "../lib/utils";

export const defaultPerspectivesState = {
  perspectives: [],
  fetchingPerspectives: false,
  fetchingPerspectivesFailed: null,

  isFetchingCompanyPerspectives: false,
  fetchingCompanyPerspectivesFailed: null,
  companyPerspectives: [],
  companyPerspectivesMap: {},

  isCreatingCompanyPerspective: false,
  creatingCompanyPerspectiveFailed: null,
  createdPerspective: null,

  companyId: null,

  selectedPerspectiveId: null,
  selectedPerspective: null,

  // vendors = 1 and customers = 2
  selectedPerspectiveType: null
};

function perspectivesReducer(state = defaultPerspectivesState, action) {
  if (window.logToConsole) console.log(action);
  switch (action.type) {
    case actions.FETCHING_PERSPECTIVES:
      return { ...state, fetchingPerspectives: true };
    case actions.FETCHED_PERSPECTIVES:
      return {
        ...state,
        fetchingPerspectives: false,
        fetchingPerspectivesFailed: false,
        perspectives: action.perspectives
      };
    case actions.FETCH_PERSPECTIVES_FAILED:
      return {
        ...state,
        fetchingPerspectivesFailed: true,
        fetchingPerspectives: false
      };

    case actions.FETCHING_COMPANY_PERSPECTIVES:
      return { ...state, isFetchingCompanyPerspectives: true };
    case actions.FETCHED_COMPANY_PERSPECTIVES:
      const companyPerspectivesMap = {};
      action.companyPerspectives.forEach(p => (companyPerspectivesMap[p.perspectiveId] = p));
      return {
        ...state,
        isFetchingCompanyPerspectives: false,
        fetchingCompanyPerspectivesFailed: false,
        companyPerspectives: action.companyPerspectives,
        companyPerspectivesMap
      };
    case actions.FETCH_COMPANY_PERSPECTIVES_FAILED:
      return {
        ...state,
        fetchingCompanyPerspectivesFailed: true,
        isFetchingCompanyPerspectives: false
      };

    case actions.CREATING_COMPANY_PERSPECTIVE:
      return { ...state, isCreatingCompanyPerspective: true };
    case actions.CREATED_COMPANY_PERSPECTIVE:
      const createdPerspective = [...action.companyPerspectives].sort((a, b) => {
        return new Date(b.modifiedDate) - new Date(a.modifiedDate);
      })[0];
      return {
        ...state,
        isCreatingCompanyPerspective: false,
        creatingCompanyPerspectiveFailed: false,
        companyPerspectives: action.companyPerspectives,
        createdPerspective
      };
    case actions.CREATE_COMPANY_PERSPECTIVE_FAILED:
      return {
        ...state,
        fetchingCompanyPerspectiveFailed: true,
        isFetchingCompanyPerspective: false
      };

    case actions.UPDATED_COMPANY_PERSPECTIVE:
      const idx = state.companyPerspectives.findIndex(({ perspectiveId }) => perspectiveId === action.perspectiveId);
      const updated = { ...state.companyPerspectives[idx], ...action.payload };

      return {
        ...state,
        // isCreatingCompanyPerspective: false,
        // creatingCompanyPerspectiveFailed: false,
        companyPerspectives: [
          ...state.companyPerspectives.slice(0, idx),
          updated,
          ...state.companyPerspectives.slice(idx + 1)
        ]
      };

    case actions.SET_SELECTED_PERSPECTIVE_ID:
      let selectedPerspective =
        find(state.perspectives, perspective => perspective.perspectiveId === action.perspectiveId) ||
        find(state.companyPerspectives, perspective => perspective.perspectiveId === action.perspectiveId) ||
        {};
      return {
        ...state,
        selectedPerspectiveId: action.perspectiveId,
        selectedPerspective,
        selectedPerspectiveType: (selectedPerspective.parentPerspective || {}).perspectiveName
      };

    case actions.CLEAR_DATA:
      return defaultPerspectivesState;

    case actions.SELECT_COMPANY:
      if (state.companyId === null) {
        return {
          ...state,
          companyId: action.companyId
        };
      } else if (action.companyId !== state.companyId) {
        return {
          ...defaultPerspectivesState,
          companyId: action.companyId
        };
      }

      return state;

    default:
      return state;
  }
}

export default perspectivesReducer;
