import React, { Component } from "react";
import { connect } from "react-redux";

import { isEmpty, compareStrings, find } from "../lib/utils";

import { dispatchToProps } from "../store/manage-actions";
import Resources from "../lib/resources";
import Icons from "../lib/icons";
import SelectInput from "./library/selectInput";

class EmailTemplatePicker extends Component {
  onTemplateSelected(e, template, templateObject) {
    if (isEmpty(this.props.templateSelected) !== true) this.props.templateSelected(template, templateObject || {});
  }

  render() {
    let templates = this.props.getAllCompanyReplyTemplates(this.props.companyId, this.props.perspective) || [];
    // templates.sort((a, b) => {
    //   return compareStrings(a.perspectiveEmailTemplateName, b.perspectiveEmailTemplateName);
    // });

    const selectInputOptions = templates.map(template => {
      return { displayName: template.emailTemplateID, value: template.emailTemplateKey };
    });
    //users = [{ userId: null, familyName: Resources.Anyone }, ...users]

    return (
      <div className="email-template-picker">
        {this.props.noLabel ? null : <div className="portal-input-label">{Resources.Template}</div>}
        <div>
          <SelectInput
            value={this.props.appliedTemplateId}
            displayValue={
              this.props.fetchingTemplateId === null
                ? (find(templates, template => template.emailTemplateKey === this.props.appliedTemplateId) || {})
                    .emailTemplateID || Resources.SelectMessageTemplate
                : Resources.FetchingPopulatedTemplate
            }
            options={selectInputOptions}
            onSelectOption={option =>
              this.onTemplateSelected(
                null,
                option.value,
                find(templates, t => t.emailTemplateKey === option.value)
              )
            }
            //label={Resources.Template}
            labelClassName="reply-form-label"
            width="20rem"
            disabled={this.props.disabled}
          />
        </div>
      </div>
    );

    return (
      <span>
        <span className="dropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <span className={Icons.template} />
          <span className="editor-action-label">{Resources.UseTemplate}</span>
          <span className={Icons.dropdown} style={{ fontSize: ".8em" }} />
        </span>
        <span className="dropdown-menu">
          {templates.map((template, index) => {
            return (
              <div
                key={`tid${index}`}
                className="dropdown-item"
                style={{ cursor: "default" }}
                onClick={e => {
                  this.onTemplateSelected(e, template);
                }}
              >
                {template.perspectiveEmailTemplateName}
              </div>
            );
          })}
        </span>
      </span>
    );
  }
}

const storeToProps = store => {
  return {
    user: store.user,
    manage: store.manage
  };
};

export default connect(storeToProps, dispatchToProps)(EmailTemplatePicker);
