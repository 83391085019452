import React, { Component } from "react";
import { connect } from "react-redux";

import Resources from "../lib/resources";
import Icons from "../lib/icons";
import { isEmpty } from "../lib/utils";

import Modal from "react-modal";
import TextInput from "./library/textInput";

import { dispatchToProps as maDP } from "../store/manage-actions";
import { dispatchToProps as modDP } from "../store/modal-actions";

const dispatchToProps = dispatch => {
  return {
    ...dispatch(maDP),
    ...dispatch(modDP)
  };
};

class SharedDocumentForm extends Component {
  constructor(props) {
    super(props);

    this.state = this.defaultState();

    this.submitDocument = this.submitDocument.bind(this);
  }

  defaultState() {
    return {
      documentId: "",
      documentName: "",
      description: "",
      errorMessage: "",
      isEditing: false
    };
  }

  componentDidMount() {
    const { companyDocument, upload } = this.props;
    // New upload
    if (isEmpty(companyDocument) || isEmpty(companyDocument.companyDocumentId)) {
      this.setState({ documentName: upload.name, isEditing: false });
    } else {
      this.setState({
        documentName: companyDocument.companyDocumentName,
        description: companyDocument.description || "",
        isEditing: true
      });
    }
  }

  componentWillUnmount() {
    this.props.clearSelectedDocument();
  }

  componentDidUpdate(prevProps) {
    if (this.state.isEditing && this.props.upload !== prevProps.upload) {
      this.setState({ documentName: this.props.upload.name });
    }
  }

  submitDocument() {
    let { documentName, description, isEditing } = this.state;
    const { perspectiveId, upload, companyId, companyDocument } = this.props;

    const req = {
      perspectiveId,
      companyId,
      metadata: { description, companyDocumentName: documentName },
      upload
    };

    // New upload
    if (isEditing === false) {
      this.props.createCompanyDocument(req);
    } else {
      req.companyDocumentId = companyDocument.companyDocumentId;
      this.props.updateCompanyDocument(req);
    }
    this.props.hideModal();
  }

  render() {
    const { companyDocument, upload } = this.props;

    if (!this.props.modalStore.showModal) {
      return null;
    }

    return (
      <Modal
        isOpen={this.props.modalStore.showModal}
        onRequestClose={() => this.props.hideModal()}
        className="custom-modal"
        overlayClassName="custom-modal-overlay"
      >
        <div className="custom-modal-header">{isEmpty(companyDocument) ? Resources.AddNewFile : Resources.Edit}</div>
        <div className="custom-modal-divider" />
        <div className="custom-modal-content">
          <div className="mt-3 mb-3">
            <span className={Icons.link + " pr-1"} />
            {!upload ? companyDocument.companyDocumentName : upload.name}
          </div>
          <TextInput
            width="80%"
            textValue={this.state.documentName}
            label={Resources.RenameFile}
            id="fileName"
            inputOnChange={e => {
              this.setState({ documentName: e.target.value });
            }}
          />
          <TextInput
            width="80%"
            textValue={this.state.description}
            label={Resources.Description}
            id="description"
            inputOnChange={e => {
              this.setState({ description: e.target.value });
            }}
          />
          {companyDocument && (
            <div className="mb-2">
              <div className="input-label mb-2">{Resources.ReplaceFile}</div>
              <div className="green-link-white-bg fw-700" onClick={this.props.uploadFile}>
                {Resources.Upload}
              </div>
            </div>
          )}
          <div className="flex-row modal-buttons">
            <button className="button-cancel float-left mr-1" onClick={this.props.hideModal}>
              {Resources.Cancel}
            </button>
            <button className="btn button-green" onClick={this.submitDocument}>
              {this.state.isEditing === false ? Resources.Submit : Resources.Update}
            </button>
          </div>
        </div>
      </Modal>
    );
  }
}

const storeToProps = store => {
  return {
    modalStore: store.modal,
    companies: store.companies,
    manageStore: store.manage
  };
};

export default connect(
  storeToProps,
  dispatchToProps
)(SharedDocumentForm);
