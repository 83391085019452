import React, { Component } from "react";

class IconTableSort extends Component {
  render() {
    let { className, height, topClass, bottomClass } = this.props;

    return (
      <svg viewBox="0 0 8 13" height={height} className={`icon ${className ? className : ""}`}>
        <g fillRule="evenodd">
          <path className={topClass ? topClass : ""} fill={"currentColor"} d="M7.686526 8.6875l-3.5 4.375-3.5-4.375z" />
          <path
            className={bottomClass ? bottomClass : ""}
            fill={"currentColor"}
            d="M7.686526 4.6875l-3.5-4.375-3.5 4.375z"
          />
        </g>
      </svg>
    );
  }
}

IconTableSort.defaultProps = {
  height: 13
};

export default IconTableSort;
