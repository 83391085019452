import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { dispatchToProps as moDP } from "../../../store/modal-actions";
import { dispatchToProps as ledgDP } from "../../../store/ledger-actions";
import { dispatchToProps as statDP } from "../../../store/statements-actions";
import { dispatchToProps as manDP } from "../../../store/manage-actions";
import { dispatchToProps as networkDP } from "../../../store/network-actions";
import { dispatchToProps as caDP } from "../../../store/company-actions";

import { isEmpty } from "../../../lib/utils";

import Resources from "../../../lib/resources";
import IconClose from "../../library/icons/iconClose";
import MainLoader from "../../mainLoader";
import "react-datepicker/dist/react-datepicker.css";
// import IconFilter from "../../library/icons/iconFilter";

import Modal from "react-modal";
import TextInput from "../../library/textInput";
import HtmlEditor from "../../htmlEditor";

const dispatchToProps = dispatch => ({
  ...moDP(dispatch),
  ...ledgDP(dispatch),
  ...statDP(dispatch),
  ...caDP(dispatch),
  ...manDP(dispatch),
  ...networkDP(dispatch)
});

class ShareFlyout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      subject: this.renderSubjectMessage(),
      to: "",
      htmlBody: this.renderShareMessage(this.props.myProfile),
      showFlyout: true
    };

    this.hideFlyout = this.hideFlyout.bind(this);
  }

  handleSelect(input) {
    input.defaultChecked = true;
  }

  hideFlyout() {
    this.setState({ showFlyout: false });
  }

  renderSubjectMessage() {
    if (this.props.isCompany) {
      return "Check out our profile on Lockstep!";
    } else {
      return "Check out my profile on Lockstep!";
    }
  }

  renderShareMessage(myProfile) {
    if (this.props.isCompany) {
      return `<div><p>Hello,<p><br></p></p><p>We use Lockstep to share all of our contact information and important documents, below is a link to our profile.</p><p><br></p><a style="color: #1fb6c1" href="#">${myProfile.accountName}'s Lockstep Profile</a><p><br></p><p>Have a wonderful day!</p><p><br></p><p>${myProfile.accountName}</p></div>`;
    } else {
      return `<div><p>Hello,<p><br></p></p><p>I am using Lockstep to share all of my contact information, below is a link to my profile.</p><p><br></p><a style="color: #1fb6c1" href="#">${myProfile.contactName}'s Lockstep Profile</a><p><br></p><p>Have a wonderful day!</p><p><br></p><p>${myProfile.contactName}</p></div>`;
    }
  }

  componentDidMount() {
    let mode = this.props.mode === "1" ? "ap" : "ar";
    this.props.getShareFlyoutMessage(mode, this.props.entityGlobalKey).then(response => {
      this.setState({ htmlBody: response.htmlBody });
    });
    this.props.getCompanyInfo(this.props.companyId);
  }

  sendMessage() {
    const companyInfo = this.props.getCompanyInfo(this.props.companyId);
    const cAddress = (((companyInfo || {}).connectorEmail || {}).synthetic || {})[this.props.perspectiveId];
    const companyEmail = isEmpty(cAddress) ? undefined : { name: companyInfo.companyName, address: cAddress };

    let request = {
      from: [{ address: companyEmail.address, name: this.props.myProfile.contactName }],
      to: [{ address: this.state.to, name: "" }],
      cc: [],
      bcc: [],
      subject: this.state.subject,
      textBody: "",
      htmlBody: this.state.htmlBody
    };

    this.props.createShareFlyoutDraft(this.props.perspectiveId, this.props.companyId, request).then(response => {
      this.props.sendShareFlyout(this.props.perspectiveId, this.props.companyId, response.data, request);
    });
    this.props.displayNotification("sendingMessageNotification");
    this.props.hideFlyout();
  }

  render() {
    let myProfile = { ContactName: "Henry Lucco", AccountName: "Holy Roman Empire" };

    return (
      <Modal
        isOpen={this.state.showFlyout}
        onRequestClose={this.hideFlyout}
        onAfterClose={!this.state.showFlyout ? this.props.hideFlyout : null}
        closeTimeoutMS={400}
        contentLabel="Example Modal"
        className="flyout share-profile-flyout"
        overlayClassName="flyout-overlay"
      >
        {this.props.networkStore.gettingNotes ? (
          <MainLoader fullScreen={true} />
        ) : (
          <React.Fragment>
            <div className="flyout-heading">
              <p>{Resources.SendAMessage}</p>
              <div onClick={this.props.hideFlyout} className="flyout-heading-close">
                <IconClose />
              </div>
            </div>
            <div className="flyout-content">
              <TextInput
                labelInline={false}
                label={Resources.To}
                textValue={this.state.to}
                inputOnChange={e => {
                  let value = e.target.value;
                  this.setState({ to: value });
                }}
              />
              <TextInput
                labelInline={false}
                label={Resources.Subject}
                textValue={this.state.subject}
                inputOnChange={e => {
                  let value = e.target.value;
                  this.setState({ subject: value });
                }}
              />
              <HtmlEditor
                onFocus={() => this.setState({ focusedField: "message" })}
                hideToolbar={true}
                htmlContent={this.state.htmlBody}
                defaultHtmlContent={this.renderShareMessage(myProfile)}
                updateHtmlContent={htmlBody => this.setState({ htmlBody })}
                updateTextContent={textBody => this.setState({ textBody })}
              />
              <button onClick={() => this.sendMessage()} className={"button-primary-square"}>
                {Resources.Send}
              </button>
            </div>
          </React.Fragment>
        )}
      </Modal>
    );
  }
}

const storeToProps = store => {
  return {
    modalStore: store.modal,
    manageStore: store.manage,
    contactList: store.network.contactList,
    accountList: store.network.accountList,
    pageRowCount: store.general.pageRowCount,
    networkStore: store.network,
    searchResults: store.network.searchResults,
    isShowingSearchResult: store.gotNetworkAccountSearchResults,
    gettingNetworkAccountList: store.network.gettingNetworkAccountList,
    gettingNetworkContactList: store.network.gettingNetworkContactList
  };
};

export default withRouter(
  connect(
    storeToProps,
    dispatchToProps
  )(ShareFlyout)
);
