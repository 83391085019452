import React, { Component } from "react";

export default class TextAreaInput extends Component {
  render() {
    const { id, inputOnChange, textValue, label, width, placeholder, rows, className } = this.props;

    return (
      <div className={`${className ? className : ""} text-input-container`} style={{ width: width }}>
        <label htmlFor={id} className="input-label">
          {label}
        </label>
        <textarea
          className={`text-input`}
          id={id}
          value={textValue}
          onChange={inputOnChange}
          onBlur={this.props.onBlur ? this.props.onBlur : undefined}
          onFocus={this.props.onFocus ? this.props.onFocus : undefined}
          placeholder={placeholder}
          rows={rows || 2}
        />
      </div>
    );
  }
}
