import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import ReactQuill from "./library/lockstepQuill";
import "react-quill/dist/quill.snow.css";
import "../css/quill.scss";
//import Dropzone from "react-dropzone";

//import Resources from "../lib/resources";

import { dispatchToProps as uaDP } from "../store/user-actions";
import { dispatchToProps as compDP } from "../store/company-actions";
import { dispatchToProps as lgrDP } from "../store/ledger-actions";
import { dispatchToProps as convDP } from "../store/conversations-actions";
import { dispatchToProps as aaDP } from "../store/accounts-actions";
import { dispatchToProps as maDP } from "../store/manage-actions";
import { dispatchToProps as persDP } from "../store/perspectives-actions";
import { dispatchToProps as gaDP } from "../store/general-actions";
import { dispatchToProps as modDP } from "../store/modal-actions";
import { dispatchToProps as congDP } from "../store/contextGroups-actions";
import { isEmpty, generateGUID } from "../lib/utils";

const dispatchToProps = dispatch => ({
  ...aaDP(dispatch),
  ...uaDP(dispatch),
  ...compDP(dispatch),
  ...lgrDP(dispatch),
  ...convDP(dispatch),
  ...persDP(dispatch),
  ...maDP(dispatch),
  ...gaDP(dispatch),
  ...modDP(dispatch),
  ...congDP(dispatch)
});

const elementId = generateGUID();

class HtmlEditor extends Component {
  constructor(props) {
    super(props);

    this.state = { ...this.defaultState };
  }

  defaultState = {
    dropEnabled: false,

    quillConfig: {
      theme: "snow",
      modules: {
        toolbar: {
          container: "#toolbar"
        },
        clipboard: {
          matchVisual: false
        }
      },
      readOnly: false
    }
  };

  componentDidMount() {
    if (!isEmpty(this.props.onRef)) {
      this.props.onRef(this);
    }
  }

  getText() {
    if (!this.quillRef) {
      return;
    }
    let editor = this.quillRef.getEditor();
    let uEditor = this.quillRef.makeUnprivilegedEditor(editor);
    return uEditor.getText();
  }

  insertText(index, text) {
    let editor = this.quillRef.getEditor();
    return editor.insertText(index, text);
  }

  updateContents(delta) {
    let editor = this.quillRef.getEditor();
    return editor.updateContents(delta);
  }

  setTabIndex(item, tabIndex) {
    item.tabIndex = tabIndex;
    if (item.children) {
      for (let i = 0; i < item.children.length; i++) {
        this.setTabIndex(item.children[i], tabIndex);
      }
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className={`html-editor ${this.props.hideToolbar ? "hide-toolbar" : ""}`}>
          <div>
            {/* <Dropzone
              noClick={true}
              onDragEnter={e => {
                this.setState({ dropEnabled: true });
              }}
              onDragLeave={e => {
                this.setState({ dropEnabled: false });
              }}
              onDrop={acceptedFiles => this.updateAttachments(Array.from(acceptedFiles))}
            >
            {({ getRootProps, getInputProps }) => (
                <div
                  {...getRootProps()}
                  className="quill-dropzone-container"
                  onFocus={e => {
                    e.preventDefault();
                    this.quillRef.focus();
                  }}
                > */}
            {this.props.label && (
              <div className={`portal-input-label mt-3 ${this.props.focused === "message" ? " focused" : ""}`}>
                {this.props.label}
              </div>
            )}
            <ReactQuill
              className="focusable"
              ref={el => {
                this.quillRef = el;
              }}
              id={`a${elementId}`}
              bounds={`#a${elementId}`}
              {...this.state.quillConfig}
              value={this.props.htmlContent || ""}
              onChange={value => {
                this.props.updateHtmlContent(value);
                this.props.updateTextContent(this.getText());
              }}
              onChangeSelection={this.props.onChangeSelection}
              onBlur={() => {
                if (!isEmpty(this.props.onBlur)) {
                  this.props.onBlur();
                }
              }}
              onFocus={() => {
                if (!isEmpty(this.props.onFocus)) {
                  this.props.onFocus();
                }
              }}
            />
            {/* {this.state.dropEnabled && (
                    <div className="quill-dropzone-overlay">
                      <h4>{Resources.DropFilesHere}</h4>
                    </div>
                  )}
            <input {...getInputProps()} />
            </div>
            </Dropzone> */}
            <div className="toolbar-spacer"></div>
            <div id="toolbar">
              <button className="ql-bold" />
              <button className="ql-italic" />
              <button className="ql-underline" />
              <button className="ql-strike" />
              <button className="ql-link" />
              {this.props.toolbarItems}
              {/* <select className="ql-color">
                <option value="red" />
                <option value="green" />
                <option value="blue" />
                <option value="orange" />
                <option value="violet" />
                <option value="#d0d1d2" />
                <option selected />
              </select> */}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const storeToProps = store => {
  return {
    user: store.user,
    ledgerStore: store.ledger,
    conversations: store.conversations,
    accountsStore: store.accounts,
    manageStore: store.manage,
    contextGroupsStore: store.contextGroups
  };
};

HtmlEditor.defaultProps = {
  hideToolbar: false
};

export default withRouter(connect(storeToProps, dispatchToProps)(HtmlEditor));
