import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import _ from "lodash";

import Icons from "../lib/icons";
import Resources from "../lib/resources";
import { formatDimensionalDate, formatCurrency, isEmpty } from "../lib/utils";

import { dispatchToProps as saDP } from "../store/statements-actions";
import { dispatchToProps as caDP } from "../store/connector-actions";

import TableData from "./library/tableData";
import SyncedWith from "./library/syncedWith";

const dispatchToProps = dispatch => {
  return {
    ...dispatch(saDP),
    ...dispatch(caDP)
  };
};

class StatementsView extends Component {
  constructor(props) {
    super(props);

    this.updateDateRange = this.updateDateRange.bind(this);
    this.mapFromDate = this.mapFromDate.bind(this);

    this.state = {
      selectedView: "openInvoices",
      dateFilters: {
        openInvoices: {
          fromDate: null,
          toDate: null
        },
        closedInvoices: {
          fromDate: null,
          toDate: null
        },
        payments: {
          fromDate: null,
          toDate: null
        }
      }
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      contextGroupsStore,
      statementsStore,
      match: { params }
    } = this.props;

    if (contextGroupsStore.gotContextGroups !== true) {
      return;
    }

    const withCompanyId = (contextGroupsStore.contextGroupsMaps[params.selectedContextGroupId] || {}).companyId;
    const switchedCompany = withCompanyId !== statementsStore.withCompanyId;

    if (statementsStore.fetchingClosedInvoicesFailed === null || switchedCompany) {
      this.props.fetchClosedInvoices(params.companyId, params.perspectiveId, withCompanyId);
    }

    if (statementsStore.fetchingOpenInvoicesFailed === null || switchedCompany) {
      this.props.fetchOpenInvoices(params.companyId, params.perspectiveId, withCompanyId);
    }

    if (statementsStore.fetchingPaymentsFailed === null || switchedCompany) {
      this.props.fetchPayments(params.companyId, params.perspectiveId, withCompanyId);
    }

    const selectedViewDateFilters = this.state.dateFilters[this.state.selectedView];
    const prevStateDateFilters = prevState.dateFilters[this.state.selectedView];
    const dateFilterChanged = !_.isEqual(
      { fromDate: selectedViewDateFilters.fromDate, toDate: selectedViewDateFilters.toDate },
      { fromDate: prevStateDateFilters.fromDate, toDate: prevStateDateFilters.toDate }
    );

    let fromDate = this.mapFromDate(selectedViewDateFilters.fromDate);

    if (!isEmpty(prevState) && dateFilterChanged) {
      const args = [params.companyId, params.perspectiveId, withCompanyId, fromDate, selectedViewDateFilters.toDate];
      if (this.state.selectedView === "openInvoices") {
        this.props.fetchOpenInvoices(...args);
      } else if (this.state.selectedView === "closedInvoices") {
        this.props.fetchClosedInvoices(...args);
      } else if (this.state.selectedView === "payments") {
        this.props.fetchPayments(...args);
      }
    }
  }

  mapFromDate(fromDate) {
    const fromDateMap = {
      pastWeek: moment()
        .subtract(1, "w")
        .format("YYYY-MM-DD HH:mm"),
      pastMonth: moment()
        .subtract(1, "M")
        .format("YYYY-MM-DD HH:mm"),
      pastYear: moment()
        .subtract(1, "years")
        .format("YYYY-MM-DD HH:mm")
    };
    return fromDateMap[fromDate] || this.state.dateFilters[this.state.selectedView].fromDate;
  }

  updateDateRange(fromDate = null, toDate = null) {
    this.setState({
      dateFilters: { ...this.state.dateFilters, [this.state.selectedView]: { fromDate, toDate } }
    });
  }

  render() {
    let {
      statementsStore,
      ledgerStore,
      contextGroupsStore,
      match: { params }
    } = this.props;

    let columns = [
      {
        header: Resources.DueDateLabel.toLocaleUpperCase(),
        content: row => <span>{formatDimensionalDate(row.dueDate)}</span>,
        className: "right-aligned-column",
        headerClassName: "right-aligned-column"
      },
      {
        header: `${Resources.Invoice}#`.toLocaleUpperCase(),
        content: row => {
          return (
            <div className="documents-file-name">
              {!isEmpty(row.attachments) && <span className={Icons.filePdf} />}
              <div className="overflow-ellipsis highlight">{`${(ledgerStore.resourceMaps[row.resourceId] || {})
                .resourceName || Resources.Document} #${row.id}`}</div>
            </div>
          );
        },
        className: "pl-3",
        headerClassName: "pl-3"
      },
      {
        header:
          ((this.props.parentPerspective || {}).perspectiveName === "vendors" ? Resources.PO : Resources.SO) + "#",
        content: row => <span>{row.orderId || "--"}</span>,
        width: "8%"
      },
      {
        header: Resources.AmountUSD.toLocaleUpperCase(),
        content: row => <span>{formatCurrency(row.amount)}</span>,
        className: "right-aligned-column",
        headerClassName: "right-aligned-column"
      },
      {
        header: Resources.ApprovedInERP.toLocaleUpperCase(),
        content: row => {
          if (row.isApproved === null) {
            return <span className="dark-gray-font">N/A</span>;
          }
          return (
            <span
              className={row.isApproved ? `${Icons.check} success-color-font` : `${Icons.close} warning-color-font`}
            />
          );
        },
        className: "pl-5"
      },
      {
        header: Resources.Payment.toLocaleUpperCase(),
        content: row => <span>{row.status}</span>
      }
    ];

    if (this.state.selectedView === "payments") {
      columns = [
        {
          header: Resources.DateProcessed.toLocaleUpperCase(),
          content: row => <span>{formatDimensionalDate(row.processedDate)}</span>,
          className: "right-aligned-column",
          headerClassName: "right-aligned-column"
        },
        {
          header: Resources.PaymentDetails.toLocaleUpperCase(),
          content: row => {
            return (
              <div className="documents-file-name">
                {!isEmpty(row.attachments) && <span className={Icons.filePdf} />}
                <div className="overflow-ellipsis highlight">{`${row.paymentName} #${row.id}`}</div>
              </div>
            );
          }
        },
        {
          header: Resources.AppliedToInvoice.toLocaleUpperCase(),
          content: row => <span>{row.invoiceId ? `#${row.invoiceId}` : "--"}</span>
        },
        {
          header: Resources.Type.toLocaleUpperCase(),
          content: row => <span>{row.paymentType}</span>,
          width: "5%"
        },
        {
          header: Resources.AmountUSD.toLocaleUpperCase(),
          content: row => <span>{formatCurrency(row.amount)}</span>,
          className: "right-aligned-column",
          headerClassName: "right-aligned-column"
        }
      ];
    }

    let data;
    let loading;
    let maxRows;
    let handleLoadMore;
    let totalLabel;
    let totalAmount;
    let templateGetter;

    const registeredConnector = this.props.getRegisteredERPConnectors(this.props.match.params.companyId)[0] || {};

    switch (this.state.selectedView) {
      case "openInvoices":
        data = statementsStore.openInvoices;
        loading = statementsStore.isFetchingOpenInvoices;
        maxRows = statementsStore.openInvoicesCount;
        handleLoadMore = this.props.fetchNextOpenInvoices;
        totalLabel = Resources.TotalOpen.toLocaleUpperCase();
        totalAmount = statementsStore.openTotalAmount;
        templateGetter = this.props.fetchOpenInvoicesTemplate;
        break;
      case "closedInvoices":
        data = statementsStore.closedInvoices;
        loading = statementsStore.isFetchingClosedInvoices;
        maxRows = statementsStore.closedInvoicesCount;
        handleLoadMore = this.props.fetchNextClosedInvoices;
        totalLabel = Resources.TotalClosed.toLocaleUpperCase();
        totalAmount = statementsStore.closedTotalAmount;
        templateGetter = this.props.fetchClosedInvoicesTemplate;
        break;
      case "payments":
        data = statementsStore.payments;
        loading = statementsStore.isFetchingPayments;
        maxRows = statementsStore.paymentsCount;
        handleLoadMore = this.props.fetchNextPayments;
        totalLabel = Resources.Total.toLocaleUpperCase();
        totalAmount = statementsStore.paymentsTotalAmount;
        templateGetter = this.props.fetchPaymentsTemplate;
        break;
      default:
        break;
    }

    let dateFilterLabel = Resources.AnyTime;
    const selectedViewDateFilters = this.state.dateFilters[this.state.selectedView];

    if (moment(selectedViewDateFilters.fromDate, "YYYY-MM-dd").isValid()) {
      dateFilterLabel =
        moment(selectedViewDateFilters.fromDate).format("l") +
        " - " +
        moment(selectedViewDateFilters.toDate).format("l");
    }

    switch (selectedViewDateFilters.fromDate) {
      case "pastWeek":
        dateFilterLabel = Resources.PastWeek;
        break;
      case "pastMonth":
        dateFilterLabel = Resources.PastMonth;
        break;
      case "pastYear":
        dateFilterLabel = Resources.PastYear;
        break;
      default:
        break;
    }

    return (
      <React.Fragment>
        <ul className="perspective-tabs nav nav-tabs">
          <li className="perspective-item nav-item">
            <div
              onClick={() => this.setState({ selectedView: "openInvoices" })}
              className={(this.state.selectedView === "openInvoices" ? "active" : "") + " nav-link clickable"}
            >
              {`${Resources.OpenInvoices} (${statementsStore.openInvoicesCount})`}
            </div>
          </li>
          <li className="perspective-item nav-item">
            <div
              onClick={() => this.setState({ selectedView: "closedInvoices" })}
              className={(this.state.selectedView === "closedInvoices" ? "active" : "") + " nav-link clickable"}
            >
              {`${Resources.ClosedInvoices} (${statementsStore.closedInvoicesCount})`}
            </div>
          </li>
          <li className="perspective-item nav-item">
            <div
              onClick={() => this.setState({ selectedView: "payments" })}
              className={(this.state.selectedView === "payments" ? "active" : "") + " nav-link clickable"}
            >
              {`${Resources.Payments} (${statementsStore.paymentsCount})`}
            </div>
          </li>
        </ul>
        <div className="flex-split statements-menu-bar">
          <div className="dropdown">
            <span role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <span className="font-weight-bold">{dateFilterLabel}</span>
              <span className={Icons.dropdown} />
            </span>
            <span className="dropdown-menu">
              <div className="dropdown-item" onClick={() => this.updateDateRange()}>
                {Resources.AnyTime}
              </div>
              <div className="dropdown-item" onClick={() => this.updateDateRange("pastWeek")}>
                {Resources.PastWeek}
              </div>
              <div className="dropdown-item" onClick={() => this.updateDateRange("pastMonth")}>
                {Resources.PastMonth}
              </div>
              <div className="dropdown-item" onClick={() => this.updateDateRange("pastYear")}>
                {Resources.PastYear}
              </div>
              <div
                className="dropdown-item"
                onClick={() => {
                  this.props.displayModal("customDateRangeModal", {
                    updateDateRange: this.updateDateRange,
                    fromDate:
                      this.mapFromDate(selectedViewDateFilters.fromDate) || moment().format("YYYY-MM-DD HH:mm:ss"),
                    toDate: selectedViewDateFilters.toDate || moment().format("YYYY-MM-DD HH:mm:ss")
                  });
                }}
              >
                {Resources.CustomRange}
              </div>
            </span>
          </div>
          <SyncedWith connectorName={registeredConnector.displayName} />
        </div>
        <div className="flex-split">
          <h4 className="p-3">
            <span className="fw-500">{totalLabel}</span> {formatCurrency(totalAmount)}
          </h4>
          {data.length > 0 && (
            <h6
              className="send-statements-button"
              onClick={() =>
                this.props.displayModal("sendStatementModal", {
                  perspectiveId: params.perspectiveId,
                  withCompanyId: (contextGroupsStore.contextGroupsMaps[params.selectedContextGroupId] || {}).companyId,
                  withCompanyName: (contextGroupsStore.contextGroupsMaps[params.selectedContextGroupId] || {})
                    .companyName,
                  companyId: params.companyId,
                  parentPerspective: this.props.parentPerspective,
                  fetchTemplate: () => {
                    const args = [
                      params.companyId,
                      params.perspectiveId,
                      (contextGroupsStore.contextGroupsMaps[params.selectedContextGroupId] || {}).companyId,
                      {
                        fromDate: this.mapFromDate(this.state.dateFilters[this.state.selectedView].fromDate),
                        toDate: this.state.dateFilters[this.state.selectedView].toDate
                      }
                    ];
                    return templateGetter(...args);
                  }
                })
              }
            >
              <span className={`${Icons.arrowRight} mr-2`} />
              {this.state.selectedView === "payments" ? Resources.SendPaymentsSummary : Resources.SendInvoiceSummary}
            </h6>
          )}
        </div>
        <TableData
          name="statements-table"
          data={data}
          columns={columns}
          rowHeight="4em"
          onLoadMore={handleLoadMore}
          headerClassName="statements-header"
          maxRows={maxRows}
          loading={loading}
          rowClassName="statements-view-row"
          emptyRender={
            <div className="flex-center">
              <h4 className="mt-5">{Resources.EmptyStatements}</h4>
            </div>
          }
        />
      </React.Fragment>
    );
  }
}

const storeToProps = store => {
  return {
    contextGroupsStore: store.contextGroups,
    statementsStore: store.statements,
    ledgerStore: store.ledger
  };
};

export default connect(storeToProps, dispatchToProps)(StatementsView);
