import React, { Component } from "react";
import { connect } from "react-redux";

import FlexibleScrollContainer from "../flexibleScrollContainer";
import MainLoader from "../mainLoader";
import Resources from "../../lib/resources";
import { findIndex, isEmpty, includes } from "../../lib/utils";

import Checkbox from "./checkbox";
import IconArrowRight from "./icons/iconArrowRight";
import IconArrowLeft from "./icons/iconArrowLeft";

import { dispatchToProps as genDP } from "../../store/general-actions";
import IconAngleDown from "./icons/iconAngleDown";

const dispatchToProps = dispatch => ({
  ...genDP(dispatch)
});

class TableData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasScrollbar: false,
      hoveredRow: null,
      scrollbarWidth: 0,
      loadedPage: props.loadedPage || 1
    };

    this.ensureScrollbarStatus = this.ensureScrollbarStatus.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    let { loadedPage } = this.state;
    let { pageRowCount } = this.props;
    // If pageRowCount updates we call the parent and act like a new page was selected
    let newLoadedPage = loadedPage;
    if (pageRowCount !== prevProps.pageRowCount) {
      let skip = loadedPage * prevProps.pageRowCount - prevProps.pageRowCount;
      newLoadedPage = skip / pageRowCount;

      if (Number.isInteger(newLoadedPage)) {
        newLoadedPage++;
      } else {
        newLoadedPage = Math.ceil(newLoadedPage);
      }

      if (!isEmpty(this.props.onLoadMore)) {
        this.props.onLoadMore(newLoadedPage);
      }
    }
    if (!isEmpty(this.props.loadedPage) && this.props.loadedPage !== loadedPage) {
      newLoadedPage = this.props.loadedPage;
    }

    if (newLoadedPage !== loadedPage) {
      this.setState({ loadedPage: newLoadedPage });
    }
  }

  ensureScrollbarStatus(isPresent, scrollWidth) {
    if (this.state.hasScrollbar !== isPresent || this.state.scrollbarWidth !== scrollWidth) {
      this.setState({ hasScrollbar: isPresent, scrollbarWidth: scrollWidth });
    }
  }

  renderTableHeader() {
    const {
      columns,
      noHeader,
      onRowSelectToggle,
      selectedRows,
      selectedKeys,
      rowKey,
      noSort,
      hideHeaderWhenLoading,
      loading,
      name = ""
    } = this.props;

    if (hideHeaderWhenLoading && loading) {
      return null;
    }

    let headerWidth = this.state.hasScrollbar ? `calc(100% - ${this.state.scrollbarWidth}px)` : `100%`;

    if (!noHeader) {
      return (
        <div
          className={`table-data-header ${this.props.headerClassName || ""}`}
          data-test-id={name ? name + "__header" : ""}
        >
          <table className="table-data-content" style={{ width: headerWidth }}>
            <thead>
              <tr>
                <td className="table-data-padding-col" />
                {columns.map((column, i) => {
                  let colRowContent;
                  let checked = false;
                  let renderedData = this.getDataToRender();
                  if (selectedRows !== undefined) {
                    checked =
                      selectedRows.length > 0 && renderedData.every((item, i) => includes(selectedRows, item.rowIndex));
                  } else if (selectedKeys !== undefined) {
                    checked =
                      !isEmpty(renderedData) && renderedData.every(item => includes(selectedKeys, item[rowKey]));
                  }
                  if (column.type === "rowSelect" && !isEmpty(onRowSelectToggle)) {
                    if (column.justify === "center") {
                      colRowContent = (
                        <div className="table-data-center-justify">
                          <Checkbox
                            className="conversation-table-checkbox"
                            preventBubbling
                            checked={checked}
                            onChange={() => {
                              let toReturn = "all";
                              if (selectedKeys !== undefined) {
                                toReturn = [];
                                let allChecked = renderedData.every(item => includes(selectedKeys, item[rowKey]));
                                renderedData.forEach(item => {
                                  if (allChecked) {
                                    toReturn.push(item[rowKey]);
                                  } else if (!includes(selectedKeys, item[rowKey])) {
                                    toReturn.push(item[rowKey]);
                                  }
                                });
                              }
                              onRowSelectToggle(toReturn);
                            }}
                          />
                        </div>
                      );
                    } else {
                      colRowContent = (
                        <Checkbox
                          className="conversation-table-checkbox"
                          preventBubbling
                          checked={checked}
                          onChange={() => {
                            let toReturn = "all";
                            if (selectedKeys !== undefined) {
                              toReturn = [];
                              let allChecked = renderedData.every(item => includes(selectedKeys, item[rowKey]));
                              renderedData.forEach(item => {
                                if (allChecked) {
                                  toReturn.push(item[rowKey]);
                                } else if (!includes(selectedKeys, item[rowKey])) {
                                  toReturn.push(item[rowKey]);
                                }
                              });
                            }
                            onRowSelectToggle(toReturn);
                          }}
                        />
                      );
                    }
                  } else {
                    colRowContent = column.header instanceof Function ? column.header(noSort) : column.header;
                  }
                  return (
                    <td
                      className={`table-heading-row ${
                        !isEmpty(column.sortable) && column.sortable(noSort) ? "sortable" : ""
                      } ${column.headerClassName || ""}`}
                      style={{ width: column.width }}
                      key={i}
                    >
                      {colRowContent}
                    </td>
                  );
                })}
                <td className="table-data-padding-col" />
              </tr>
            </thead>
          </table>
        </div>
      );
    } else {
      return null;
    }
  }

  getDataToRender() {
    const { data, pageRowCount, loading, pagination, maxRows } = this.props;
    let { loadedPage } = this.state;

    if (!pagination) {
      return data;
    }

    if (loading && pagination) {
      return [];
    }

    let dataToRender = data.map((row, rowIndex) => {
      // If maxRows is not present, we can assume that further api calls are needed to fetch data
      // This will stop rendering data when data needs to be fetched and when it is beyong pageRowCount
      // This is to avoid rendering all data passed into the component in the event that more is passed in than the pageRowCount
      if (data.length > pageRowCount && !isEmpty(maxRows) && rowIndex > pageRowCount) {
        return null;
      }
      if (
        data.length > pageRowCount &&
        isEmpty(maxRows) &&
        (rowIndex < pageRowCount * (loadedPage - 1) || rowIndex >= pageRowCount * loadedPage)
      ) {
        return null;
      }
      if (
        data.length > pageRowCount &&
        isEmpty(maxRows) &&
        (rowIndex < pageRowCount * (loadedPage - 1) || rowIndex >= pageRowCount * loadedPage)
      ) {
        return null;
      }
      return { ...row, rowIndex: pageRowCount * (loadedPage - 1) + rowIndex };
    });
    return dataToRender.filter(row => row != null);
  }

  renderTableBody() {
    const {
      loading,
      emptyRender,
      hoverColumns,
      columns,
      rowHeight,
      rowClassName,
      selectedRows,
      selectedKeys,
      rowKey,
      onRowSelectToggle,
      pagination,
      name = ""
    } = this.props;
    let dataToRender = this.getDataToRender();
    if (dataToRender.length === 0) {
      if (loading === true) {
        return <MainLoader fullScreen className="table-data-loader-fullscreen" />;
      }

      return emptyRender;
    }

    return (
      <table className="table-data-content" data-test-id={name ? name + "__body" : ""}>
        <tbody>
          <tr key={"hidden"} className={`table-data-row hidden`}>
            <td className="table-data-padding-col" />
            {columns.map((column, colIndex) => {
              return (
                <td
                  key={colIndex}
                  className={column.className}
                  style={{ width: column.width }}
                  colSpan={column.colSpan || 1}
                ></td>
              );
            })}
            <td className="table-data-padding-col" />
          </tr>
          {dataToRender.map((row, i) => {
            let rowIndex;
            if (pagination) {
              rowIndex = row.rowIndex;
            } else {
              rowIndex = i;
            }
            let rowColumns = columns;
            if (!isEmpty(hoverColumns) && this.state.hoveredRow === rowIndex) {
              rowColumns = hoverColumns;
            }

            let rowSelected = isEmpty(rowKey)
              ? findIndex(selectedRows, row => rowIndex === row) > -1
              : findIndex(selectedKeys, key => key === row[rowKey]) > -1;

            let rowId = name ? name + "__body__row--" + rowIndex : "";

            return (
              <tr
                key={rowIndex}
                data-test-id={rowId}
                className={`table-data-row ${rowIndex % 2 === 1 ? "alternate" : ""} ${(rowClassName || "",
                this.props.noHover ? "no-hover" : "")} ${rowSelected ? "table-data-row-selected" : ""}`}
                style={{ height: rowHeight }}
                onMouseEnter={() => this.setState({ hoveredRow: rowIndex })}
                onMouseLeave={() => {
                  this.setState({ hoveredRow: null });
                }}
                onClick={() => {
                  if (this.props.onRowClick) {
                    this.props.onRowClick(row, rowIndex);
                  }
                }}
              >
                <td className="table-data-padding-col" />
                {rowColumns.map((column, colIndex) => {
                  let colRowContent;
                  let colId = name && column.name ? rowId + "--" + column.name : "";
                  if (column.type === "rowSelect" && !isEmpty(onRowSelectToggle)) {
                    let checked = false;
                    if (!isEmpty(selectedRows)) {
                      checked = findIndex(selectedRows, row => row === rowIndex) > -1;
                    } else if (!isEmpty(selectedKeys)) {
                      checked = findIndex(selectedKeys, key => row[rowKey] === key) > -1;
                    }
                    if (!isEmpty(column.content) && checked === false && this.state.hoveredRow !== rowIndex) {
                      colRowContent = column.content(row, rowIndex);
                    } else {
                      if (column.justify === "center") {
                        colRowContent = (
                          <div className="table-data-center-justify">
                            <Checkbox
                              className="conversation-table-checkbox"
                              preventBubbling
                              checked={checked}
                              onChange={() => {
                                if (selectedRows !== undefined) {
                                  onRowSelectToggle(rowIndex);
                                } else {
                                  onRowSelectToggle(row[rowKey]);
                                }
                              }}
                            />
                          </div>
                        );
                      } else {
                        colRowContent = (
                          <Checkbox
                            className="conversation-table-checkbox"
                            preventBubbling
                            checked={checked}
                            onChange={() => {
                              if (selectedRows !== undefined) {
                                onRowSelectToggle(rowIndex);
                              } else {
                                onRowSelectToggle(row[rowKey]);
                              }
                            }}
                          />
                        );
                      }
                    }
                  } else {
                    colRowContent = column.content(row, rowIndex);
                  }
                  return (
                    <td
                      key={colIndex}
                      className={column.className}
                      style={{ width: column.width }}
                      colSpan={column.colSpan || 1}
                      data-test-id={colId}
                    >
                      {colRowContent}
                    </td>
                  );
                })}
                <td className="table-data-padding-col" />
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  renderPagination() {
    let numPages = Math.ceil((this.props.maxRows || this.props.data.length) / this.props.pageRowCount);

    if (isEmpty(this.props.maxRows)) {
      if (this.props.data.length <= 10) {
        return null;
      }
    } else {
      if (this.props.maxRows <= 10) {
        return null;
      }
    }

    if (this.props.loading) {
      return null;
    }

    if (this.props.data.length === 0) {
      return <div className="table-data-pagination noselect"></div>;
    }

    let pages = [];
    for (let i = 1; i <= numPages; i++) {
      switch (true) {
        case i === 1:
          pages = [...pages, i];
          break;
        case i === numPages:
          pages = [...pages, i];
          break;
        case i === this.state.loadedPage:
          pages = [...pages, i];
          break;
        // These next two handle when the loaded page is <= 6
        case this.state.loadedPage <= 6 && i < 10:
          pages = [...pages, i];
          break;
        case this.state.loadedPage <= 6 && i === 10:
          pages = [...pages, "..."];
          break;
        // These next two are to handle when the loaded page
        // is on a middle page
        case i >= this.state.loadedPage - 3 && i <= this.state.loadedPage + 3:
          pages = [...pages, i];
          break;

        // These next two handle when the loaded page is
        // within the last 6 pages
        case this.state.loadedPage >= numPages - 5 && i > numPages - 9:
          pages = [...pages, i];
          break;
        case this.state.loadedPage >= numPages - 5 && i === numPages - 9:
          pages = [...pages, "..."];
          break;

        case i === this.state.loadedPage - 4 || i === this.state.loadedPage + 4:
          pages = [...pages, "..."];
          break;
        default:
          break;
      }
    }

    pages = pages.map((page, i) => {
      return (
        <button
          disabled={page === "..."}
          onClick={() => {
            if (!isEmpty(this.props.onLoadMore)) {
              this.props.onLoadMore(page);
            }
            this.setState({ loadedPage: page });
          }}
          className={`table-data-pagination-nav-item ${this.state.loadedPage === page ? "active" : ""}`}
          key={page + i}
        >
          {page}
        </button>
      );
    });

    return (
      <div
        className="table-data-pagination noselect"
        data-test-id={this.props.name ? this.props.name + "__pagination" : ""}
      >
        <div className="table-data-pagination-rows-to-load">{this.renderPageRowCount()}</div>
        {(this.props.maxRows || this.props.data.length) > this.props.pageRowCount && (
          <div className="table-data-pagination-nav">
            <button
              disabled={this.state.loadedPage === 1}
              onClick={() => {
                if (!isEmpty(this.props.onLoadMore)) {
                  this.props.onLoadMore(this.state.loadedPage - 1);
                }
                this.setState({ loadedPage: this.state.loadedPage - 1 });
              }}
              className={`table-data-pagination-nav-item`}
            >
              <IconArrowLeft height={18} />
            </button>
            {pages}
            <button
              disabled={this.state.loadedPage === numPages}
              onClick={() => {
                if (!isEmpty(this.props.onLoadMore)) {
                  this.props.onLoadMore(this.state.loadedPage + 1);
                }
                this.setState({ loadedPage: this.state.loadedPage + 1 });
              }}
              className={`table-data-pagination-nav-item`}
            >
              <IconArrowRight height={18} />
            </button>
          </div>
        )}
        <div className="table-data-pagination-rows-to-load"></div>
      </div>
    );
  }

  renderPageRowCount() {
    let options = [10, 20, 40, 100];

    return (
      <div className="dropdown button-page-row-count">
        <div
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          className="button-page-row-count__trigger"
        >
          {Resources.ShowNItems(this.props.pageRowCount)}
          <IconAngleDown height={8} className="pl-2" />
        </div>
        <span className="dropdown-menu">
          {options.map(option => (
            <span
              className={`dropdown-item ${this.props.pageRowCount === option ? "active" : ""}`}
              key={option}
              onClick={() => this.props.updatePageRowCount(option)}
            >
              {Resources.ShowNItems(option)}
            </span>
          ))}
        </span>
      </div>
    );
  }

  render() {
    const { data, className, pagination, noScroll, name = "" } = this.props;

    return (
      <div className={`table-data ${className ? className : ""}`} data-test-id={name}>
        {this.renderTableHeader()}
        {navigator.onLine === false && (
          <div className="notification notification-alert flex-column w-100 py-2">
            <span>{Resources.YoureOffline}</span>
          </div>
        )}
        {pagination ? (
          <React.Fragment>
            {this.renderTableBody()}
            {this.renderPagination()}
          </React.Fragment>
        ) : noScroll ? (
          this.renderTableBody()
        ) : (
          <FlexibleScrollContainer
            scrollbarPresent={(isPresent, scrollWidth) => this.ensureScrollbarStatus(isPresent, scrollWidth)}
            maxHeight={this.props.maxHeight}
            onLoadMore={this.props.onLoadMore}
            maxRows={this.props.maxRows}
            loading={this.props.loading}
            loaded={data.length}
            noGrow={this.props.noGrow}
            offsetHeight={this.props.offsetHeight}
          >
            {this.renderTableBody()}
          </FlexibleScrollContainer>
        )}
      </div>
    );
  }
}

TableData.defaultProps = {
  noSort: false,
  emptyRender: (
    <div className="flex-center">
      <h4 className="mt-4">{Resources.NothingToShowHere}</h4>
    </div>
  )
};

const storeToProps = store => {
  return {
    pageRowCount: store.general.pageRowCount
  };
};

export default connect(storeToProps, dispatchToProps)(TableData);
