import React, { Component } from "react";

import Resources from "../../lib/resources";

import IconAutoPay from "../library/icons/iconAutoPay";
import IconAngleRight from "../library/icons/iconAngleRight";

export default class AutoPayIndicator extends Component {
  render() {
    let { onClickManage, autoPayEnabled } = this.props;

    return (
      <div className="dropdown auto-pay-indicator">
        <div
          className="button-secondary auto-pay-indicator button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <IconAutoPay height={18} style={{ marginRight: "0.33rem" }} />
          <span>{autoPayEnabled ? Resources.AutomaticPaymentsAreOn : Resources.AutomaticPaymentsAreOff}</span>
        </div>
        <div className="auto-pay-indicator content dropdown-menu">
          {autoPayEnabled
            ? Resources.WeWillPayAllOpenInvoicesOnTheirGivenPaymentDates
            : Resources.WhenEnabledWeWillPayAllOpenInvoicesOnTheirGivenPaymentDates}
          <button
            className="button-secondary"
            onClick={onClickManage}
            style={{ justifyContent: "left", paddingBottom: 0 }}
          >
            {Resources.ManageAutomaticPayments}
            <IconAngleRight height={15} />
          </button>
        </div>
      </div>
    );
  }
}
