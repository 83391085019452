import React, { Component } from "react";

import IconHelp from "../../library/icons/iconHelp";
import Resources from "../../../lib/resources";

export default class CustomerGroupsHelpDropdown extends Component {
  render() {
    return (
      <div className="account-groups-info-dropdown dropdown">
        <button
          className="account-groups-info-dropdown button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <IconHelp height={12} />
        </button>
        <div
          className="account-groups-info-dropdown content dropdown-menu"
          dangerouslySetInnerHTML={{
            __html: Resources.AccountGroupsInfo
          }}
        ></div>
      </div>
    );
  }
}
