import { actions } from "./conversations-actions";
import { replaceAt, findIndex, isEmpty, includes } from "../lib/utils";

export const defaultConversationsState = {
  companyId: null,
  withCompanyId: null,

  isShowingSearchResult: false,

  gettingConversations: {},
  fetchedConversations: {},
  conversations: {},
  gettingConversation: false,
  conversationId: null,
  conversation: {},
  related: {
    maxLength: 500,
    keys: []
  },
  creatingConversation: false,
  gettingStatuses: false,
  fetchedStatuses: false,
  statuses: [],
  statusesLookup: {},
  isUpdatingConversationReadStatuses: false,
  updatedConversationReadStatuses: false,
  failedUpdatingConversationReadStatuses: false,

  assigningConversations: false,
  settingConversationStatuses: false,
  setConversationStatuses: false,
  failedSetConversationStatuses: false,
  conversationIndex: null,
  moveToNext: false,
  sortBy: "ModifiedDate",
  sortDirection: "desc",

  isDeletingConversation: false,

  gettingDocuments: false,
  gotDocuments: false,
  failedGetDocuments: null,
  documents: {
    value: [],
    nextLink: null,
    count: null,
    sortBy: "CreatedDate",
    sortDirection: "desc"
  },

  perspectiveId: null,
  gettingReminders: false,
  gotReminders: false,
  failedGetReminders: false,
  reminders: {
    value: [],
    nextLink: null,
    count: null,
    sortBy: "createdDate",
    sortDirection: "desc"
  },

  gettingApprovalRequest: false,
  getApprovalRequest: false,
  failedGetApprovalRequest: false,
  approvalRequest: {},

  puttingApprovalRequestAction: false,
  putApprovalRequestAction: false,
  failedPutApprovalRequestAction: false,

  gettingConversationsSearchResults: false,
  gotConversationsSearchResults: false,
  failedGetConversationsSearchResults: false,
  conversationsSearchTerm: "",
  conversationsSearchResults: {
    value: [],
    count: 0
  }
};

const ItemTypes = {
  Conversations: "CONVERSATIONS",
  Conversation: "CONVERSATION"
};

function conversationsReducer(state = defaultConversationsState, action) {
  if (window.logToConsole) console.log(action);

  if (action.type === actions.SELECT_COMPANY) {
    if (state.companyId === action.companyId) return state;
    return { ...defaultConversationsState, companyId: action.companyId };
  }

  switch (action.type) {
    case actions.SET_CONVERSATIONS_TO_SEARCH_RESULT:
      return {
        ...state,
        isShowingSearchResult: true,
        conversations: {
          ...state.conversations,
          [action.perspectiveId]: { ...state.conversations[action.perspectiveId], value: action.conversations }
        }
      };
    case actions.GETTING_CONVERSATION_STATUSES:
      return { ...state, ...{ gettingStatuses: true, fetchedStatuses: false } };
    case actions.GET_CONVERSATION_STATUSES_FAILED:
      return {
        ...state,
        ...{ gettingStatuses: false, fetchedStatuses: false }
      };
    case actions.GOT_CONVERSATION_STATUSES:
      if (state.gettingStatuses !== true) return state;
      let statuses = [...action.statuses];
      let statusesLookup = {};
      statuses.sort((a, b) => {
        return a < b ? 1 : a === b ? 0 : -1;
      });
      statuses.forEach(s => {
        const getDisplayStyle = name => {
          switch (name) {
            case "active":
              return "highlight";
            case "closed":
            case "spam":
              return "lowlight";
            default:
              return "normal";
          }
        };
        s.displayStyle = s.displayStyle || getDisplayStyle(s.conversationStatusName.toLocaleLowerCase());
        statusesLookup[s.conversationStatusId] = s;
      });
      return {
        ...state,
        ...{
          gettingStatuses: false,
          fetchedStatuses: true,
          statuses,
          statusesLookup
        }
      };

    case actions.UPDATING_CONVERSATION_READ_STATUSES:
      return {
        ...state,
        isUpdatingConversationReadStatuses: true,
        updatedConversationReadStatuses: false,
        failedUpdatingConversationReadStatuses: false
      };

    case actions.UPDATED_CONVERSATION_READ_STATUSES:
      let nextConversations = {};
      Object.keys(state.conversations).forEach(perspectiveId => {
        const newValue = state.conversations[perspectiveId].value.map(conv => {
          const _conv = { ...conv };
          if (includes(action.conversationIds, conv.conversationId)) {
            _conv.hasUnreads = !action.isRead;
          }

          return _conv;
        });

        const nextPerspectiveConversations = {
          ...state.conversations[perspectiveId],
          value: newValue
        };

        nextConversations[perspectiveId] = nextPerspectiveConversations;
      });

      return {
        ...state,
        isUpdatingConversationReadStatuses: false,
        updatedConversationReadStatuses: true,
        failedUpdatingConversationReadStatuses: false,
        conversations: nextConversations
      };
    case actions.UPDATE_CONVERSATION_READ_STATUSES_FAILED:
      return {
        ...state,
        isUpdatingConversationReadStatuses: false,
        updatedConversationReadStatuses: false,
        failedUpdatingConversationReadStatuses: true
      };

    case actions.CLEAR_CONVERSATIONS:
      return { ...state, conversations: {} };

    case actions.GETTING_CONVERSATIONS:
      let gcState = updateGettingFetched(state, action.perspective, true, ItemTypes.Conversations);
      if (gcState.withCompanyId !== action.withCompanyId && action.withCompanyId) {
        gcState.withCompanyId = action.withCompanyId;
        gcState.conversations = {};
      }
      if (action.clearConversations) {
        gcState.conversations = {};
      }
      gcState.label = action.label;
      gcState.isShowingSearchResult = false;
      return gcState;
    case actions.GET_CONVERSATIONS_FAILED:
      let gcfState = updateGettingFetched(state, action.perspective, false, ItemTypes.Conversations);
      return gcfState;
    case actions.GOT_CONVERSATIONS:
      if (action.label && state.label !== action.label) {
        if (action.label === "unassigned" && isEmpty(state.unassignedUnreads)) {
          // allow state to update unassignedUnreads with out of order fetch
          return { ...state, unassignedUnreads: action.conversations.unreadCount };
        }
        return state;
      }
      if (state.gettingConversations[action.perspective] !== true) return state;
      if (state.withCompanyId !== action.withCompanyId && action.withCompanyId) return state;

      let gcsState = updateGettingFetched(state, action.perspective, false, ItemTypes.Conversations);
      let gcs = action.conversations;

      if (action.nextLink) {
        gcs.value = [...gcsState.conversations[action.perspective].value, ...action.conversations.value];
      }

      gcsState.conversations[action.perspective] = gcs;
      if (action.label === "unassigned") {
        gcsState.unassignedUnreads = gcs.unreadCount;
      }
      gcsState.withCompanyId = action.withCompanyId;
      return gcsState;

    case actions.GETTING_CONVERSATIONS_SEARCH_RESULTS:
      return {
        ...state,
        gettingConversationsSearchResults: true,
        gotConversationsSearchResults: false,
        failedGetConversationsSearchResults: false,
        conversationsSearchTerm: action.searchTerm
      };
    case actions.GOT_CONVERSATIONS_SEARCH_RESULTS:
      return {
        ...state,
        gettingConversationsSearchResults: false,
        gotConversationsSearchResults: true,
        failedGetConversationsSearchResults: false,
        conversationsSearchResults: {
          ...action.conversations
        }
      };
    case actions.FAILED_GET_CONVERSATIONS_SEARCH_RESULTS:
      return {
        ...state,
        gettingConversationsSearchResults: false,
        gotConversationsSearchResults: false,
        failedGetConversationsSearchResults: true
      };
    case actions.CLEAR_CONVERSATIONS_SEARCH_RESULTS:
      return {
        ...state,
        gettingConversationsSearchResults: false,
        gotConversationsSearchResults: false,
        failedGetConversationsSearchResults: false,
        conversationsSearchResults: defaultConversationsState.conversationsSearchResults,
        conversationsSearchTerm: defaultConversationsState.conversationsSearchTerm
      };

    case actions.ASSOCIATION_ADDED:
      let aaState = { ...state };
      aaState = updateConversations(aaState, "conversations", action.conversation);
      if (aaState.conversationId === action.conversation.conversationId) {
        aaState.conversation = action.conversation;
      }
      return aaState;

    case actions.GETTING_CONVERSATION:
      return {
        ...state,
        ...{ gettingConversation: true, conversationId: action.conversationId }
      };
    case actions.GET_CONVERSATION_FAILED:
      if (action.conversationId !== state.conversationId) {
        return state;
      }
      return {
        ...state,
        ...{
          gettingConversation: false,
          conversationId: null,
          conversation: {}
        }
      };
    case actions.CLEAR_CONVERSATION:
      return {
        ...state,
        ...{
          gettingConversation: false,
          conversationId: null,
          conversation: {}
        }
      };
    case actions.GOT_CONVERSATION:
      if (action.conversationId !== state.conversationId) {
        return state;
      }
      return {
        ...state,
        ...{
          gettingConversation: false,
          conversation: action.conversation,
          conversationId: action.conversationId
        }
      };
    case actions.START_CONVERSATION:
      return {
        ...state,
        ...{
          gettingConversation: false,
          conversationId: "new",
          conversation: { new: true, participantIds: [], activityType: action.activityType }
        }
      };

    case actions.SET_MAX_RELATED_LENGTH:
      if (state.related.maxLength > action.maxLength) return state;
      let smrLength = { ...state.related };
      smrLength.maxLength = action.maxLength;
      return { ...state, ...{ related: smrLength } };

    case actions.GOT_RELATED:
      if (state.related[action.addressId]) return state;
      let grState = { ...state };
      let gr = { ...grState.related };
      grState.related = gr;
      let grKeys = [...gr.keys];
      gr.keys = grKeys;
      grKeys.unshift(action.addressId);
      gr[action.addressId] = action.related;
      if (grKeys.length > gr.maxLength) {
        let popped = grKeys.pop();
        delete gr[popped];
      }
      return grState;

    case actions.CREATING_CONVERSATION:
      return { ...state, creatingConversation: true };
    case actions.CREATE_CONVERSATION_FAILED:
      return { ...state, creatingConversation: false };
    case actions.CREATED_CONVERSATION:
      let createdState = { ...state, creatingConversation: false };

      createdState = insertConversation(createdState, "conversations", action.conversation);

      return createdState;

    case actions.DELETING_CONVERSATION:
      return { ...state, isDeletingConversation: true };
    case actions.DELETED_CONVERSATION:
      return { ...state, isDeletingConversation: false };
    case actions.DELETE_CONVERSATION_FAILED:
      return { ...state, isDeletingConversation: false };

    case actions.ASSIGNING_CONVERSATIONS:
      return { ...state, assigningConversations: true };
    case actions.ASSIGN_CONVERSATIONS_FAILED:
      return { ...state, assigningConversations: false };

    case actions.SETTING_CONVERSATION_STATUSES:
      return {
        ...state,
        settingConversationStatuses: true,
        setConversationStatuses: false,
        failedSetConversationStatuses: false
      };
    case actions.SET_CONVERSATION_STATUSES_FAILED:
      return {
        ...state,
        settingConversationStatuses: false,
        setConversationStatuses: false,
        failedSetConversationStatuses: true
      };

    case actions.SET_CONVERSATION_STATUSES:
      return {
        ...state,
        settingConversationStatuses: false,
        setConversationStatuses: true,
        failedSetConversationStatuses: false
      };
    case actions.ASSIGNED_CONVERSATIONS:
      return {
        ...state,
        assigningConversations: false
      };

    case actions.SET_CONVERSATION_INDEX:
      return { ...state, conversationIndex: action.index };
    case actions.SET_CONVERSATION_MOVE_TO_NEXT:
      return { ...state, moveToNext: action.moveToNext };

    case actions.SET_CONVERSATION_SORT:
      return {
        ...state,
        sortBy: action.sortBy,
        sortDirection: action.sortDirection
      };

    case actions.SET_DOCUMENT_SORT:
      return {
        ...state,
        documents: { ...state.documents, sortBy: action.sortBy, sortDirection: action.sortDirection }
      };

    case actions.GETTING_DOCUMENTS:
      if (!action.next) {
        return {
          ...state,
          gettingDocuments: true,
          failedGetDocuments: false,
          gotDocuments: false,
          documents: { ...state.documents, value: [] },
          selector: action.selector,
          perspectiveId: action.perspectiveId
        };
      }
      return {
        ...state,
        gettingDocuments: true,
        failedGetDocuments: false,
        gotDocuments: false,
        selector: action.selector
      };
    case actions.GET_DOCUMENTS_FAILED:
      if (action.selector !== state.selector || state.perspectiveId !== action.perspectiveId) {
        return state;
      }
      return {
        ...state,
        gettingDocuments: false,
        failedGetDocuments: true,
        gotDocuments: false,
        documents: defaultConversationsState.documents
      };
    case actions.GOT_DOCUMENTS:
      if (
        (!isEmpty(state.selector) && state.selector !== action.selector) ||
        state.companyId !== action.companyId ||
        state.perspectiveId !== action.perspectiveId
      ) {
        return state;
      }
      let returnState = {
        ...state,
        gettingDocuments: false,
        failedGetDocuments: false,
        gotDocuments: true,
        documents: {
          ...state.documents,
          nextLink: action.nextLink,
          count: action.count,
          value: [...state.documents.value, ...action.documents]
        },
        selector: action.selector
      };
      return returnState;

    case actions.GETTING_REMINDERS:
      if (state.perspectiveId !== action.perspectiveId || state.companyId !== action.companyId) {
        return {
          ...state,
          gettingReminders: true,
          failedGetReminders: false,
          gotReminders: false,
          reminders: defaultConversationsState.reminders,
          perspectiveId: action.perspectiveId,
          companyId: action.companyId
        };
      }
      return {
        ...state,
        gettingReminders: true,
        failedGetReminders: false,
        gotReminders: false,
        perspectiveId: action.perspectiveId
      };
    case actions.GET_REMINDERS_FAILED:
      if (action.perspectiveId !== state.perspectiveId) {
        return state;
      }
      return {
        ...state,
        gettingReminders: false,
        failedGetReminders: true,
        gotReminders: false,
        reminders: { ...defaultConversationsState.reminders, count: state.reminders.count }
      };
    case actions.GOT_REMINDERS:
      if (state.perspectiveId !== action.perspectiveId || state.companyId !== action.companyId) {
        return state;
      }
      let reminderReturnState = {
        ...state,
        gettingReminders: false,
        failedGetReminders: false,
        gotReminders: true,
        reminders: {
          nextLink: action.nextLink,
          count: action.count,
          value: [...state.reminders.value, ...action.reminders]
        },
        perspectiveId: action.perspectiveId
      };
      return reminderReturnState;

    case actions.GETTING_APPROVAL_REQUEST:
      return {
        ...state,
        gettingApprovalRequest: true,
        getApprovalRequest: false,
        failedGetApprovalRequest: false,
        approvalRequest: {}
      };
    case actions.GOT_APPROVAL_REQUEST:
      return {
        ...state,
        gettingApprovalRequest: false,
        getApprovalRequest: true,
        failedGetApprovalRequest: false,
        approvalRequest: action.approvalRequest
      };
    case actions.GET_APPROVAL_REQUEST_FAILED:
      return {
        ...state,
        gettingApprovalRequest: false,
        getApprovalRequest: false,
        failedGetApprovalRequest: true
      };

    case actions.PUTTING_APPROVAL_REQUEST_ACTION:
      return {
        ...state,
        puttingApprovalRequestAction: true,
        putApprovalRequestAction: false,
        failedPutApprovalRequestAction: false
      };
    case actions.PUT_APPROVAL_REQUEST_ACTION:
      return {
        ...state,
        puttingApprovalRequestAction: false,
        putApprovalRequestAction: true,
        failedPutApprovalRequestAction: false
      };
    case actions.PUT_APPROVAL_REQUEST_ACTION_FAILED:
      return {
        ...state,
        puttingApprovalRequestAction: false,
        putApprovalRequestAction: false,
        failedPutApprovalRequestAction: true
      };

    case actions.CLEAR_DATA:
      return defaultConversationsState;
    default:
      return state;
  }
}

function updateGettingFetched(state, perspective, value, type) {
  let newState = { ...state };
  let getting = {};
  let fetched = {};
  switch (type) {
    case ItemTypes.Conversations:
      getting = { ...newState.gettingConversations };
      newState.gettingConversations = getting;
      fetched = { ...newState.fetchedConversations };
      newState.fetchedConversations = fetched;
      break;
    default:
      return state;
  }

  if (value) getting[perspective] = value;
  else {
    delete getting[perspective];
    fetched[perspective] = true;
  }
  return newState;
}

function updateConversations(state, type, conversation) {
  let conversations = { ...state[type] };
  let perspectives = Object.keys(conversations);
  perspectives.forEach(perspective => {
    let item = { ...conversations[perspective] };
    let found = findIndex(item.value, i => {
      return i.conversationId === conversation.conversationId;
    });
    if (found >= 0) {
      item.value = replaceAt(item.value, found, conversation);
      conversations[perspective] = item;
    }
  });
  return { ...state, [type]: conversations };
}

function insertConversation(state, type, perspective, conversation) {
  let conversations = { ...state[type] };
  let items = { ...conversations[perspective] };
  let value = [conversation, ...items.value];
  items.value = value;
  conversations[perspective] = items;
  return { ...state, [type]: conversations };
}

export default conversationsReducer;
