import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { withRouter } from "react-router-dom";

import { dispatchToProps as uaDP } from "../../store/user-actions";
import { dispatchToProps as compDP } from "../../store/company-actions";
import { dispatchToProps as lgrDP } from "../../store/ledger-actions";
import { dispatchToProps as convDP } from "../../store/conversations-actions";
import { dispatchToProps as conDP } from "../../store/connector-actions";
import { dispatchToProps as persDP } from "../../store/perspectives-actions";
import { dispatchToProps as genDP } from "../../store/general-actions";
import { dispatchToProps as congDP } from "../../store/contextGroups-actions";
import { dispatchToProps as moDP } from "../../store/modal-actions";
import { dispatchToProps as netDP } from "../../store/network-actions";
import { dispatchToProps as manDP } from "../../store/manage-actions";
import { dispatchToProps as accDP } from "../../store/accounts-actions";

import Resources from "../../lib/resources";
import { Perspectives } from "../../lib/perspectives";
import Icons from "../../lib/icons";
import { isEmpty, compareDates, compareStrings, find, includes, isGuid, formatDate } from "../../lib/utils";
import { formatInvoice } from "../../lib/transform-ubl";
import RRule from "rrule";

import CompanyProfile from "../companyProfile/companyProfile";
import ConversationEditor from "./editors/conversationEditor";
import FlexibleScrollContainer from "../flexibleScrollContainer";
import StatusChangeThreadItem from "./threadItems/statusChangeThreadItem";
import AssignmentThreadItem from "./threadItems/assignmentThreadItem";
import ForwardThreadItem from "./threadItems/forwardThreadItem";
import ApprovalThreadItem from "./threadItems/approvalThreadItem";
import ApprovalRequestThreadItem from "./threadItems/approvalRequestThreadItem";
import RejectionThreadItem from "./threadItems/rejectionThreadItem";
import EmailThreadItem from "./threadItems/emailThreadItem";
import DraftThreadItem from "./threadItems/draftThreadItem";
import InvoiceThreadItem from "./threadItems/invoiceThreadItem";
import TaskThreadItem from "./threadItems/taskThreadItem";
import ReminderThreadItem from "./threadItems/reminderThreadItem";
import RequestResponseThreadItem from "./threadItems/requestResponseThreadItem";
import SimpleForwardThreadItem from "./threadItems/simpleForwardThreadItem";
import SimpleForwardResponseThreadItem from "./threadItems/simpleForwardResponseThreadItem";

import ConversationActionButtons from "../lockstep/conversationActionButtons";
import Card from "../library/card";
import MainLoader from "../mainLoader";

const dispatchToProps = dispatch => ({
  ...uaDP(dispatch),
  ...compDP(dispatch),
  ...lgrDP(dispatch),
  ...convDP(dispatch),
  ...conDP(dispatch),
  ...persDP(dispatch),
  ...genDP(dispatch),
  ...congDP(dispatch),
  ...moDP(dispatch),
  ...netDP(dispatch),
  ...manDP(dispatch),
  ...accDP(dispatch)
});

class Conversation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ignoredDrafts: {},
      displayAddresses: {},
      showAsText: false,
      editorMode: null,
      displayedNewCompanyToast: false,
      companyInfo: {},

      creatingEntryType: null,
      entryTo: [],
      entryHtmlBody: "",
      entryTextBody: ""
    };
    this.updateEditorMode = this.updateEditorMode.bind(this);
  }

  componentDidMount() {
    this.tryUpdate();
    if (this.props.isNew) {
      this.setState({ editorMode: "new" });
    }
    this.props.getCompanyUserRoles();
  }

  componentDidUpdate(prevProps) {
    this.tryUpdate(prevProps);
  }

  componentWillUnmount() {
    if (this.props.generalStore.toastInfo.text === Resources.NewCompanyMessage) {
      this.props.hideToast();
    }
  }

  tryUpdate(prevProps = {}) {
    this.props.ensureConversationStatuses();

    let { gettingCompanyInfo, fetchedCompanyInfo, fetchingCompanyInfoFailed } = this.props.companies;
    let {
      companyId,
      conversationId,
      conversation,
      conversation: { participantIds },
      perspective,
      conversations: { gettingConversation },
      match: { params }
    } = this.props;

    if ((prevProps.conversation || {}).conversationId !== this.props.conversation.conversationId) {
      this.props.hideToast();
      this.setState({ displayedNewCompanyToast: false });
      if (
        this.state.displayedNewCompanyToast === false &&
        this.props.isCompanyNew(this.props.conversation.withCompanyId, perspective)
      ) {
        this.props.showToast({
          timeout: 8000,
          type: "orange",
          text: Resources.NewCompanyMessage,
          linkHidden: true
        });
        this.setState({ displayedNewCompanyToast: true });
      }
    }

    this.ensureConversationBucket();

    let fetching = false;
    if (gettingCompanyInfo[companyId]) {
      fetching = true;
    } else if (fetchedCompanyInfo[companyId] !== true && fetchingCompanyInfoFailed[companyId] !== true) {
      this.props.fetchCompanyInfo(companyId);
      fetching = true;
    }

    const outForApprovalChanged =
      prevProps.conversation &&
      conversation.conversationStatusId === "3aaa3297-041e-46ff-a39e-0624a9364b2a" &&
      prevProps.conversation.conversationStatusId !== conversation.conversationStatusId;
    if (
      prevProps.conversation &&
      (conversation.approvalStatus !== prevProps.conversation.approvalStatus || outForApprovalChanged)
    ) {
      this.props.refreshContextGroups(companyId, perspective);
    }

    if (isEmpty(conversationId) === false) {
      if (this.props.isNew !== true) {
        if (conversation.conversationId === conversationId) {
          participantIds.forEach(companyId => {
            if (gettingCompanyInfo[companyId]) {
              fetching = true;
            } else if (fetchedCompanyInfo[companyId] !== true && fetchingCompanyInfoFailed[companyId] !== true) {
              this.props.fetchCompanyInfo(companyId);
              fetching = true;
            }
          });
        } else {
          if (gettingConversation) {
            fetching = true;
          } else {
            this.props.getConversation(conversationId);
            fetching = true;
          }
        }
      } else if (
        prevProps.conversationId !== "new" ||
        (((prevProps.match || {}).params || {}).activityType || prevProps.activityType) !==
          (params.activityType || this.props.activityType) ||
        prevProps.conversationId !== conversationId
      ) {
        this.props.startConversation(params.activityType || this.props.activityType);
      }
    }

    if (!fetching && isEmpty(this.state.companyInfo)) {
      this.updateCompanyInfo();
    }

    if (this.state.fetching !== fetching) {
      this.setState({ fetching });
    }
  }

  setRef(ref, refId) {
    this[refId] = ref;
  }

  ensureConversationBucket() {
    const {
      conversation,
      match: { params }
    } = this.props;
    if (isGuid(params.selectedContextGroupId) !== true) {
      const conversationStatusName = (
        (this.props.getConversationStatus(conversation.conversationStatusId) || {}).conversationStatusName || ""
      ).toLowerCase();

      const assignedUserId =
        typeof conversation.assignedUserId === "string"
          ? conversation.assignedUserId.toLowerCase()
          : conversation.assignedUserId;

      switch (conversationStatusName) {
        case "spam":
          if (params.selectedContextGroupId !== "spam") {
            this.props.history.replace(this.props.location.pathname.replace(params.selectedContextGroupId, "spam"));
          }
          break;
        case "waiting for response":
          if (params.selectedContextGroupId !== "waiting-for-response") {
            this.props.history.replace(
              this.props.location.pathname.replace(params.selectedContextGroupId, "waiting-for-response")
            );
          }
          break;
        case "out for approval":
          if (params.selectedContextGroupId !== "pending-approval") {
            this.props.history.replace(
              this.props.location.pathname.replace(params.selectedContextGroupId, "pending-approval")
            );
          }
          break;
        case "reminder set":
          if (params.selectedContextGroupId !== "reminder") {
            this.props.history.replace(this.props.location.pathname.replace(params.selectedContextGroupId, "reminder"));
          }
          break;
        case "closed":
          if (params.selectedContextGroupId !== "closed") {
            this.props.history.replace(this.props.location.pathname.replace(params.selectedContextGroupId, "closed"));
          }
          break;
        default:
          switch (assignedUserId) {
            case undefined:
              break;
            case null:
              if (params.selectedContextGroupId !== "unassigned") {
                this.props.history.replace(
                  this.props.location.pathname.replace(params.selectedContextGroupId, "unassigned")
                );
              }
              break;
            case this.props.getMyUserIdFromToken().toLowerCase():
              if (params.selectedContextGroupId !== "mine") {
                this.props.history.replace(this.props.location.pathname.replace(params.selectedContextGroupId, "mine"));
              }
              break;
            default:
              if (params.selectedContextGroupId !== "assigned") {
                this.props.history.replace(
                  this.props.location.pathname.replace(params.selectedContextGroupId, "assigned")
                );
              }
          }
          return;
      }
    }
  }

  updateCompanyInfo() {
    let {
      companyId,
      conversations: { conversation },
      perspectiveId,
      partners
    } = this.props;

    let companyInfo = this.props.getCompanyInfo(companyId);
    let cAddress = companyInfo.connectorEmail.synthetic[perspectiveId];
    let companyEmail = isEmpty(cAddress) ? undefined : { name: companyInfo.companyName, address: cAddress };

    let withPerspective =
      perspectiveId === Perspectives.AccountsPayable ? Perspectives.AccountsReceivable : Perspectives.AccountsPayable;

    let withCompanyIds = [
      companyId,
      ...(conversation.participantIds || []).filter(id => {
        return id !== companyId;
      })
    ];

    let withCompanies = {};
    let displayAddresses = {};
    withCompanyIds.forEach(id => {
      let company = { ...(partners[id] || {}) };
      let info = this.props.getCompanyInfo(id);
      company.info = info;
      for (let p in info.connectorEmail.synthetic) {
        let synthetic = info.connectorEmail.synthetic[p];
        let remote = info.connectorEmail.remote[p];
        displayAddresses[synthetic] = {
          name: info.companyName || company.companyName,
          address: synthetic !== remote ? remote : null
        };
      }
      withCompanies[id] = company;
    });

    this.setState({
      companyInfo,
      companyEmail,
      withCompanies,
      withPerspective,
      displayAddresses
    });
  }

  isText(str) {
    return (
      [
        "text",
        "forward",
        "note",
        "email",
        "statuschange",
        "assignmentchange",
        "approvalrequest",
        "approval",
        "rejection",
        "task",
        "reminder",
        "requestresponse",
        "response"
      ].indexOf(str) > -1
    );
  }

  isInvoice(str) {
    return ["invoice"].indexOf(str) > -1;
  }

  formatEmails(type, typeText, rawAddresses = []) {
    let meto = null;
    let clone = rawAddresses.map(ra => {
      return { ...ra };
    });
    let addresses =
      clone.filter(address => {
        if (isEmpty(address) === true) return false;
        if (this.state.companyEmail) {
          if (type === "to" && address.address === this.state.companyEmail.address) {
            meto = address;
            return false;
          } else return true;
        } else return true;
      }) || [];

    if (addresses.length === 0) return null;

    if (type === "to" && addresses.length === 0 && isEmpty(meto) === false) {
      addresses.push(meto);
    }

    addresses.forEach(address => {
      address.displayName = isEmpty(address.name) ? address.address : `${address.name} (${address.address})`;

      let displayAddress = this.state.displayAddresses[address.address];

      if (isEmpty(displayAddress) === false) {
        address.address = displayAddress.address;
        address.name = displayAddress.name;
      }

      address.displayName = isEmpty(address.name) ? address.address : `${address.name} (${address.address})`;
    });

    addresses = addresses.filter(a => {
      return isEmpty(a.address) === false;
    });
    if (addresses.length === 0) return null;

    return (
      <span key={`${type}emails`}>
        <span>{typeText}: </span>
        {addresses.map((address, index) => {
          if (isEmpty(address.address)) return null;
          else
            return (
              <span key={`${type}${address.address}`}>
                {address.displayName}
                {index === addresses.length - 1 ? "" : "; "}
              </span>
            );
        })}
      </span>
    );
  }

  updateEditorMode(mode) {
    this.setState({ editorMode: mode });
  }

  render() {
    let {
      conversation,
      conversationId,
      sendToAccountId,
      sendToContactId,
      conversations,
      getEntry,
      fetchEntry,
      getRelated,
      fetchRelated,
      getAttachments,
      fetchAttachments,
      partners,
      getUserDisplayName,
      perspectiveId,
      connectorStore,
      match: { params }
    } = this.props;
    let perspectiveName = (this.props.getCompanyPerspectiveById(perspectiveId) || {}).perspectiveName;

    if (conversations.gettingConversation && conversations.conversationId === params.selectedItemId) {
      return <MainLoader fullScreen></MainLoader>;
    }

    if (isEmpty(conversation)) {
      return <div />;
    }

    partners = partners || {};

    const companyRootUrl = `/v2/${params.companyId}/${params.perspectiveId || perspectiveId}/activities`;

    let draft = null;
    let fetchingEmails = false;
    let fetchingRelated = false;
    let relatedConversations = {};
    let conversationAddresses = conversation.conversationAddresses || [];

    // this.props.setMaxEntriesLength(conversationAddresses.length * 2);
    let entries = conversationAddresses.map(addr => {
      let entry = getEntry(addr.address);
      let related = null;
      let attachments = null;
      if (!entry) {
        //fetchingEntries = true
        if (this.isText(addr.type)) fetchingEmails = true;
        fetchEntry(addr.address, addr.isDraft);
      } else {
        if (addr.isDraft !== true) {
          related = getRelated(entry.ledgerHash);
          if (!related) {
            fetchingRelated = true;
            fetchRelated(entry.ledgerHash);
          } else {
            related.value.forEach(r => {
              relatedConversations[r.conversationId] = r;
            });
          }
        }
        if (addr.hasAttachments) {
          attachments = getAttachments(entry.ledgerHash);
          if (isEmpty(attachments)) {
            fetchAttachments(entry.ledgerHash);
          }
        }
      }
      let result = {
        type: addr.type,
        entry,
        addr,
        data: {},
        date: moment(addr.associationDate, "YYYY-MM-DDTHH:mm:ssZ"),
        related,
        attachments,
        status:
          this.props.getConversationStatus(addr.conversationStatusId || "65d7d5d6-3615-4710-bce7-7920fd12d0d0") || {}
      };
      if (entry) {
        let data;
        switch (addr.type) {
          case "text":
          case "forward":
          case "note":
          case "email":
          case "statuschange":
          case "assignmentchange":
          case "approvalrequest":
          case "approval":
          case "rejection":
          case "task":
          case "requestresponse":
          case "response":
            data = JSON.parse(entry.content.split("\n").join(""));
            result.data = data;
            result.data.to = (data.to || []).filter(a => {
              return a !== null && a !== undefined;
            });
            result.data.from = data.from.filter(a => {
              return a !== null && a !== undefined;
            });
            result.date = moment(result.data.date || entry.dateCreated, "YYYY-MM-DDTHH:mm:ssZ");
            break;
          case "invoice":
            if (isEmpty(addr.connectorId)) {
              result.isSystemMessage = true;
              result.data =
                entry.parentLedgerHash === ""
                  ? Resources.ResourceCreated(Resources.Invoice)
                  : Resources.ResourceImported(Resources.Invoice);
              result.date = moment(entry.dateCreated, "YYYY-MM-DDTHH:mm:ssZ");
              break;
            } else {
              return result;
            }
          case "reminder":
            data = JSON.parse(entry.content.split("\n").join(""));
            result.data = data;
            result.date = moment(result.data.date || entry.dateCreated, "YYYY-MM-DDTHH:mm:ssZ");
            result.reminderName = (
              (
                find(
                  this.props.manage.workflows[perspectiveId] || [],
                  reminder => reminder.ledgerHash === data.reminderHash
                ) || {}
              ).asJson || {}
            ).name;
            result.dueDate = RRule.fromString(data.recurrenceRule || "").all();
            break;
          default:
            return result;
        }
      }

      if (this.state.draftId === addr.address) {
        draft = result;
      }

      return result;
    });

    entries.sort((a, b) => {
      if (a.addr.isDraft !== b.addr.isDraft) return a.addr.isDraft === true && b.addr.isDraft !== true ? -1 : 1;
      return 0 - compareDates(a.date, b.date);
    });

    let participants = { to: [], cc: [] };

    if (fetchingEmails === false && this.state.companyEmail) {
      let textEntries = entries.filter(entry => {
        return this.isText(entry.addr.type);
      });

      let first = find(textEntries, entry => {
        return (
          ((entry.data.from || {})[0] || {}).address !== this.state.companyEmail.address && entry.addr.type === "email"
        );
      });
      if (isEmpty(first) !== true) {
        participants.to = [...(first.data.from || [])];
        participants.cc = [...(first.data.to || []), ...(first.data.cc || [])].filter(email => {
          return (
            email.address !== this.state.companyEmail.address &&
            email.address !== this.state.companyInfo.connectorEmail.remote[perspectiveId]
          );
        });
      } else {
        let toEntries = textEntries.filter(entry => {
          return (entry.data.to || []).some(email => {
            return isEmpty(this.state.displayAddresses[email.address]);
          });
        });
        if (isEmpty(toEntries) === false) {
          let last = toEntries[toEntries.length - 1];
          // remove the synthetic addresses and take the first to entry as the original target address for this conversation
          let cto = last.data.to.filter(email => {
            return isEmpty(this.state.displayAddresses[email.address]);
          });
          participants.to = [cto[0]];
          cto.shift();
          participants.cc = [...cto, ...(last.data.cc || [])].filter(email => {
            return (
              email.address !== this.state.companyEmail.address &&
              email.address !== this.state.companyInfo.connectorEmail.remote[perspectiveId]
            );
          });
        }
      }
    }

    delete relatedConversations[conversation.conversationId];
    let relatedArray = Object.keys(relatedConversations).map(k => {
      return relatedConversations[k];
    });
    relatedArray.sort((a, b) => {
      return 0 - compareDates(a.modifiedDate, b.modifiedDate);
    });

    let participantNames = [];
    if (conversation.new !== true) {
      conversation.participantIds.forEach(id => {
        if (id !== this.props.companyId && isEmpty(partners[id]) === false)
          participantNames.push(partners[id].companyName);
      });
      participantNames.sort((a, b) => {
        return compareStrings(a, b);
      });
    }

    if (isGuid(sendToAccountId) && conversationId === "new") {
      let addressToPush;
      if (params.subView === "accounts" || !isEmpty(sendToAccountId)) {
        addressToPush = this.props.getAccountProfile(perspectiveId, sendToAccountId).emailAddr;
      } else {
        addressToPush = this.props.getContactProfile(perspectiveId, sendToContactId).emailAddr;
      }
      participants.to.push({ address: addressToPush });
    }

    const { connectorId } = this.props.getRegisteredERPConnectors(this.props.companyId)[0] || {};

    const companyPerspective = this.props.getCompanyPerspectiveById(perspectiveId, this.props.companyId);
    // Note, this assumes that we are only worried about actions for resources that are perspective specific. We are not grabbing resources that exist in multiple perspectives such as email.
    const perspectiveResourceIds = this.props.ledgerStore.resources
      .filter(resource => {
        return (
          isEmpty(resource.perspectiveId) || resource.perspectiveId === (companyPerspective || {}).parentPerspectiveId
        );
      })
      .map(resource => {
        return resource.resourceId;
      });

    let connectorActions = this.props.getConnectorActionsByResourceIds(perspectiveResourceIds, connectorId);

    let resourceActions = [];
    for (let index = 0; index < entries.length; index++) {
      let entry = entries[index];
      if (!isEmpty(entry.entry) && perspectiveResourceIds.indexOf(entry.entry.resourceId) > -1) {
        let ie = entry.entry;
        resourceActions.push({
          actionTitle: Resources.GoToResource(entry.type),
          actionLinkTemplate: ie.link
        });
      }
    }

    let actions = [];
    if (resourceActions.length > 0) {
      connectorActions = connectorActions.filter(cAction => {
        return cAction.actionId === "1299a32e-8735-43ea-8680-991c3dde894e";
      });
      actions = [...resourceActions, ...connectorActions];
    } else {
      actions = [
        ...connectorActions.filter(cAction => {
          return cAction.actionId === null;
        })
      ];
    }

    const rootResourceName = (
      (
        find(this.props.ledgerStore.resources, r => {
          return r.resourceId === (conversation.rootEntry || {}).resourceId;
        }) || {}
      ).resourceName || ""
    ).toLowerCase();

    let invoice = (
      (
        find(entries, e => {
          return e.type === "invoice" && !isEmpty(e.entry);
        }) || {}
      ).entry || {}
    ).asJson;
    let invoiceNum;
    let issueDate;
    let dueDate;
    let amount;
    if (
      this.isInvoice(rootResourceName) &&
      isEmpty(conversation.rootEntry.asJson) === false &&
      isEmpty(JSON.parse(conversation.rootEntry.metadata).ConnectorVersionId)
    ) {
      ({ invoiceNum, issueDate, dueDate, amount } = formatInvoice(conversation.rootEntry.asJson));
      conversation.subject = [invoiceNum, Resources.IssueDate(issueDate), Resources.DueDate(dueDate), amount].join(
        "  |  "
      );
    }

    // Keys are put in for IE11 support only... will not render correctly without seperate keys
    let relatedDiv =
      fetchingRelated === true ? (
        <div key="a" className={Icons.spinner} />
      ) : (
        relatedArray.length === 0 && <div key="b">{Resources.NoRelatedConversationsFound}</div>
      );

    let isTask = this.props.conversation.activityType === "task" || rootResourceName === "task";

    const isApprovalTask =
      isTask &&
      !isEmpty(conversation.rootEntry) &&
      (conversation.rootEntry.asJson.type || "").toLowerCase() === "approvalrequest";

    const isResponseTask =
      isTask &&
      !isEmpty(conversation.rootEntry) &&
      (conversation.rootEntry.asJson.type || "").toLowerCase() === "requestresponse";

    return (
      <div className="flex">
        <div className="conversation-contents full-height-minus-header">
          {conversation.new && (
            <ConversationEditor
              participants={participants}
              mode={this.state.editorMode}
              updateEditorMode={this.updateEditorMode}
              participantNames={participantNames}
              conversation={conversation}
              companyId={this.props.companyId}
              perspective={this.props.perspective}
              perspectiveId={this.props.perspectiveId}
              draft={draft}
              onDraftSaved={draftId => {
                let ignoredDrafts = { ...this.state.ignoredDrafts };
                if (this.state.draftId && this.state.draftId !== draftId) {
                  ignoredDrafts[this.state.draftId] = true;
                }
                this.setState({ draftId, ignoredDrafts });
              }}
              showAsText={this.state.showAsText}
              companyInfo={this.state.companyInfo}
              companyEmail={this.state.companyEmail}
              withCompanies={this.state.withCompanies}
              withPerspective={this.state.withPerspective}
              displayAddress={this.state.displayAddresses}
              handleShowAsText={() => this.setState({ showAsText: true })}
              handleShowAsHTML={() => this.setState({ showAsText: false })}
              // onShowAsTextToggled={() => { this.setState({ showAsText: !this.state.showAsText }) }}
              onClosed={() => {
                this.setState({ draftId: null });
                this.props.onClosed && this.props.onClosed();
              }}
              onSubmitComplete={() => this.props.onSubmitComplete && this.props.onSubmitComplete()}
            />
          )}
          {conversation.new !== true && (
            <React.Fragment>
              <div style={{ marginBottom: "2.1rem" }}>
                <ConversationActionButtons
                  actionComplete={() => {
                    this.setState({ selectedRows: [], selectedKeys: [] });
                    this.props.refreshContextGroups(
                      this.props.match.params.companyId,
                      this.props.match.params.perspectiveId || perspectiveId
                    );
                    this.props.history.goBack();
                  }}
                  selectedRows={[conversation]}
                  selectedKeys={[conversation.conversationId]}
                  updateEditorMode={this.updateEditorMode}
                  perspectiveId={perspectiveId}
                />
              </div>
              <Card type="conversation">
                <ConversationEditor
                  participants={participants}
                  mode={this.state.editorMode}
                  updateEditorMode={this.updateEditorMode}
                  participantNames={participantNames}
                  conversation={conversation}
                  companyId={this.props.companyId}
                  perspective={this.props.perspective}
                  perspectiveId={this.props.perspectiveId}
                  draft={draft}
                  onDraftSaved={draftId => {
                    let ignoredDrafts = { ...this.state.ignoredDrafts };
                    if (this.state.draftId && this.state.draftId !== draftId) {
                      ignoredDrafts[this.state.draftId] = true;
                    }
                    this.setState({ draftId, ignoredDrafts });
                  }}
                  showAsText={this.state.showAsText}
                  companyInfo={this.state.companyInfo}
                  companyEmail={this.state.companyEmail}
                  withCompanies={this.state.withCompanies}
                  withPerspective={this.state.withPerspective}
                  displayAddress={this.state.displayAddresses}
                  handleShowAsText={() => this.setState({ showAsText: true })}
                  handleShowAsHTML={() => this.setState({ showAsText: false })}
                  // onShowAsTextToggled={() => { this.setState({ showAsText: !this.state.showAsText }) }}
                  onClosed={() => {
                    this.setState({ draftId: null });
                  }}
                />
                {entries.map((item, index) => {
                  let { entry, addr, data, date, attachments } = item;
                  let dateText = formatDate(date);
                  let statusName;
                  let assignedTo;
                  // use this in case you need to see the data in the UI instead of the spinner
                  //let value = <div dangerouslySetInnerHTML={{ __html: data }} />
                  if (isEmpty(this.props.conversations.gettingConversations) && entry) {
                    if (!this.isInvoice(addr.type) && !this.isText(addr.type)) {
                      return null;
                    }
                    if (isEmpty(addr.connectorId) === false) {
                      return null;
                    }
                    if (item.hideDraft) {
                      return null;
                    }

                    let posterName = getUserDisplayName(entry.postingUserId, Resources.You);
                    assignedTo = getUserDisplayName(addr.assignedUserId, Resources.You);
                    statusName = item.status.conversationStatusName;

                    const postingUser = this.props.getUser(entry.postingUserId);

                    const approverName =
                      posterName || ((data.from || [])[0] || {}).name || ((data.from || [])[0] || {}).address;

                    if (data.htmlBody === "Html body not available.") {
                      data.htmlBody = null;
                    }
                    if (data.textBody === "Text body not available.") {
                      data.textBody = null;
                    }

                    let emailBody = data.htmlBody;
                    let originalEmailBody = data.originalHtmlBody;

                    if (isEmpty(emailBody) || (this.state.showAsText && data.textBody !== null)) {
                      emailBody = (data.textBody || "").split("\n").join("<br />");
                    }
                    if (isEmpty(originalEmailBody) || (this.state.showAsText && data.originalTextBody !== null)) {
                      originalEmailBody = (data.originalTextBody || "").split("\n").join("<br />");
                    }

                    if (item.isForward === true || item.isSystemMessage === true) {
                      emailBody = null;
                    }

                    const isFirstEntry = index === entries.length - 1;
                    const props = {
                      location: this.props.location,
                      history: this.props.history,
                      params,
                      posterName,
                      addr,
                      emailBody,
                      originalEmailBody,
                      attachments,
                      isFirstEntry,
                      assignedTo,
                      assignedUserId: addr.assignedUserId,
                      statusName,
                      dateText,
                      key: addr.conversationAddressId,
                      perspectiveId,
                      perspective: perspectiveId,
                      data,
                      approverName,
                      entry,
                      showAsText: this.state.showAsText,
                      isApprovalTask,
                      isResponseTask,
                      conversation: this.props.conversation,
                      conversationId: this.props.conversationId,
                      companyId: this.props.companyId,
                      postingUser
                    };

                    if (!isEmpty(data.to)) {
                      props.to = this.formatEmails("to", Resources.EmailTo, data.to);
                    }
                    if (!isEmpty(data.from)) {
                      props.from = this.formatEmails("from", Resources.EmailFrom, data.from);
                    }
                    if (!isEmpty(data.cc)) {
                      props.cc = this.formatEmails("cc", Resources.EmailCC, data.cc);
                    }
                    if (!isEmpty(data.bcc)) {
                      props.bcc = this.formatEmails("bcc", Resources.EmailBCC, data.bcc);
                    }

                    // Drafts of any type are rendered the same way
                    if (addr.isDraft) {
                      const hideDraft =
                        (this.state.draftId && this.state.draftId === addr.address) ||
                        this.state.ignoredDrafts[addr.address] === true;
                      if (hideDraft) {
                        return null;
                      }
                      return (
                        <DraftThreadItem
                          {...props}
                          handleEdit={() => {
                            this.setState({ draftId: addr.address });
                          }}
                          handleDelete={() => {
                            this.props.deleteDraft(addr.address);
                          }}
                        />
                      );
                    }

                    if (
                      includes(["forward", "email", "approvalrequest", "requestresponse"], addr.type) &&
                      (entry.asJson || {}).hasOwnProperty("originalHtmlBody")
                    ) {
                      const { originalHtmlBody, originalTextBody, subject, originalSubject } = entry.asJson;

                      const isOutgoing = includes(
                        (data.from || []).map(addr => addr.address),
                        ((this.state.companyInfo.connectorEmail || {}).synthetic || {})[this.props.perspective]
                      );

                      props.viewOriginal = (
                        <React.Fragment>
                          <span className={Icons.emailSettings + (!isEmpty(posterName) ? " ml-3" : "") + " mr-1"} />
                          <span
                            className="forward-color clickable"
                            onClick={() =>
                              this.props.displayModal("originalEmailModal", {
                                sendDate: entry.dateCreated,
                                originalHtmlBody,
                                originalTextBody,
                                subject: originalSubject || subject,
                                to: props.to,
                                from: props.from,
                                cc: props.cc,
                                bcc: props.bcc
                              })
                            }
                          >
                            {isOutgoing ? Resources.ViewSent : Resources.ViewReceived}
                          </span>
                        </React.Fragment>
                      );
                    }
                    props.resource = this.props.getResource("resourceId", props.entry.resourceId);

                    switch (addr.type) {
                      case "invoice":
                        return <InvoiceThreadItem {...props} />;
                      case "response":
                        if (!isEmpty(entry.asJson.forwardHash)) {
                          return (
                            <SimpleForwardResponseThreadItem
                              {...props}
                              scrollToThreadItem={() => {
                                this[entry.asJson.messageId].scrollIntoView();
                              }}
                            />
                          );
                        }
                        return <EmailThreadItem {...props} />;
                      case "email":
                        if (addr.actionName === "forward") {
                          props.setRef = this.setRef.bind(this);
                          return <SimpleForwardThreadItem {...props} item={item} />;
                        }
                        return <EmailThreadItem {...props} item={item} />;
                      case "approvalrequest":
                        props.recipient = ((data.to || [])[0] || {}).name || ((data.to || [])[0] || {}).address;
                        return <ApprovalRequestThreadItem {...props} showCancel={index === 0} />;
                      case "approval":
                        return <ApprovalThreadItem {...props} />;
                      case "rejection":
                        return <RejectionThreadItem {...props} />;
                      case "forward":
                        return <SimpleForwardThreadItem {...props} item={item} />;
                      case "note":
                        return <EmailThreadItem {...props} itemType="note" />;
                      //return <NoteThreadItem {...props} />;
                      case "statuschange":
                        return <StatusChangeThreadItem {...props} />;
                      case "assignmentchange":
                        return <AssignmentThreadItem isTask={isTask} {...props} />;
                      case "task":
                        return <TaskThreadItem {...props} />;
                      case "reminder":
                        return (
                          <ReminderThreadItem
                            {...props}
                            item={item}
                            companyId={this.props.match.params.companyId}
                            showCancel={index === 0}
                          />
                        );
                      case "requestresponse":
                        props.requestee = data.to[0].name || data.to[0].address;
                        return <RequestResponseThreadItem {...props} showCancel={index === 0} />;
                      default:
                        break;
                    }
                  }

                  return (
                    <div className="conversation-thread-item" key={addr.conversationAddressId}>
                      <div key={`l${index}${addr.conversationAddressId}`}>
                        <span>{Resources.Loading} </span>
                        <span className={Icons.spinner} />
                      </div>
                    </div>
                  );
                })}
                {
                  // <div>
                  //   {true && (
                  //     <React.Fragment>
                  //       <span className="email-header-label">{Resources.EmailTo}:</span>
                  //       <EmailAddressBar
                  //         perspectiveId={perspective}
                  //         type="to"
                  //         participants={this.state.entryTo}
                  //         modalOpen={false}
                  //         startAdding={true}
                  //         contacts={this.props.getCompanyAddressBook(params.companyId)}
                  //         onChanged={to => {
                  //           this.toChanged(to);
                  //         }}
                  //       />
                  //     </React.Fragment>
                  //   )}
                  //   <HtmlEditor
                  //     onFocus={() => this.setState({ focusedField: "message" })}
                  //     onBlur={e => this.setState({ focusedField: null })}
                  //     hideToolbar={this.state.focusedField !== "message"}
                  //     htmlContent={this.state.entryHtmlBody}
                  //     updateHtmlContent={htmlBody => this.setState({ entryHtmlBody: htmlBody })}
                  //     updateTextContent={textBody => this.setState({ entryTextBody: textBody })}
                  //   />
                  // </div>
                }
              </Card>
            </React.Fragment>
          )}
        </div>
        {!conversation.new && connectorStore.companyHasERPConnector && (
          <div className="conversation-side-panel full-height-minus-header">
            <h5>{participantNames.join(", ")}</h5>
            <FlexibleScrollContainer initialHeight={300}>
              <CompanyProfile
                companyId={conversation.withCompanyId}
                perspective={this.props.perspective}
                isSidePanel={true}
                actions={actions}
                invoice={invoice}
                erpInvoiceEntry={conversation.conversationAddresses[1] || null}
              />

              <div>{Resources.RelatedConversations.toLocaleUpperCase()}</div>
              {relatedDiv}
              {relatedArray.map(r => {
                return (
                  <div
                    className="related-conversation"
                    key={r.conversationId}
                    onClick={() => {
                      this.props.history.push(`${companyRootUrl}/${r.contextGroupIds[0]}/${r.conversationId}`);
                    }}
                  >
                    <span className="related-subject">{r.subject}</span>
                    <span className="related-ago">{moment(r.modifiedDate).fromNow()}</span>
                  </div>
                );
              })}
            </FlexibleScrollContainer>
          </div>
        )}
      </div>
    );
  }
}

const storeToProps = store => {
  return {
    companies: store.companies,
    user: store.user,
    ledgerStore: store.ledger,
    conversations: store.conversations,
    conversation: store.conversations.conversation,
    connectorStore: store.connector,
    generalStore: store.general,
    manage: store.manage
  };
};

export default withRouter(connect(storeToProps, dispatchToProps)(Conversation));
