import React from "react";
import { components } from 'react-select';
import AsyncCreatableSelect from 'react-select/async-creatable';
import Avatar from "../library/avatar";

const ContactEmailSelect = ({ loadOptions, onChange, label }) => {
  const [isActive, setIsActive] = React.useState(false)

  return (
    <div className="contact-email-select">
      <div className={`input-label email-select-label ${isActive ? 'email-select__label--is-active' : ''}`}>
        {label}
      </div>

      <AsyncCreatableSelect
        isMulti
        defaultOptions
        cacheOptions
        allowCreateWhileLoading
        className="email-select-container"
        classNamePrefix="email-select"
        closeMenuOnSelect={false}
        isClearable={false}
        loadOptions={loadOptions}
        onFocus={() => setIsActive(true)}
        onBlur={() => setIsActive(false)}
        getNewOptionData={(_, label) => ({ value: label, label })}
        onChange={selected => {
          const emails = Array.isArray(selected) ? selected.map(s => ({ address: s.value, name: s.label })) : []
          onChange(emails)
        }}
        components={{
          Option: props => (
            <components.Option {...props}>
              <div className="email-select__option__container">
                <Avatar imgURL={props.data.profilePictureURL} objectName={props.data.name} />
                <div className="email-select__option__user-info">
                  <div>{props.data.name}</div>
                  <div>{props.label}</div>
                </div>
              </div>
            </components.Option>
          )
        }}
      />
    </div>
  )
}

export default ContactEmailSelect
