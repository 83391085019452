import React, { Component } from "react";
import { connect } from "react-redux";

import { dispatchToProps } from "../store/user-actions";
import Resources from "../lib/resources";
import MenuItem from "./menuItem";
import Icons from "../lib/icons";

class ReportsNavContent extends Component {
  getRoute(view) {
    const { params, url } = this.props.match;
    return params.view ? url.replace(params.view || "", view) : `${url}/${view}`;
  }

  render() {
    const { view } = this.props.match.params;
    // const isAdmin = this.props.isCompanyAdmin() !== false
    return (
      <div>
        <MenuItem
          to={this.getRoute("team")}
          iconClasses={Icons.userFriends}
          label={Resources.Team}
          active={view === "team"}
        />
        <MenuItem
          to={this.getRoute("tasks")}
          iconClasses={Icons.clipboardList}
          label={Resources.Tasks}
          active={view === "tasks"}
        />
        <MenuItem
          to={this.getRoute("approvers")}
          iconClasses={Icons.clipboardCheck}
          label={Resources.Approvers}
          active={view === "approvers"}
        />
        <MenuItem
          to={this.getRoute("approval-aging")}
          iconClasses={Icons.clock}
          label={Resources.ApprovalAging}
          active={view === "approval-aging"}
        />
      </div>
    );
  }
}

const storeToProps = store => {
  return {
    accountsStore: store.accounts
  };
};

export default connect(
  storeToProps,
  dispatchToProps
)(ReportsNavContent);
