import React, { Component } from "react";

class IconMissing extends Component {
  render() {
    let { className, height } = this.props;
    return (
      <svg height={height} className={`icon ${className ? className : ""}`} viewBox="0 0 22 28">
        <defs />
        <g fill="none" fillRule="evenodd">
          <path fill="#FFF" d="M-380-1070h1440V449H-380z" />
          <path fill="#FFF" d="M-56-995h1116V632H-56z" />
          <rect
            width="963"
            height="91"
            x=".5"
            y=".5"
            fill="#FFF"
            stroke="currentColor"
            rx="2"
            transform="translate(-24 -32)"
          />
          <g stroke="#3E5969" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
            <g transform="translate(1 4)">
              <circle cx="8.94736842" cy="8.94736842" r="8.94736842" />
              <path d="M20 20l-4.5789474-4.5789474" />
            </g>
            <path d="M8 10.45800156c.32362458-1.00360405 1.26358908-1.60624995 2.22478421-1.4263912.96119512.17985875 1.66333536 1.08977514 1.66188471 2.15366393 0 1.45454545-2.00000225 2.18181818-2.00000225 2.18181818M9.94 16.27618338h.00666667" />
          </g>
        </g>
      </svg>
    );
  }
}

IconMissing.defaultProps = {
  height: 22
};

export default IconMissing;
