import React, { Component } from "react";
import { connect } from "react-redux";

import { dispatchToProps } from "../../store/modal-actions";
import Resources from "../../lib/resources";

import IconSpinner from "../library/icons/iconSpinner";
import IconCheck from "../library/icons/iconCheck";
import IconAlertCircle from "../library/icons/iconAlertCircle";

class UploadNotification extends Component {
  constructor(props) {
    super(props);

    this.state = {
      address: "",
      isValidAddress: false
    };
  }

  componentDidUpdate(prevProps) {
    if (
      (this.props.uploadedDocument === true && prevProps.uploadedDocument === false) ||
      (this.props.uploadDocumentFailed === true && prevProps.uploadDocumentFailed === false)
    ) {
      setTimeout(this.props.hideNotification, 5000);
    }
  }

  render() {
    let { uploadingDocument, uploadedDocument, uploadDocumentFailed } = this.props;

    let content = "TEST";
    if (uploadingDocument) {
      content = (
        <React.Fragment>
          <IconSpinner height={20} className="notification-icon-left" />
          <div>{Resources.DocumentUploading}</div>
        </React.Fragment>
      );
    }
    if (uploadedDocument) {
      content = (
        <React.Fragment>
          <IconCheck height={18} className="notification-icon-left"></IconCheck>
          <div>{Resources.DocumentUploaded}</div>
        </React.Fragment>
      );
    }
    if (uploadDocumentFailed) {
      content = (
        <React.Fragment>
          <IconAlertCircle height={18} className="notification-icon-left"></IconAlertCircle>
          <div>{Resources.DocumentFailedToUpload}</div>
        </React.Fragment>
      );
    }
    return <div className="notification">{content}</div>;
  }
}

const storeToProps = store => {
  return {
    uploadingDocument: store.ledger.uploadingDocument,
    uploadedDocument: store.ledger.uploadedDocument,
    uploadDocumentFailed: store.ledger.uploadDocumentFailed
  };
};

export default connect(
  storeToProps,
  dispatchToProps
)(UploadNotification);
