import React, { Component } from "react";

class DropdownItem extends Component {
  render() {
    let { children, onClick, disabled } = this.props;
    return (
      <button className={"dropdown-item" + (disabled ? " disabled" : "")} onClick={onClick}>
        {children}
      </button>
    );
  }
}

export default DropdownItem;
