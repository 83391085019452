import data from "../lib/data";
import _ from "lodash";
import { debounce, isEmpty } from "../lib/utils";
import { actions as GeneralActions } from "./general-actions";
import { handlePromiseError } from "./error-actions";

export const actions = {
  ...GeneralActions,
  ...{
    UPDATE_SEARCH_TERM: "UPDATE_SEARCH_TERM",

    FETCHING_SEARCH_RESULTS: "FETCHING_SEARCH_RESULTS",
    FETCHED_SEARCH_RESULTS: "FETCHED_SEARCH_RESULTS",
    FETCH_SEARCH_RESULTS_FAILED: "FETCH_SEARCH_RESULTS_FAILED",

    GETTING_GLOBAL_SEARCH_RESULTS: "GETTING_GLOBAL_SEARCH_RESULTS",
    GOT_GLOBAL_SEARCH_RESULTS: "GOT_GLOBAL_SEARCH_RESULTS",
    FAILED_GET_GLOBAL_SEARCH_RESULTS: "FAILED_GET_GLOBAL_SEARCH_RESULTS",

    PUSH_SEARCH_HISTORY_ITEM: "PUSH_SEARCH_HISTORY_ITEM",

    CLEAR_SEARCH_RESULTS: "CLEAR_SEARCH_RESULTS"
  }
};

const debouncedSearchResultsGet = debounce((dispatch, companyId, request) => {
  let endpoint = `v1/api/search/${companyId}/conversations`;
  if (isEmpty(request.request)) {
    return dispatch({ type: actions.CLEAR_SEARCH_RESULTS });
  }

  data
    .post(endpoint, request)
    .then(response => {
      dispatch({
        type: actions.FETCHED_SEARCH_RESULTS,
        searchResults: response.data.value,
        request
      });
    })
    .catch(rejection => {
      dispatch({ type: actions.FETCH_SEARCH_RESULTS_FAILED });
      handlePromiseError(rejection, "TODO: Getting search results failed. Please try again.", "search results");
    });
}, 400);

const debouncedFetchSearchResults = (companyId, request) => (dispatch, getState) => {
  const store = getState().search;
  if (store.isFetchingSearchResults && _.isEqual(request, store.searchQuery)) {
    return;
  }

  dispatch({ type: actions.FETCHING_SEARCH_RESULTS, request });
  debouncedSearchResultsGet(dispatch, companyId, request);
};

const fetchSearchResults = (companyId, request) => (dispatch, getState) => {
  const store = getState().search;
  if (store.isFetchingSearchResults && _.isEqual(request, store.searchQuery)) {
    return;
  }

  dispatch({ type: actions.FETCHING_SEARCH_RESULTS, request });
  data
    .post(`v1/api/search/${companyId}/conversations`, request)
    .then(response => {
      dispatch({
        type: actions.FETCHED_SEARCH_RESULTS,
        searchResults: response.data.value,
        request
      });
    })
    .catch(rejection => {
      dispatch({ type: actions.FETCH_SEARCH_RESULTS_FAILED });
      handlePromiseError(rejection, "TODO: Getting search results failed. Please try again.", "search results");
    });
};

const debouncedAccountingGroupSearchResultsGet = debounce(
  (dispatch, accountId, companyId, accountingGroupId, request, top, skip) => {
    let endpoint = `v3/api/search/${accountId}/${accountingGroupId}/${companyId}?$top=${top}&$skip=${skip}`;
    if (isEmpty(request.request)) {
      return dispatch({ type: actions.CLEAR_SEARCH_RESULTS });
    }

    data
      .post(endpoint, request)
      .then(response => {
        dispatch({
          type: actions.GOT_GLOBAL_SEARCH_RESULTS,
          searchResults: response.data,
          request
        });
      })
      .catch(rejection => {
        dispatch({ type: actions.FAILED_GET_GLOBAL_SEARCH_RESULTS });
        handlePromiseError(
          rejection,
          "TODO: Getting global search results failed. Please try again.",
          "search results"
        );
      });
  },
  400
);

const debouncedFetchAccountingGroupSearchResults = (
  accountId,
  companyId,
  accountingGroupId,
  request,
  top = 20,
  skip = 0
) => (dispatch, getState) => {
  const store = getState().search;
  if (store.gettingSearchResults && _.isEqual(request.request, store.searchTermFetching)) {
    return;
  }

  dispatch({ type: actions.GETTING_GLOBAL_SEARCH_RESULTS, request, skip });
  debouncedAccountingGroupSearchResultsGet(dispatch, accountId, companyId, accountingGroupId, request, top, skip);
};

export const searchContacts = (accountId, companyId, accountingGroupId, request, nextLink, top = 20, skip = 0) =>
  data.post(
    nextLink || `v3/api/search/${accountId}/${accountingGroupId}/${companyId}?$top=${top}&$skip=${skip}`,
    request
  );

// accountId and companyId are the same thing (currently)
const fetchAccountingGroupSearchResults = (
  accountId,
  companyId,
  accountingGroupId,
  request,
  top = 20,
  skip = 0,
  fetchNextLabel
) => (dispatch, getState) => {
  const store = getState().search;
  if (store.gettingSearchResults && _.isEqual(request.request, store.searchTermFetching)) {
    return;
  }

  dispatch({ type: actions.GETTING_GLOBAL_SEARCH_RESULTS, request, skip, fetchNextLabel });
  const nextLink = !isEmpty(fetchNextLabel) && store.nextLinks[fetchNextLabel];

  searchContacts(accountId, companyId, accountingGroupId, request, nextLink, top, skip)
    .then(response => {
      dispatch({
        type: actions.GOT_GLOBAL_SEARCH_RESULTS,
        searchResults: response.data,
        request,
        fetchNextLabel
      });
    })
    .catch(rejection => {
      dispatch({ type: actions.FAILED_GET_GLOBAL_SEARCH_RESULTS });
      handlePromiseError(rejection, "TODO: Getting global search results failed. Please try again.", "search results");
    });
};

export const dispatchToProps = dispatch => ({
  updateSearchTerm: searchTerm => {
    dispatch({ type: actions.UPDATE_SEARCH_TERM, searchTerm });
  },
  debouncedFetchSearchResults: (companyId, request) => {
    dispatch(debouncedFetchSearchResults(companyId, request));
  },
  fetchSearchResults: (companyId, request) => {
    dispatch(fetchSearchResults(companyId, request));
  },

  debouncedFetchAccountingGroupSearchResults: (accountId, companyId, accountingGroupId, request) => {
    dispatch(debouncedFetchAccountingGroupSearchResults(accountId, companyId, accountingGroupId, request));
  },
  fetchAccountingGroupSearchResults: (accountId, companyId, accountingGroupId, request, top, skip, fetchNextLabel) => {
    dispatch(
      fetchAccountingGroupSearchResults(accountId, companyId, accountingGroupId, request, top, skip, fetchNextLabel)
    );
  },

  pushSearchHistoryItem: searchTerm => {
    dispatch({ type: actions.PUSH_SEARCH_HISTORY_ITEM, searchTerm });
  },

  clearSearchResults: () => {
    dispatch({ type: actions.CLEAR_SEARCH_RESULTS });
  }
});
