import React, { Component } from "react";

class IconClipboard extends Component {
  render() {
    let { className, height } = this.props;

    return (
      <svg
        height={height}
        className={`icon ${className ? className : ""}`}
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        viewBox="0 0 24 24"
      >
        <path d="M16 4h2a2 2 0 012 2v14a2 2 0 01-2 2H6a2 2 0 01-2-2V6a2 2 0 012-2h2" />
        <rect width="8" height="4" x="8" y="2" rx="1" ry="1" />
      </svg>
    );
  }
}

IconClipboard.defaultProps = {
  height: 24
};

export default IconClipboard;
