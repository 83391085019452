import React, { Component } from "react";
import { getPartnerType } from "../lib/perspectives";
import { connect } from "react-redux";
import { dispatchToProps } from "../store/perspectives-actions";
import Resources from "../lib/resources";
import Icons from "../lib/icons";

class PerspectiveTabs extends Component {
  render() {
    const { handleSelect, selectedPerspective, handleAddWorkspace } = this.props;
    const companyPerspectives = this.props.getCompanyPerspectives(this.props.companyId) || [];

    const addWorkspaceActive = selectedPerspective === "new";

    return (
      <React.Fragment>
        <ul className="perspective-tabs nav nav-tabs">
          {companyPerspectives.map((perspective, i) => {
            return (
              <li key={i} className={`perspective-tabs__item nav-item ${i === 0 ? "first" : ""}`}>
                <div
                  onClick={() => handleSelect(perspective)}
                  className={(selectedPerspective === perspective ? "active" : "") + " nav-link clickable"}
                >
                  {getPartnerType(perspective.perspectiveName, true)}
                </div>
              </li>
            );
          })}
          {handleAddWorkspace !== null && (
            <li className="perspective-tabs__item nav-item">
              <div
                onClick={() => handleAddWorkspace()}
                className={(addWorkspaceActive ? "active" : "") + " nav-link clickable"}
              >
                <span className={Icons.plus + " mr-1" + (addWorkspaceActive ? "" : " c-gray-font")} />
                {Resources.AddWorkspace}
              </div>
            </li>
          )}
        </ul>
      </React.Fragment>
    );
  }
}

const storeToProps = store => {
  return {
    companyPerspectives: store.perspectives.companyPerspectives
  };
};

PerspectiveTabs.defaultProps = {
  handleAddWorkspace: null
};

export default connect(
  storeToProps,
  dispatchToProps
)(PerspectiveTabs);
