import React, { useEffect } from "react";
import moment from "moment";

import Resources from "../../lib/resources";
import { isEmpty, includes, createKey } from "../../lib/utils";
import { isInt } from "../../lib/validation";

import SelectInput from "./selectInput";
import TextInput from "./textInput";
import DatePicker from "react-datepicker";

import IconPlusCircle from "./icons/iconPlusCircle";
import IconTrash from "./icons/iconTrash";

export default function FilterBuilder(props) {
  const {
    addFilter = () => null,
    deleteFilter = i => null,
    updateFilter = () => null,
    updateFilterString = () => null,
    clearFilters = () => null,
    filters = [],
    filterOptions,
    onDoneClick = () => null
  } = props;

  useEffect(() => {
    updateFilterString(generateFilterString(filters));
  }, [updateFilterString, filters]);

  return (
    <div className="filter-builder">
      <div>
        {filters.map((filter, i) => {
          return (
            <div key={i}>
              <FilterItem
                filter={filter}
                i={i}
                filterOptions={filterOptions}
                updateFilter={updateFilter}
                deleteFilter={deleteFilter}
              />
            </div>
          );
        })}
        <button
          className={`button-secondary filter-builder__add-new`}
          onClick={() => {
            addFilter({ field: "", comparator: "", value: "", editing: true });
          }}
        >
          <IconPlusCircle height={18} />
          <span style={{ paddingLeft: "0.3rem" }}>{Resources.AddFilter}</span>
        </button>
        <div className="filter-builder__actions">
          <button
            className="button-secondary-square"
            closeDropdownOnClick="true"
            onClick={() => {
              clearFilters();
            }}
          >
            {Resources.ClearAll}
          </button>
          <button className="button-primary-square" onClick={onDoneClick} closeDropdownOnClick="true">
            {Resources.Done}
          </button>
        </div>
      </div>
    </div>
  );
}

function generateFilterString(filters = []) {
  let filterString = "";
  let addedFirstVariable = false;
  filters.forEach((filter, i) => {
    if (isEmpty(filter.selectedOption) || isEmpty(filter.selectedOption.optionKey) || isEmpty(filter.value)) {
      return;
    }
    if (addedFirstVariable) {
      filterString += "%20AND%20";
    }
    switch (filter.filterValueType) {
      case "date":
        let equalToDate = moment(filter.value)
          .startOf("day")
          .utc();
        const startingDateTimeString = equalToDate.format("YYYY-MM-DD kk:mm:ss.SSS");
        const endingDateTimeString = equalToDate.add(1, "day").format("YYYY-MM-DD kk:mm:ss.SSS");
        filterString += `(${filter.filterKey}%20>=%20'${startingDateTimeString}'%20AND%20${filter.filterKey}%20<%20'${endingDateTimeString}')`;
        break;
      case "address":
        let addressString = "";
        filter.addressFilterKeys.forEach((addressFilterKey, i) => {
          if (i > 0) {
            addressString += "%20OR%20";
          }
          addressString += addressFilterKey + "%20";
          addressString += filter.selectedOption.optionKey + "%20";
          addressString += filter.value;
        });
        addressString = `(${addressString})`;
        filterString += addressString;
        break;
      case "string":
      default:
        filterString += filter.filterKey + "%20";
        filterString += filter.selectedOption.optionKey + "%20";
        filterString += filter.value;
        break;
    }
  });
  return filterString;
}

function FilterItem(props) {
  const { updateFilter = (i, filter) => null, deleteFilter = () => null, filterOptions = [], filter, i } = props;

  let content;
  let comparatorItems = filter.filterCriteriaOptions || [];
  const hideValueInput =
    isEmpty(filter.selectedOption) || includes(["IsNotNull", "IsNull"], (filter.selectedOption || {}).optionKey);

  let input = (
    <TextInput
      style={{ marginBottom: 0 }}
      width="20rem"
      textValue={filter.value}
      inputOnChange={e => {
        let newValue;
        switch (filter.filterValueType) {
          case "int":
            newValue = isInt(e.target.value) ? e.target.value : filter.value;
            break;
          case "string":
          default:
            newValue = e.target.value;
            break;
        }
        updateFilter(i, { ...filter, value: newValue });
      }}
    />
  );

  if (filter.filterValueType === "date") {
    input = (
      <DatePicker
        className="date-picker-input"
        calendarClassName="date-picker-calendar"
        dayClassName={d => "date-picker-day"}
        maxDate={new Date()}
        dateFormat="yyyy-MM-dd"
        selected={filter.value ? moment(filter.value).toDate() : new Date()}
        onChange={d => {
          updateFilter(i, { ...filter, value: moment(d).format("YYYY-MM-DD") });
        }}
      />
    );
  }

  content = (
    <div key={createKey(filter.filterKey, i)}>
      <div className="customer-group-dynamic-filter-item-editing-content">
        <SelectInput
          className="field"
          value={filter.filterKey}
          displayValue={filter.filterDescription}
          optionValueKey="filterKey"
          optionDisplayKey="filterDescription"
          options={filterOptions}
          onSelectOption={option => {
            updateFilter(i, {
              ...filter,
              ...option
            });
          }}
        />
        <SelectInput
          className="comparator"
          value={(filter.selectedOption || {}).optionKey}
          displayValue={(filter.selectedOption || {}).optionDescription}
          options={comparatorItems}
          optionValueKey="optionKey"
          optionDisplayKey="optionDescription"
          onSelectOption={option => {
            updateFilter(i, { ...filter, selectedOption: { ...option } });
          }}
        />
        {!hideValueInput && input}
      </div>
    </div>
  );

  return (
    <div className="customer-group-dynamic-filter-item" key={createKey(filter.field, i)}>
      <span className={`content no-border static`}>{content}</span>
      {!filter.editing && (
        <button className="button-action-icon" onClick={() => deleteFilter(i)}>
          <IconTrash height="20" className="icon-button" />
        </button>
      )}
    </div>
  );
}
