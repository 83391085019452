import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import Resources from "../../lib/resources";

import ProfileAttribute from "./profileAttribute";
import SyncedWith from "../library/syncedWith";
import { isEmpty } from "../../lib/utils";

class CompanyProfileUI extends Component {
  getAddress(address = { Country: "", Line1: "", Locality: "", PostalCode: "", Region: "" }) {
    let { /*Country,*/ Line1, Locality, PostalCode, Region } = address;
    return (
      <div>
        {Line1}
        <br />
        {Locality}, {Region}
        <br />
        {PostalCode}
      </div>
    );
  }

  render() {
    let {
      companyName,
      apEmail,
      arEmail,
      apPhone,
      arPhone,
      url,
      address,
      TIN,
      terms,
      contactEmail,
      contactName,
      accountId,
      connectorName,
      mobile,
      vendorLink
    } = this.props;

    let isVendor = !!arEmail || !!arPhone;
    let isCustomer = !!apEmail || !!apPhone;
    let isVendorAndCustomer = (isVendor && isCustomer) || (!isVendor && !isCustomer);
    let shouldShowCustomSyncedWithText = !(isVendor && isCustomer);

    return (
      <React.Fragment>
        <div className="company-profile-content">
          <div className="company-profile-column">
            <ProfileAttribute
              label={isVendorAndCustomer ? Resources.Name : isVendor ? Resources.Vendor : Resources.Customer}
              content={companyName}
            />
            {(isCustomer || isVendorAndCustomer) && (
              <ProfileAttribute label={isVendorAndCustomer ? Resources.ArEmail : Resources.Email} content={apEmail} />
            )}
            {(isVendor || isVendorAndCustomer) && (
              <ProfileAttribute label={isVendorAndCustomer ? Resources.ApEmail : Resources.Email} content={arEmail} />
            )}
            {(isCustomer || isVendorAndCustomer) && (
              <ProfileAttribute label={isVendorAndCustomer ? Resources.ArPhone : Resources.Phone} content={apPhone} />
            )}
            {(isVendor || isVendorAndCustomer) && (
              <ProfileAttribute label={isVendorAndCustomer ? Resources.ApPhone : Resources.Phone} content={arPhone} />
            )}
            <ProfileAttribute label={Resources.Mobile} content={mobile} />
            <ProfileAttribute label={Resources.Website} content={url} />
            <ProfileAttribute label={Resources.AccountId} content={accountId} />
          </div>
          <div className="company-profile-column">
            <ProfileAttribute label={Resources.BillingAddress} content={this.getAddress(address || {})} />
            <ProfileAttribute label={Resources.PrimaryContact} content={contactName} />
            <ProfileAttribute label={Resources.ContactEmail} content={contactEmail} />
            <ProfileAttribute label={Resources.TaxID} content={TIN} />
            <ProfileAttribute label={Resources.Terms} content={terms} />
          </div>
        </div>
        {!this.props.isMyCompany && (
          <div className="company-profile-footer">
            {!isEmpty(vendorLink) && (
              <a href={vendorLink.link || ""} target="_blank" rel="noopener noreferrer">
                {vendorLink.linkText || ""}
              </a>
            )}
            <SyncedWith
              connectorName={connectorName}
              text={
                shouldShowCustomSyncedWithText
                  ? isVendor
                    ? Resources.VendorDataSynchronizedWith
                    : Resources.CustomerDataSynchronizedWith
                  : null
              }
            />
          </div>
        )}
      </React.Fragment>
    );
  }
}

const storeToProps = store => {
  return {
    companies: store.companies
  };
};

CompanyProfileUI.defaultProps = {
  readOnly: true,
  showConnectorLink: true,
  isSidePanel: false,
  actions: []
};

export default withRouter(connect(storeToProps)(CompanyProfileUI));
