import { actions } from "./user-actions";
import jwt_decode from "jwt-decode";

const defaultCompanyUsersState = {
  usersCompanyId: null,
  gettingUsers: false,
  fetchedUsers: false,
  users: [],
  userLookup: {}
};

const defaultUserState = {
  isLoginModalVisible: false,
  isLoggedIn: false,
  isLoggingIn: false,
  isLoggingOut: false,
  didLoginFail: false,
  isTokenExpiring: false,
  shouldRefreshToken: false,
  lastTokenRefresh: null,

  isValidatingPassword: false,
  isPasswordValid: false,
  isPasswordScoreValid: false,
  isPasswordLengthValid: false,
  isPasswordCharsValid: false,
  validatePasswordErrors: [],

  gettingUserConfig: false,
  fetchedUserConfig: false,
  userConfig: {},

  isUpdatingCompanyUserSignature: false,
  updatedCompanyUserSignature: false,
  updatingCompanyUserSignatureFailed: false,

  isUpdatingSignatureAttachments: false,
  updatedSignatureAttachments: false,
  updatingSignatureAttachmentsFailed: false,

  isFetchingCompanyUserSignatures: false,
  fetchedCompanyUserSignatures: false,
  fetchingCompanyUserSignaturesFailed: false,
  userSignatures: [],

  ...defaultCompanyUsersState
};

const userReducer = (state = defaultUserState, action) => {
  switch (action.type) {
    case actions.SHOW_LOGIN_MODAL:
      return { ...state, ...{ isLoginModalVisible: true } };
    case actions.HIDE_LOGIN_MODAL:
      return { ...state, ...{ isLoginModalVisible: false } };

    case actions.LOGGING_IN:
      return {
        ...state,
        ...{ isLoggingIn: true, didLoginFail: action.didLoginFail }
      };
    case actions.LOGIN_FAILED:
      return {
        ...state,
        ...{ isLoggingIn: false, didLoginFail: action.didLoginFail }
      };
    case actions.LOGIN_FAILED_RESET:
      return { ...state, didLoginFail: false };
    case actions.LOGGED_IN:
      let decoded = null;
      if (action.token) decoded = jwt_decode(action.token);
      return {
        ...state,
        ...{
          isLoggingIn: false,
          isLoggedIn: true,
          token: action.token,
          decoded: decoded,
          isTokenExpiring: false,
          shouldRefreshToken: false,
          lastTokenRefresh: new Date()
        }
      };
    case actions.TOKEN_EXPIRING:
      return { ...state, isTokenExpiring: true };
    case actions.LOGGING_OUT:
      return {
        ...state,
        ...{ isLoggingOut: true, isLoggedIn: false, token: null, decoded: null }
      };
    case actions.LOGOUT_FAILED:
      return { ...state, ...{ isLoggingOut: false } };
    case actions.LOGGED_OUT:
      return {
        ...state,
        ...{
          isLoggingOut: false,
          isLoggedIn: false,
          token: null,
          decoded: null
        }
      };

    case actions.VALIDATING_PASSWORD:
      return { ...state, isValidatingPassword: true, isPasswordValid: false };
    case actions.PASSWORD_VALIDATED:
      return {
        ...state,
        isValidatingPassword: false,
        isPasswordScoreValid: action.isPasswordScoreValid,
        isPasswordLengthValid: action.isPasswordLengthValid,
        isPasswordCharsValid: action.isPasswordCharsValid,
        isPasswordValid: action.isPasswordValid,
        validatePasswordErrors: []
      };
    case actions.PASSWORD_VALIDATION_FAILED:
      return {
        ...state,
        isValidatingPassword: false,
        isPasswordValid: false,
        validatePasswordErrors: action.validatePasswordErrors
      };

    case actions.GETTING_USERS:
      let gettingUsers = { ...state };
      if (state.usersCompanyId !== action.companyId) {
        gettingUsers.users = [];
        gettingUsers.userLookup = {};
      }
      return {
        ...gettingUsers,
        ...{
          gettingUsers: true,
          fetchedUsers: false,
          usersCompanyId: action.companyId
        }
      };
    case actions.GET_USERS_FAILED:
      if (state.usersCompanyId !== action.companyId) return state;
      return { ...state, ...{ gettingUsers: false, fetchedUsers: true } };
    case actions.GOT_USERS:
      if (state.usersCompanyId !== action.companyId) return state;
      let users = [...action.users];
      let userLookup = {};
      users.forEach(u => {
        userLookup[u.userId] = u;
      });
      return {
        ...state,
        ...{ gettingUsers: false, fetchedUsers: true, users, userLookup }
      };

    case actions.GETTING_USER_CONFIG:
      return {
        ...state,
        ...{ gettingUserConfig: true, fetchedUserConfig: false }
      };
    case actions.GET_USER_CONFIG_FAILED:
      return {
        ...state,
        ...{ gettingUserConfig: false, fetchedUserConfig: true }
      };
    case actions.GOT_USER_CONFIG:
      return {
        ...state,
        ...{
          gettingUserConfig: false,
          fetchedUserConfig: true,
          userConfig: action.config
        }
      };

    case actions.UPDATING_USER_CONFIG:
      return { ...state, updatingUserConfig: true };
    case actions.UPDATED_USER_CONFIG:
      return { ...state, updatingUserConfig: false, userConfig: { ...state.userConfig, ...action.configUpdate } };
    case actions.UPDATE_USER_CONFIG_FAILED:
      return { ...state, updatingUserConfig: false };

    case actions.FETCHING_COMPANY_USER_SIGNATURES:
      return {
        ...state,
        isFetchingCompanyUserSignatures: true,
        fetchedCompanyUserSignatures: false,
        fetchingCompanyUserSignaturesFailed: false
      };
    case actions.FETCHED_COMPANY_USER_SIGNATURES:
      return {
        ...state,
        isFetchingCompanyUserSignatures: false,
        fetchedCompanyUserSignatures: true,
        fetchingCompanyUserSignaturesFailed: false,
        userSignatures: action.userSignatures
      };
    case actions.FETCH_COMPANY_USER_SIGNATURES_FAILED:
      return {
        ...state,
        isFetchingCompanyUserSignatures: false,
        fetchedCompanyUserSignatures: false,
        fetchingCompanyUserSignaturesFailed: true
      };

    case actions.UPDATING_COMPANY_USER_SIGNATURE:
      return {
        ...state,
        isUpdatingCompanyUserSignature: true,
        updatedCompanyUserSignature: false,
        updatingCompanyUserSignatureFailed: false
      };
    case actions.UPDATED_COMPANY_USER_SIGNATURE:
      return {
        ...state,
        isUpdatingCompanyUserSignature: false,
        updatedCompanyUserSignature: true,
        updatingCompanyUserSignatureFailed: false
      };
    case actions.UPDATING_COMPANY_USER_SIGNATURE_FAILED:
      return {
        ...state,
        isUpdatingCompanyUserSignature: false,
        updatedCompanyUserSignature: false,
        updatingCompanyUserSignatureFailed: true
      };

    case actions.UPDATING_SIGNATURE_ATTACHMENTS:
      return {
        ...state,
        isUpdatingSignatureAttachments: true,
        updatedSignatureAttachments: false,
        updatingSignatureAttachmentsFailed: false
      };
    case actions.UPDATED_SIGNATURE_ATTACHMENTS:
      return {
        ...state,
        isUpdatingSignatureAttachments: false,
        updatedSignatureAttachments: true,
        updatingSignatureAttachmentsFailed: false
      };
    case actions.UPDATING_SIGNATURE_ATTACHMENTS_FAILED:
      return {
        ...state,
        isUpdatingSignatureAttachments: false,
        updatedSignatureAttachments: false,
        updatingSignatureAttachmentsFailed: true
      };

    case actions.SELECT_COMPANY:
      if (state.usersCompanyId !== action.companyId) return { ...state, ...defaultCompanyUsersState };
      else return state;
    case actions.CLEAR_PASSWORD_ERRORS:
      return { ...state, validatePasswordErrors: [] };
    case actions.CLEAR_DATA:
      return defaultUserState;
    case actions.USER_ACTIVITY:
      return { ...state, shouldRefreshToken: true };
    default:
      return state;
  }
};

export default userReducer;
