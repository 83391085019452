import React, { Component } from "react";

class IconPercent extends Component {
  render() {
    let { className, height } = this.props;
    return (
      <svg
        height={height}
        className={`icon ${className ? className : ""}`}
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        viewBox="0 0 22 22"
      >
        <defs />
        <path d="M19 5L5 19" />
        <circle cx="6.5" cy="6.5" r="2.5" />
        <circle cx="17.5" cy="17.5" r="2.5" />
      </svg>
    );
  }
}

IconPercent.defaultProps = {
  height: 22
};

export default IconPercent;
