import Resources from "../../../../lib/resources";

const accountUpdateData = [
  { fieldName: Resources.CompanyName, type: Resources.FreeformText, required: Resources.Yes },
  { fieldName: Resources.ein, type: Resources.FreeformText, required: Resources.Yes },
  { fieldName: Resources.EntityClassification, type: Resources.DropdownSelection, required: Resources.Yes },
  { fieldName: Resources.ApEmail, type: Resources.FreeformText, required: Resources.Yes },
  { fieldName: Resources.ApPhone, type: Resources.FreeformText, required: Resources.Yes },
  { fieldName: Resources.ApFax, type: Resources.FreeformText, required: Resources.No },
  { fieldName: Resources.CompanyLinkedIn, type: Resources.FreeformText, required: Resources.No },
  { fieldName: Resources.CompanyLogo, type: Resources.FileUpload, required: Resources.Yes },
  { fieldName: Resources.BillToAddress, type: Resources.FreeformText, required: Resources.Yes },
  { fieldName: Resources.ShipToAddress, type: Resources.FreeformText, required: Resources.Yes },
  { fieldName: Resources.Notes, type: Resources.FreeformText, required: Resources.No },
  { fieldName: Resources.Contacts, type: Resources.FreeformText, required: Resources.No },
  { fieldName: Resources.CreditApplication, type: Resources.FileUpload, required: Resources.Yes },
  { fieldName: Resources.ProofOfInsurance, type: Resources.FileUpload, required: Resources.Yes },
  { fieldName: Resources.TaxExemptionCertificate, type: Resources.FileUpload, required: Resources.Yes }
];

const aniversaryAccountData = [
  { fieldName: Resources.CompanyName, type: Resources.FreeformText, required: Resources.Yes },
  { fieldName: Resources.ein, type: Resources.FreeformText, required: Resources.Yes },
  { fieldName: Resources.EntityClassification, type: Resources.DropdownSelection, required: Resources.Yes },
  { fieldName: Resources.ApEmail, type: Resources.FreeformText, required: Resources.Yes },
  { fieldName: Resources.ApPhone, type: Resources.FreeformText, required: Resources.Yes },
  { fieldName: Resources.ApFax, type: Resources.FreeformText, required: Resources.No },
  { fieldName: Resources.CompanyLinkedIn, type: Resources.FreeformText, required: Resources.No },
  { fieldName: Resources.CompanyLogo, type: Resources.FileUpload, required: Resources.Yes },
  { fieldName: Resources.BillToAddress, type: Resources.FreeformText, required: Resources.Yes },
  { fieldName: Resources.ShipToAddress, type: Resources.FreeformText, required: Resources.Yes },
  { fieldName: Resources.Notes, type: Resources.FreeformText, required: Resources.No },
  { fieldName: Resources.Contacts, type: Resources.FreeformText, required: Resources.No },
  { fieldName: Resources.CreditApplication, type: Resources.FileUpload, required: Resources.Yes },
  { fieldName: Resources.ProofOfInsurance, type: Resources.FileUpload, required: Resources.Yes },
  { fieldName: Resources.TaxExemptionCertificate, type: Resources.FileUpload, required: Resources.Yes }
];

const contactUpdateData = [
  { fieldName: Resources.Name, type: Resources.FreeformText, required: Resources.Yes },
  { fieldName: Resources.EmailAddress, type: Resources.FreeformText, required: Resources.Yes },
  { fieldName: Resources.Phone, type: Resources.FreeformText, required: Resources.Yes },
  { fieldName: Resources.Fax, type: Resources.FreeformText, required: Resources.No },
  { fieldName: Resources.Role, type: Resources.DropdownSelection, required: Resources.Yes },
  { fieldName: Resources.Title, type: Resources.FreeformText, required: Resources.Yes },
  { fieldName: Resources.LinkedIn, type: Resources.FreeformText, required: Resources.No },
  { fieldName: Resources.ProfilePicture, type: Resources.FileUpload, required: Resources.No },
  { fieldName: Resources.Notes, type: Resources.FreeformText, required: Resources.No }
];

const onboardingData = [
  { fieldName: Resources.CompanyName, type: Resources.FreeformText, required: Resources.Yes },
  { fieldName: Resources.ein, type: Resources.FreeformText, required: Resources.Yes },
  { fieldName: Resources.EntityClassification, type: Resources.DropdownSelection, required: Resources.Yes },
  { fieldName: Resources.EmailAddress, type: Resources.FreeformText, required: Resources.Yes },
  { fieldName: Resources.Phone, type: Resources.FreeformText, required: Resources.Yes },
  { fieldName: Resources.Fax, type: Resources.FreeformText, required: Resources.No },
  { fieldName: Resources.LinkedIn, type: Resources.FreeformText, required: Resources.No },
  { fieldName: Resources.CompanyLogo, type: Resources.FileUpload, required: Resources.Yes },
  { fieldName: Resources.BillToAddress, type: Resources.FreeformText, required: Resources.Yes },
  { fieldName: Resources.ShipToAddress, type: Resources.FreeformText, required: Resources.Yes },
  { fieldName: Resources.Notes, type: Resources.FreeformText, required: Resources.No },
  { fieldName: Resources.PrimaryContactName, type: Resources.FreeformText, required: Resources.Yes },
  { fieldName: Resources.PrimaryContactRole, type: Resources.DropdownSelection, required: Resources.Yes },
  { fieldName: Resources.PrimaryContactEmail, type: Resources.FreeformText, required: Resources.Yes },
  { fieldName: Resources.PrimaryContactPhone, type: Resources.FreeformText, required: Resources.Yes },
  { fieldName: Resources.SecondaryContactName, type: Resources.FreeformText, required: Resources.Yes },
  { fieldName: Resources.SecondaryContactRole, type: Resources.DropdownSelection, required: Resources.Yes },
  { fieldName: Resources.SecondaryContactEmail, type: Resources.FreeformText, required: Resources.Yes },
  { fieldName: Resources.SecondaryContactPhone, type: Resources.FreeformText, required: Resources.Yes },
  { fieldName: Resources.CreditApplication, type: Resources.FileUpload, required: Resources.Yes },
  { fieldName: Resources.ProofOfInsurance, type: Resources.FileUpload, required: Resources.Yes },
  { fieldName: Resources.TaxExemptionCertificate, type: Resources.FileUpload, required: Resources.Yes }
];

export { contactUpdateData, onboardingData, accountUpdateData, aniversaryAccountData };
