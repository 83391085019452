import React, { Component } from "react";
import { connect } from "react-redux";

import Icons from "../lib/icons";
import Resources from "../lib/resources";
import { isGuid, getFileNameIconClasses, containsHtml, htmlToText, formatDimensionalDate } from "../lib/utils";

import { dispatchToProps } from "../store/conversations-actions";

import TableData from "./library/tableData";

class DocumentsTable extends Component {
  constructor(props) {
    super(props);

    this.loadMore = this.loadMore.bind(this);
  }

  componentDidMount() {
    if (this.props.conversations.perspectiveId !== this.props.match.params.perspectiveId) {
      this.props.getConversationDocuments(
        this.props.match.params.companyId,
        this.props.match.params.selectedContextGroupId,
        false,
        this.props.match.params.perspectiveId
      );
    }
  }

  shouldComponentUpdate(nextProps) {
    let gotAttachmentUrls = this.props.gettingAttachmentUrls === true && nextProps.gettingAttachmentUrls === false;

    if (
      this.props.conversationDocuments.value.length !== nextProps.conversationDocuments.value.length ||
      this.props.gettingDocuments !== nextProps.gettingDocuments ||
      this.props.conversations.documents.sortBy !== nextProps.conversations.documents.sortBy ||
      this.props.conversations.documents.sortDirection !== nextProps.conversations.documents.sortDirection ||
      gotAttachmentUrls
    ) {
      return true;
    }
    return false;
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.conversations.documents.sortBy !== prevProps.conversations.documents.sortBy ||
      this.props.conversations.documents.sortDirection !== prevProps.conversations.documents.sortDirection
    ) {
      this.props.getConversationDocuments(
        this.props.match.params.companyId,
        this.props.match.params.selectedContextGroupId,
        false,
        this.props.match.params.perspectiveId
      );
    }
  }

  loadMore() {
    let {
      conversations,
      match: {
        params: { companyId, selectedContextGroupId, perspectiveId }
      }
    } = this.props;
    if (conversations.gettingDocuments !== true) {
      this.props.getConversationDocuments(companyId, selectedContextGroupId, true, perspectiveId);
    }
  }

  changeDocumentSort(sortBy) {
    let currentSortBy = this.props.conversations.documents.sortBy;
    let currentSortDirection = this.props.conversations.documents.sortDirection;

    if (currentSortBy !== sortBy) {
      this.props.setDocumentSort(sortBy, "desc");
    } else if (currentSortDirection === "desc") {
      this.props.setDocumentSort(sortBy, "asc");
    } else {
      this.props.setDocumentSort(sortBy, "desc");
    }
  }

  prettyDocuments(docs) {
    return docs.value.map(doc => {
      let newDoc = {};

      if (containsHtml(doc.text)) {
        let text = doc.text;
        if (text === "Html body not available.") {
          text = "<body></body>";
        }
        newDoc.text = htmlToText(text);
      } else {
        newDoc.text = doc.text;
      }

      newDoc.createdDate = formatDimensionalDate(doc.createdDate);
      newDoc.partnerName = this.props.getContextGroupNameFromCompanyId(doc.partnerCompanyId);
      newDoc.fileName = doc.fileName;
      newDoc.direction = doc.direction;
      newDoc.url = this.props.getAttachmentUrl(doc.attachmentId);
      newDoc.subject = doc.subject;
      newDoc.downloadIcon = <span className={Icons.download + "float-right"} />;
      return newDoc;
    });
  }

  columns = hasPartnerCol => {
    let sortByIcon =
      this.props.conversations.documents.sortDirection === "desc" ? (
        <span className={`${Icons.angleDown} sort-direction-icon`} />
      ) : (
        <span className={`${Icons.angleUp} sort-direction-icon`} />
      );

    let organizationButton = (
      <div className="sort-by-button" onClick={() => this.changeDocumentSort("withCompany")}>
        {Resources.Organization.toLocaleUpperCase()}
        {this.props.conversations.documents.sortBy === "withCompany" && sortByIcon}
      </div>
    );
    let subjectButton = (
      <div className="sort-by-button" onClick={() => this.changeDocumentSort("subject")}>
        {Resources.Subject.toLocaleUpperCase()}
        {this.props.conversations.documents.sortBy === "subject" && sortByIcon}
      </div>
    );
    let fileNameButton = (
      <div className="sort-by-button" onClick={() => this.changeDocumentSort("fileName")}>
        {Resources.Document.toLocaleUpperCase()}
        {this.props.conversations.documents.sortBy === "fileName" && sortByIcon}
      </div>
    );
    let dateButton = (
      <div className="sort-by-button" onClick={() => this.changeDocumentSort("createdDate")}>
        {Resources.Date.toLocaleUpperCase()}
        {this.props.conversations.documents.sortBy === "createdDate" && sortByIcon}
      </div>
    );
    let directionButton = (
      <div className="sort-by-button" onClick={() => this.changeDocumentSort("direction")}>
        {Resources.Direction.toLocaleUpperCase()}
        {this.props.conversations.documents.sortBy === "direction" && sortByIcon}
      </div>
    );

    let organizationCol = {
      header: organizationButton,
      content: row => {
        return <div className="overflow-ellipsis highlight">{row.partnerName}</div>;
      },
      width: "12%"
    };
    let fileNameCol = {
      header: fileNameButton,
      content: row => (
        <div className="documents-file-name">
          <span className={getFileNameIconClasses(row.fileName)} />{" "}
          <div className="overflow-ellipsis highlight">{row.fileName}</div>
        </div>
      ),
      width: "25%"
    };
    let subjectCol = hasPartnerCol => {
      return {
        header: subjectButton,
        content: row => {
          return (
            <div>
              <div className="overflow-ellipsis highlight">{row.subject}</div>
              <div className="overflow-ellipsis documents-subject-subtext">{row.text}</div>
            </div>
          );
        },
        width: hasPartnerCol ? "37%" : "49%"
      };
    };
    let directionCol = {
      header: directionButton,
      content: row => <span>{row.direction}</span>,
      width: "10%"
    };
    let dateCol = {
      header: dateButton,
      content: row => <span className="documents-date">{row.createdDate}</span>,
      width: "11%"
    };
    let downloadCol = {
      header: "",
      content: row => (
        <a href={row.url} onClick={e => e.stopPropagation()} target="_blank" rel="noreferrer noopener">
          <span className={Icons.download + " documents-file-download"} />
        </a>
      ),
      className: "text-center"
    };

    if (hasPartnerCol) {
      return [organizationCol, fileNameCol, subjectCol(hasPartnerCol), directionCol, dateCol, downloadCol];
    } else {
      return [fileNameCol, subjectCol(hasPartnerCol), directionCol, dateCol, downloadCol];
    }
  };

  render() {
    let { conversationDocuments, gettingDocuments } = this.props;
    let params = this.props.match.params;

    let prettyDocuments = this.prettyDocuments(conversationDocuments);

    return (
      <TableData
        name="documents-table"
        data={prettyDocuments}
        columns={this.columns(!isGuid(params.selectedContextGroupId))}
        rowHeight="4em"
        onLoadMore={this.loadMore}
        maxRows={conversationDocuments.count}
        loading={gettingDocuments}
        rowClassName="documents-view-row"
        onRowClick={row => {
          window.open(row.url, "_blank");
        }}
        emptyRender={
          <div className="flex-center">
            <h4 className="mt-5">{Resources.EmptyInboxDocuments}</h4>
          </div>
        }
      />
    );
  }
}

const storeToProps = store => {
  return {
    conversationDocuments: store.conversations.documents,
    gettingDocuments: store.conversations.gettingDocuments,
    gettingAttachmentUrls: store.ledger.gettingAttachmentUrls
  };
};

export default connect(storeToProps, dispatchToProps)(DocumentsTable);
