import React, { Component } from "react";
import { getDisplaySize, getFileNameIconClasses } from "../lib/utils";
import Icons from "../lib/icons";
import Resources from "../lib/resources";

class Attachment extends Component {
  constructor(props) {
    super(props);
    this.onDelete = this.onDelete.bind(this);
  }

  onDelete(e) {
    e.stopPropagation();
    let { onDelete } = this.props;
    if (onDelete) onDelete();
  }

  render() {
    let { name, size, showDelete, showIcon } = this.props;
    let { signedUrl, previewUrl } = this.props.attachment;

    let attachmentOverlay;
    if (previewUrl) {
      attachmentOverlay = (
        <div className="attachment-overlay">
          <a href={previewUrl} target="_blank" rel="noopener noreferrer">
            {Resources.Preview}
          </a>
        </div>
      );
    }

    return (
      <React.Fragment>
        <div className="attachment noselect">
          {showIcon === true && <span className={`attachment-icon ${getFileNameIconClasses(name)}`} />}
          <div className="attachment-content-container">
            <div className="attachment-content">
              <div>{name}</div>
              <div className="attachment-size">{getDisplaySize(size || 0)}</div>
            </div>
            {attachmentOverlay}
          </div>
          {showDelete ? (
            <div className="attachment-right" onClick={this.onDelete}>
              <span className={`attachment-delete ${Icons.close}`} />
            </div>
          ) : (
            <div className="attachment-right">
              <a href={signedUrl} target="_blank" rel="noopener noreferrer">
                <span className={`attachment-download ${Icons.download}`} />
              </a>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default Attachment;
