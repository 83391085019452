import React from "react";

import Card from "../../../library/card";
import ToggleSwitch from "../../../library/toggleSwitch";
import Resources from "../../../../lib/resources";
import TableData from "../../../library/tableData";
import { onboardingData, accountUpdateData, contactUpdateData, aniversaryAccountData } from "./sequenceData";

function ViewSequenceContent(props) {
  const { sequence = {} } = props;

  let sequenceTypes = {
    "Profile Update": "profile update",
    "Customer Onboarding": "onboarding"
  };

  let columns = [
    { header: Resources.FieldName.toLocaleUpperCase(), content: row => row.fieldName, width: "25%" },
    { header: Resources.Type.toLocaleUpperCase(), content: row => row.type, width: "25%" },
    { header: Resources.Required.toLocaleUpperCase(), content: row => row.required, width: "25%" }
  ];

  let sequenceMap = {
    "Account profile update request": (
      <div className="sequence-table-container">
        <TableData noScroll noGrow data={accountUpdateData} columns={columns} rowHeight={"5rem"} />
      </div>
    ),
    "Contact profile update request": (
      <div className="sequence-table-container">
        <TableData noScroll noGrow data={contactUpdateData} columns={columns} rowHeight={"5rem"} />
      </div>
    ),
    "Account onboarding": (
      <div className="sequence-table-container">
        <TableData noScroll noGrow data={onboardingData} columns={columns} rowHeight={"5rem"} />
      </div>
    ),
    "Account annual anniversary": (
      <div className="sequence-table-container">
        <TableData noScroll noGrow data={aniversaryAccountData} columns={columns} rowHeight={"5rem"} />
      </div>
    )
  };

  return (
    <Card className="sequence-view-card">
      <div className="sequence-view-card-header">
        <div className="sequence-view-card-header-description">{sequence.description}</div>
        <label className="edit-sequence-input-label">{Resources.Status}</label>
        <div>
          <ToggleSwitch
            checked={sequence.isActive}
            onChange={() => props.toggleSequenceActivation()}
            text={sequence.isActive ? Resources.Active : Resources.Inactive}
          />
        </div>
      </div>
      <hr className="sequence-view-card-divider" />
      <div className="sequence-view-card-steps">
        <h2 className="sequence-steps-header">{Resources.Steps}</h2>
        <div className="sequence-steps-list">
          {(sequence.sequenceSteps || []).map((sequenceStep, i) => SequenceStep({ sequenceStep, i }))}
        </div>
      </div>
      <hr className="sequence-view-card-divider" />
      <div className="sequence-view-card-steps">
        <h2 className="sequence-steps-header">{Resources.FormFields}</h2>
        <p className="sequence-table-label">
          {Resources.FormFieldsDescription(sequenceTypes[sequence.customerSequenceName])}
        </p>
        {sequenceMap[sequence.customerSequenceName]}
        <p>{Resources.SequenceContactPrivateMessage}</p>
      </div>
    </Card>
  );
}

function SequenceStep(props) {
  let { sequenceStep, i } = props;
  return (
    <div className="sequence-step">
      <div className="sequence-step-order-number">{i + 1}</div>
      <Card index={i} className="sequence-step-card no-hover">
        {sequenceStep.description}
      </Card>
    </div>
  );
}

export default ViewSequenceContent;
