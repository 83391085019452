import { getString, formatString } from "./strings";
// import { format } from "numeral";

class Resources {
  get Add() {
    return getString("ADD", "Add");
  }
  get Edit() {
    return getString("EDIT", "Edit");
  }
  get Delete() {
    return getString("DELETE", "Delete");
  }
  get YesDelete() {
    return getString("YES_DELETE", "Yes, delete");
  }
  get DeleteConversationWarning() {
    return getString(
      "DELETE_CONVERSATION_WARNING",
      "You are about to permanently delete this conversation. Just checking because once we delete it, it's gone for good."
    );
  }
  get DeleteTaskWarning() {
    return getString(
      "DELETE_TASK_WARNING",
      "You are about to permanently delete this task. Just checking because once we delete it, it's gone for good."
    );
  }

  get Error() {
    return getString("ERROR", "Error");
  }

  get Ok() {
    return getString("OK", "Ok");
  }
  get Save() {
    return getString("SAVE", "Save");
  }
  get SaveAs() {
    return getString("SAVE_AS", "Save as...");
  }
  get SaveAndClose() {
    return getString("SAVE_AND_CLOSE", "Save and close");
  }
  get Saved() {
    return getString("SAVED", "Saved");
  }
  get Saving() {
    return getString("SAVING", "Saving");
  }
  get Update() {
    return getString("UPDATE", "Update");
  }
  get Updating() {
    return getString("UPDATING", "Updating");
  }
  get Submit() {
    return getString("SUBMIT", "Submit");
  }
  get Send() {
    return getString("SEND", "Send");
  }
  get SendAndClose() {
    return getString("SEND_AND_CLOSE", "Send and close");
  }
  get SendForResponse() {
    return getString("SEND_FOR_RESPONSE", "Send for response");
  }
  get Discard() {
    return getString("DISCARD", "Discard");
  }
  get Done() {
    return getString("DONE", "Done");
  }
  get Cancel() {
    return getString("CANCEL", "Cancel");
  }
  get NoCancel() {
    return getString("NO_CANCEL", "No, cancel");
  }
  get Close() {
    return getString("CLOSE", "Close");
  }
  get Preview() {
    return getString("PREVIEW", "Preview");
  }
  get ViewTask() {
    return getString("VIEW_TASK", "View Task");
  }
  get ViewSent() {
    return getString("VIEW_Sent", "View Sent");
  }
  get ViewReceived() {
    return getString("VIEW_RECEIVED", "View Received");
  }
  get ViewResponse() {
    return getString("VIEW_RESPONSE", "View Response");
  }
  get ViewRelatedConversation() {
    return getString("VIEW_RELATED_CONVERSATION", "View related conversation");
  }
  get View() {
    return getString("VIEW", "View");
  }
  get Clear() {
    return getString("CLEAR", "Clear");
  }
  get Upload() {
    return getString("UPLOAD", "Upload");
  }
  get Import() {
    return getString("Import", "Import");
  }
  get DragFileToUpload() {
    return getString("DRAG_FILE_TO_UPLOAD", "Drag file here to upload");
  }
  get CSVUploadError() {
    return getString(
      "UPLOAD_CSV_ERROR",
      "We were unable to import the file you provided.  Lockstep can import files exported directly from Gmail or Outlook.  If you are having trouble, please send your file to Lockstep support and we'll be happy to help."
    );
  }
  get ImportCSV() {
    return getString("IMPORT_CSV", "Import .csv");
  }
  get SelectCSVFromComputer() {
    return getString("SELECT_CSV_FROM_COMPUTER", "Select .CSV file from computer");
  }
  get SelectedFile() {
    return getString("SELECTED_FILE", "Selected file:");
  }
  get Select() {
    return getString("SELECT", "Select");
  }
  get Yes() {
    return getString("YES", "Yes");
  }
  get No() {
    return getString("NO", "No");
  }
  get Search() {
    return getString("SEARCH", "Search");
  }
  get SearchResults() {
    return getString("SEARCH_RESULTS", "Search results");
  }
  get BackToSearchResults() {
    return getString("BACK_TO_SEARCH_RESULTS", "Back to search results");
  }
  get Searching() {
    return getString("SEARCHING", "Searching");
  }
  get Reports() {
    return getString("REPORTS", "Reports");
  }
  get Manage() {
    return getString("MANAGE", "Manage");
  }
  get Accounts() {
    return getString("ACCOUNTS", "Accounts");
  }
  get Company() {
    return getString("COMPANY", "Company");
  }

  get Resend() {
    return getString("RESEND", "Resend");
  }
  get Resending() {
    return getString("RESENDING", "Resending");
  }
  get Resent() {
    return getString("RESENT", "Resent");
  }
  get Retry() {
    return getString("RETRY", "Retry");
  }

  get Revoke() {
    return getString("REVOKE", "Revoke");
  }

  get Refreshing() {
    return getString("REFRESHING", "Refreshing");
  }
  get Refresh() {
    return getString("REFRESH", "Refresh");
  }

  get Hide() {
    return getString("HIDE", "Hide");
  }
  get Show() {
    return getString("SHOW", "Show");
  }

  get Issued() {
    return getString("ISSUED", "Issued");
  }
  get Due() {
    return getString("DUE", "Due");
  }
  get DueDateLabel() {
    return getString("DUE_DATE_LABEL", "Due date");
  }
  get DateProcessed() {
    return getString("DATE_PROCESSED", "Date processed");
  }
  get DatePaid() {
    return getString("DATE_PAID", "Date paid");
  }
  get DueToday() {
    return getString("DUE_TODAY", "Due Today");
  }
  get Today() {
    return getString("TODAY", "Today");
  }
  get DueTomorrow() {
    return getString("DUE_TOMORROW", "Due Tomorrow");
  }
  OverdueBy(days) {
    return formatString(getString("OVERDUE_BY", "Overdue by {days} days"), { days });
  }
  get OverdueByOneDay() {
    return getString("OVERDUE_BY_ONE_DAY", "Overdue by 1 day");
  }
  IssueDate(date) {
    return formatString(getString("ISSUE_DATE", "Issued: {date}"), { date });
  }
  DueDate(date) {
    return formatString(getString("DUE_DATE", "Due: {date}"), { date });
  }
  get Dates() {
    return getString("DATES", "Dates");
  }
  get Property() {
    return getString("PROPERTY", "Property");
  }
  get Value() {
    return getString("VALUE", "Value");
  }
  get All() {
    return getString("ALL", "All");
  }

  get Help() {
    return getString("HELP", "Help");
  }
  get SignIn() {
    return getString("SIGN_IN", "Sign In");
  }
  get SigningIn() {
    return getString("SIGNING_IN", "Signing In");
  }
  get Logout() {
    return getString("LOGOUT", "Logout");
  }
  get Settings() {
    return getString("SETTINGS", "Settings");
  }
  get SettingsV2() {
    return getString("SETTINGS", "Settings V2");
  }
  get Home() {
    return getString("HOME", "Home");
  }
  get HomeTitle() {
    return getString("HOME_TITLE", "Welcome to Lockstep!");
  }
  get LoginTitle() {
    return getString("LOGIN_TITLE", "Login");
  }
  get SessionExpiringTitle() {
    return getString("SESSION_EXPIRE_TITLE", "Session Expiring");
  }
  get SessionExpiring() {
    return getString("SESSION_EXPIRE", "Your session is about to expire.  Click Ok to renew your session.");
  }

  get UnknownSources() {
    return getString("UKNOWN_SOURCES", "Unknown Sources");
  }
  get SortBy() {
    return getString("SORT_BY", "Sort By");
  }
  get Name() {
    return getString("NAME", "Name");
  }
  get MostRecent() {
    return getString("MOST_RECENT", "Most Recent");
  }
  get ActiveCount() {
    return getString("ACTIVE_COUNT", "Active Count");
  }
  get RecentSearches() {
    return getString("RECENT_SEARCHES", "Recent Searches");
  }
  get Filter() {
    return getString("FILTER", "Filter");
  }

  get Vendors() {
    return getString("VENDORS", "Vendors");
  }
  get Customers() {
    return getString("CUSTOMERS", "Customers");
  }
  get VendorsAndCustomers() {
    return getString("VENDORS_AND_CUSTOMERS", "Vendors and Customers");
  }
  get Vendor() {
    return getString("VENDOR", "Vendor");
  }
  get Customer() {
    return getString("CUSTOMER", "Customer");
  }
  get OtherCompanyBusiness() {
    return getString("OTHER_COMPANY_BUSINESS", "Other company business (e.g. Payroll)");
  }
  get Documents() {
    return getString("DOCUMENTS", "Documents");
  }
  get Activities() {
    return getString("ACTIVITIES", "Activities");
  }
  get Conversations() {
    return getString("CONVERSATIONS", "Conversations");
  }
  get Conversation() {
    return getString("CONVERSATION", "Conversation");
  }
  get NewConversation() {
    return getString("NEW_CONVERSATION", "New conversation");
  }
  get Task() {
    return getString("TASK", "Task");
  }
  get Create() {
    return getString("CREATE", "Create");
  }
  get CreateNewTask() {
    return getString("CREATE_NEW_TASK", "Create new task");
  }
  TaskCreationMessage(creator, assignee) {
    return formatString(getString("TASK_CREATION_MESSAGE", "{creator} assigned a task to {assignee}"), {
      creator,
      assignee
    });
  }
  get Transactions() {
    return getString("TRANSACTIONS", "Transactions");
  }
  get SendInvoiceSummary() {
    return getString("SEND_INVOICE_SUMMARY", "Send summary of invoices");
  }
  get SendPaymentsSummary() {
    return getString("SEND_PAYMENTS_SUMMARY", "Send summary of payments");
  }
  get Statements() {
    return getString("STATEMENTS", "Statements");
  }
  StatementFor(company) {
    return formatString(getString("STATEMENT_FOR", "Statement for {company}"), { company });
  }
  get StatementWillBeIncluded() {
    return getString(
      "STATEMENT_WILL_BE_INCLUDED",
      "The account statement will be included at the end of this message."
    );
  }
  get Profile() {
    return getString("PROFILE", "Profile");
  }

  YouDontHavePermissions(verb, resource) {
    return formatString(
      getString(
        "YOU_DONT_HAVE_PERMISSIONS",
        "You don't have permission to {verb} the {resource}. It may be a feature available only to account administrators."
      ),
      {
        verb,
        resource
      }
    );
  }
  get SubscriptionFetchFailure() {
    return getString("SUBSCRIPTION_FETCH_FAILED", "Fetching of your subscription data failed.  Please try again.");
  }

  get AccountFetchFailure() {
    return getString("ACCOUNT_FETCH_FAILED", "Fetching of your account data failed.  Please try again.");
  }
  get AccountCreateFailure() {
    return getString("ACCOUNT_CREATE_FAILED", "Creation of your new account failed.  Please try again.");
  }
  get AccountUpdateFailure() {
    return getString("ACCOUNT_UPDATE_FAILED", "Update of your account failed.  Please try again.");
  }

  get CompanyInfoFetchFailure() {
    return getString("COMPANY_INFO_FETCH_FAILURE", "Fetching information for the company failed. Please try again.");
  }
  get CompanyFetchFailure() {
    return getString("COMPANY_FETCH_FAILED", "Fetching of your company data failed.  Please try again.");
  }
  get CompanyCreateFailure() {
    return getString("COMPANY_CREATE_FAILED", "Creation of your new company failed.  Please try again.");
  }
  get CompanyUpdateFailure() {
    return getString("COMPANY_UPDATE_FAILED", "Update of your company failed.  Please try again.");
  }

  get FetchResourcesFailure() {
    return getString("FETCH_RESOURCES_FAILURE", "Fetching resources failed. Please try again.");
  }

  get SavingSettingsFailure() {
    return getString("SAVING_SETTINGS_FAILURE", "Saving settings failed. Please try again.");
  }
  get FetchSettingsFailure() {
    return getString("FETCH_SETTINGS_FAILURE", "Fetching settings failed. Please try again.");
  }

  get UserLoginFailure() {
    return getString("USER_LOGIN_FAILURE", "Login failed.  Please verify your information and try again.");
  }

  get GrantsFetchFailure() {
    return getString("GRANTS_FETCH_FAILED", "Fetching the grants for this company failed.  Please try again.");
  }
  get ResourcesFetchFailure() {
    return getString("RESOURCES_FETCH_FAILED", "Fetching the resources for this company failed.  Please try again.");
  }

  get AccountInformation() {
    return getString("ACCOUNT_INFORMATION", "Account Information");
  }
  get AccountName() {
    return getString("ACCOUNT_NAME", "Account Name");
  }

  get CompanyInformation() {
    return getString("COMPANY_INFORMATION", "Company Information");
  }
  get CompanyName() {
    return getString("COMPANY_NAME", "Company name");
  }

  get Addresses() {
    return getString("ADDRESSES", "Addresses");
  }
  get EmailAddresses() {
    return getString("EMAIL_ADDRESSES", "Email Addresses");
  }
  get EmailAddress() {
    return getString("EMAIL_ADDRESS", "Email Address");
  }

  get DefaultWorkspace() {
    return getString("DEFAULT_WORKSPACE", "Default Workspace");
  }
  get UsersMustHaveWorkspaceError() {
    return getString(
      "USERS_MUST_HAVE_WORKSPACE_ERROR",
      "Whoops! Please make sure that the user has access to at least one workspace."
    );
  }
  get WorkspaceAccess() {
    return getString("WORKSPACE_ACCESS", "Workspace Access");
  }
  get AddWorkspace() {
    return getString("ADD_WORKSPACE", "Add Workspace");
  }
  get Signature() {
    return getString("SIGNATURE", "Signature");
  }
  get Repeat() {
    return getString("REPEAT", "Repeat");
  }
  get Frequency() {
    return getString("FREQUENCY", "Frequency");
  }
  get CustomerNotificationFrequency() {
    return getString("CUSTOMER_NOTIFICATION_FREQUENCY", "Customer notification frequency");
  }
  get NotificationFrequencyHelperText() {
    return getString(
      "NOTIFICATION_FREQUENCY_HELPER_TEXT",
      "You don’t want to spam your customers with too many notifications and updates. Since you send a high volume of invoices per month to some of your bigger customers, you can control how frequently they are contacted. The customer notification frequency will ensure only the most important communications are sent at the interval of your choosing."
    );
  }
  get Notifications() {
    return getString("NOTIFICATIONS", "Notifications");
  }
  get NotificationSettings() {
    return getString("NOTIFICATION_SETTINGS", "Notification Settings");
  }
  get NotificationSettingsSubtext() {
    return getString(
      "NOTIFICATION_SETTINGS_SUBTEXT",
      "To help you stay on top of important activities we can send you activity summary emails. Select the frequency at which you’d like to receive these emails."
    );
  }
  get CompanyWorkspaceSettings() {
    return getString("COMPANY_WORKSPACE_SETTINGS", "Workspace Settings");
  }

  get CompanyEmailSettings() {
    return getString("COMPANY_EMAIL_SETTINGS", "Email Settings");
  }
  get CompanyAccountingPackages() {
    return getString("COMPANY_ACCOUNTING_PACKAGES", "Accounting Packages");
  }
  get CompanyUsersAndPermissions() {
    return getString("COMPANY_USERS_AND_PERMISSIONS", "Users and Permissions");
  }
  get CompanyAuthentication() {
    return getString("COMPANY_AUTHENTICATION", "Authentication");
  }
  get CompanyFeatures() {
    return getString("COMPANY_FEATURES", "Features");
  }
  get CompanyAppStore() {
    return getString("COMPANY_APP_STORE", "App Store");
  }
  get MyProfile() {
    return getString("MY_PROFILE", "My profile");
  }
  get CompanyProfile() {
    return getString("COMPANY_PROFILE", "Company profile");
  }

  get CompanyInfoTitle() {
    return getString("COMPANY_INFO_TITLE", "Company Profile");
  }
  CompanyInfoSendMailTo(type) {
    return formatString(getString("COMPANY_INFO_SEND_MAIL", "Send {type} items to"), { type: type });
  }
  get CompanyInfoSendMailToAP() {
    return this.CompanyInfoSendMailTo(this.AccountsPayableAbbr);
  }
  get CompanyInfoSendMailToAR() {
    return this.CompanyInfoSendMailTo(this.AccountsReceivableAbbr);
  }
  get CompanyInfoContactNumbers() {
    return getString("COMPANY_INFO_CONTACT_NUMBERS", "Contact Numbers");
  }
  get CompanyInfoAddress() {
    return getString("COMPANY_INFO_ADDRESS", "Address");
  }
  get CompanyInfoUrl() {
    return getString("COMPANY_INFO_URL", "URL");
  }
  get CompanyInfoTIN() {
    return getString("COMPANY_INFO_TIN", "TIN");
  }
  get CompanyInfoW9() {
    return getString("COMPANY_INFO_W9", "W-9 File?");
  }
  get CompanyInfoW8() {
    return getString("COMPANY_INFO_W8", "W-8 BEN File");
  }
  CompanyInfoLink(perspective) {
    return formatString(getString("COMPANY_INFO_LINK", "{perspective} ID"), {
      perspective
    });
  }
  get ViewCompanyInAccountingPackage() {
    return getString("VIEW_COMPANY_IN_ACCOUNTING_PACKAGE", "View company in accounting package");
  }

  get ChooseIcon() {
    return getString("CHOOSE_ICON", "Choose Icon");
  }
  get ThisSpaceIsFor() {
    return getString("THIS_SPACE_IS_FOR", "This space is for collaborating with");
  }

  EmailSettingsForward(type) {
    return formatString(
      getString("EMAIL_SETTINGS_FORWARD", "Forward a copy of your <b>{type}</b> emails to this address:"),
      { type: type }
    );
  }
  get EmailSettingsAPForward() {
    return this.EmailSettingsForward(this.AccountsPayable);
  }
  get EmailSettingsARForward() {
    return this.EmailSettingsForward(this.AccountsReceivable);
  }
  get EmailForwardText() {
    return getString(
      "EMAIL_FORWARD_TEXT",
      "You could forward the emails yourself with a rule in your email program.  Or, you could work with your email administration team to have all emails forwarded to the appropriate address listed above for your primary AR and AP email addresses."
    );
  }
  get EmailCcBccToggle() {
    return getString("EMAIL_CC_BCC_TOGGLE", "Cc/Bcc");
  }
  get EmailFrom() {
    return getString("EMAIL_FROM", "From");
  }
  get EmailTo() {
    return getString("EMAIL_TO", "To");
  }
  get EmailCC() {
    return getString("EMAIL_CC", "Cc");
  }
  get EmailBCC() {
    return getString("EMAIL_BCC", "Bcc");
  }
  get NoRelatedConversationsFound() {
    return getString(
      "NO_RELATED_CONVERSATIONS",
      "We didn't find any conversations that might also be related to this conversation."
    );
  }
  get RelatedConversations() {
    return getString("RELATED_CONVERSATIONS", "Related Conversations");
  }
  get EmailRe() {
    return getString("EMAIL_RE", "RE:");
  }
  get EmailFw() {
    return getString("EMAIL_FW", "FW:");
  }
  get EmailAbout() {
    return getString("EMAIL_ABOUT", "RE:");
  }

  get Bill() {
    return getString("BILL", "Bill");
  }
  get CreateNewBill() {
    return getString("CREATE_NEW_BILL", "Create a new bill");
  }
  get Invoice() {
    return getString("INVOICE", "Invoice");
  }
  get Invoices() {
    return getString("INVOICES", "Invoices");
  }
  get OpenInvoices() {
    return getString("OPEN_INVOICES", "Open Invoices");
  }
  get ClosedInvoices() {
    return getString("CLOSED_INVOICES", "Closed Invoices");
  }
  get PastDueInvoices() {
    return getString("PAST_DUE_INVOICES", "Past Due Invoices");
  }
  get DaysComingDue() {
    return getString("DAYS_COMING_DUE", "Days coming due");
  }
  get DaysPastDue() {
    return getString("DAYS_PAST_DUE", "Days past due");
  }
  get InvoiceComingDue() {
    return getString("INVOICE_COMING_DUE", "Invoice coming due");
  }
  get InvoicePastDue() {
    return getString("INVOICE_PAST_DUE", "Invoice past due");
  }
  get TotalOpen() {
    return getString("TOTAL_OPEN", "Total open");
  }
  get TotalOpenInvoiceBalance() {
    return getString("TOTAL_OPEN_INVOICE_BALANCE", "Total open invoice balance");
  }
  get TotalAccountBalance() {
    return getString("TOTAL_ACCOUNT_BALANCE", "Total account balance");
  }
  get AccountBalanceHelperText() {
    return getString(
      "ACCOUNT_BALANCE_HELPER_TEXT",
      "Your total account balance is updated to reflect any pending and unapplied payments you have made."
    );
  }
  get TotalClosed() {
    return getString("TOTAL_CLOSED", "Total closed");
  }
  get Total() {
    return getString("TOTAL", "Total");
  }
  get PO() {
    return getString("PO", "PO");
  }
  get SO() {
    return getString("SO", "SO");
  }
  get Payment() {
    return getString("PAYMENT", "Payment");
  }
  get MakeAPayment() {
    return getString("MAKE_A_PAYMENT", "Make a payment");
  }
  get Payments() {
    return getString("PAYMENTS", "Payments");
  }
  get PaymentDetails() {
    return getString("PAYMENT_DETAILS", "Payment details");
  }
  get PaymentAmount() {
    return getString("PAYMENT_AMOUNT", "Payment amount");
  }
  get PaymentMethod() {
    return getString("PAYMENT_METHOD", "Payment method");
  }
  get AddingPaymentMethod() {
    return getString("ADDING_PAYMENT_METHOD", "Adding payment method");
  }
  get AddedPaymentMethod() {
    return getString("ADDED_PAYMENT_METHOD", "Added payment method");
  }
  get AddingPaymentMethodFailed() {
    return getString("ADDING_PAYMENT_METHOD_FAILED", "Adding the payment method failed");
  }
  get PaymentDate() {
    return getString("PAYMENT_DATE", "Payment date");
  }
  get PaidFrom() {
    return getString("PAID_FROM", "Paid from");
  }
  get PaymentInfoFetchError() {
    return getString("PAYENT_INFO_FETCH_ERROR", "Fetching payment info failed.");
  }
  get MakePaymentError() {
    return getString("MAKE_PAYMENT_ERROR", "Making payment failed.");
  }
  get DeletePaymentMethodError() {
    return getString("DELETE_PAYMENT_METHOD_ERROR", "Deleting payment method failed.");
  }
  get BankAccount() {
    return getString("BANK_ACCOUNT", "Bank account");
  }
  get CreditCard() {
    return getString("CREDIT_CARD", "Credit card");
  }
  get ACH() {
    return getString("ACH", "ACH");
  }
  get Expired() {
    return getString("EXPIRED", "Expired");
  }
  get GoToBill() {
    return getString("GO_TO_BILL", "Go to bill");
  }
  get GoToInvoice() {
    return getString("GO_TO_INVOICE", "Go to invoice");
  }
  get AppliedToInvoice() {
    return getString("APPLIED_TO_INVOICE", "Applied to invoice");
  }
  TotalInvoicedIn(year) {
    return formatString(getString("TOTAL_INVOICED_IN", "Total invoiced in {year}"), { year });
  }
  TotalPaidIn(year) {
    return formatString(getString("TOTAL_PAID_IN", "Total paid in {year}"), { year });
  }
  get TotalPaidHelperText() {
    return getString(
      "TOTAL_PAID_HELPER_TEXT",
      "Your total amount paid includes any pending and unapplied payments you have made."
    );
  }
  GoToResource(resource) {
    return formatString(getString("GO_TO_RESOURCE", "Go to {resource}"), {
      resource
    });
  }

  get InvoicesSelectedForMessage() {
    return getString("INVOICES_SELECTED_FOR_MESSAGE", "Invoices selected for message");
  }
  InvoicesSelectedForPayment(num) {
    return formatString(getString("INVOICES_SELECTED_FOR_PAYMENT", "Invoices selected for payment ({num})"), { num });
  }
  TotalPaymentAmount(num) {
    return formatString(getString("TOTAL_PAYMENT_AMOUNT", "Total payment amount: {num}"), { num });
  }

  get PayFrom() {
    return getString("PAY_FROM", "Pay from");
  }
  get MakePayment() {
    return getString("MAKE_PAYMENT", "Make a payment");
  }
  get EditPaymentMethod() {
    return getString("EDIT_PAYMENT_METHOD", "Edit payment method");
  }
  get AddNewPaymentMethod() {
    return getString("ADD_NEW_PAYMENT_METHOD", "Add a new payment method");
  }
  get ManagePaymentMethods() {
    return getString("MANAGE_PAYMENT_METHODS", "Manage payment methods");
  }
  get ViewTransactions() {
    return getString("VIEW_TRANSACTIONS", "View transaction history");
  }

  get InvoiceArrived() {
    return getString("INVOICE_ARRIVED", "New invoice has arrived");
  }
  ResourceCreated(resource) {
    return formatString(getString("RESOURCE_CREATED", "{resource} created!"), {
      resource
    });
  }
  InvoiceImported(resource) {
    return formatString(getString("RESOURCE_IMPORTED", "{resource} imported into your accounting package!"), {
      resource
    });
  }
  AutoEntryMessage(resource, id, erp) {
    return formatString(getString("AUTO_ENTRY_MESSAGE", "{resource} {id} was automatically entered into {erp}"), {
      resource,
      id,
      erp
    });
  }

  get AccountsPayableAbbr() {
    return getString("ACCOUNTS_PAYABLE_ABBR", "AP");
  }
  get AccountsReceivableAbbr() {
    return getString("ACCOUNTS_RECEIVABLE_ABBR", "AR");
  }
  get AccountsPayable() {
    return getString("ACCOUNTS_PAYABLE", "Accounts Payable");
  }
  get AccountsReceivable() {
    return getString("ACCOUNTS_RECEIVABLE", "Accounts Receivable");
  }

  get Payables() {
    return getString("PAYABLES", "Payables");
  }
  get Receivables() {
    return getString("RECEIVABLES", "Receivables");
  }
  get SignUp_BodyTextStep5() {
    return getString("BODY_TEXT_STEP_5", "Congratulations, you have successfully created your account.");
  }
  get Enter() {
    return getString("ENTER", "Enter");
  }
  get Lockstep() {
    return getString("LOCKSTEP", "Lockstep");
  }
  get SignUp_BodyTextStep4() {
    return getString("BODY_TEXT_STEP_4", "Congratulations, your account has been successfully connected!");
  }
  get Previous() {
    return getString("PREVIOUS", "Previous");
  }
  get Next() {
    return getString("NEXT", "Next");
  }
  get Step() {
    return getString("STEP", "Step");
  }
  get Loading() {
    return getString("LOADING", "Loading");
  }
  get LoadingAccounts() {
    return getString("LOADING_ACCOUNTS", "Loading Accounts");
  }

  get Newest() {
    return getString("NEWEST", "Newest");
  }
  get Features() {
    return getString("FEATURES", "Features");
  }
  get ExclamationMark() {
    return getString("!", "!");
  }
  get SelectCompany_Title() {
    return getString("SELECT_COMPANY_TITLE", "Select company");
  }
  get SelectCompany_ChooseCompanyBlurb() {
    return getString("CHOOSE_COMPANY_BLURB", "Choose the company you would like to work with:");
  }
  get SelectCompany_FooterBlurb() {
    return getString("FOOTER_BLURB", "We've just added the ability to X and X. Click here to learn more...");
  }

  get DeliveredByLockstep() {
    return getString("DELIVERED_BY_LOCKSTEP", "Delivered by Lockstep Network");
  }

  get OtherActions() {
    return getString("OTHER_ACTIONS", "Other Actions");
  }
  get Actions() {
    return getString("ACTIONS", "Actions");
  }
  get ReAssign() {
    return getString("REASSIGN", "Re-assign");
  }
  get Assign() {
    return getString("ASSIGN", "Assign");
  }
  get YouAssigned() {
    return getString("YOU_ASSIGNED", "You assigned.");
  }
  get Status() {
    return getString("STATUS", "Status");
  }
  YouChangedStatus(status) {
    return formatString(getString("YOU_CHANGED_STATUS", "You changed status to {status}."), { status });
  }
  get StartNew() {
    return getString("START_NEW", "+ Start new...");
  }
  get CreateNew() {
    return getString("CREATE_NEW", "+ Create new...");
  }
  get Compose() {
    return getString("COMPOSE", "Compose");
  }
  get YouSentAStatement() {
    return getString("YOU_SENT_A_STATEMENT", "You sent a statement.");
  }
  get YouCreatedATask() {
    return getString("YOU_CREATED_A_TASK", "You created a task.");
  }
  get YouSentNewMail() {
    return getString("YOU_SENT_NEW_MAIL", "You sent new mail.");
  }
  get YouSentNewMailClosed() {
    return getString("YOU_SENT_NEW_MAIL_CLOSED", "You sent new mail and closed the conversation.");
  }
  get YouSentNewMailWaitForResponse() {
    return getString("YOU_SENT_NEW_MAIL_WAIT_FOR_RESPONSE", "You sent new mail and are waiting for a response.");
  }
  get Reply() {
    return getString("REPLY", "Reply");
  }
  get YouReplied() {
    return getString("YOU_REPLIED", "You replied.");
  }
  get YouRepliedClosed() {
    return getString("YOU_REPLIED_CLOSED", "You replied and closed the conversation.");
  }
  get YouRepliedWaitForResponse() {
    return getString("YOU_REPLIED_WAIT_FOR_RESPONSE", "You replied and are waiting for a response.");
  }
  get SentButNotClosed() {
    return getString("SENT_BUT_NOT_CLOSED", "Your message was sent, but the conversation was not closed.");
  }
  get SentButNotWaitingForResponse() {
    return getString(
      "SENT_BUT_NOT_WAITING_FOR_RESPONSE",
      "Your message was sent, but the conversation was not marked as waiting for response."
    );
  }
  get ReplyAll() {
    return getString("REPLY_ALL", "Reply All");
  }
  get ForwardACopy() {
    return getString("FORWARD_A_COPY", "Forward a Copy");
  }
  get Forward() {
    return getString("FORWARD", "Forward");
  }
  get ForwardAsNewConversation() {
    return getString("FORWARD_AS_NEW_CONVERSATION", "Forward as a New Conversation");
  }
  get YouForwarded() {
    return getString("YOU_FORWARDED", "You forwarded.");
  }
  get YouForwardedClosed() {
    return getString("YOU_FORWARDED_CLOSED", "You forwarded and closed the conversation.");
  }
  RespondedToForward(responder) {
    return formatString(
      getString("RESPONDED_TO_FORWARD", "{responder} <span class='forward-color'>responded to forward.</span>"),
      { responder }
    );
  }
  get AddNote() {
    return getString("ADD_NOTE", "Add Note");
  }
  get YouCreatedNote() {
    return getString("YOU_CREATED_NOTE", "You created a note.");
  }
  get YouCreatedNoteClosed() {
    return getString("YOU_CREATED_NOTE_CLOSED", "You created a note and closed the conversation.");
  }
  get Title() {
    return getString("TITLE", "Title");
  }
  get Subject() {
    return getString("SUBJECT", "Subject");
  }
  get NoSubject() {
    return getString("NO_SUBJECT", "(No subject)");
  }
  get Partner() {
    return getString("PARTNER", "Partner");
  }
  get Age() {
    return getString("AGE", "Age");
  }
  get Ago() {
    return getString("AGO", "Ago");
  }
  get WaitingFor() {
    return getString("WAITING_FOR", "Waiting for");
  }
  get Text() {
    return getString("TEXT", "text");
  }
  get HTML() {
    return getString("HTML", "html");
  }
  get ShowAsText() {
    return getString("SHOW_AS_TEXT", "Show email as text");
  }
  get ShowAsHtml() {
    return getString("SHOW_AS_HTML", "Show email as HTML");
  }

  get From() {
    return getString("FROM", "From");
  }
  get To() {
    return getString("TO", "To");
  }
  get CC() {
    return getString("CC", "CC");
  }
  get Date() {
    return getString("DATE", "Date");
  }
  get Item() {
    return getString("ITEM", "Item");
  }
  get Amount() {
    return getString("AMOUNT", "Amount:");
  }
  get AmountUSD() {
    return getString("AMOUNT_USD", "Amount USD");
  }

  get RegCompanyExistsHeader() {
    return getString("REG_COMPANY_EXISTS_HEADER", "Access Requested");
  }
  get RegCompanyExistsBlurb1() {
    return getString("REG_COMPANY_EXISTS_BLURB1", "Your company already exists in Lockstep");
  }
  get RegCompanyExistsBlurb2() {
    return getString(
      "REG_COMPANY_EXISTS_BLURB2",
      "We've sent the request to join to your Lockstep company administrator via your company e-mail address. They can add you to your companies Lockstep account."
    );
  }
  get RegCompanyExistsBlurb3() {
    return getString(
      "REG_COMPANY_EXISTS_BLURB3",
      "Need Help? Dial (800) 720-3580 to speak with a product specialist or email help@lockstep.network"
    );
  }

  get RegUserExistsHeader() {
    return getString("REG_USER_EXISTS_HEADER", "User Accounts Exists");
  }
  get RegUserExistsBlurb1() {
    return getString("REG_USER_EXISTS_BLURB1", "You already have an account on Lockstep");
  }
  get RegUserExistsBlurb2() {
    return getString(
      "REG_USER_EXISTS_BLURB2",
      "Need Help? Dial (800) 720-3580 to speak with a product specialist or email help@Lockstep.network"
    );
  }

  get RegCompanyInfoHeader() {
    return getString("REG_COMPANY_INFO_HEADER", "Enter Company Information and Setup Lockstep Password");
  }

  get RegVerifyEmailHeader() {
    return getString("REG_VERIFIY_EMAIL_HEADER", "Verify E-mail");
  }
  get RegVerifyEmailBlurb1() {
    return getString("REG_VERIFIY_EMAIL_BLURB1", "Your verification link should be arriving shortly in your inbox");
  }
  get RegVerifyEmailBlurb2() {
    return getString(
      "REG_VERIFIY_EMAIL_BLURB2",
      "We've sent your verification link to the email address you used to sign up. Please be aware that"
    );
  }
  get RegVerifyEmailBlurb3() {
    return getString("REG_VERIFIY_EMAIL_BLURB3", "it can take up to 5 minutes to receive the email.");
  }
  get RegVerifyEmailBlurb4() {
    return getString(
      "REG_VERIFIY_EMAIL_BLURB4",
      "If you don't see our email within 5 minutes, please check your spam or junk folders. Or resend using this link"
    );
  }
  get RegVerifyEmailBlurb5() {
    return getString("REG_VERIFIY_EMAIL_BLURB5", "Resend Verification E-mail");
  }
  get RegVerifyEmailBlurb6() {
    return getString(
      "REG_VERIFIY_EMAIL_BLURB6",
      "Need help? Dial (800) 720-3580 to speak with a product specialist or"
    );
  }
  get RegVerifyEmailBlurb7() {
    return getString("REG_VERIFIY_EMAIL_BLURB7", "email help@Lockstep.network");
  }

  get RegBackToLogin() {
    return getString("REG_BACK_TO_LOGIN", "Back to login?");
  }

  get PasswordsNeedToMatchError() {
    return getString(
      "PASSWORDS_NEED_TO_MATCH_ERRORS",
      "The passwords you entered do not match.  Please make sure they are the same."
    );
  }
  get BlankPasswordError() {
    return getString("BLANK_PASSWORD_ERROR", "Your password cannot be blank.");
  }
  get AllEntriesEmptyError() {
    return getString(
      "ALL_ENTRIES_EMPTY_ERRORS",
      "All of the entry fields must contain a value.  Please fill in the empty fields."
    );
  }
  get MustHaveCorrectPassPatternError() {
    return getString(
      "MUST_HAVE_CORRECT_PASS_PATTERN_ERROR",
      "Password must be 8 or more characters and contain at least two of the following: an uppercase letter, a lowercase letter, a number or a special character."
    );
  }
  PasswordIsWeakError(score) {
    return formatString(
      getString("PASSWORD_IS_TOO_WEAK", "The password you entered is too weak. Please enter a stronger password."),
      { score }
    );
  }
  get ContactInfo() {
    return getString("CONTACT_INFO", "Contact Info");
  }
  get ContactInformation() {
    return getString("CONTACT_INFORMATION", "Contact Information");
  }
  get AddressInformation() {
    return getString("ADDRESS_INFORMATION", "Address Information");
  }
  get Address() {
    return getString("ADDRESS", "Address");
  }
  get City() {
    return getString("CITY", "City");
  }
  get Zip() {
    return getString("ZIP", "Zip");
  }
  get State() {
    return getString("STATE", "State");
  }
  get Country() {
    return getString("COUNTRY", "Country");
  }
  get Website() {
    return getString("WEBSITE", "Website");
  }
  get Accounting() {
    return getString("ACCOUNTING", "Accounting");
  }
  get Personal() {
    return getString("PERSONAL", "Personal");
  }
  get Password() {
    return getString("PASSWORD", "Password");
  }
  get Confirm() {
    return getString("CONFIRM", "Confirm");
  }
  get Email() {
    return getString("EMAIL", "Email");
  }
  get SetupHeader() {
    return getString("SETUP_HEADER", "Getting started with Lockstep!");
  }
  get SetupSubHeader() {
    return getString(
      "SETUP_SUB_HEADER",
      "Start collaborating with your customers and vendors, by completing the tasks below."
    );
  }
  get SetupBlurb1() {
    return getString(
      "SETUP_BLURB1",
      "Take a video tour for an introduction into the Lockstep Network -- showing how buyers and sellers collaborate for a more automated and trusted supply chain."
    );
  }
  get SetupBlurb2() {
    return getString("SETUP_BLURB2", "Quick start checklist");
  }
  get SetupBlurb3() {
    return getString("SETUP_BLURB3", "Connect E-mail to Shared Ledger");
  }
  get SetupBlurb4() {
    return getString(
      "SETUP_BLURB4",
      "Configure your external e-mail to securely connect your e-mail communication into Ledger on Lockstep Network."
    );
  }
  get ConnectYourERPToLockstep() {
    return getString("CONNECT_YOUR_ERP_TO_LOCKSTEP", "Connect your Accounting System to Lockstep");
  }
  get SetupBlurb6() {
    return getString(
      "SETUP_BLURB6",
      "Streamline all accounting communication by connecting your accounting system to Lockstep."
    );
  }
  get Introduction() {
    return getString("INTRODUCTION", "Introduction");
  }
  get Watch() {
    return getString("WATCH", "Watch");
  }
  get Video() {
    return getString("VIDEO", "Video");
  }
  get DefaultConfiguration() {
    return getString("DEFAULT_CONFIGURATION", "Default Configuration");
  }
  get RegistrationSettings() {
    return getString("REGISTRATION_SETTINGS", "Registration Settings");
  }
  get RegisterStepSucceeded() {
    return getString(
      "REGISTER_STEP_SUCCEEDED",
      "Your account is succesfully connected. Next complete the configuration settings."
    );
  }
  ConnectorRegistrationSuccess(connector) {
    return formatString(getString("CONNECTOR_REGISTRATION_SUCCESS", "SUCCESS: You are now connected to {connector}."), {
      connector
    });
  }
  get ConnectorRegistrationError() {
    return getString(
      "CONNECTOR_REGISTRATION_ERROR",
      "There was a problem registering your accounting package. Please confirm these values."
    );
  }
  get RegistrationNotComplete() {
    return getString(
      "REGISTRATION_NOT_COMPLETE",
      "WARNING: Your package is not fully configured. Please edit configuration settings."
    );
  }
  get Configure() {
    return getString("CONFIGURE", "Configure");
  }
  ConfigureConnectorSettings(connector) {
    return formatString(getString("CONFIGURE_CONNECTOR_SETTINGS", "Configure {connector} Settings"), { connector });
  }
  get ConfigureMessage() {
    return getString(
      "CONFIGURE_MESSAGE",
      "If correct values aren't listed here, then return to your registered accounting package to edit them."
    );
  }
  EditPackageSettings(connector) {
    return formatString(getString("EDIT_PACKAGE_SETTINGS", "Edit {connector} Settings"), { connector });
  }
  get UpdateDefaultConfiguration() {
    return getString("UPDATE_DEFAULT_CONFIGURATION", "Update default configuration settings");
  }
  get UpdateRegistration() {
    return getString("UPDATE_REGISTRATION", "Update registration values");
  }
  get SyncingMessage() {
    return getString("SYNCING_MESSAGE", "Working magic, loading configuration details...");
  }
  get LoadingOptions() {
    return getString("LOADING_OPTIONS", "Loading options...");
  }
  get ConnectAcctSystem() {
    return getString("CONNECT_ACCT_SYSTEM", "Connect Acct. System");
  }
  get ConnectAnother() {
    return getString("CONNECT_ANOTHER", "Connect Another");
  }

  get Description() {
    return getString("DESCRIPTION", "Description");
  }

  get DescriptionExample() {
    return getString("DESCRIPTION_EXAMPLE", "My Companies Account Payable");
  }
  get EmailExample() {
    return getString("EMAIL_EXAMPLE", "@mycompany.com");
  }

  get Setup() {
    return getString("SETUP", "Setup");
  }
  get Complete() {
    return getString("COMPLETE", "Complete");
  }

  get SetupBarGetStartedBlurb() {
    return getString("SETUP_BAR_GET_STARTED_BLURB", "Lockstep quick start guide");
  }
  get SetupInboxToConnect() {
    return getString("SETUP_INBOX_TO_CONNECT", "Select Inbox to connect");
  }

  get SetupConnectEmailBlurb1AR() {
    return getString(
      "SETUP_CONNECT_EMAIL_BLURB_1_AR",
      "Enter your e-mail address that you use for Accounts Receivable."
    );
  }
  get SetupConnectEmailBlurb1AP() {
    return getString("SETUP_CONNECT_EMAIL_BLURB_1_AP", "Enter your e-mail address that you use for Accounts Payable.");
  }

  get SetupForwardingEmailHeaderAR() {
    return getString("SETUP_FORWARDING_EMAIL_HEADER_AR", "Connect your Accounts Receivable Inbox with Lockstep");
  }
  get SetupForwardingEmailHeaderAP() {
    return getString("SETUP_FORWARDING_EMAIL_HEADER_AP", "Connect your Accounts Payable Inbox with Lockstep");
  }
  SetupForwardingEmailForwardTo(email) {
    return formatString(
      getString("SETUP_FORWARDING_EMAIL_ForwardTo", "Forward a copy of all {email} to this email address."),
      { email: email }
    );
  }
  get SetupForwardingEmailNeedHelpLink() {
    return getString(
      "SETUP_FORWARDING_EMAIL_NEED_HELP_LINK",
      "Need help? Try these step-by-step instructions for configuration connection"
    );
  }
  get SetupForwardingEmailSendPreEmailLink() {
    return getString("SETUP_FORWARDING_EMAIL_SEND_PRE_EMAIL_LINK", "Send this pre-written e-mail");
  }

  get SetupConnectionVerifiedHeaderAR() {
    return getString("SETUP_CONNECTION_VERIFIED_HEADER_AR", "Connect your Accounts Receivable Inbox with Lockstep");
  }
  get SetupConnectionVerifiedHeaderAP() {
    return getString("SETUP_CONNECTION_VERIFIED_HEADER_AP", "Connect your Accounts Payable Inbox with Lockstep");
  }
  SetupConnectionVerifiedSentToForwardTo(email) {
    return formatString(
      getString(
        "SETUP_CONNECTION_VERIFIED_BLURB_1_AR",
        "Copies of all e-mail sent to {email} will be forwarded to this address:"
      ),
      { email: email }
    );
  }
  get SetupConnectionVerifiedBlurb1AP() {
    return getString(
      "SETUP_CONNECTION_VERIFIED_BLURB_1_AP",
      "Copies of all e-mail sent to ap@joule-tech.com will be forwarded to this address:"
    );
  }
  get SetupConnectionVerified() {
    return getString("SETUP_CONNECTION_VERIFIED", "Connection Verified");
  }
  get SetupConnectionVerifiedContinueWithSetup() {
    return getString("SETUP_CONNECTION_VERIFIED_CONTINUE_WITH_SETUP", "Continue with Setup");
  }
  SentApprovalTask(requester, approver) {
    return formatString(
      getString(
        "SENT_APPROVAL_TASK",
        "{requester} <span class='approval-request-color'>sent an approval task to</span> {approver}"
      ),
      { requester, approver }
    );
  }
  SentResponseTask(requester, approver) {
    return formatString(
      getString(
        "SENT_APPROVAL_TASK",
        "{requester} <span class='forward-color'>sent a response task to</span> {approver}"
      ),
      { requester, approver }
    );
  }
  ApprovalRequestText(requester, approver) {
    return formatString(
      getString(
        "APPROVAL_REQUEST_TEXT",
        "{requester} <span class='approval-request-color'>sent an approval request to</span> {approver}"
      ),
      { requester, approver }
    );
  }
  ApprovalGrantedText(approver) {
    return formatString(
      getString("APPROVAL_GRANTED_TEXT", "{approver} <span class='approval-color'>approved this transaction<span>"),
      { approver }
    );
  }
  ApprovalRejectedText(approver) {
    return formatString(
      getString("APPROVAL_REJECTED_TEXT", "{approver} <span class='rejection-color'>rejected this transaction<span>"),
      { approver }
    );
  }
  get RequestApproved() {
    return getString("REQUEST_APPROVED", "Request has been succesfully approved.");
  }
  get RequestRejected() {
    return getString("REQUEST_REJECTED", "Request has been succesfully rejected.");
  }
  get Respond() {
    return getString("RESPOND", "Respond");
  }
  RequestedResponseText(requester, requestee) {
    return formatString(
      getString(
        "REQUESTED_RESPONSE_TEXT",
        "{requester} <span class='forward-color'>requested a response from</span> {requestee}"
      ),
      { requester, requestee }
    );
  }
  PrivateNoteText(name) {
    return formatString(
      getString("PRIVATE_NOTE_TEXT", "{name} <span class='note-color'>created a private note</span"),
      { name }
    );
  }
  EmailResponseText(name) {
    return formatString(
      getString("EMAIL_RESPONSE_TEXT", "{name} <span class='response-color'>responded to the conversation</span> "),
      { name }
    );
  }
  EmailFirstInConversationText(name) {
    return formatString(
      getString(
        "EMAIL_FIRST_IN_CONVERSATION_TEXT",
        "{name} <span class='response-color'>started the conversation</span> "
      ),
      { name }
    );
  }
  ReassignedByTo(by, to, isTask) {
    const activityType = isTask ? "task" : "conversation";
    return formatString(getString("REASSIGNED_BY_TO", "{by} assigned the {activityType} to {to}"), {
      by,
      to,
      activityType
    });
  }
  StatusChangedByTo(by, to) {
    return formatString(getString("STATUS_CHANGED_BY_TO", "{by} set the conversation status to {to}"), { by, to });
  }
  ForwardedByTo(by, to) {
    return formatString(getString("FORWARDED_BY_TO", "{by} forwarded a copy of this activity to {to}"), { by, to });
  }
  CopyForwardedBy(by) {
    return formatString(
      getString(
        "COPY_FORWARDED_BY",
        "{by} <span class='forward-color'>forwarded this conversation as a new conversation</span>"
      ),
      { by }
    );
  }
  ForwardingVisibilityMessage(perspective) {
    return formatString(
      getString(
        "FORWARDING_VISIBILITY_MESSAGE",
        "This thread will not be visible to the {perspective} when you reply."
      ),
      { perspective }
    );
  }
  get PrivateDraftText() {
    return getString("PRIVATE_DRAFT_TEXT", "You <span class='draft-color'>created a draft</span>");
  }
  get DropFilesHere() {
    return getString("DROP_FILES_HERE", "Drop files here.");
  }
  get DropImageFilesHere() {
    return getString("DROP_IMAGE_FILES_HERE", "Drop image files of type .jpg, .png, or .gif here.");
  }
  get AttachFile() {
    return getString("ATTACH_FILE", "Attach file");
  }
  get InsertImage() {
    return getString("INSERT_IMAGE", "Insert image");
  }
  get ImageOnlyUploadWarning() {
    return getString(
      "IMAGE_ONLY_UPLOAD_WARNING",
      "Only files of type .jpg, .png, and .gif can be added. The following files were rejected:"
    );
  }
  get Attach() {
    return getString("ATTACH", "Attach");
  }
  get ChooseLibraryFiles() {
    return getString("CHOOSE_LIBRARY_FILES", "Choose files from your library.");
  }
  get NothingToShowHere() {
    return getString("NOTHING_TO_SHOW_HERE", "Nothing to show here.");
  }
  get NoCompanyDocuments() {
    return getString("NO_COMPANY_DOCUMENTS", "Nothing here yet. First upload files from your computer.");
  }
  get NoFilesSelected() {
    return getString("NO_FILES_SELECTED", "No files selected");
  }
  get AttachFromComputer() {
    return getString("ATTACH_FROM_COMPUTER", "Attach files from computer");
  }
  get AddFilesToSharedDocuments() {
    return getString("ADD_FILES_TO_SHARED_DOCUMENTS", "Add files to Shared Documents");
  }
  get UploadFromComputer() {
    return getString("UPLOAD_FROM_COMPUTER", "Upload from computer");
  }
  get UploadAttachment() {
    return getString("UPLOAD_ATTACHMENT", "Upload attachment");
  }
  get UploadingAttachments() {
    return getString("UPLOADING_ATTACHMENTS", "Uploading attachments...");
  }
  get SelectUploadFile() {
    return getString("SELECT_UPLOAD_FILE", "Select files to upload");
  }

  get Copy() {
    return getString("COPY", "Copy");
  }

  get CopyAddress() {
    return getString("COPYADDRESS", "Copy address");
  }

  get Or() {
    return getString("OR", "Or");
  }

  get Verify() {
    return getString("VERIFY", "Verify");
  }
  get Forwarding() {
    return getString("FORWARDING", "Forwarding");
  }

  get Unassigned() {
    return getString("UNASSIGNED", "Unassigned");
  }
  get Assigned() {
    return getString("ASSIGNED", "Assigned");
  }
  get Assignee() {
    return getString("ASSIGNEE", "Assignee");
  }
  get AssignTaskTo() {
    return getString("ASSIGN_TASK_TO", "Assign task to");
  }
  get ChooseTeamMember() {
    return getString("CHOOSE_TEAM_MEMBER", "Choose team member");
  }
  AutoAssignMessage(partnerType) {
    return formatString(
      getString("AUTO_ASSIGN_MESSAGE", "Assign a team member to receive messages for this {partnerType} by default."),
      { partnerType }
    );
  }
  get ChooseDefaultApprover() {
    return getString("CHOOSE_DEFAULT_APPROVER", "Choose default approver.");
  }
  get Messages() {
    return getString("MESSAGES", "Messages");
  }
  get AssignMessagesTo() {
    return getString("ASSIGN_MESSAGES_TO", "Assign messages to:");
  }
  get SendApprovalRequestsTo() {
    return getString("SEND_APPROVAL_REQUESTS_TO", "Send approval requests to:");
  }
  get Mine() {
    return getString("MINE", "Mine");
  }
  get OutForApproval() {
    return getString("OUT_FOR_APPROVAL", "Out for Approval");
  }
  get Closed() {
    return getString("CLOSED", "Closed");
  }
  get Completed() {
    return getString("COMPLETED", "Completed");
  }
  get Spam() {
    return getString("SPAM", "Spam");
  }
  get MarkAsSpam() {
    return getString("MARK_AS_SPAM", "Mark as Spam");
  }
  get MarkSenderAsSpam() {
    return getString("MARK_SENDER_AS_SPAM", "Mark Sender as Spam");
  }
  get MessageSent() {
    return getString("MESSAGE_SENT", "Message sent");
  }
  get MarkMessageAsSpam() {
    return getString("MARK_MESSAGE_AS_SPAM", "Mark Message as Spam");
  }
  get MessageMarkedAsSpam() {
    return getString("MESSAGE_MARKED_AS_SPAM", "Message marked as spam");
  }
  get SpamMapping() {
    return getString("SPAM_MAPPING", "Spam Mapping");
  }
  get SpamSettingsDescription() {
    return getString(
      "SPAM_SETTINGS_DESCRIPTION",
      "Mail from these domains or email addresses have been identified as Spam. Change this list to change what is considered spam."
    );
  }
  get AddSpamDescription() {
    return getString("ADD_SPAM_DESCRIPTION", "New mail coming from this domain or address will go to Spam.");
  }
  get AddSpamPlaceholder() {
    return getString("ADD_SPAM_PLACEHOLDER", "mycompany.com, accounting@mycompany.com");
  }
  ConfirmDeleteSpamAddress(address) {
    return formatString(
      getString("CONFIRM_DELETE_SPAM_ADDRESS", "Are you sure you want to remove <b>{address}</b> from the spam list?"),
      { address }
    );
  }

  get Approved() {
    return getString("APPROVED", "Approved");
  }
  get Rejected() {
    return getString("REJECTED", "Rejected");
  }
  get Reject() {
    return getString("REJECT", "Reject");
  }
  get OriginalDate() {
    return getString("ORIGINAL_DATE", "Original Date");
  }

  get Anyone() {
    return getString("ANYONE", "Anyone");
  }
  get NoOne() {
    return getString("N0_ONE", "No one");
  }
  get Me() {
    return getString("ME", "Me");
  }
  get You() {
    return getString("YOU", "You");
  }
  get Yourself() {
    return getString("YOURSELF", "Yourself");
  }

  get ApprovedInERP() {
    return getString("APPROVED_IN_ERP", "Approved In ERP");
  }
  get Approval() {
    return getString("APPROVAL", "Approval");
  }
  get Approvals() {
    return getString("APPROVALS", "Approvals");
  }
  get New() {
    return getString("NEW", "New");
  }
  get Note() {
    return getString("NOTE", "Note");
  }
  get ContactAlreadyExistsError() {
    return getString(
      "CONTACT_ALREADY_EXISTS_ERROR",
      "This contact email already exists. If it does not exist for this perspective and you would like to add it, please create a new contact."
    );
  }
  get ApprovalRequest() {
    return getString("APPROVAL_REQUEST", "Approval Request");
  }
  get Approve() {
    return getString("APPROVE", "Approve");
  }
  get YouDeletedConversation() {
    return getString("YOU_DELETED_CONVERSATION", "You deleted the conversation.");
  }
  get YouDeletedTask() {
    return getString("YOU_DELETED_TASK", "You deleted the task.");
  }
  get YouApproved() {
    return getString("YOU_APPROVED", "You approved.");
  }
  get YouResponded() {
    return getString("YOU_RESPONDED", "You responded.");
  }
  get YouRejected() {
    return getString("YOU_REJECTED", "You rejected.");
  }
  get YouRequestedApproval() {
    return getString("YOU_REQUESTED_APPROVAL", "You sent approval request.");
  }
  get Approver() {
    return getString("APPROVER", "Approver");
  }
  get Approvers() {
    return getString("APPROVERS", "Approvers");
  }
  get AddApprover() {
    return getString("ADD_APPROVER", "Add Approver");
  }
  get EditApprover() {
    return getString("EDIT_APPROVER", "Edit Approver");
  }
  get AddingApprover() {
    return getString("ADDING_APPROVER", "Adding contact as approver...");
  }
  get ApprovalTask() {
    return getString("APPROVAL_TASK", "Approval Task: ");
  }
  ApprovalReassignedTo(assignee) {
    return formatString(getString("APPROVAL_ASSIGNED_TO", "Approval task for conversation reassigned to {assignee}"), {
      assignee
    });
  }
  ResponseRequestReassignedTo(assignee) {
    return formatString(
      getString("RESPONSE_REQUEST_ASSIGNED_TO", "Response task for conversation reassigned to {assignee}"),
      { assignee }
    );
  }
  AssignedApprovalTask(poster, assignee) {
    return formatString(getString("ASSIGNED_APPROVAL_TASK", "{poster} assigned this approval task to {assignee}."), {
      poster,
      assignee
    });
  }
  AssignedResponseTask(poster, assignee) {
    return formatString(getString("ASSIGNED_RESPONSE_TASK", "{poster} assigned this response task to {assignee}."), {
      poster,
      assignee
    });
  }
  get ForApproval() {
    return getString("FOR_APPROVAL", "For approval");
  }
  get SendForApproval() {
    return getString("SEND_FOR_APPROVAL", "Send for Approval");
  }
  get MarkAsApproved() {
    return getString("MARK_AS_APPROVED", "Mark as Approved");
  }
  get MarkAsRejected() {
    return getString("MARK_AS_REJECTED", "Mark as Rejected");
  }
  get ImportContactList() {
    return getString("IMPORT_CONTACT_LIST", "Import contact list");
  }
  ConfirmDeleteContact(contact) {
    return formatString(
      getString("CONFIRM_DELETE_CONTACT", "Are you sure you want to remove <b>{contact}</b> as a contact?"),
      { contact }
    );
  }
  ConfirmDeleteApprover(approver) {
    return formatString(
      getString("CONFIRM_DELETE_APPROVER", "Are you sure you want to remove <b>{approver}</b> as an approver?"),
      { approver }
    );
  }

  get AddNewFile() {
    return getString("ADD_NEW_FILE", "Add new file");
  }
  get AddedBy() {
    return getString("ADDED_BY", "Added by");
  }
  get DateAdded() {
    return getString("DATE_ADDED", "Date added");
  }
  get RenameFile() {
    return getString("RENAME_FILE", "Rename the file");
  }
  get ReplaceFile() {
    return getString("REPLACE_THE_FILE", "Replace the file");
  }
  get SharedDocuments() {
    return getString("SHARED_DOCUMENTS", "Shared Documents");
  }
  ConfirmDeleteDocument(documentName) {
    return formatString(
      getString("CONFIRM_DELETE_DOCUMENT", "Are you sure you want to delete <b>{documentName}</b>?"),
      { documentName }
    );
  }

  get SelectPerspective() {
    return getString("SELECT_PERSPECTIVE", "Select Perspective");
  }
  get Perspective() {
    return getString("PERSPECTIVE", "Perspective");
  }
  get SavedReplies() {
    return getString("SAVED_REPLIES", "Saved Replies");
  }
  get InsertVariable() {
    return getString("INSERT_VARIABLE", "Insert variable...");
  }
  get UseTemplate() {
    return getString("USE_TEMPLATE", "Use template");
  }
  get Templates() {
    return getString("TEMPLATES", "Templates");
  }
  get Template() {
    return getString("TEMPLATE", "Template");
  }
  get CreateNewTemplate() {
    return getString("CREATE_NEW_TEMPLATE", "Create a new template");
  }
  get TemplateName() {
    return getString("TEMPLATE_NAME", "Template Name");
  }
  get SaveAsTemplate() {
    return getString("SAVE_AS_TEMPLATE", "Save as template...");
  }
  get SaveNewTemplate() {
    return getString("SAVE_NEW_TEMPLATE", "New template");
  }
  get TemplateNameAlreadyExists() {
    return getString(
      "TEMPLATE_NAME_ALREADY_EXISTS",
      "A template with this name already exits. Please rename this template."
    );
  }
  ConfirmDeleteTemplate(templateName) {
    return formatString(
      getString("CONFIRM_DELETE_TEMPLATE", "Are you sure you want to delete <b>{templateName}</b>?"),
      { templateName }
    );
  }
  get UnableToPopulateMessage() {
    return getString("UNABLE_TO_POPULATE_MESSAGE", "We weren't able to populate the following values:");
  }
  get PleaseFillManually() {
    return getString("PLEASE_FILL_MANUALLY", "Please fill them out manually before sending.");
  }
  get SaveAsDraft() {
    return getString("SAVE_AS_DRAFT", "Save draft");
  }
  get Body() {
    return getString("BODY", "Body");
  }

  get BackToEmailSettings() {
    return getString("BACK_TO_EMAIL_SETTINGS", "Back to Email Settings");
  }
  get CurrentEmailType() {
    return getString("CURRENT_EMAIL_TYPE", "Current Email Type");
  }
  get ThisAddressNeedsToBeValidated() {
    return getString("THIS_ADDRESS_NEEDS_TO_BE_VALIDATED", "This Address needs to be validated.");
  }
  get Why() {
    return getString("WHY", "Why");
  }
  get SMTPSetupBlurb1() {
    return getString("SMTP_SETUP_BLURB_1", "We'll send an email to check if your redirection is working.");
  }
  get SendEmail() {
    return getString("SEND_EMAIL", "Send Email");
  }
  get Set() {
    return getString("SET", "Set");
  }
  get CustomSMTP() {
    return getString("CUSTOM_SMTP", "Custom SMTP");
  }
  get SMTPSetupBlurb2() {
    return getString("SMTP_SETUP_BLURB_2", "This server will be used to send outgoing emails");
  }
  get SMTPSetupBlurb3() {
    return getString("SMTP_SETUP_BLURB_3", "Ensure that e-mail does not end up in spam folder");
  }
  get TestConnection() {
    return getString("TEST_CONNECTION", "Test Connection");
  }
  get Enable() {
    return getString("ENABLE", "Enable");
  }
  get Disable() {
    return getString("DISABLE", "Disable");
  }
  get SMTPConnectionFailed() {
    return getString("SMTP_CONNECTION_FAILED", "SMTP Connection Failed");
  }
  get SignUp() {
    return getString("SIGN_UP", "Sign Up");
  }
  get ConfirmPassword() {
    return getString("CONFIRM_PASSWORD", "Confirm Password");
  }
  get InviteSignInToJoinTheNetwork() {
    return getString("INVITE_SIGN_IN_TO_JOIN_NETWORK", "Sign in to join Lockstep Network");
  }
  get InviteSignInToJoinTheCompany() {
    return getString("INVITE_SIGN_IN_TO_JOIN_COMPANY", "Sign in to join the company");
  }
  InvitePolicyLinks(terms, use, privacy) {
    return formatString(getString("INVITE_POLICY_LINKS", "{terms}, {use} and {privacy}"), { terms, use, privacy });
  }

  get IAcceptPolicies() {
    return getString("I_ACCEPT_POLICIES", "I have read and accept the following policies:");
  }
  get PleaseAcceptPolicies() {
    return getString("PLEASE_ACCEPT_POLICIES", "Please accept policies");
  }
  get TermsOfService() {
    return getString("TERMS_OF_SERVICE", "Terms of Service");
  }
  get TermsOfServiceUrl() {
    return getString(
      "TERMS_OF_SERVICE_URL",
      "https://intercom.help/lockstep-network/lockstep-help-center/customer-terms-of-service"
    );
  }
  get AcceptableUse() {
    return getString("ACCEPTABLE_USE", "Acceptable Use");
  }
  get AcceptableUseUrl() {
    return getString(
      "ACCEPTABLE_USE_URL",
      "https://intercom.help/lockstep-network/lockstep-help-center/acceptable-use-policy"
    );
  }
  get Privacy() {
    return getString("PRIVACY", "Privacy");
  }
  get PrivacyUrl() {
    return getString("PRIVACY_URL", "https://intercom.help/lockstep-network/lockstep-help-center/privacy-policy");
  }

  InvitedWeNeedMoreDetails(email) {
    return formatString(getString("INVITED_WE_NEED_MORE_DETAILS", "Create a password for {email}"), { email: email });
  }

  ConfirmRevokeInvite(email) {
    return formatString(getString("CONFIRM_REVOKE_INVITE", "Are you sure you want to revoke the invite for {email}?"), {
      email
    });
  }
  get Invite() {
    return getString("INVITE", "Invite");
  }
  get Pending() {
    return getString("PENDING", "Pending");
  }
  get UnappliedAmount() {
    return getString("UNAPPLIED_AMOUNT", "Unapplied Amount");
  }
  get PendingPayment() {
    return getString("PENDING_PAYMENT", "Pending payment");
  }
  get Accepted() {
    return getString("ACCEPTED", "Accepted");
  }
  get Message() {
    return getString("MESSAGE", "Message");
  }
  get InviteToCompanyMessage() {
    return getString(
      "INVITE_TO_COMPANY_MESSAGE",
      `Welcome to the team!

We do all of our work via Lockstep.  Log in or create your user profile and come join us.`
    );
  }

  get ApEmail() {
    return getString("AP_EMAIL", "AP department e-mail");
  }
  get ArEmail() {
    return getString("AR_EMAIL", "AR department e-mail");
  }
  get ApPhone() {
    return getString("AP_PHONE", "AP department phone");
  }
  get ArPhone() {
    return getString("AR_PHONE", "AR department phone");
  }
  get ArFax() {
    return getString("AR_FAX", "AR department fax (Optional)");
  }
  get ApFax() {
    return getString("AP_FAX", "AP department fax (Optional)");
  }
  get AP() {
    return getString("AP", "AP");
  }
  get AR() {
    return getString("AR", "AR");
  }
  get URL() {
    return getString("URL", "URL");
  }

  get EntityType() {
    return getString("EntityType", "Entity type");
  }
  get TIN() {
    return getString("TIN", "TIN - Tax id");
  }

  get W9File() {
    return getString("W9_FILE", "If no TIN");
  }
  get W8BenFile() {
    return getString("W8_BEN_FILE", "W8 BEN File");
  }

  get AcceptProfileData() {
    return getString("ACCEPT_PROFILE_DATA", "Accept Profile Data");
  }

  get Username() {
    return getString("USERNAME", "Username");
  }
  get UsernameEmail() {
    return getString("USERNAME", "Username (email address)");
  }
  get BackToLogin() {
    return getString("BACK_TO_LOGIN", "Back to Login");
  }
  EnterNewPassword(username) {
    return formatString(getString("ENTER_NEW_PASSWORD", "Enter your new password for {username}"), { username });
  }
  get NewPassword() {
    return getString("NEW_PASSWORD", "New Password");
  }
  get ConfirmNewPassword() {
    return getString("CONFIRM_NEW_PASSWORD", "Confirm New Password");
  }
  get ChangePassword() {
    return getString("CHANGE_PASSWORD", "Change my password");
  }
  get UsernameEmail() {
    return getString("USERNAME_EMAIL", "Username/Email");
  }
  get CheckEmailResetPassword() {
    return getString(
      "CHECK_EMAIL_RESERT_PASSWORD",
      "Check your email for a secure link to reset your password. If you don't see our email, check your spam folder."
    );
  }
  get FindYourAccount() {
    return getString("FIND_YOUR_ACCOUNT", "First, let's find your account");
  }
  get FindAccount() {
    return getString("FIND_ACCOUNT", "Find account");
  }
  get ResendLink() {
    return getString("RESEND_LINK", "Resend Link");
  }
  get TryAnotherEmail() {
    return getString("TRY_ANOTHER_EMAIL", "Try another email");
  }
  get ResetPasswordSuccess() {
    return getString("RESET_PASSWORD_SUCCESS", "Password Reset Successful!");
  }
  get LinkExpired() {
    return getString("LINK_EXPIRED", "The link that you used is expired. Please try again.");
  }

  get RememberMeFor30Days() {
    return getString("REMEMBER_ME_FOR_30_DAYS", "Remember me for 30 days");
  }
  get ForgotPassword() {
    return getString("FORGOT_PASSWORD", "Forgot your password?");
  }
  get YouAlreadyHaveAnAccount() {
    return getString("YOU_ALREADY_HAVE_AN_ACCOUNT", "You already have an account with us, login!");
  }
  get LoginBlurb1() {
    return getString("LOGIN_BLURB_1", "Spend less time in email and more time on your books.");
  }
  get LoginBlurb2() {
    return getString(
      "LOGIN_BLURB_2",
      "Lockstep is an email solution designed for the way accounting professionals want to work."
    );
  }
  get DontHaveAnAccount() {
    return getString("DONT_HAVE_AN_ACCOUNT", "Don't have an account?");
  }

  get ForwardFromMailbox() {
    return getString("FORWARD_FROM_MAILBOX", "Forward from your shared mailbox");
  }
  get ConnectDirectlyToExchange() {
    return getString("CONNECT_DIRECTLY_TO_EXCHANGE", "Connect directly to your MS Exchange");
  }
  get ConnectDirectlyToGmail() {
    return getString("CONNECT_DIRECTLY_TO_GMAIL", "Connect directly to your Gmail");
  }
  get Sent() {
    return getString("SENT", "Sent");
  }
  get SendAMessage() {
    return getString("SEND_A_MESSAGE", "Send a message");
  }
  get SendingMessage() {
    return getString("SENDING_MESSAGE", "Sending message");
  }
  get SentMessage() {
    return getString("SENT_MESSAGE", "Message sent");
  }
  get SendMessageFailed() {
    return getString("SEND_MESSAGE_FAILED", "Sending the message failed");
  }
  get Archive() {
    return getString("ARCHIVE", "Archive");
  }
  get MoveToInbox() {
    return getString("MOVE_TO_INBOX", "Move to inbox");
  }
  InboxCount(count) {
    return formatString(getString("INBOX_COUNT", "Inbox ({count})"), { count });
  }
  get EmptyInboxActivities() {
    return getString("EMPTY_INBOX_ACTIVITIES", "You have no activities in this inbox.");
  }
  get EmptyInboxMessages() {
    return getString("EMPTY_INBOX_MESSAGES", "You have no messages in this inbox.");
  }
  get EmptyInboxDocuments() {
    return getString("EMPTY_INBOX_DOCUMENTS", "You have no documents in this inbox.");
  }
  get EmptyStatements() {
    return getString("EMPTY_STATEMENTS", "There are no statements to show.");
  }
  get EmptySequences() {
    return getString("EMPTY_SEQUENCES", "There are no sequences to show.");
  }
  get NoWorkspaceAccess() {
    return getString("NO_WORKSPACE_ACCESS", "You don't have access to any workspaces for your default company:");
  }
  get ContactAnAdministrator() {
    return getString("CONTACT_AN_ADMINISTRATOR", "Contnact one of your account administrators to grant you access.");
  }
  get WorkspaceEmailSettings() {
    return getString("WORKSPACE_EMAIL_SETTINGS", "Workspace E-mail Settings");
  }
  get EmailAddressForWorkspace() {
    return getString("EMAIL_ADDRESS_FOR_WORKSPACE", "E-mail address for workspace");
  }
  get EmailForwarding() {
    return getString("EMAIL_FORWARDING", "E-mail Forwarding");
  }
  get EmailDelivery() {
    return getString("EMAIL_DELIVERY", "E-mail Delivery");
  }
  get SendSPFDKIMHelpEmail() {
    return getString("SEND_SPF_DKIM_HELP_EMAIL", "Send SPF/DKIM Help Email");
  }
  get AddingSPFDKIMRecordsForLockstep() {
    return getString("ADDING_SPF_DKIM_RECORDS_FOR_LOCKSTEP", "Adding SPF / DKIM records for Lockstep");
  }
  get Hello() {
    return getString("HELLO", "Hello");
  }
  FormattedTextCopyEmailForSPFDKIM(companyName) {
    return formatString(
      getString(
        "FORMATTED_TEXT_COPY_EMAIL_FOR_SPF_DKIM",
        "Hello\n\nA user from {companyName} is in the process of setting up {companyName} to manage shared email addresses and we need your help with one small thing.\n\nTo improve email delivery rates and reduce the risk of emails being marked as spam, we need you to make this settings change. Can you please add these five SPF\x2FDKIM records to your DNS? this change will authorize Lockstep to send emails on their behalf.\n\nDKIM: \n"
      ),
      { companyName: companyName }
    );
  }
  get SPFDKIMBlurb1() {
    return getString(
      "SPF_DKIM_BLURB_1",
      "If you need help from your IT team to make the DNS change, we've prepared an email that you can send. Just fill out the To: field and hit Send."
    );
  }
  SPFDKIMBlurb2(companyName) {
    return formatString(
      getString(
        "SPF_DKIM_BLURB_2",
        "A user from {companyName} is in the process of setting up {companyName} to manage shared email addresses and we need your help with one small thing."
      ),
      { companyName: companyName }
    );
  }
  get SPFDKIMBlurb3() {
    return getString(
      "SPF_DKIM_BLURB_3",
      "To improve email delivery rates and reduce the risk of emails being marked as spam, we need you to make this settings change. Can you please add these five SPF/DKIM records to your DNS? This change will authorize Lockstep to send emails on their behalf."
    );
  }
  get SPFDKIMBlurb4() {
    return getString(
      "SPF_DKIM_BLURB_4",
      "Adding SPF/DKIM records to your company's DNS will authorize Lockstep to send emails on your behalf. "
    );
  }
  get SPFDKIMBlurb5() {
    return getString(
      "SPF_DKIM_BLURB_5",
      "To improve e-mail delivery rates and reduce e-mails being marked as SPAM, an IT administrator needs to make these setting changes. "
    );
  }
  get SPFDKIMConfigurations() {
    return getString("SPF_DKIM_CONFIGURATIONS", "Your SPF/DKIM Configurations");
  }

  get SPFDKIMConfigBlurb() {
    return getString(
      "SPFDKIMConfigBlurb",
      "Below are your SPF/DKIM configurations. To improve e-mail delivery rates and reduce e-mails being marked as SPAM, an IT administrator needs to make some setting changes. "
    );
  }
  get ClickHere() {
    return getString("LearnMore", "Click here to learn more.");
  }
  get LearnMore() {
    return getString("LearnMore", "Learn More");
  }

  get Gmail() {
    return getString("GMAIL", "Gmail");
  }
  get Outlook() {
    return getString("OUTLOOK", "Outlook");
  }
  GmailForwardingSetup(emailAddress) {
    return formatString(
      getString(
        "GMAIL_FORWARDING_SETUP",
        "<h4>From your gmail account:</h4>1. In the top right, click the cog menu <span class='fal fa-cog'></span> and select <b>Settings</b>.<br/>2. Click the <b>Forwarding and POP/IMAP</b> tab.<br/>3. In the <b>Forwarding</b> section, click <b>Add a forwarding address</b>. <br/>4. Enter the email address: {emailAddress}<br/>5. Click <b>Next</b> and then <b>Proceed</b> and then <b>OK</b>.<br/>6. A verification message will be sent to that address. Click the verification link in that message.<br/>7. Go back to the settings page for the Gmail account you want to forward messages from, and refresh your browser.<br/>8. Click the <b>Forwarding and POP/IMAP</b> tab.<br/>9. In the <b>Forwarding</b> section, select <b>Forward a copy of incoming mail to</b><br/>10. Choose what you want to happen with the Gmail copy of your emails. We recommend <b>Keep Gmail's copy in the Inbox</b>.<br/>11. At the bottom of the page, click <b>Save Changes</b>"
      ),
      { emailAddress }
    );
  }
  OutlookForwardingSetup(emailAddress) {
    return formatString(
      getString(
        "OUTLOOK_FORWARDING_SETUP",
        "<i>NOTE: For emails at @outlook.com, @hotmail.com, @live.com, or @msn.com, you can log in at <a href='https://outlook.com' target='_blank' rel='noopener noreferrer'>https://outlook.com</a>. If you have an address at a custom domain, you will need to login into your company's webmail, e.g., \"https://mail.yourcompany.com\".</i><br/><br/><h4>From your Microsoft Outlook Web Client:</h4>1. Login into the account you want to forward.<br/>2. In the top right, click <b>Settings</b>.</br>3. Click <b>Options</b>.<br/>4. From the sidebar menu, under the <b>Accounts</b> heading, click <b>Forwarding</b>.<br/>5. Select the <b>Start Forwarding</b> radio button.<br/>6. Enter the email address {emailAddress} on the <b>To</b> line at the top of the window.<br/>7. If you want to keep a copy of all your messages in your Microsoft account, check the <b>Keep a copy of forwarded messages</b> box.<br/>8. Click the <b>Save</b> button to start forwarding your messages."
      ),
      { emailAddress }
    );
  }

  get InvalidEmailFormat() {
    return getString("INVALID_EMAIL_FORMAT", "Invalid email format");
  }
  get ErrorEmailFormat() {
    return getString(
      "ERROR_EMAIL_FORMAT",
      "The email address entered didn't match the expected pattern.  Please adjust your entry and try again."
    );
  }
  get ErrorExistingUserInviteEmail() {
    return getString(
      "ERROR_EXISTING_USER_INVITE_EMAIL",
      "The email address entered belongs to a user that is already added or invited."
    );
  }
  get ErrorFullNameRequired() {
    return getString("ERROR_FULLNAME_REQUIRED", "The full name is required.  Please adjust your entry and try again.");
  }
  get ErrorEmailRequired() {
    return getString("ERROR_EMAIL_REQUIRED", "Email is a required field. Please provide an email and try again.");
  }
  get ErrorEmailInvalid() {
    return getString("ERROR_EMAIL_INVALID", "Please enter a valid email.");
  }
  get UhOh() {
    return getString("UH_OH", "Uh Oh.");
  }
  get SomethingWentWrong() {
    return getString("SOMETHING_WENT_WRONG", "Something went wrong on our side.");
  }
  get OurEngineersHaveBeenNotified() {
    return getString("OUR_ENGINEERS_HAVE_BEEN_NOTIFIED", "Our engineers have been notified to fix this issue.");
  }
  get TryAgainInAWhile() {
    return getString("TRY_AGAIN_IN_A_WHILE", "Try again in a little while or");
  }
  get ReturnToHomePage() {
    return getString("RETURN_TO_HOME_PAGE", "return to home page.");
  }

  get Enabled() {
    return getString("ENABLED", "Enabled");
  }
  get Disabled() {
    return getString("DISABLED", "Disabled");
  }
  get Type() {
    return getString("TYPE", "Type");
  }
  get Unverified() {
    return getString("UNVERIFIED", "Unverified");
  }
  get Verified() {
    return getString("VERIFIED", "Verified");
  }

  get ImproveDeliveryRatesSPFDKIM() {
    return getString("IMPROVE_DELIVERY_RATES_SPF_DKIM", "Improve delivery rates - SPF/DKIM");
  }
  get CopyInstructionsToEmailYourITAdmin() {
    return getString(
      "COPY_INSTRUCTIONS_TO_EMAIL_YOUR_IT_ADMIN",
      " Click here to copy instructions to send to your IT administrator."
    );
  }
  get GetConfigurations() {
    return getString("GetConfigurations", "Get configurations");
  }
  get CopyToClipboard() {
    return getString("COPY_TO_CLIPBOARD", "Copy To Clipboard");
  }
  get CopiedToClipboard() {
    return getString("COPIED_TO_CLIPBOARD", "Copied To Clipboard");
  }
  get Copied() {
    return getString("COPIED", "Copied");
  }
  get Domain() {
    return getString("DOMAIN", "Domain");
  }
  get DNSRecord() {
    return getString("DNS_RECORD", "DNS Record");
  }
  get DisableDNSSettings() {
    return getString("DISABLE_DNS_SETTINGS", "Disable DNS Settings");
  }
  get SetDNSSettings() {
    return getString("SET_DNS_SETTINGS", "Set DNS Settings");
  }

  get CheckDNSSettings() {
    return getString("CHECK_DNS_SETTINGS", "Check DNS Settings");
  }

  get ConnectSMTP() {
    return getString("CONNECT_SMTP", "Connect SMTP");
  }
  get DisableSMTPSettings() {
    return getString("DISABLE_SMTP_SETTINGS", "DisableSMTPSettings");
  }
  ForwardCopyOfSharedEmail(perspective) {
    return formatString(
      getString("FORWARD_COPY_OF_SHARED_EMAIL", "Forward a copy of your shared {perspective} email address:"),
      { perspective: perspective }
    );
  }

  get SendTestEmail() {
    return getString("SEND_TEST_EMAIL", "We will send a test email to make sure forwarding is working");
  }
  get ToYourCompanySecureLockstepNetwork() {
    return getString("TO_YOUR_COMPANY_SECURE_LOCKSTEP_NETWORK", "To your company's secure Lockstep Network address");
  }
  get StepByStepInstructions() {
    return getString("STEP_BY_STEP_INSTRUCTIONS", "Click here for step-by-step instructions.");
  }
  SearchItemNotFound(searchItem) {
    return formatString(getString("SEARCH_ITEM_NOT_FOUND", "No search results found for {searchItem}"), { searchItem });
  }
  get NoResultsFound() {
    return getString("NO_RESULTS_FOUND", "No search results found.");
  }
  get SearchFailed() {
    return getString("SEARCH_FAILED", "Something went wrong, Search Failed.");
  }
  get UpdateResults() {
    return getString("UPDATE_RESULTS", "Update results");
  }
  get ClearFilters() {
    return getString("CLEAR_FILTERS", "Clear filters");
  }
  get GoBack() {
    return getString("GO_BACK", "Go Back");
  }
  get Back() {
    return getString("BACK", " Back");
  }
  get PostalCode() {
    return getString("POSTAL_CODE", "Postal Code");
  }
  get Region() {
    return getString("REGION", "Region");
  }
  get StreetAddress() {
    return getString("STREET_ADDRESS", "Street Address");
  }
  get LoadMore() {
    return getString("LOAD_MORE", "click here to load more");
  }
  get UserSettings() {
    return getString("USER_SETTINGS", "User Settings");
  }
  get UserProfile() {
    return getString("USER_PROFILE", "User Profile");
  }
  get NotificationPreferencesSaved() {
    return getString("NOTIFICATION_PREFERENCES_SAVED", "Notification preferences saved.");
  }
  get UserProfileSaved() {
    return getString("USER_PROFILE_SAVED", "User profile saved.");
  }
  get User() {
    return getString("USER", "User");
  }
  get Users() {
    return getString("USERS", "Users");
  }
  get UserRole() {
    return getString("USER_ROLE", "User Role");
  }
  get UserRoleAndAccess() {
    return getString("USER_ROLE_AND_ACCESS", "User Role and Access");
  }
  get Invites() {
    return getString("INVITES", "Invites");
  }
  get Administrator() {
    return getString("ADMIN", "Administrator");
  }
  get Connector() {
    return getString("CONNECTOR", "Connector");
  }
  get Unknown() {
    return getString("UNKNOWN", "Unknown");
  }
  get Role() {
    return getString("ROLE", "Role");
  }
  get Promote() {
    return getString("PROMOTE", "Promote");
  }
  get Demote() {
    return getString("DEMOTE", "Demote");
  }
  get Remove() {
    return getString("REMOVE", "Remove");
  }
  get ResetPassword() {
    return getString("RESET_PASSWORD", "Reset Password");
  }
  get Action() {
    return getString("ACTION", "Action");
  }

  get YoureBack() {
    return getString("YOURE_BACK", "You're back!");
  }
  get YoureOffline() {
    return getString("YOURE_OFFLINE", "You're offline. Results may not be up to date.");
  }
  get NoNetworkMessage() {
    return getString("NO_NETWORK_MESSAGE", "Your computer seems to be offline.  Check your network connection.");
  }
  get ErrorDialogTitle() {
    return getString("ERROR_DIALOG_TITLE", "Whoops! Something went wrong!");
  }
  get InviteUser() {
    return getString("INVITE_USER", "Invite User");
  }
  get InviteNewUser() {
    return getString("INVITE_NEW_USER", "Invite new user");
  }
  get AddUser() {
    return getString("ADD_USER", "Add User");
  }
  ConfirmDeleteUser(username) {
    return formatString(
      getString("CONFIRM_DELETE_USER", "Are you sure you want to remove {username} from the company?"),
      { username }
    );
  }

  get PasswordIsTooShortError() {
    return getString("PASSWORD_IS_TOO_SHORT_ERROR", "Password is too short.");
  }
  get StandardLockstepDelivery() {
    return getString("STANDARD_LOCKSTEP_DELIVERY", "Standard Lockstep Delivery");
  }

  get NotSuccessful() {
    return getString("NOT_SUCCESSFUL", "Not Successful");
  }
  get Contact() {
    return getString("CONTACT", "Contact");
  }
  get Contacts() {
    return getString("CONTACTS", "Contacts");
  }
  get AddContacts() {
    return getString("ADD_CONTACTS", "Add contacts");
  }
  get TryAgain() {
    return getString("TRY_AGAIN", "Try Again");
  }
  get BackToLockstep() {
    return getString("BACK_TO_LOCKSTEP", "Back to Lockstep");
  }
  get ThisMayTakeAWhile() {
    return getString("THIS_MAY_TAKE_A_WHILE", "This may take a while...");
  }

  get RegisteredAccountingPackage() {
    return getString("REGISTERED_ACCOUNTING_PACKAGE", "Registered accounting package");
  }
  get SelectAccountingSoftware() {
    return getString("SELECT_ACCOUNTING_SOFTWARE", "Select your accounting software package to connect to Lockstep:");
  }
  get VoteForNextPackage() {
    return getString("VOTE_FOR_NEXT_PACKAGE", "Or vote for next accounting package to sync with Lockstep");
  }
  get VoteForPackageSubtext() {
    return getString(
      "VOTE_FOR_PACKAGE_SUBTEXT",
      "Select your accounting package and we will let you know when it's ready"
    );
  }
  ERPConnectionSuccessMessage(erp) {
    return formatString(getString("ERP_CONNECTION_SUCCESS_MESSAGE", "Success, you're now connected to {erp}!"), {
      erp
    });
  }
  SyncedWith(erp) {
    return formatString(getString("SYNCED_WITH", "Synced with {erp}"), { erp });
  }
  get EnterPackageDetails() {
    return getString("ENTER_PACKAGE_DETAILS", "Enter details to connect with your accounting package.");
  }

  get AccountId() {
    return getString("ACCOUNT_ID", "Account ID");
  }
  get TokenId() {
    return getString("TOKEN_ID", "Token ID");
  }
  get TokenSecret() {
    return getString("TOKEN_SECRET", "Token Secret");
  }
  get Register() {
    return getString("REGISTER", "Register");
  }
  get Registering() {
    return getString("REGISTERING", "Registering");
  }
  get Registered() {
    return getString("REGISTERED", "Registered");
  }
  get Unregister() {
    return getString("UNREGISTER", "Unregister");
  }
  get YesRegister() {
    return getString("YES_REGISTER", "Yes, I want to register");
  }
  get YesDisconnect() {
    return getString("YES_DISCONNECT", "Yes, I want to disconnect");
  }
  get Vote() {
    return getString("VOTE", "Vote");
  }
  VoteFor(connectorName) {
    return formatString(getString("VOTE_FOR", "Vote for {conncetorName}"), {
      connectorName
    });
  }
  ThanksForVotingFor(connector) {
    return formatString(getString("THANKS_FOR_VOTING_FOR", "Thank you for voting for {connector}!"), { connector });
  }
  get Warning() {
    return getString("WARNING", "Warning!");
  }
  get Login() {
    return getString("LOGIN", "Login");
  }
  get CustomersSynched() {
    return getString("CUSTOMERS_SYNCHED", "Customers Synched");
  }
  get VendorsSynched() {
    return getString("VENDORS_SYNCHED", "Vendors Synched");
  }
  get VendorSettingsSaved() {
    return getString("VENDOR_SETTINGS_SAVED", "Vendor settings saved");
  }
  get VerifyInstallation() {
    return getString("VERIFY_INSTALLATION", "Verify Installation");
  }
  get InstallationInstructions() {
    return getString("INSTALLATION_INSTRUCTIONS", "Installation Instructions");
  }
  StartingViewBlurb1ERPSetupInstall(ERPName) {
    return formatString(
      getString(
        "STARTING_VIEW_BLURB_1_ERP_SETUP_INSTALL",
        "In order for Lockstep to communicate with your implementation of {ERPName} --- the certified Lockstep {ERPName} bundle must be installed."
      ),
      { ERPName: ERPName }
    );
  }
  get StartingViewBlurb2ERPSetupInstall() {
    return getString(
      "STARTING_VIEW_BLURB_2_ERP_SETUP_INSTALL",
      "After the Lockstep Bundle is installed, it must be verified and registered."
    );
  }
  get RegisterHere() {
    return getString("REGISTER_HERE", "Register Here");
  }
  get MissingOne() {
    return getString("MISSING_ONE", "Missing one? Type name here");
  }
  get LetsGetStarted() {
    return getString("LETS_GET_STARTED", "Get started with Lockstep!");
  }
  ReturnToErp(connectorName) {
    return formatString(getString("RETURN_TO_ERP", "Return to {connectorName}"), { connectorName });
  }
  ConnectorRegisterSuccess(connectorName) {
    return formatString(getString("CONNECTOR_REGISTER_SUCCESS", "Success, you're now connected to {connectorName}!"), {
      connectorName
    });
  }
  ConnectorRegisterSuccessMessage(connectorName) {
    return formatString(
      getString(
        "CONNECTOR_REGISTER_SUCCESS_MESSAGE",
        "Your {connectorName} account has been successfully connected to Lockstep."
      ),
      { connectorName }
    );
  }
  ConnectorRegisterWarning(connectorName) {
    return formatString(
      getString(
        "CONNECTOR_REGISTER_WARNING",
        "You are about to register a new accounting package. This will unregister {connectorName} first. <br/><br/> Are you sure you want to register a new accounting package?"
      ),
      { connectorName }
    );
  }
  ConnectorDisconnectWarning(connectorName) {
    return formatString(
      getString("CONNECTOR_DISCONNECT_WARNING", "Are you sure you want to disconnect {connectorName}?"),
      { connectorName }
    );
  }
  get ConnectorCompanySelectionHeader() {
    return getString(
      "CONNECTOR_COMPANY_SELECTION_HEADER",
      "We connect to your accounting software to synchronize your invoices and contracts."
    );
  }
  get ThisConnectorHasAlreadyBeenInstalled() {
    return getString(
      "THIS_CONNECTOR_HAS_ALREADY_BEEN_INSTALLED",
      "This connector had already been installed and registered."
    );
  }

  get AutomatedTasks() {
    return getString("AUTOMATED_TASKS", "Automated tasks");
  }
  get ManualTasks() {
    return getString("MANUAL_TASKS", "Manual tasks");
  }
  get Tasks() {
    return getString("TASKS", "Tasks");
  }
  get Team() {
    return getString("TEAM", "Team");
  }
  get ApprovalAging() {
    return getString("APPROVAL_AGING", "Approval Aging");
  }
  get TotalTasks() {
    return getString("TOTAL_TASKS", "Total Tasks");
  }
  get Responses() {
    return getString("RESPONSES", "Responses");
  }
  get AvgReviewTime() {
    return getString("AVG_REVIEW_TIME", "Avg. Review Time");
  }
  get Resolutions() {
    return getString("RESOLUTIONS", "Resolutions");
  }
  get AvgResolutionTime() {
    return getString("AVG_RESOLUTION_TIME", "Avg. Resolution Time");
  }
  get AvgResponseTime() {
    return getString("AVG_RESPONSE_TIME", "Avg. Response Time");
  }
  get TeamMember() {
    return getString("TEAM_MEMBER", "Team Member");
  }
  get Percentage() {
    return getString("PERCENTAGE", "Percentage");
  }
  get PercentResponses() {
    return getString("PERCENT_RESPONSES", "% Responses");
  }
  get PercentResolutions() {
    return getString("PERCENT_RESOLUTIONS", "% Resolutions");
  }
  get AvgFirstResponse() {
    return getString("AVG_FIRST_RESPONSE", "Avg. First Response");
  }
  get TaskTitle() {
    return getString("TASK_TITLE", "Task Title");
  }
  get Organization() {
    return getString("ORGANIZATION", "Organization");
  }
  get ResolutionTime() {
    return getString("RESOLUTION_TIME", "Resolution Time");
  }
  get FirstResponse() {
    return getString("FIRST_RESPONSE", "First Response");
  }
  get ApprovalRequests() {
    return getString("APPROVAL_REQUESTS", "Approval Requests");
  }
  get Outstanding() {
    return getString("OUTSTANDING", "Outstanding");
  }
  get AvgApprovalTime() {
    return getString("AVG_APPROVAL_TIME", "Avg. Approval Time");
  }
  get MaxApprovalTime() {
    return getString("MAX_APPROVAL_TIME", "Max Approval Time");
  }
  get Requests() {
    return getString("REQUESTS", "Requests");
  }
  get MaxOutstandingTime() {
    return getString("MAX_OUTSTANDING_TIME", "Max Outstanding Time");
  }
  get CurrentlyAging() {
    return getString("CURRENTLY_AGING", "Currently Aging");
  }
  get GreaterThanOrEqualTo() {
    return getString("GREATER_THAN_OR_EQUAL_TO", "Greater than or equal to");
  }
  get AndLessThan() {
    return getString("AND_LESS_THAN", "and less than");
  }
  get LessThanTwentyFourHours() {
    return getString("LESS_THAN_24_HOURS", "< 24 Hours");
  }
  get TwentyFourToFourtyEightHours() {
    return getString("24_TO_48_HOURS", "24 - 48 Hours");
  }
  get FourtyEightToSeventyTwoHours() {
    return getString("48_TO_72_HOURS", "48 - 72 Hours");
  }
  get SeventyTwoPlusHours() {
    return getString("72_PLUS_HOURS", "72+ Hours");
  }
  get MaxAgingTime() {
    return getString("MAX_AGING_TIME", "Max Aging Time");
  }
  get Current() {
    return getString("CURRENT", "Current");
  }
  get AgingLessThanTwentyFourH() {
    return getString("AGING_LESS_THAN_24_H", "Aging < 24h");
  }
  get AgingTwentyFourToFourtyEightH() {
    return getString("AGING_24_TO_48_H", "Aging 24 - 48h");
  }
  get AgingFourtyEightToSeventyTwoH() {
    return getString("AGING_48_TO_72_H", "Aging 48 - 72h");
  }
  get AgingSeventyTwoPlusH() {
    return getString("AGING_72_PLUS_H", "Aging 72+ h");
  }
  get FilterBy() {
    return getString("FILTER_BY", "Filter by");
  }
  get TimeRange() {
    return getString("TIME_RANGE", "Time range");
  }
  get AnyTime() {
    return getString("ANY_TIME", "Any time");
  }
  get PastTwentyFourHours() {
    return getString("PAST_TWENTY_FOUR_HOURS", "Past 24 hours");
  }
  get PastWeek() {
    return getString("PAST_WEEK", "Past week");
  }
  get PastMonth() {
    return getString("PAST_MONTH", "Past month");
  }
  get PastYear() {
    return getString("PAST_YEAR", "Past year");
  }
  get CustomRange() {
    return getString("CUSTOM_RANGE", "Custom range...");
  }
  get NumberOfDays() {
    return getString("NUMBER_OF_DAYS", "Number of days");
  }
  get DayOfTheMonth() {
    return getString("DAY_OF_THE_MONTH", "Day of the month");
  }
  get LastTwentyEightDays() {
    return getString("LAST_28_DAYS", "Last 28 Days");
  }
  get LastTenDays() {
    return getString("LAST_10_DAYS", "Last 10 Days");
  }
  get LastTwelveHours() {
    return getString("LAST_12_HOURS", "Last 12 Hours");
  }
  get DailyWeekdays() {
    return getString("DAILY_WEEKDAYS", "Daily (weekdays only)");
  }
  get WeeklyMondays() {
    return getString("WEEKLY_MONDAYS", "Weekly (on Mondays)");
  }
  get Never() {
    return getString("NEVER", "Never");
  }
  get TeamReport() {
    return getString("TEAM_REPORT", "Team Report");
  }
  get TasksReport() {
    return getString("TASKS_REPORT", "Tasks Report");
  }
  get ApproversReport() {
    return getString("APPROVERS_REPORT", "Approvers Report");
  }
  get ApprovalAgingReport() {
    return getString("APPROVAL_AGING_REPORT", "Approval Aging Report");
  }
  get AverageResolutionTimeByTeamMember() {
    return getString("AVERAGE_RESOLUTION_TIME_BY_TEAM_MEMBER", "Average resolution time by team member");
  }
  get AverageApprovalTimeByApprover() {
    return getString("AVERAGE_APPROVAL_TIME_BY_APPROVER", "Average Approval Time by Approver");
  }
  get MaxApprovalTimeByApprover() {
    return getString("MAX_APPROVAL_TIME_BY_APPROVER", "Max Approval Time by Approver");
  }
  get NumberOfRequestsByApprover() {
    return getString("NUMBER_OF_REQUESTS_BY_APPROVER", "Number of Requests by Approver");
  }
  get SlowestResolver() {
    return getString("SLOWEST_RESOLVER", "Slowest Resolver");
  }
  get LastWeek() {
    return getString("LAST_WEEK", "Last Week");
  }
  get ThisWeek() {
    return getString("THIS_WEEK", "This Week");
  }
  get LastMonth() {
    return getString("LAST_MONTH", "Last Month");
  }
  get ThisMonth() {
    return getString("THIS_MONTH", "This Month");
  }
  get LastQuarter() {
    return getString("LAST_QUARTER", "Last Quarter");
  }
  get ThisQuarter() {
    return getString("THIS_QUARTER", "This Quarter");
  }

  get InvoiceSummary() {
    return getString("INVOICE_SUMMARY", "Invoice summary");
  }
  get BillNumber() {
    return getString("BILL_NUMBER", "Bill #");
  }
  get DateEntered() {
    return getString("DATE_ENTERED", "Date Entered");
  }
  get RemainingPayment() {
    return getString("REMAINING_PAYMENT", "Remaining Payment");
  }
  get Terms() {
    return getString("TERMS", "Terms");
  }
  get CheckNumber() {
    return getString("CHECK_NUMBER", "Check Number");
  }
  get CheckDate() {
    return getString("CHECK_DATE", "Check Date");
  }
  get InvoiceNumber() {
    return getString("INVOICE_NUMBER", "Invoice Number");
  }
  get NoStatementSearchResultsMessage() {
    return getString(
      "NO_STATEMENT_SEARCH_RESULTS_MESSAGE",
      "Oops! We can't find what you're looking for. You can search for an invoice number."
    );
  }
  get NoMessageSearchResultsMessage() {
    return getString(
      "NO_STATEMENT_SEARCH_RESULTS_MESSAGE",
      "Oops! We can't find what you're looking for. You can search for a subject or message content."
    );
  }
  SeeAllInvoiceResults(searchTerm) {
    return formatString(getString("SEE_ALL_INVOICE_RESULTS", 'See all results for invoices with "{searchTerm}"'), {
      searchTerm
    });
  }
  SeeAllPaymentsResults(searchTerm) {
    return formatString(getString("SEE_ALL_PAYMENTS_RESULTS", 'See all results for payments with "{searchTerm}"'), {
      searchTerm
    });
  }
  SeeAllMessageResults(searchTerm) {
    return formatString(getString("SEE_ALL_MESSAGES_RESULTS", 'See all results for messages with "{searchTerm}"'), {
      searchTerm
    });
  }
  get TransactionId() {
    return getString("TRANSACTION_ID", "Transaction Id");
  }
  get TransactionType() {
    return getString("TRANSACTION_TYPE", "Transaction Type");
  }
  get NotEntered() {
    return getString("NOT_ENTERED", "Not Entered");
  }
  get RemainingDollars() {
    return getString("REMAINING_DOLLARS", "Remaining $");
  }
  get OriginalAmount() {
    return getString("ORIGINAL_AMOUNT", "Original Amount");
  }
  get AmountDue() {
    return getString("AMOUNT_DUE", "Amount Due");
  }
  get PaymentAmt() {
    return getString("PAYMENT_AMT", "Payment Amt");
  }
  get UnappliedAmt() {
    return getString("UNAPPLED_AMT", "Unapplied Amt");
  }
  UnappliedPaymentsAlert(count, total) {
    return formatString(
      getString(
        "UNAPPLIED_PAYMENTS_ALERT",
        "You have {count} unapplied payments totaling {total} contributing to your total account balance."
      ),
      { count, total }
    );
  }
  get AmountPaid() {
    return getString("AMOUNT_PAID", "Amount Paid");
  }
  get InvoiceDate() {
    return getString("INVOICE_DATE", "Invoice Date");
  }
  get EnteredOn() {
    return getString("ENTERED_ON", "Entered on");
  }
  get Default() {
    return getString("DEFAULT", "Default");
  }

  get Remap() {
    return getString("REMAP", "Remap");
  }
  get EnterNewName() {
    return getString("ENTER_NEW_NAME", "Enter new name");
  }
  get RenameCompanyProfile() {
    return getString("RENAME_COMPANY_PROFILE", "Rename company profile");
  }
  get CompanyEmailIdentifiers() {
    return getString("COMPANY_EMAIL_IDENTIFIERS", "Company Email Identifiers");
  }
  get ContactDetailsOptional() {
    return getString("CONTACT_DETAILS_OPTIONAL", "Contact Details - Optional");
  }

  CompanyMarkedAsSpam(companyName) {
    return formatString(getString("COMPANY_MARKED_AS_SPAM", "{companyName} has been marked as spam"), { companyName });
  }
  AddressMarkedAsSpam(address) {
    return formatString(getString("ADDRESS_MARKED_AS_SPAM", "{address} has been marked as spam"), { address });
  }
  HasBeenRemaped(companyNameFrom, companyNameTo) {
    return formatString(
      getString("COMPANY_HAS_BEEN_REMAPED", "{companyNameFrom} has been remaped to {companyNameTo}"),
      { companyNameTo, companyNameFrom }
    );
  }
  ChannelNameHasBeenChanged(oldCompanyName, newCompanyName) {
    return formatString(
      getString("CHANNEL_NAME_CHANGED", "Channel display name changed from {oldCompanyname} to {newCompanyName}"),
      { oldCompanyName, newCompanyName }
    );
  }
  get NewCompanyMessage() {
    return getString("NEW_COMPANY_MESSAGE", "This conversation is with an unknown/new company");
  }

  get RemapAndMergeTheEmailToAnExistingCompany() {
    return getString(
      "REMAP_AND_MERGE_THE_EMAIL_TO_AN_EXISTING_COMPANY",
      "Remap and merge the email to an existing company."
    );
  }
  get ThisActionCannotBeUndone() {
    return getString("THIS_ACTION_CANNOT_BE_UNDONE", "This action cannot be undone");
  }

  get Download() {
    return getString("DOWNLOAD", "Download");
  }
  get DownloadAttachments() {
    return getString("DOWNLOAD_ATTACHMENTS", "Download attachments");
  }
  get Document() {
    return getString("DOCUMENT", "Document");
  }
  get Direction() {
    return getString("DIRECTION", "Direction");
  }

  get Workflows() {
    return getString("WORKFLOWS", "Workflows");
  }
  get CreateWorkflow() {
    return getString("CREATE_WORKFLOW", "Create workflow");
  }
  get NewWorkflow() {
    return getString("NEW_WORKFLOW", "New Workflow");
  }
  get Reminder() {
    return getString("REMINDER", "Reminder");
  }
  get Reminders() {
    return getString("REMINDERS", "Reminders");
  }
  get CustomWorkflow() {
    return getString("CUSTOM_FLOW", "Custom workflow");
  }
  get EditingWorkflow() {
    return getString("EDITING_WORKFLOW", "Editing Workflow");
  }
  get WorkflowType() {
    return getString("WORKFLOW_TYPE", "Workflow Type");
  }
  SetReminderNameReminder(reminderName) {
    return formatString(getString("SET_REMINDER_NAME_REMINDER", "Set {reminderName} Reminder"), { reminderName });
  }
  get SelectCustomDateAndTime() {
    return getString("SELECT_CUSTOM_DATE_AND_TIME", "Select custom date and time");
  }
  get CustomDateRange() {
    return getString("CUSTOM_DATE_RANGE", "Custom date range");
  }
  get Time() {
    return getString("TIME", "Time");
  }
  get UseAsDefaultTemplateFor() {
    return getString("USE_AS_DEFAULT_TEMPLATE_FOR", "Use as default template for");
  }
  get AssignTo() {
    return getString("ASSIGN_TO", "Assign to");
  }
  ReminderNameSetForDate(reminderName, reminderDate) {
    return formatString(getString("REMINDER_NAME_SET_FOR_DATE", "Reminder {reminderName} set for {reminderDate}"), {
      reminderName,
      reminderDate
    });
  }
  get ReminderCanceled() {
    return getString("REMINDER_WAS_CANCELED", "Reminder was canceled");
  }
  ReminderNameForDateReminded(reminderName, reminderDate) {
    return formatString(
      getString("REMINDER_NAME_FOR_DATE_REMINDED", "Reminder {reminderName} for {reminderDate} actiavted"),
      {
        reminderName,
        reminderDate
      }
    );
  }

  get Tomorrow() {
    return getString("TOMORROW", "Tomorrow");
  }
  get NextWeek() {
    return getString("NEXT_WEEK", "Next Week");
  }
  get NextMonth() {
    return getString("NEXT_MONTH", "Next Month");
  }
  get NextQuarter() {
    return getString("NEXT_QUARTER", "Next Quarter");
  }
  get CustomDayTime() {
    return getString("CUSTOM_DAY_TIME", "Custom Day/Time");
  }

  ReminderNameDueTime(reminderName, time) {
    return formatString(getString("REMINDER_NAME_DUE_TIME", "{reminderName} due {time}"), {
      reminderName,
      time
    });
  }
  ReminderNameDueTimeAgo(reminderName, time) {
    return formatString(getString("REMINDER_NAME_DUE_TIME_AGO", "{reminderName} due {time} ago"), {
      reminderName,
      time
    });
  }

  get ConnectDirectlyToYourIMAPServer() {
    return getString("CONNECT_DIRECTLY_TO_YOUR_IMAP_SERVER", "Connect directly to your IMAP server");
  }

  get Activate() {
    return getString("ACTIVATE", "Activate");
  }
  get Deactivate() {
    return getString("DEACTIVATE", "Deactivate");
  }
  get Alias() {
    return getString("ALIAS", "Alias");
  }
  get HostAddress() {
    return getString("HOST_ADDRESS", "Host Address");
  }
  get HostAddressExample() {
    return getString("HOST_ADDRESS_EXAMPLE", "imap.domain.com");
  }
  get Port() {
    return getString("PORT", "Port");
  }
  get Active() {
    return getString("ACTIVE", "Active");
  }
  get ValidEmailError() {
    return getString(
      "VALID_EMAIL_ERROR",
      "We weren't able to process this email address. Double check and make sure that it's valid."
    );
  }
  get ForwardEmailsToYourWorkspace() {
    return getString("FORWARD_EMAILS_TO_YOUR_WORKSPACE", "Forward e-mails to your workspace");
  }
  get ForwardAllEmailsFromInboxToSharedLedger() {
    return getString(
      "FORWARD_ALL_EMAILS_FROM_INBOX_TO_SHARED_LEDGER",
      "Forward all emails from your email inbox to a specific workspace address."
    );
  }
  UseEmailForImapWorkspace(email) {
    return formatString(
      getString(
        "USE_EMAIL_FOR_IMAP_WORSPACE",
        "Use your email account to securely deliver messages sent to <b>{email}</b> into this workspace."
      ),
      {
        email
      }
    );
  }
  get IncomingMailSettings() {
    return getString("INCOMING_MAIL_SETTINGS", "Incoming Mail Settings");
  }
  get OutgoingMailSettings() {
    return getString("OUTGOING_MAIL_SETTINGS", "Outgoing Mail Settings");
  }
  get DeleteEmailFromServerAfterImport() {
    return getString("DELETE_EMAIL_FROM_SERVER_AFTER_IMPORT", "Delete email from server after import?");
  }
  get AddAlias() {
    return getString("ADD_ALIAS", "Add an alias");
  }
  get RemoveAlias() {
    return getString("REMOVE_ALIAS", "Remove alias");
  }
  get NumberOfDaysBackToImport() {
    return getString("NUMBER_OF_DAYS_BACK_TO_IMPORT", "Number of days back to import");
  }
  get NotConnected() {
    return getString("NOT_CONNECTED", "Not Connected");
  }
  get AliasAtDomain() {
    return getString("ALIAS_AT_DOMAIN", "alias@domain.com");
  }
  get Day() {
    return getString("DAY", "Day");
  }
  get Week() {
    return getString("WEEK", "Week");
  }
  get Month() {
    return getString("MONTH", "Month");
  }
  get Custom() {
    return getString("CUSTOM", "Custom");
  }
  get ImportAllMailFromPast() {
    return getString("IMPORT_ALL_MAIL_FROM_PAST", "Import all mail from past");
  }
  get Days() {
    return getString("DAYS", "Days");
  }
  get EmailSettings() {
    return getString("EMAIL_SETTINGS", "Email Settings");
  }
  get ShortestWaitingTime() {
    return getString("SHORTEST_WAITING_TIME", "Shortest Waiting Time");
  }
  get LongestWaitingTime() {
    return getString("LONGEST_WAITING_TIME", "Longest Waiting Time");
  }
  get NewestDate() {
    return getString("NEWEST_DATE", "Newest Date");
  }
  get OldestDate() {
    return getString("OLDEST_DATE", "Oldest Date");
  }
  get NextBusinessDay() {
    return getString("NEXT_BUSINESS_DAY", "Next Business Day");
  }
  get MarkClosed() {
    return getString("MARK_CLOSED", "Mark Closed");
  }

  get WaitingResponse() {
    return getString("WAITING_RESPONSE", "Waiting Response");
  }
  get RequestResponse() {
    return getString("REQUEST_RESPONSE", "Request Response");
  }
  get ForwardAsRequest() {
    return getString("FORWARD_AS_REQUEST", "Forward as a Request");
  }
  get YouRequestedResponse() {
    return getString("YOU_REQUESTED_RESPONSE", "You requested a response");
  }
  get SettingsSavedCheckBackInAMinute() {
    return getString(
      "SETTINGS_SAVED_CHECK_BACK_IN_A_MINUTE",
      "Settings saved, please check back in a minute or two to verify successful connection."
    );
  }
  get CurrentChannelIs() {
    return getString("CURRENT_CHANNEL_IS", "Current channel is");
  }
  get MoveThisConversationTo() {
    return getString("MOVE_THIS_CONVERSATION_TO", "Move this conversation to");
  }
  get MoveTheseConversationsTo() {
    return getString("MOVE_THESE_CONVERSATIONS_TO", "Move these conversations to");
  }
  get Channel() {
    return getString("CHANNEL", "Channel");
  }
  get CompanySettings() {
    return getString("COMPANY_SETTINGS", "Company Settings");
  }
  get BackToWorkspace() {
    return getString("BACK_TO_WORKSPACE", "Back to workspace");
  }
  get AddComment() {
    return getString("ADD_COMMENT", "Add comment");
  }
  get AddResponse() {
    return getString("ADD_RESPONSE", "Add response");
  }
  get AddOptionalNote() {
    return getString("ADD_OPTIONAL_NOTE", "Add optional note");
  }
  get PoweredByLockstep() {
    return getString(
      "POWERED_BY_LOCKSTEP",
      "Powered by <a href='https://lockstephq.com' target='_blank' rel='noopener noreferrer'>LOCKSTEP™</a>. All your accounting workflows in one place."
    );
  }
  get WhoopsThatWasAMisstep() {
    return getString("WHOOPS_THAT_WAS_A_MISSTEP", "Whoops. That was a misstep.");
  }
  get PageNotFoundMessage() {
    return getString(
      "PAGE_NOT_FOUND",
      "The page is missing. The engineers will double check on their side. In the meantime, let's get back into Lockstep."
    );
  }
  get ReturnToYourWorkspace() {
    return getString("RETURN_TO_YOUR_WORKSPACE", "Return to your workspace");
  }
  get AccountingDataSyncing() {
    return getString("ACCOUNTING_DATA_SYNCING", "Accounting data syncing...");
  }
  get ThankYouForYourDecision() {
    return getString("THANK_YOU_FOR_YOUR_DECISION", "Thank you for your decision!");
  }
  get YourComment() {
    return getString("YOUR_COMMENT", "Your comment");
  }
  YouHaveActionThis(action) {
    return formatString(getString("YOU_HAVE_ACTION_THIS", "You have {action} this."), {
      action
    });
  }
  WeWillNotifyRequesterOfYourDecision(requester) {
    return formatString(
      getString("WE_WILL_NOTIFY_REQUESTER_OF_YOUR_DECISION", "We'll notify {requester} of your decision."),
      {
        requester
      }
    );
  }
  get HaveAWonderfulDayClosePage() {
    return getString("HAVE_A_WONDERFUL_DAY_CLOSE_PAGE", "Have a wonderful day! You can now close this page.");
  }
  get CommentRequired() {
    return getString("COMMENT_REQUIRED", "A comment is required");
  }
  get FailedToSendApprovalDecision() {
    return getString("FAILED_TO_SEND_APPROVAL_DECISION", "Failed to send approval decision (a comment is required)");
  }
  get ConnectWithGmail() {
    return getString("CONNECT_WITH_GMAIL", "Connect with Gmail");
  }
  get Syncing() {
    return getString("SYNCING", "Syncing");
  }
  get DisconnectGmail() {
    return getString("DISCONNECT_GMAIL", "Disconnect Gmail");
  }
  get ConnectYourGmailAccount() {
    return getString("CONNECT_YOUR_GMAIL_ACCOUNT", "Connect your Gmail Account");
  }
  get ChooseGmailConnector() {
    return getString(
      "CHOOSE_GMAIL_CONNECTOR",
      "Choose Gmail as your email provider. You will be asked to allow Lockstep to access your Google account."
    );
  }
  get ConnectToIMAPServer() {
    return getString("CONNECT_TO_IMAP_SERVER", "Connect to IMAP Server");
  }
  get ChooseIMAPConnector() {
    return getString(
      "CHOOSE_IMAP_CONNECTOR",
      "Use your email account to securely deliver messages into this workspace."
    );
  }
  get SetUpEmailForwarding() {
    return getString("SET_UP_EMAIL_FORWARDING", "Set up email forwarding");
  }
  get ImapSettings() {
    return getString("IMAP_SETTINGS", "IMAP Settings");
  }
  get ChangeIncomingEmailAccount() {
    return getString("CHANGE_INCOMING_EMAIL_ACCOUNT", "Change incoming e-mail account?");
  }
  get ChangeIncomingEmailModalText() {
    return getString(
      "CHANGE_INCOMING_EMAIL_MODAL_TEXT",
      "Connecting to a different e-mail account will disable your previously activated account for this workspace."
    );
  }
  get IncomingEmail() {
    return getString("INCOMING_EMAIL", "Incoming Email");
  }
  get BillingAddress() {
    return getString("BILLING_ADDRESS", "Billing Address");
  }
  get Connection() {
    return getString("CONNECTION", "Connection");
  }
  get Connected() {
    return getString("CONNECTED", "Connected");
  }
  get Connecting() {
    return getString("CONNECTING", "Connecting");
  }
  get Disconnect() {
    return getString("DISCONNECT", "Disconnect");
  }
  get Disconnected() {
    return getString("DISCONNECTED", "Disconnected");
  }
  get Disconnecting() {
    return getString("DISCONNECTING", "Disconnecting");
  }
  get ConfigurationRequired() {
    return getString("CONFIGURATION_REQUIRED", "Configuration Required");
  }
  SynchronizingData(configProgress) {
    return formatString(
      getString("SYNCHRONIZING_DATA_PERCENT_COMPLETE", "Synchronizing Data...{configProgress}% complete"),
      {
        configProgress
      }
    );
  }
  ConnectToConnector(connectorName) {
    return formatString(getString("CONNECT_TO_CONNECTOR", "Connect to {connectorName}"), {
      connectorName
    });
  }
  get Synchronizes() {
    return getString("SYNCHRONIZES", "Synchronizes");
  }
  get Reconnect() {
    return getString("RECONNECT", "Reconnect");
  }
  DisconnectFromConnector(connectorName) {
    return formatString(getString("DISCONNECT_FROM_CONNECTOR", "Disconnect from {connectorName}"), {
      connectorName
    });
  }
  get PrimaryContact() {
    return getString("PRIMARY_CONTACT", "Primary contact");
  }
  get ContactEmail() {
    return getString("CONTACT_EMAIL", "Contact Email");
  }
  get TaxID() {
    return getString("TAX_ID", "Tax ID");
  }
  get AccountingPackageNotConnectedCompanyProfile() {
    return getString(
      "ACCOUNTING_PACKAGE_NOT_CONNECTED_COMPANY_PROFILE",
      "Your accounting package isn't connected to Lockstep yet, so we can't show any profile data."
    );
  }
  get ConnectNow() {
    return getString("CONNECT_NOW", "Connect Now");
  }
  get EmailMapping() {
    return getString("EMAIL_MAPPING", "Email Mapping");
  }
  get EmailMappingExplanation() {
    return getString(
      "EMAIL_MAPPING_EXPLANAITION",
      "Conversations from these email domains or email addresses will be mapped to this channel. Change this list to change what is mapped to this channel."
    );
  }

  get ProfileUpdateWelcome() {
    return getString(
      "PROFILE_UPDATE_WELCOME",
      "Thanks for taking a few minutes to confirm your contact information with us! We use Lockstep to store and manage our customer contact information. We’ve prefilled the information we already have on file, would you mind verifying that information and filling in any blanks?"
    );
  }

  get LinkedIn() {
    return getString("LINKED_IN", "LinkedIn URL");
  }

  get HowIsMyDataUsed() {
    return getString("HOW_IS_MY_DATA_USED", "How is my data used and stored?");
  }

  get ProfilePicture() {
    return getString("PROFILE_PICTURE", "Profile picture");
  }

  get DomainOrAddress() {
    return getString("DOMAIN_OR_ADDRESS", "Domain or Address");
  }
  get AddNewDomainOrEmail() {
    return getString("ADD_NEW_DOMAIN_OR_EMAIL", "Add New Domain or Email");
  }
  get AddNew() {
    return getString("ADD_NEW", "Add New");
  }
  get NewEmailDomainsToAdd() {
    return getString("NEW_EMAIL_DOMAINS_TO_ADD", "New Email domains or addresses to be displayed in this channel");
  }
  get VendorDataSynchronizedWith() {
    return getString("VENDOR_DATA_SYNCHRONIZED_WITH", "Vendor data synchronized with");
  }
  get CustomerDataSynchronizedWith() {
    return getString("CUSTOMER_DATA_SYNCHRONIZED_WITH", "Customer data synchronized with");
  }
  get Phone() {
    return getString("PHONE", "Phone");
  }
  get Mobile() {
    return getString("MOBILE", "Mobile");
  }
  ChannelIsNotLinkedToAConnectorCompany(connectorName) {
    return formatString(
      getString(
        "CHANNEL_IS_NOT_LINKED_TO_A_CONNECTOR_COMPANY",
        "The email address for this channel isn't linked to a vendor/customer in {connectorName}. You can leave it as is or take one of the actions below."
      ),
      {
        connectorName
      }
    );
  }
  get LockstepActions() {
    return getString("LOCKSTEP_ACTIONS", "Lockstep Actions");
  }
  get RemapEmailToExistingCompany() {
    return getString("REMAP_EMAIL_TO_EXISTING_COMPANY", "Remap email to existing company");
  }
  CreateVendorCustomerProfileHelperText(connectorName) {
    return formatString(
      getString(
        "CREATE_VENDOR_CUSTOMER_PRFOLIE_HELPER_TEXT",
        "To get profile data, you can create a new vendor/customer in {connectorName} and then remap this company into your newly created company from {connectorName}."
      ),
      {
        connectorName
      }
    );
  }
  get NotifyRespondentViaEmail() {
    return getString("NOTIFY_RESPONDENT_VIA_EMAIL", "Notify Respondent via Email");
  }
  get CancelRequestWithoutSendingAnEmail() {
    return getString("CANCEL_REQUEST_WITHOUT_SENDING_AN_EMAIL", "Cancel request without sending an email");
  }
  get CancelApprovalRequestWithoutSendingAnEmail() {
    return getString(
      "CANCEL_APPROVAL_REQUEST_WITHOUT_SENDING_AN_EMAIL",
      "Cancel approval request without sending an email"
    );
  }
  get ResponseRequired() {
    return getString("RESPONSE_REQUIRED", "Response Required");
  }
  get YouCanceledTheResponseRequest() {
    return getString("YOU_CANCELED_THE_RESPONSE_REQUEST", "You canceled the Response Request");
  }
  PosterCanceledTheApprovalRequest(posterName) {
    return formatString(
      getString("POSTER_CANCELED_THE_APPROVAL_REQUEST", "{posterName} canceled the Approval Request"),
      { posterName }
    );
  }
  get UserList() {
    return getString("USER_LIST", "User List");
  }
  get UserListDescription() {
    return getString(
      "USER_LIST_DESCRIPTION",
      "You deserve some help.  Add and manage team members.  Each team member can belong to one or more workspaces.  You can also grant admin privileges to others if want a sidekick."
    );
  }
  get InvalidInviteMessage() {
    return getString(
      "INVALID_INVITE_MESSAGE",
      " This invitation is no longer valid. It may have been revoked by a company admin."
    );
  }
  get InviteList() {
    return getString("INVITE_LIST", "Invite List");
  }
  get InviteListDescription() {
    return getString("INVITE_LIST_DESCRIPTION", "These are invites that we’ve sent out.");
  }
  get ApproverList() {
    return getString("APPROVER_LIST", "Approver List");
  }
  get ApproverListDescription() {
    return getString(
      "APPROVER_LIST_DESCRIPTION",
      "Approvers are external collaborators that are responsible for authorizing transactions such as payments and credits.  You can add them now or dynamically when sending out approval requests."
    );
  }
  get WorkflowList() {
    return getString("WORKFLOW_LIST", "Workflow List");
  }
  get WorkflowListDescription() {
    return getString(
      "WORKFLOW_LIST_DESCRIPTION",
      "Workflows are automation steps that team members can use to make work easier for everyone."
    );
  }
  get TemplateList() {
    return getString("TEMPLATE_LIST", "Template List");
  }
  get TemplateListDescription() {
    return getString(
      "TEMPLATE_LIST_DESCRIPTION",
      "Ensure consistent communications.  This is a list of templates that team members can use to accelerate response and resolutions."
    );
  }
  get SharedDocumentList() {
    return getString("SHARED_DOCUMENT_LIST", "Shared Document List");
  }
  get SharedDocumentListDescription() {
    return getString(
      "SHARED_DOCUMENT_LIST_DESCRIPTION",
      "You can make business-critical documents easy to access.  Store your W-9, proof-of-insurance, or tax exemption certificate here."
    );
  }
  get ContactList() {
    return getString("CONTACT_LIST", "Contact List");
  }
  get ContactListDescription() {
    return getString(
      "CONTACT_LIST_DESCRIPTION",
      "We’re tracking all the contact your working with.  You can import some too."
    );
  }
  get MoveChannel() {
    return getString("MOVE_CHANNEL", "Move Channel");
  }
  get IncludeInternalExternalMaterialInForwardedConversation() {
    return getString(
      "INCLUDE_INTERNAL_EXTERNAL_MATERIAL_IN_FORWARDED_CONVERSATION",
      "Include all internal and external conversation content in forwarded conversation"
    );
  }
  get InvalidEmailIdentifier() {
    return getString(
      "INVALID_EMAIL_IDENTIFIER",
      "Must be a domain (example.com) or an email address (test@example.com)"
    );
  }
  get ChangeDeliveryMethod() {
    return getString("CHANGE_DELIVERY_METHOD", "Change delivery method?");
  }
  get ChangingDeliveryFromGmailWarning() {
    return getString(
      "CHANGING_DELIVERY_FROM_GMAIL_WARNING",
      "Changing delivery settings will disable email delivery from your connected Gmail account. Do you still want to change?"
    );
  }
  get YesChange() {
    return getString("YES_CHANGE", "Yes, change");
  }
  get ConnectedGmailAccount() {
    return getString("CONNECTED_GMAIL_ACCOUNT", "Connected Gmail Account");
  }
  get PutFutureEmailsFromSenderInSelectedChannel() {
    return getString(
      "PUT_FUTURE_EMAILS_FROM_SENDER_IN_SELECTED_CHANNEL",
      "Put future emails from this sender in the selected channel"
    );
  }
  get PutFutureEmailsFromSendersInSelectedChannel() {
    return getString(
      "PUT_FUTURE_EMAILS_FROM_SENDER_IN_SELECTED_CHANNEL",
      "Put future emails from these senders in the selected channel"
    );
  }
  get NameDescending() {
    return getString("NAME_DESCENDING", "Name (A-Z)");
  }
  get NameAscending() {
    return getString("NAME_ASCENDING", "Name (Z-A)");
  }
  get LargestActiveCount() {
    return getString("LARGEST_ACTIVE_COUNT", "Largest Active Count");
  }
  get SmallestActiveCount() {
    return getString("SMALLEST_ACTIVE_COUNT", "Smallest Active Count");
  }
  get NewestToOldest() {
    return getString("NEWEST_TO_OLDEST", "Newest to Oldest");
  }
  get OldestToNewest() {
    return getString("OLDEST_TO_NEWEST", "Oldest to Newest");
  }
  get MergeWithExistingChannel() {
    return getString("MERGE_WITH_EXISTING_CHANNEL", "Merge With Existing Channel");
  }
  get NotificationApprovalRequestCancelation() {
    return getString("NOTIFICATION_APPROVAL_REQUEST_CANCELATION", "Notification - Approval Request Cancelation");
  }
  get SendAndRequestResponse() {
    return getString("SEND_AND_REQUEST_RESPONSE", "Send and Request Response");
  }
  get Hidden() {
    return getString("HIDDEN", "Hidden");
  }
  get HideChannel() {
    return getString("HIDE_CHANNEL", "Hide Channel");
  }
  get UnhideChannel() {
    return getString("UNHIDE_CHANNEL", "Unhide Channel");
  }
  get ConfirmHideChannelMessage() {
    return getString(
      "CONFIRM_HIDE_CHANNEL_MODAL",
      "Hiding this channel will close all active items. Would you still like to hide this channel?"
    );
  }
  get YesHideChannel() {
    return getString("YES_HIDE_CHANNEL", "Yes, Hide Channel");
  }
  get ChangeDisplayNameForChannel() {
    return getString("CHANGE_DISPLAY_NAME_FOR_CHANNEL", "Change the Display Name for This Channel");
  }
  WorkspaceSettings(workspaceName) {
    return formatString(getString("WORKSPACE_SETTINGS", "{workspaceName} Settings"), { workspaceName });
  }
  get Icon() {
    return getString("ICON", "Icon");
  }
  get ForCollaborationWith() {
    return getString("FOR_COLLABORATION_WITH", "For collaboration with");
  }
  get ConnectEmailAccountToSendAndReceiveMailFromWorkspace() {
    return getString(
      "CONNECT_EMAIL_ACCOUNT_TO_SEND_AND_RECEIVE_MAIL_FROM_WORKSPACE",
      "Connect an email account to send and receive emails from your workspace."
    );
  }
  get DeliveryPreference() {
    return getString("DELIVERY_PREFERENCE", "Delivery preference");
  }

  get TestIncomingEmail() {
    return getString("TestIncomingEmail", "Test incoming email");
  }

  get SSLProtocol() {
    return getString("SSLProtocol", "SSL protocol");
  }

  get TestOutgoingEmail() {
    return getString("TestOutgoingEmail", "Test outgoing email");
  }
  get WorkspaceAddress() {
    return getString("WORKSPACE_ADDRESS", "Workspace address: ");
  }
  get GmailAccount() {
    return getString("GMAIL_ACCOUNT", "Gmail account");
  }
  get ImapServer() {
    return getString("IMAP_SERVER", "Imap server");
  }
  get IMAPMethod() {
    return getString("ImapMethod", "Complete the form below to setup IMAP delivery for incoming emails.");
  }
  get SMTPMethod() {
    return getString("SMTPMethod", "Complete the form below to setup SMPT delivery for incoming emails.");
  }
  get RequiredFields() {
    return getString("RequiredFields", "Required fields");
  }
  get Inactive() {
    return getString("INACTIVE", "Inactive");
  }
  get RemoteEmailPlaceholder() {
    return getString("REMOTE_EMAIL_PLACEHOLDER", "Inbound email for this workspace - e.g. finance@mycompany.com");
  }
  get Fax() {
    return getString("FAX", "Fax");
  }
  get MailingAddress() {
    return getString("MAILING_ADDRESS", "Mailing Address");
  }
  get Notes() {
    return getString("NOTES", "Notes");
  }
  get NotesOptional() {
    return getString("NOTES_OPTIONAL", "Notes (optional)");
  }
  get ContactUs() {
    return getString("CONTACT_US", "Contact Us");
  }
  get YourInformation() {
    return getString("YOUR_INFORMATION", "Your Information");
  }
  get NoContactInfoYet() {
    return getString("NO_CONTACT_INFO_YET", "No contact info yet.");
  }
  get AddYourInformationSoWeKnowBestWayToReachYou() {
    return getString(
      "ADD_YOUR_INFORMATION_SO_WE_KNOW_BEST_WAY_TO_REACH_YOU",
      "Add your information so we know the best way to reach you."
    );
  }
  get AddNewContact() {
    return getString("ADD_NEW_CONTACT", "Add a new contact");
  }
  get Pay() {
    return getString("PAY", "Pay");
  }
  get SendMessage() {
    return getString("SEND_MESSAGE", "Send message");
  }
  get Dispute() {
    return getString("DISPUTE", "Dispute");
  }
  get PromiseToPay() {
    return getString("PROMISE_TO_PAY", "Promise to pay");
  }
  get Export() {
    return getString("EXPORT", "Export");
  }
  get MarkAsRead() {
    return getString("MARK_AS_READ", "Mark as read");
  }
  get MarkAsUnread() {
    return getString("MARK_AS_UNREAD", "Mark as unread");
  }
  get Due_Date() {
    return getString("DUE_DATE", "Due date");
  }
  get TransactionDate() {
    return getString("TRANSACTION_DATE", "Transaction date");
  }
  get Owner() {
    return getString("OWNER", "Owner");
  }
  get DateUploaded() {
    return getString("DATE_UPLOADED", "Date uploaded");
  }
  ShowNItems(numItems) {
    return formatString(getString("SHOW_N_ITEMS", "Show {numItems} items"), { numItems });
  }
  get DocumentUploading() {
    return getString("DOCUMENT_UPLOADING", "Document uploading");
  }
  get DocumentUploaded() {
    return getString("DOCUMENT_UPLOADED", "Document uploaded");
  }
  get DocumentFailedToUpload() {
    return getString("DOCUMENT_FAILED_TO_UPLOAD", "Document failed to upload");
  }
  get ZeroInvoices() {
    return getString("ZERO_INVOICES", "0 Invoices");
  }
  get DisputeInvoices() {
    return getString("DISPUTE_INVOICES", "Dispute invoices");
  }
  get Reason() {
    return getString("REASON", "Reason");
  }
  get MessageOptional() {
    return getString("MESSAGE_OPTIONAL", "Message (optional)");
  }
  get InvoicesSelectedForDispute() {
    return getString("INVOICES_SELECTED_FOR_DISPUTE", "Invoices selected for dispute");
  }
  get IncorrectInformation() {
    return getString("INCORRECT_IFORMATION", "Incorrect information");
  }
  get MissingInformation() {
    return getString("MISSING_INFORMATION", "Missing information");
  }
  get IncorrectPriceOrCharges() {
    return getString("INCORRECT_PRICE_OR_CHARGES", "Incorrect price or charges");
  }
  get DuplicateCharge() {
    return getString("DUPLICATE_CHARGE", "Duplicate charge");
  }
  get OrderCanceledOrIncomplete() {
    return getString("ORDER_CANCELED_OR_INCOMPLETE", "Order canceled or incomplete");
  }
  get OrderDamagedOrMissing() {
    return getString("ORDER_DAMAGED_OR_MISSING", "Order damaged or missing");
  }
  get Other() {
    return getString("OTHER", "Other");
  }
  get ExpectedPaymentDate() {
    return getString("EXPECTED_PAYMENT_DATE", "Expected payment date");
  }
  get PaymentSuccessfulMessage() {
    return getString("PAYMENT_SUCCESSFUL_MESSAGE", "Nice work, payment successful!");
  }
  get PaymentFailureMessage() {
    return getString("PAYMENT_FAILURE_MESSAGE", "Oops, something's not right!");
  }
  YourTransactionIdIs(transactionId) {
    return formatString(
      getString(
        "YOUR_TRANSACTION_ID_IS",
        "Your transaction ID is {transactionId}. If this opened a new window, you may close it."
      ),
      {
        transactionId
      }
    );
  }
  PaymentErrorIs(message) {
    return formatString(
      getString("PAYMENT_ERROR_IS", "It looks like {message}. Please go back and re-enter your payment information."),
      { message }
    );
  }
  get YourPaymentMethodWasAddedSuccessfully() {
    return getString(
      "PAYMENT_METHOD_SUCCESSFUL",
      "Your payment method was added successfully. If this opened a new window, you may close it."
    );
  }
  get InvoicesSelected() {
    return getString("INVOICES_SELECTED", "Invoices selected");
  }
  InvoicesPaid(num) {
    return formatString(getString("INVOICES_PAID", "Invoices paid ({num})"), { num });
  }
  get PastDue() {
    return getString("PAST_DUE", "Past due");
  }
  get ExportingTable() {
    return getString("EXPORTING_TABLE", "Exporting table");
  }
  get ExportComplete() {
    return getString("EXPORT_COMPLETE", "Export complete");
  }
  get ExportTableFailed() {
    return getString("EXPORT_TABLE_FAILED", "Export of table failed");
  }
  get PhoneNumber() {
    return getString("PHONE_NUMBER", "Phone number");
  }
  get MakePrimaryContact() {
    return getString("MAKE_PRIMARY_CONTACT", "Make primary contact");
  }
  get ContactIsBusiness() {
    return getString("CONTACT_IS_BUSINESS", "Contact is business");
  }
  get Person() {
    return getString("PERSON", "Person");
  }
  get Business() {
    return getString("BUSINESS", "Business");
  }
  get WereOnIt() {
    return getString("WERE_ON_IT", "We're on it!");
  }
  get OurSiteIsExperiencingSomeIssues() {
    return getString(
      "OUR_SITE_IS_EXPERIENCING_SOME_ISSUES",
      "Our site is currently experiencing some issues and we are working hard to fix them."
    );
  }
  get ConnectedBy() {
    return getString("CONNECTED_BY", "connected by");
  }
  get Disputed() {
    return getString("DISPUTED", "Disputed");
  }
  get Promised() {
    return getString("PROMISED", "Promised");
  }
  get ReasonForDispute() {
    return getString("REASON_FOR_DISPUTE", "Reason for dispute");
  }
  get SubmittingPromiseToPay() {
    return getString("SUBMITTING_PROMISE_TO_PAY", "Submitting promise to pay");
  }
  get PromiseToPaySubmitted() {
    return getString("PROMISE_TO_PAY_SUBMITTED", "Promise to pay submitted");
  }
  get FailedSubmittingPromiseToPay() {
    return getString("FAILED_SUBMITTING_PROMISE_TO_PAY", "Failed to submit promise to pay");
  }
  get SubmittingDispute() {
    return getString("SUBMITTING_DISPUTE", "Submitting dispute");
  }
  get DisputeSubmitted() {
    return getString("DISPUTE_SUBMITTED", "Dispute submitted");
  }
  get FailedSubmittingDispute() {
    return getString("FAILED_SUBMITTING_DISPUTE", "Failed to submit dispute");
  }
  get Primary() {
    return getString("PRIMARY", "Primary");
  }
  get DownloadInProgress() {
    return getString("DOWNLOAD_IN_PROGRESS", "Download in progress");
  }
  get DownloadComplete() {
    return getString("PRIMARY", "Download complete");
  }
  get DownloadFailed() {
    return getString("PRIMARY", "Download failed");
  }
  get ContactSubmitting() {
    return getString("CONTACT_SUBMITTING", "Contact submitting");
  }
  get ContactSubmitted() {
    return getString("CONTACT_SUBMITTED", "Contact submitted");
  }
  get ContactSubmitFailed() {
    return getString("CONTACT_SUBMIT_FAILED", "Contact submit failed");
  }
  get ContactDeleting() {
    return getString("CONTACT_DELETING", "Contact deleting");
  }
  get ContactDeleted() {
    return getString("CONTACT_DELETED", "Contact deleted");
  }
  get ContactDeleteFailed() {
    return getString("CONTACT_DELETE_FAILED", "Contact delete failed");
  }
  get Reset() {
    return getString("RESET", "Reset");
  }
  NTransactions(n) {
    return formatString(getString("N_TRANSACTIONS", "{n} transactions"), { n });
  }
  NApplications(n) {
    return formatString(getString("N_APPLICATIONS", "{n} applications"), { n });
  }
  get Apply() {
    return getString("APPLY", "Apply");
  }
  get ApplyToInvoice() {
    return getString("APPLY_TO_INVOICE", "Apply to invoice");
  }
  SelectInvoicesForPaymentApplication(paymentId) {
    return formatString(
      getString("SELECT_INVOICES_FOR_PAYMENT_APPLICATION", "Select invoices to apply to payment {paymentId}"),
      { paymentId }
    );
  }
  get OneApplication() {
    return getString("ONE_APPLICATION", "1 application");
  }
  InvoiceNAttachments(n) {
    return formatString(getString("INVOICE_N_ATTACHMENTS", "Invoice {n} - Attachments"), { n });
  }
  get InvoiceAttachments() {
    return getString("INVOICE_ATTACHMENTS", "Invoice attachments");
  }
  get CompanyStreetAddress() {
    return getString("COMPANY_STREET_ADDRESS", "Company street address");
  }
  get JobTitle() {
    return getString("JOB_TITLE", "Job title");
  }
  get StateRegion() {
    return getString("STATE_REGION", "State/region");
  }
  get ZipCode() {
    return getString("ZIP_CODE", "Zip code");
  }
  get CommunicationTemplates() {
    return getString("COMMUNICATION_TEMPLATES", "Communication templates");
  }
  get EmptyCommunicationTemplates() {
    return getString("EMPTY_COMMUNICATION_TEMPLATES", "There are no communication templates to show");
  }
  get LastEdited() {
    return getString("LAST_EDITED", "Last edited");
  }
  get FailedGetCommunicationTemplates() {
    return getString("FAILED_GET_COMMUNICATION_TEMPLATES", "Failed to get communication templates. Please try again.");
  }
  get FailedGetCommunicationTemplate() {
    return getString("FAILED_POST_COMMUNICATION_TEMPLATE", "Failed to save communication template. Please try again.");
  }
  get FailedDeleteCommunicationTemplate() {
    return getString(
      "FAILED_DELETE_COMMUNICATION_TEMPLATE",
      "Failed to delete communication template. Please try again."
    );
  }
  get FailedGetCommunicationTemplateDestinations() {
    return getString(
      "FAILED_GET_COMMUNICATION_TEMPLATE_DESTINATIONS",
      "Failed to get communication template destinations. Please try again."
    );
  }
  get FailedGetCommunicationTemplateSnippets() {
    return getString(
      "FAILED_GET_COMMUNICATION_TEMPLATE_SNIPPETS",
      "Failed to get communication template snippets. Please try again."
    );
  }
  get Voice() {
    return getString("VOICE", "Voice");
  }
  get Sequences() {
    return getString("SEQUENCES", "Sequences");
  }
  get SequenceName() {
    return getString("SEQUENCE_NAME", "Sequence name");
  }
  get Overview() {
    return getString("OVERVIEW", "Overview");
  }
  get CreateANewSequence() {
    return getString("CREATE_A_NEW_SEQUENCE", "Create a new sequence");
  }
  get ViewAndEditSequence() {
    return getString("VIEW_AND_EDIT_SEQUENCE", "View and edit sequence");
  }
  get CreateASequence() {
    return getString("CREATE_A_SEQUENCE", "Create a sequence");
  }
  get CreateMyOwn() {
    return getString("CREATE_MY_OWN", "Create my own");
  }
  get CustomSequenceMessage() {
    return getString("CUSTOM_SEQUENCE_MESSAGE", "Don't see what you're looking for? Create your own custom sequence.");
  }
  get RecentlyTriggeredSequenceSteps() {
    return getString("RECENTLY_TRIGGERED_SEQUENCE_STEPS", "Recently triggered sequence steps");
  }
  get Steps() {
    return getString("STEPS", "Steps");
  }
  get AddAStep() {
    return getString("ADD_A_STEP", "Add a step");
  }
  get ActiveSequences() {
    return getString("ACTIVE_SEQUENCES", "Active sequences");
  }
  get Trigger() {
    return getString("TRIGGER", "Trigger");
  }
  get CustomerGroupsAssigned() {
    return getString("CUSTOMER_GROUPS_ASSIGNED", "Customer groups assigned");
  }
  get ManageCustomerGroups() {
    return getString("MANAGE_CUSTOMER_GROUPS", "Manage customer groups");
  }
  get PhoneCall() {
    return getString("PHONE_CALL", "Phone call");
  }
  get TextMessage() {
    return getString("TEXT_MESSAGE", "Text message");
  }
  get CustomerGroups() {
    return getString("CUSTOMER_GROUPS", "Customer Groups");
  }
  get CreateNewGroup() {
    return getString("CREATE_NEW_GROUP", "Create a new group");
  }
  get GroupName() {
    return getString("GROUP_NAME", "Group Name");
  }
  get FailedGetAccountGroups() {
    return getString("FAILED_GET_ACCOUNT_GROUPS", "Failed to get account groups. Please try again.");
  }
  get FailedSaveAccountGroup() {
    return getString("FAILED_SAVE_ACCOUNT_GROUPS", "Failed to save account group. Please try again.");
  }
  get FailedDeleteAccountGroup() {
    return getString("FAILED_DELETE_ACCOUNT_GROUPS", "Failed to delete account group. Please try again.");
  }
  get FailedGetAccountGroupFilters() {
    return getString("FAILED_GET_ACCOUNT_GROUP_FILTERS", "Failed to get account group filters. Please try again.");
  }
  get EmptyCustomerGroups() {
    return getString("EMPTY_CUSTOMER_GROUPS", "There are no customer groups to show");
  }
  get NoCustomerGroupsSearchResultsMessage() {
    return getString(
      "NO_CUSTOMER_GROUPS_SEARCH_RESULTS_MESSAGE",
      "Oops! We can't find what you're looking for. You can search for a group name."
    );
  }
  get NoTemplatesSearchResultsMessage() {
    return getString(
      "NO_TEMPLATES_SEARCH_RESULTS_MESSAGE",
      "Oops! We can't find what you're looking for. You can search for a template name."
    );
  }
  SeeAllResultsFor(searchTerm) {
    return formatString(getString("SEE_ALL_RESULTS_FOR", 'See all results for "{searchTerm}"'), {
      searchTerm
    });
  }
  get ViewAndEditGroup() {
    return getString("VIEW_AND_EDIT_GROUP", "View and edit group");
  }
  get FailedGetCustomers() {
    return getString("FAILED_GET_CUSTOMERS", "Failed to get customers. Please try again.");
  }
  get CustomerId() {
    return getString("CUSTOMER_ID", "Customer id");
  }
  get CustomerName() {
    return getString("CUSTOMER_NAME", "Customer name");
  }
  get SalesPerson() {
    return getString("SALES_PERSON", "Sales person");
  }
  get TotalAging() {
    return getString("TOTAL_AGING", "Total aging");
  }
  get Static() {
    return getString("STATIC", "Static");
  }
  get Dynamic() {
    return getString("DYNAMIC", "Dynamic");
  }
  get GroupType() {
    return getString("GROUP_TYPE", "Group type");
  }
  get Filters() {
    return getString("FILTERS", "Filters");
  }
  get AddFilter() {
    return getString("ADD_FILTER", "Add a filter");
  }
  get EmptyCustomers() {
    return getString("EMPTY_CUSTOMERS", "There are no customers to show.");
  }
  get AccountGroupsInfo() {
    return getString(
      "ACCOUNT_GROUPS_INFO",
      `<p>
    <b>An account can only be assigned to one group.</b>
  </p>
  <p>If an account is specified in a static group, then the account will be assigned to the static group.</p>
  <p>
    If not in a static group but is in multiple dynamic groups, then the account will be assigned to the dynamic
    group that was created first
  </p>`
    );
  }
  get Equal() {
    return getString("EQUAL", "Equal");
  }
  get GreaterThan() {
    return getString("GREATER_THAN", "Greater than");
  }
  get LessThan() {
    return getString("LESS_THAN", "Less than");
  }
  get AutomaticPaymentsAreOn() {
    return getString("AUTOMATIC_PAYMENTS_ARE_ON", "Automatic payments are on");
  }
  get AutomaticPaymentsAreOff() {
    return getString("AUTOMATIC_PAYMENTS_ARE_OFF", "Automatic payments are off");
  }
  get WeWillPayAllOpenInvoicesOnTheirGivenPaymentDates() {
    return getString(
      "WE_WILL_PAY_ALL_OPEN_INVOICES_ON_THEIR_GIVEN_PAYMENT_DATE",
      "We will pay all open invoices on their given payment dates."
    );
  }
  get WhenEnabledWeWillPayAllOpenInvoicesOnTheirGivenPaymentDates() {
    return getString(
      "WHEN_ENABLED_WE_WILL_PAY_ALL_OPEN_INVOICES_ON_THEIR_GIVEN_PAYMENT_DATE",
      "When enabled, we will pay all open invoices on their given payment dates."
    );
  }
  get ManageAutomaticPayments() {
    return getString("MANAGE_AUTOMATIC_PAYMENTS", "Manage automatic payments");
  }
  get WhenPaymentIsMadeHowWouldYouLikeToBeNotified() {
    return getString(
      "WHEN_PAYMENT_IS_MADE_HOW_WOULD_YOU_LIKE_TO_BE_NOTIFIED",
      "When a payment is made, how would you like to be notified?"
    );
  }
  get PortalMessage() {
    return getString("PORTAL_MESSAGE", "Portal message");
  }
  get EmailDefaultAddressOnFile() {
    return getString("EMAIL_DEFAULT_ADDRESS_ON_FILE", "Email (default address on file)");
  }
  get AutomaticPayments() {
    return getString("AUTOMATIC_PAYMENTS", "Automatic payments");
  }
  get AutoPayConfigurationError() {
    return getString("AUTO_PAY_CONFIG_ERROR", "There was a problem saving your auto pay settings. Please try again.");
  }
  get WeWillPayAllOpenInvoicesOnTheirGivenDueDateUsingThePaymentMethodSelectedAbove() {
    return getString(
      "WE_WILL_PAY_ALL_OPNE_INVOICES_ON_THEIR_GIVEN_DUE_DATE_USING_THE_PAYMENT_METHOD_SELECTED_ABOVE",
      "We will pay all open invoices on their given due date using the payment method selected above."
    );
  }
  get CheckingForUnappliedPayments() {
    return getString("CHECKING_FOR_UNAPPLIED_PAYMENTS", "Checking for unapplied payments...");
  }
  get PortalClosedInvoicesEmpty() {
    return getString("PORTAL_CLOSED_INVOICES_EMPTY", "Once you've closed an invoice it will show up here.");
  }
  get NothingToSeeHere() {
    return getString("NOTHING_TO_SEE_HERE", "Nothing to see here!");
  }
  get NiceWorkYoureAllCaughtUp() {
    return getString("NICE_WORK_YOURE_ALL_CAUGHT_UP", "Nice work, you're all caught up!");
  }
  get PortalOpenInvoicesEmpty() {
    return getString("PORTAL_OPEN_INVOICES_EMPTY", "You have 0 open invoices.");
  }
  get PortalDocumentsEmpty() {
    return getString(
      "PORTAL_DOCUMENTS_EMPTY",
      "Upload documents like your W9 so you can keep everything in one place."
    );
  }
  get PortalPaymentsEmpty() {
    return getString("PORTAL_PAYMENTS_EMPTY", "Once you've made a payment it will show up here.");
  }
  get YourInboxIsEmpty() {
    return getString("YOUR_INBOX_IS_EMPTY", "Your inbox is empty!");
  }
  get ThisInboxIsEmpty() {
    return getString("THIS_INBOX_IS_EMPTY", "This inbox is empty!");
  }
  get Communications() {
    return getString("COMMUNICATIONS", "Communications");
  }
  get TotalUnappliedPaymentAmount() {
    return getString("TOTAL_UNAPPLIED_PAYMENT_AMOUNT", "Total unapplied payment amount");
  }
  HelloCompanyNameWelcomeBack(companyName) {
    return formatString(getString("HELLO_COMPANY_NAME_WELCOME_BACK", "Hello {companyName}, welcome back."), {
      companyName
    });
  }
  HelloCompanyNameIdWelcomeBack(companyName, companyId) {
    return formatString(
      getString("HELLO_COMPANY_NAME_ID_WELCOME_BACK", "Hello {companyName} ({companyId}), welcome back."),
      { companyName, companyId }
    );
  }
  get YourSessionHasExpired() {
    return getString("YOUR_SESSION_HAS_EXPIRED", "Your session has expired.");
  }
  get PortalSessionExpired() {
    return getString(
      "PORTAL_SESSION_EXPIRED",
      "For security purposes, we end a session after 12 hours. Please click the link in your email to start a new session."
    );
  }
  get MakePaymentFlyoutFilteredOutCreditMemo() {
    return getString(
      "MAKE_PAYMENT_FLYOUT_FILTERED_OUT_CREDIT_MEMO",
      "We noticed you selected a credit memo for this payment. Payments on credit memos cannot be made, so that selection is not shown here."
    );
  }
  get NA() {
    return getString("NA", "N/A");
  }
  get PreviewAccounts() {
    return getString("PREVIEW_ACCOUNTS", "Preview accounts");
  }
  get DynamicGroupsExplanation() {
    return getString(
      "DYNAMIC_GROUPS_EXPLANATION",
      "Dynamic groups are updated daily to include accounts that meet the below filter criteria."
    );
  }
  get StaticGroupsExplanation() {
    return getString(
      "STATIC_GROUPS_EXPLANATION",
      "Static groups are set based on the acccounts you select from the table below."
    );
  }
  get CreateANewAccountGroup() {
    return getString("CREATE_A_NEW_ACCOUNT_GROUP", "Create a new account group");
  }
  get AccountGroups() {
    return getString("ACCOUNT_GROUPS", "Account Groups");
  }
  get AccountGroupPreview() {
    return getString("ACCOUNT_GROUP_PREVIEW", "Account Group Preview");
  }
  get PreviewGroup() {
    return getString("PREVIEW_GROUP", "Preview group");
  }
  get SaveGroup() {
    return getString("SAVE_GROUP", "Save group");
  }
  get DeleteGroup() {
    return getString("DELETE_GROUP", "Delete group");
  }
  get AccountGroupPreviewEmpty() {
    return getString("ACCOUNT_GROUP_PREVIEW_EMPTY", "We can't find any accounts that match your filter criteria.");
  }
  get OopsThereIsAnIssueAccessingYourAccount() {
    return getString(
      "OOPS_THERE_IS_AN_ISSUE_ACCESSING_YOUR_ACCOUNT",
      "Oops! There's an issue with accessing your account."
    );
  }
  get PleaseContactSupportToReportThisError() {
    return getString(
      "PLEASE_CONTACT_SUPPORT_TO_REPORT_THIS_ERROR",
      "Please contact support@lockstephq.com to report this error and get it fixed."
    );
  }
  GetLanguageFailure(languageCode) {
    return formatString(
      getString(
        "GET_LANGUAGE_FAILURE",
        "Oops! There was an issue getting language {languageCode}. Please refresh the page. If this issue persist, please contact us at support@lockstephq.com."
      ),
      { languageCode }
    );
  }
  get GetLanguagesFailure() {
    return getString(
      "GET_LANGUAGES_FAILURE",
      "Oops! There was an issue getting all languages. Please refresh the page. If this issue persist, please contact us at support@lockstephq.com."
    );
  }

  get GetDisputeCodeFailure() {
    return getString(
      "GET_DISPUTECODE_FAILURE",
      "Oops! There was an issue getting all disputecodes. Please refresh the page. If this issue persist, please contact us at support@lockstephq.com."
    );
  }

  get Language() {
    return getString("LANGUAGE", "Language");
  }
  get InternalContacts() {
    return getString("INTERNAL_CONTACTS", "Internal Contacts");
  }
  get ExternalContacts() {
    return getString("EXTERNAL_CONTACTS", "External Contacts");
  }
  get ContactSettings() {
    return getString("CONTACT_SETTINGS", "Contact Settings");
  }
  get AccountSettings() {
    return getString("ACCOUNT_SETTINGS", "Account Settings");
  }
  get OpenOnboardingRequests() {
    return getString("OPEN_ONBOARDING_REQUESTS", "Open onboarding requests");
  }
  get OpenProfileUpdateRequests() {
    return getString("OPEN_PROFILE_UPDATE_REQUESTS", "Open profile update requests");
  }
  get NetworkActivity() {
    return getString("NETWORK_ACTIVITY", "Network Activity");
  }
  get TotalNumberOfAccounts() {
    return getString("TOTAL_NUMBER_OF_ACCOUNTS", "Total number of accounts");
  }
  get LockstepMemberAccounts() {
    return getString("LOCKSTEP_MEMBER_ACCOUNTS", "Lockstep member accounts");
  }
  get LockstepVerifiedAccounts() {
    return getString("LOCKSTEP_VERIFIED_ACCOUNTS", "Lockstep verified accounts");
  }
  get UnverifiedAccounts() {
    return getString("UNVERIFIED_ACCOUNTS", "Unverified accounts");
  }
  get LastUpdated() {
    return getString("LAST_UPDATED", "Last updated");
  }
  LastUpdatedDate(date) {
    return formatString(getString("LAST_UPDATED_DATE", "Last updated {date}"), { date });
  }
  get TotalNumberOfContacts() {
    return getString("TOTAL_NUMBER_OF_CONTACTS", "Total number of contacts");
  }
  get LockstepMemberContacts() {
    return getString("LOCKSTEP_MEMBER_CONTACTS", "Lockstep member contacts");
  }
  get LockstepVerifiedContacts() {
    return getString("LOCKSTEP_VERIFIED_CONTACTS", "Lockstep verified contacts");
  }
  get UnverifiedContacts() {
    return getString("UNVERIFIED_CONTACTS", "Unverified contacts");
  }
  get MyNetwork() {
    return getString("MY_NETWORK", "My network");
  }
  get Open() {
    return getString("OPEN", "Open");
  }
  get AttachPromiseToPayInvoices() {
    return getString("ATTACH_PROMISE_TO_PAY_INVOICES", "Attach promise to pay invoices");
  }
  get AttachDisputedInvoices() {
    return getString("ATTACH_DISPUTED_INVOICES", "Attach disputed invoices");
  }
  get OpenRequests() {
    return getString("OPEN_REQUESTS", "Open requests");
  }
  get Onboarding() {
    return getString("ONBOARDING", "Onboarding");
  }
  get InitialRequest() {
    return getString("INITIAL_REQUEST", "Initial request");
  }
  get LatestReminder() {
    return getString("LATEST_REMINDER", "Latest reminder");
  }
  get NextReminder() {
    return getString("NEXT_REMINDER", "Next reminder");
  }
  get AccountProfiles() {
    return getString("ACCOUNT_PROFILES", "Account profiles");
  }
  get SeeAll() {
    return getString("SEE_ALL", "See all");
  }
  SearchResultsNum(numSearchResults) {
    return formatString(getString("SEARCH_RESULTS_NUM", "Search results ({numSearchResults})"), { numSearchResults });
  }
  get RequestProfileUpdate() {
    return getString("REQUEST_PROFILE_UPDATE", "Request profile update");
  }
  get NoProfileSearchResultsMessage() {
    return getString(
      "NO_PROFILE_SEARCH_RESULTS_MESSAGE",
      "Oops! We can't find what you're looking for. You can search for any profile name."
    );
  }
  get SendingProfileUpdateRequest() {
    return getString("SENDING_PROFILE_UPDATE_REQUEST", "Sending profile update request");
  }
  get ProfileUpdateRequestSent() {
    return getString("PROFILE_UPDATE_REQUEST_SENT", "Profile update request sent");
  }
  get FailedToSendProfileUpdateRequest() {
    return getString("FAILED_TO_SEND_PROFILE_UPDATE_REQUEST", "Failed to send profile update request");
  }
  UpdatedDate(date) {
    return formatString(getString("UPDATED_DATE", "Updated {date}"), { date });
  }
  get LoadingDisputeCodes() {
    return getString("LOADING_DISPUTECODES", "Loading Dispute Codes...");
  }
  get SeeMore() {
    return getString("SEE_MORE", "See more");
  }
  get ProfileUpdateThanks() {
    return getString(
      "PROFILE_UPDATE_THANKS",
      "Thanks for taking a few minutes to confirm your profile information with us! How does everything look?"
    );
  }
  ProfileUpdateContacts(companyName) {
    return formatString(
      getString("PROFILE_UPDATE_CONTACTS", "Great, do all of these contacts still work for {companyName}?"),
      {
        companyName
      }
    );
  }
  get ProfileUpdateDocuments() {
    return getString("PROFILE_UPDATE_DOCUMENTS", "Last question, are the documents we have on file up-to-date?");
  }
  get ProfileUpdateSuccess() {
    return getString("PROFILE_UPDATE_SUCESS", "Nice work, thanks for confirming your information!");
  }
  get GetAccountListFailure() {
    return getString(
      "GET_ACCOUNTLIST_FAILURE",
      "Oops! There was an issue getting the account list. Please refresh the page. If this issue persist, please contact us at support@lockstephq.com."
    );
  }
  get GetContactListFailure() {
    return getString(
      "GET_CONTACTLIST_FAILURE",
      "Oops! There was an issue getting the contact list. Please refresh the page. If this issue persist, please contact us at support@lockstephq.com."
    );
  }
  get PrefilledInfo() {
    return getString(
      "PREFILLED_INFO",
      "We've prefilled the information we have on file, edit and save anything that is out of date."
    );
  }
  get DocumentType() {
    return getString("DOCUMENT_TYPE", "Document Type");
  }
  get File() {
    return getString("FILE", "Files");
  }
  get LockstepSucess() {
    return getString("LOCKSTEP_SUCESS", "Nice work, thanks for confirming your information!");
  }
  get ein() {
    return getString("EIN", "EIN");
  }
  get EntityClassification() {
    return getString("ENTITY_CLASSIFICATION", "Entity classification");
  }
  get Logo() {
    return getString("LOGO", "Company Logo");
  }
  get BillToStreet() {
    return getString("BILL_STREET", "Bill-to street address");
  }
  get SameAsBilling() {
    return getString("SAME_AS_BILLING", "Same as billing address");
  }
  get ShipToStreet() {
    return getString("SHIP_STREET", "Ship-to street address");
  }
  get AddContactsMessage() {
    return getString(
      "ADD_CONTACTS",
      "Next, can you please verify who the primary and secondary contacts are on your team? Feel free to add additional important contacts if you’d like."
    );
  }
  get SecondaryContact() {
    return getString("SECONDARY_CONTACT", "Secondary contact");
  }
  get DocumentUploadForm() {
    return getString(
      "DOCUMENT_UPLOAD_MESSAGE",
      "Last step! We’d like to be sure we have your most up to date documents on file. Please upload the documents listed below, you may upload multiple files per document type."
    );
  }
  ProfileUpdateRequestFlyoutPreview(amountProfiles) {
    return formatString(
      getString(
        "PROFILE_UPDATE_REQUEST_FLYOUT_PREVIEW",
        "Here is a preview of the request that will go out to the {amountProfiles} profiles you’ve selected. If you are an admin user, you can change the button color and logo in the profile and settings section."
      ),
      {
        amountProfiles
      }
    );
  }
  get SendRequests() {
    return getString("SEND_REQUESTS", "Send Requests");
  }
  get UseSSL() {
    return getString("UseSSL", "Use SSL");
  }
  get StartTLS() {
    return getString("StartTLS", "Start TLS");
  }
  get AboutSendRequets() {
    return getString(
      "ABOUT_SEND",
      "Woohoo! You’re about to send your first batch of profile update requests. Soon you’ll have a complete set of community sourced master data that is as accurate as possible."
    );
  }
  get JustStartingOutMessage() {
    return getString(
      "JUST_STARTING_OUT",
      "Since you are just starting out with Lockstep we recommend you send a profile update request to all of the email addresses we have on file. Once you select the profiles you’d like updates from, we will show you a preview of the email they will recieve."
    );
  }
  ProfileUpdateToAll(amountProfiles) {
    return formatString(
      getString(
        "PROFILE_UPDATE_TO_ALL",
        "We will send a profile update request to the {amountProfiles} profiles we have on file."
      ),
      {
        amountProfiles
      }
    );
  }
  ProfileUpdateHeader(name) {
    return formatString(getString("PROFILE_UPDATE_HEADER", "Hello {name}, welcome!"), {
      name
    });
  }
  get ProfileUpdateToSome() {
    return getString(
      "PROFILE_UPDATE_TO_SOME",
      "We will send a profile update request to the profiles you select from the table below."
    );
  }
  get homeViewEmptyMessage() {
    return getString(
      "HOME_VIEW_EMPTY_MESSAGE",
      "You’ll see your network activity here when profile updates are made. We recommend sending all of your customers a profile update request to get started."
    );
  }
  AccountProfilesNum(num) {
    return formatString(getString("ACCOUNT_PROFILES_NUM", "Account profiles ({num})"), {
      num
    });
  }
  ContactProfilesNum(num) {
    return formatString(getString("CONTACT_PROFILES_NUM", "Contact profiles ({num})"), {
      num
    });
  }
  LastGeneratedDate(date) {
    return formatString(getString("LAST_GENERATED_DATE", "Last generated {date}"), {
      date
    });
  }
  get NewActivity() {
    return getString("NEW_ACTIVITY", "New activity");
  }

  get AdditionalContacts() {
    return getString("ADDITIONAL_CONTACTS", "Additional contacts");
  }

  TransactionsAppliedToInvoice(invoice) {
    return formatString(getString("TRANSACTIONS_APPLIED_TO_INVOICE", "Transactions applied to invoice #{invoice}"), {
      invoice
    });
  }

  InvoicesAppliedToPayment(payment) {
    return formatString(getString("INVOICES_APPLIED_TO_PAYMENT", "Invoices applied to payment {payment}"), {
      payment
    });
  }
  get GetCurrenciesFailure() {
    return getString(
      "GET_CURRENCIES_FAILER",
      "Oops! There was an issue getting currencies. Please refresh the page. If this issue persist, please contact us at support@lockstephq.com."
    );
  }

  ProfileUpdateWelcomeFromATC(name) {
    return formatString(
      getString(
        "PROFILE_UPDATE_ATC_WECLOME",
        "Hi {name} welcome to Lockstep! Lockstep is your one-stop shop for everything contact and document managment related."
      ),
      {
        name
      }
    );
  }
  get WeAreExcitedToHaveYou() {
    return getString("WE_ARE_EXCITED", "We are excited to have your as a member of our network, ready to get going?");
  }
  get RequestProfileUpdates() {
    return getString("REQUEST_PROFILE_UPDATES", "Request profile updates");
  }
  get YesLetsDoIt() {
    return getString("YES_EXCITED", "Yes, let's do it!");
  }
  get ARDepartmentStreet() {
    return getString("AR_DEPARTMENT_STREET", "AR department street address");
  }
  get PrimaryButtonColor() {
    return getString("PRIMARY_BUTTON_COLOR", "Primary button color");
  }
  get INeedToUpdateContacts() {
    return getString("I_NEED_TO_UPDATE_CONTACTS", "I need to update these contacts");
  }
  DoAllTheseContactsStillWorkFor(companyName) {
    return formatString(
      getString(
        "DO_ALL_THESE_COMPANIES_STILL_WORK_FOR",
        "Great, do all of these contacts still work for {companyName}?"
      ),
      {
        companyName
      }
    );
  }
  get ShareProfile() {
    return getString("SHARE_PROFILE", "Share profile");
  }
  get EditProfile() {
    return getString("EDIT_PROFILE", "Edit profile");
  }
  get NoDocumentsHaveBeenAddedYet() {
    return getString("NO_DOCUMENTS_HAVE_BEEN_ADDED_YET", "No documents have been added yet.");
  }
  get NoContactsHaveBeenAddedYet() {
    return getString("NO_CONTACTS_HAVE_BEEN_ADDED_YET", "No contacts have been added yet.");
  }
  get RequestProfileUpdateToHaveAccountAddDocumentsToProfile() {
    return getString(
      "REQUEST_PROFILE_UPDATE_TO_HAVE_ACCOUNT_ADD_DOCUMENTS_TO_PROFILE",
      "Request a profile update to have this account add documents to their profile."
    );
  }
  get RequestProfileUpdateToHaveAccountAddContactsToProfile() {
    return getString(
      "REQUEST_PROFILE_UPDATE_TO_HAVE_ACCOUNT_ADD_CONTACTS_TO_PROFILE",
      "Request a profile update to have this account add contacts to their profile."
    );
  }
  get Finance() {
    return getString("FINANCE", "Finance");
  }
  SentContactUpdateRequests(name, count) {
    return formatString(
      getString("NAME_SENT_COUNT_CONTACT_UPDATE_REQUESTS", "{name} sent {count} contact update requests."),
      {
        name,
        count
      }
    );
  }
  SentContactUpdateRequest(name) {
    return formatString(getString("NAME_SENT_CONTACT_UPDATE_REQUEST", "{name} sent a contact update request."), {
      name
    });
  }
  SentProfileUpdateRequests(name, count) {
    return formatString(
      getString("NAME_SENT_COUNT_PROFILE_UPDATE_REQUESTS", "{name} sent {count} profile update requests."),
      {
        name,
        count
      }
    );
  }
  SentProfileUpdateRequest(name) {
    return formatString(getString("NAME_SENT_PROFILE_UPDATE_REQUEST", "{name} sent a profile update request."), {
      name
    });
  }
  UpdatedContactInfo(name) {
    return formatString(getString("NAME_UPDATED_CONTACT_INFO", "{name} updated their contact information."), {
      name
    });
  }
  UpdatedProfileInfo(name) {
    return formatString(getString("NAME_UPDATED_PROFILE_INFO", "{name} updated their profile information."), {
      name
    });
  }
  get InvalidActivityType() {
    return getString("INVALID_ACTIVITY_TYPE", "Invalid activity type");
  }
  get InvalidPhoneNumber() {
    return getString("INVALID_PHONE_NUMBER", "Invalid phone number");
  }
  get InvalidFaxNumber() {
    return getString("INVALID_FAX_NUMBER", "Invalid fax number");
  }
  get InvalidEIN() {
    return getString("INVALID_EIN", "Invalid EIN");
  }
  get InvalidDomain() {
    return getString("INVALID_DOMAIN", "Invalid domain");
  }
  get InvalidZipCode() {
    return getString("INVALID_ZIP_CODE", "Invalid zip code");
  }
  get PleaseEnsureCompanyNameAndEmailAddrFilledOut() {
    return getString(
      "PLEAE_ENSURE_COMPANY_NAME_AND_EMAIL_FILLED_OUT",
      "Please ensure that the Company Name and AR Department E-mail fields are filled out."
    );
  }
  get PleaseEnsureNameEmailAndRoleFilledOut() {
    return getString(
      "PLEASE_ENSURE_NAME_EMAIL_AND_ROLE_FILLED_OUT",
      "Please ensure that the Contact Name and Email fields are both filled out for all contacts you wish to add."
    );
  }
  get YouHaveInvalidDataPleaseEnsureAllFieldsValid() {
    return getString(
      "YOU_HAVE_INVALID_DATA_PLEASE_ENSURE_ALL_FIELDS_VALID",
      "You have invalid data, please ensure all fields are valid before submitting."
    );
  }
  PleaseEnsureFormsFilledOut(form) {
    return formatString(
      getString("PLEAE_ENSURE_NAME_AND_EMAIL_FILLED_OUT", "Please ensure that the {form} fields are filled out"),
      {
        form: form
      }
    );
  }
  PleaseEnsureFormFilledOut(form) {
    return formatString(
      getString("PLEAE_ENSURE_NAME_AND_EMAIL_FILLED_OUT", "Please ensure that the {form} field is filled out"),
      {
        form: form
      }
    );
  }
  get ViewProfile() {
    return getString("VIEW_PROFILE", "View profile");
  }
  get ViewOpenRequests() {
    return getString("VIEW_OPEN_REQUESTS", "View open requests");
  }
  get UpdatedYourContactInfo() {
    return getString("YOU_UPDATED_YOUR_CONTACT_INFO", "You updated your contact information.");
  }
  get GettingLink() {
    return getString("GETTING_LINK", "Getting link");
  }
  get LinkCopied() {
    return getString("LINK_COPIED", "Link copied");
  }
  get FailedCopyLink() {
    return getString("FAILED_COPY_LINK", "Failed to copy link");
  }
  get SendInMessage() {
    return getString("SEND_IN_MESSAGEE", "Send in message");
  }
  get CopyLink() {
    return getString("COPY_LINK", "Copy link");
  }
  get UploadingDocument() {
    return getString("UPLOADING_DOCUMENT", "Uploading document");
  }
  get UploadedDocument() {
    return getString("UPLOADED_DOCUMENT", "Uploaded document");
  }
  get UploadDocumentFailed() {
    return getString("UPLOAD_FAILED_DOCUMENT", "Uploading document failed");
  }
  get UploadingImage() {
    return getString("UPLOADING_IMAGE", "Uploading image");
  }
  get UploadedImage() {
    return getString("UPLOADED_IMAGE", "Uploaded image");
  }
  get UploadingImageFailed() {
    return getString("UPLOAD_FAILED_IMAGE", "Uploading image failed");
  }
  get FailedGetCustContact() {
    return getString("FAILED_GET_CUST_CONTACT", "Failed to get customer contact");
  }
  get FailedPostCustContact() {
    return getString("FAILED_POST_CUST_CONTACT", "Failed to post customer contact");
  }
  get FailedGetCustAccount() {
    return getString("FAILED_GET_CUST_ACCOUNT", "Failed to get account information");
  }
  get FailedPostCustAccount() {
    return getString("FAILED_POST_CUST_ACCOUNT", "Failed to post account information");
  }
  get FailedGetCustAccountDocuments() {
    return getString("FAILED_GET_ACCOUNT_DOCUMENTS", "Getting account documents failed");
  }
  get FailedGetCustAccountContacts() {
    return getString("FAILED_GET_CUST_ACCOUNT_CONTACTS", "Getting account contacts failed");
  }
  get FailedGetNotes() {
    return getString("FAILED_GET_NOTES", "Failed to get notes");
  }
  get FailedPostNotes() {
    return getString("FAILED_POST_NOTES", "Failed to post notes");
  }
  get FailedDeleteNote() {
    return getString("FAILED_DELETE_NOTE", "Failed delete note");
  }
  get FailedGetShareFlyout() {
    return getString("FAILED_GET_SHARE_FLYOUT", "Failed to get share information");
  }
  get FailedSendMessage() {
    return getString("FAILED_SEND_MESSAGE", "Failed to send message");
  }
  get FailedGetShareLink() {
    return getString("FAILED_GET_SHARE_LINK", "Failed to get shareable link");
  }
  get FailedGetSubsidiaryList() {
    return getString("FAILED_GET_SUBSIDIARY_LIST", "Failed to get the list of subsidiaries");
  }
  IfYourVARIABLEIsDifferentContactUsAtEMAIL(variable, email) {
    return formatString(
      getString(
        "IF_YOUR_VARIABLE_IS_DIFFERENT_CONTACT_US_AT_EMAIL",
        "If your {variable} is different from what's listed here please contact us"
      ),
      {
        variable: variable,
        email: email
      }
    );
  }
  FieldMustBeLessThanN(n) {
    return formatString(getString("FIELD_MUST_BE_LESS_THAN_N", "The field must be {n} characters or less"), {
      n: n
    });
  }
  get BillTo() {
    return getString("BILL_TO", "Bill-to");
  }
  get ShipTo() {
    return getString("SHIP_TO", "Ship-to");
  }
  get NothingToSeeHereNotes() {
    return getString(
      "NOTHING_TO_SEE_HERE_NOTES",
      "Nothing to see here yet! Add a note about this profile for you and your team to see."
    );
  }
  get FormFields() {
    return getString("FORM_FIELDS", "Form fields");
  }
  FormFieldsDescription(sequenceType) {
    return formatString(
      getString(
        "THESE_ARE_THE_FIELDS_THAT_YOUR_CUSTOMER_WILL_BE_ASKED_TO_FILL_OUT_FOR_SEQUENCETYPE",
        "These are the fields that your customer will be asked to fill out in the {sequenceType} request"
      ),
      {
        sequenceType: sequenceType
      }
    );
  }

  get FieldName() {
    return getString("FIELD_NAME", "Field Name");
  }

  get Required() {
    return getString("REQUIRED", "required");
  }

  get FreeformText() {
    return getString("FREEFORM_TEST", "Freeform text");
  }

  get DropdownSelection() {
    return getString("DROPDOWN_SELECTION", "Dropdown selection");
  }

  get FileUpload() {
    return getString("FILE_UPLOAD", "File upload");
  }

  get CompanyLogo() {
    return getString("COMPANY_LOGO", "Company logo");
  }

  get PrimaryContactName() {
    return getString("PRIMARY_CONTACT_NAME", "Primary contact name");
  }
  get PrimaryContactRole() {
    return getString("PRIMARY_CONTACT_ROLE", "Primary contact role");
  }
  get PrimaryContactEmail() {
    return getString("PRIMARY_CONTACT_EMAIL", "Primary contact email");
  }
  get PrimaryContactPhone() {
    return getString("PRIMARY_CONTACT_PHONE", "Primary contact phone");
  }
  get SecondaryContactName() {
    return getString("SECONDARY_CONTACT_NAME", "Secondary contact name");
  }
  get SecondaryContactRole() {
    return getString("SECONDARY_CONTACT_ROLE", "Secondary contact role");
  }
  get SecondaryContactEmail() {
    return getString("SECONDARY_CONTACT_EMAIL", "Secondary contact email");
  }
  get SecondaryContactPhone() {
    return getString("SECONDARY_CONTACT_PHONE", "Secondary contact phone");
  }
  get CreditApplication() {
    return getString("CREDIT_APPLICATION", "Credit application");
  }
  get ProofOfInsurance() {
    return getString("PROOF_OF_INSURANCE", "Proof of insurance");
  }
  get TaxExemptionCertificate() {
    return getString("TAX_EXEMPTION_CERTIFICATE", "Tax exemption certificate");
  }
  get BillToAddress() {
    return getString("BILL_TO_ADDRESS", "Bill to address");
  }
  get ShipToAddress() {
    return getString("SHIP_TO_ADDRESS", "Ship to address");
  }
  get AllAccountsAndContacts() {
    return getString("ALL_ACCOUNTS_AND_CONTACTS", "All accounts and contacts");
  }
  get SignaturesExplanation() {
    return getString(
      "SIGNATURES_EXPLANATION",
      "Your personal user signature will automatically be added to the bottom of every activity you make inside Lockstep and ATC."
    );
  }
  get ProfileName() {
    return getString("PROFILE_NAME", "Profile name");
  }
  get Contains() {
    return getString("CONTAINS", "Contains");
  }
  get LockstepVerified() {
    return getString("LOCKSTEP_VERIFIED", "Lockstep verified");
  }
  get ClearAll() {
    return getString("CLEAR_ALL", "Clear all");
  }
  get JustNow() {
    return getString("JUST_NOW", "just now");
  }
  get MarkAsPrivate() {
    return getString("MARK_AS_PRIVATE", "Mark as private");
  }
  get MarkAsPublic() {
    return getString("MARK_AS_PUBLIC", "Mark as public");
  }
  get Private() {
    return getString("PRIVATE", "Private");
  }
  get LockstepVerifiedInformation() {
    return getString(
      "LOCKSTEP_VERIFIED_INFORMATION",
      "Lockstep verified profiles have been validated by the person that owns the profile. This means the information in these profiles is current and up to date."
    );
  }
  get INeedToUpdateThisProfile() {
    return getString("I_NEED_TO_UPDATE_THIS_PROFILE", "I need to update this profile");
  }
  get LooksGood() {
    return getString("LOOKS_GOOD_!", "Looks good!");
  }
  get INeedToUpdateTheseDocuments() {
    return getString("I_NEED_TO_UPDATE_THESE_DOCUMENTS", "I need to update these documents");
  }
  get LastQuestionDocumentsOnFileUpToDate() {
    return getString(
      "LAST_QUESTION_DOCUMENTS_ON_FILE_UP_TO_DATE",
      "Last question, are the documents on file up to date?"
    );
  }
  get YesTheyDo() {
    return getString("YES_THEY_DO", "Yes they do!");
  }
  get YesTheyAre() {
    return getString("YES_THEY_ARE", "Yes they are!");
  }
  IncorporatedInStateDate(state, date) {
    return formatString(getString("INCORPORATED_IN_STATE_DATE", "Incorporated in {state}, {date}"), {
      state: state,
      date: date
    });
  }
  IncorporatedInState(state) {
    return formatString(getString("INCORPORATED_IN_STATE", "Incorporated in {state}"), {
      state: state
    });
  }
  IncorporatedDate(date) {
    return formatString(getString("INCORPORATED_DATE", "Incorporated {date}"), {
      date: date
    });
  }
  get Snooze() {
    return getString("SNOOZE", "Snooze");
  }
  get AssigningConversations() {
    return getString("ASSIGNING_CONVERSATIONS", "Assigning conversations");
  }
  get AssignedConversations() {
    return getString("ASSIGNED_CONVERSATIONS", "Assigned conversations");
  }
  get FailedToAssignConversations() {
    return getString("FAILED_TO_ASSIGN_CONVERSATIONS", "Failed to assign conversations");
  }
  AssignedToName(name) {
    return formatString(getString("ASSIGNED_TO_NAME", "Assigned to {name}"), {
      name: name
    });
  }
  get MarkingConversationsRead() {
    return getString("MARKING_CONVERSATION_READ", "Marking conversations read");
  }
  get MarkedConversationsRead() {
    return getString("MARKED_CONVERSATION_READ", "Marked conversations read");
  }
  get FailedToMarkConversationsRead() {
    return getString("FAILED_TO_MARK_CONVERSATION_READ", "Failed to mark conversations read");
  }
  get MarkingConversationsUnread() {
    return getString("MARKING_CONVERSATION_UNREAD", "Marking conversations unread");
  }
  get MarkedConversationsUnread() {
    return getString("MARKED_CONVERSATION_UNREAD", "Marked conversations unread");
  }
  get FailedToMarkConversationsUnread() {
    return getString("FAILED_TO_MARK_CONVERSATION_UNREAD", "Failed to mark conversations unread");
  }
  get ClosingConversations() {
    return getString("CLOSING_CONVERSATIONS", "Closing conversations");
  }
  get ClosedConversations() {
    return getString("CLOSED_CONVERSATIONS", "Closed conversations");
  }
  get FailedToCloseConversations() {
    return getString("FAILED_TO_CLOSE_CONVERSATIONS", "Failed to close conversations");
  }
  get ActivatingConversaions() {
    return getString("ACTIVATING_CONVERSATIONS", "Activating conversations");
  }
  get ActivatedConversations() {
    return getString("ACTIVATED_CONVERSATIONS", "Activated conversations");
  }
  get FailedToActivateConversations() {
    return getString("FAILED_TO_ACTIVATE_CONVERSATIONS", "Failed to activate conversations");
  }
  get MarkingConversationAsSpam() {
    return getString("MARKING_CONVERSATION_AS_SPAM", "Marking conversation as spam");
  }
  get MarkedConversationAsSpam() {
    return getString("MARKED_CONVERSATION_AS_SPAM", "Marked conversation as spam");
  }
  get FailedToMarkConversationAsSpam() {
    return getString("FAILED_TO_MARK_CONVERSATION_AS_SPAM", "Failed to mark conversation as spam");
  }

  get CompanyLinkedIn() {
    return getString("COMPANY_LINKED_IN", "Company LinkedIn URL");
  }
  get SequenceContactPrivateMessage() {
    return getString(
      "SEQUENCE_CONTACT_PRIVATE_MESSAGE",
      "All contacts (excluding private contacts) associated with an account profile are included in the account profile update request."
    );
  }
  get MissingEmailAddress() {
    return getString("MISSING_EMAIL_ADDRESS", "Missing email address");
  }
  get NoSubsidiariesErrorMessage() {
    return getString(
      "NO_SUBSIDIARIES_IN_LIST",
      "You cannot edit the company profile because there is no profile to edit"
    );
  }
  get UpdatingContactPrivacy() {
    return getString("UPDATING_CONTACT_PRIVACY_STATUS", "Updating contact privacy status");
  }
  get UpdatedContactPrivacy() {
    return getString("CONTACT_PRIVACY_STATUS_UPDATED", "Contact privacy status updated");
  }
  get FailedUpdateContactPrivacy() {
    return getString("FAILED_UPDATE_CONTACT_PRVIACY", "Failed to update contact privacy");
  }
  get LockstepPrivateInformation() {
    return getString("LOCKSTEP_PRIVATE_CONTACT_INFO", "Private contacts are only viewable by you and your teammates.");
  }
  get Friday() {
    return getString("FRIDAY", "Friday");
  }
  get CustomDate() {
    return getString("CUSTOM_DATE", "Custom date");
  }
  get ChooseACustomDateToSnoozeThisActivityUntil() {
    return getString(
      "CHOOSE_A_CUSTOM_DATE_TO_SNOOZE_THIS_ACTIVITY_UNTIL",
      "Choose a custom date to snooze this activity until"
    );
  }
  get SnoozingActivity() {
    return getString("SNOOZING_ACTIVITY", "Snoozing activity");
  }
  get SnoozedActivity() {
    return getString("SNOOZED_ACTIVITY", "Snoozed activity");
  }
  get FailedToSnoozeActivity() {
    return getString("FAILED_TO_SNOOZE_ACTIVITY", "Failed to snooze activity");
  }
  get UntilTomorrow() {
    return getString("UNTIL_TOMORROW", "Until tomorrow");
  }
  get UntilFriday() {
    return getString("UNTIL_FRIDAY", "Until friday");
  }
  get UntilNextWeek() {
    return getString("UNTIL_NEXT_WEEK", "Until next week");
  }
  get UntilNextMonth() {
    return getString("UNTIL_NEXT_MONTH", "Until next month");
  }
  get CreateUsernamePasswordHeading() {
    return getString(
      "CREATE_USERNAME_PASSWORD_HEADING",
      "First let’s create a username and password and select which department will be collaborating on Lockstep. You can always add an additional department to your workspace later. "
    );
  }
  get RecomendUsingCompanyEmail() {
    return getString(
      "RECOMEND_USING_COMPANY_EMAIL",
      "We recommend using your company email address for your username."
    );
  }
  get CreateUserContactInfo() {
    return getString(
      "CREATE_USER_CONTACT_INFO",
      "Next, please list the primary and secondary contacts on your team (one of them may be yourself). This will ensure that your network knows who exactly to contact for important escalations and issues. Once you’ve added contacts we will send them invites to join Lockstep."
    );
  }
  get Department() {
    return getString("DEPARTMENT", "Department");
  }
  get CreateUserProfileInfo() {
    return getString(
      "CREATE_USER_PROFILE_INFO",
      "To get you up and running we need to gather some information for your company’s profile. This information will be used to seamlessly collaborate with your accounting network. You can include extra details like communication preferences in the notes section."
    );
  }
  get CreateUserMyProfileInfo() {
    return getString("CREATE_USER_PROFILE_INFO", "Finally, let’s add some more detail to your profile!");
  }
  get CreateUserMyContactProfileInfo() {
    return getString(
      "CREATE_USER_PROFILE_CONTACT_INFO",
      "Next please take a minute to confirm your profile information with us! How does everything look?"
    );
  }
  get AddANewContact() {
    return getString("ADD_A_NEW_CONTACT", "Add a new contact");
  }
  get SaveAndSendUpdate() {
    return getString("SAVE_AND_SEND_UPDATE", "Save and request profile update");
  }
  get Snoozed() {
    return getString("SNOOZED", "Snoozed");
  }
  get UpdatedYourProfileInfo() {
    return getString("YOU_UPDATED_YOUR_PROFILE_INFO", "You updated your profile information");
  }
  get AddingContact() {
    return getString("ADDING_CONTACT", "Adding contact");
  }
  get AddedContact() {
    return getString("ADDED_CONTACT", "Contact added");
  }
  get FailedAddContact() {
    return getString("FAILED_ADD_CONTACT", "Failed to add contact");
  }
  get AddingContactAndSendingRequest() {
    return getString("ADDING_CONTACT_AND_SENDING_REQUEST", "Adding contact and sending request");
  }
  get AddedContactAndSentRequest() {
    return getString("ADDED_CONTACT_AND_SENT_REQUEST", "Contact added and request sent");
  }
  get MarkAsPrimary() {
    return getString("MARK_AS_PRIMARY", "Mark as primary");
  }
  get MarkingContactPrimary() {
    return getString("MARKING_CONTACT_PRIMARY", "Marking contact primary");
  }
  get MarkedContactPrimary() {
    return getString("MARKED_CONTACT_PRIMARY", "Marked contact as primary");
  }
  get FailedMarkContactPrimary() {
    return getString("FAILED_MARK_CONTACT_PRIMARY", "Failed mark contact as primary");
  }
  get DocumentName() {
    return getString("DOCUMENT_NAME", "Document name");
  }
  get AddDocument() {
    return getString("ADD_DOCUMENT", "Add document");
  }

  get EmailFieldIsEmpty() {
    return getString("EMAIL_FIELD_IS_EMPTY", "Email field is empty");
  }
  get ArchivedAccounts() {
    return getString("ARCHIVED_ACCOUNTS", "Archived accounts");
  }
  get TotalNumberOfArchivedContacts() {
    return getString("TOTAL_NUMBER_ARCHIVED_CONTACTS", "Total number of archived contacts");
  }
  get ArchivedContacts() {
    return getString("ARCHIVED_CONTACTS", "Archived contacts");
  }
  get TotalNumberOfArchivedAccounts() {
    return getString("TOTAL_NUMBER_OF_ARCHIVED_ACCOUNTS", "Total number of archived accounts");
  }
  get LockstepVerifiedArchivedAccounts() {
    return getString("LOCKSTEP_VERIFIED_ARCHIVED_ACCOUNTS", "Lockstep verified archived accounts");
  }
  get UnverifiedArchivedAccounts() {
    return getString("UNVERIFIED_ARCHIVED_ACCOUNTS", "Unverified archived accounts");
  }
  get Unarchive() {
    return getString("UNARCHIVE", "Unarchive");
  }
  get ArchivingAccount() {
    return getString("ARCHIVING_ACCOUNT", "Archiving account");
  }
  get AccountArchived() {
    return getString("ACCOUNT_ARCHIVED", "Account archived");
  }
  get FailedArchiveAccount() {
    return getString("FAILED_ARCHIVE_ACCOUNT", "Failed to archive account");
  }
  get RestoringAccount() {
    return getString("RESTORING_ACCOUNT", "Restoring account");
  }
  get AccountRestored() {
    return getString("ACCOUNT_RESTORED", "Account restored");
  }
  get FailedRestoreAccount() {
    return getString("FAILED_RESTORE_ACCOUNT", "Failed to restore account");
  }
  get ArchivingContact() {
    return getString("ARCHIVING_CONTACT", "Archiving contact");
  }
  get ArchivedContact() {
    return getString("ARCHIVED_CONTACT", "Archived contact");
  }
  get FailedToArchiveContact() {
    return getString("FAILED_TO_ARCHIVE_CONTACT", "Failed to archive contact");
  }
  get RestoringContact() {
    return getString("RESTORING_CONTACT", "Restoring contact");
  }
  get RestoredContact() {
    return getString("RESTORED_CONTACT", "Restored contact");
  }
  get FailedToRestoreContact() {
    return getString("FAILED_TO_RESTORE_CONTACT", "Failed to restore contact");
  }
  get Archived() {
    return getString("ARCHIVED", "Archived");
  }
  get LockstepArchivedInfo() {
    return getString("LOCKSTEP_ARCHIVED_INFO", "Archived contacts are only visible to you and your team");
  }
  get ToggleArchived() {
    return getString("TOGGLE_ARCHIVED", "Toggle archived");
  }
  get UpdatingContactStatus() {
    return getString("UPDATING_CONTACT_STATUS", "Updating contact status");
  }
  get ContactStatusUpdated() {
    return getString("CONTACT_STATUS_UPDATED", "Contact status updated");
  }
  get FailedToUpdateContactStatus() {
    return getString("FAILED_TO_UPDATE_CONTACT_STATUS", "Failed to update contact status");
  }
  get YouAreTryingToArchiveAPrimaryContact() {
    return getString("YOU_ARE_TRYING_TO_ARCHIVE_A_PRIMARY_CONTACT", "You are trying to archive a primary contact");
  }
  PrimaryContactArchiveMessage(name, companyname) {
    return formatString(
      getString(
        "PRIMARY_CONTACT_ARCHIVE_MESSAGE_NAME_COMPANY_NAME",
        "{name} is the primary contact for {companyname}. Please select a new primary contact for {companyname} from the table below."
      ),
      {
        name: name,
        companyname: companyname
      }
    );
  }
  get YourOneStepCloserToOptimizing() {
    return getString(
      "YOUR_ONE_STEP_CLOSER_TO_OPTIMIZING",
      "You're one step closer to optimizing your accounting workflow."
    );
  }
  get ConfirmCompanyInfoProfile() {
    return getString(
      "CONFIRM_COMPANY_INFO_PROFILE",
      "Confirm your company's profile information below to ensure seamless collaboration with network."
    );
  }
  get GreatWhoGetsInvite() {
    return getString("GREAT_WHO_GETS_INVITE", "Great, who from your team should get an invitation to join lockstep");
  }
  get FinallyLetsAddSomeDetail() {
    return getString(
      "FINALLY_LETS_ADD_SOME_DETAILS",
      "Finally, let’s add some more detail to your profile. We recommend adding things like communication preferences or fun facts to your profile too! If you’re in a hurry you can do this later."
    );
  }
  get ClaimYourProfile() {
    return getString("CLAIM_YOUR_PROFILE", "Claim your profile");
  }
  get AUserWithThisEmailAlreadyExists() {
    return getString("A_USER_WITH_THIS_EMAIL_ALREADY_EXISTS", "A user with this email already exists");
  }
  get FetchingPopulatedTemplate() {
    return getString("FETCHING_POPULATED_TEMPLATE_NAME", "Fetching template");
  }
  get EmailAttachments() {
    return getString("EMAIL_ATTACHMENTS", "Email Attachments");
  }
  get WhatDocumentTypeShouldWeSetTheseTo() {
    return getString("WHAT_DOCUMENT_TYPE_SHOULD_WE_SET_THESE_TO", "What type should we assign these documents?");
  }
  get YesIAmSure() {
    return getString("YES_I_AM_SURE", "Yes, I am sure");
  }
  get OnceYouDeleteDocumentYouCannotRecover() {
    return getString(
      "ONCE_YOU_DELETE_YOU_CANNOT_RECOVER",
      "Once a document is deleted it can never be accessed again."
    );
  }
  get AddContact() {
    return getString("ADD_CONTACT", "Add contact");
  }
  get PleaseEnsureAllFileNamesShorterThanOneHundred() {
    return getString(
      "PLEASE_ENSURE_ALL_FILE_NAMES_LESS_THAN_ONE_HUNDRED",
      "Please make sure that all file names are less then 100 characters in length"
    );
  }
  get ThisProfileIsMarkedPrivateMessage() {
    return getString(
      "THIS_PROFILE_IS_MARKED_PRIVATE_MESSAGE",
      "This profile is marked as private. If you send a profile update request to this profile it will no longer be marked as private."
    );
  }
  get AreYouSure() {
    return getString("ARE_YOU_SURE", "Are you sure?");
  }
  get DeletingDocumens() {
    return getString("DELETING_DOCUMENTS", "Deleting documents");
  }
  get DeletedDocument() {
    return getString("DELETED_DOCUMENTS", "Documents deleted");
  }
  get FailedDeleteDocuments() {
    return getString("FAILED_DELETE_DOCUMENTS", "Failed to delete documents");
  }
  get WNine() {
    return getString("W_NINE", "W9");
  }
  get BankVerification() {
    return getString("BANK_VERIFICATION", "Bank Verification");
  }
  get YesIAmSure() {
    return getString("YES_I_AM_SURE", "Yes, I am sure");
  }
  get CFO() {
    return getString("CFO", "CFO");
  }
  get Controller() {
    return getString("CONTROLLER", "Controller");
  }
  get SalesRepresentitive() {
    return getString("SALES_REP", "Sales representitive");
  }
  get Manager() {
    return getString("MANAGER", "Manager");
  }
  get Specialist() {
    return getString("SPECIALIST", "Specialist");
  }
  OnboardingCount(number) {
    return formatString(getString("ONBOARDING_COUNT", "Onboarding ({number})"), {
      number: number
    });
  }
  ProfileCount(number) {
    return formatString(getString("PROFILE_COUNT", "Profile Update ({number})"), {
      number: number
    });
  }
  get YouCannotSendUpdateRequestWithoutEmail() {
    return getString(
      "NO_REQUEST_WITHOUT_EMAIL",
      "You cannot send a profile update request to a profile with no email address."
    );
  }

  get IncomingDeliveryPreference() {
    return getString("IncomingDeliveryPreference", "Incoming Delivery Preference");
  }

  get OutgoingDeliveryPreference() {
    return getString("OutgoingDeliveryPreference", "Outgoing Delivery Preference");
  }

  get ServerName() {
    return getString("ServerName", "Server Name");
  }

  get OptionalFields() {
    return getString("OptionalFields", "Optional fields");
  }

  get UserDomain() {
    return getString("userDomain", "User domain");
  }

  get InboxFolder() {
    return getString("InboxFolder", "Inbox folder");
  }

  get SentItemsFolder() {
    return getString("SentItemsFolder", "Sent items folder");
  }

  get ConnectionSecurity() {
    return getString("ConnectionSecurity", "Connection security");
  }

  get AuthenticationMethod() {
    return getString("AuthenticationMethod", "Authentication method");
  }

  get ConnectionTimeout() {
    return getString("ConnectionTimeout", "Connection timeout");
  }

  get RetrievalTimeout() {
    return getString("RetrievalTimeout", "Retrieval timeout");
  }

  get DefaultSendAsEmail() {
    return getString("DefaultSendAsEmail", "Default send as email");
  }

  get OutgoingEmail() {
    return getString("OutgoingEmail", "Outgoing Email");
  }
  get TestEmailConfiguration() {
    return getString("TEST_EMAIL_CONFIGURATION", "Test email configuration");
  }
  TestingEmailConfigurationForName(name) {
    return formatString(getString("TESTING_EMAIL_CONFIGURATION_FOR_NAME", "Testing email configuration for {name}"), {
      name: name
    });
  }
  CompletedTestingEmailConfigurationForName(name) {
    return formatString(
      getString("COMPLETED_TESTING_EMAIL_CONFIGURATION_FOR_NAME", "Completed testing email configuration for {name}"),
      {
        name: name
      }
    );
  }
  get ShowConfig() {
    return getString("SHOW_CONFIG", "Show config");
  }
  get HideConfig() {
    return getString("HIDE_CONFIG", "Hide config");
  }
  get SuccessfullyConnected() {
    return getString("SUCCESSFULLY_CONNECTED", "Successfully connected");
  }
  get FailedToConnect() {
    return getString("FAILED_TO_CONNECT", "Failed to connect");
  }
  get SuccessfullyAuthorized() {
    return getString("SUCCESSFULLY_AUTHENTICATED", "Successfully authenticated");
  }
  get FailedToAuthenticate() {
    return getString("FAILED_TO_AUTHENTICATE", "Failed to authenticate");
  }
  get SmtpConnectionStatus() {
    return getString("SMTP_CONNECTION_ESTABLISHED", "SMTP connection established");
  }
  get SmtpAuthentication() {
    return getString("SMTP_AUTHENTICATION", "SMTP authentication");
  }
  get SmtpConfigUsed() {
    return getString("SMTP_CONFIG_USED", "SMTP config used");
  }
  get SmtpConfigTestResults() {
    return getString("SMTP_CONFIG_TEST_RESULTS", "SMTP config test results");
  }
  get ImapConnectionStatus() {
    return getString("IMAP_CONNECTION_ESTABLISHED", "IMAP connection established");
  }
  get ImapAuthentication() {
    return getString("IMAP_AUTHENTICATION", "IMAP authentication");
  }
  get ImapConfigUsed() {
    return getString("IMAP_CONFIG_USED", "IMAP config used");
  }
  get ImapConfigTestResults() {
    return getString("IMAP_CONFIG_TEST_RESULTS", "IMAP config test results");
  }
  get SuccessfullyFoundRecords() {
    return getString("SUCCESSFULLY_FOUND_RECORDS", "Successfully found records");
  }
  get FailedToFindRecords() {
    return getString("FAILED_TO_FIND_RECORDS", "Failed to find records");
  }
  get DkimRecordsStatus() {
    return getString("DKIM_RECORDS_STATUS", "DKIM records status");
  }
  get DkimDomain() {
    return getString("DKIM_DOMAIN", "DKIM domain");
  }
  get DkimConfigTestResults() {
    return getString("DKIM_CONFIG_TEST_RESULTS", "DKIM config test results");
  }
  get Activity() {
    return getString("ACTIVITY", "Activity");
  }
  get Received() {
    return getString("RECEIVED", "Received");
  }
  get ErrorMessage() {
    return getString("ERROR_MESSAGE", "Error message");
  }
  get CustomerID() {
    return getString("CUSTOMER_ID", "Customer ID");
  }
  get ForwardActivity() {
    return getString("FORWARD_ACTIVITY", "Forward activity");
  }
  get SelectMessageTemplate() {
    return getString("SELECT_MESSAGE_TEMPLATE", "Select message template");
  }
  get ViewFull() {
    return getString("VIEW_FULL", "View full");
  }
  get ActivitySummaryEmails() {
    return getString("ACTIVITY_SUMMARY_EMAILS", "Activity summary emails");
  }
  get VendorID() {
    return getString("VENDOR_ID", "Vendor ID");
  }
  get CompanyProfiles() {
    return getString("COMPANY_PROFILES", "Company profiles");
  }
  get YourOpenActivities() {
    return getString("YOUR_OPEN_ACTIVITIES", "Your open activities");
  }
  get UnassignedOpenActivities() {
    return getString("UNASSIGNED_OPEN_ACTIVITIES", "Unassigned open activites");
  }

  get SignInAndSecurity() {
    return getString("SIGN_IN_AND_SECURITY", "Sign-in and security");
  }
  get ChangeYourPassword() {
    return getString("CHANGE_YOUR_PASSWORD", "Change your password.");
  }
  get ActivitySummaryEmails() {
    return getString("ACTIVITY_SUMMARY_EMAILS", "Activity summary emails");
  }
  get ChooseActivitySummmaryEmailsFrequency() {
    return getString(
      "CHOOSE_ACTIVITY_SUMMMARY_EMAILS_FREQUENCY",
      "Choose the frequency at which you receive activity summary emails from Lockstep."
    );
  }
  get WorkspacePreferences() {
    return getString("WORKSPACE_PREFERENCES", "Workspace preferences");
  }
  get CustomizeWorkspaceNames() {
    return getString("CUSTOMIZE_WORKSPACE_NAMES", "Customize workspace names and select your default workspace.");
  }
  get EmailSettingsV2() {
    return getString("EMAIL_SETTINGS", "E-mail settings");
  }
  get ManageEmailSetupAndDelivery() {
    return getString("MANAGE_EMAIL_SETUP_AND_DELIVERY", "Manage email setup and delivery for your workspaces.");
  }
  get AccountingSoftwareSettings() {
    return getString("ACCOUNTING_SOFTWARE_SETTINGS", "Accounting software settings");
  }
  get ManageConnectionsToAccountingSoftware() {
    return getString("MANAGE_CONNECTIONS_TO_ACCOUNTING_SOFTWARE", "Manage connections to your accounting software.");
  }
  get WorkspaceName() {
    return getString("WORKSPACE_NAME", "Workspace name");
  }
  get WorkspaceName() {
    return getString("WORKSPACE_NAME", "Workspace name");
  }
  get AddVendors() {
    return getString("ADD_VENDORS", "Add vendors");
  }

  getString(name) {
    return getString(name.toUpperCase(), `[${name}]`);
  }
}

const resources = new Resources();

export default resources;
