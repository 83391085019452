import React, { Component } from "react";

class IconXCircle extends Component {
  render() {
    let { className, height } = this.props;

    return (
      <svg
        fill="none"
        stroke="currentColor"
        height={height}
        className={`icon ${className ? className : ""}`}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        viewBox="0 0 24 24"
      >
        <defs />
        <circle cx="12" cy="12" r="10" />
        <path d="M15 9l-6 6M9 9l6 6" />
      </svg>
    );
  }
}

IconXCircle.defaultProps = {
  height: 21
};

export default IconXCircle;
