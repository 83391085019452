import React, { Component } from "react";
import { connect } from "react-redux";

import EmailAddress from "../emailAddress";
import { removeAt, isEmpty, isEmailValid, deepCompare, find, includes, compareStrings } from "../../lib/utils";
import Icons from "../../lib/icons";

import { dispatchToProps } from "../../store/error-actions";

class PartnerAdder extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filtering: false,
      adding: false,
      partnerList: [],
      filter: ""
    };

    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.validateAndAdd = this.validateAndAdd.bind(this);
    this.getFilteredContacts = this.getFilteredContacts.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.stopAdding = this.stopAdding.bind(this);
  }

  componentDidMount() {
    this.tryUpdate();
  }

  componentDidUpdate(prevProps, prevState) {
    this.tryUpdate(prevProps, prevState);
  }

  tryUpdate(prevProps, prevState) {
    let changed = isEmpty(prevProps);

    let state = {};
    if (
      this.props.startAdding === true &&
      (isEmpty(prevProps) ||
        prevProps.startAdding !== true ||
        (this.props.addedPartners.length === 0 && this.state.adding === false))
    ) {
      state.adding = true;
      changed = true;
    }

    let { partnerList } = this.props;

    if (changed || deepCompare(partnerList, prevProps.partnerList) !== 0) {
      state = { ...state, partnerList };
      changed = true;
    }

    if (changed) {
      this.setState(state);
    }

    if (this.state.adding === true && prevState.adding !== true && this.inputRef) {
      this.inputRef.focus();
    }
  }

  onSelect(selected) {
    let addedPartners = [...this.props.addedPartners];
    let found = find(this.props.addedPartners, c => {
      return c.companyName === selected.companyName;
    });
    if (!found) {
      addedPartners.push(selected);
    }
    this.setState({ filter: "", adding: false, invalidFormat: false });
    this.onChanged(addedPartners);
  }

  removePartner(index) {
    let addedPartners = removeAt(this.props.addedPartners, index);
    this.onChanged(addedPartners);
  }

  onChanged(addedPartners) {
    let { onChanged } = this.props;
    if (onChanged) onChanged(addedPartners);
  }

  onFilterChanged(e) {
    this.setState({ filter: e.target.value });
  }

  handleKeyPress(e) {
    switch (e.key) {
      case "Enter":
        this.validateAndAdd(e);
        break;
      default:
        this.setState({ invalidFormat: false });
        break;
    }
  }

  validateAndAdd(e) {
    let address = e.target.value.trim();
    let newEmail = {
      name: "",
      address: address,
      isValid: isEmailValid(address),
      isEmpty: isEmpty(address)
    };

    let hasRequiredEmail =
      this.props.startAdding !== true || (this.props.startAdding === true && this.props.addedPartners.length > 0);

    if (this.getFilteredContacts().length === 0) {
      if (newEmail.isEmpty) {
        if (hasRequiredEmail) {
          this.stopAdding();
        }
      } else {
        if (newEmail.isValid) {
          this.onSelect(newEmail);
        } else {
          this.setState({ invalidFormat: true });
        }
      }
    } else {
      if (isEmpty(this.interval)) {
        this.interval = setInterval(() => {
          clearInterval(this.interval);
          this.interval = undefined;
          if (this.state.filtering === true) {
            this.setState({ filtering: false });
          }
        }, 250);
      }
      if (newEmail.isValid !== true && newEmail.isEmpty !== true) {
        this.setState({ invalidFormat: true });
      }
    }
  }

  stopAdding() {
    this.setState({ adding: false, filter: "", filtering: false });
  }

  handleKeyDown(e) {
    switch (e.key) {
      case "Escape":
        this.stopAdding();
        break;
      default:
        break;
    }
  }

  getFilteredContacts() {
    let filtered = [];
    let partnerList = [...(this.state.partnerList || [])];

    if (this.state.adding) {
      if (this.state.filter.trim() === "") {
        filtered = partnerList;
      } else {
        filtered = partnerList.filter(p => {
          return includes((p.companyName || "").toLocaleLowerCase(), this.state.filter.toLocaleLowerCase());
        });
      }
    }
    filtered.sort((a, b) => {
      let aStart = a.companyName.toLocaleLowerCase().startsWith(this.state.filter.toLocaleLowerCase());
      let bStart = b.companyName.toLocaleLowerCase().startsWith(this.state.filter.toLocaleLowerCase());
      if (aStart && !bStart) {
        return -1;
      }
      if (!aStart && bStart) {
        return 1;
      }
      return compareStrings(a.companyName, b.companyName);
    });

    return filtered.slice(0, 4);
  }

  handleFocus() {
    this.setState({ filtering: true, invalidFormat: false });
  }

  render() {
    let include =
      this.state.filtering !== true
        ? []
        : this.getFilteredContacts().map((c, index) => {
            return (
              <div
                key={`partner_${index}`}
                className="dropdown-item"
                onMouseDown={e => {
                  this.onSelect(c);
                }}
                style={{ cursor: "default" }}
              >
                {c.companyName}
              </div>
            );
          });

    return (
      <span>
        <div className="flex-column align-left">
          {this.props.addedPartners.map((p, index) => {
            return (
              <EmailAddress
                key={index}
                email={{ address: p.companyName }}
                showRemove={true}
                onRemove={() => {
                  this.removePartner(index);
                }}
              />
            );
          })}
        </div>

        {this.state.adding ? (
          <span id={`${this.props.type}EmailInputSpan`} className="dropdown">
            <input
              ref={input => (this.inputRef = input)}
              className="dropdown-toggle email-input"
              onChange={e => {
                this.onFilterChanged(e);
              }}
              onKeyPress={this.handleKeyPress}
              onKeyDown={this.handleKeyDown}
              onBlur={this.stopAdding}
              onFocus={this.handleFocus}
              role="button"
              data-toggle="collapse"
              aria-haspopup="true"
              aria-expanded="true"
            />
            {include.length === 0 ? null : (
              <span className="dropdown-menu show" style={{ display: "inline-table" }}>
                {include}
              </span>
            )}
          </span>
        ) : (
          <span
            className={`plus-email ${Icons.plus}`}
            onClick={() => {
              this.setState({ adding: true });
            }}
          />
        )}
      </span>
    );
  }
}

const storeToProps = store => {
  return {
    errorStore: store.error
  };
};

export default connect(
  storeToProps,
  dispatchToProps
)(PartnerAdder);
