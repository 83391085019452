import React, { Component } from "react";

class ReportContentBlock extends Component {
  render() {
    const { heading, data, backgroundColor } = this.props;

    return (
      <div className="reports-content-block-column col-md-2 col-sm-4">
        <div className="reports-content-block" style={{ color: "#ffffff", backgroundColor: backgroundColor }}>
          <h4>{heading}</h4>
          <h3>{data}</h3>
        </div>
      </div>
    );
  }
}

ReportContentBlock.defaultProps = {
  heading: "",
  data: "0",
  color: "#FFFFF"
};

export default ReportContentBlock;
