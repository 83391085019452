import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import Resources from "../lib/resources";
import Icons from "../lib/icons";
import { isEmpty, formatCurrency, getInvoiceNum } from "../lib/utils";

import { dispatchToProps } from "../store/conversations-actions";

class SideBarInvoice extends Component {
  shouldComponentUpdate(prevProps, prevState) {
    if (this.props.invoice !== prevProps.invoice) {
      return true;
    }
    return false;
  }

  render() {
    let { invoice, conversation, erpInvoiceEntry } = this.props;
    if (isEmpty(invoice) || isEmpty(conversation)) {
      return null;
    } else {
      let invoiceId = invoice.ID;
      let invoiceNum = getInvoiceNum(invoiceId);
      // let billId = isEmpty(invoice.BillingReference) ? "---" : invoice.BillingReference.InvoiceDocumentReference.ID;
      let issueDate = invoice.IssueDate ? moment(invoice.IssueDate.ValueString).format("ll") : "---";
      // let dueDate = isEmpty(invoice.DueDate)
      //   ? (((invoice.PaymentMeans || [])[0] || {}).PaymentDueDate || {}).ValueString
      //   : invoice.DueDate.ValueString
      // dueDate = isEmpty(dueDate) ? "-" : moment(dueDate).format('ll');
      // let statusId = isEmpty(conversation.conversationStatusId) ? Resources.Closed : this.props.getConversationStatus(conversation.conversationStatusId || "65d7d5d6-3615-4710-bce7-7920fd12d0d0").conversationStatusName;
      let invoiceAmount = formatCurrency(((invoice.LegalMonetaryTotal || {}).PayableAmount || {}).Value);
      // let totalDebits = ((invoice.LegalMonetaryTotal || {}).PayableAmount || {}).Value;
      // let totalCredits = 0;
      // let prePaid = invoice.PrePaidPayment ? invoice.PrePaidPayment.PaidAmount.Value : 0;
      // let remainingPayment = formatCurrency(totalDebits - totalCredits + prePaid);
      let invoiceLink = invoice.Link;
      let invoiceTitle;
      let eneteredLine = isEmpty(erpInvoiceEntry)
        ? `${Resources.Status}: ${Resources.NotEntered}`
        : `${Resources.EnteredOn} ${moment(erpInvoiceEntry.associationDate).format("ll")}`;

      if (isEmpty(invoiceLink)) {
        invoiceTitle = (
          <div className="invoice-sidebar-number">
            <b>
              {Resources.InvoiceNumber}: {invoiceNum}
            </b>
          </div>
        );
      } else {
        invoiceTitle = (
          <div className="invoice-sidebar-number">
            <a href={invoiceLink} target="_blank" rel="noopener noreferrer">
              {Resources.InvoiceNumber}: {invoiceNum}
            </a>
          </div>
        );
      }

      return (
        <React.Fragment>
          <span className="divider" />
          <div className="invoice-sidebar">
            <div className="invoice-sidebar-header">{Resources.InvoiceSummary.toLocaleUpperCase()}</div>
            <div className="invoice-sidebar-content">
              <div className="invoice-sidebar-icon-wrapper">
                <div className="invoice-sidebar-icon">
                  <span className={Icons.fileInvoiceDollar + " fa-4x"} />
                </div>
                <div className="invoice-sidebar-icon-items">
                  {invoiceTitle}
                  <div>{eneteredLine}</div>
                  <div>
                    {Resources.OriginalAmount}: {invoiceAmount}
                  </div>
                </div>
              </div>

              <div className="row" style={{ width: "100%" }}>
                <div className="col-sm-5 text-right invoice-sidebar-key-col">
                  {/* <div>{Resources.Due} {dueDate}</div>
                  <div className="invoice-sidebar-key">{Resources.BillNumber}:</div>
                  <div className="invoice-sidebar-key">{Resources.RemainingDollars}:</div> */}
                  <div className="invoice-sidebar-key">{Resources.InvoiceDate}:</div>
                  {/* <div className="invoice-sidebar-key">{Resources.Status}:</div>
                  <div className="invoice-sidebar-key">{Resources.Terms}:</div>
                  <div className="invoice-sidebar-key">{Resources.CheckNumber}:</div>
                  <div className="invoice-sidebar-key">{Resources.CheckDate}:</div> */}
                </div>
                <div className="col-sm-7 invoice-sidebar-value-col">
                  {/* <div className="invoice-sidebar-value">{billId}</div>
                  <div className="invoice-sidebar-value">---</div> */}
                  <div className="invoice-sidebar-value">{issueDate}</div>
                  {/* <div className="invoice-sidebar-value">{statusId}</div>
                  <div className="invoice-sidebar-value">---</div>
                  <div className="invoice-sidebar-value">---</div>
                  <div className="invoice-sidebar-value">---</div> */}
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      );
      // } else {
      //   let invoiceId = invoice.ID;
      //   let invoiceNum = getInvoiceNum(invoiceId);
      //   let invoiceTitle = <div className="invoice-sidebar-number"><b>{Resources.InvoiceNumber}: {invoiceNum}</b></div>;

      //   return (
      //     <React.Fragment>
      //       <span className="divider" />
      //       <div className="invoice-sidebar">
      //         <div className="invoice-sidebar-header">{Resources.InvoiceSummary.toLocaleUpperCase()}</div>
      //         <div className="invoice-sidebar-content">
      //           <div className="invoice-sidebar-icon-wrapper">
      //             <div className="invoice-sidebar-icon">
      //               <span className={Icons.fileInvoiceDollar + " fa-4x"} />
      //             </div>
      //             <div className="invoice-sidebar-icon-items">
      //               {invoiceTitle}
      //               <div>{Resources.Status}: {Resources.NotEntered}</div>
      //               <div><a href="" target="_blank" rel="noopener noreferrer">{Resources.CreateNewBill}</a></div>
      //             </div>
      //           </div>
      //         </div>
      //       </div>
      //     </React.Fragment>
      //   );
      // }
    }
  }
}

const storeToProps = store => {
  return {
    conversation: store.conversations.conversation
  };
};

export default connect(
  storeToProps,
  dispatchToProps
)(SideBarInvoice);
