import React, { Component } from "react";

class IconCode extends Component {
  render() {
    let { className, height } = this.props;

    return (
      <svg
        height={height}
        width={height}
        className={`icon ${className ? className : ""}`}
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        style={{ fill: "none" }}
      >
        <polyline points="16 18 22 12 16 6"></polyline>
        <polyline points="8 6 2 12 8 18"></polyline>
      </svg>
    );
  }
}

IconCode.defaultProps = {
  height: 24
};

export default IconCode;
