import React, { Component } from "react";
import { connect } from "react-redux";

import { dispatchToProps as modDP } from "../../store/modal-actions";
import { dispatchToProps as manDP } from "../../store/manage-actions";

import CreateCommunicationTemplate from "./createCommunicationTemplate";

import Resources from "../../lib/resources";
import { formatDate } from "../../lib/utils";

import Card from "../library/card";
import TableData from "../library/tableData";
import TableDataSortableHeader from "../library/tableDataSortableHeader";
import AutoCompleteInput from "../library/autoCompleteInput";
import IconAngleLeft from "../library/icons/iconAngleLeft";

const dispatchToProps = dispatch => {
  return {
    ...dispatch(manDP),
    ...dispatch(modDP)
  };
};

class ManageCommunicationTemplates extends Component {
  constructor(props) {
    super(props);

    this.state = {
      creatingTemplate: false,

      searchTerm: "",
      searchResults: [],
      displaySearchResults: false,

      templateEditing: {}
    };

    this.updateSearchTerm = this.updateSearchTerm.bind(this);
    this.communicationTemplatesColumns = this.communicationTemplatesColumns.bind(this);
  }

  communicationTemplatesColumns = () => [
    {
      header: (
        <TableDataSortableHeader
          sortBy={this.props.manageStore.communicationTemplatesSortBy}
          sortDirection={this.props.manageStore.communicationTemplatesSortDirection}
          updateSort={(sortBy, sortDirection) => {
            this.updateSort(sortBy, sortDirection);
          }}
          text={Resources.TemplateName.toLocaleUpperCase()}
          sortKey={"emailTemplateID"}
        />
      ),
      width: "25%",
      content: row => {
        return row.emailTemplateID;
      }
    },
    {
      header: (
        <TableDataSortableHeader
          sortBy={this.props.manageStore.communicationTemplatesSortBy}
          sortDirection={this.props.manageStore.communicationTemplatesSortDirection}
          updateSort={(sortBy, sortDirection) => {
            this.updateSort(sortBy, sortDirection);
          }}
          text={Resources.Preview.toLocaleUpperCase()}
          sortKey={"emailTemplateDesc"}
        />
      ),
      width: "50%",
      content: row => row.emailTemplateDesc
    },
    {
      header: (
        <TableDataSortableHeader
          sortBy={this.props.manageStore.communicationTemplatesSortBy}
          sortDirection={this.props.manageStore.communicationTemplatesSortDirection}
          updateSort={(sortBy, sortDirection) => {
            this.updateSort(sortBy, sortDirection);
          }}
          text={Resources.Type.toLocaleUpperCase()}
          sortKey={"type"}
        />
      ),
      width: "11%",
      content: row => {
        let content;
        switch (1) {
          case row.emailTemplateStatus:
            content = Resources.Email;
            break;
          case row.voiceMessageStatus:
            content = Resources.Voice;
            break;
          case row.smsStatus:
            content = Resources.Text;
            break;
          default:
            content = Resources.Inactive;
            break;
        }
        return content;
      }
    },
    {
      header: (
        <TableDataSortableHeader
          sortBy={this.props.manageStore.communicationTemplatesSortBy}
          sortDirection={this.props.manageStore.communicationTemplatesSortDirection}
          updateSort={(sortBy, sortDirection) => {
            this.updateSort(sortBy, sortDirection, "date");
          }}
          text={Resources.LastEdited.toLocaleUpperCase()}
          sortKey={"updateDate"}
        />
      ),
      content: row => formatDate(row.updateDate)
    }
  ];

  updateSort(sortBy, sortDirection, sortByType = "string") {
    this.props.updateCommunicationTemplatesSort(sortBy, sortDirection, sortByType);
  }

  componentDidMount() {
    this.props.getCommunicationTemplates(this.props.match.params.companyId);
  }

  updateSearchTerm(searchTerm) {
    let searchResults = this.searchData(searchTerm);
    this.setState({ searchTerm, searchResults });
  }

  searchData(searchTerm) {
    let data = this.props.manageStore.communicationTemplates;
    return data.filter(template => template.emailTemplateID.toLowerCase().includes(searchTerm.toLowerCase()));
  }

  displaySearchResults(searchTerm) {
    this.updateSearchTerm(searchTerm);
    this.setState({ displaySearchResults: true });
  }

  render() {
    let data = this.state.displaySearchResults
      ? [...this.state.searchResults]
      : [...this.props.manageStore.communicationTemplates];

    if (this.state.creatingTemplate) {
      return (
        <React.Fragment>
          <div className="sequences-create-header">
            <button
              className="button-back"
              onClick={() => this.setState({ creatingTemplate: false, templateEditing: {} })}
            >
              <IconAngleLeft />
              {Resources.Back.toLocaleUpperCase()}
            </button>
          </div>
          <hr style={{ marginTop: "1rem", marginBottom: "1.66rem" }} />
          <div className="manage-content">
            <CreateCommunicationTemplate
              companyId={this.props.match.params.companyId}
              templateEditing={this.state.templateEditing}
              savedTemplate={() => {
                this.setState({ creatingTemplate: false, templateEditing: {} });
                this.props.getCommunicationTemplates(this.props.match.params.companyId);
              }}
            />
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <div className="manage-content">
          <div>
            <button
              className="button-purple"
              style={{ width: "20rem", marginBottom: "2.66rem", marginTop: "2.66rem" }}
              onClick={() => this.setState({ creatingTemplate: true })}
            >
              {Resources.CreateNewTemplate}
            </button>
            <Card
              type="company-info-contact-us"
              className="company-info-contact-us"
              title={Resources.CommunicationTemplates}
              headAboveCard
            >
              <div className="table-data-card-header">
                <div className="d-flex align-items-center">
                  <div className="table-data-card-header-search">
                    <AutoCompleteInput
                      className="auto-complete-input__table-data-search"
                      placeholder={Resources.Search}
                      onChange={e => {
                        this.updateSearchTerm(e.target.value);
                      }}
                      isShowingSearchResult={this.state.displaySearchResults}
                      handleClearResult={() => {
                        this.setState({ searchTerm: "", searchResults: [], displaySearchResults: false });
                      }}
                      text={this.state.searchTerm}
                      noResultsMessage={Resources.NoTemplatesSearchResultsMessage}
                      showNoResultsMessage={
                        this.state.searchTerm.length !== 0 && this.props.manageStore.gotCommunicationTemplates
                      }
                      maxOptions={7}
                      loading={this.props.manageStore.gettingCommunicationTemplates}
                      handleSelectOption={option => {
                        this.displaySearchResults(option.emailTemplateID);
                      }}
                      callToActionButton={
                        <div
                          className="dropdown-item-clean dropdown-call-to-action"
                          onClick={() => this.displaySearchResults(this.state.searchTerm)}
                        >
                          {Resources.SeeAllResultsFor(this.state.searchTerm)}
                        </div>
                      }
                      renderOption={option => `${option.emailTemplateID}`}
                      options={this.state.searchResults}
                      width="300px"
                    />
                  </div>
                </div>
              </div>
              <TableData
                pagination
                data={data}
                columns={this.communicationTemplatesColumns()}
                onRowClick={(row, rowIndex) => {
                  this.setState({ creatingTemplate: true, templateEditing: row });
                }}
                rowHeight="4em"
                maxRows={data.length}
                loading={!this.props.manageStore.gotCommunicationTemplates}
                offsetHeight="40"
                emptyRender={
                  <div className="flex-center">
                    <h4 className="mt-5">{Resources.EmptyCommunicationTemplates}</h4>
                  </div>
                }
              />
            </Card>
          </div>
        </div>
      );
    }
  }
}

const storeToProps = store => {
  return {
    pageRowCount: store.general.pageRowCount,
    companyStore: store.companies,
    manageStore: store.manage
  };
};

export default connect(storeToProps, dispatchToProps)(ManageCommunicationTemplates);
