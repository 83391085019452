import React, { Component } from "react";

class IconDownload extends Component {
  render() {
    let { className, height } = this.props;

    return (
      <svg viewBox="0 0 20 20" height={height} className={`icon ${className ? className : ""}`}>
        <path
          d="M19 13v4c0 1.1045695-.8954305 2-2 2H3c-1.1045695 0-2-.8954305-2-2v-4m4-5l5 5 5-5m-5 5V1"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          stroke="currentColor"
          fill="none"
          fillRule="evenodd"
        />
      </svg>
    );
  }
}

IconDownload.defaultProps = {
  height: 20
};

export default IconDownload;
