import React, { Component } from "react";
import { connect } from "react-redux";
import Resources from "../lib/resources";

import MainContentHeader from "../components/main_content_header/mainContentHeader";
import RadioInput from "./library/radioInput";

import { getPartnerType } from "../lib/perspectives";
import { includes } from "../lib/utils";

import { dispatchToProps as paDP } from "../store/perspectives-actions";
import { dispatchToProps as uaDP } from "../store/user-actions";
import { dispatchToProps as gaDP } from "../store/general-actions";

const dispatchToProps = dispatch => {
  return {
    ...paDP(dispatch),
    ...uaDP(dispatch),
    ...gaDP(dispatch)
  };
};

class MyProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      defaultPerspective: this.props.userStore.userConfig.defaultPerspective,
      readOnly: true
    };

    this.handleEditOrSave = this.handleEditOrSave.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.userStore.userConfig !== prevProps.userStore.userConfig) {
      this.setState({
        defaultPerspective: this.props.userStore.userConfig.defaultPerspective
      });
    }
  }

  handleEditOrSave() {
    if (this.state.readOnly) {
      this.setState({ readOnly: false });
      return;
    } else {
      this.setState({ readOnly: true });

      this.props
        .updateUserConfig({ defaultPerspective: this.state.defaultPerspective })
        .then(() => {
          this.props.showToast({
            timeout: 5000,
            text: Resources.UserProfileSaved,
            linkHidden: true
          });
        })
        .catch(err => {
          return;
        });
    }
  }

  render() {
    const {
      userStore,
      match: {
        params: { companyId }
      }
    } = this.props;
    const companyPerspectives = this.props.getCompanyPerspectives(companyId) || [];
    const companyPerspectiveIds = companyPerspectives.map(p => p.perspectiveId);
    let defaultPerspective = this.state.defaultPerspective;

    if (includes(companyPerspectiveIds, defaultPerspective) !== true) {
      defaultPerspective = (companyPerspectives[0] || {}).perspectiveId;
    }

    const perspectiveRadios = companyPerspectives.map(p => {
      return { label: getPartnerType(p.perspectiveName, true), value: p.perspectiveId };
    });

    return (
      <React.Fragment>
        <MainContentHeader hideSearch={true} title={Resources.MyProfile} />

        <div className="manage-content">
          <div>
            <label className="input-label">{Resources.DefaultWorkspace}</label>
            <RadioInput
              inline
              inputClassName="w-45"
              disabled={this.state.readOnly}
              radioName="workspaces"
              inputs={perspectiveRadios}
              value={defaultPerspective}
              onChange={item => this.setState({ defaultPerspective: item.value })}
            />

            <div className="flex-row mt-4">
              <button className="btn button-green" onClick={this.handleEditOrSave}>
                {this.state.readOnly ? Resources.Edit : Resources.Save}
              </button>
              {this.state.readOnly === false && (
                <button
                  className="button-cancel ml-1"
                  onClick={() =>
                    this.setState({
                      defaultPerspective:
                        (userStore.userConfig || {}).defaultPerspective || (companyPerspectives[0] || {}).perspectiveId,
                      readOnly: true
                    })
                  }
                >
                  {Resources.Cancel}
                </button>
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const storeToProps = store => {
  return {
    userStore: store.user
  };
};

export default connect(storeToProps, dispatchToProps)(MyProfile);
