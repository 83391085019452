import React, { Component } from "react";

class IconAutoPay extends Component {
  render() {
    let { className, height, style } = this.props;

    return (
      <svg viewBox="0 0 18 17" height={height} className={`icon ${className ? className : ""}`} style={style}>
        <g fill="none" fillRule="evenodd">
          <g stroke="currentColor" strokeLinecap="round" strokeLinejoin="round">
            <path d="M8.71306818 3.8125v9.375M10.84375 5.51704545H7.64772727c-.82372015 0-1.49147727.66775712-1.49147727 1.49147728C6.15625 7.83224288 6.82400712 8.5 7.64772727 8.5h2.13068182c.82372014 2e-8 1.49147721.66775713 1.49147721 1.49147727s-.66775707 1.49147723-1.49147721 1.49147723H6.15625" />
            <path d="M15.7173964 6.45353261c-.3072568-1.08575524-.8529132-2.07138916-1.5768405-2.89677309C12.7659256 1.98947817 10.7485153 1 8.5 1 4.35786438 1 1 4.35786438 1 8.5 1 12.6421356 4.35786438 16 8.5 16c2.33969139 0 4.4291554-1.0713507 5.8045793-2.7502395" />
            <path d="M16.9116805 5.08031778L15.66968222 7.0679305 13.6820695 5.82593222" />
          </g>
        </g>
      </svg>
    );
  }
}

IconAutoPay.defaultProps = {
  height: 17
};

export default IconAutoPay;
