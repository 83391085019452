import { actions } from "./modal-actions";

const defaultModalState = {
  showModal: false,
  modalType: "",
  modalProps: { companyEmail: "" },
  showFlyout: false,
  flyoutType: "",
  flyoutProps: {},
  showNotification: false,
  notificationType: "",
  notificationProps: {}
};

function modalReducer(state = { ...defaultModalState }, action) {
  switch (action.type) {
    case actions.SHOW_MODAL:
      return {
        ...state,
        showModal: true,
        modalType: action.modalType,
        modalProps: action.modalProps
      };
    case actions.HIDE_MODAL:
      return { ...state, showModal: false, modalType: "", modalProps: {} };

    case actions.SHOW_FLYOUT:
      return {
        ...state,
        showFlyout: true,
        flyoutType: action.flyoutType,
        flyoutProps: action.flyoutProps
      };
    case actions.HIDE_FLYOUT:
      return { ...state, showFlyout: false, flyoutType: "", flyoutProps: {} };

    case actions.SHOW_NOTIFICATION:
      return {
        ...state,
        showNotification: true,
        notificationType: action.notificationType,
        notificationProps: action.notificationProps
      };
    case actions.HIDE_NOTIFICATION:
      return { ...state, showNotification: false, flyoutTynotificationTypepe: "", notificationProps: {} };

    case actions.UPDATE_NOTIFICATION_PROPS:
      return { ...state, notificationProps: { ...state.notificationProps, ...action.notificationProps } };

    case actions.CLEAR_DATA:
      return { ...defaultModalState };
    case actions.SELECT_COMPANY:
      if (action.companyId === state.companyId) return state;
      return { ...defaultModalState, companyId: action.companyId };

    default:
      return state;
  }
}

export default modalReducer;
