import React, { Component } from "react";
import { connect } from "react-redux";

import { dispatchToProps } from "../../store/modal-actions";
import Resources from "../../lib/resources";

import IconCheck from "../library/icons/iconCheck";
import IconAlertCircle from "../library/icons/iconAlertCircle";
import IconSpinner from "../library/icons/iconSpinner";

class DownloadNotification extends Component {
  constructor(props) {
    super(props);

    this.state = {
      address: "",
      isValidAddress: false
    };
  }

  componentDidUpdate(prevProps) {
    if (
      (this.props.downloaded === true && prevProps.downloaded === false) ||
      (this.props.failedDownload === true && prevProps.failedDownload === false)
    ) {
      setTimeout(this.props.hideNotification, 5000);
    }
  }

  render() {
    let { downloading, downloaded, failedDownload } = this.props;

    let content;
    if (downloading) {
      content = (
        <React.Fragment>
          <IconSpinner height={20} className="notification-icon-left" />
          <div>{Resources.DownloadInProgress}</div>
        </React.Fragment>
      );
    }
    if (downloaded) {
      content = (
        <React.Fragment>
          <IconCheck height={18} className="notification-icon-left"></IconCheck>
          <div>{Resources.DownloadComplete}</div>
        </React.Fragment>
      );
    }
    if (failedDownload) {
      content = (
        <React.Fragment>
          <IconAlertCircle height={18} className="notification-icon-left"></IconAlertCircle>
          <div>{Resources.DownloadFailed}</div>
        </React.Fragment>
      );
    }
    return <div className="notification">{content}</div>;
  }
}

const storeToProps = store => {
  return {
    downloading: store.ledger.gettingAttachmentsZip,
    downloaded: store.ledger.gotAttachmentsZip,
    failedDownload: store.ledger.getAttachmentsZipFailed
  };
};

export default connect(
  storeToProps,
  dispatchToProps
)(DownloadNotification);
