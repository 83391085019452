import React, { Component } from "react";

export default class VendorIcon extends Component {
  render() {
    return (
      <span className={this.props.className}>
        <span
          onClick={e => {
            if (this.props.onClick) {
              this.props.onClick(e);
            }
          }}
          className={"default-icon"}
        >
          V
        </span>
      </span>
    );
  }
}
