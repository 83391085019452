import React, { Component } from "react";
import { connect } from "react-redux";

import ReportsFilter from "./reportsFilter";
import { dispatchToProps as raDP } from "../store/reports-actions";
import { dispatchToProps as aaDP } from "../store/accounts-actions";
import MainContentHeader from "./main_content_header/mainContentHeader";
import MainLoader from "./mainLoader";
import { isEmpty, clone, ticksToReadableTime, coalesceUserName } from "../lib/utils";
import ReportContentBlock from "./reportsContentBlock";
import TabularData from "./library/tabularData";
import Resources from "../lib/resources";
//import { LineChart, YAxis, XAxis, Line, Tooltip, ResponsiveContainer, CartesianGrid } from 'recharts'
import moment from "moment";
import ReportsExportButton from "./reportsExportButton";
import ReportsNoData from "./reportsNoData";

const dispatchToProps = dispatch => ({
  ...aaDP(dispatch),
  ...raDP(dispatch)
});

class ReportsApprovalAging extends Component {
  constructor(props) {
    super(props);
    this.state = {
      numberOfEntries: 30
    };

    this.loadMore = this.loadMore.bind(this);
  }

  componentDidMount() {
    this.tryUpdate();
  }

  componentDidUpdate(prevProps) {
    this.tryUpdate(prevProps);
  }

  tryUpdate(prevProps) {
    if (
      isEmpty(prevProps) ||
      this.props.reports.companyId !== prevProps.reports.companyId ||
      this.props.reports.rangeType !== prevProps.reports.rangeType
    ) {
      this.props.getReportsData("approval-aging");
    }
    if (
      isEmpty(prevProps) === false &&
      this.props.reports.fetchedReport === true &&
      prevProps.reports.fetchedReport === false
    ) {
      let guids = this.props.reports.reportData.approvers.map(approver => {
        return approver.approverId;
      });
      this.props.getReportUsers(guids);
    }
  }

  loadMore() {
    if (this.state.numberOfEntries < this.props.reports.reportData.approvers.length) {
      this.setState({ numberOfEntries: this.state.numberOfEntries + 10 });
    }
  }

  approvalAgingColumns(isAdmin) {
    let approvalAgingColumns = [
      {
        header: "",
        key: "",
        style: {
          width: "2%"
        }
      },
      {
        header: Resources.Approver,
        key: "displayName",
        style: {
          width: "23%"
        }
      },
      {
        header: Resources.Current,
        key: "outstanding",
        style: {
          width: "11%"
        }
      },
      {
        header: Resources.AgingLessThanTwentyFourH,
        key: "lessThanOneDayOutstanding",
        style: {
          width: "11%"
        }
      },
      {
        header: Resources.AgingTwentyFourToFourtyEightH,
        key: "oneToTwoDaysOutstanding",
        style: {
          width: "11%"
        }
      },
      {
        header: Resources.AgingFourtyEightToSeventyTwoH,
        key: "twoToThreeDaysOutstanding",
        style: {
          width: "11%"
        }
      },
      {
        header: Resources.AgingSeventyTwoPlusH,
        key: "moreThanThreeDaysOutstanding",
        style: {
          width: "11%"
        }
      },
      {
        header: Resources.MaxAgingTime,
        key: "maxOutStandingTime",
        style: {
          width: "16%"
        }
      },
      {
        header: "",
        key: "",
        style: {
          width: "2%"
        }
      }
    ];

    return approvalAgingColumns;
  }

  iconCircle = () => {
    return <circle r="20" stroke="black" strokeWidth="10" fill="white" />;
  };

  prettyGraph(date) {
    let dateFormat;
    switch (this.props.reports.rangeType) {
      case "P28D":
        dateFormat = "M/D";
        break;
      case "P10D":
        dateFormat = "M/D/YY";
        break;
      case "P12H":
        dateFormat = "M/D h:mmA";
        break;
      default:
        dateFormat = "M/D";
        break;
    }
    return moment(date).format(dateFormat);
  }

  prettyAging(approvers) {
    let approversList = this.props.getCompanyApprovers(this.props.reports.companyId) || [];
    let approversFlattenedMap = {};
    let perspectives = Object.keys(approversList);

    perspectives.forEach(key => {
      approversList[key].forEach(approver => {
        approversFlattenedMap[approver.companyContactId] = approver;
      });
    });

    if (!isEmpty(approvers)) {
      return approvers.map(approver => {
        let a = clone(approver);
        a.displayName = coalesceUserName(approversFlattenedMap[approver.approverId]);
        a.maxOutStandingTime = ticksToReadableTime(approver.maxOutstandingTime);
        return a;
      });
    }
    return approvers;
  }

  render() {
    let { fetchingReport, reportData } = this.props.reports;
    let approvalsAging = this.prettyAging(reportData.approvers || []);
    //let graphData = (reportData.requestsSent || [])

    return (
      <React.Fragment>
        <MainContentHeader title={Resources.ApprovalAgingReport} hideSearch={true} />
        {fetchingReport ? (
          <MainLoader fullScreen={true} />
        ) : (
          <div className="reports">
            <ReportsFilter />
            <div className="reports-content-blocks">
              <div className="row">
                <ReportContentBlock
                  heading={Resources.CurrentlyAging}
                  data={reportData.outstanding}
                  backgroundColor="#E76D01"
                />
                <ReportContentBlock
                  heading={Resources.LessThanTwentyFourHours}
                  data={reportData.lessThanOneDayOutstanding}
                  backgroundColor="#676C89"
                />
                <ReportContentBlock
                  heading={Resources.TwentyFourToFourtyEightHours}
                  data={reportData.oneToTwoDaysOutstanding}
                  backgroundColor="#676C89"
                />
                <ReportContentBlock
                  heading={Resources.FourtyEightToSeventyTwoHours}
                  data={reportData.twoToThreeDaysOutstanding}
                  backgroundColor="#4B4E61"
                />
                <ReportContentBlock
                  heading={Resources.SeventyTwoPlusHours}
                  data={reportData.moreThanThreeDaysOutstanding}
                  backgroundColor="#EB5757"
                />
                <ReportContentBlock
                  heading={Resources.MaxAgingTime}
                  data={ticksToReadableTime(reportData.maxOutstandingTime)}
                  backgroundColor="#31333F"
                />
              </div>
            </div>
            {/* <div className="reports-chart-area">
            <div className="reports-charts-title">{Resources.Responses}</div>
            <ResponsiveContainer>
              <LineChart data={graphData} margin={{ top: 10, bottom: 10, right: 10, left: 10 }}>
                <CartesianGrid vertical={false} strokeDasharray="3 3"></CartesianGrid>
                <XAxis type="category" dataKey="date" tickLine={false} tickFormatter={(tick) => this.prettyGraph(tick)} />
                <YAxis type="number" tickLine={false} />
                <Line name="Responses" strokeWidth={5} activeDot={{ stroke: '#77A6ED', strokeWidth: 4, r: 8, fill: 'white' }} dot={{ stroke: '#77A6ED', strokeWidth: 3, r: 6, fill: 'white' }} dataKey="count" stroke="#77A6ED">
                </Line>
                <Tooltip labelFormatter={(label) => moment(label.toString()).format("ll hA")}></Tooltip>
              </LineChart>
            </ResponsiveContainer>
          </div> */}
            <ReportsExportButton />
            <div className="table-tasks-report">
              {isEmpty(approvalsAging) ? (
                <ReportsNoData height="12vw" reportType="approval aging" />
              ) : (
                <TabularData
                  selectable={false}
                  onLoadMore={this.loadMore}
                  maxRows={approvalsAging.length}
                  rowClassName="testing-reports-table"
                  rowStyle={{ cursor: "default" }}
                  data={approvalsAging.slice(0, this.state.numberOfEntries)}
                  format={{
                    callBack: row => {},
                    selectAll: (e, rows) => {},
                    selected: (e, row) => {},
                    columns: this.approvalAgingColumns()
                  }}
                  //maxHeight="40vh"
                />
              )}
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

const storeToProps = store => {
  return {
    reports: store.reports
  };
};

export default connect(
  storeToProps,
  dispatchToProps
)(ReportsApprovalAging);
