import React, { Component } from "react";

class IconAward extends Component {
  render() {
    let { className, height } = this.props;

    return (
      <svg
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        viewBox="0 0 24 24"
        height={height}
        className={`icon ${className ? className : ""}`}
      >
        <circle cx="12" cy="8" r="7" />
        <path d="M8.21 13.89L7 23l5-3 5 3-1.21-9.12" />
      </svg>
    );
  }
}

IconAward.defaultProps = {
  height: 22
};

export default IconAward;
