import React, { Component } from "react";

class IconAttachment extends Component {
  render() {
    let { className, height, style } = this.props;

    return (
      <svg viewBox="0 0 22 23" height={height} className={`icon ${className ? className : ""}`} style={style}>
        <g fill="none" fillRule="evenodd">
          <path
            d="M20.4383366 10.66222434l-9.19 9.18999996c-2.34444879 2.3444488-6.14555126 2.3444488-8.49000003 0-2.34444876-2.3444487-2.34444876-6.1455512 0-8.49l9.19000003-9.18999996c1.5629658-1.5629658 4.0970341-1.56296578 5.6599999 4e-8 1.5629659 1.56296583 1.5629659 4.09703411 1e-7 5.65999996L8.40833657 17.0222243c-.78148292.781483-2.04851708.781483-2.83 0-.78148292-.7814829-.78148292-2.048517 0-2.83l8.49000003-8.47999996"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
        </g>
      </svg>
    );
  }
}

IconAttachment.defaultProps = {
  height: 23
};

export default IconAttachment;
