import React, { Component } from "react";

class IconArrowLeft extends Component {
  render() {
    let { className, height } = this.props;

    return (
      <svg viewBox="0 0 12 18" height={height} className={`icon ${className ? className : ""}`}>
        <path
          d="M1 9l10 8V1z"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          fill="none"
          fillRule="evenodd"
        />
      </svg>
    );
  }
}

IconArrowLeft.defaultProps = {
  height: 18
};

export default IconArrowLeft;
