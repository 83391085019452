import React, { Component } from "react";
import LockstepLogo from "../library/lockstepLogo";
import Resources from "../../lib/resources";

class PortalSessionExpired extends Component {
  render() {
    return (
      <div className="portal-session-expired">
        <div className="connected-by-lockstep-container">
          <span className="mr-1">{Resources.ConnectedBy}</span> <LockstepLogo />
        </div>
        <svg viewBox="0 0 245 98">
          <g fill="none" fill-rule="evenodd">
            <path fill="#FFF" d="M-598-106H842V918H-598z" />
            <path
              fill="#F4F3FF"
              d="M134.976852 9.93981481c4.385038 0 7.939815 3.55477619 7.939815 7.93981479 0 4.3850387-3.554777 7.9398148-7.939815 7.9398148h72.592592c4.385039 0 7.939815 3.5547762 7.939815 7.9398149 0 4.3850386-3.554776 7.9398148-7.939815 7.9398148h24.953704c4.385039 0 7.939815 3.5547762 7.939815 7.9398148s-3.554776 7.9398148-7.939815 7.9398148h-21.550926c-4.385038 0-7.939815 3.5547762-7.939815 7.9398148 0 4.3850387 3.554777 7.9398148 7.939815 7.9398148h6.805556c4.385038 0 7.939815 3.5547762 7.939815 7.9398148 0 4.3850387-3.554777 7.9398149-7.939815 7.9398149H52.1759259c-4.3850386 0-7.9398148-3.5547762-7.9398148-7.9398149 0-4.3850386 3.5547762-7.9398148 7.9398148-7.9398148H7.93981481C3.55477618 73.4583333 0 69.9035572 0 65.5185185c0-4.3850386 3.55477618-7.9398148 7.93981481-7.9398148H53.3101852c4.3850386 0 7.9398148-3.5547762 7.9398148-7.9398148s-3.5547762-7.9398148-7.9398148-7.9398148H24.9537037c-4.3850386 0-7.9398148-3.5547762-7.9398148-7.9398148 0-4.3850387 3.5547762-7.9398149 7.9398148-7.9398149h45.3703704c-4.3850387 0-7.9398148-3.5547761-7.9398148-7.9398148 0-4.3850386 3.5547761-7.93981479 7.9398148-7.93981479h64.6527779zM237.060185 73.4583333c4.385039 0 7.939815 3.5547762 7.939815 7.9398148 0 4.3850387-3.554776 7.9398149-7.939815 7.9398149-4.385038 0-7.939815-3.5547762-7.939815-7.9398149 0-4.3850386 3.554777-7.9398148 7.939815-7.9398148z"
            />
            <path
              fill="#FFF"
              stroke="#BEB6FF"
              stroke-linecap="round"
              stroke-width="3.5"
              d="M85.0694444 96.1435185c-13.78155 0-24.9537037-10.9182411-24.9537037-24.3865741 0-13.4683329 11.1721537-24.386574 24.9537037-24.386574.5860457 0 1.1673729.0197433 1.7433231.0585864-.4001725-2.2275181-.6090638-4.5214355-.6090638-6.864142C86.2037037 19.2660557 103.469759 2 124.768519 2c17.003729 0 31.437205 11.0045658 36.566947 26.280218 1.215395-.127081 2.449588-.192255 3.699256-.192255 19.10624 0 34.594908 15.234755 34.594908 34.0277777 0 17.8539313-13.979399 32.5842481-31.75926 34.0030336v.0247442h-64.076786m-5.1175976 0h-7.8996616"
            />
            <path
              stroke="#BEB6FF"
              stroke-linecap="round"
              stroke-width="3.5"
              d="M136.111111 15.6111111c7.38277 1.9538494 13.212084 7.7275472 15.244768 15.0779183M103.217592 51.9074078c0 4.3850386 3.554776 7.9398148 7.939815 7.9398148 4.385038 0 7.939814-3.5547762 7.939814-7.9398148M136.111111 51.9074078c0 4.3850386 3.554776 7.9398148 7.939815 7.9398148 4.385039 0 7.939815-3.5547762 7.939815-7.9398148M119.097222 73.4583333h16.446759"
            />
            <path
              fill="#BEB6FF"
              fill-rule="nonzero"
              d="M74.3711111 23.07v1.7240741l-7.5496296 9.2737037h7.7311111v1.96H63.9359259v-1.8511111L71.4855556 25.03h-6.9688889v-1.96h9.8544444zM59.1584259 5.73398148v2.37060185L48.7776852 20.8559259H59.407963v2.695H44.8100463v-2.5452778L55.190787 8.42898148h-9.5822222v-2.695h13.5498611z"
            />
          </g>
        </svg>
        <h2>{Resources.YourSessionHasExpired}</h2>
        <p>{Resources.PortalSessionExpired}</p>
      </div>
    );
  }
}

export default PortalSessionExpired;
