import React, { Component } from "react";
import { find, isEmpty, compareStrings } from "../../lib/utils";
import Icons from "../../lib/icons";

export default class AutoCompleteDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isActive: false,
      filter:
        (this.props.selected || {})[this.props.displayKey] ||
        (this.props.options[0] || {})[this.props.displayKey] ||
        "",
      filteredOptions: this.props.options.slice(0, 4),
      highlightIndex: null
    };

    this.input = React.createRef();

    this.onFilterChanged = this.onFilterChanged.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleSelectOption = this.handleSelectOption.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidUpdate(prevProps) {
    const update = {};
    if (this.props.options !== prevProps.options) {
      update.filteredOptions = this.props.options.slice(0, 4);
      update.filter = (this.props.options[0] || {})[this.props.displayKey] || "";
    }

    if (this.props.selected !== prevProps.selected) {
      update.filter = this.props.selected[this.props.displayKey];
    }

    if (!isEmpty(update)) {
      this.setState(update);
    }
  }

  onFilterChanged(e) {
    const { displayKey } = this.props;
    const filter = e.target.value;
    let filtered = [];
    const options = [...(this.props.options || [])];
    if (this.state.adding || true) {
      if (filter.trim() === "") {
        filtered = options;
      } else {
        filtered = options.filter(option => {
          return (option[displayKey] || "").toLowerCase().indexOf(filter.toLowerCase()) > -1;
        });
      }
    }

    filtered.sort((a, b) => {
      let aStart = a.companyName.toLocaleLowerCase().startsWith(this.state.filter.toLocaleLowerCase());
      let bStart = b.companyName.toLocaleLowerCase().startsWith(this.state.filter.toLocaleLowerCase());
      if (aStart && !bStart) {
        return -1;
      }
      if (!aStart && bStart) {
        return 1;
      }
      return compareStrings(a.companyName, b.companyName);
    });

    this.setState({
      filter,
      filteredOptions: filtered.slice(0, 4),
      highlightIndex: null
    });
  }

  handleKeyDown(e) {
    const { filteredOptions, highlightIndex } = this.state;
    let nextHighlightIndex;

    switch (e.key) {
      case "ArrowDown":
        e.preventDefault();
        if (highlightIndex === null) {
          nextHighlightIndex = 0;
        } else if (highlightIndex === filteredOptions.length - 1) {
          return;
        } else {
          nextHighlightIndex = highlightIndex + 1;
        }
        this.setState({ highlightIndex: nextHighlightIndex });
        break;
      case "ArrowUp":
        e.preventDefault();
        if (highlightIndex === null || highlightIndex === 0) {
          return;
        } else {
          nextHighlightIndex = highlightIndex - 1;
        }
        this.setState({ highlightIndex: nextHighlightIndex });
        break;
      case "Enter":
        if (filteredOptions[highlightIndex]) {
          this.handleSelectOption(filteredOptions[highlightIndex], true);
        }
        break;
      default:
        break;
    }
  }

  handleFocus(e) {
    const update = { isActive: true };

    const highlightIndex = this.state.filteredOptions.indexOf(this.props.selected);
    if (highlightIndex > -1) {
      update.highlightIndex = highlightIndex;
    }

    this.setState(update);
  }

  handleClick(e) {
    e.preventDefault();
    e.stopPropagation();
    if (this.props.disabled || this.state.isActive) {
      return;
    }
    this.input.current.focus();
  }

  handleBlur() {
    const { displayKey } = this.props;
    const filterOption = find(this.props.options, option => {
      return option[displayKey].toLowerCase() === this.state.filter.toLowerCase();
    });
    const update = { isActive: false, highlightIndex: null };
    if (filterOption) {
      this.props.handleSelectOption(filterOption);
      update.filter = filterOption[displayKey];
    } else {
      update.filter = this.props.selected[displayKey];
    }
    this.setState(update);
  }

  handleSelectOption(option, forceBlur = false) {
    const { displayKey } = this.props;
    clearTimeout(this.timeout);
    if (forceBlur) {
      this.input.current.blur();
    }
    this.props.handleSelectOption(option);
    this.setState({ filter: option[displayKey], isActive: false });
  }

  render() {
    const { width, label, id, displayKey, disabled, placeholder, inline, icon, readOnly } = this.props;
    const { isActive, filter, highlightIndex, filteredOptions } = this.state;

    return (
      <div className={`dropdown ${inline ? "auto-complete-input-inline" : ""}`} onClick={e => e.preventDefault()}>
        {!isEmpty(label) && (
          <label className={`input-label${inline ? "-inline pr-2" : ""}`} htmlFor={`auto-complete-input-${id}`}>
            {label}
          </label>
        )}
        <div
          className={`auto-complete-dropdown ${disabled ? "disabled" : ""}`}
          style={{ width: width }}
          onClick={this.handleClick}
        >
          <input
            type="text"
            autoComplete="off"
            id={`auto-complete-input-${id}`}
            disabled={disabled}
            onFocus={this.handleFocus}
            onClick={this.handleClick}
            onBlur={() => (this.timeout = setTimeout(this.handleBlur, 200))}
            onChange={this.onFilterChanged}
            onKeyDown={this.handleKeyDown}
            style={{ width: `calc(${width} - 30px)` }}
            value={disabled ? placeholder : filter}
            ref={this.input}
            readOnly={readOnly}
          />
          <span className={`${icon ? icon : Icons.search} auto-complete-dropdown-icon`} />
        </div>
        {isActive && filteredOptions.length > 0 && (
          <span className="dropdown-menu" style={{ display: "inline-table", width, left: "auto" }}>
            {filteredOptions.map((option, i) => {
              return (
                <div
                  key={i}
                  className={`dropdown-item ${i === highlightIndex ? "active" : ""}`}
                  style={{ width }}
                  onMouseEnter={() => this.setState({ highlightIndex: i })}
                  onClick={() => this.handleSelectOption(option)}
                >
                  {option[displayKey]}
                </div>
              );
            })}
          </span>
        )}
      </div>
    );
  }
}
