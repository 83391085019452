import React, { Component } from "react";

import Resources from "../../../lib/resources";
import { isEmpty } from "../../../lib/utils";
import Attachment from "../../attachment";

export default class ApprovalThreadItem extends Component {
  render() {
    const { emailBody, attachments, dateText, to, from, cc, approverName } = this.props;

    return (
      <div className="conversation-thread-item approval-item">
        <div>
          <div>
            <b>
              <span
                dangerouslySetInnerHTML={{
                  __html: Resources.ApprovalGrantedText(approverName)
                }}
              />
            </b>
            <span className="float-right" style={{ textAlign: "right" }}>
              <div className="thread-item-age">
                {dateText} {Resources.Ago.toLowerCase()}
              </div>
            </span>
          </div>
          <div>{from}</div>
          <div>{to}</div>
          <div>{cc}</div>
          <div>
            <br />
            <div dangerouslySetInnerHTML={{ __html: emailBody }} />
          </div>
        </div>
        {isEmpty(attachments) === false && (
          <div>
            <br />
            {attachments.map(att => {
              return (
                <Attachment
                  attachment={att}
                  key={att.attachmentId}
                  showIcon={true}
                  name={att.fileName}
                  size={att.sizeBytes}
                />
              );
            })}
          </div>
        )}
      </div>
    );
  }
}
