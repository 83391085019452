import React, { Component } from "react";
import { connect } from "react-redux";

import { dispatchToProps } from "../../../store/user-actions";

import Resources from "../../../lib/resources";
import { isEmpty } from "../../../lib/utils";

class AssignmentThreadItem extends Component {
  render() {
    const { posterName, assignedTo, dateText, isTask, entry, assignedUserId } = this.props;
    const assignee = this.props.getUserDisplayName(assignedUserId, Resources.Yourself.toLocaleLowerCase());
    let titleNote = Resources.ReassignedByTo(posterName, assignee, isTask);

    if (!isEmpty(entry.asJson.relatedConversationId)) {
      if (entry.asJson.relatedConversationType.toLowerCase() === "requestresponse") {
        titleNote = Resources.ResponseRequestReassignedTo(assignee);
      } else if (entry.asJson.relatedConversationType.toLowerCase() === "approvalrequest") {
        titleNote = Resources.ApprovalReassignedTo(assignee);
      }
    }

    return (
      <div className="conversation-thread-item">
        <div className="conversation-thread-item__body">
          <div className="conversation-thread-item__body__header">
            <span className="conversation-thread-item__body__badge conversation-thread-item__body__badge--assigned">
              {titleNote}
            </span>
            <span className="thread-item-age">{dateText}</span>
          </div>
        </div>
      </div>
    );
  }
}

const storeToProps = store => {
  return {};
};

export default connect(storeToProps, dispatchToProps)(AssignmentThreadItem);
