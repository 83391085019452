import React, { Component } from "react";
import IconUser from "./icons/iconUser";

class UserAvatar extends Component {
  render() {
    const { height, className } = this.props;
    return (
      <div className={`user-avatar ${className || ""}`}>
        <IconUser height={height || null}></IconUser>
      </div>
    );
  }
}

export default UserAvatar;
