import React, { Component } from "react";

class IconArchive extends Component {
  render() {
    let { className, height } = this.props;

    return (
      <svg viewBox="0 0 23 18" height={height} className={`icon ${className ? className : ""}`}>
        <path
          d="M19.3861302 5.44444444V17H3.37055082V5.44444444M1.591042 1h19.574597v4.44444444H1.591042zm8.00778968 8h3.55901762"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          stroke="currentColor"
          fill="none"
          fillRule="evenodd"
        />
      </svg>
    );
  }
}

IconArchive.defaultProps = {
  height: 18
};

export default IconArchive;
