import React, { Component } from "react";

class IconSlash extends Component {
  render() {
    let { className, height } = this.props;
    return (
      <svg
        height={height}
        className={`icon ${className ? className : ""}`}
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        viewBox="0 0 24 24"
      >
        <defs />
        <circle cx="12" cy="12" r="10" />
        <path d="M4.93 4.93l14.14 14.14" />
      </svg>
    );
  }
}

IconSlash.defaultProps = {
  height: 22
};

export default IconSlash;
