import React, { Component } from "react";
import Resources from "../../../../lib/resources";
import { generatePath, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { dispatchToProps as networkDP } from "../../../../store/network-actions";
import { updateSelectedRows, includes } from "../../../../lib/utils";

import MainLoader from "../../../mainLoader";
import ContactsTable from "../../contactsTable";

import Card from "../../../library/card";

const dispatchToProps = dispatch => ({
  ...networkDP(dispatch)
});

class ContactTableForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deletedContactKeys: [],
      deletedContactRows: [],
      editMode: false,
      accountContacts: {}
    };
    this.goToNext = this.goToNext.bind(this);
    this.toggleEditMode = this.toggleEditMode.bind(this);
  }

  componentDidMount() {
    let mode = this.props.match.params.mode;
    let accountingGroupKey = this.props.match.params.accountingGroupKey;
    let companyGlobalKey = this.props.match.params.companyGlobalKey;
    if (this.props.profileUpdate) {
      this.setState({ accountContacts: this.props.contacts });
    } else {
      this.props.fetchAccountingGroupProfile(accountingGroupKey, companyGlobalKey, mode);
      this.props.fetchAccountingGroupProfileContacts(accountingGroupKey, companyGlobalKey, mode);
    }
  }

  goToNext() {
    let accountingGroupKey = this.props.match.params.accountingGroupKey;
    let companyGlobalKey = this.props.match.params.companyGlobalKey;
    let accessId = this.props.match.params.accessId;
    let mode = this.props.match.params.mode;
    let accountContacts = { ...this.props.networkStore.custAccountContacts, ...this.state.accountContacts };
    let filteredContacts = accountContacts.contacts.filter(
      contact => !includes(this.state.deletedContactKeys, contact.entityGlobalKey)
    );
    let request = {
      contacts: filteredContacts,
      isLockstepVerified: true
    };
    if (this.props.profileUpdate) {
      this.props.postCustAccountKeyContact(accessId, request, mode).then(response => {
        this.props.history.push(
          generatePath("/v2/profileupdate/:type(account)/:mode(ar|ap)/documents/:accessId", {
            accessId: this.props.match.params.accessId,
            type: this.props.match.params.type,
            mode: this.props.match.params.mode
          })
        );
      });
    } else {
      this.props.postAccountingGroupProfileContacts(accountingGroupKey, companyGlobalKey, mode, request).then(
        this.props.history.push(
          generatePath(this.props.route, {
            accessId: this.props.match.params.accessId,
            view: this.props.view,
            mode: this.props.match.params.mode,
            accountingGroupKey: this.props.match.params.accountingGroupKey,
            companyGlobalKey: this.props.match.params.companyGlobalKey
          })
        )
      );
    }
  }

  deleteContact(key, row) {
    let newSelectedKeys = updateSelectedRows(key, this.state.deletedContactKeys);
    let newSelectedRows = updateSelectedRows(row, this.state.deletedContactRows);
    this.setState({ deletedContactKeys: newSelectedKeys, deletedContactRows: newSelectedRows });
  }

  toggleEditMode() {
    this.setState({ editMode: !this.state.editMode });
  }

  render() {
    const { contactList, gettingCustAccountContacts, gettingCustAccount } = this.props;
    let accountData = { ...this.props.networkStore.custAccount, ...this.state.accountData };
    let accountContacts = { ...contactList, ...this.state.accountContacts };
    let companyName = accountData.companyName || this.props.companyName;
    if (gettingCustAccountContacts || gettingCustAccount) {
      return <MainLoader className={this.props.profile ? null : "profile-update-form-mainloader"} fullScreen={true} />;
    } else {
      return (
        <React.Fragment>
          {this.props.profile ? null : (
            <h2 className="first-time-title-contact">{Resources.DoAllTheseContactsStillWorkFor(companyName)}</h2>
          )}
          <Card
            className={this.props.profile ? "company-profile-contacts-card" : "first-time-admin-contacts-table-card"}
          >
            <ContactsTable
              name="profile-update-form__contacts-table"
              data={accountContacts.contacts}
              loading={gettingCustAccountContacts}
              maxRows={contactList.count}
              selectedKeys={this.state.selectedContactKeys}
              rowKey="entityGlobalKey"
              noCompany
              noClick
              noHover
              profileUpdate
              editMode={this.state.editMode}
              pagination={false}
              deleteContact={(key, contact) => this.deleteContact(key, contact)}
              deletedContactKeys={this.state.deletedContactKeys}
            />
          </Card>
          {this.props.profile ? null : (
            <div className="profile-update-button-container">
              <button onClick={this.toggleEditMode} className="button-secondary-square large">
                {this.state.editMode ? Resources.Cancel : Resources.INeedToUpdateContacts}
              </button>
              <button onClick={this.goToNext} className="button-primary-square large">
                {this.state.editMode ? Resources.Save : Resources.YesTheyDo}
              </button>
            </div>
          )}
        </React.Fragment>
      );
    }
  }
}

const storeToProps = store => {
  return {
    networkStore: store.network,
    contactList: store.network.custAccountContacts,
    pageRowCount: store.general.pageRowCount,
    gettingCustAccount: store.network.gettingCustAccount,
    gettingCustAccountContacts: store.network.gettingCustAccountContacts
  };
};

export default withRouter(connect(storeToProps, dispatchToProps)(ContactTableForm));
