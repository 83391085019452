import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Form, FormGroup, Label, Input } from "reactstrap";
import Resources from "../lib/resources";
import { dispatchToProps } from "../store/registration-actions";
import { isEmailValid } from "../lib/utils";

class SetupConnectEmail extends Component {
  state = {
    errorMsg: "",
    areInputsValid: true,
    email: ""
  };

  setIfInputAreNotValid = () => {
    return new Promise(resolve => {
      if (this.state.email === "") {
        this.setState({ areInputsValid: false, errorMsg: "Must fill in all entry fields" }, () => {
          resolve(false);
        });
      } else if (!isEmailValid(this.state.email)) {
        this.setState(
          {
            areInputsValid: false,
            errorMsg: "Must use a valid email address."
          },
          () => {
            resolve(false);
          }
        );
      } else {
        this.setState({ areInputsValid: true }, () => {
          resolve(true);
        });
      }
    });
  };

  perspectiveText = () => {
    let contextText;
    if (this.props.perspective === "AR")
      contextText = [Resources.SetupForwardingEmailHeaderAR, Resources.SetupConnectEmailBlurb1AR];
    if (this.props.perspective === "AP")
      contextText = [Resources.SetupForwardingEmailHeaderAP, Resources.SetupConnectEmailBlurb1AP];

    return contextText;
  };

  onInput = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  setForwardingEmail = () => {
    let { companyId, perspective, setEmailForwardingAddress, progressStage } = this.props;
    let { email, areInputsValid } = this.state;

    setEmailForwardingAddress(companyId, perspective, email).then(() => {
      this.setIfInputAreNotValid().then(() => {
        if (areInputsValid) progressStage("FORWARD_EMAIL");
      });
    });
  };

  render() {
    let AP_OR_AR_CONTEXT = this.perspectiveText();
    let {
      registration: { errorMsgs },
      perspective
    } = this.props;
    let { areInputsValid, errorMsg, email } = this.state;

    return (
      <div>
        <h3 className="text-center">{AP_OR_AR_CONTEXT[0]}</h3>
        <hr />
        <p className="text-center">{AP_OR_AR_CONTEXT[1]}</p>
        <Form className=" light-gray-bg w-75 mx-auto p-4" onSubmit={e => e.preventDefault()}>
          {areInputsValid ? "" : <span className="warning-color-font">{errorMsg}</span>}
          {errorMsgs.map((item, i) => {
            return (
              <span key={i} className=" warning-color-font">
                {item}
              </span>
            );
          })}
          <FormGroup className="row text-right pt-3">
            <div className="col-4">
              <Label for="username">{Resources.Email + " " + Resources.Address}:</Label>
            </div>
            <div className="col-8">
              <Input
                type="email"
                name="email"
                value={email}
                onChange={this.onInput}
                id="usernameInput"
                placeholder={"e.g. " + perspective.toLowerCase() + Resources.EmailExample}
              />
            </div>
          </FormGroup>
        </Form>

        <Button onClick={this.setForwardingEmail} className="mt-3 mx-auto w-25 d-block">
          {Resources.Next} {Resources.Step}
        </Button>
      </div>
    );
  }
}

const storeToProps = store => {
  return {
    registration: store.registration
  };
};

export default connect(
  storeToProps,
  dispatchToProps
)(SetupConnectEmail);
