import React, { Component } from "react";

import Resources from "../../../lib/resources";

export default class SimpleForwardResponseThreadItem extends Component {
  render() {
    const { approverName, dateText } = this.props;

    return (
      <div className="conversation-thread-item system-message-item">
        <span>
          <b dangerouslySetInnerHTML={{ __html: Resources.RespondedToForward(approverName) }} />{" "}
          <span onClick={this.props.scrollToThreadItem} className="forward-color clickable underline">
            {Resources.ViewResponse}
          </span>
        </span>
        <span className="float-right" style={{ textAlign: "right" }}>
          <div className="thread-item-age">
            {dateText} {Resources.Ago.toLowerCase()}
          </div>
        </span>
      </div>
    );
  }
}
