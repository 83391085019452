import React, { Component } from "react";
import { connect } from "react-redux";
import Resources from "../lib/resources";
import { find } from "../lib/utils";

import Modal from "react-modal";

import Icons from "../lib/icons";

import { dispatchToProps as modDP } from "../store/modal-actions";
import { dispatchToProps as conDP } from "../store/connector-actions";

const dispatchToProps = dispatch => ({
  ...conDP(dispatch),
  ...modDP(dispatch)
});

class ChangeIncomingEmailModal extends Component {
  constructor(props) {
    super(props);

    this.state = { disconnecting: false };

    this.getImapCompanyPermissions = this.getImapCompanyPermissions.bind(this);
    this.getGmailCompanyPermissions = this.getGmailCompanyPermissions.bind(this);
    this.deactivate = this.deactivate.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    let { modalStore } = this.props;
    let { modalProps } = modalStore;
    let { companyId, perspectiveId } = modalProps;
    let gmailConnector = find(
      this.props.connectorStore.allSupportedConnectors,
      connector => connector.connectorName === "GMail"
    );
    let gmailConnectorVersion = this.props.connectorStore.connectorVersionData[gmailConnector.connectorId] || [];

    if (this.state.disconnecting === true && prevState.disconnecting === false) {
      this.deactivate(this.props.modalStore.modalProps.connectorToDeactivate)
        .then(response => {
          if (this.props.modalStore.modalProps.connectorToEnable === "Email") {
            this.props
              .setConnectorPermissions(companyId, this.getImapCompanyPermissions())
              .then(response =>
                this.props.displayModal("imapConfigModal", {
                  companyId,
                  perspective: { perspectiveId: perspectiveId }
                })
              )
              .catch(error => null);
          } else if (this.props.modalStore.modalProps.connectorToEnable === "GMail") {
            this.props
              .setConnectorPermissions(companyId, this.getGmailCompanyPermissions())
              .then(response => {
                window.location = gmailConnectorVersion[0].integrationEndpointUrl
                  .replace("{companyId}", companyId)
                  .replace("{perspectiveId}", perspectiveId);
              })
              .catch(error => null);
          }
        })
        .catch(error => this.setState({ disconnecting: false }));
    }
  }

  getImapCompanyPermissions() {
    let emailConnector = find(
      this.props.connectorStore.allSupportedConnectors,
      connector => connector.connectorName === "Email"
    );
    let emailResource = find(this.props.ledgerResources, resource => resource.resourceName === "Email");
    let connectorConfigResource = find(
      this.props.ledgerResources,
      resource => resource.resourceName === "ConnectorConfigs"
    );

    return [
      {
        resourceId: emailResource.resourceId,
        actionId: find(emailResource.actions, action => action.actionName === "Create").actionId,
        permittedCompanyId: emailConnector.companyId
      },
      {
        resourceId: emailResource.resourceId,
        actionId: find(emailResource.actions, action => action.actionName === "Read").actionId,
        permittedCompanyId: emailConnector.companyId
      },
      {
        resourceId: connectorConfigResource.resourceId,
        actionId: find(connectorConfigResource.actions, action => action.actionName === "Create").actionId,
        permittedCompanyId: emailConnector.companyId
      },
      {
        resourceId: connectorConfigResource.resourceId,
        actionId: find(connectorConfigResource.actions, action => action.actionName === "Read").actionId,
        permittedCompanyId: emailConnector.companyId
      },
      {
        resourceId: connectorConfigResource.resourceId,
        actionId: find(connectorConfigResource.actions, action => action.actionName === "Update").actionId,
        permittedCompanyId: emailConnector.companyId
      },
      {
        resourceId: connectorConfigResource.resourceId,
        actionId: find(connectorConfigResource.actions, action => action.actionName === "Delete").actionId,
        permittedCompanyId: emailConnector.companyId
      }
    ];
  }

  getGmailCompanyPermissions() {
    let gmailConnector = find(
      this.props.connectorStore.allSupportedConnectors,
      connector => connector.connectorName === "GMail"
    );
    let connectorConfigResource = find(
      this.props.ledgerResources,
      resource => resource.resourceName === "ConnectorConfigs"
    );

    return [
      {
        resourceId: connectorConfigResource.resourceId,
        actionId: find(connectorConfigResource.actions, action => action.actionName === "Create").actionId,
        permittedCompanyId: gmailConnector.companyId
      },
      {
        resourceId: connectorConfigResource.resourceId,
        actionId: find(connectorConfigResource.actions, action => action.actionName === "Read").actionId,
        permittedCompanyId: gmailConnector.companyId
      },
      {
        resourceId: connectorConfigResource.resourceId,
        actionId: find(connectorConfigResource.actions, action => action.actionName === "Update").actionId,
        permittedCompanyId: gmailConnector.companyId
      },
      {
        resourceId: connectorConfigResource.resourceId,
        actionId: find(connectorConfigResource.actions, action => action.actionName === "Delete").actionId,
        permittedCompanyId: gmailConnector.companyId
      }
    ];
  }

  deactivate(connectorName) {
    let companyId = this.props.modalStore.modalProps.companyId;
    let perspectiveId = this.props.modalStore.modalProps.perspectiveId;
    let connector = find(
      this.props.connectorStore.allSupportedConnectors,
      connector => connector.connectorName === connectorName
    );

    this.setState({ editingImap: false });
    let connectorVersionId = this.props.connectorStore.connectorVersionData[(connector || {}).connectorId][0]
      .connectorVersionId;
    return this.props.updateConnectorConfiguration(
      companyId,
      connectorVersionId,
      this.props.connectorStore.connectorConfigs[connectorVersionId].connectorConfiguration.filter(
        connectorConfig => connectorConfig.perspectiveId !== perspectiveId
      )
    );
  }

  render() {
    return (
      <Modal
        isOpen={this.props.modalStore.showModal}
        onRequestClose={() => this.props.hideModal()}
        contentLabel="Example Modal"
        className="custom-modal"
        overlayClassName="custom-modal-overlay"
      >
        <div className="custom-modal-header">{Resources.ChangeIncomingEmailAccount}</div>
        <div className="custom-modal-divider" />
        <div className="custom-modal-content">
          <div className="mt-4">{Resources.ChangeIncomingEmailModalText}</div>
          <div className="flex-row modal-buttons mt-4">
            <button className="button-cancel float-left mr-1" onClick={this.props.hideModal}>
              {Resources.Cancel}
            </button>
            <button
              className="btn button-warning"
              onClick={() => {
                this.setState({ disconnecting: true });
              }}
            >
              {this.state.disconnecting ? (
                <span className="d-flex align-content-center">
                  <span className="mr-2">{Resources.Disconnecting}</span>
                  <span className={Icons.spinner} />
                </span>
              ) : (
                Resources.Deactivate
              )}
            </button>
          </div>
        </div>
      </Modal>
    );
  }
}

const storeToProps = store => {
  return {
    modalStore: store.modal,
    connectorStore: store.connector,
    ledgerResources: store.ledger.resources
  };
};

export default connect(
  storeToProps,
  dispatchToProps
)(ChangeIncomingEmailModal);
