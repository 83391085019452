import React, { Component } from "react";
import Resources from "../lib/resources";

class RegUserCompanyExists extends Component {
  componentDidMount() {
    window.localStorage.removeItem("registration-stage");
    window.localStorage.removeItem("is_registering");
  }

  render() {
    return (
      <div className="">
        <div className="w-40 mx-auto mt-5 p-3">
          <h3>{Resources.RegCompanyExistsBlurb1}</h3>
          <p>{Resources.RegCompanyExistsBlurb2}</p>
          <p className="pt-3">{Resources.RegCompanyExistsBlurb3}</p>
        </div>
      </div>
    );
  }
}

export default RegUserCompanyExists;
